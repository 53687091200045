import { createAsyncThunk } from "@reduxjs/toolkit";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {SensorEditRequestDto} from "../../../models/entities/Requests/SensorEditRequestDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get sensor by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} sensorId - sensor ID
 * @return {SensorResponseDto | null}
 */
export const actionGetSensor = createAsyncThunk<SensorResponseDto | null, number>(
    "editSensor/getSensor",
    async (sensorId: number) => {
        return await SensorRepository.getSensor(sensorId);
    }
);

/**
 * Update sensor by ID and hardware
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{sensorId: number, sensorRequest: SensorEditRequestDto, t: TranslatorType}} updateRequest - edit sensor info
 * @return {SensorResponseDto | null | string}
 */
export const actionUpdateSensor = createAsyncThunk<SensorResponseDto | null | string,
    {sensorId: number, sensorRequest: SensorEditRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "editSensor/updateSensor",
    async (payload: {sensorId: number, sensorRequest: SensorEditRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await SensorRepository.updateSensor(payload.sensorRequest, payload.sensorId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await SensorRepository.getSensor(payload.sensorId);
    }
);
