import React from "react";
import "./DraggablePlanElement.scss"
import personIcon from "../../../images/personIcon.svg";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {InternalBuildingLocationType} from "../../../models/utils/AbstractBuildingReducers";
import {CustomDraggableAllowedContent} from "../CustomDraggable/CustomDraggable";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import picGateway from "../../../images/picGateway.svg";
import {T, useTranslate} from "@tolgee/react";
import {PatientLocatingStateEnum} from "../../../models/entities/Enums/PatientLocatingStateEnum";
import errIcon from "../../../images/inputNOK.svg";
import {ImageType} from "../../../models/entities/Utils/Image";
import {HiveMetasSensor, HiveMetasType} from "../../../models/utils/HiveUtil";

/** 
 * @interface 
 * @category Components
 */
export interface DraggablePlanBubble {

    /** Alert in bubble **/
    alert?: { text: string, icon?: ImageType}
    /** List of sensors for bubble **/
    sensors: Array<HiveMetasSensor>|null
    /** Locating info **/
    locationStatus?: PatientLocatingStateEnum,
}

/** 
 * @alias DraggablePlanElementLocation
 * @category Components
 */
type DraggablePlanElementLocation = {

    /** Left offset in map in percents **/
    leftOffsetPct: number,
    /** Top offset in map in percents **/
    topOffsetPct: number
}

/** 
 * @alias DraggablePlanElementHardware
 * @category Components
 */
type DraggablePlanElementHardware = {

    /** Hardware id number from DB **/
    id: number,
    /** Location internal type **/
    locationType: InternalBuildingLocationType,
    /** Hardware name **/
    name: string,
    /** Last communication for hardware **/
    lastCommunication?: string|null,
}

/** 
 * @alias DraggablePlanElementPatient
 * @category Components
 */
type DraggablePlanElementPatient = {

    /** Patient id number from DB **/
    id: number,
    /** Patient full name **/
    name: string,
}

/**
 * @alias DraggablePlanElementProps
 * @category Components
 */
type DraggablePlanElementProps = {
    zoom: number,
    /** Current plan element selected **/
    active: boolean,
    /** Currently dragged **/
    dragged: boolean,
    small: boolean,
    onClick?: EmptyFunc,
    /** Pop-up bubble options **/
    bubble?: DraggablePlanBubble,
    /** State of the draggable **/
    type?: HiveMetasType,
    /** Item name **/
    name: string,
    /** Hardware options **/
    hardware?: DraggablePlanElementHardware,
    /** Patient options **/
    patient?: DraggablePlanElementPatient,
    /** Current location on plan **/
    location: DraggablePlanElementLocation,
    /** Allowed bounds **/
    allowedContent: CustomDraggableAllowedContent,
    /** Remove redux dispatch callback **/
    dispatchRemoveHardwareBuildingPosition?: ActionCreatorWithPayload<{ hardwareId: number }>
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {DraggablePlanElementLocation} location - hardware location object
 * @param {CustomDraggableAllowedContent} allowedContent - plan bounds
 * @param {boolean} dragged - is currently dragged
 * @param {boolean} active - hardware is active
 * @param {EmptyFunc|undefined} onClick - onClick event for another callback
 * @param {DraggablePlanElementHardware|undefined} hardware - hardware info for map element
 * @param {DraggablePlanElementPatient|undefined} patient - patient info for map element
 * @param {ActionCreatorWithPayload<{ hardwareId: number }>|undefined}dispatchRemoveHardwareBuildingPosition - remove hardware from plan callback
 * @param {DraggablePlanBubble|undefined} bubble - show bubble with some info
 * @param {string} name - element name
 * @param {boolean} small - smaller size
 * @param {HiveMetasType|undefined} type - state of the draggable
 */
export const DraggablePlanElement = ({location, allowedContent, dragged, active, onClick, hardware, patient, bubble, name, small, type,
    dispatchRemoveHardwareBuildingPosition} : DraggablePlanElementProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    // Choose the right icon
    const getIconMeta = () : { icon: any, class: string } => {

        if (patient) { return {icon: personIcon, class: 'person'}; }
        if (hardware) {
            return {
                icon: HardwareUtil.getHardwareTypeMetaFromHardwareType(
                    BuildingUtil.getHardwareTypeFromInternalBuildingLocation(hardware.locationType),
                    t
                ).image,
                class: 'hardware'
            }
        }
        return {
            icon: picGateway,
            class: 'hardware'
        }
    }

    // Get class name for main element
    const getMainElementClassName = () : string => {

        let output = 'DraggablePlanElement';
        output += small ? ' small' : '';
        output += ` ${getIconMeta().class}`;
        output += active ? ' active' : '';
        output += dragged ? ' dragged' : '';
        output += onClick ? ' clickable' : '';
        output += hardware && hardware.locationType ? ` ${hardware.locationType}` : '';
        return output;
    }

    // Contains the bubble with alerts and sensors
    const getBubbleElement = (bubble: DraggablePlanBubble) : React.JSX.Element => {


        return (
            <div className={`DraggablePlanElement-block-bubble ${type ?? ''}`}>
                <div className={`DraggablePlanElement-block-bubble-inside inside`}>
                    <div className={'DraggablePlanElement-block-bubble-inside-title title'}>
                        {bubble.alert && bubble.alert.icon &&
                            <div className={'DraggablePlanElement-block-bubble-inside-title-icon'}>
                                <img src={bubble.alert.icon} alt={t('Base_name_alert')} />
                            </div>
                        }
                        <div className={'DraggablePlanElement-block-bubble-inside-title-text'}>
                            {bubble.alert ? bubble.alert.text : t('Base_normal_state')}
                        </div>
                    </div>
                    <div className={'DraggablePlanElement-block-bubble-inside-content'}>
                        <div className={'DraggablePlanElement-block-bubble-inside-content-inside'}>
                            {patient &&
                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-name'}> {patient.name} </div>
                            }
                            {hardware &&
                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-name'}> {hardware.name} </div>
                            }
                            {bubble.sensors && bubble.sensors.length > 0 ?
                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first'}>
                                    {bubble.sensors.map((sensor, index: number) => {
                                        return (
                                            <div key={`DraggablePlanElement-block-bubble-inside-content-inside-first-item-${index}`}
                                                 className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item'}>
                                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item-values'}>
                                                    <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item-values-icon'}>
                                                        <img src={sensor.icon} alt={t('Base_name_sensor')} />
                                                    </div>
                                                    <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item-values-value'}>
                                                        {sensor.textValue ?? '-'}
                                                    </div>
                                                    {sensor.unit &&
                                                        <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item-values-unit'}>
                                                            {sensor.unit}
                                                        </div>
                                                    }
                                                </div>
                                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-first-item-updated'}>
                                                    {sensor.updatedText ?? '-'}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className={'DraggablePlanElement-block-bubble-inside-content-inside-empty'}>
                                    <div className={'DraggablePlanElement-block-bubble-inside-content-inside-empty-sensors'}>
                                        <T keyName={'DraggablePlanElement_without_sensors'} />
                                    </div>
                                    {hardware && typeof hardware.lastCommunication !== 'undefined' &&
                                        <div className={'DraggablePlanElement-block-bubble-inside-content-inside-empty-lastCommunication'}>
                                            <T keyName={'DraggablePlanElement_last_communication'} /> {hardware.lastCommunication ?? '-'}
                                        </div>
                                    }
                                </div>
                            }
                            {(bubble.locationStatus === PatientLocatingStateEnum.FAILED || bubble.locationStatus === PatientLocatingStateEnum.ACTIVE) &&
                                <div className={`DraggablePlanElement-block-bubble-inside-content-inside-locating ${bubble.locationStatus}`}>
                                    {bubble.locationStatus === PatientLocatingStateEnum.FAILED &&
                                        <div className={`DraggablePlanElement-block-bubble-inside-content-inside-locating-tick`}>
                                            <img src={errIcon} alt={t('Base_failed_location')} />
                                        </div>
                                    }
                                    <div className={`DraggablePlanElement-block-bubble-inside-content-inside-locating-text`}>
                                        <T keyName={bubble.locationStatus === PatientLocatingStateEnum.ACTIVE
                                            ? 'Base_loading_location' : 'Base_failed_location'} />
                                    </div>
                                    {bubble.locationStatus === PatientLocatingStateEnum.ACTIVE &&
                                        <div className={`DraggablePlanElement-block-bubble-inside-content-inside-locating-spinner`}>
                                            <div className={`Spinner warning`}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className={'DraggablePlanElement-block-bubble-inside-triangle triangle'}>
                        <div className={'DraggablePlanElement-block-bubble-inside-triangle-sub'}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // Contains action buttons
    const getControlsElement = () : React.JSX.Element => {
        return (
            <div className={'DraggablePlanElement-block-controls controls'}>
                {dispatchRemoveHardwareBuildingPosition && hardware &&
                    <div className={'DraggablePlanElement-block-controls-item remove'}>
                        <Button
                            onClick={() => {
                                dispatch(dispatchRemoveHardwareBuildingPosition({hardwareId: hardware.id}));
                            }}
                            text={t('DraggablePlanElement_remove')}
                            type={ButtonType.SECONDARY}/>
                    </div>
                }
                <div className={'DraggablePlanElement-block-controls-item detail'}>
                    <Button
                        onClick={ () => {
                            if (hardware) { navigation("/devices/" + hardware.id); }
                            else if (patient) { navigation("/patient/" + patient.id); }
                        } }
                        text={t('DraggablePlanElement_detail')}
                        type={ButtonType.SECONDARY} />
                </div>
            </div>
        )
    }

    // Get title element
    const getTitleElement = () : React.JSX.Element => {
        return (
            <div className={'DraggablePlanElement-block-text text'}>
                <div className={'DraggablePlanElement-block-text-item'}>
                    {name}
                </div>
            </div>
        )
    }

    // Construct
    return (
        <div className={getMainElementClassName()}
             onClick={ () => { if (onClick) { onClick(); } } }
             style={{
                 left: dragged ? undefined : (location.leftOffsetPct/100*allowedContent?.width) - (34 / 2),
                 top: dragged ? undefined : (location.topOffsetPct/100*allowedContent?.height) - (41 / 2),
             }}>
            <div draggable={false} className={`DraggablePlanElement-block block ${type ? type + 'Glow' : ''}`}>
                <div className={`DraggablePlanElement-block-icon icon`}>
                    <img src={getIconMeta().icon} draggable={false} alt={t('Base_name_building_plan_element')}/>
                </div>
                {bubble && active && getBubbleElement(bubble)}
                {active && !dragged && !bubble && getControlsElement()}
                {!dragged && getTitleElement()}
            </div>
        </div>
    )
}

export default DraggablePlanElement;
