import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface BuildingPlanUploadState extends AbstractFormReducersState {}

const initialState: BuildingPlanUploadState = {
    form: {
        inputs: [
            {name: 'file', validations: {required: true, type: ValidatorItemType.SVG}},
            {name: 'sizeX', validations: {required: true, type: ValidatorItemType.NUMBER}},
            {name: 'sizeY', validations: {required: true, type: ValidatorItemType.NUMBER}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingPlanUploadOrganismSlice = createSlice({
    name: 'buildingPlanUploadOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}}
})

export default buildingPlanUploadOrganismSlice.reducer
