import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {HardwareTypeDto} from "../../../models/entities/HardwareTypeDto";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
export interface CreateHardwarePageScannedData {

    /** Hardware UUID **/
    uuid: string,
    /** Hardware Template ID **/
    templateId: number
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
export interface CreateHardwarePageState {

    /** Upload modal shown **/
    uploadModal: boolean,
    /** Currently selected hardware type **/
    hardwareTypeSelected: HardwareTypeEnum|null,
    /** List of all hardware types **/
    hardwareTypes?: Array<HardwareTypeDto>|null,
    /** Show scan modal **/
    scanRequested: boolean,
    /** Scanned data **/
    scannedData: CreateHardwarePageScannedData|null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const initialState: CreateHardwarePageState = {
    hardwareTypeSelected: null,
    scanRequested: false,
    uploadModal: false,
    scannedData: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const createHardwarePageSlice = createSlice({
    name: 'createHardwarePage',
    initialState,
    reducers: {
        setScannedData: (state, action: PayloadAction<{scannedData: CreateHardwarePageScannedData|null}>) => {
            state.scannedData = action.payload.scannedData;
        },
        showScanModal: (state: CreateHardwarePageState) => {
            state.scanRequested = true;
        },
        hideScanModal: (state: CreateHardwarePageState) => {
            state.scanRequested = false;
        },
        hideBulkUploadModal: (state: CreateHardwarePageState) => {
            state.uploadModal = false;
        },
        showBulkUploadModal: (state: CreateHardwarePageState) => {
            state.uploadModal = true;
        },
        selectHardwareType: (state: CreateHardwarePageState, action: PayloadAction<{ hardwareType: HardwareTypeEnum }>) => {
            state.hardwareTypeSelected = action.payload.hardwareType;
        },
        destroyPage: (state: CreateHardwarePageState) => {
            state.scannedData = null;
            state.scanRequested = false;
            state.hardwareTypeSelected = null;
            state.uploadModal = false;
        },
    },
})

export default createHardwarePageSlice.reducer