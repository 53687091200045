import { createAsyncThunk } from "@reduxjs/toolkit";
import {ZoneRepository} from "../../../models/repositories/ZoneRepository";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";

/**
 * Get zone by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} zoneId - zone ID
 * @return {ZoneResponseDto | null}
 */
export const actionGetZone = createAsyncThunk<ZoneResponseDto | null, number>(
    "addOrEditZone/getZone",
    async (zoneId: number) => {
        return await ZoneRepository.getZone(zoneId);
    }
);
