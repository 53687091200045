import React, {useEffect, useRef} from "react";
import "./BuildingManager.scss"
import save from "../../../images/save.svg"
import fullscreen from "../../../images/fullscreen.svg"
import {Button, ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {CustomDraggable, CustomDraggableAllowedContent, CustomDraggableDoneCallback} from "../../molecules/CustomDraggable/CustomDraggable";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {BuildingUpdateRequestDto} from "../../../models/entities/Requests/BuildingUpdateRequestDto";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {
    actionRemoveBuildingFloor,
    actionRemoveBuildingPlan,
    actionResetBuildingPlan,
    actionUpdateBuildingPlan
} from "../../../store/actions/data/buildingAction";
import {
    AbstractBuildingActiveType,
    AbstractBuildingUpdateActivePayload,
    InternalBuildingBuilding,
    InternalBuildingFloor,
    InternalBuildingLocation,
    InternalBuildingLocationType, UpdateFloorCallback
} from "../../../models/utils/AbstractBuildingReducers";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
import reset from "../../../images/reset.svg";
import removeFloor from "../../../images/buildingPlan.svg";
import deleteIcon from "../../../images/delete.svg";
import {Dropdown} from "../../molecules/Dropdown/Dropdown";
import {buildingManagerOrganismSlice} from "../../../store/slices/organisms/buildingManagerSlice";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {DraggablePlanElementManagerComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementManagerComponent";
import {useTranslate} from "@tolgee/react";
import FilterFloor from "../FilterFloor/FilterFloor";
import Building from "../../molecules/Building/Building";

type BuildingManagerProps = {
    currentZoom: number,
    saveDisabled?: boolean,
    currentFloorIndex: number,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    currentFloorElevation: number,
    building: InternalBuildingBuilding,
    doneCallBack?: CustomDraggableDoneCallback,
    dispatchAddFloor: ActionCreatorWithoutPayload,
    allowedContent: CustomDraggableAllowedContent|null,
    dispatchShowFullscreen: ActionCreatorWithoutPayload,
    dispatchUpdateZoom: ActionCreatorWithPayload<{ zoom: number }>,
    updateFloorCallback: UpdateFloorCallback,
    dispatchUpdateActiveHardware: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchRemoveHardwareBuildingPosition?: ActionCreatorWithPayload<{ hardwareId: number }>,
    dispatchUpdateDraggableAllowedContent: ActionCreatorWithPayload<CustomDraggableAllowedContent>,
    dispatchInitialized: ActionCreatorWithoutPayload,
    dispatchRemoveActives: ActionCreatorWithoutPayload,
    initialized: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {InternalBuildingBuilding} building - contains internal building data type
 * @param {CustomDraggableAllowedContent|null} allowedContent - allowed bounds
 * @param {CustomDraggableDoneCallback|undefined} doneCallBack - drag end callback
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - active hardware id
 * @param {number} currentFloorElevation - active floor elevation number
 * @param {number} currentFloorIndex - active floor index number
 * @param {ActionCreatorWithPayload<{ hardwareId: number }>|undefined} dispatchRemoveHardwareBuildingPosition - redux callback to remove hardware from plan
 * @param {UpdateFloorCallback} updateFloorCallback - update current viewed floor elevation,
 * @param {number} currentZoom - current zoom level
 * @param {ActionCreatorWithPayload<{ zoom: number }>} dispatchUpdateZoom - Redux callback to update zoom level
 * @param {ActionCreatorWithPayload<CustomDraggableAllowedContent>} dispatchUpdateDraggableAllowedContent - update plan bounds
 * @param {ActionCreatorWithoutPayload} dispatchShowFullscreen - show fullscreen modal
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardware - update active hardware
 * @param {boolean|undefined} saveDisabled - disable save button
 * @param {ActionCreatorWithoutPayload} dispatchAddFloor - redux callback to show add floor modal
 * @param {boolean} initialized - view is initialized
 * @param {ActionCreatorWithoutPayload} dispatchInitialized - set view initialized
 * @param {ActionCreatorWithoutPayload} dispatchRemoveActives - Redux callback to remove all actives
 */
export const BuildingManager = ({building, dispatchRemoveHardwareBuildingPosition, updateFloorCallback, currentZoom, dispatchUpdateZoom,
    dispatchUpdateDraggableAllowedContent, dispatchShowFullscreen, dispatchUpdateActiveHardware, allowedContent, saveDisabled, initialized,
    doneCallBack, activeHardware, currentFloorElevation, currentFloorIndex, dispatchAddFloor, dispatchInitialized,
    dispatchRemoveActives} : BuildingManagerProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const buildingContainerRef: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    const deleteDropdownShownState = useAppSelector((state) => state.buildingManagerOrganism.deleteDropdownShown);

    // Destroy state
    useEffect(() => { dispatch(buildingManagerOrganismSlice.actions.destroyOrganism()); }, [dispatch]);

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => {
        if (deleteDropdownShownState) { dispatch(buildingManagerOrganismSlice.actions.hideDeleteDropDown()); }
    });

    // Get hardware plan element from building plan locations
    const getBuildingLocationBuildingPlanElement = (location: InternalBuildingLocation, allowedContent: CustomDraggableAllowedContent,
        dragged: boolean, index?: number) : React.JSX.Element => {

        return (
            <DraggablePlanElementManagerComponent
                key={`DraggablePlanElementManagerComponent-${index}`}
                dispatchRemoveHardwareBuildingPosition={dispatchRemoveHardwareBuildingPosition}
                activeHardware={activeHardware}
                allowedContent={allowedContent}
                currentZoom={currentZoom}
                dragged={dragged}
                location={location}/>
        )
    }

    // Get draggable element
    const getDraggablePlanElement = (location: InternalBuildingLocation, allowedContent: CustomDraggableAllowedContent,
        index: number, doneCallback: CustomDraggableDoneCallback) : React.JSX.Element => {

        return (
            <CustomDraggable
                name={location.hardwareName}
                id={location.hardwareId}
                type={location.type}
                startCallBack={ (id: number) => {
                    dispatch(dispatchUpdateActiveHardware({id: id, callerType: AbstractBuildingActiveType.FROM_BUILDING_PLAN}));
                } }
                clickCallBack={ (id: number) => {
                    dispatch(dispatchUpdateActiveHardware({id: id, callerType: AbstractBuildingActiveType.FROM_BUILDING_PLAN}));
                } }
                key={`BuildingManager-content-main-building-hardware-${index}`}
                allowedContent={allowedContent}
                doneCallBack={doneCallback}
                mainContent={getBuildingLocationBuildingPlanElement(location, allowedContent, false)}
                draggedContent={getBuildingLocationBuildingPlanElement(location, allowedContent, true)} />
        )
    }

    // Save whole building updated data
    const handleSaveChanges = async () => {

        const buildingUpdateRequest : BuildingUpdateRequestDto = {floors: building.floors.map((floor: InternalBuildingFloor) => {
            return {
                elevation: floor.elevation,
                hardwareLocations: floor.locations.map((location: InternalBuildingLocation) => {
                    return {
                        planOffsetLeft: location.leftOffsetPct,
                        planOffsetTop: location.topOffsetPct,
                        hardware: location.hardwareId,
                    }
                })
            }
        })};

        const result = await dispatch(actionUpdateBuildingPlan({buildingUpdateRequest: buildingUpdateRequest, t: t}))
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('BuildingManager_successfully_updated')}));
    }

    // Reset all locations in the plan
    const handleResetPlan = async () => {

        const result = await dispatch(actionResetBuildingPlan({t: t}))
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('BuildingManager_successfully_reset')}));
    }

    // Remove whole plan
    const handleRemovePlan = async () => {

        const result = await dispatch(actionRemoveBuildingPlan({t: t}))
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('BuildingManager_successfully_remove')}));
    }

    // Remove current floor
    const handleRemoveFloor = async () => {

        const result = await dispatch(actionRemoveBuildingFloor({elevation: currentFloorElevation, t: t}))
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('BuildingManager_successfully_removeFloor')}));
    }

    // Get list of elements for building plan
    const getHardwareElementsList = (allowedContent: CustomDraggableAllowedContent, doneCallBack: CustomDraggableDoneCallback) : Array<React.JSX.Element>|null => {

        return building.floors[shownFloorIndex].locations.filter((location: InternalBuildingLocation) => {
            return location.type !== InternalBuildingLocationType.DEFAULT;
        }).map((location: InternalBuildingLocation, index: number) => {
            return (getDraggablePlanElement(location, allowedContent, index, doneCallBack));
        });
    }

    // Current floor for hardware
    let shownFloorIndex : number = currentFloorIndex;
    if (activeHardware) {
        const currentHardwareFloor = BuildingUtil.getFloorFromHardwareId(building, activeHardware.id);
        shownFloorIndex = currentHardwareFloor ? currentHardwareFloor.elevation - 1 : shownFloorIndex;
    }

    return (
        <div className={"BuildingManager"}>
            <div className={"BuildingManager-content"} ref={buildingContainerRef}>
                <div className={"BuildingManager-content-menu"}>
                    <div className={"BuildingManager-content-menu-section"}>
                        <div className={"BuildingManager-content-menu-section-zoom"}>
                            <div className={"BuildingManager-content-menu-section-zoom-control"}
                                onClick={ () => { dispatch(dispatchUpdateZoom({
                                    zoom: BuildingUtil.calculateCurrentZoomDecrease(currentZoom)
                                })); } }>
                                <img src={minus} alt={t('Base_decrease_zoom')}/>
                            </div>
                            <div className={"BuildingManager-content-menu-section-zoom-value"}>
                                {Math.round(currentZoom * 100)}%
                            </div>
                            <div className={"BuildingManager-content-menu-section-zoom-control"}
                                 onClick={ () => { dispatch(dispatchUpdateZoom({
                                     zoom: BuildingUtil.calculateCurrentZoomIncrease(currentZoom)
                                 }));} }>
                                <img src={plus} alt={t('Base_increase_zoom')}/>
                            </div>
                        </div>
                    </div>
                    <div className={"BuildingManager-content-menu-section"}>
                        <div className={"BuildingManager-content-menu-section-floor"}>
                            <FilterFloor
                                dispatchAddFloor={dispatchAddFloor}
                                updateFloorCallback={updateFloorCallback}
                                title={currentFloorElevation + '. ' + t('Base_name_floor')} />
                        </div>
                    </div>
                    <div className={"BuildingManager-content-menu-section"}>
                        <div className={"BuildingManager-content-menu-section-controls"}>
                            <div className={"BuildingManager-content-menu-section-controls-item"}>
                                <Button
                                    onClick={handleSaveChanges.bind(this)}
                                    image={save}
                                    text={t('BuildingManager_button_save')}
                                    disabled={saveDisabled}
                                    type={ButtonType.PRIMARY}/>
                            </div>
                            <div ref={wrapperRef} className={"BuildingManager-content-menu-section-controls-item"}>
                                <div
                                    className={"BuildingManager-content-menu-section-controls-item-delete"}
                                    onClick={() => { dispatch(buildingManagerOrganismSlice.actions.toggleDeleteDropDown()); }}>
                                    <img src={deleteIcon} alt={t('BuildingManager_remove')}/>
                                    {deleteDropdownShownState &&
                                        <div className={"BuildingManager-content-menu-section-section-controls-item-dropdown"}>
                                            <Dropdown
                                                options={[
                                                    {title: t('BuildingManager_removeFloor'), icon: removeFloor, callback: handleRemoveFloor},
                                                    {title: t('BuildingManager_resetPlan'), icon: reset, callback: handleResetPlan},
                                                    {title: t('BuildingManager_removePlan'), icon: deleteIcon, callback: handleRemovePlan}
                                                ]}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"BuildingManager-content-menu-section-controls-item"}>
                                <div className={"BuildingManager-content-menu-section-controls-item-save"}
                                    onClick={ () => { dispatch(dispatchShowFullscreen()); } }>
                                    <img src={fullscreen} alt={t('Base_fullscreen')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <Building
                        dispatchRemoveActives={dispatchRemoveActives}
                        dispatchInitialized={dispatchInitialized}
                        initialized={initialized}
                        dispatchUpdateZoom={dispatchUpdateZoom}
                        dispatchUpdateDraggableAllowedContent={dispatchUpdateDraggableAllowedContent}
                        buildingContainerElement={buildingContainerRef.current ? buildingContainerRef.current : null}
                        plan={building.floors[currentFloorIndex].plan}
                        currentZoom={currentZoom}
                        draggableElements={allowedContent && doneCallBack ? getHardwareElementsList(allowedContent, doneCallBack) : null}
                    />

            </div>
        </div>
    );
}

export default BuildingManager;
