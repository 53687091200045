import React from "react";
import {CustomDraggableAllowedContent} from "../CustomDraggable/CustomDraggable";
import {DraggablePlanElement} from "./DraggablePlanElement";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {AbstractBuildingUpdateActivePayload, InternalBuildingLocation} from "../../../models/utils/AbstractBuildingReducers";

type DraggablePlanElementManagerComponentProps = {
    location: InternalBuildingLocation,
    allowedContent: CustomDraggableAllowedContent,
    dragged: boolean,
    currentZoom?: number,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    dispatchRemoveHardwareBuildingPosition?: ActionCreatorWithPayload<{ hardwareId: number }>,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {InternalBuildingLocation} location - building location info
 * @param {CustomDraggableAllowedContent} allowedContent - map bounds
 * @param {boolean} dragged - item is dragged
 * @param {number|undefined} currentZoom - actual zoom
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - current active hardware
 * @param {ActionCreatorWithPayload<{ hardwareId: number }>|undefined} dispatchRemoveHardwareBuildingPosition - remove HW from map Redux callback
 */
export const DraggablePlanElementManagerComponent = ({location, allowedContent, dragged, currentZoom,
    activeHardware, dispatchRemoveHardwareBuildingPosition} : DraggablePlanElementManagerComponentProps): React.JSX.Element|null => {

    return (
        <DraggablePlanElement
            small={true}
            name={location.hardwareName}
            hardware={{id: location.hardwareId, locationType: location.type, name: location.hardwareName}}
            active={!!(activeHardware && location.hardwareId === activeHardware.id)}
            zoom={currentZoom ?? 1}
            dispatchRemoveHardwareBuildingPosition={dispatchRemoveHardwareBuildingPosition}
            allowedContent={allowedContent}
            dragged={dragged}
            location={{leftOffsetPct: location.leftOffsetPct, topOffsetPct: location.topOffsetPct}} />
    )
}

export default DraggablePlanElementManagerComponent;
