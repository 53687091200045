import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {BoxUsers} from "../../organisms/BoxUsers/BoxUsers";
import {useAppSelector} from "../../../store/hooks";
import {userDetailPageSlice} from "../../../store/slices/pages/userDetailPageSlice";
import {CardUser} from "../../organisms/CardUser/CardUser";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import {Storage} from "../../../models/utils/Storage";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type UserDetailTemplateProps = {
    user: UserResponseDto,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @todo implement log for user detail
 * @param {UserResponseDto} user - user data
 * @param {RoleEnum} role - role from session for user
 */
export const UserDetailTemplate = ({user, role}: UserDetailTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const paginationPageState = useAppSelector((state) => state.userDetailPage.paginationPage);
    const session = Storage.getSession();
    const isMe = !(!session || (session.user.id !== user.User.id));
    const title = t('UserDetailTemplate_title', {userName: `${user.User.firstName} ${user.User.lastName}`});

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    <title>
                        {title}
                    </title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={title}
                        breadItems={[
                            {name: t('Navigation_users'), link: '/users'},
                            {name: title, link: null}
                        ]} />
                    <div className="layout-right-content fullHeight layout-right-content-flex">
                        <div className={`layout-right-content-item fullHeight ${!isMe ? 'withoutThird' : 'fill'}`}>
                            <CardUser
                                isMe={isMe}
                                user={user}/>
                        </div>
                        {!isMe &&
                            <div className="layout-right-content-item third fullHeight fill">
                                <BoxUsers
                                    pagination={{
                                        page: paginationPageState,
                                        dispatchChangePage: userDetailPageSlice.actions.changePaginationPage,
                                        count: 5
                                    }}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </main>
    );
}

export default UserDetailTemplate;
