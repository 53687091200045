import {createSlice} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HeaderState {

    /** Profile dropdown is shown **/
    dropdownShownProfile: boolean,
    /** Dial dropdown is shown **/
    dropdownShownDial: boolean,
    /** SMS dropdown is shown **/
    dropdownShownSMS: boolean,
    /** Add new dial contact shown **/
    addContactFormShown: boolean,
}

const initialState: HeaderState = {
    dropdownShownProfile: false,
    dropdownShownDial: false,
    dropdownShownSMS: false,
    addContactFormShown: false,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const headerOrganismSlice = createSlice({
    name: 'headerOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.dropdownShownProfile = false;
            state.dropdownShownDial = false;
            state.dropdownShownSMS = false;
            state.addContactFormShown = false;
        },
        hideDropdownProfile: (state) => {
            state.dropdownShownProfile = false;
        },
        toggleDropdownProfile: (state) => {
            state.dropdownShownProfile = !state.dropdownShownProfile;
        },
        hideDropdownDial: (state) => {
            state.dropdownShownDial = false;
        },
        toggleDropdownDial: (state) => {
            state.dropdownShownDial = !state.dropdownShownDial;
        },
        toggleDropdownSMS: (state) => {
            state.dropdownShownSMS = !state.dropdownShownSMS;
        },
        hideDropdownSMS: (state) => {
            state.dropdownShownSMS = false;
        },
        showAddDialContact: (state) => {
            state.addContactFormShown = true;
        },
        hideAddDialContact: (state) => {
            state.addContactFormShown = false;
        },
    },
})

export default headerOrganismSlice.reducer
