import React, {useEffect} from "react";
import "./BoxUsers.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {EasyBox, EasyBoxItem} from "../../molecules/EasyBox/EasyBox";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {actionGetUserList} from "../../../store/actions/data/userAction";
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {boxUsersOrganismSlice} from "../../../store/slices/organisms/boxUsersSlice";
import {Storage} from "../../../models/utils/Storage";
import {UserDto} from "../../../models/entities/UserDto";
import {useTranslate} from "@tolgee/react";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";

/**
 * @alias BoxUsersPagination
 * @category Components
 */
type BoxUsersPagination = {

    /** Shown count **/
    count: number,
    /** Current page **/
    page: number,
    /** Redux change page dispatch callback **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

type BoxUsersProps = {
    pagination: BoxUsersPagination,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {BoxUsersPagination} pagination - pagination info
 */
export const BoxUsers = ({pagination}: BoxUsersProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const searchState = useAppSelector((state) => state.boxUsersOrganism.search);
    const usersState = useAppSelector((state) => state.userData.userList);
    const storageData = Storage.getSession();

    // Re-load data
    useEffect(() => { if (!usersState) { dispatch(actionGetUserList()); } }, [dispatch, usersState])

    const parseUsers = (users: Array<UserResponseDto>, currentUser: UserDto) : Array<EasyBoxItem> => {

        if (!users) { return users; }
        return users
            .filter((user: UserResponseDto) => {
                return user.User.role !== RoleEnum.HARDWARE && user.User.active && (user.User.id !== currentUser.id);
            })
            .map((user: UserResponseDto) => {
            return {
                searchTags: [user.User.phone, user.User.branch, user.User.email],
                role: user.User.role,
                title: `${user.User.firstName} ${user.User.lastName}`,
                id: user.User.id,
                link: `/user/${user.User.id}`,
            }
        });
    }

    // Loading state
    if (typeof usersState === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD}/> )
    }

    // Error state user
    if (usersState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description", {dataName: t('Base_name_user')})} />
        )
    }

    // Error state session
    if (storageData === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    const outputList = parseUsers(usersState, storageData.user);

    // Empty state
    if (outputList.length === 0) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD}
                title={t('Base_without_data_title')}
                subTitle={t('Base_without_data_subtitle', {dataName: t('Base_name_users')})} />
        )
    }

    return (
        <div className={"BoxUsers"}>
            <EasyBox
                items={outputList}
                title={t('BoxUsers_title')}
                searchDispatch={boxUsersOrganismSlice.actions.updateSearchValue}
                search={searchState}
                pagination={pagination} />
        </div>
    );
}

export default BoxUsers;
