import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {actionGetSensor, actionUpdateSensor} from "../../actions/organisms/editSensorAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface EditSensorState extends AbstractFormReducersState {

    /** Current sensor data **/
    sensorData?: SensorResponseDto|null
}

const initialState: EditSensorState = {
    form: {
        inputs: [
            {name: 'name', validations: {required: false}},
            {name: 'limitMin', validations: {required: false, type: ValidatorItemType.NUMBER}},
            {name: 'limitMax', validations: {required: false, type: ValidatorItemType.NUMBER}},
            {name: 'interval', validations: {required: false, type: ValidatorItemType.NUMBER}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const editSensorOrganismSlice = createSlice({
    name: 'editSensorOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        destroyOrganism: (state: EditSensorState) => { state.sensorData = undefined; },
    }},
    extraReducers: (builder) => {
        builder.addCase(actionGetSensor.fulfilled, (state, {payload}) => {
            state.sensorData = payload;
        })
        builder.addCase(actionUpdateSensor.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('editSensorOrganismSlice-actionUpdateSensor: wrong payload, skip!')
                return;
            }

            state.sensorData = payload;
            state.form.error = null;
        })
    }
})

export default editSensorOrganismSlice.reducer
