import {createSlice} from '@reduxjs/toolkit'
import {
    actionAddFloorToBuildingPlan,
    actionGetInternalBuilding, actionRemoveBuildingFloor,
    actionRemoveBuildingPlan,
    actionResetBuildingPlan,
    actionUpdateBuildingPlan
} from "../../actions/data/buildingAction";
import {InternalBuilding} from "../../../models/utils/AbstractBuildingReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface BuildingState {

    /** Building object **/
    building?: InternalBuilding|null,
}

const initialState: BuildingState = {
    building: undefined,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingDataSlice = createSlice({
    name: 'buildingData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetInternalBuilding.fulfilled, (state, {payload}) => {
            state.building = payload;
        })
        builder.addCase(actionUpdateBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingDataSlice-actionUpdateBuildingPlan: wrong payload, skip!')
                return;
            }

            state.building = payload;
        })
        builder.addCase(actionAddFloorToBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingDataSlice-actionAddFloorToBuildingPlan: wrong payload, skip!')
                return;
            }

            state.building = payload;
        })
        builder.addCase(actionResetBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingDataSlice-actionResetBuildingPlan: wrong payload, skip!')
                return;
            }

            state.building = payload;
        })
        builder.addCase(actionRemoveBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingDataSlice-actionRemoveBuildingPlan: wrong payload, skip!')
                return;
            }

            state.building = payload;
        })
        builder.addCase(actionRemoveBuildingFloor.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingDataSlice-actionRemoveBuildingFloor: wrong payload, skip!')
                return;
            }

            state.building = payload;
        })
    }
})

export default buildingDataSlice.reducer
