import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    actionBanZoneForPatient,
    actionGetBannedZoneList,
    actionGetPatient,
    actionRemovePatientHardwareList,
    actionRemovePatientZoneList
} from "../../actions/data/patientAction";
import {PatientResponseDto} from "../../../models/entities/Responses/PatientResponseDto";
import {actionUpdateAlertState} from "../../actions/organisms/sensorStateAction";
import {actionAssignHardware} from "../../actions/data/hardwareAction";
import {ZoneDto} from "../../../models/entities/ZoneDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface PatientDetailPageState {

    /** Patient data **/
    patient?: PatientResponseDto|null,
    /** Add new HW to patient modal **/
    addPatientHardwareModalShown: boolean,
    /** Add new Zone to patient modal **/
    addPatientZoneModalShown: boolean,
    /** Edit sensor modal shown **/
    editSensorModalShown: boolean,
    /** Edit sensor modal sensor ID **/
    editSensorModalSensorId: number|null,
    /** Edit sensor modal HW ID **/
    editSensorModalHardwareId: number|null,
    /** Edit patient modal shown **/
    editPatientModalShown: boolean,
    /** Edit patient modal patient ID **/
    editPatientModalId: null|number,
    /** List of zones banned for patient **/
    bannedZoneList?: ZoneDto[]|null
}

const initialState: PatientDetailPageState = {
    addPatientHardwareModalShown: false,
    addPatientZoneModalShown: false,
    editSensorModalShown: false,
    editSensorModalHardwareId: null,
    editSensorModalSensorId: null,
    editPatientModalShown: false,
    editPatientModalId: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const patientDetailPageSlice = createSlice({
    name: 'patientDetailPage',
    initialState,
    reducers: {
        destroyPage: (state) => {
            state.patient = undefined;
            state.addPatientHardwareModalShown = false;
            state.addPatientZoneModalShown = false;
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.editPatientModalShown = false;
            state.editPatientModalId = null;
            state.bannedZoneList = undefined;
        },
        showEditPatientModal: (state, action: PayloadAction<{patientId: number}>) => {
            state.addPatientHardwareModalShown = false;
            state.addPatientZoneModalShown = false;
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.editPatientModalShown = true;
            state.editPatientModalId = action.payload.patientId;
        },
        hideEditPatientModal: (state) => {
            state.editPatientModalId = null;
            state.editPatientModalShown = false;
        },
        showEditSensorModal: (state, action: PayloadAction<{sensorId: number, hardwareId: number }>) => {
            state.addPatientHardwareModalShown = false;
            state.addPatientZoneModalShown = false;
            state.editSensorModalShown = true;
            state.editSensorModalSensorId = action.payload.sensorId;
            state.editSensorModalHardwareId = action.payload.hardwareId;
            state.editPatientModalShown = false;
            state.editPatientModalId = null;
        },
        hideEditHardwareModal: (state) => {
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
        },
        showAddPatientHardwareModal: (state) => {
            state.addPatientHardwareModalShown = true;
            state.addPatientZoneModalShown = false;
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.editPatientModalShown = false;
            state.editPatientModalId = null;
        },
        hideAddPatientHardwareModal: (state) => {
            state.addPatientHardwareModalShown = false;
        },
        showAddPatientZoneModal: (state) => {
            state.addPatientHardwareModalShown = false;
            state.addPatientZoneModalShown = true;
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.editPatientModalShown = false;
            state.editPatientModalId = null;
        },
        hideAddPatientZoneModal: (state) => {
            state.addPatientZoneModalShown = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionRemovePatientZoneList.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.bannedZoneList = payload.patientBannedZoneList; }
        })
        builder.addCase(actionBanZoneForPatient.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.bannedZoneList = payload; }
        })
        builder.addCase(actionGetBannedZoneList.fulfilled, (state, {payload}) => {
            state.bannedZoneList = payload;
        })
        builder.addCase(actionGetPatient.fulfilled, (state, {payload}) => {
            state.patient = payload;
        })
        builder.addCase(actionRemovePatientHardwareList.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDetailPageSlice-actionRemovePatientHardwareList: wrong payload, skip!')
                return;
            }

            state.patient = payload.patient;
        })
        builder.addCase(actionAssignHardware.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string" && typeof payload.patient !== 'undefined') { state.patient = payload.patient; }
        })
        builder.addCase(actionUpdateAlertState.fulfilled, (state, {payload}) => {

            if (!state.patient || typeof payload === "string") {
                console.warn('patientDetailPageSlice-actionUpdateAlertState: wrong state or payload, skip!')
                return;
            }

            for (let x = 0; x < state.patient.Hardware.length; x++) {
                for (let y = 0; y < state.patient.Hardware[x].Sensors.length; y++) {
                    if (state.patient.Hardware[x].Sensors[y].Sensor.id === payload.Sensor.id) {
                        state.patient.Hardware[x].Sensors[y].Sensor = payload.Sensor;
                    }
                }
            }
        })
    }
})

export default patientDetailPageSlice.reducer
