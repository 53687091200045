import {createSlice} from '@reduxjs/toolkit'
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {actionGetSensorTypes} from "../../actions/data/sensorAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface SensorState {

    /** List of sensor types **/
    sensorTypes?: Array<SensorTypeDto>|null,
}

const initialState: SensorState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const sensorDataSlice = createSlice({
    name: 'sensorData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetSensorTypes.fulfilled, (state, {payload}) => {
            state.sensorTypes = payload;
        })
    }
})

export default sensorDataSlice.reducer