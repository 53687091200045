import React from "react";
import "./BoxHive.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {EasyBox, EasyBoxItem} from "../../molecules/EasyBox/EasyBox";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {boxHiveOrganismSlice} from "../../../store/slices/organisms/boxHiveSlice";
import {
    AbstractBuildingActiveType,
    AbstractBuildingUpdateActivePayload,
    InternalBuildingBuilding
} from "../../../models/utils/AbstractBuildingReducers";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HiveUtil} from "../../../models/utils/HiveUtil";
import {useTranslate} from "@tolgee/react";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionLocatePatient} from "../../../store/actions/data/patientAction";
import {actionGetHiveFresh} from "../../../store/actions/data/hiveAction";
import compare_func from "compare-func";
import {ZoneDto} from "../../../models/entities/ZoneDto";

/**
 * @alias BoxHivePagination
 * @category Components
 */
type BoxHivePagination = {

    /** Shown count **/
    count: number,
    /** Current page **/
    page: number|null,
    /** Redux on change page callback **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

type BoxHiveProps = {
    building: InternalBuildingBuilding,
    role: RoleEnum,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    activePatient: AbstractBuildingUpdateActivePayload|null,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    hive: HiveResponseDto,
    dispatchChangeActiveHardware: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchChangeActivePatient: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    pagination: BoxHivePagination,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {BoxHivePagination} pagination - pagination info
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchChangeActiveHardware - Redux callback for active HW
 * @param {InternalBuildingBuilding} building - building data
 * @param {RoleEnum} role - user role from session
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - current active hardware
 * @param {HiveResponseDto} hive - hive data
 * @param {SensorTypeDto[]} sensorTypes - sensor types data
 * @param {ZoneDto[]} zoneList - zone list
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchChangeActivePatient - Redux callback for active patient
 * @param {AbstractBuildingUpdateActivePayload|null} activePatient - current active patient
 */
export const BoxHive = ({pagination, dispatchChangeActiveHardware, building, role, activeHardware, activePatient,
    hive, sensorTypes, zoneList, dispatchChangeActivePatient}: BoxHiveProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const searchState = useAppSelector((state) => state.boxHiveOrganism.search);

    // Try to locate the patient
    const handleLocatePatient = async (patientId: number) => {

        const result = await dispatch(actionLocatePatient({patientId: patientId, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }
        dispatch(actionGetHiveFresh());
    }


    // Parse HIVE patient sensors into HiveBlocks data
    const parseHiveAssigned = (hive: HiveResponseDto, sensorTypes: Array<SensorTypeDto>, zoneList: ZoneDto[]) : Array<EasyBoxItem> => {

        if (role === RoleEnum.TECHNICAL) { return []; }
        return HiveUtil.hivePatientsToBoxItems({
            hivePatients: hive.HivePatients,
            sensorTypes: sensorTypes,
            zoneList: zoneList,
            activePatient: activePatient,
            detailCallback: async (patientId: number) => {
                dispatch(dispatchChangeActivePatient({id: patientId, callerType: AbstractBuildingActiveType.BOX_HIVE}));
                await handleLocatePatient(patientId);
            },
        }, t);
    }

    // Parse HIVE free sensors into box item data
    const parseHiveFree = (hive: HiveResponseDto, sensorTypes: Array<SensorTypeDto>, zoneList: Array<ZoneDto>) : Array<EasyBoxItem> => {

        if (role === RoleEnum.MEDICAL) { return []; }
        const hiveHardware = hive.UnassignedHardware.filter((hardware: HardwareWithSensorsResponseDto) => {
            return !(hardware.HardwareTemplate.type === HardwareTypeEnum.WEARABLE && hardware.Patient === null);
        });

        return HiveUtil.hiveHardwareListToBoxItems({
            activeHardware: activeHardware,
            hiveHardware: hiveHardware,
            sensorTypes: sensorTypes,
            zoneList: zoneList,
            building: building,
            detailCallback: async (hardwareId: number) => {
                dispatch(dispatchChangeActiveHardware({id: hardwareId, callerType: AbstractBuildingActiveType.BOX_HIVE}));
            },
        }, t)
    }

    // Sort hive list - make alerts first
    const sortEasyBoxList = (assigned: Array<EasyBoxItem>, free: Array<EasyBoxItem>) : Array<EasyBoxItem> => {
       return assigned.concat(free).sort(compare_func((el: EasyBoxItem) => { return HiveUtil.getPriorityFromHiveType(el.type); })).reverse();
    }

    // Get page for active HW
    const getFinalListActivePage = (finalList: Array<EasyBoxItem>) : number => {

        if (activeHardware === null && activePatient === null) { return pagination.page ?? 0; }
        const foundActiveItemIndex = finalList.findIndex((item: EasyBoxItem) => { return item.active; });
        if (foundActiveItemIndex === -1) { return 0; }
        return Math.ceil((foundActiveItemIndex+1)/pagination.count) - 1;
    }

    const getComponentTitle = () : string => {

        switch (role) {
            case RoleEnum.MEDICAL: return t('Base_name_patients');
            case RoleEnum.TECHNICAL:
                return t('Base_name_hardware_list').charAt(0).toUpperCase()
                + t('Base_name_hardware_list').slice(1);
            default: return t('Base_name_hardwareAll')
        }
    }

    const dataAssigned = parseHiveAssigned(hive, sensorTypes, zoneList);
    const dataFree = parseHiveFree(hive, sensorTypes, zoneList);

    // Empty state
    if (dataAssigned.length === 0 && dataFree.length === 0) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD}
                title={t('Base_without_data_title')}
                subTitle={t('Base_without_data_subtitle', {dataName: t('Base_name_hardware')})} />
        )
    }

    // Get fresh parsed data
    const outputList = sortEasyBoxList(dataAssigned, dataFree);

    return (
        <div className={"BoxHive"}>
            <EasyBox
                items={outputList}
                title={getComponentTitle()}
                searchDispatch={boxHiveOrganismSlice.actions.updateSearchValue}
                search={searchState}
                pagination={{count: pagination.count, page: getFinalListActivePage(outputList), dispatchChangePage: pagination.dispatchChangePage}} />
        </div>
    );
}

export default BoxHive;
