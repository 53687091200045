import { createAsyncThunk } from "@reduxjs/toolkit";
import {PatientRepository} from "../../../models/repositories/PatientRepository";
import {PatientRequestDto} from "../../../models/entities/Requests/PatientRequestDto";
import {PatientResponseDto} from "../../../models/entities/Responses/PatientResponseDto";
import {PatientWithHardwareListResponseDto} from "../../../models/entities/Responses/PatientWithHardwareListResponseDto";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {HardwareRepository} from "../../../models/repositories/HardwareRepository";
import {HardwareUnassignBulkRequestDto} from "../../../models/entities/Requests/HardwareUnassignBulkRequestDto";
import {ZoneUnassignBulkRequestDto} from "../../../models/entities/Requests/ZoneUnassignBulkRequestDto";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Locate patient by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{ hardwareId: number, t: any }} request - patient ID
 * @return {void | null}
 */
export const actionLocatePatient = createAsyncThunk<void | null | string, { patientId: number, t: any }, {rejectValue: string}>(
    "patient/locatePatient",
    async (request: { patientId: number, t: any }, { rejectWithValue }) => {
        try {await PatientRepository.locatePatient(request.patientId, request.t) }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return;
    }
);

/**
 * Get patient by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} patientId - patient id
 * @return {PatientResponseDto | null}
 */
export const actionGetPatient = createAsyncThunk<PatientResponseDto | null, number>(
    "patient/getPatient",
    async (patientId: number) => {
        return await PatientRepository.getPatient(patientId);
    }
);

/**
 * Get patient list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {PatientWithHardwareListResponseDto | null}
 */
export const actionGetPatientList = createAsyncThunk<PatientWithHardwareListResponseDto | null>(
    "patient/getPatientList",
    async () => {
        return await PatientRepository.getPatientList();
    }
);

/**
 * Create new patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientRequest: PatientRequestDto, t: TranslatorType}}
 * @return {{patientList: PatientWithHardwareListResponseDto | null, createdPatient: PatientResponseDto}|string}
 */
export const actionCreatePatient = createAsyncThunk<
    {patientList: PatientWithHardwareListResponseDto | null, createdPatient: PatientResponseDto} | string,
    {patientRequest: PatientRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "patient/createPatient",
    async (payload: {patientRequest: PatientRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        let result = null;
        try { result = await PatientRepository.createPatient(payload.patientRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return { patientList: await PatientRepository.getPatientList(), createdPatient: result };
    }
);

/**
 * Update patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number, patientRequest: PatientRequestDto, t: TranslatorType}}
 * @return {PatientWithHardwareListResponseDto|null|string}
 */
export const actionUpdatePatient = createAsyncThunk<PatientWithHardwareListResponseDto | null | string,
    {patientId: number, patientRequest: PatientRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "patient/updatePatient",
    async (updateRequest: {patientId: number, patientRequest: PatientRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await PatientRepository.updatePatient(updateRequest.patientId, updateRequest.patientRequest, updateRequest.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await PatientRepository.getPatientList();
    }
);

/**
 * Delete patient by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number, t: any}} request - patient delete request
 * @return {PatientWithHardwareListResponseDto|null|string}
 */
export const actionDeletePatient = createAsyncThunk<PatientWithHardwareListResponseDto | null | string, {patientId: number, t: any}, {rejectValue: string}>(
    "patient/deletePatient",
    async (request: {patientId: number, t: any}, { rejectWithValue }) => {
        try { await PatientRepository.deletePatient(request.patientId, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await PatientRepository.getPatientList();
    }
);

/**
 * Undelete patient by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number, t: any}} request - patient activate request
 * @return {PatientWithHardwareListResponseDto|null|string}
 */
export const actionActivatePatient = createAsyncThunk<PatientWithHardwareListResponseDto | null | string, {patientId: number, t: any}, {rejectValue: string}>(
    "patient/activatePatient",
    async (request: {patientId: number, t: any}, { rejectWithValue }) => {
        try { await PatientRepository.activatePatient(request.patientId, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await PatientRepository.getPatientList();
    }
);

/**
 * Remove HW from patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{requestEntity: HardwareUnassignBulkRequestDto, t: TranslatorType, patientId: number}} removePatientHardwareRequest - info to remove HW
 * @return {{hardwareList: HardwareResponseDto[]|null, patientList: PatientWithHardwareListResponseDto|null, patient: PatientResponseDto|null}|string}
 */
export const actionRemovePatientHardwareList = createAsyncThunk<
    {patient: PatientResponseDto, hardwareList: Array<HardwareResponseDto>|null, patientList: PatientWithHardwareListResponseDto|null} | string,
    {requestEntity: HardwareUnassignBulkRequestDto, t: TranslatorType, patientId: number}, {rejectValue: string}>(
    "patient/removePatientHardwareList",
    async (request: {requestEntity: HardwareUnassignBulkRequestDto, t: any, patientId: number}, { rejectWithValue }) => {
        let result = null;
        try { result = await PatientRepository.unAssignHardwareFromPatient(request.patientId, request.requestEntity, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return {
            patient: result,
            hardwareList: await HardwareRepository.getHardwareList(false),
            patientList: await PatientRepository.getPatientList()
        }
    }
);

/**
 * Remove zones from patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{requestEntity: ZoneUnassignBulkRequestDto, t: any, patientId: number}} removePatientHardwareRequest - info to remove HW
 * @return {{patient: PatientResponseDto|null, patientBannedZoneList: Array<ZoneDto>}|string}
 */
export const actionRemovePatientZoneList = createAsyncThunk<
    {patient: PatientResponseDto, patientBannedZoneList: Array<ZoneDto>|null} | string,
    {requestEntity: ZoneUnassignBulkRequestDto, t: TranslatorType, patientId: number}, {rejectValue: string}>(
    "patient/removePatientZoneList",
    async (request: {requestEntity: ZoneUnassignBulkRequestDto, t: TranslatorType, patientId: number}, { rejectWithValue }) => {
        let result = null;
        try { result = await PatientRepository.unAssignZonesFromPatient(request.patientId, request.requestEntity, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return {
            patientBannedZoneList: await PatientRepository.getBannedZonesList(request.patientId),
            patient: result,
        }
    }
);

/**
 * Ban zone for patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, t: TranslatorType, patientId: number}} banRequest - info to ban zone
 * @return {ZoneDto|string}
 */
export const actionBanZoneForPatient = createAsyncThunk<
    ZoneDto[] | string,
    {zoneId: number, t: TranslatorType, patientId: number}, {rejectValue: string}>(
    "patient/banZoneForPatient",
    async (request: {zoneId: number, t: any, patientId: number}, { rejectWithValue }) => {
        try { return await PatientRepository.banZoneForPatient(request.patientId,{zone: request.zoneId}, request.t,); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);

/**
 * Get patient banned zones list
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} patientId - patient ID
 * @return {ZoneDto | null}
 */
export const actionGetBannedZoneList = createAsyncThunk<ZoneDto[] | null, number>(
    "patient/getBannedZoneList",
    async (patientId: number) => {
        return await PatientRepository.getBannedZonesList(patientId);
    }
);
