import {RoleEnum} from "../entities/Enums/RoleEnum";
import {TableCellDataTextColor} from "../../components/atoms/Table/TableColumn";
import adminIcon from "../../images/admin.svg";
import technicalIcon from "../../images/technical.svg";
import medicalIcon from "../../images/medical.svg";
import superUserIcon from "../../images/superAdmin.png";
import {EasyFilterColor} from "../../components/molecules/EasyFilter/EasyFilter";
import {AddOrEditUserRoleColor} from "../../components/organisms/AddOrEditUser/AddOrEditUser";

/**
 * @class
 * @category Utils
 */
export class UserUtil {

    /**
     * Get meta info for role
     * @function
     * @category Utils
     * @param {RoleEnum} role - role
     * @param {any} t - translator object
     */
    public static getRoleFEMetaFromRole(role: RoleEnum, t: any) : {
        text: string,
        id: string,
        cellColor: TableCellDataTextColor,
        filterColor: EasyFilterColor,
        image: any,
        modalLabelColor: AddOrEditUserRoleColor,
    } {

        switch (role) {
            case RoleEnum.SUPERUSER: return {
                text: t('UserUtil_superuser'),
                id: 'yellow',
                cellColor: TableCellDataTextColor.YELLOW,
                image: superUserIcon,
                filterColor: EasyFilterColor.YELLOW,
                modalLabelColor: AddOrEditUserRoleColor.YELLOW,
            };
            case RoleEnum.ADMINISTRATOR: return {
                text: t('UserUtil_admin'),
                id: 'yellow',
                cellColor: TableCellDataTextColor.YELLOW,
                image: adminIcon,
                filterColor: EasyFilterColor.YELLOW,
                modalLabelColor: AddOrEditUserRoleColor.YELLOW,
            };
            case RoleEnum.TECHNICAL: return {
                text: t('UserUtil_technic'),
                id: "blue",
                cellColor: TableCellDataTextColor.BLUE,
                image: technicalIcon,
                filterColor: EasyFilterColor.BLUE,
                modalLabelColor: AddOrEditUserRoleColor.BLUE,
            };
            case RoleEnum.MEDICAL: return {
                text: t('UserUtil_medic'),
                id: "pink",
                cellColor: TableCellDataTextColor.PINK,
                image: medicalIcon,
                filterColor: EasyFilterColor.PINK,
                modalLabelColor: AddOrEditUserRoleColor.PINK,
            };
            case RoleEnum.HARDWARE: return {
                text: t('UserUtil_hardware'),
                id: "default",
                cellColor: TableCellDataTextColor.DEFAULT,
                image: null,
                filterColor: EasyFilterColor.DEFAULT,
                modalLabelColor: AddOrEditUserRoleColor.DEFAULT,
            };
            default: return {
                text: t('UserUtil_unknown'),
                id: "default",
                cellColor: TableCellDataTextColor.DEFAULT,
                image: null,
                filterColor: EasyFilterColor.DEFAULT,
                modalLabelColor: AddOrEditUserRoleColor.DEFAULT,
            };
        }
    }
}