import {configureStore} from '@reduxjs/toolkit'
import mainReducer from './slices/extra/mainSlice'

// Organisms
import buildingManagerOrganismReducer from './slices/organisms/buildingManagerSlice'
import addOrEditHardwareOrganismReducer from './slices/organisms/addOrEditHardwareSlice'
import addOrEditUserOrganismReducer from './slices/organisms/addOrEditUserSlice'
import addOrEditZoneOrganismReducer from './slices/organisms/addOrEditZoneSlice'
import addOrEditPatientOrganismReducer from './slices/organisms/addOrEditPatientSlice'
import sensorEditOrganismReducer from './slices/organisms/editSensorSlice'
import buildingPlanUploadOrganismReducer from './slices/organisms/buildingPlanUploadSlice'
import registerBlockOrganismReducer from './slices/organisms/registerBlockSlice'
import loginBlockOrganismReducer from './slices/organisms/loginBlockSlice'
import filterHardwareOrganismReducer from './slices/organisms/filterHardwareSlice'
import filterHiveOrganismReducer from './slices/organisms/filterHiveSlice'
import filterFloorOrganismReducer from './slices/organisms/filterFloorSlice'
import filterPatientOrganismReducer from './slices/organisms/filterPatientSlice'
import filterAlertsOrganismReducer from './slices/organisms/filterAlertsSlice'
import buildingPlanOrganismReducer from './slices/organisms/buildingPlanSlice'
import createSupportOrganismReducer from './slices/organisms/createSupportSlice'
import hardwareGraphsOrganismReducer from './slices/organisms/hardwareGraphsSlice'
import alertGraphsOrganismReducer from './slices/organisms/alertGraphsSlice'
import listLogOrganismReducer from './slices/organisms/listLogSlice'
import assignHardwareOrganismReducer from './slices/organisms/assignHardwareSlice'
import assignZoneOrganismReducer from './slices/organisms/assignZoneSlice'
import boxHiveOrganismReducer from './slices/organisms/boxHiveSlice'
import boxUsersOrganismReducer from './slices/organisms/boxUsersSlice'
import buildingFullScreenReducer from './slices/organisms/buildingFullScreenSlice'
import cardUserOrganismReducer from './slices/organisms/cardUserSlice'
import bulkImportUploadOrganismReducer from './slices/organisms/bulkImportUploadSlice'
import hardwareScanRequestOrganismReducer from './slices/organisms/hardwareScanRequestSlice'
import resetBlockOrganismReducer from './slices/organisms/resetBlockSlice'
import recoveryBlockOrganismReducer from './slices/organisms/recoveryBlockSlice'
import googleAuthOrganismReducer from './slices/organisms/googleAuthSlice'
import sensorStateOrganismReducer from './slices/organisms/sensorStateSlice'
import alertNoteModalOrganismReducer from './slices/organisms/alertNoteModalSlice'
import headerOrganismReducer from './slices/organisms/headerSlice'
import hardwareCreateDialogOrganismReducer from './slices/organisms/hardwareCreateDialogSlice'
import hardwareCreateDialogManualOrganismReducer from './slices/organisms/hardwareCreateDialogManualSlice'
import hardwareCreateDialogScanOrganismReducer from './slices/organisms/hardwareCreateDialogScanSlice'
import dialModalOrganismReducer from './slices/organisms/dialModalSlice'
import addDialOrganismReducer from './slices/organisms/addDialSlice'
import patientSensorsOrganismReducer from './slices/organisms/patientSensorsSlice'
import hardwareStatesOrganismReducer from './slices/organisms/hardwareStatesSlice'
import bulkListZoneHardwareOrganismReducer from './slices/organisms/bulkListZoneHardwareSlice'
import bulkListPatientHardwareOrganismReducer from './slices/organisms/bulkListPatientHardwareSlice'
import bulkListPatientZonesOrganismReducer from './slices/organisms/bulkListPatientZonesSlice'
import userNotifyOptionsOrganismReducer from './slices/organisms/userNotifyOptionsSlice'
import smsMenuOrganismReducer from './slices/organisms/smsMenuSlice'

// Data
import zoneDataReducer from './slices/data/zoneSlice'
import hardwareDataReducer from './slices/data/hardwareSlice'
import hardwareTemplateDataReducer from './slices/data/hardwareTemplateSlice'
import alertDataReducer from './slices/data/alertSlice'
import phoneDataReducer from './slices/data/phoneSlice'
import userDataReducer from './slices/data/userSlice'
import sensorDataReducer from './slices/data/sensorSlice'
import patientDataReducer from './slices/data/patientSlice'
import hiveDataReducer from './slices/data/hiveSlice'
import buildingDataReducer from './slices/data/buildingSlice'

// Pages
import userPageReducer from './slices/pages/userPageSlice'
import hardwarePageReducer from './slices/pages/hardwarePageSlice'
import patientPageReducer from './slices/pages/patientPageSlice'
import dashboardPageReducer from './slices/pages/dashboardPageSlice'
import alertsPageReducer from './slices/pages/alertsPageSlice'
import buildingConstructorPageReducer from './slices/pages/buildingConstructorSlice'
import buildingManagerPageReducer from './slices/pages/buildingManagerSlice'
import buildingObserverPageReducer from './slices/pages/buildingObserverSlice'
import alertDetailPageReducer from './slices/pages/alertDetailPageSlice'
import hardwareDetailPageReducer from './slices/pages/hardwareDetailPageSlice'
import patientDetailPageReducer from './slices/pages/patientDetailPageSlice'
import welcomePageReducer from './slices/pages/welcomePageSlice'
import userDetailPageReducer from './slices/pages/userDetailPageSlice'
import createHardwarePageReducer from './slices/pages/createHardwarePageSlice'
import accountsPageReducer from './slices/pages/accountPageSlice'
import zonesPageReducer from './slices/pages/zonesPageSlice'
import zoneDetailPageReducer from './slices/pages/zoneDetailPageSlice'

import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }
    ),
    reducer: {
        main: mainReducer,

        // Organisms
        smsMenuOrganism: smsMenuOrganismReducer,
        addOrEditUserOrganism: addOrEditUserOrganismReducer,
        addOrEditZoneOrganism: addOrEditZoneOrganismReducer,
        addOrEditHardwareOrganism: addOrEditHardwareOrganismReducer,
        addOrEditPatientOrganism: addOrEditPatientOrganismReducer,
        sensorEditOrganism: sensorEditOrganismReducer,
        registerBlockOrganism: registerBlockOrganismReducer,
        loginBlockOrganism: loginBlockOrganismReducer,
        filterHardwareOrganism: filterHardwareOrganismReducer,
        buildingPlanOrganism: buildingPlanOrganismReducer,
        filterHiveOrganism: filterHiveOrganismReducer,
        filterPatientOrganism: filterPatientOrganismReducer,
        filterAlertsOrganism: filterAlertsOrganismReducer,
        filterFloorOrganism: filterFloorOrganismReducer,
        createSupportOrganism: createSupportOrganismReducer,
        hardwareGraphsOrganism: hardwareGraphsOrganismReducer,
        alertGraphsOrganism: alertGraphsOrganismReducer,
        listLogOrganism: listLogOrganismReducer,
        assignHardwareOrganism: assignHardwareOrganismReducer,
        assignZoneOrganism: assignZoneOrganismReducer,
        buildingPlanUploadOrganism: buildingPlanUploadOrganismReducer,
        boxHiveOrganism: boxHiveOrganismReducer,
        boxUsersOrganism: boxUsersOrganismReducer,
        buildingFullScreenOrganism: buildingFullScreenReducer,
        buildingManagerOrganism: buildingManagerOrganismReducer,
        cardUserOrganism: cardUserOrganismReducer,
        bulkImportUploadOrganism: bulkImportUploadOrganismReducer,
        hardwareScanRequestOrganism: hardwareScanRequestOrganismReducer,
        resetBlockOrganism: resetBlockOrganismReducer,
        recoveryBlockOrganism: recoveryBlockOrganismReducer,
        googleAuthOrganism: googleAuthOrganismReducer,
        sensorStateOrganism: sensorStateOrganismReducer,
        alertNoteModalOrganism: alertNoteModalOrganismReducer,
        headerOrganism: headerOrganismReducer,
        hardwareCreateDialogOrganism: hardwareCreateDialogOrganismReducer,
        hardwareCreateDialogManualOrganism: hardwareCreateDialogManualOrganismReducer,
        hardwareCreateDialogScanOrganism: hardwareCreateDialogScanOrganismReducer,
        dialModalOrganism: dialModalOrganismReducer,
        addDialOrganism: addDialOrganismReducer,
        patientSensorsOrganism: patientSensorsOrganismReducer,
        hardwareStatesOrganism: hardwareStatesOrganismReducer,
        bulkListZoneHardwareOrganism: bulkListZoneHardwareOrganismReducer,
        bulkListPatientHardwareOrganism: bulkListPatientHardwareOrganismReducer,
        bulkListPatientZonesOrganism: bulkListPatientZonesOrganismReducer,
        userNotifyOptionsOrganism: userNotifyOptionsOrganismReducer,

        // Data
        zoneData: zoneDataReducer,
        hardwareData: hardwareDataReducer,
        hardwareTemplateData: hardwareTemplateDataReducer,
        alertData: alertDataReducer,
        phoneData: phoneDataReducer,
        userData: userDataReducer,
        hiveData: hiveDataReducer,
        patientData: patientDataReducer,
        sensorData: sensorDataReducer,
        buildingData: buildingDataReducer,

        // Pages
        alertDetailPage: alertDetailPageReducer,
        hardwareDetailPage: hardwareDetailPageReducer,
        patientDetailPage: patientDetailPageReducer,
        welcomePage: welcomePageReducer,
        dashboardPage: dashboardPageReducer,
        alertsPage: alertsPageReducer,
        hardwarePage: hardwarePageReducer,
        userPage: userPageReducer,
        patientPage: patientPageReducer,
        buildingConstructorPage: buildingConstructorPageReducer,
        buildingManagerPage: buildingManagerPageReducer,
        buildingObserverPage: buildingObserverPageReducer,
        userDetailPage: userDetailPageReducer,
        createHardwarePage: createHardwarePageReducer,
        accountsPage: accountsPageReducer,
        zonesPage: zonesPageReducer,
        zoneDetailPage: zoneDetailPageReducer
    },
    enhancers: (getDefaultEnhancers) => {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
