import React from "react";
import "./TableRow.scss"
import {TableColumns} from "./Table";
import {TableCellData, TableColumn} from "./TableColumn";
import {TableDetail} from "./TableDetail";
import {TableTitle} from "./TableTitle";
import {DotType} from "../Dot/Dot";
import {useNavigate} from "react-router-dom";
import {clickedToElementWithClassRecursive} from "../../../models/utils/UIUtil";

/**
 * @interface
 * @category Components
 */
export interface TableRowDataTitle {

    /** Row link redirect **/
    link: string
    /** Row link title **/
    title: string
}

/**
 * @interface
 * @category Components
 */
export interface TableRowData {

    /** Table columns list **/
    columns: Array<TableCellData>,
    /** Row link **/
    link ?: TableRowDataTitle
    /** Row title shown on mobile **/
    rowTitle: string,
    /** Row subtitle shown on mobile **/
    rowSubtitle?: string|null,
    /** Show dot on row as representation of state **/
    rowState?: {type: DotType},
}

type TableRowProps = {
    row: TableRowData,
    columns: TableColumns,
    indexRow: number,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {TableRowData} row - table row data
 * @param {number} indexRow - row index
 * @param {TableColumnData[]} columns - columns data
 */
export const TableRow = ({row, indexRow, columns} : TableRowProps): React.JSX.Element => {

    const [showDetail, setShowDetail] = React.useState(false);
    const navigation = useNavigate();

    // Show row detail on mobile
    const handleToggleDetailCallback = (event: any) : void => {

        event.stopPropagation();
        event.preventDefault();
        setShowDetail(!showDetail);
    }

    // Process link on the row
    const handleProcessLink = (link: string, event: React.MouseEvent<HTMLTableRowElement>) => {

        const target = event.target as HTMLElement;
        const customSelectElement = clickedToElementWithClassRecursive(target, 'CustomSelect');
        const mobileTableElement = clickedToElementWithClassRecursive(target, 'TableTitle');
        if (customSelectElement || mobileTableElement) { return; }
        navigation(link);
    }

    const oddEvenClassName = indexRow % 2 ? 'TableRow-row-even' : 'TableRow-row-odd';

    return (
        <tbody className={showDetail ? 'opened' : ''}>
            <tr
                onClick={(e: React.MouseEvent<HTMLTableRowElement>) => { if (row.link) { handleProcessLink(row.link.link, e); }}}
                className={`TableRow-first ${oddEvenClassName} ${row.link ? 'link' : ''}`}>
                <TableTitle
                    detailShown={showDetail}
                    title={row.rowTitle}
                    subtitle={row.rowSubtitle}
                    state={row.rowState}
                    toggleDetailCallback={handleToggleDetailCallback} />
                {row.columns.map((cellData: TableCellData, indexCell: number) => {
                    return (
                        <TableColumn
                            first={indexCell === 0}
                            key={`column-${indexCell}`}
                            cellData={cellData}
                        />
                    )
                })}
            </tr>
            {showDetail &&
                <tr className={"TableRow-second"}>
                    <td className={"TableRow-second-column"}>
                        <TableDetail
                            rowData={row}
                            headers={columns} />
                    </td>
                </tr>
            }
        </tbody>
    )
}

export default TableRow;
