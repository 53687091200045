/**
 * @enum {string}
 * @category Entities
 * @subcategory Enums
 */
export enum LogEventTypeEnum {

    ALERT = "ALERT",
    HARDWARE = "HARDWARE",
    SENSOR = "SENSOR",
    USER = "USER",
    OTHER = "OTHER",
    PATIENT = "PATIENT"
}