import React from "react";
import "./Image.scss"
import {ImageType} from "../../../models/entities/Utils/Image";

/**
 * @interface
 * @category Components
 */
export interface ImageSize {

    /** Image width **/
    width?: number,
    /** Image height **/
    height?: number
}

type ImageProps = {
    source: ImageType,
    description: string,
    size?: ImageSize,
    className?: string,
    onClick?: (e: React.MouseEvent) => void
}

/**
 * @callback onClick
 * @param {React.MouseEvent} e
 * @return void
 */

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {ImageType} source - image source
 * @param {string} description - description of the image
 * @param {ImageSize|undefined} size - image size
 * @param {string|undefined} className - image class name
 * @param {onClick|undefined} onClick - onclick callback
 */
export const Image = ({source, description, size, className, onClick} : ImageProps): React.JSX.Element => {

    return (
        <img
            className={className}
            onClick={onClick}
            src={source}
            alt={description}
            height={size ? size.height : undefined}
            width={size ? size.width : undefined}/>
    );
}

export default Image;
