import "./Badge.scss";
import React from "react";

type BadgeProps = {
    text: string
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string} text - text inside badge
 */
export const Badge = ({text} : BadgeProps): React.JSX.Element => {

    return (
        <div className={`Badge`}>
            <div className={`Badge-inside`}>
                <div className={`Badge-inside-text`}>
                    {text}
                </div>
            </div>
        </div>
    );
}

export default Badge;
