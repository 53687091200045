import React from "react";
import {Header} from "../../organisms/Header/Header";
import {ListAlerts} from "../../organisms/ListAlerts/ListAlerts";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {useAppSelector} from "../../../store/hooks";
import {alertsPageSlice} from "../../../store/slices/pages/alertsPageSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import AlertNoteModal from "../../organisms/AlertNoteModal/AlertNoteModal";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type AlertsTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const AlertsTemplate = ({role} : AlertsTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const paginationPageState = useAppSelector((state) => state.alertsPage.paginationPage);
    const filterTypeState = useAppSelector((state) => state.alertsPage.filterType);
    const filterTitleState = useAppSelector((state) => state.alertsPage.filterTitle);
    const sensorTypeState = useAppSelector((state) => state.alertsPage.sensorType);
    const templateIdState = useAppSelector((state) => state.alertsPage.templateId);
    const elevationState = useAppSelector((state) => state.alertsPage.elevation);
    const fullTextValueState = useAppSelector((state) => state.alertsPage.fullTextValue);
    const patientIdState = useAppSelector((state) => state.alertsPage.patientId);
    const filterDateState = useAppSelector((state) => state.alertsPage.filterDate);
    const paginationOrderState = useAppSelector((state) => state.alertsPage.paginationOrder);
    const valueTrendState = useAppSelector((state) => state.alertsPage.valueTrend);
    const noteModalShownState = useAppSelector((state) => state.alertsPage.noteModalShown);
    const noteModalAlertIdState = useAppSelector((state) => state.alertsPage.noteModalAlertId);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('AlertsTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('AlertsTemplate_title')}
                        easySearch={{
                            searchValue: fullTextValueState,
                            dispatchSearchValueUpdate: alertsPageSlice.actions.filterFullText
                        }}
                        breadItems={[
                            {name: t('Navigation_alerts'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <ListAlerts
                                dispatchShownNoteAlertModal={alertsPageSlice.actions.showNoteModal}
                                role={role}
                                pagination={{
                                    showCount: 10,
                                    order: paginationOrderState,
                                    dispatchChangeOrder: alertsPageSlice.actions.changePaginationOrder,
                                    page: paginationPageState,
                                    dispatchChangePage: alertsPageSlice.actions.changePaginationPage
                                }}
                                filter={{
                                    patientId: patientIdState,
                                    fullTextValue: fullTextValueState ? fullTextValueState.toLowerCase() : null,
                                    templateId: templateIdState,
                                    elevation: elevationState,
                                    sensorType: sensorTypeState,
                                    filterType: filterTypeState,
                                    filterTitle: filterTitleState,
                                    filterDate: filterDateState,
                                    valueTrend: valueTrendState,
                                    dispatchFilterElevation: alertsPageSlice.actions.filterElevation,
                                    dispatchFilterDate: alertsPageSlice.actions.filterDate,
                                    dispatchFilterPatient: alertsPageSlice.actions.filterPatient,
                                    dispatchFilterSensor: alertsPageSlice.actions.filterSensor,
                                    dispatchFilterTemplate: alertsPageSlice.actions.filterTemplate,
                                    dispatchFilterAll: alertsPageSlice.actions.destroyFilter,
                                    dispatchFilterValueTrend: alertsPageSlice.actions.filterValueTrend,
                                    dispatchFilterFinished: alertsPageSlice.actions.filterFinished,
                                    dispatchFilterResolved: alertsPageSlice.actions.filterResolved,
                                    dispatchFilterProgress: alertsPageSlice.actions.filterProgress,
                                    dispatchFilterUnread: alertsPageSlice.actions.filterUnread,
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            {noteModalShownState && noteModalAlertIdState &&
                <AlertNoteModal
                    hideModalDispatch={alertsPageSlice.actions.hideNoteModal}
                    alertId={noteModalAlertIdState} />
            }
        </main>
    );
}

export default AlertsTemplate;
