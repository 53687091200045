import React, {useEffect, useRef} from "react";
import "./FilterAlerts.scss"
import arrow from "../../../images/arrowRightSmallGrey.svg"
import {Image} from "../../atoms/Image/Image";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {selectInputState} from "../../../models/utils/AbstractFormReducers";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import {actionGetPatientList} from "../../../store/actions/data/patientAction";
import {PatientSimpleResponseDto} from "../../../models/entities/Responses/PatientSimpleResponseDto";
import {FilterMain} from "../../extras/Filter/FilterMain";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {filterAlertsOrganismSlice} from "../../../store/slices/organisms/filterAlertsSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionGetInternalBuilding} from "../../../store/actions/data/buildingAction";
import {InternalBuildingFloor} from "../../../models/utils/AbstractBuildingReducers";
import {AlertsPageValueTrend} from "../../../store/slices/pages/alertsPageSlice";
import {T, useTranslate} from "@tolgee/react";

type FilterAlertsProps = {
    title: string|null,
    role: RoleEnum,
    sensorTypes: Array<SensorTypeDto>,
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    dispatchFilterPatient: ActionCreatorWithPayload<{patientId: number, title: string}>,
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
    dispatchFilterValueTrend: ActionCreatorWithPayload<{valueTrend: AlertsPageValueTrend, title: string}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{templateId: number, title: string}>} dispatchFilterTemplate - filter by template dispatch
 * @param {ActionCreatorWithPayload<{sensorType: number, title: string}>} dispatchFilterSensor - filter by sensor dispatch
 * @param {ActionCreatorWithPayload<{patientId: number, title: string}>} dispatchFilterPatient - filter by patient dispatch
 * @param {ActionCreatorWithPayload<{elevationId: number, title: string}>} dispatchFilterElevation - filter by elevation
 * @param {ActionCreatorWithPayload<{valueTrend: AlertsPageValueTrend, title: string}>} dispatchFilterValueTrend - filter by value trend
 * @param {string|null} title - filter actual search title
 * @param {SensorTypeDto[]} sensorTypes - sensor types list
 * @param {RoleEnum} role - role user session
 */
export const FilterAlerts = ({dispatchFilterTemplate, dispatchFilterSensor, title, dispatchFilterPatient, sensorTypes, dispatchFilterValueTrend,
    role, dispatchFilterElevation} : FilterAlertsProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const openedState = useAppSelector((state) => state.filterAlertsOrganism.opened);
    const filterTypeState = useAppSelector((state) => state.filterAlertsOrganism.filterType);
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);
    const filterSearchState = useAppSelector((state) => selectInputState(state.filterAlertsOrganism, 'filterSearch'));
    const patientListState = useAppSelector((state) => state.patientData.patientList);
    const buildingState = useAppSelector((state) => state.buildingData.building);

    // Re-load data
    useEffect(() => {
        if (!hardwareTemplateListState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE) { dispatch(actionGetHardwareTemplateList()); }
        else if (!patientListState && filterTypeState === AbstractFilterType.FILTER_PATIENT) { dispatch(actionGetPatientList()); }
        else if (!buildingState && filterTypeState === AbstractFilterType.FILTER_ELEVATION) { dispatch(actionGetInternalBuilding()); }
    }, [dispatch, hardwareTemplateListState, filterTypeState, patientListState, buildingState])

    // Clean-up
    useEffect(() => { return () => { dispatch(filterAlertsOrganismSlice.actions.hideDropDown()); } }, [dispatch])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { if (openedState) { dispatch(filterAlertsOrganismSlice.actions.hideDropDown()); } });

    // Search is initialized
    const searchValue = filterSearchState && filterSearchState.value && filterSearchState.value.toString().length > 0
        ? filterSearchState.value.toString().toLowerCase() : null;

    // Search in templates
    let templateListFiltered = hardwareTemplateListState && searchValue
        ? hardwareTemplateListState.filter((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.name.toLocaleLowerCase().includes(searchValue);
          }) : hardwareTemplateListState;

    // Search in floors
    let elevationsFiltered = buildingState && buildingState.building && searchValue
        ? buildingState.building.floors.filter((floor: InternalBuildingFloor) => {
            return floor.elevation.toString().toLocaleLowerCase().includes(searchValue);
        }) : (!buildingState ? buildingState : (buildingState.building ? buildingState.building.floors : null));


    // Filter templates for roles
    templateListFiltered = templateListFiltered ? templateListFiltered.filter((template: HardwareTemplateResponseDto) => {
        if (role === RoleEnum.TECHNICAL && template.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) { return false; }
        return !(role === RoleEnum.MEDICAL && template.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE);
    }) : templateListFiltered;

    // Search in patients
    const patientListFiltered = patientListState && searchValue
        ? patientListState.Patients.filter((patient: PatientSimpleResponseDto) => {
            const patientName = patient ? `${patient.Patient.firstName} ${patient.Patient.lastName}` : null;
            return patientName && patientName.toLocaleLowerCase().includes(searchValue);
        }) : (patientListState ? patientListState.Patients : patientListState);

    // Search in sensors
    const sensorList = DataParser.getSensorTypesListForSelect(sensorTypes, t);
    const sensorListFiltered = searchValue
        ? sensorList.filter((sensor: {value: number, label: string}) => {
            return sensor.label.toLowerCase().includes(searchValue);
        }) : sensorList;

    return (
        <div className={`FilterAlerts ${openedState ? 'active' : 'hidden'}`} ref={wrapperRef}>
            <FilterMain
                openedState={openedState}
                filterTypeState={filterTypeState}
                title={title}
                filterSearchState={filterSearchState}
                toggleDropDown={ () => { dispatch(filterAlertsOrganismSlice.actions.toggleDropDown()); }}
                updateInput={filterAlertsOrganismSlice.actions.updateInput} />
            <div className="FilterAlerts-content content">
                {openedState && filterTypeState === AbstractFilterType.FILTER_ALL &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-item"
                             onClick={() => {
                                 dispatch(filterAlertsOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_TEMPLATE}));
                             }}>
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_template'} />
                            </div>
                            <div className="FilterAlerts-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterAlerts-content-inside-item"
                             onClick={() => {
                                 dispatch(filterAlertsOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_SENSOR}));
                             }}>
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_sensor'} />
                            </div>
                            <div className="FilterAlerts-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        {role !== RoleEnum.TECHNICAL &&
                            <div className="FilterAlerts-content-inside-item"
                                 onClick={() => {
                                     dispatch(filterAlertsOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_PATIENT}));
                                 }}>
                                <div className="FilterAlerts-content-inside-item-text">
                                    <T keyName={'Base_filter_patient'} />
                                </div>
                                <div className="FilterAlerts-content-inside-item-arrow">
                                    <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                                </div>
                            </div>
                        }
                        <div className="FilterAlerts-content-inside-item"
                             onClick={() => {
                                 dispatch(filterAlertsOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_ELEVATION}));
                             }}>
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_elevation'} />
                            </div>
                            <div className="FilterAlerts-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterAlerts-content-inside-item"
                             onClick={() => {
                                 dispatch(filterAlertsOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_VALUE_TREND}));
                             }}>
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_trend'} />
                            </div>
                            <div className="FilterAlerts-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && typeof templateListFiltered === 'undefined' &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered === null &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'}
                               params={{dataName: t('Base_name_hardware_template_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered &&
                    <div className="FilterAlerts-content-inside">
                        {templateListFiltered.map((template: HardwareTemplateResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterTemplate(
                                            {templateId: template.HardwareTemplate.id, title: template.HardwareTemplate.name}));
                                        dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterAlerts-content-inside-item">
                                    <div className="FilterAlerts-content-inside-item-text">
                                        {template.HardwareTemplate.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_SENSOR &&
                    <div className="FilterAlerts-content-inside">
                        {sensorListFiltered.map((item: {value: number, label: string}, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterSensor({sensorType: item.value, title: item.label}));
                                        dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterAlerts-content-inside-item">
                                    <div className="FilterAlerts-content-inside-item-text">
                                        {item.label}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && typeof patientListFiltered === 'undefined' &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && patientListFiltered === null &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'}
                               params={{dataName: t('Base_name_patient_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && patientListFiltered &&
                    <div className="FilterAlerts-content-inside">
                        {patientListFiltered.map((patient: PatientSimpleResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterPatient({patientId: patient.Patient.id, title: patient.Patient.lastName}));
                                        dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterAlerts-content-inside-item">
                                    <div className="FilterAlerts-content-inside-item-text">
                                        {patient.Patient.firstName} {patient.Patient.lastName}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && typeof elevationsFiltered === 'undefined' &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered === null &&
                    <div className="FilterAlerts-content-inside">
                        <div className="FilterAlerts-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'}
                               params={{dataName: t('Base_name_building')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered &&
                    <div className="FilterAlerts-content-inside">
                        {elevationsFiltered.map((floor: InternalBuildingFloor, index: number) => {
                            return (
                                <div
                                    key={`Filter-elevation-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterElevation({
                                            elevationId: floor.elevation,
                                            title: `${floor.elevation}. ${t('Base_name_floor')}`
                                        }));
                                        dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterAlerts-content-inside-item">
                                    <div className="FilterAlerts-content-inside-item-text">
                                        {floor.elevation}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_VALUE_TREND &&
                    <div className="FilterAlerts-content-inside">
                        <div onClick={() => {
                                dispatch(dispatchFilterValueTrend({valueTrend: AlertsPageValueTrend.RISING, title: `Extreme reached`}));
                                dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                            }}
                            className="FilterAlerts-content-inside-item">
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_extreme'} />
                            </div>
                        </div>
                        <div onClick={() => {
                            dispatch(dispatchFilterValueTrend({valueTrend: AlertsPageValueTrend.LOWERING, title: `Minimum reached`}));
                            dispatch(filterAlertsOrganismSlice.actions.hideDropDown());
                        }}
                             className="FilterAlerts-content-inside-item">
                            <div className="FilterAlerts-content-inside-item-text">
                                <T keyName={'Base_filter_minimum'} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default FilterAlerts;
