/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum BackendCodesEnum {
    BAD_REQUEST = 400,
    VALIDATION_ERROR = 400,
    NOT_FOUND = 404,
    AUTH_ERROR = 401,
    UNAUTHORIZED_ERROR = 403,
    SERVER_ERROR = 399
}