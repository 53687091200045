import React from 'react';
import {App} from "./App";
import {Provider} from "react-redux";
import {store} from './store/store';
import {Storage} from "./models/utils/Storage";
import './styles/layout.scss';
import './libs/ReactToastify.min.css';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import {DevTools, LanguageDetector, Tolgee, TolgeeOptions, TolgeeProvider} from "@tolgee/react";
import { FormatIcu } from '@tolgee/format-icu';
import { initializeApp } from "firebase/app";
import {HelmetProvider} from "react-helmet-async";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT,
    storageBucket: process.env.REACT_APP_FB_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MSID,
    appId: process.env.REACT_APP_FB_APPID,
};

const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENV === "production") {

    const session = Storage.getSession();
    const user = session ? session.user : null;

    Sentry.init({
        dsn: "https://a873da5dfc4b49f39b450d0d0bfda6b0@o381139.ingest.sentry.io/6193194",
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay(),
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            })
        ],
        environment: process.env.REACT_APP_ENV,
        release: `grant-frontend@${process.env.REACT_APP_VERSION}`,
        tracesSampleRate: 0.1,
        normalizeDepth: 10,
        initialScope: {
            user: {
                id: user && user.id ? user.id.toString() : undefined,
                email: user && user.email ? user.email : undefined,
            },
        }
    })
}

const getTolgee = (prod: boolean) => {

    const options : TolgeeOptions = {
        fallbackLanguage: 'en',
        availableLanguages: ['en', 'cs'],
        defaultLanguage: 'cs',
    };

    if (!prod) {
        options.apiKey = process.env.REACT_APP_TOLGEE_API_KEY;
        options.apiUrl = process.env.REACT_APP_TOLGEE_API_URL;
    } else {
        options.staticData = {
            en: () => import('./lang/en.json'),
            cs: () => import('./lang/cs.json'),
        }
    }

    return prod
        ? Tolgee().use(FormatIcu()).use(LanguageDetector()).init(options)
        : Tolgee().use(DevTools()).use(FormatIcu()).init(options);
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <TolgeeProvider
            fallback="Loading..."
            options={{useSuspense: false}}
            tolgee={getTolgee(process.env.REACT_APP_ENV === "production")}>
            <HelmetProvider>
                <App firebase={app}/>
            </HelmetProvider>
        </TolgeeProvider>
    </Provider>
);
