import React from "react";
import "./AlertInfo.scss"
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import limitDown from "../../../images/arrowDown.svg";
import limitUp from "../../../images/arrowUp.svg";
import {Image} from "../../atoms/Image/Image";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {T, useTranslate} from "@tolgee/react";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {useNavigate} from "react-router-dom";

type AlertInfoProps =  {
    alert: AlertResponseDto,
    sensor: SensorResponseDto,
    sensorTypes: Array<SensorTypeDto>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {AlertResponseDto} alert - alert data
 * @param {SensorResponseDto} sensor - sensor data
 * @param {SensorTypeDto[]} sensorTypes - sensor types list
 */
export const AlertInfo = ({alert, sensor, sensorTypes} : AlertInfoProps): React.JSX.Element => {

    const {t} = useTranslate();
    const sensorType = DataParser.getSensorTypeFromList(sensorTypes, alert.SensorTemplate.type);
    const meta = HardwareUtil.getHardwareTypeMetaFromHardwareType(alert.HardwareTemplate.type, t)
    const colorClassFromHardwareType = meta.colorClass;
    const navigation = useNavigate();

    return (
        <div className={"AlertInfo"}>
            <div className="AlertInfo-content">
                <div className="AlertInfo-content-title">
                    <T keyName={'AlertInfo_title'} />
                </div>
            </div>
            <div className="AlertInfo-content">
                <div className="AlertInfo-content-title">
                    <div className="AlertInfo-content-title-left">
                    </div>
                    <div className="AlertInfo-content-parameters-right">
                        <div className="AlertInfo-content-parameters-right-first">
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_id'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        #{alert.Alert.id}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_hardwareType'} />
                                    </div>
                                    <div className={`AlertInfo-content-parameters-right-item-content-value ${colorClassFromHardwareType}`}>
                                        {meta.title}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_template'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {alert.HardwareTemplate.name}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_hardwareId'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value link">
                                        <button onClick={() => {navigation(`/devices/${alert.Hardware.id}`)}}>
                                            {alert.Hardware.internalId ?? alert.Hardware.uid}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_limit'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value flex">
                                        {sensor.Sensor.limitMax !== null &&
                                            <div className="AlertInfo-content-parameters-right-item-content-value-limit">
                                                <div className="AlertInfo-content-parameters-right-item-content-value-limit-arrow">
                                                    <Image source={limitUp} description={t('AlertInfo_maxLimit')}/>
                                                </div>
                                                <div className="AlertInfo-content-parameters-right-item-content-value-limit-value">
                                                    {sensor.SensorTemplate.limitMax} {sensorType ? sensorType.unit : ''}
                                                </div>
                                            </div>
                                        }
                                        {sensor.Sensor.limitMin !== null &&
                                            <div className="AlertInfo-content-parameters-right-item-content-value-limit">
                                                <div className="AlertInfo-content-parameters-right-item-content-value-limit-arrow">
                                                    <Image source={limitDown} description={t('AlertInfo_minLimit')}/>
                                                </div>
                                                <div className="AlertInfo-content-parameters-right-item-content-value-limit-value">
                                                    {sensor.SensorTemplate.limitMin} {sensorType ? sensorType.unit : ''}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_firstOccurrence'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {ValueUtil.getStringFromValue(t, alert.Alert.firstOccurrenceAt)}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_notifiedAt'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {ValueUtil.getStringFromValue(t, alert.Alert.notifiedAt)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="AlertInfo-content-parameters-right-second">
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'Base_name_sensor'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {sensor.Sensor.customName
                                            ?? sensor.SensorTemplate.customName
                                            ?? SensorUtil.getMetaForSensorType(alert.SensorTemplate.type, t).name}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_state'} />
                                    </div>
                                    <div className={`AlertInfo-content-parameters-right-item-content-value 
                                        ${alert.Alert.active ? 'green' : 'red'}`}>
                                        {alert.Alert.active ? t('Base_active') : t('Base_inactive')}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'Base_name_patient'} />
                                    </div>
                                    <div className={`AlertInfo-content-parameters-right-item-content-value ${alert.Patient ? 'link' : ''}`}>
                                        <button onClick={() => { if (alert.Patient) { navigation(`/patient/${alert.Patient?.id}`); }}}>
                                            {alert.Patient ? `${alert.Patient.firstName} ${alert.Patient.lastName}` : '-'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_location'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {alert.BuildingFloor && alert.BuildingFloor.elevation ? (alert.BuildingFloor.elevation + '. floor') : '-'}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_lastOccurrence'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {ValueUtil.getStringFromValue(t, alert.Alert.lastOccurrenceAt)}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                                <div className="AlertInfo-content-parameters-right-item-content">
                                    <div className="AlertInfo-content-parameters-right-item-content-name">
                                        <T keyName={'AlertInfo_readAt'} />
                                    </div>
                                    <div className="AlertInfo-content-parameters-right-item-content-value">
                                        {ValueUtil.getStringFromValue(t, alert.Alert.readAt)}
                                    </div>
                                </div>
                            </div>
                            <div className="AlertInfo-content-parameters-right-item item">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertInfo;
