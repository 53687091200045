import React, {useEffect} from "react";
import Swal from "sweetalert2";
import {TableCellDataAction} from "../../components/atoms/Table/TableColumn";
import {mainSlice} from "../../store/slices/extra/mainSlice";
import {ThunkDispatch} from "@reduxjs/toolkit";

/**
 * @alias OutsideClickTriggerCallback
 * @category Utils
 */
export type OutsideClickTriggerCallback = (event: EventTarget|null) => void;

/**
 * Show confirm dialog
 * @function
 * @category Utils
 * @param callback
 * @param {any} event
 * @param {TableCellDataAction} parameters
 * @param {ThunkDispatch<any, any, any>} dispatch
 */
export const showConfirmDialog = async (
    callback: () => Promise<any>,
    event: any,
    parameters: TableCellDataAction,
    dispatch: ThunkDispatch<any, any, any>): Promise<void> => {

    event.stopPropagation();
    event.preventDefault();

    if (parameters.confirm) {
        Swal.fire({
            title: parameters.confirm.title,
            showCancelButton: true,
            confirmButtonText: `Confirm`,
            denyButtonText: `Decline`,
        }).then(async (result) => {
            if (result.isConfirmed && parameters.confirm) {

                try { await callback() } catch (e: any) {
                    dispatch(mainSlice.actions.setTopLevelErrorMessage({message: e.toString()}));
                    return;
                }

                dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: parameters.confirm.successTitle}));
            }
        })
    } else { try { await callback() } catch (e) { return; } }
}

/**
 * Do something when clicked outside of element
 * @function
 * @category Utils
 * @param {Array<React.MutableRefObject<any>>} ref - reference element
 * @param {EmptyFunc} callback - callback to call
 */
export const useOutsideClickTrigger = (ref: Array<React.MutableRefObject<any>>, callback: OutsideClickTriggerCallback) => {

    useEffect(() => {

        function handleClickOutside(event: Event) {
            let setUp = true;
            ref.forEach((item: React.MutableRefObject<any>) => {
                if (!(item.current && !item.current.contains(event.target))) {
                    setUp = false;
                }
            })
            if (setUp) { callback(event.target); }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside); };
    }, [ref, callback]);
};

export const clickedToElementWithClassRecursive = (element: HTMLElement, className: string) => {

    let currentElement : HTMLElement|null = element;

    while (element.parentNode) {

        if (!currentElement || !currentElement.parentElement) { return null; }
        else { currentElement = currentElement.parentElement; }
        if (currentElement && currentElement.classList.contains(className))
            return currentElement;
    }
    return null;

}

