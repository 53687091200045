import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface RecoveryBlockState extends AbstractFormReducersState {}

const initialState: RecoveryBlockState = {
    form: {
        inputs: [
            {name: 'email', validations: {required: true, type: ValidatorItemType.EMAIL}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const recoveryBlockOrganismSlice = createSlice({
    name: 'recoveryBlockOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}}
})

export default recoveryBlockOrganismSlice.reducer