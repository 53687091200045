import React, {useEffect} from "react";
import "./ListHardware.scss"
import {TableRows} from "../../atoms/Table/Table";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import remove from "../../../images/delete.svg"
import edit from "../../../images/edit.svg"
import {FunctionTable, OrderOptions} from "../../molecules/FunctionTable/FunctionTable";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionActivateHardware, actionAssignHardware, actionDeleteHardware, actionGetHardwareList} from "../../../store/actions/data/hardwareAction";
import {TableCellData, TableCellDataAction, TableCellDataActionTypeEnum} from "../../atoms/Table/TableColumn";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {useNavigate} from "react-router-dom";
import {DotType} from "../../atoms/Dot/Dot";
import hardware from "../../../images/hardware.svg"
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {EasyFilter, EasyFilterItem} from "../../molecules/EasyFilter/EasyFilter";
import {FilterHardware} from "../FilterHardware/FilterHardware";
import {ListHardwareFilterTypes} from "../../../store/slices/pages/hardwarePageSlice";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {actionGetPatientList} from "../../../store/actions/data/patientAction";
import {DataParser} from "../../../models/utils/DataParser";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {PatientWithHardwareListResponseDto} from "../../../models/entities/Responses/PatientWithHardwareListResponseDto";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {actionGetHiveFresh} from "../../../store/actions/data/hiveAction";
import {useTranslate} from "@tolgee/react";
import activate from "../../../images/activateHW.svg";
import {actionGetZoneList} from "../../../store/actions/data/zoneAction";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {TableDetailLinkType} from "../../atoms/Table/TableDetail";

/**
 * @alias ListHardwarePagination
 * @category Components
 */
type ListHardwarePagination = {

    /** Show count **/
    showCount: number,
    /** Current page **/
    page: number,
    /** Current order **/
    order: OrderOptions|null,
    /** Redux action to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
    /** Redux action to change order **/
    dispatchChangeOrder: ActionCreatorWithPayload<OrderOptions>
}

/**
 * @alias ListHardwareFilter
 * @category Components
 */
type ListHardwareFilter = {

    /** Current filtration type **/
    filterType: ListHardwareFilterTypes,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by elevation **/
    elevation: number|null,
    /** Filter by patient **/
    patientId: number|null,
    /** Filter by wearable **/
    wearable: boolean|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by full text value **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by hardware type **/
    type: HardwareTypeEnum|null,
    /** Redux action to filter by owned **/
    dispatchFilterOwned: ActionCreatorWithoutPayload,
    /** Redux action to filter all **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux action to filter by active **/
    dispatchFilterActive: ActionCreatorWithoutPayload,
    /** Redux action to filter by patient **/
    dispatchFilterPatient: ActionCreatorWithPayload<{patientId: number, title: string}>,
    /** Redux action to filter by template **/
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    /** Redux action to filter by wearable **/
    dispatchFilterWearable: ActionCreatorWithPayload<{wearable: boolean, title: string}>,
    /** Redux action to filter by sensor type **/
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    /** Redux action to filter by hardware type **/
    dispatchFilterType: ActionCreatorWithPayload<{type: HardwareTypeEnum, title: string}>,
    /** Redux action to filter by elevation floor **/
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
}

type ListHardwareProps = {
    pagination: ListHardwarePagination,
    filter: ListHardwareFilter,
    role: RoleEnum,
    dispatchShowAddOrEditHardwareModal: ActionCreatorWithPayload<{ hardwareId: number }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @todo more sensors in list are not allowed now (max 3)
 * @param {ListHardwareFilter} filter - filter options
 * @param {ListHardwarePagination} pagination - pagination options
 * @param {ActionCreatorWithPayload<{ hardwareId: number }>} dispatchShowAddOrEditHardwareModal - dispatch to show edit hardware modal
 * @param {RoleEnum} role - user session role
 */
export const ListHardware = ({filter, pagination, dispatchShowAddOrEditHardwareModal, role} : ListHardwareProps): React.JSX.Element => {

    // Redux
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const hardwareList = useAppSelector((state) => state.hardwareData.hardwareList);
    const patientList = useAppSelector((state) => state.patientData.patientList);
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);

    // Re-load data
    useEffect(() => { if (!zoneList) { dispatch(actionGetZoneList()); } }, [dispatch,zoneList])
    useEffect(() => { if (!patientList) { dispatch(actionGetPatientList()); } }, [dispatch,patientList])
    useEffect(() => { if (!hardwareList) { dispatch(actionGetHardwareList()); } }, [dispatch, hardwareList])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Filter hardware list using filter states
    const filterHardwareList = (hardwareList: Array<HardwareResponseDto>) : Array<HardwareResponseDto> => {

        return hardwareList.filter((item: HardwareResponseDto) => {

            if (role === RoleEnum.MEDICAL && item.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE) { return false; }
            if (role === RoleEnum.TECHNICAL && item.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) { return false; }

            const patientName = item.Patient ? `${item.Patient.firstName} ${item.Patient.lastName}` : null;
            if (filter.filterType === ListHardwareFilterTypes.ALL) { return true; }
            else if (filter.filterType === ListHardwareFilterTypes.OWNED) { return item.Hardware.patient !== null; }
            else if (filter.filterType === ListHardwareFilterTypes.ACTIVE) { return item.Hardware.active; }
            else if (filter.filterType === ListHardwareFilterTypes.TEMPLATE && filter.templateId !== null) {
                return item.HardwareTemplate.id === filter.templateId;
            }
            else if (filter.filterType === ListHardwareFilterTypes.ELEVATION && filter.elevation !== null) {
                return item.BuildingFloor !== null && item.BuildingFloor.elevation === filter.elevation;
            }
            else if (filter.filterType === ListHardwareFilterTypes.TYPE && filter.type !== null) {
                return item.HardwareTemplate.type === filter.type;
            }
            else if (filter.filterType === ListHardwareFilterTypes.WEARABLE && filter.wearable !== null) {
                return item.HardwareTemplate.type === HardwareTypeEnum.WEARABLE;
            }
            else if (filter.filterType === ListHardwareFilterTypes.PATIENT && filter.patientId !== null) {
                return item.Patient && item.Patient.id === filter.patientId;
            }
            else if (filter.filterType === ListHardwareFilterTypes.SENSOR && filter.sensorType !== null && item.SensorTypes) {
                return item.SensorTypes.filter((sensor: number) => { return sensor === filter.sensorType; }).length > 0
            }
            else if (filter.filterType === ListHardwareFilterTypes.FULLTEXT && filter.fullTextValue !== null) {
                return item.HardwareTemplate.name.toLowerCase().includes(filter.fullTextValue)
                    || item.HardwareTemplate.model.toLowerCase().includes(filter.fullTextValue)
                    || (patientName && patientName.toLowerCase().includes(filter.fullTextValue))
                    || item.Hardware.uid.toLowerCase().includes(filter.fullTextValue)
                    || (item.Hardware.internalId && item.Hardware.internalId.toLowerCase().includes(filter.fullTextValue));
            }
            return false;
        });
    }

    // Create table format for hardware
    const parseHardwareList = (hardwareList: Array<HardwareResponseDto>, patientList: PatientWithHardwareListResponseDto,
        zoneList: Array<ZoneDto>, sensorTypes: Array<SensorTypeDto>) : TableRows => {

        const patientListOptions = DataParser.getPatientSelectFromList(patientList);
        const zoneListOptions = DataParser.getZoneSelectFromList(zoneList);

        return hardwareList.map((hardware : HardwareResponseDto) => {

            // Edit
            let actions : Array<TableCellDataAction> = [];
            if (dispatchShowAddOrEditHardwareModal) {
                actions.push({
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    title: t('Base_goto_editDetails'),
                    image: edit,
                    linkType: TableDetailLinkType.PRIMARY,
                    trigger: async () => { dispatch(dispatchShowAddOrEditHardwareModal({hardwareId: hardware.Hardware.id})); }
                });
            }

            // Delete
            if (hardware.Hardware.active) {
                actions.push({
                    confirm: {
                        title: t('ListHardware_delete_confirm'),
                        successTitle: t('ListHardware_delete_success',
                            {hardwareName: hardware.Hardware.internalId ?? hardware.Hardware.uid})
                    },
                    linkType: TableDetailLinkType.DELETE,
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    title: t('ListHardware_delete_title'),
                    image: remove,
                    trigger: async () => {
                        const result = await dispatch(actionDeleteHardware({hardwareId: hardware.Hardware.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                        dispatch(actionGetHiveFresh());
                    }
                });
            } else {
                actions.push({
                    confirm: {
                        title: t('ListHardware_activate_question'),
                        successTitle: t('ListHardware_activate_success',
                            {hardwareName: hardware.Hardware.internalId ?? hardware.Hardware.uid})
                    },
                    linkType: TableDetailLinkType.PRIMARY,
                    type: TableCellDataActionTypeEnum.SECONDARY,
                    title: t('ListHardware_activate_title'),
                    image: activate,
                    trigger: async () => {
                        const result = await dispatch(actionActivateHardware({hardwareId: hardware.Hardware.id, t:t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                        dispatch(actionGetHiveFresh());
                    }
                });
            }

            // Init first columns
            const hardwareTypeMetas = HardwareUtil.getHardwareTypeMetaFromHardwareType(hardware.HardwareTemplate.type, t);
            const columns : Array<TableCellData> = [
                {dot: {
                    type: hardware.Hardware.active ? DotType.SUCCESS : DotType.ERROR,
                    stateName: t(DotType.SUCCESS ? 'Base_state_active' : 'Base_state_inactive')
                }},
                {text: hardware.Hardware.internalId ?? hardware.Hardware.uid},
                {colorText: {text: hardwareTypeMetas.title, color: hardwareTypeMetas.cellColor}},
                {text: hardware.HardwareTemplate.name}
            ];

            // Patient select
            if (hardware.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) {
                columns.push(
                    {select: {
                        options: patientListOptions,
                        clearable: true,
                        name: 'owner',
                        defaultText: hardware.Patient ? `${hardware.Patient.firstName} ${hardware.Patient.lastName}` : null,
                        placeholder: t('ListHardware_select_patient_placeholder'),
                        default: hardware.Hardware.patient,
                        callback: (async (inputName: string, inputValue: string | number | null) => {
                            await hardwareAssignCallback(
                                hardware.Hardware.id,
                                inputValue ? parseInt(inputValue.toString()) : null,
                                null,
                                null,
                                hardware.Hardware.patient
                            )
                        })
                    }}
                )
            }
            else {
                columns.push(
                    {select: {
                        options: zoneListOptions,
                        clearable: true,
                        name: 'zone',
                        defaultText: hardware.Zone ? hardware.Zone.name : null,
                        placeholder: t('ListHardware_select_zone_placeholder'),
                        default: hardware.Zone?.id,
                        callback: (async (inputName: string, inputValue: string | number | null) => {
                            await hardwareAssignCallback(
                                hardware.Hardware.id,
                                null,
                                inputValue ? parseInt(inputValue.toString()) : null,
                                hardware.Zone?.id ?? null,
                                null
                            )
                        })
                    }}
                )
            }

            // Finish adding columns
            let sensorImages = SensorUtil.getImagesFromSensorTypesNumbers(hardware.SensorTypes, sensorTypes, t);
            columns.push({sensors: sensorImages});
            columns.push({actions: actions});

            // Construct object
            return {
                link: {link: "/devices/" + hardware.Hardware.id, title: t('ListHardware_gotoHardwareDetail')},
                rowTitle: `${hardware.Hardware.internalId ?? hardware.Hardware.uid}`,
                rowSubtitle: hardware.HardwareTemplate.type === HardwareTypeEnum.WEARABLE
                    ? hardware.Patient ? `${hardware.Patient.firstName} ${hardware.Patient.lastName}` : t('ListHardware_not_assigned')
                    : hardwareTypeMetas.title,
                rowState: {type: hardware.Hardware.active ? DotType.SUCCESS : DotType.ERROR},
                columns: columns,
            }
        });
    }

    const getEasyFilterItems = () : Array<EasyFilterItem> => {

        let itemList : Array<EasyFilterItem> = [
            {title: t('ListHardware_filter_active'),
                active: filter.filterType === ListHardwareFilterTypes.ACTIVE,
                dispatchFilterChange: filter.dispatchFilterActive
            },
        ]

        itemList.push({
            title: t('ListHardware_filter_all'),
            active: filter.filterType === ListHardwareFilterTypes.ALL,
            dispatchFilterChange: filter.dispatchFilterAll
        });

        if (role === RoleEnum.ADMINISTRATOR) {
            itemList.push({
                title: t('ListHardware_filter_owned'),
                active: filter.filterType === ListHardwareFilterTypes.OWNED,
                dispatchFilterChange: filter.dispatchFilterOwned}
            );
        }

        if (role === RoleEnum.ADMINISTRATOR) {
            const meta = HardwareUtil.getHardwareTypeMetaFromHardwareType(HardwareTypeEnum.WEARABLE, t);
            itemList.push({
                title: meta.title,
                color: meta.filterColor,
                active: filter.filterType === ListHardwareFilterTypes.TYPE && filter.type === HardwareTypeEnum.WEARABLE,
                callBackFilterChange: () => {
                    dispatch(filter.dispatchFilterType({
                        type: HardwareTypeEnum.WEARABLE,
                        title: meta.title}));
                }
            });
        }

        if (role !== RoleEnum.MEDICAL) {

            const metaStatic = HardwareUtil.getHardwareTypeMetaFromHardwareType(HardwareTypeEnum.STATIC, t);
            const metaGateway = HardwareUtil.getHardwareTypeMetaFromHardwareType(HardwareTypeEnum.GATEWAY, t);
            const metaRepeater = HardwareUtil.getHardwareTypeMetaFromHardwareType(HardwareTypeEnum.REPEATER, t);

            itemList.push({
                title: metaStatic.title,
                color: metaStatic.filterColor,
                active: filter.filterType === ListHardwareFilterTypes.TYPE && filter.type === HardwareTypeEnum.STATIC,
                callBackFilterChange: () => {
                    dispatch(filter.dispatchFilterType({
                        type: HardwareTypeEnum.STATIC,
                        title: metaStatic.title}));
                }
            },
            {
                title: metaGateway.title,
                color: metaGateway.filterColor,
                active: filter.filterType === ListHardwareFilterTypes.TYPE && filter.type === HardwareTypeEnum.GATEWAY,
                callBackFilterChange: () => {
                    dispatch(filter.dispatchFilterType({
                        type: HardwareTypeEnum.GATEWAY,
                        title: metaGateway.title}));
                }
            },
            {
                title: metaRepeater.title,
                color: metaRepeater.filterColor,
                active: filter.filterType === ListHardwareFilterTypes.TYPE && filter.type === HardwareTypeEnum.REPEATER,
                callBackFilterChange: () => {
                    dispatch(filter.dispatchFilterType({
                        type: HardwareTypeEnum.REPEATER,
                        title: metaRepeater.title}));
                }
            });
        }

        return itemList;
    }

    // Callback to assign hardware and process result
    const hardwareAssignCallback = async (hardwareId: number, patientId: number|null, zoneId: number|null,
        defaultZoneId: number|null, defaultPatientId: number|null) => {

        const result = await dispatch(actionAssignHardware({
            t: t,
            patientId: defaultPatientId,
            zoneId: defaultZoneId,
            hardwareId: hardwareId,
            hardwareAssignRequest: {patient: patientId, zone: zoneId}
        }));

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }
        dispatch(actionGetHiveFresh());
    }

    // Loading state
    if (typeof hardwareList === "undefined" || typeof sensorTypes === "undefined" || typeof patientList === "undefined"
        || typeof zoneList === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state hardware list
    if (hardwareList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware_list')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    // Error state patients
    if (patientList === null || zoneList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_patient_list')})} />
        )
    }

    // Prepare table output data
    const parsedHardwareList = parseHardwareList(filterHardwareList(hardwareList), patientList, zoneList, sensorTypes);
    const filterItems = getEasyFilterItems();

    return (
        <div className={'ListHardware'}>
            <div className="ListHardware-functions">
                <div className="ListHardware-functions-left">
                    <EasyFilter items={filterItems} />
                </div>
                <div className="ListHardware-functions-right">
                    <div className="ListHardware-functions-right-item">
                        <div className="ListHardware-functions-right-item-button">
                            <Button
                                image={hardware}
                                onClick={() => navigation('/devices/create')}
                                text={t('ListHardware_button_create')}
                                type={ButtonType.CREATE}/>
                        </div>
                    </div>
                    <div className="ListHardware-functions-right-item">
                        <FilterHardware
                            role={role}
                            sensorTypes={sensorTypes}
                            title={filter.filterTitle}
                            dispatchFilterType={filter.dispatchFilterType}
                            dispatchFilterElevation={filter.dispatchFilterElevation}
                            dispatchFilterWearable={filter.dispatchFilterWearable}
                            dispatchFilterPatient={filter.dispatchFilterPatient}
                            dispatchFilterSensor={filter.dispatchFilterSensor}
                            dispatchFilterTemplate={filter.dispatchFilterTemplate}/>
                    </div>
                </div>
            </div>
            <div className="ListHardware-table">
                <FunctionTable
                    empty={{
                        title: t('ListHardware_empty_title'),
                        subTitle: t('ListHardware_empty_subtitle')
                    }}
                    fePagination={{
                        size: pagination.showCount,
                        page: pagination.page,
                        dispatchChangePage: pagination.dispatchChangePage,
                        dispatchChangeOrder: pagination.dispatchChangeOrder,
                        order: pagination.order,
                    }}
                    data={{rows: parsedHardwareList, columns: [
                        {name: t('ListHardware_col_state'), state: true},
                        {name: t('ListHardware_col_identification')},
                        {name: t('ListHardware_col_type')},
                        {name: t('ListHardware_col_hardware')},
                        {name: t('ListHardware_col_owner_zone')},
                        {name: t('ListHardware_col_sensors'), forceDisableOrder: true},
                        {name: t('ListHardware_col_actions'), action: true}
                    ]}} />
            </div>
        </div>
    );
}

export default ListHardware;
