import {Communicator, RequestType} from "../utils/Communicator";
import {TranslatorType} from "../entities/Utils/Translator";
import {SMSSimpleMessageRequestDto} from "../entities/Requests/SMSSimpleMessageRequestDto";

/**
 * @class
 * @category Repositories
 */
export class SMSRepository {

    /**
     * Create new support message
     * @param {SMSSimpleMessageRequestDto} request - SMS message content
     * @param {TranslatorType} t - translator object
     */
    public static async sendSMSMessage(request: SMSSimpleMessageRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "sms",
            body: request
        });

        if (response.ok) { return; }
        throw new Error(t('SMSRepository_sendSMSMessage'));
    }
}
