import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface RegisterBlockState extends AbstractFormReducersState {

    /** Current register step **/
    phase: RegisterBlockViewType
}

export enum RegisterBlockViewType {
    IDENTIFY,
    COMPANY,
    USER
}

const initialState: RegisterBlockState = {
    phase: RegisterBlockViewType.IDENTIFY,
    form: {
        inputs: [
            {name: 'firstName', validations: {required: true}},
            {name: 'lastName', validations: {required: true}},
            {name: 'email', validations: {required: true, type: ValidatorItemType.EMAIL}},
            {name: 'password', validations: {required: true, minLength: 8}},
            {name: 'passwordAgain', validations: {required: true, minLength: 8}},
            {name: 'companyAddress', validations: {required: true}},
            {name: 'companyName', validations: {required: true}},
            {name: 'vat', validations: {required: true, minLength: 10}},
            {name: 'companyId', validations: {required: true, type: ValidatorItemType.NUMBER, minLength: 8}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const registerBlockOrganismSlice = createSlice({
    name: 'registerBlockOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        setPhase: (state: RegisterBlockState, action: PayloadAction<{viewType: RegisterBlockViewType }>) => {
            state.phase = action.payload.viewType;
        },
    }},
})

export default registerBlockOrganismSlice.reducer