import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {actionGetBannedZoneList, actionGetPatient} from "../../store/actions/data/patientAction";
import {patientDetailPageSlice} from "../../store/slices/pages/patientDetailPageSlice";
import {PatientDetailTemplate} from "../templates/PatientDetailTemplate/PatientDetailTemplate";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {actionGetSensorTypes} from "../../store/actions/data/sensorAction";
import {Storage} from "../../models/utils/Storage";
import {useTranslate} from "@tolgee/react";
import {actionGetZoneList} from "../../store/actions/data/zoneAction";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const PatientDetailPage = (): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = Storage.getSession()?.user.role;
    const {t, isLoading} = useTranslate();

    // Initialize states
    const patientState = useAppSelector((state) => state.patientDetailPage.patient);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);
    const bannedZoneList = useAppSelector((state) => state.patientDetailPage.bannedZoneList);

    // Dispatch data load & clean data
    useEffect(() => { if (params.id) { dispatch(actionGetBannedZoneList(parseInt(params.id))); }}, [params, dispatch]);
    useEffect(() => { if (params.id) { dispatch(actionGetPatient(parseInt(params.id))); } }, [dispatch, params]);
    useEffect(() => { return () => { dispatch(patientDetailPageSlice.actions.destroyPage()); } }, [dispatch])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])
    useEffect(() => { if (!zoneList) { dispatch(actionGetZoneList()); } }, [dispatch, zoneList])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    // Check & Wait for required data
    if (!params.id) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_not_found_title")}
            subTitle={t("Base_errors_page_not_found_description")} />
    }

    if (typeof patientState === "undefined" || typeof bannedZoneList === "undefined" || typeof sensorTypes === "undefined"
        || typeof zoneList === "undefined"  || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (patientState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_patient')})} />
    }

    if (sensorTypes === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_sensor_types')})} />
    }

    if (zoneList === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_zone_list')})} />
    }

    if (bannedZoneList === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_banned_zone_list')})} />
    }

    return (
        <PatientDetailTemplate
            bannedZonesList={bannedZoneList}
            role={role}
            zoneList={zoneList}
            sensorTypes={sensorTypes}
            patient={patientState} />
    )
};

export default PatientDetailPage;
