import {Communicator, RequestType} from "../utils/Communicator";
import {HiveResponseDto} from "../entities/Responses/HiveResponseDto";
import {CacheExpiry} from "../utils/Storage";

/**
 * @class
 * @category Repositories
 */
export class HiveRepository {

    /**
     * Get HIVE data
     * @param {boolean} useCache - use cache or get fresh data
     */
    public static async getHive(useCache: boolean) : Promise<HiveResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            cache: {cacheKey: 'getHive', expiry: CacheExpiry.FIVE_SECONDS, useCache: useCache},
            endpoint: "hive",
        });

        if (response.ok) { return response.data; }
        return null;
    }
}