import React, {useEffect, useRef} from "react";
import "./FilterHive.scss"
import arrow from "../../../images/arrowRightSmallGrey.svg"
import {Image} from "../../atoms/Image/Image";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {selectInputState} from "../../../models/utils/AbstractFormReducers";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {actionGetPatientList} from "../../../store/actions/data/patientAction";
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import {PatientSimpleResponseDto} from "../../../models/entities/Responses/PatientSimpleResponseDto";
import {FilterMain} from "../../extras/Filter/FilterMain";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {filterHiveOrganismSlice} from "../../../store/slices/organisms/filterHiveSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {actionGetInternalBuilding} from "../../../store/actions/data/buildingAction";
import {InternalBuildingFloor} from "../../../models/utils/AbstractBuildingReducers";
import {T, useTranslate} from "@tolgee/react";

type FilterHiveProps = {
    title: string|null,
    role: RoleEnum,
    sensorTypes: Array<SensorTypeDto>,
    dispatchFilterPatient: ActionCreatorWithPayload<{patientId: number, title: string}>,
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    dispatchFilterWearable: ActionCreatorWithPayload<{wearable: boolean, title: string}>,
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{patientId: number, title: string}>} dispatchFilterPatient - filter by patient dispatch
 * @param {ActionCreatorWithPayload<{sensorType: number, title: string}>} dispatchFilterSensor - filter by sensor dispatch
 * @param {string|null} title - filter title
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {RoleEnum} role - user session role
 * @param {ActionCreatorWithPayload<{wearable: boolean, title: string}>} dispatchFilterWearable - Redux callback to filter wearable
 * @param {ActionCreatorWithPayload<{templateId: number, title: string}>} dispatchFilterTemplate - Redux callback to filter template
 * @param {ActionCreatorWithPayload<{elevationId: number, title: string}>} dispatchFilterElevation - filter by elevation
 * @todo hive patients doesn't have HW template for template filtering (allow only in technical mode for now)
 * @todo hive patients doesn't have HW type for wearable filtering (allow only in technical mode for now)
 */
export const FilterHive = ({dispatchFilterPatient, dispatchFilterSensor, title, sensorTypes, role, dispatchFilterWearable, dispatchFilterElevation,
    dispatchFilterTemplate} : FilterHiveProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const openedState = useAppSelector((state) => state.filterHiveOrganism.opened);
    const filterTypeState = useAppSelector((state) => state.filterHiveOrganism.filterType);
    const patientListState = useAppSelector((state) => state.patientData.patientList);
    const filterSearchState = useAppSelector((state) => selectInputState(state.filterHiveOrganism, 'filterSearch'));
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);
    const buildingState = useAppSelector((state) => state.buildingData.building);

    // Re-load data
    useEffect(() => {
        if (!patientListState && filterTypeState === AbstractFilterType.FILTER_PATIENT) { dispatch(actionGetPatientList()); }
        else if (!hardwareTemplateListState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE) { dispatch(actionGetHardwareTemplateList()); }
        else if (!buildingState && filterTypeState === AbstractFilterType.FILTER_ELEVATION) { dispatch(actionGetInternalBuilding()); }
    }, [dispatch, filterTypeState, patientListState, hardwareTemplateListState, buildingState])

    // Clean-up
    useEffect(() => { return () => { dispatch(filterHiveOrganismSlice.actions.hideDropDown()); } }, [dispatch])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { dispatch(filterHiveOrganismSlice.actions.hideDropDown()); });
    
    // Search is initialized
    const searchValue = filterSearchState && filterSearchState.value && filterSearchState.value.toString().length > 0
        ? filterSearchState.value.toString().toLowerCase() : null;
    
    // Search in patients
    const patientListFiltered = patientListState && searchValue
        ? patientListState.Patients.filter((patient: PatientSimpleResponseDto) => {
            const patientName = patient ? `${patient.Patient.firstName} ${patient.Patient.lastName}` : null;
            return patientName && patientName.toLocaleLowerCase().includes(searchValue);
        }) : (patientListState ? patientListState.Patients : patientListState);

    // Search in sensors
    const sensorList = DataParser.getSensorTypesListForSelect(sensorTypes, t);
    const sensorListFiltered = searchValue
        ? sensorList.filter((sensor: {value: number, label: string}) => {
            return sensor.label.toLowerCase().includes(searchValue);
        }) : sensorList;

    // Search in templates
    let templateListFiltered = hardwareTemplateListState && searchValue
        ? hardwareTemplateListState.filter((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.name.toLocaleLowerCase().includes(searchValue);
        }) : hardwareTemplateListState;

    // Search in floors
    let elevationsFiltered = buildingState && buildingState.building && searchValue
        ? buildingState.building.floors.filter((floor: InternalBuildingFloor) => {
            return floor.elevation.toString().toLocaleLowerCase().includes(searchValue);
        }) : (!buildingState ? buildingState : (buildingState.building ? buildingState.building.floors : null));

    // Filter templates for role
    templateListFiltered = templateListFiltered ? templateListFiltered.filter((template: HardwareTemplateResponseDto) => {
        if (role === RoleEnum.TECHNICAL && template.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) { return false; }
        return !(role === RoleEnum.MEDICAL && template.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE);
    }) : templateListFiltered;

    return (
        <div className={`FilterHive ${openedState ? 'active' : 'hidden'}`} ref={wrapperRef}>
            <FilterMain
                openedState={openedState}
                filterTypeState={filterTypeState}
                title={title}
                filterSearchState={filterSearchState}
                toggleDropDown={ () => { dispatch(filterHiveOrganismSlice.actions.toggleDropDown()); }}
                updateInput={filterHiveOrganismSlice.actions.updateInput} />
            <div className="FilterHive-content content">
                {openedState && filterTypeState === AbstractFilterType.FILTER_ALL &&
                    <div className="FilterHive-content-inside">
                        {role !== RoleEnum.TECHNICAL &&
                            <div className="FilterHive-content-inside-item"
                                 onClick={() => {
                                     dispatch(filterHiveOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_PATIENT}));
                                 }}>
                                <div className="FilterHive-content-inside-item-text">
                                    <T keyName={'Base_filter_patient'} />
                                </div>
                                <div className="FilterHive-content-inside-item-arrow">
                                    <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                                </div>
                            </div>
                        }
                        {role === RoleEnum.TECHNICAL &&
                            <div className="FilterHive-content-inside-item"
                                 onClick={() => {
                                     dispatch(filterHiveOrganismSlice.actions.setFilterType(
                                         {filterType: AbstractFilterType.FILTER_TEMPLATE}
                                     ));
                                 }}>
                                <div className="FilterHive-content-inside-item-text">
                                    <T keyName={'Base_filter_template'} />
                                </div>
                                <div className="FilterHive-content-inside-item-arrow">
                                    <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                                </div>
                            </div>
                        }
                        <div className="FilterHive-content-inside-item"
                             onClick={() => {
                                 dispatch(filterHiveOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_SENSOR}));
                             }}>
                            <div className="FilterHive-content-inside-item-text">
                                <T keyName={'Base_filter_sensor'} />
                            </div>
                            <div className="FilterHive-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterHive-content-inside-item"
                             onClick={() => {
                                 dispatch(filterHiveOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_ELEVATION}));
                             }}>
                            <div className="FilterHive-content-inside-item-text">
                                <T keyName={'Base_filter_elevation'} />
                            </div>
                            <div className="FilterHive-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && typeof patientListFiltered === 'undefined' &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && patientListFiltered === null &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_patient_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_PATIENT && patientListFiltered &&
                    <div className="FilterHive-content-inside">
                        {patientListFiltered.map((patient: PatientSimpleResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterPatient({patientId: patient.Patient.id, title: patient.Patient.lastName}));
                                        dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterHive-content-inside-item">
                                    <div className="FilterHive-content-inside-item-text">
                                        {patient.Patient.firstName} {patient.Patient.lastName}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_SENSOR &&
                    <div className="FilterHive-content-inside">
                        {sensorListFiltered.map((item: {value: number, label: string}, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterSensor({sensorType: item.value, title: item.label}));
                                        dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterHive-content-inside-item">
                                    <div className="FilterHive-content-inside-item-text">
                                        {item.label}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && typeof templateListFiltered === 'undefined' &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered === null &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_hardware_template_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered &&
                    <div className="FilterHive-content-inside">
                        {templateListFiltered.map((template: HardwareTemplateResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterTemplate({
                                            templateId: template.HardwareTemplate.id,
                                            title: template.HardwareTemplate.name
                                        }));
                                        dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterHive-content-inside-item">
                                    <div className="FilterHive-content-inside-item-text">
                                        {template.HardwareTemplate.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_WEARABLE &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-item"
                             onClick={() => {
                                 dispatch(dispatchFilterWearable({wearable: true, title: t('Base_filter_isWearable')}));
                                 dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                             }} >
                            <div className="FilterHive-content-inside-item-text">
                                <T keyName={'Base_filter_isWearable'} />
                            </div>
                        </div>
                        <div className="FilterHive-content-inside-item"
                             onClick={() => {
                                 dispatch(dispatchFilterWearable({wearable: false, title: t('Base_filter_notWearable')}));
                                 dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                             }} >
                            <div className="FilterHive-content-inside-item-text">
                                <T keyName={'Base_filter_notWearable'} />
                            </div>
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && typeof elevationsFiltered === 'undefined' &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered === null &&
                    <div className="FilterHive-content-inside">
                        <div className="FilterHive-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_building')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered &&
                    <div className="FilterHive-content-inside">
                        {elevationsFiltered.map((floor: InternalBuildingFloor, index: number) => {
                            return (
                                <div
                                    key={`Filter-elevation-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterElevation({elevationId: floor.elevation, title: `${floor.elevation}. `
                                                + t('Base_name_floor')}));
                                        dispatch(filterHiveOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterHive-content-inside-item">
                                    <div className="FilterHive-content-inside-item-text">
                                        {floor.elevation}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default FilterHive;
