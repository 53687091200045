import {actionShowNotification} from "../../store/actions/extra/mainSliceAction";
import {NotifyMessage} from "../entities/Utils/Messaging/NotifyMessage";
import {DispatchType} from "../entities/Utils/Redux";
import {TranslatorType} from "../entities/Utils/Translator";
import {SensorUtil} from "./SensorUtil";
import {MessagePayload} from "@firebase/messaging";
import {FcmMessageTypeEnum} from "../entities/Enums/FcmMessageTypeEnum";
import {AlertHardwareMessageDataDto} from "../entities/Utils/Messaging/AlertHardwareMessageDataDto";
import {AlertPatientMessageDataDto} from "../entities/Utils/Messaging/AlertPatientMessageDataDto";
import {AlertCountMessageDataDto} from "../entities/Utils/Messaging/AlertCountMessageDataDto";
import {actionSetActiveCount} from "../../store/actions/data/alertAction";
import notifySound from "../../sounds/notify.mp3";
import {NotifyMessageNotificationData} from "../entities/Utils/Messaging/NotifyMessageNotificationData";
import {AlertNotifyTypeEnum} from "../entities/Enums/AlertNotifyTypeEnum";
import {ToastifyType} from "../../components/extras/Toastify/Toastify";

export class MessagingUtil {

    private readonly dispatch : DispatchType;
    private readonly translator : TranslatorType;
    constructor(dispatch: DispatchType, translator: TranslatorType) {

        this.dispatch = dispatch;
        this.translator = translator;
    }

    /**
     * Resolve message type to handle more types of messages
     * @todo enable logging after migration of notifications
     * @param {MessagePayload} messagePayload - payload from FCM
     * @param {boolean} backendHandler - backend notify handler
     * @param {boolean} blurredWindow - app is hidden
     */
    public resolveMessage(messagePayload: MessagePayload, backendHandler: boolean, blurredWindow: boolean) : NotifyMessage|null {

        if (!messagePayload.data) { return null; }

        if (!messagePayload.data.typeId || !messagePayload.data.json) {
            //Sentry.captureMessage("[FCM] Wrong payload data!",
            //    {tags: {'util' : 'MessagingUtil'}, extra: {messagePayload: messagePayload}});
            console.error('[FCM] Wrong payload data!');
            return null;
        }

        const mpType = parseInt(messagePayload.data.typeId) as FcmMessageTypeEnum;
        const mpData : any = JSON.parse(messagePayload.data.json);

        let notify : NotifyMessage = {type: mpType};
        switch (mpType) {
            case FcmMessageTypeEnum.ALERT_PATIENT:
                notify.notification = this.processPatientAlertNotification(mpData, backendHandler);
                break;
            case FcmMessageTypeEnum.ALERT_HARDWARE:
                notify.notification = this.processHardwareAlertNotification(mpData, backendHandler);
                break;
            case FcmMessageTypeEnum.ALERT_NEW_COUNT:
                this.processAlertCountNotification(mpData);
                break;
            case FcmMessageTypeEnum.SOUND:
                if ((backendHandler && blurredWindow) || (!backendHandler && !blurredWindow)) {
                  this.processSoundNotification();
                }
            break;
        }

        if (!backendHandler && notify.notification) { this.dispatch(actionShowNotification(notify.notification)); }
        return notify;
    }

    /**
     * Get notification info for patient alert
     * @param {AlertPatientMessageDataDto} mp - alert notification info
     * @param {boolean} backendHandler - called by worker
     * @private
     */
    private processPatientAlertNotification(mp: AlertPatientMessageDataDto, backendHandler: boolean) : NotifyMessageNotificationData {

        const title: string = this.translator(`MessagingUtil_newAlarm_title_${mp.notifyType}`);
        const message = this.translator(backendHandler ? 'MessagingUtil_newAlarm_patient_body_worker' : 'MessagingUtil_newAlarm_patient_body', {
            value: SensorUtil.getValueInterpretationForSensor(
                mp.value, mp.valueString, mp.sensorRangeStep ?? 1, mp.sensorTypeId, null, this.translator),
            firstName: mp.patientFirstName,
            lastName: mp.patientLastName,
            sensor: SensorUtil.getMetaForSensorType(mp.sensorTypeId, this.translator).name,
            unit: mp.valueUnit ?? '',
        });

        return {
            subtitle: mp.dataReaderZoneName
                ? this.translator('MessagingUtil_newAlarm_reportedByZone', {
                    reporter: mp.dataReaderInternalId ?? mp.dataReaderUid,
                    zone: mp.dataReaderZoneName })
                : (mp.dataReaderUid || mp.dataReaderInternalId)
                    ? this.translator('MessagingUtil_newAlarm_reportedBy', {reporter: mp.dataReaderInternalId ?? mp.dataReaderUid})
                    : null,
            message: message,
            title: title,
            type: mp.notifyType === AlertNotifyTypeEnum.ESCALATING ? ToastifyType.ERROR : ToastifyType.NOTIFY,
            addressFull: mp.alertUrl,
            address: mp.alertUrl.replace(process.env.REACT_APP_ENDPOINT ?? '', '')
        };
    }

    /**
     * Get notification info for hardware alert
     * @param {AlertHardwareMessageDataDto} mp - alert notification info
     * @param {boolean} backendHandler - called by worker
     * @private
     */
    private processHardwareAlertNotification(mp: AlertHardwareMessageDataDto, backendHandler: boolean) : NotifyMessageNotificationData {

        const title: string = this.translator(`MessagingUtil_newAlarm_title_${mp.notifyType}`);
        const message = this.translator(backendHandler ? 'MessagingUtil_newAlarm_hardware_body_worker' : 'MessagingUtil_newAlarm_hardware_body', {
            value: SensorUtil.getValueInterpretationForSensor(
                mp.value, mp.valueString,mp.sensorRangeStep ?? 1, mp.sensorTypeId, null, this.translator),
            name: mp.hardwareInternalId ?? mp.hardwareUid,
            sensor: SensorUtil.getMetaForSensorType(mp.sensorTypeId, this.translator).name,
            unit: mp.valueUnit ?? '',
        });

        return {
            subtitle: mp.dataReaderZoneName
                ? this.translator('MessagingUtil_newAlarm_reportedByZone', {
                    reporter: mp.dataReaderInternalId ?? mp.dataReaderUid,
                    zone: mp.dataReaderZoneName })
                : (mp.dataReaderUid || mp.dataReaderInternalId)
                    ? this.translator('MessagingUtil_newAlarm_reportedBy', {reporter: mp.dataReaderInternalId ?? mp.dataReaderUid})
                    : null,
            message: message,
            title: title,
            type: mp.notifyType === AlertNotifyTypeEnum.ESCALATING ? ToastifyType.ERROR : ToastifyType.NOTIFY,
            addressFull: mp.alertUrl,
            address: mp.alertUrl.replace(process.env.REACT_APP_ENDPOINT ?? '', '')
        };
    }

    /**
     * Process alert count info
     * @param {AlertCountMessageDataDto} mp - alert count info
     * @private
     */
    private processAlertCountNotification(mp: AlertCountMessageDataDto) : void {
        this.dispatch(actionSetActiveCount(mp.count));
    }

    /**
     * Process sound play
     * @private
     */
    private processSoundNotification() : void {
        void new Audio(notifySound).play().catch(() => {});
    }
}
