import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";
import {actionCreateSupport} from "../../actions/organisms/createSupportAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface CreateSupportState extends AbstractFormReducersState {}

const initialState: CreateSupportState = {
    form: {
        inputs: [
            {name: 'name', validations: {required: true}},
            {name: 'email', validations: {required: true, type: ValidatorItemType.EMAIL}},
            {name: 'subject', validations: {required: true}},
            {name: 'message', validations: {required: true}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const createSupportOrganismSlice = createSlice({
    name: 'createSupportOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}},
    extraReducers: (builder) => {
        builder.addCase(actionCreateSupport.fulfilled, (state) => {
            state.form.error = null;
        })
    }
})

export default createSupportOrganismSlice.reducer