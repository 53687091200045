import React from "react";
import "./DialModal.scss"
import backArrow from "../../../images/backArrow.svg"
import {T, useTranslate} from "@tolgee/react";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import DialMenu from "../DialMenu/DialMenu";
import {dialModalOrganismSlice} from "../../../store/slices/organisms/dialModalSlice";
import AddDial from "../AddDial/AddDial";

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const DialModal = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const dialModalShown = useAppSelector((state) => state.main.dialModalShown);
    const addFormShown = useAppSelector((state) => state.dialModalOrganism.addFormShown);

    return (
        <div className="DialModal">
            {dialModalShown &&
                <div className="DialModal-navigation">
                    <div
                        onClick={ () => {
                            dispatch(dialModalOrganismSlice.actions.destroyOrganism());
                            dispatch(mainSlice.actions.dialModalHide());
                        }}
                        className="DialModal-navigation-back">
                        <div className="DialModal-navigation-back-icon">
                            <img src={backArrow} alt={t('DialModal_back')}  />
                        </div>
                        <div className="DialModal-navigation-back-text">
                            <T keyName={'DialModal_back'} />
                        </div>
                    </div>
                    {!addFormShown
                        ?
                        <div className="DialModal-navigation-inside">
                            <DialMenu dispatchShowAddForm={dialModalOrganismSlice.actions.showAddForm} />
                        </div>
                        :
                        <div className="DialModal-navigation-inside">
                            <AddDial dispatchHide={dialModalOrganismSlice.actions.hideAddForm} />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default DialModal;
