import React, {useEffect} from "react";
import {CreateHardwareTemplate} from "../templates/CreateHardwareTemplate/CreateHardwareTemplate";
import {Storage} from "../../models/utils/Storage";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {useAppDispatch} from "../../store/hooks";
import {createHardwarePageSlice} from "../../store/slices/pages/createHardwarePageSlice";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const CreateHardwarePage = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const role = Storage.getSession()?.user.role;
    const {t} = useTranslate();

    useEffect(() => { dispatch(createHardwarePageSlice.actions.destroyPage()); }, [dispatch])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

        return (
            <CreateHardwareTemplate
                role={role}/>
        )

};

export default CreateHardwarePage;
