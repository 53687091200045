import "./Empty.scss";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import React from "react";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import logo from "../../../images/logo.svg";
import {useTranslate} from "@tolgee/react";

/**
 * @enum {number}
 * @category Components
 */
export enum EmptyTypeEnum {
    MODAL = 1,
    CARD,
    CARD_FLUID,
    FULL,
    FLAT
}

/**
 * @alias EmptyModal
 * @category Components
 */
type EmptyModal = {

    /** Redux hide modal callback **/
    dispatchHide: ActionCreatorWithoutPayload,
    /** Name of the modal **/
    name: string
}

type EmptyProps = {
    type: EmptyTypeEnum,
    modal?: EmptyModal
    title: string,
    subTitle: string
}

/**
 * @component
 * @category Components
 * @subcategory Extras
 * @param {EmptyTypeEnum} type - view type
 * @param {EmptyModal|undefined} modal - modal options
 * @param {string} title - title text
 * @param {string} subTitle - subtitle text
 */
export const Empty = ({type, modal, title, subTitle} : EmptyProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    if (type === EmptyTypeEnum.MODAL && modal) {
        return (
            <div className={`Empty-modal`}>
                <div className={`Empty-modal-content`}>
                    <div className={`Empty-modal-content-title`}>
                        {modal.name &&
                            <div className={`Empty-modal-content-title-text`}>
                                {modal.name}
                            </div>
                        }
                        <div className={`Empty-modal-content-title-close`}>
                            <Image onClick={() => { if (modal.dispatchHide) { dispatch(modal.dispatchHide()); } }}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        </div>
                    </div>
                    <div className={`Empty-modal-content-block`}>
                        <div className={`Empty-modal-content-block-inside`}>
                            <div className="Empty-modal-content-block-inside-title">
                                {title}
                            </div>
                            <div className="Empty-modal-content-block-inside-subTitle">
                                {subTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === EmptyTypeEnum.FULL) {
        return (
            <div className={`Empty-full`}>
                <div className={`Empty-full-content`}>
                    <div className="Empty-full-content-block">
                        <div className="Empty-full-content-block-title">
                            {title}
                        </div>
                        <div className="Empty-full-content-block-subTitle">
                            {subTitle}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === EmptyTypeEnum.FLAT) {
        return (
            <div className={`Empty-flat`}>
                <div className={`Empty-flat-content`}>
                    <div className="Empty-flat-content-block">
                        <div className="Empty-flat-content-block-image">
                            <Image
                                source={logo}
                                description={"Logo"}
                                size={{height: 120, width: 120}} />
                        </div>
                        <div className="Empty-flat-content-block-title">
                            {title}
                        </div>
                        <div className="Empty-flat-content-block-subTitle">
                            {subTitle}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`Empty-card ${type === EmptyTypeEnum.CARD_FLUID ? 'fluid' : 'card'}`}>
            <div className={`Empty-card-content`}>
                <div className="Empty-card-content-block">
                    <div className="Empty-card-content-block-inside">
                        <div className="Empty-card-content-block-inside-image">
                            <Image
                                source={logo}
                                description={"Logo"}
                                size={{height: 120, width: 120}} />
                        </div>
                        <div className="Empty-card-content-block-inside-title">
                            {title}
                        </div>
                        <div className="Empty-card-content-block-inside-subTitle">
                            {subTitle}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Empty;
