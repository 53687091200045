import React, {FormEvent, useEffect} from "react";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {Storage} from "../../../models/utils/Storage";
import "./LoginBlock.scss"
import {useNavigate} from "react-router-dom";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {WelcomePageViewType} from "../../../store/slices/pages/welcomePageSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {loginBlockOrganismSlice} from "../../../store/slices/organisms/loginBlockSlice";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {T, useTranslate} from "@tolgee/react";
import {actionLogIn, actionSendFireBaseToken} from "../../../store/actions/organisms/loginBlockAction";

type LoginBlockProps = {
    dispatchChangeView: ActionCreatorWithPayload<{viewType: WelcomePageViewType}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{viewType: WelcomePageViewType}>} dispatchChangeView - dispatch change view type
 */
export const LoginBlock = ({dispatchChangeView}: LoginBlockProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.loginBlockOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.loginBlockOrganism));
    const email = useAppSelector((state) => selectInputState(state.loginBlockOrganism, 'email'));
    const password = useAppSelector((state) => selectInputState(state.loginBlockOrganism, 'password'));
    const firebaseMessagingToken = useAppSelector((state) => state.main.firebaseMessagingToken);

    const navigate = useNavigate();

    // Cleanup
    useEffect(() => {
        return () => { dispatch(loginBlockOrganismSlice.actions.clearForm({t: t}));
    } }, [dispatch, t])

    // Submit login
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!email || !email.value || !password || !password.value) {
            dispatch(loginBlockOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const resultAuthToken = await dispatch(actionLogIn({email: email.value.toString(), password: password.value.toString(), t: t}));
        if (typeof resultAuthToken.payload === "string") {
            dispatch(loginBlockOrganismSlice.actions.setFormError({error: resultAuthToken.payload}));
            return;
        }

        if (!resultAuthToken.payload) { return; }
        if (firebaseMessagingToken && process.env.REACT_APP_ENABLE_FCM === 'yes') {
            const resultFcmToken = await dispatch(actionSendFireBaseToken(
                {fcmToken: {token: firebaseMessagingToken}, authToken: resultAuthToken.payload.token, t: t}));
            if (typeof resultFcmToken.payload === "string") {
                dispatch(loginBlockOrganismSlice.actions.setFormError({error: resultFcmToken.payload}));
                return;
            }
        }

        dispatch(loginBlockOrganismSlice.actions.setFormError({error: null}));
        Storage.saveSession(resultAuthToken.payload);
        navigate('/dashboard/');
    }

    return (
       <div className="LoginBlock">
           <div className="LoginBlock-header">
               <T keyName={'LoginBlock_title'} ></T>
           </div>
           <div className="LoginBlock-description">
               <T keyName={'LoginBlock_description'} />
           </div>
           <div className="LoginBlock-form">
               <form autoComplete="off" onSubmit={handleSubmit.bind(this)}>
                   <div className="LoginBlock-form-inputs">
                       <InputText
                           placeholder={t('LoginBlock_email_placeholder')}
                           inputName="email"
                           label={t('LoginBlock_email_label')}
                           withoutValidations={true}
                           inputState={email}
                           value={email?.value}
                           inputType={InputTextType.TEXT}
                           dispatchOnChange={loginBlockOrganismSlice.actions.updateInput}
                           dispatchOnFocus={loginBlockOrganismSlice.actions.setFocus}
                           dispatchOnBlur={loginBlockOrganismSlice.actions.removeFocus} />
                       <InputText
                           placeholder={t('LoginBlock_password_placeholder')}
                           inputName="password"
                           withoutValidations={true}
                           value={password?.value}
                           label={t("LoginBlock_password_label")}
                           inputState={password}
                           inputType={InputTextType.PASSWORD}
                           dispatchOnChange={loginBlockOrganismSlice.actions.updateInput}
                           dispatchOnFocus={loginBlockOrganismSlice.actions.setFocus}
                           dispatchOnBlur={loginBlockOrganismSlice.actions.removeFocus} />
                       {formErrorState && <span className="formError">{formErrorState}</span>}
                   </div>
                   <div className="LoginBlock-form-button">
                       <Button
                           options={{textCentered: true, positionCentered: true}}
                           text={t("LoginBlock_button")}
                           type={ButtonType.PRIMARY}
                           onClickDisabled={() => { dispatch(loginBlockOrganismSlice.actions.showInputValidStates()); }}
                           disabled={!formValidState}
                           isSubmit={true} />
                   </div>
               </form>
           </div>

           <div className={"LoginBlock-options"}>
               <div className="LoginBlock-options-item"
                    onClick={() => dispatch(dispatchChangeView({viewType: WelcomePageViewType.REGISTER}))}>
                   <T keyName={'LoginBlock_register'} />
               </div>
               <div className="LoginBlock-options-divider"/>
               <div className="LoginBlock-options-item"
                    onClick={() => dispatch(dispatchChangeView({viewType: WelcomePageViewType.RECOVERY}))}>
                   <T keyName={'LoginBlock_forgotten'} />
               </div>
           </div>
       </div>
    );
}

export default LoginBlock;
