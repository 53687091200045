import {DataParser} from "./DataParser";
import {GraphData, LineTypes} from "../../components/molecules/Graph/Graph";
import {ZoneDto} from "../entities/ZoneDto";
import * as Highcharts from "highcharts";
import {SensorDataChartTypeEnum} from "../entities/Enums/SensorDataChartTypeEnum";
import {SensorAlertStateEnum} from "../entities/Enums/SensorAlertStateEnum";

/**
 * @class
 * @category Utils
 */
export class GraphsUtil {

    /**
     * Callback on X-Range chart on graph load
     * Updates series data for updated categories
     * Removed unused categories / zones
     * We have just one virtual zone sensor so one series
     * 1) get chart Y values
     * 2) get dictionary to translate zone id to category index
     * 3) update series data points using category indexes
     */
    static updateXRangeDataOnChartLoadCallback() : Highcharts.ChartLoadCallbackFunction {
        return function (this: Highcharts.Chart) {

            if (this.series.length !== 1) { return; }

            // Get Y values from series
            let yValues : number[] = (this.series[0].data
                .map((point: Highcharts.Point) => { return point.y })
                .filter((item: number|undefined) => { return typeof item !== "undefined"; }) as number[]);

            const helpDictionary = GraphsUtil.getXRangeHelpTranslateDictionary(yValues);

            const updatedPoints : Highcharts.Point[] = [];
            for (const point of this.series[0].data) {
                if (point.y) { point.y = helpDictionary[point.y]; }
                updatedPoints.push(point);
            }

            this.series[0].setData(updatedPoints);
        }
    }

    /**
     * Get categories array for X-Range
     * 1) get Y values from graph
     * 2) get zone IDs in data
     * 3) translate categories to simple array 0,1,2,3 using number of used zone IDs
     * 4) add zone names to the array 0,1,2,3 -> categories
     * @param graphData - graph input data (we should have just one zone virtual sensor)
     * @param zoneList - list of zones from data redux storage
     */
    static getXRangeCategories(graphData: GraphData[], zoneList: Array<ZoneDto>) : string[] {

        if (graphData.length !== 1) { return [] }

        let yValues : number[] = graphData[0].points.map((value) => { return value.y; })
        const usedZoneIds : number[] = this.getXRangeUsedZonesIds(yValues);

        const categories : string[] = usedZoneIds.includes(0) ? ['NULA'] : [];
        for (let i = 0; i < usedZoneIds.length; i++) {

            const usedZone = DataParser.getZoneFromList(zoneList, usedZoneIds[i]);
            if (!usedZone) { continue; }
            categories.push(usedZone.name);
        }

        return categories;
    }

    /**
     * Get list of used zone IDs in values
     * 1) filter uniq
     * 2) sort by integer value (zone ID)
     * @param yValues - graph Y values
     */
    private static getXRangeUsedZonesIds(yValues: number[]) : number[] {
        return yValues
            .filter(DataParser.onlyUniqueFilterFunction)
            .sort((a: number, b: number) => { return a - b; })
    }

    /**
     * Get dictionary to translate from zoneID to index number in categories
     * 1) get used zone IDs
     * 2) create dist zoneId : indexedCategoryValue
     * @param yValues - graph Y values
     */
    private static getXRangeHelpTranslateDictionary(yValues: number[]) : { [id: number] : number; } {

        // Get list of used zone ids
        const usedZones = this.getXRangeUsedZonesIds(yValues);

        // Get transformation help dictionary zone index 1 = zone id 10
        const helpDictionary: { [id: number] : number; } = {};
        for (let i = 0; i < usedZones.length; i++) {
            helpDictionary[usedZones[i]] = i;
        }

        return helpDictionary;
    }

    /**
     * Convert API chart type to HighChart chart type
     */
    static getChartTypeFromGraphType = (graphType: SensorDataChartTypeEnum) : LineTypes => {

        let output = 'line';
        switch (graphType) {
            case SensorDataChartTypeEnum.SCATTER: output = 'column'; break;
            case SensorDataChartTypeEnum.XRANGE: output = 'xrange'; break;
            default: break;
        } return output as LineTypes;
    }

    /**
     * Parse series for graph
     * 1) check if is prioritized (any alert)
     * 2) make alerting charts red, warning orange, other green
     * 3) construct series for graph
     * @param graphType - current graph type
     * @param seriesData - series data list
     */
    static getSeries = (graphType: SensorDataChartTypeEnum, seriesData: GraphData[]) : Highcharts.SeriesOptionsType[] => {

        const output : Highcharts.SeriesOptionsType[] = [];
        const alertingIndex = seriesData.findIndex((data: GraphData) => { return data.alertState === SensorAlertStateEnum.ALERTING; });
        const resolvingIndex = seriesData.findIndex((data: GraphData) => { return data.alertState === SensorAlertStateEnum.RESOLVED; });
        const priorityIndex = alertingIndex !== -1 ? alertingIndex : (resolvingIndex !== -1 ? resolvingIndex : 0)

        for (let i = 0; i < seriesData.length; i++) {

            const dataItem = seriesData[i];
            const color = dataItem.alertState === SensorAlertStateEnum.ALERTING
                ? '#DC4245'
                : (dataItem.alertState === SensorAlertStateEnum.RESOLVED ? '#e2aa56' : '#3CBE7D')

            output.push({
                tooltip: {
                    valueSuffix: dataItem.unit ? dataItem.unit : undefined,
                    valueDecimals: dataItem.range.step ? 1 / dataItem.range.step : undefined
                },
                name: dataItem.title ? dataItem.title : undefined,
                type: GraphsUtil.getChartTypeFromGraphType(graphType),
                data: dataItem.points,
                color: color,
                visible: graphType === SensorDataChartTypeEnum.XRANGE || i === priorityIndex,
            });
        }

        return output;
    }

    /**
     * Get tooltip formatter function callback
     * Show current value on hover on point
     * @param graphType - type of graph
     */
    static getTooltipFormatterCallback(graphType: SensorDataChartTypeEnum) : Highcharts.TooltipFormatterCallbackFunction {
        return function (this: any) {
            return `
                <div class="tooltip-item-wrapper">
                     <div class="tooltip-item-wrapper-name">
                        <div class="tooltip-item-wrapper-name-square" style="background: ${this.color}">
                        </div>
                        <div class="tooltip-item-wrapper-name-text">
                            ${this.series.name}
                        </div>
                     </div>
                     <div class="tooltip-item-wrapper-value">
                        ${graphType === SensorDataChartTypeEnum.XRANGE ? this.yCategory : this.y}
                        <span class="unit">${this.point.series.tooltipOptions.valueSuffix ?? ''}</span>
                     </div>
                     <div class="tooltip-item-wrapper-date">
                        ${(new Date(this.x)).toLocaleDateString() + ' ' + (new Date(this.x)).toLocaleTimeString()}
                     </div>
                </div>`;
        }
    }

    /**
     * Update legend design above graph
     * Square with name
     */
    static getLabelFormatterCallback() : Highcharts.FormatterCallbackFunction<any> {
        return function (this: Highcharts.Point | Highcharts.Series) {
            return `
                <div class="legend-item-wrapper">
                    <div class="legend-item-wrapper-square" style="background: ${this.color}"></div>
                    <div class="legend-item-wrapper-title">${this.name}</div>
                </div>`;
        }
    }
}
