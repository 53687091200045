import React, {useEffect} from "react";
import {WelcomeTemplate} from "../templates/WelcomeTemplate/WelcomeTemplate";
import {welcomePageSlice} from "../../store/slices/pages/welcomePageSlice";
import {useAppDispatch} from "../../store/hooks";
import {useSearchParams} from "react-router-dom";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const WelcomePage = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => { return () => { dispatch(welcomePageSlice.actions.destroyPage()); } }, [dispatch])

    return ( <WelcomeTemplate
        hash={ searchParams.get("hash") ?  searchParams.get("hash") : null}
        email={ searchParams.get("email") ?  searchParams.get("email") : null} />
    );
}

export default WelcomePage;
