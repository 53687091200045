import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BuildingPlanUploadCallbackUpdateFileInput} from "../../../models/utils/AbstractBuildingReducers";

/**
 * @category Redux
 * @subcategory Slices
 * @interface
 */
export interface BuildingConstructorShowUploadModalProps {

    /** Callback when uploaded file **/
    callBackUpdate: BuildingPlanUploadCallbackUpdateFileInput,
    /** Current floor **/
    floor: number
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface BuildingConstructorState {

    /** Upload modal shown **/
    uploadModal: boolean,
    /** Upload modal elevation **/
    uploadModalFloor: number|null,
    /** Callback on file upload **/
    uploadModalCallBackUpdate: BuildingPlanUploadCallbackUpdateFileInput|null,
}

const initialState: BuildingConstructorState = {
    uploadModal: false,
    uploadModalCallBackUpdate: null,
    uploadModalFloor: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingConstructorPageSlice = createSlice({
    name: 'buildingConstructorPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.uploadModal = false;
            state.uploadModalCallBackUpdate = null;
            state.uploadModalFloor = null;
        },
        hideBuildingPlanUploadModal: state => {
            state.uploadModal = false;
            state.uploadModalCallBackUpdate = null;
            state.uploadModalFloor = null;
        },
        showBuildingPlanUploadModal: (state, action: PayloadAction<BuildingConstructorShowUploadModalProps>) => {
            state.uploadModal = true;
            state.uploadModalCallBackUpdate = action.payload.callBackUpdate;
            state.uploadModalFloor = action.payload.floor;
        },
    },
})

export default buildingConstructorPageSlice.reducer