import React, {useEffect} from "react";
import "./AlertGraphs.scss"
import {Graph, GraphData, GraphPointData} from "../../molecules/Graph/Graph";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {AlertDataResponseDto} from "../../../models/entities/Responses/AlertDataResponseDto";
import {actionGetAlertData} from "../../../store/actions/organisms/alertGraphsAction";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {alertGraphsOrganismSlice} from "../../../store/slices/organisms/alertGraphsSlice";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {AlertDto} from "../../../models/entities/AlertDto";
import {SensorAlertStateEnum} from "../../../models/entities/Enums/SensorAlertStateEnum";
import {T, useTranslate} from "@tolgee/react";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {actionGetZoneList} from "../../../store/actions/data/zoneAction";

type AlertGraphsProps = {
    sensor: SensorResponseDto,
    alert: AlertDto,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {SensorResponseDto} sensorsData - sensors data
 * @param {AlertDto} alert - alert data
 */
export const AlertGraphs = ({sensor, alert} : AlertGraphsProps): React.JSX.Element|null => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const sensorDataState = useAppSelector((state) => state.alertGraphsOrganism.sensorData);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const zoneListState = useAppSelector((state) => state.zoneData.zoneList);

    // Init && destroy
    useEffect(() => { if (typeof zoneListState === 'undefined') { dispatch(actionGetZoneList()); } }, [dispatch, zoneListState])
    useEffect(() => { dispatch(actionGetAlertData(alert.id)); }, [dispatch, alert])
    useEffect(() => { return () => { dispatch(alertGraphsOrganismSlice.actions.destroyOrganism()); } }, [dispatch])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Parse input data for graph
    const parseSensorsDataToGraphData = (sensorDataState: Array<AlertDataResponseDto>, sensorTypes: Array<SensorTypeDto>) : GraphData => {

        const pointValues : GraphPointData = sensorDataState.map((data: AlertDataResponseDto) => {
           return {x: new Date(data.Data.createdAt).getTime(), y: data.Data.value}
        });

        const sensorType = DataParser.getSensorTypeFromList(sensorTypes, sensor.SensorTemplate.type);
        const title = sensor.Sensor.customName ?? SensorUtil.getMetaForSensorType(sensor.SensorTemplate.type, t).name;

        const unit =  sensorType ? sensorType.unit : null;
        return {
            range: {
                min: sensor.SensorTemplate.rangeMin,
                max: sensor.SensorTemplate.rangeMin,
                step: sensor.SensorTemplate.rangeStep ?? undefined
            },
            type: sensor.SensorTemplate.dataChartType,
            alertState: alert.resolved ? SensorAlertStateEnum.RESOLVED : SensorAlertStateEnum.ALERTING,
            points: pointValues,
            title: title,
            unit: unit};
    }

    // Loading hardware
    if (typeof sensorDataState === 'undefined' || typeof sensorTypes === "undefined" || typeof zoneListState === "undefined" || isLoading) {
        return (
            <Loading type={LoadingTypeEnum.CARD} />
        )
    }

    // Error state graph data
    if (sensorDataState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_graphData')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    // Error state zone list
    if (zoneListState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_zone_list')})} />
        )
    }

    // Get parsed graph data
    const graphData = parseSensorsDataToGraphData(sensorDataState, sensorTypes);

    // Error state sensor types
    if (graphData.points.length === 0) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD_FLUID}
                title={t('Base_graph_empty_title')}
                subTitle={t('Base_graph_empty_subtitle')} />
        )
    }

    return (
        <div className="AlertGraphs">
            <div className="AlertGraphs-content">
                <div className="AlertGraphs-content-title">
                    <T keyName={'Base_graph_title'} />
                </div>
                <div className="AlertGraphs-content-graph">
                    <Graph
                        zoneList={zoneListState}
                        showLegend={false}
                        data={[graphData]}/>
                </div>
            </div>
        </div>
    )
}

export default AlertGraphs;
