import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {ListPatient} from "../../organisms/ListPatient/ListPatient";
import {useAppSelector} from "../../../store/hooks";
import {patientPageSlice} from "../../../store/slices/pages/patientPageSlice";
import {AddOrEditPatient} from "../../organisms/AddOrEditPatient/AddOrEditPatient";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type PatientsTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const PatientsTemplate = ({role} : PatientsTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const addOrEditPatientModalShownState = useAppSelector((state) => state.patientPage.addOrEditPatientModalShown);
    const addOrEditPatientModalIdState = useAppSelector((state) => state.patientPage.addOrEditPatientModalId);
    const filterTypeState = useAppSelector((state) => state.patientPage.filterType);
    const fullTextValueState = useAppSelector((state) => state.patientPage.fullTextValue);
    const paginationPageState = useAppSelector((state) => state.patientPage.paginationPage);
    const filterTitleState = useAppSelector((state) => state.patientPage.filterTitle);
    const templateIdState = useAppSelector((state) => state.patientPage.templateId);
    const elevationState = useAppSelector((state) => state.patientPage.elevation);
    const sensorTypeState = useAppSelector((state) => state.patientPage.sensorType);
    const hardwareIdState = useAppSelector((state) => state.patientPage.hardwareId);
    const paginationOrderState = useAppSelector((state) => state.patientPage.paginationOrder);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('PatientsTemplate-title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('PatientsTemplate-title')}
                        easySearch={{
                            searchValue: fullTextValueState,
                            dispatchSearchValueUpdate: patientPageSlice.actions.filterFullText
                        }}
                        breadItems={[
                            {name: t('Navigation_patients'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <ListPatient
                                role={role}
                                filter={{
                                    hardwareId: hardwareIdState,
                                    fullTextValue: fullTextValueState ? fullTextValueState : null,
                                    filterType: filterTypeState,
                                    filterTitle: filterTitleState,
                                    elevation: elevationState,
                                    templateId: templateIdState,
                                    sensorType: sensorTypeState,
                                    dispatchFilterActive: patientPageSlice.actions.destroyFilter,
                                    dispatchFilterAll: patientPageSlice.actions.filterAll,
                                    dispatchFilterSensor: patientPageSlice.actions.filterSensor,
                                    dispatchFilterTemplate: patientPageSlice.actions.filterTemplate,
                                    dispatchFilterHardware: patientPageSlice.actions.filterHardware,
                                    dispatchFilterElevation: patientPageSlice.actions.filterElevation
                                }}
                                pagination={{
                                    showCount: 10,
                                    order: paginationOrderState,
                                    dispatchChangeOrder: patientPageSlice.actions.changePaginationOrder,
                                    page: paginationPageState,
                                    dispatchChangePage: patientPageSlice.actions.changePaginationPage
                                }}
                                dispatchShowAddOrEditPatientModal={patientPageSlice.actions.showEditPatientModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {addOrEditPatientModalShownState &&
                <AddOrEditPatient
                    patientId={addOrEditPatientModalIdState}
                    dispatchHideAddOrEditPatientModal={patientPageSlice.actions.hideAddOrEditPatientModal} />
            }
        </main>
    );
}

export default PatientsTemplate;
