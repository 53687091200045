import {Communicator, RequestType} from "../utils/Communicator";
import {UserResponseDto} from "../entities/Responses/UserResponseDto";
import {CacheExpiry} from "../utils/Storage";
import {TokenResponseDto} from "../entities/Responses/TokenResponseDto";
import { UserCreateRequestDto } from "../entities/Requests/UserCreateRequestDto";
import {UserUpdateRequestDto} from "../entities/Requests/UserUpdateRequestDto";
import {ProfileUpdateRequestDto} from "../entities/Requests/ProfileUpdateRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";
import {NotifyOptionsRequestDto} from "../entities/Requests/NotifyOptionsRequestDto";
import {NotifyOptionsDto} from "../entities/NotifyOptionsDto";

/**
 * @class
 * @category Repositories
 */
export class UserRepository {

    /** Get list of users **/
    public static async getUserList() : Promise<Array<UserResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `admin/user`,
            cache: {cacheKey: "getUserList", expiry: CacheExpiry.FIVE_MINUTES, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Delete user by id
     * @param {number} userId - user id
     * @param {TranslatorType} t - translator object
     */
    public static async deleteUser(userId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `admin/user/${userId}`,
            cache: {deleteKeys: ['getUserList']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('UserRepository_deleteUser'))
    }

    /**
     * Update user by user ID
     * @param {number} userId - user ID
     * @param {UserUpdateRequestDto} userUpdateRequest - info about update
     * @param {TranslatorType} t - translator object
     */
    public static async updateUser(userId: number, userUpdateRequest: UserUpdateRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            body: userUpdateRequest,
            endpoint: `admin/user/${userId}`,
            cache: {deleteKeys: ['getUserList']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('UserRepository_updateUser'))
    }

    /**
     * Get new token
     */
    public static async getToken() : Promise<TokenResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `user/token`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Create new user
     * @param {UserCreateRequestDto} userCreateRequest - info about new user
     * @param {TranslatorType} t - translator object
     */
    public static async createUser(userCreateRequest: UserCreateRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `admin/user`,
            body: userCreateRequest,
            cache: {deleteKeys: ['getUserList']}
        });

        if (response.ok) { return; }
        if (response.apiError?.code === "CREATE_USER_EMAIL_EXISTS") { throw new Error(t('UserRepository_createUserErrEmailExists')); }
        throw new Error('Unexpected error while creating user!');
    }

    /**
     * @param {TranslatorType} t - translator object
     * @param {ProfileUpdateRequestDto} editProfile
     */
    public static async editProfile(editProfile: ProfileUpdateRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            endpoint: `user`,
            body: editProfile,
            cache: {deleteKeys: []}
        });

        if (response.ok) { return; }
        throw new Error(t('UserRepository_editProfile'));
    }

    /**
     * Update user notification options by user ID
     * @param {number} userId - user ID
     * @param {NotifyOptionsRequestDto} request - info about update
     * @param {TranslatorType} t - translator object
     */
    public static async updateUserNotificationOptions(userId: number, request: NotifyOptionsRequestDto, t: TranslatorType) : Promise<NotifyOptionsDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            body: request,
            endpoint: `admin/user/notifyOptions/${userId}`,
        });

        if (response.ok) { return response.data; }
        throw new Error(t('NotificationRepository_updateUserNotificationOptions'))
    }

    /**
     * Get user notify option preferences
     * @param userId - user id to get notification options
     */
    public static async getUserNotificationOptions(userId: number) : Promise<NotifyOptionsDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `admin/user/notifyOptions/${userId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

}
