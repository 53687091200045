import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionGetZone} from "../../actions/organisms/addOrEditZoneAction";
import {actionAssignHardware} from "../../actions/data/hardwareAction";
import {actionRemoveZoneHardwareList, actionUpdateZone} from "../../actions/data/zoneAction";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface ZoneDetailPageState {

    /** Patient data **/
    zone?: ZoneResponseDto|null,
    /** Add new HW to zone modal **/
    assignHardwareModalShown: boolean,
    /** Edit zone modal shown **/
    editZoneModalShown: boolean,
    /** Edit zone modal patient ID **/
    editZoneModalId: null|number,
}

const initialState: ZoneDetailPageState = {
    assignHardwareModalShown: false,
    editZoneModalShown: false,
    editZoneModalId: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const zoneDetailPageSlice = createSlice({
    name: 'zoneDetailPage',
    initialState,
    reducers: {
        destroyPage: (state) => {
            state.zone = undefined;
            state.assignHardwareModalShown = false;
            state.editZoneModalShown = false;
            state.editZoneModalId = null;
        },
        showEditZoneModal: (state, action: PayloadAction<{zoneId: number}>) => {
            state.assignHardwareModalShown = false;
            state.editZoneModalShown = true;
            state.editZoneModalId = action.payload.zoneId;
        },
        hideEditZoneModal: (state) => {
            state.editZoneModalId = null;
            state.editZoneModalShown = false;
        },
        showAssignHardwareModal: (state) => {
            state.assignHardwareModalShown = true;
            state.editZoneModalShown = false;
            state.editZoneModalId = null;
        },
        hideAssignHardwareModal: (state) => {
            state.assignHardwareModalShown = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionRemoveZoneHardwareList.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zone = payload; }
        })
        builder.addCase(actionGetZone.fulfilled, (state, {payload}) => {
            state.zone = payload;
        })
        builder.addCase(actionAssignHardware.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string" && typeof payload.zone !== 'undefined') {
                state.zone = payload.zone;
            }
        })
        builder.addCase(actionUpdateZone.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zone = payload.zone; }
        })
    }
})

export default zoneDetailPageSlice.reducer
