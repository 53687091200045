import { createAsyncThunk } from "@reduxjs/toolkit";
import {SMSSimpleMessageRequestDto} from "../../../models/entities/Requests/SMSSimpleMessageRequestDto";
import {SMSRepository} from "../../../models/repositories/SMSRepository";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Send SMS message
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{request: SMSSimpleMessageRequestDto, t: TranslatorType}} payload - request payload
 * @return {void|string}
 */
export const actionSendSMSMessage = createAsyncThunk<void|string, { request: SMSSimpleMessageRequestDto, t: TranslatorType }>(
    "smsMenu/sendSMSMessage",
    async (payload: { request: SMSSimpleMessageRequestDto, t: TranslatorType }, { rejectWithValue }) => {
        try { await SMSRepository.sendSMSMessage(payload.request, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);

