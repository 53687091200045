import React from "react";
import "./GoogleAuthCodes.scss"
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {T, useTranslate} from "@tolgee/react";

interface GoogleAuthCodesProps {
    dispatchHideModal: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const GoogleAuthCodes = ({dispatchHideModal}: GoogleAuthCodesProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    return (
        <div className="GoogleAuthCodes">
            <div className="GoogleAuthCodes-modal">
                <div className="GoogleAuthCodes-modal-content">
                    <div className="GoogleAuthCodes-modal-content-title">
                        <div className="GoogleAuthCodes-modal-content-title-text">
                            <T keyName={'GoogleAuthCodes_title'} />
                        </div>
                        <div className="GoogleAuthCodes-modal-content-title-close">
                            <Image onClick={() => dispatch(dispatchHideModal()) }
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}} />
                        </div>
                    </div>
                    <div className="GoogleAuthCodes-modal-content-codes">
                        <div className="GoogleAuthCodes-modal-content-codes-preamble">
                            <T keyName={'GoogleAuthCodes_preamble'} />
                        </div>
                        <div className="GoogleAuthCodes-modal-content-codes-block">
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d4b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d2b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                            <div className="GoogleAuthCodes-modal-content-codes-block-item">d07d1b8a9468f5fb</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoogleAuthCodes;
