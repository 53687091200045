import {createSlice} from '@reduxjs/toolkit'
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {actionGetHiveCached, actionGetHiveFresh} from "../../actions/data/hiveAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HiveState {

    /** Hive data **/
    hive?: HiveResponseDto|null,
}

const initialState: HiveState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hiveDataSlice = createSlice({
    name: 'hiveData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetHiveCached.fulfilled, (state, {payload}) => {
            state.hive = payload;
        })
        builder.addCase(actionGetHiveFresh.fulfilled, (state, {payload}) => {
            state.hive = payload;
        })
    }
})

export default hiveDataSlice.reducer