import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {useAppSelector} from "../../../store/hooks";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import CardZone from "../../organisms/CardZone/CardZone";
import {zoneDetailPageSlice} from "../../../store/slices/pages/zoneDetailPageSlice";
import AddOrEditZone from "../../organisms/AddOrEditZone/AddOrEditZone";
import AssignHardware from "../../organisms/AssignHardware/AssignHardware";
import {BulkListZoneHardware} from "../../organisms/BulkListZoneHardware/BulkListZoneHardware";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type ZoneDetailTemplateProps = {
    zone: ZoneResponseDto,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {ZoneResponseDto} zone - zone data
 * @param {RoleEnum} role - role for user from session
 */
export const ZoneDetailTemplate = ({zone, role}: ZoneDetailTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const assignHardwareModalState = useAppSelector((state) => state.zoneDetailPage.assignHardwareModalShown);
    const editZoneModalShownState = useAppSelector((state) => state.zoneDetailPage.editZoneModalShown);
    const editZoneModalIdState = useAppSelector((state) => state.zoneDetailPage.editZoneModalId);
    const title = t('ZoneDetailTemplate_title', {zoneName: zone.Zone.name});

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    {title}
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={title}
                        breadItems={[
                            {name: t('Navigation_zones'), link: '/zones'},
                            {name: title, link: null},
                        ]} />
                    <div className="layout-right-content layout-right-content-flex flex">
                        <div className="layout-right-content-item half">
                            <CardZone
                                dispatchShowEditZoneModal={zoneDetailPageSlice.actions.showEditZoneModal}
                                zone={zone.Zone} />
                        </div>
                        <div className="layout-right-content-item half">
                            <BulkListZoneHardware
                                zone={zone.Zone}
                                dispatchShowAddHardwareModal={zoneDetailPageSlice.actions.showAssignHardwareModal}
                                hardwareList={zone.HardwareList}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {editZoneModalShownState && editZoneModalIdState &&
                <AddOrEditZone
                    zoneId={editZoneModalIdState}
                    dispatchHideAddOrEditZoneModal={zoneDetailPageSlice.actions.hideEditZoneModal} />
            }
            {assignHardwareModalState &&
                <AssignHardware
                    hwTypesSelectFrom={[HardwareTypeEnum.GATEWAY, HardwareTypeEnum.REPEATER, HardwareTypeEnum.STATIC]}
                    patient={null}
                    zone={zone.Zone}
                    dispatchHideModal={zoneDetailPageSlice.actions.hideAssignHardwareModal} />
            }
        </main>
    );
}

export default ZoneDetailTemplate;
