import "./InputCheckbox.scss";
import React, {useEffect} from "react";
import {AbstractFormUpdateCheckBoxCallBack} from "../../../models/utils/AbstractFormReducers";

type InputCheckboxProps = {
    label: string,
    inputName: string
    onChangeCallBack: AbstractFormUpdateCheckBoxCallBack,
    startValue?: boolean|null,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string} label - label text
 * @param {AbstractFormUpdateCheckBoxCallBack} onChangeCallBack - basic callback
 * @param {string} inputName - input name
 * @param {boolean|undefined|null} startValue - starting default value
 * @todo dependencies
 */
export const InputCheckbox = ({label, onChangeCallBack, inputName, startValue} : InputCheckboxProps): React.JSX.Element => {

    const [checked, setChecked] = React.useState<boolean>(startValue ?? false);

    useEffect(() => {
        setChecked(startValue ?? false);
        onChangeCallBack(inputName, startValue ?? false);
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
        [startValue, inputName]);

    return (
        <div className={`InputCheckbox`}>
            <div className={`InputCheckbox-content`}>
                <label className={'InputCheckbox-content-label'} htmlFor={inputName}>
                    <input
                        onChange={() => {
                            onChangeCallBack(inputName, !checked);
                            setChecked(!checked);
                        }}
                        checked={checked}
                        name={inputName}
                        className={'InputCheckbox-content-label-input'}
                        type={'checkbox'}
                    />
                    <span className="InputCheckbox-content-label-mark mark"></span>
                    <span className="InputCheckbox-content-label-text text">{label}</span>
                </label>
            </div>
        </div>
    );
}

export default InputCheckbox;
