import React, {useEffect, useRef} from "react";
import "./FilterPatient.scss"
import arrow from "../../../images/arrowRightSmallGrey.svg"
import {Image} from "../../atoms/Image/Image";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {selectInputState} from "../../../models/utils/AbstractFormReducers";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import {actionGetHardwareList} from "../../../store/actions/data/hardwareAction";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {FilterMain} from "../../extras/Filter/FilterMain";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {filterPatientOrganismSlice} from "../../../store/slices/organisms/filterPatientSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionGetInternalBuilding} from "../../../store/actions/data/buildingAction";
import {InternalBuildingFloor} from "../../../models/utils/AbstractBuildingReducers";
import {T, useTranslate} from "@tolgee/react";

type FilterPatientProps = {
    title: string|null,
    sensorTypes: Array<SensorTypeDto>,
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    dispatchFilterHardware: ActionCreatorWithPayload<{hardwareId: number, title: string}>,
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{templateId: number, title: string}>} dispatchFilterTemplate - filter by template dispatch
 * @param {ActionCreatorWithPayload<{sensorType: number, title: string}>} dispatchFilterSensor - filter by sensor dispatch
 * @param {ActionCreatorWithPayload<{hardwareId: number, title: string}>} dispatchFilterHardware - filter by hardware dispatch
 * @param {string|null} title - filter actual search title
 * @param {ActionCreatorWithPayload<{elevationId: number, title: string}>} dispatchFilterElevation - filter by elevation
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {RoleEnum} role - role from session data
 */
export const FilterPatient = ({dispatchFilterTemplate, dispatchFilterSensor, dispatchFilterHardware, title, dispatchFilterElevation,
    sensorTypes, role} : FilterPatientProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const openedState = useAppSelector((state) => state.filterPatientOrganism.opened);
    const filterTypeState = useAppSelector((state) => state.filterPatientOrganism.filterType);
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);
    const hardwareListState = useAppSelector((state) => state.hardwareData.hardwareList);
    const buildingState = useAppSelector((state) => state.buildingData.building);
    const filterSearchState = useAppSelector((state) => selectInputState(state.filterPatientOrganism, 'filterSearch'));

    // Re-load data
    useEffect(() => {
        if (!hardwareTemplateListState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE) { dispatch(actionGetHardwareTemplateList()); }
        else if (!hardwareListState && filterTypeState === AbstractFilterType.FILTER_HARDWARE) { dispatch(actionGetHardwareList()); }
        else if (!buildingState && filterTypeState === AbstractFilterType.FILTER_ELEVATION) { dispatch(actionGetInternalBuilding()); }
    }, [dispatch, hardwareTemplateListState, filterTypeState, hardwareListState, buildingState])

    // Clean-up
    useEffect(() => { return () => { dispatch(filterPatientOrganismSlice.actions.hideDropDown()); } }, [dispatch])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { if (openedState) { dispatch(filterPatientOrganismSlice.actions.hideDropDown()); } });

    // Search is initialized
    const searchValue = filterSearchState && filterSearchState.value && filterSearchState.value.toString().length > 0
        ? filterSearchState.value.toString().toLowerCase() : null;

    // Search in templates
    let templateListFiltered = hardwareTemplateListState && searchValue
        ? hardwareTemplateListState.filter((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.name.toLocaleLowerCase().includes(searchValue);
          }) : hardwareTemplateListState;

    // Search in floors
    let elevationsFiltered = buildingState && buildingState.building && searchValue
        ? buildingState.building.floors.filter((floor: InternalBuildingFloor) => {
            return floor.elevation.toString().toLocaleLowerCase().includes(searchValue);
        }) : (!buildingState ? buildingState : (buildingState.building ? buildingState.building.floors : null));

    // Filter templates for role
    templateListFiltered = templateListFiltered ? templateListFiltered.filter((template: HardwareTemplateResponseDto) => {
        if (role === RoleEnum.TECHNICAL && template.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) { return false; }
        return !(role === RoleEnum.MEDICAL && template.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE);
    }) : templateListFiltered;

    // Search in patients
    let hardwareListFiltered = hardwareListState && searchValue
        ? hardwareListState.filter((hardware: HardwareResponseDto) => {
            return hardware.Hardware.uid.toLocaleLowerCase().includes(searchValue) 
                || (hardware.Hardware.internalId && hardware.Hardware.internalId.toLocaleLowerCase().includes(searchValue));
        }) : hardwareListState;

    if (hardwareListFiltered) {
        hardwareListFiltered = hardwareListFiltered.filter((item: HardwareResponseDto) => {
            return item.HardwareTemplate.type === HardwareTypeEnum.WEARABLE;
        })
    }

    // Search in sensors
    const sensorList = DataParser.getSensorTypesListForSelect(sensorTypes, t);
    const sensorListFiltered = searchValue
        ? sensorList.filter((sensor: {value: number, label: string}) => {
            return sensor.label.toLowerCase().includes(searchValue);
        }) : sensorList;

    return (
        <div className={`FilterPatient ${openedState ? 'active' : 'hidden'}`} ref={wrapperRef}>
            <FilterMain
                openedState={openedState}
                filterTypeState={filterTypeState}
                title={title}
                filterSearchState={filterSearchState}
                toggleDropDown={ () => { dispatch(filterPatientOrganismSlice.actions.toggleDropDown()) } }
                updateInput={filterPatientOrganismSlice.actions.updateInput} />
            <div className="FilterPatient-content content">
                {openedState && filterTypeState === AbstractFilterType.FILTER_ALL &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-item"
                             onClick={() => {
                                 dispatch(filterPatientOrganismSlice.actions.setFilterType(
                                     {filterType: AbstractFilterType.FILTER_TEMPLATE}
                                 ));
                             }}>
                            <div className="FilterPatient-content-inside-item-text">
                                <T keyName={'Base_filter_template'} />
                            </div>
                            <div className="FilterPatient-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterPatient-content-inside-item"
                             onClick={() => {
                                 dispatch(filterPatientOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_SENSOR}));
                             }}>
                            <div className="FilterPatient-content-inside-item-text">
                                <T keyName={'Base_filter_sensor'} />
                            </div>
                            <div className="FilterPatient-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterPatient-content-inside-item"
                             onClick={() => {
                                 dispatch(filterPatientOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_HARDWARE}));
                             }}>
                            <div className="FilterPatient-content-inside-item-text">
                                <T keyName={'Base_filter_hardware'} />
                            </div>
                            <div className="FilterPatient-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                        <div className="FilterPatient-content-inside-item"
                             onClick={() => {
                                 dispatch(filterPatientOrganismSlice.actions.setFilterType({filterType: AbstractFilterType.FILTER_ELEVATION}));
                             }}>
                            <div className="FilterPatient-content-inside-item-text">
                                <T keyName={'Base_filter_elevation'} />
                            </div>
                            <div className="FilterPatient-content-inside-item-arrow">
                                <Image source={arrow} description={t('Base_filter_show_dropdown')}/>
                            </div>
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && typeof templateListFiltered === 'undefined' &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered === null &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_hardware_template_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_TEMPLATE && templateListFiltered &&
                    <div className="FilterPatient-content-inside">
                        {templateListFiltered.map((template: HardwareTemplateResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterTemplate(
                                            {templateId: template.HardwareTemplate.id, title: template.HardwareTemplate.name}));
                                        dispatch(filterPatientOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterPatient-content-inside-item">
                                    <div className="FilterPatient-content-inside-item-text">
                                        {template.HardwareTemplate.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_HARDWARE && typeof hardwareListFiltered === 'undefined' &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_HARDWARE && hardwareListFiltered === null &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_hardware_list')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_HARDWARE && hardwareListFiltered &&
                    <div className="FilterPatient-content-inside">
                        {hardwareListFiltered.map((hardware: HardwareResponseDto, index: number) => {
                            return (
                                <div
                                    key={`Filter-hardware-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterHardware(
                                            {hardwareId: hardware.Hardware.id, title: hardware.Hardware.internalId ?? hardware.Hardware.uid}));
                                        dispatch(filterPatientOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterPatient-content-inside-item">
                                    <div className="FilterPatient-content-inside-item-text">
                                        {hardware.Hardware.internalId ?? hardware.Hardware.uid}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_SENSOR &&
                    <div className="FilterPatient-content-inside">
                        {sensorListFiltered.map((item: {value: number, label: string}, index: number) => {
                            return (
                                <div
                                    key={`Filter-template-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterSensor({sensorType: item.value, title: item.label}));
                                        dispatch(filterPatientOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterPatient-content-inside-item">
                                    <div className="FilterPatient-content-inside-item-text">
                                        {item.label}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && typeof elevationsFiltered === 'undefined' &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered === null &&
                    <div className="FilterPatient-content-inside">
                        <div className="FilterPatient-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_building')}} />
                        </div>
                    </div>
                }
                {openedState && filterTypeState === AbstractFilterType.FILTER_ELEVATION && elevationsFiltered &&
                    <div className="FilterPatient-content-inside">
                        {elevationsFiltered.map((floor: InternalBuildingFloor, index: number) => {
                            return (
                                <div
                                    key={`Filter-elevation-${index}`}
                                    onClick={() => {
                                        dispatch(dispatchFilterElevation({elevationId: floor.elevation, title: `${floor.elevation}. floor`}));
                                        dispatch(filterPatientOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterPatient-content-inside-item">
                                    <div className="FilterPatient-content-inside-item-text">
                                        {floor.elevation}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default FilterPatient;
