import React, {useEffect} from "react";
import "./ListAlerts.scss"
import {TableRows} from "../../atoms/Table/Table";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import remove from "../../../images/delete.svg";
import resolve from "../../../images/inputOK.svg";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionDeleteAlert, actionGetAlertList} from "../../../store/actions/data/alertAction";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {FunctionTable, OrderOptions} from "../../molecules/FunctionTable/FunctionTable";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {EasyFilter, EasyFilterColor} from "../../molecules/EasyFilter/EasyFilter";
import {AlertsPageValueTrend, ListAlertsFilterTypes} from "../../../store/slices/pages/alertsPageSlice";
import {FilterAlerts} from "../FilterAlerts/FilterAlerts";
import {EasyCalendar, EasyCalendarFilterPayload} from "../../molecules/EasyCalendar/EasyCalendar";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {DataParser} from "../../../models/utils/DataParser";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {TableCellData, TableCellDataAction, TableCellDataActionTypeEnum} from "../../atoms/Table/TableColumn";
import {DotType} from "../../atoms/Dot/Dot";
import {SensorAlertStateEnum} from "../../../models/entities/Enums/SensorAlertStateEnum";
import {useTranslate} from "@tolgee/react";
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {TableDetailLinkType} from "../../atoms/Table/TableDetail";

/**
 * @alias ListAlertsPagination
 * @category Components
 */
type ListAlertsPagination = {

    /** Show count **/
    showCount: number,
    /** Current page **/
    page: number,
    /** Current oder **/
    order: OrderOptions|null,
    /** Redux callback to change order **/
    dispatchChangeOrder: ActionCreatorWithPayload<OrderOptions>
    /** Redux callback to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

/**
 * @alias ListAlertsFilter
 * @category Components
 */
type ListAlertsFilter = {

    /** Current filter type **/
    filterType: ListAlertsFilterTypes,
    /** Redux callback to filter all items **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux callback to filter just resolved **/
    dispatchFilterResolved: ActionCreatorWithoutPayload,
    /** Redux callback to filter just progress **/
    dispatchFilterProgress: ActionCreatorWithoutPayload,
    /** Redux callback to filter just unread **/
    dispatchFilterUnread: ActionCreatorWithoutPayload,
    /** Redux callback to filter just finished **/
    dispatchFilterFinished: ActionCreatorWithoutPayload,
    /** Current filter title **/
    filterTitle: string|null,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by elevation **/
    elevation: number|null,
    /** Filter by patient **/
    patientId: number|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by value trend **/
    valueTrend: AlertsPageValueTrend|null,
    /** Full text value to search **/
    fullTextValue: string|null,
    /** Filter by date **/
    filterDate: EasyCalendarFilterPayload|null,
    /** Redux callback to filter by value trend **/
    dispatchFilterValueTrend: ActionCreatorWithPayload<{valueTrend: AlertsPageValueTrend, title: string}>,
    /** Redux callback to filter by sensor **/
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    /** Redux callback to filter by patient **/
    dispatchFilterPatient: ActionCreatorWithPayload<{patientId: number, title: string}>,
    /** Redux callback to filter by template **/
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    /** Redux callback to filter by elevation **/
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
    /** Redux callback to filter by date **/
    dispatchFilterDate: ActionCreatorWithPayload<EasyCalendarFilterPayload>,
}

type ListAlertsProps = {
    role: RoleEnum,
    pagination: ListAlertsPagination,
    filter: ListAlertsFilter,
    dispatchShownNoteAlertModal: ActionCreatorWithPayload<{ alertId: number }>
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ListAlertsPagination} pagination - pagination options
 * @param {ListAlertsFilter} filter - filter options
 * @param {RoleEnum} role - logged user role
 * @param {ActionCreatorWithPayload<{ alertId: number }>} dispatchShownNoteAlertModal - show note modal
 */
export const ListAlerts = ({pagination, filter, role, dispatchShownNoteAlertModal} : ListAlertsProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const alertList = useAppSelector((state) => state.alertData.alertList);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const dispatch = useAppDispatch();

    // Init
    useEffect(() => {
        if (!alertList) { dispatch(actionGetAlertList()); }
        const checkMs = process.env.REACT_APP_STATUS_REFRESH_MS ? parseInt(process.env.REACT_APP_STATUS_REFRESH_MS) : 10000;
        const interval = setInterval(() => { dispatch(actionGetAlertList()); }, checkMs);
        return () => { clearInterval(interval); }
    }, [dispatch, alertList])

    // Load sensor types
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Filter hardware list using filter states
    const filterAlertList = (alerts: Array<AlertResponseDto>) : Array<AlertResponseDto> => {

        return alerts.filter((item: AlertResponseDto) => {

            if (role === RoleEnum.MEDICAL && item.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE) { return false; }
            if (role === RoleEnum.TECHNICAL && item.HardwareTemplate.type === HardwareTypeEnum.WEARABLE) { return false; }

            if (filter.filterType === ListAlertsFilterTypes.ALL) { return true; }
            else if (filter.filterType === ListAlertsFilterTypes.TEMPLATE && filter.templateId !== null) {
                return item.Hardware.hardwareTemplate === filter.templateId;
            }
            else if (filter.filterType === ListAlertsFilterTypes.SENSOR && filter.sensorType !== null) {
                return item.SensorTemplate.type === filter.sensorType;
            }
            else if (filter.filterType === ListAlertsFilterTypes.PATIENT && filter.patientId !== null) {
                return item.Hardware.patient === filter.patientId;
            }
            else if (filter.filterType === ListAlertsFilterTypes.ELEVATION && filter.elevation !== null) {
                return item.BuildingFloor !== null && item.BuildingFloor.elevation === filter.elevation;
            }
            else if (filter.filterType === ListAlertsFilterTypes.VALUE_TREND && filter.valueTrend) {
                return filter.valueTrend === AlertsPageValueTrend.RISING ? item.Alert.extremeMax !== null : item.Alert.extremeMin !== null;
            }
            else if (filter.filterType === ListAlertsFilterTypes.FINISHED) {
                return item.Sensor.alertState === SensorAlertStateEnum.NONE;
            }
            else if (filter.filterType === ListAlertsFilterTypes.PROGRESS) {
                return item.Sensor.alertState === SensorAlertStateEnum.ALERTING;
            }
            else if (filter.filterType === ListAlertsFilterTypes.UNREAD) {
                return !item.Alert.read;
            }
            else if (filter.filterType === ListAlertsFilterTypes.RESOLVED) {
                return item.Sensor.alertState === SensorAlertStateEnum.RESOLVED;
            }
            else if (filter.filterType === ListAlertsFilterTypes.DATE && filter.filterDate !== null) {
                const itemTimestamp = Date.parse(item.Alert.firstOccurrenceAt);
                if (filter.filterDate.dateFrom && (itemTimestamp < filter.filterDate.dateFrom.getTime())) { return false; }
                return !(filter.filterDate.dateTo && (itemTimestamp > filter.filterDate.dateTo.getTime()));
            }
            else if (filter.filterType === ListAlertsFilterTypes.FULLTEXT && filter.fullTextValue !== null) {
                return (item.HardwareTemplate.name.toLowerCase().includes(filter.fullTextValue))
                    || item.Hardware.uid.toLowerCase().includes(filter.fullTextValue)
                    || (item.Hardware.internalId && item.Hardware.internalId.toLowerCase().includes(filter.fullTextValue))
                    || ValueUtil.getStringFromValue(t, item.Alert.firstOccurrenceAt).toLowerCase().includes(filter.fullTextValue)
                    || (item.Sensor.customName && item.Sensor.customName.toLowerCase().includes(filter.fullTextValue))
                    || (SensorUtil.getMetaForSensorType(item.SensorTemplate.type, t).name.toLowerCase().includes(filter.fullTextValue));
            }
            return false;
        });
    }

    const parseAlertsList = (alerts: Array<AlertResponseDto>, sensorTypes: Array<SensorTypeDto>) : TableRows => {

        return alerts.map((alert : AlertResponseDto) => {

            const sensorType = DataParser.getSensorTypeFromList(sensorTypes, alert.SensorTemplate.type);
            const duration = ValueUtil.getDurationFromMillisecondsWithText(
                new Date(alert.Alert.lastOccurrenceAt), new Date(alert.Alert.firstOccurrenceAt), t);

            const actions : Array<TableCellDataAction> = [];
            actions.push({
                confirm: {
                    title: t('ListAlerts_delete_confirm'),
                    successTitle: t('ListAlerts_delete_success', {alertId: alert.Alert.id})
                },
                linkType: TableDetailLinkType.DELETE,
                title: t('ListAlerts_delete_title'),
                image: remove,
                type: TableCellDataActionTypeEnum.PRIMARY,
                trigger: async () => {
                    const result = await dispatch(actionDeleteAlert({alertId: alert.Alert.id, t: t}));
                    if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                        throw new Error(result.payload);
                    }
                }
            });

            if (alert.Sensor.alertState === SensorAlertStateEnum.ALERTING) {
                actions.push({
                    linkType: TableDetailLinkType.PRIMARY,
                    title: t('ListAlerts_resolve'),
                    image: resolve,
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    trigger: async () => {
                        dispatch(dispatchShownNoteAlertModal({alertId: alert.Alert.id}));
                    }
                });
            }

            const unread = ', ' + t('ListAlerts_unread');
            let columns : Array<TableCellData> = [
                {dot: {
                    highlight: !alert.Alert.read,
                    type: alert.Sensor.alertState === SensorAlertStateEnum.ALERTING
                        ? DotType.ERROR : (alert.Sensor.alertState === SensorAlertStateEnum.RESOLVED
                            ? DotType.WARNING : DotType.SUCCESS),
                    stateName: t(alert.Sensor.alertState === SensorAlertStateEnum.ALERTING
                        ? 'ListAlerts_progress' : (alert.Sensor.alertState === SensorAlertStateEnum.RESOLVED
                            ? 'ListAlerts_resolved' : 'ListAlerts_finished')) + (!alert.Alert.read ? unread : '')

                }},
                {text: '#' + alert.Alert.id},
                {dateTime: alert.Alert.firstOccurrenceAt},
                {text: alert.Hardware.internalId ?? alert.Hardware.uid},
                {
                    text: duration ? `${duration.value} ${duration.text}` : undefined,
                    emptyText: !duration ? '-' : undefined
                },
                {limit: {
                    min: alert.Sensor.limitMin,
                    max: alert.Sensor.limitMax,
                    unit: sensorType ? sensorType.unit : null
                }},
                {extreme: {
                    min: alert.Alert.extremeMin,
                        max: alert.Alert.extremeMax,
                        unit: sensorType ? sensorType.unit : null
                }},
                {sensors: SensorUtil.getImagesFromSensorTypesNumbers([alert.SensorTemplate.type], sensorTypes, t)},
                {actions: actions}
            ];

            const rowTitle = alert.Patient
                ? `${alert.Patient.firstName} ${alert.Patient.lastName}`
                : alert.Hardware.internalId ?? alert.Hardware.uid;

            return {
                link: {link: "/alert/" + alert.Alert.id, title: t('ListAlerts_gotoAlertDetail')},
                rowTitle: rowTitle,
                rowSubtitle: ValueUtil.getStringFromValue(t, alert.Alert.firstOccurrenceAt),
                columns: columns
            }
        });
    }

    // Loading state
    if (typeof alertList === "undefined" || typeof sensorTypes === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state alerts
    if (alertList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_alert_list')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    // Prepare table output data
    const parsedAlertsList = parseAlertsList(filterAlertList(alertList), sensorTypes);

    return (
        <div className={'ListAlerts'}>
            <div className="ListAlerts-functions">
                <div className="ListAlerts-functions-left">
                    <EasyFilter items={[
                        {title: t('ListAlerts_all'),
                            color: EasyFilterColor.DEFAULT,
                            active: filter.filterType === ListAlertsFilterTypes.ALL,
                            dispatchFilterChange: filter.dispatchFilterAll},
                        {title: t('ListAlerts_unread').charAt(0).toUpperCase() + t('ListAlerts_unread').slice(1),
                            color: EasyFilterColor.DEFAULT,
                            active: filter.filterType === ListAlertsFilterTypes.UNREAD,
                            dispatchFilterChange: filter.dispatchFilterUnread},
                        {title: t('ListAlerts_progress'),
                            color: EasyFilterColor.RED,
                            active: filter.filterType === ListAlertsFilterTypes.PROGRESS,
                            dispatchFilterChange: filter.dispatchFilterProgress},
                        {title: t('ListAlerts_resolved'),
                            color: EasyFilterColor.ORANGE,
                            active: filter.filterType === ListAlertsFilterTypes.RESOLVED,
                            dispatchFilterChange: filter.dispatchFilterResolved},
                        {title: t('ListAlerts_finished'),
                            color: EasyFilterColor.GREEN,
                            active: filter.filterType === ListAlertsFilterTypes.FINISHED,
                            dispatchFilterChange: filter.dispatchFilterFinished},
                    ]} />
                </div>
                <div className="ListAlerts-functions-right">
                    <div className="ListHardware-functions-right-item">
                        <EasyCalendar
                            date={filter.filterType === ListAlertsFilterTypes.DATE ? filter.filterDate : null}
                            dispatchChange={filter.dispatchFilterDate}/>
                    </div>
                    <div className="ListHardware-functions-right-item">
                        <FilterAlerts
                            role={role}
                            sensorTypes={sensorTypes}
                            title={filter.filterTitle}
                            dispatchFilterValueTrend={filter.dispatchFilterValueTrend}
                            dispatchFilterElevation={filter.dispatchFilterElevation}
                            dispatchFilterPatient={filter.dispatchFilterPatient}
                            dispatchFilterSensor={filter.dispatchFilterSensor}
                            dispatchFilterTemplate={filter.dispatchFilterTemplate}/>
                    </div>
                </div>
            </div>
            <div className="ListAlerts-table">
                <FunctionTable
                    empty={{
                        title: t('ListAlerts_empty_title'),
                        subTitle: t('ListAlerts_empty_subtitle')
                    }}
                    fePagination={{
                        size: pagination.showCount,
                        page: pagination.page,
                        dispatchChangePage: pagination.dispatchChangePage,
                        dispatchChangeOrder: pagination.dispatchChangeOrder,
                        order: pagination.order,
                    }}
                    data={{rows: parsedAlertsList, columns:  [
                            {name: t('ListAlerts_col_state')},
                            {name: t('ListAlerts_col_id')},
                            {name: t('ListAlerts_col_time')},
                            {name: t('ListAlerts_col_issuer')},
                            {name: t('ListAlerts_col_duration')},
                            {name: t('ListAlerts_col_limit'), forceDisableOrder: true},
                            {name: t('ListAlerts_col_extreme'), forceDisableOrder: true},
                            {name: t('ListAlerts_col_sensor'), forceDisableOrder: true},
                            {name: t('ListAlerts_col_actions'), action: true}]
                    }} />
            </div>
        </div>
    );
}

export default ListAlerts;
