import {createSlice} from '@reduxjs/toolkit'
import {abstractFilterInitialState, AbstractFilterState, getAbstractFilterReducers} from "../../../models/utils/AbstractFilterReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFilterState}
 */
interface FilterAlertsState extends AbstractFilterState {}

const initialState: FilterAlertsState = abstractFilterInitialState;

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const filterAlertsOrganismSlice = createSlice({
        name: 'filterAlertsOrganism',
    initialState,
    reducers: {...getAbstractFilterReducers(), ...{}},
})

export default filterAlertsOrganismSlice.reducer