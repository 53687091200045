import {Communicator, RequestType} from "../utils/Communicator";
import {CacheExpiry} from "../utils/Storage";
import {ZoneDto} from "../entities/ZoneDto";
import {ZoneRequestDto} from "../entities/Requests/ZoneRequestDto";
import {HardwareUnassignBulkRequestDto} from "../entities/Requests/HardwareUnassignBulkRequestDto";
import {ZoneBanRequestDto} from "../entities/Requests/ZoneBanRequestDto";
import {ZoneResponseDto} from "../entities/Responses/ZoneResponseDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class ZoneRepository {

    /**
     * Get zone list
     */
    public static async getZones() : Promise<Array<ZoneDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "zone",
            cache: {cacheKey: 'getZones', expiry: CacheExpiry.FIVE_MINUTES, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Delete zone by ID
     * @param {number} zoneId - id to delete
     * @param {TranslatorType} t - translator object
     */
    public static async deleteZone(zoneId: number, t: TranslatorType) : Promise<ZoneDto> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `zone/${zoneId}`,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_deleteZone'));
    }

    /**
     * Un-delete zone by ID
     * @param {number} zoneId - id to delete
     * @param {TranslatorType} t - translator object
     */
    public static async undeleteZone(zoneId: number, t: TranslatorType) : Promise<ZoneDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `zone/${zoneId}/undelete`,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_undeleteZone'));
    }

    /**
     * Update zone by zone ID
     * @param {number} zoneId - zone ID
     * @param {ZoneRequestDto} zoneUpdateRequest - info about update
     * @param {TranslatorType} t - translator object
     */
    public static async updateZone(zoneId: number, zoneUpdateRequest: ZoneRequestDto, t: TranslatorType) : Promise<ZoneResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            body: zoneUpdateRequest,
            endpoint: `zone/${zoneId}`,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_updateZone'));
    }

    /**
     * Ban zone in template by ID
     * @param zoneBanRequest - request to ban zone
     * @param {TranslatorType} t - translator object
     */
    public static async banZoneInTemplateById(zoneBanRequest: ZoneBanRequestDto, t: TranslatorType) : Promise<ZoneDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `zoneBanTemplate`,
            body: zoneBanRequest,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_banZoneInTemplateById'));
    }

    /**
     * Un-Ban zone in template by ID
     * @param zoneBanRequest - request to un-ban zone
     * @param {TranslatorType} t - translator object
     */
    public static async unbanZoneInTemplateById(zoneBanRequest: ZoneBanRequestDto, t: TranslatorType) : Promise<ZoneDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `zoneBanTemplate/unban`,
            body: zoneBanRequest,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_unbanZoneInTemplateById'));
    }

    /**
     * Create new zone
     * @param {ZoneRequestDto} zoneCreateRequest - info about new zone
     * @param {TranslatorType} t - translator object
     */
    public static async createZone(zoneCreateRequest: ZoneRequestDto, t: TranslatorType) : Promise<ZoneDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `zone`,
            body: zoneCreateRequest,
            cache: {deleteKeys: ['getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_createZone'));
    }

    /**
     * Get zone by ID
     * @param {number} zoneId - zone ID
     */
    public static async getZone(zoneId: number) : Promise<ZoneResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `zone/${zoneId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get zone ban template
     * Contains list of zones default banned
     * @param {boolean} fresh - don't use cache
     */
    public static async getZoneBanTemplate(fresh: boolean) : Promise<Array<ZoneDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `zoneBanTemplate`,
            cache: {cacheKey: 'getZoneBanTemplate', expiry: CacheExpiry.FIVE_MINUTES, useCache: !fresh}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Un-assign list of hardware IDs from zone
     * @param {number} zoneId - zone to remove HW list
     * @param {HardwareUnassignBulkRequestDto} request - request object
     * @param {TranslatorType} t - translator object
     * @return {Promise<ZoneResponseDto>}
     */
    public static async unAssignHardwareFromZone(zoneId: number, request: HardwareUnassignBulkRequestDto, t: TranslatorType) : Promise<ZoneResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `zone/${zoneId}/hardware/unassign`,
            body: request,
            cache: {deleteKeys: ['getZones', 'getHardwareList']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('ZoneRepository_unAssignHardwareFromZone'));
    }
}
