import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionBanZoneForPatient} from "../../actions/data/patientAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface AssignZoneState extends AbstractFormReducersState {}

const initialState: AssignZoneState = {
    form: {
        inputs: [
            {name: 'zone', validations: {required: true}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const assignZoneOrganismSlice = createSlice({
    name: 'assignZoneOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}},
    extraReducers: (builder) => {
        builder.addCase(actionBanZoneForPatient.fulfilled, (state) => {
            state.form.error = null;
        })
    }
})

export default assignZoneOrganismSlice.reducer
