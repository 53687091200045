/**
 * @enum {string}
 * @category Entities
 * @subcategory Enums
 */
export enum SensorAlertStateEnum {

    ALERTING = "ALERTING",
    RESOLVED = "RESOLVED",
    NONE = "NONE",
}