import React, {useEffect} from "react";
import {BuildingConstructorTemplate} from "../templates/BuildingConstructorTemplate/BuildingConstructorTemplate";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {BuildingObserverTemplate} from "../templates/BuildingObserverTemplate/BuildingObserverTemplate";
import {actionGetInternalBuilding} from "../../store/actions/data/buildingAction";
import {buildingObserverPageSlice} from "../../store/slices/pages/buildingObserverSlice";
import {buildingConstructorPageSlice} from "../../store/slices/pages/buildingConstructorSlice";
import {Storage} from "../../models/utils/Storage";
import {actionGetHiveCached, actionGetHiveFresh} from "../../store/actions/data/hiveAction";
import {actionGetSensorTypes} from "../../store/actions/data/sensorAction";
import {useTranslate} from "@tolgee/react";
import {actionGetZoneList} from "../../store/actions/data/zoneAction";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const BuildingObserverPage = (): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const buildingState = useAppSelector((state) => state.buildingObserverPage.internalBuilding);
    const hiveState = useAppSelector((state) => state.hiveData.hive);
    const role = Storage.getSession()?.user.role;
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);

    useEffect(() => { if (!buildingState) { dispatch(actionGetInternalBuilding());} }, [dispatch, buildingState])

    // Load sensor types
    useEffect(() => { if (!sensorTypes && buildingState && buildingState.building) {
        dispatch(actionGetSensorTypes());
    } }, [dispatch, sensorTypes, buildingState]);

    // Load zone list
    useEffect(() => { if (!zoneList && buildingState && buildingState.building) {
        dispatch(actionGetZoneList());
    } }, [dispatch, zoneList, buildingState])

    // Re-load data periodically
    useEffect(() => {
        if (!buildingState || !buildingState.building) { return; }
        dispatch(actionGetHiveCached());
        const checkMs = process.env.REACT_APP_STATUS_REFRESH_MS ? parseInt(process.env.REACT_APP_STATUS_REFRESH_MS) : 10000;
        const interval = setInterval(() => { dispatch(actionGetHiveFresh()); }, checkMs);
        return () => { clearInterval(interval); }
    }, [dispatch, buildingState])

    useEffect(() => {
        return () => {
            dispatch(buildingObserverPageSlice.actions.destroyPage());
            dispatch(buildingConstructorPageSlice.actions.destroyPage());
        }
    }, [dispatch])

    // Wrong session state
    // noinspection DuplicatedCode
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    if (typeof buildingState === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (buildingState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_building')})} />
    }

    if (buildingState.building) {

        if (typeof hiveState === "undefined" || typeof sensorTypes === "undefined" || typeof zoneList === "undefined") {
            return <Loading type={LoadingTypeEnum.FULL} />
        }

        if (hiveState === null) {
            return <ErrorComponent
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_page_load_title")}
                subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_hive')})} />
        }

        if (sensorTypes === null) {
            return <ErrorComponent
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_page_load_title")}
                subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_sensor_types')})} />
        }

        if (zoneList === null) {
            return <ErrorComponent
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_page_load_title")}
                subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_zone_list')})} />
        }

        return (
            <BuildingObserverTemplate
                sensorTypes={sensorTypes}
                hive={hiveState}
                zoneList={zoneList}
                building={buildingState.building}
                role={role} />
        )
    } else {
        return (
            <BuildingConstructorTemplate
                role={role} />
        )
    }
};

export default BuildingObserverPage;
