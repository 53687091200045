import "./Help.scss";
import React from "react";

type HelpProps = {
    preText?: string,
    accent?: string,
    afterText?: string
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string|undefined} preText - prepend text
 * @param {string|undefined} accent - accent text
 * @param {string|undefined} afterText - append text
 */
export const Help = ({preText, afterText, accent}: HelpProps): React.JSX.Element => {

    return (
        <div className={"Help"}>
            <div className={"Help-trigger"}>
                <div className={"Help-trigger-content"}>
                    <div className={"Help-trigger-content-triangle"}>
                        <div className={"Help-trigger-content-triangle-sub"}>
                        </div>
                    </div>
                    <div className={"Help-trigger-content-text"}>
                        {preText && <span className={'preText'}>{preText + ' '}</span> }
                        {accent && <span className={'accent'}>{accent + ' '}</span> }
                        {afterText && <span className={'afterText'}>{afterText + ' '}</span> }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;
