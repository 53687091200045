import {createSlice} from '@reduxjs/toolkit'
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {actionGetHardware} from "../../actions/organisms/addOrEditHardwareAction";

interface AddOrEditOrganismState {
    hardware?: HardwareResponseDto|null,
}

const initialState : AddOrEditOrganismState = {};

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const addOrEditHardwareOrganismSlice = createSlice({
    name: 'addOrEditHardwareOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.hardware = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(actionGetHardware.fulfilled, (state: AddOrEditOrganismState, {payload}) => {
            state.hardware = payload;
        })
    }
})

export default addOrEditHardwareOrganismSlice.reducer