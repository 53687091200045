import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface CardUserState extends AbstractFormReducersState {}

const initialState: CardUserState = {
    form: {
        inputs: [
            {name: 'firstName', validations: {required: true}},
            {name: 'lastName', validations: {required: true}},
            {name: 'email', validations: {required: true}},
            {name: 'role', validations: {required: true}},
            {name: 'branch', validations: {required: false}},
            {name: 'phone', validations: {required: false}},
            {name: '2fa', validations: {required: false}},
            {name: 'password', validations: {required: false, minLength: 8}},
            {name: 'passwordAgain', validations: {required: false, minLength: 8}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const cardUserOrganismSlice = createSlice({
    name: 'cardUserOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}}
})

export default cardUserOrganismSlice.reducer