import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OrderOptions} from "../../../components/molecules/FunctionTable/FunctionTable";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum ListZonesFilterTypes {
    ALL = 1,
    ACTIVE = 3,
    FULLTEXT = 7,
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface ZonesPageState {

    /** Add/Edit zone modal shown **/
    addOrEditZoneModalShown: boolean,
    /** Add/Edit zone modal zone ID **/
    addOrEditZoneModalId: number|null,
    /** Current filter **/
    filterType: ListZonesFilterTypes,
    /** Current pagination page **/
    paginationPage: number,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Pagination order **/
    paginationOrder: OrderOptions|null,
}

const initialState: ZonesPageState = {
    addOrEditZoneModalId: null,
    addOrEditZoneModalShown: false,
    filterType: ListZonesFilterTypes.ACTIVE,
    paginationPage: 0,
    filterTitle: null,
    fullTextValue: null,
    paginationOrder: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const zonesPageSlice = createSlice({
    name: 'zonesPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.filterType = ListZonesFilterTypes.ACTIVE;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.fullTextValue = null;
            state.paginationOrder = null;
            state.addOrEditZoneModalId = null;
            state.addOrEditZoneModalShown = false;
        },
        destroyFilter: state => {
            state.filterType = ListZonesFilterTypes.ACTIVE;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.fullTextValue = null;
            state.paginationOrder = null;
        },
        changePaginationPage: (state, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
        changePaginationOrder: (state, action: PayloadAction<OrderOptions>) => {
            state.paginationOrder = action.payload;
        },
        filterAll: state => {
            state.filterType = ListZonesFilterTypes.ALL;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterFullText: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.paginationPage = 0;
            state.filterTitle = null;
            state.paginationOrder = null;
            if (action.payload.inputValue === null) {
                state.filterType = ListZonesFilterTypes.ALL;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = ListZonesFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
        showAddOrEditZoneModal: (state, action: PayloadAction<{zoneId: number|null }>) => {
            state.addOrEditZoneModalShown = true;
            state.addOrEditZoneModalId = action.payload.zoneId;
        },
        hideAddOrEditZoneModal: (state) => {
            state.addOrEditZoneModalShown = false;
            state.addOrEditZoneModalId = null;
        },
    }
})

export default zonesPageSlice.reducer
