import React, {useEffect} from "react";
import "./HardwareStates.scss"
import {SensorState} from "../SensorState/SensorState";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {HardwareDto} from "../../../models/entities/HardwareDto";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {SensorResponseListResponseDto} from "../../../models/entities/Responses/SensorResponseListResponseDto";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionRefreshHardwareSensors} from "../../../store/actions/organisms/hardwareStatesAction";
import {hardwareStatesOrganismSlice} from "../../../store/slices/organisms/hardwareStatesSlice";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type HardwareStatesProps = {
    hardware: HardwareDto,
    sensorsData: SensorResponseListResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    dispatchShowSensorEditModal: ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {SensorResponseListResponseDto} sensorsData - sensors data
 * @param {ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>} dispatchShowSensorEditModal - dispatch to show sensor edit modal
 * @param {HardwareDto} hardware - hardware data
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of zones
 */
export const HardwareStates = ({sensorsData, dispatchShowSensorEditModal, hardware, sensorTypes, zoneList} : HardwareStatesProps): React.JSX.Element|null => {

    const dispatch = useAppDispatch();
    const hardwareSensorsRefreshed = useAppSelector((state) => state.hardwareStatesOrganism.hardwareSensors);

    useEffect(() => { return () => { dispatch(hardwareStatesOrganismSlice.actions.destroyOrganism()); } }, [dispatch])
    useEffect( () => {
        const checkMs = process.env.REACT_APP_DETAIL_REFRESH_MS ? parseInt(process.env.REACT_APP_DETAIL_REFRESH_MS) : 10000;
        const interval = setInterval(() => {
            dispatch(actionRefreshHardwareSensors({hardwareId: hardware.id}));
            }, checkMs);
        return () => { clearInterval(interval); }
    }, [hardware, dispatch]);

    const sortSensors = (sensors: Array<SensorResponseDto>) : Array<SensorResponseDto> => {
        const inputSensors = [...sensors];
        return inputSensors.sort((a: SensorResponseDto, b: SensorResponseDto) => {
            if (a.Sensor.lastReadAt !== null && b.Sensor.lastReadAt === null) { return -1; }
            if (a.Sensor.lastReadAt === null && b.Sensor.lastReadAt !== null) { return 1; }
            return 0;
        });
    }

    // Get sensors from page load hardware data or from refreshed data
    const sensors = hardwareSensorsRefreshed ? hardwareSensorsRefreshed : sensorsData.Sensors;

    return (
        <div className="HardwareStates">
            <div className="HardwareStates-cards">
                {sortSensors(sensors).map((sensor: SensorResponseDto) => {
                    return (
                        <div key={`sensorCard-${sensor.Sensor.id}`} className="HardwareStates-cards-item">
                            <SensorState
                                zoneList={zoneList}
                                sensorTypes={sensorTypes}
                                dispatchShowSensorEditModal={dispatchShowSensorEditModal}
                                sensor={sensor}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default HardwareStates;
