import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    actionAddFloorToBuildingPlan,
    actionGetInternalBuilding,
    actionRemoveBuildingPlan,
    actionResetBuildingPlan,
    actionUpdateBuildingPlan
} from "../../actions/data/buildingAction";
import {
    abstractBuildingInitialState,
    AbstractBuildingReducersState, destroyGetAbstractBuilding,
    getAbstractBuildingReducers,
    InternalBuildingFloor, InternalBuildingLocation,
    InternalBuildingLocationType
} from "../../../models/utils/AbstractBuildingReducers";
import * as Sentry from "@sentry/react";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface UpdateHardwareBuildingPositionPayload {

    /** Offset in map from left **/
    offsetXPct: number,
    /** Offset in map from top **/
    offsetYPct: number,
    /** Hardware id **/
    hardwareId: number,
    /** Location type **/
    type: InternalBuildingLocationType,
    /** Hardware name **/
    hardwareName: string
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractBuildingReducersState}
 */
interface BuildingManagerState extends AbstractBuildingReducersState {

    /** Any changes were made **/
    anyChangesMade: boolean,
    /** Upload modal shown **/
    uploadModal: boolean,
}

const initialState: BuildingManagerState = {...{
    anyChangesMade: false,
    uploadModal: false,
}, ...abstractBuildingInitialState}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingManagerPageSlice = createSlice({
    name: 'buildingManagerPage',
    initialState,
    reducers: {...getAbstractBuildingReducers(), ...{
        destroyPage: (state: BuildingManagerState) => {
            destroyGetAbstractBuilding(state);
            state.anyChangesMade = false;
            state.uploadModal = false;
        },
        hideBuildingPlanUploadModal: (state: BuildingManagerState) => {
            state.uploadModal = false;
        },
        showBuildingPlanUploadModal: (state: BuildingManagerState)=> {
            state.uploadModal = true;
        },
        removeHardwareBuildingPosition: (state: BuildingManagerState, action: PayloadAction<{hardwareId: number}>) => {

            if (!state.internalBuilding || !state.internalBuilding.building) {
                Sentry.captureMessage("Attempt for removal but building not initialized!",
                    {tags: {'slice' : 'buildingManager'}});
                return;
            }

            state.activeHardware = null;
            state.anyChangesMade = true;
            state.internalBuilding.building.floors = state.internalBuilding.building.floors.map((floor: InternalBuildingFloor) => {
                return {
                    elevation: floor.elevation,
                    plan: floor.plan,
                    locations: floor.locations.filter((location: InternalBuildingLocation) => {
                        return location.hardwareId !== action.payload.hardwareId;
                    }),
                }
            });
        },
        updateHardwareBuildingPosition: (state: BuildingManagerState, action: PayloadAction<UpdateHardwareBuildingPositionPayload>) => {

            if (!state.internalBuilding || !state.internalBuilding.building || !state.internalBuilding.building.floors[state.floorIndex]) {
                Sentry.captureMessage("Attempt for update but building or floor not initialized!",
                    {tags: {'slice' : 'buildingManager'}});
                return;
            }

            // Find current position
            let hwCurrentPosition : {floor: number, index: number}|null = null;
            for (let x = 0; x < state.internalBuilding.building.floors.length; x++) {
                const floor = state.internalBuilding.building.floors[x];
                for (let y = 0; y < floor.locations.length; y++) {
                    const location = floor.locations[y];
                    if (location.hardwareId === action.payload.hardwareId) {
                        hwCurrentPosition = {floor: x, index: y};
                    }
                }
            }

            // Remove position
            if (hwCurrentPosition !== null) {
                state.internalBuilding.building.floors[hwCurrentPosition.floor].locations.splice(hwCurrentPosition.index, 1);
            }

            // Add position
            state.anyChangesMade = true;
            state.internalBuilding.building.floors[state.floorIndex].locations.push({
                leftOffsetPct: action.payload.offsetXPct,
                topOffsetPct: action.payload.offsetYPct,
                hardwareId: action.payload.hardwareId,
                type: action.payload.type,
                hardwareName: action.payload.hardwareName,
            });
        },
    }},
    extraReducers: (builder) => {
        builder.addCase(actionGetInternalBuilding.fulfilled, (state, {payload}) => {
            state.internalBuilding = payload;
        })
        builder.addCase(actionUpdateBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingManagerPageSlice-actionUpdateBuildingPlan: wrong payload, skip!')
                return;
            }

            state.anyChangesMade = false;
            state.internalBuilding = payload;
        })
        builder.addCase(actionAddFloorToBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingManagerPageSlice-actionAddFloorToBuildingPlan: wrong payload, skip!')
                return;
            }

            state.anyChangesMade = false;
            state.internalBuilding = payload;
        })
        builder.addCase(actionResetBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingManagerPageSlice-actionResetBuildingPlan: wrong payload, skip!')
                return;
            }

            state.anyChangesMade = false;
            state.internalBuilding = payload;
        })
        builder.addCase(actionRemoveBuildingPlan.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('buildingManagerPageSlice-actionRemoveBuildingPlan: wrong payload, skip!')
                return;
            }

            state.anyChangesMade = false;
            state.internalBuilding = payload;
        })
    }
})

export default buildingManagerPageSlice.reducer
