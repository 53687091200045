import React from "react";
import "./Table.scss"
import {TableRow, TableRowData} from "./TableRow";
import {OrderOptions, OrderType} from "../../molecules/FunctionTable/FunctionTable";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";

/**
 * @alias TableRows
 * @category Components
 */
export type TableRows = Array<TableRowData>;

/**
 * @alias TableColumns
 * @category Components
 */
export type TableColumns = Array<TableColumnData>;

/**
 * @alias TableColumnData
 * @category Components
 */
type TableColumnData = {
    /** Column name **/
    name: string,
    /** Column type is action **/
    action?: boolean,
    /** Column type is state **/
    state?: boolean,
    /** Disable ordering functionality **/
    forceDisableOrder?: boolean,
}

type TableProps = {
    columns: TableColumns,
    rows: TableRows,
    order?: OrderOptions|null,
    dispatchChangeOrder?: ActionCreatorWithPayload<OrderOptions>
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {TableColumnData[]} columns - columns data
 * @param {TableRowData[]} rows - rows data
 * @param {OrderOptions|null|undefined} order - current order configuration
 * @param {ActionCreatorWithPayload<OrderOptions>|undefined} dispatchChangeOrder - change ordering
 */
export const Table = ({columns, rows, order, dispatchChangeOrder} : TableProps): React.JSX.Element => {

    const dispatch = useAppDispatch();

    return (
        <table className={"Table"}>
            <thead className={"Table-head"}>
                <tr className={"Table-head-row"}>
                    {columns.map((column: TableColumnData, index: number) => {
                        return (
                            <th
                                key={`column-${index}`}
                                className={`Table-head-row-column ${column.action ? 'action' : ''} ${column.state ? 'state' : ''}`}>
                                <div className={'Table-head-row-column-inside'}>
                                    {!column.action ? column.name : ''}
                                    {dispatchChangeOrder && !column.action && !column.forceDisableOrder &&
                                        <div className={'Table-head-row-column-inside-order'}>
                                            <div className={`Table-head-row-column-inside-order-item 
                                                ${order && order.orderType === OrderType.ASC && order.columnIndex === index ? 'active' : ''}`}
                                                onClick={() => {
                                                    dispatch(dispatchChangeOrder({orderType: OrderType.ASC, columnIndex: index}))
                                                }}>
                                                <div className={'Table-head-row-column-inside-order-item-triangle up triangle'}></div>
                                            </div>

                                                <div className={`Table-head-row-column-inside-order-item 
                                                     ${order && order.columnIndex === index && order.orderType === OrderType.DESC ? 'active' : ''}`}
                                                     onClick={() => {
                                                        dispatch(dispatchChangeOrder({orderType: OrderType.DESC, columnIndex: index}))
                                                     }}>
                                                    <div className={'Table-head-row-column-inside-order-item-triangle down triangle'}></div>
                                                </div>
                                        </div>
                                    }
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            {rows.map((row: TableRowData, indexRow: number) => {
                return (
                    <TableRow
                        key={`row-${indexRow}`}
                        row={row}
                        indexRow={indexRow}
                        columns={columns} />
                )
            })}
        </table>
    );
}

export default Table;
