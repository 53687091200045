/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum SensorTypeEnum {
    TEMPERATURE = 1,
    HEARTBEAT = 2,
    HUMIDITY = 3,
    BUTTON = 4,
    CONTACT = 5,
    OCCUPANCY = 6,
    TAMPER = 7,
    BATTERY = 8,
    SPO2 = 9,
    STEPS = 10,
    BLOOD_PRESSURE = 11,
    HEART_RATE_VARIABILITY = 12,
    STRESS = 13,
    SLEEP = 14,
    SOS = 15,
    FALL_DETECTION = 16,
    CONNECTION = 17,
    ZONE = 18,
}
