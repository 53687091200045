import {createSlice, PayloadAction} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareScanRequestState {
    error: string|null
}

const initialState: HardwareScanRequestState = {
    error: null
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareScanRequestOrganismSlice = createSlice({
    name: 'hardwareScanRequestOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.error = null;
        },
        setError: (state, action: PayloadAction<{error: string|null }>) => {
            state.error = action.payload.error;
        }
    }
})

export default hardwareScanRequestOrganismSlice.reducer