import {createSlice} from '@reduxjs/toolkit'
import {actionGetAlertData} from "../../actions/organisms/alertGraphsAction";
import {AlertDataResponseDto} from "../../../models/entities/Responses/AlertDataResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface AlertGraphsState {

    /** Alert graph data **/
    sensorData?: Array<AlertDataResponseDto>|null,
}

const initialState: AlertGraphsState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const alertGraphsOrganismSlice = createSlice({
    name: 'alertGraphsOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => { state.sensorData = undefined; },
    },
    extraReducers: (builder) => {
        builder.addCase(actionGetAlertData.fulfilled, (state, {payload}) => {
            state.sensorData = payload;
        })
    }
})

export default alertGraphsOrganismSlice.reducer