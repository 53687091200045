import React, {useRef} from "react";
import "./HardwareQRGen.scss"
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import close from "../../../images/closeWhite.svg";
import {Image} from "../../atoms/Image/Image";
import {T, useTranslate} from "@tolgee/react";
import {QRCodeCanvas} from "qrcode.react";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import Button from "../../atoms/Button/Button";

type HardwareQRGenProps =  {
    dispatchHideModal: ActionCreatorWithoutPayload,
    hardware: HardwareResponseDto
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - hide modal
 * @param {HardwareResponseDto} hardware - hardware data
 */
export const HardwareQRGen = ({dispatchHideModal, hardware} : HardwareQRGenProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const canvasContainerRef = useRef<HTMLDivElement>(null);

    const handleDownload = () => {

        if (!canvasContainerRef || !canvasContainerRef.current) { return; }
        const childElements = canvasContainerRef.current.children;
        if (childElements.length === 0) { return; }
        const canvas = childElements[0] as HTMLCanvasElement;

        const tempLink = document.createElement('a');
        tempLink.href = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        tempLink.setAttribute('download', `${hardware.Hardware.internalId ?? hardware.Hardware.uid}.png`);
        tempLink.click();
    }

    return (
        <div className="HardwareQRGen">
            <div className="HardwareQRGen-content">
                <div className="HardwareQRGen-content-title">
                    <div className="HardwareQRGen-content-title-text">
                        <T keyName={'HardwareQRGen_title'} />
                    </div>
                    <div className="HardwareQRGen-content-title-close">
                        <Image onClick={() => dispatch(dispatchHideModal())}
                               source={close}
                               description={t("Base_close_modal")}
                               size={{width: 20, height: 20}} />
                    </div>
                </div>
                <div className="HardwareQRGen-content-gen">
                    <div className="HardwareQRGen-content-gen-inside">
                        <div className="HardwareQRGen-content-gen-inside-svg" ref={canvasContainerRef}>
                            <QRCodeCanvas size={300} bgColor={'white'} includeMargin={true} value={JSON.stringify({
                                UUID: hardware.Hardware.uid,
                                TemplateId: hardware.HardwareTemplate.id,
                            })}/>
                        </div>
                        <div className="HardwareQRGen-content-gen-inside-download">
                            <Button
                                onClick={() => { handleDownload(); }}
                                text={t('HardwareQRGen_download_qr')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HardwareQRGen;
