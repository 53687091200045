import {createSlice, PayloadAction} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface UserDetailPageState {
    paginationPage: number,
}

const initialState: UserDetailPageState = {
    paginationPage: 0
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const userDetailPageSlice = createSlice({
    name: 'userDetailPage',
    initialState,
    reducers: {
        destroyPage: (state: UserDetailPageState) => {
            state.paginationPage = 0;
        },
        changePaginationPage: (state: UserDetailPageState, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
    },
})

export default userDetailPageSlice.reducer