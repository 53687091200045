import {Communicator, RequestType} from "../utils/Communicator";
import {BackendCodesEnum} from "../entities/Enums/BackendCodesEnum";
import {UserDto} from "../entities/UserDto";
import {TokenResponseDto} from "../entities/Responses/TokenResponseDto";
import {CompanyRequestDto} from "../entities/Requests/CompanyRequestDto";
import {UserRegisterRequestDto} from "../entities/Requests/UserRegisterRequestDto";
import {PasswordResetRequestDto} from "../entities/Requests/PasswordResetRequestDto";
import {PasswordSetRequestDto} from "../entities/Requests/PasswordSetRequestDto";
import {FcmTokenRequestDto} from "../entities/Requests/FcmTokenRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class AuthorizationRepository {

    /**
     * Log in to get token
     * @param {string} email - user email
     * @param {string} password - user password
     * @param {TranslatorType} t - translator object
     */
    public static async logIn(email: string, password: string, t: TranslatorType) : Promise<TokenResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "authorization",
            disableAuth: true,
            body: {email: email, password: password}
        });

        if (response.ok && response.data && response.data.token) { return response.data; }
        if (response.apiError?.code === 'INVALID_LOGIN_CREDENTIALS') { throw new Error(t('AuthorizationRepository_logInErrCredentials')); }
        if (response.apiError?.code === 'DISABLED_USER') { throw new Error(t('AuthorizationRepository_logInErrDisabled')); }
        if (response.apiError?.code === 'USER_FORBIDDEN') { throw new Error(t('AuthorizationRepository_logInErrRole')); }
        if (response.apiError?.code === 'USER_FOR_AUTH_NOT_FOUND') { throw new Error(t('AuthorizationRepository_logInErrEmail')); }
        throw new Error(t('AuthorizationRepository_logIn'))
    }

    /**
     * Send Firebase Messaging API token
     * @param {FcmTokenRequestDto} request - token request
     * @param {string} authToken - bearer token string
     * @param {TranslatorType} t - translator object
     */
    public static async sendFirebaseMessagingToken(request: FcmTokenRequestDto, authToken: string, t: TranslatorType) : Promise<void> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "fcm/token",
            body: request,
            forceAuthToken: authToken
        });

        if (response.ok) { return; }
        throw new Error(t('AuthorizationRepository_sendFirebaseMessagingToken'))
    }


    /**
     * Register new user as admin
     * @param {UserRegisterRequestDto} userRequest - info about user
     * @param {CompanyRequestDto} companyRequest - info about company
     * @param {TranslatorType} t - translator object
     */
    public static async register(userRequest: UserRegisterRequestDto, companyRequest: CompanyRequestDto, t: TranslatorType) : Promise<UserDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "authorization/register",
            disableAuth: true,
            disableForceLogout: true,
            body: {User: userRequest, Company: companyRequest}
        });

        if (response.ok) { return response.data; }
        if (response.httpCode === BackendCodesEnum.BAD_REQUEST) { throw new Error(t('AuthorizationRepository_registerErrServerDeclined')); }
        if (response.apiError?.code === 'CREATE_USER_EXISTS') { throw new Error(t('AuthorizationRepository_registerErrExists')); }
        throw new Error(t('AuthorizationRepository_register'))
    }

    /**
     * Reset user password using email
     * @param resetRequest - request data (contains email)
     * @param {TranslatorType} t - translator object
     */
    public static async reset(resetRequest: PasswordResetRequestDto, t: TranslatorType) : Promise<void> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "authorization/password/reset",
            disableAuth: true,
            body: resetRequest,
        });

        if (response.ok) { return ; }
        throw new Error(t('AuthorizationRepository_reset'));
    }

    /**
     * Set new password in recovery process
     * @param {PasswordSetRequestDto} passwordSetRequest - request data (contains email)
     * @param {TranslatorType} t - translator object
     */
    public static async setNewPassword(passwordSetRequest: PasswordSetRequestDto, t: TranslatorType) : Promise<TokenResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "authorization/password/set",
            disableAuth: true,
            body: passwordSetRequest
        });

        if (response.ok) { return response.data; }
        throw new Error(t('AuthorizationRepository_setNewPassword'));
    }
}
