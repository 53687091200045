import "./Label.scss";
import React from "react";

type LabelProps = {
    text: string
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string} text - text inside label
 */
export const Label = ({text} : LabelProps): React.JSX.Element => {

    return (
        <div className={`Label red`}>
            {text}
        </div>
    );
}

export default Label;
