import React, {useEffect} from "react";
import "./BulkListPatientZones.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {TFnType, useTranslate} from "@tolgee/react";
import BulkList, {BulkListItem} from "../../molecules/BulkList/BulkList";
import {actionRemovePatientZoneList} from "../../../store/actions/data/patientAction";
import {PatientDto} from "../../../models/entities/PatientDto";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {bulkListPatientZonesOrganismSlice} from "../../../store/slices/organisms/bulkListPatientZonesSlice";
import zoneIcon from "../../../images/zones.svg";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";

type BulkListPatientZonesProps =  {
    patient: PatientDto,
    dispatchShowAddZoneModal: ActionCreatorWithoutPayload,
    bannedZonesList: ZoneDto[]
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {PatientDto} patient - patient data
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {ActionCreatorWithoutPayload} dispatchShowAddZoneModal - show modal Redux callback
 * @param {ZoneDto[]} bannedZonesList - list of banned zones
 */
export const BulkListPatientZones = ({dispatchShowAddZoneModal, patient, bannedZonesList} : BulkListPatientZonesProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const deleteInProgressState = useAppSelector((state) => state.bulkListPatientZonesOrganism.deleteProgress);

    // Cleanup
    useEffect(() => { return () => { dispatch(bulkListPatientZonesOrganismSlice.actions.destroyOrganism()); } }, [dispatch]);

    // Parse HW to bulk list items
    const parseZonesIntoBulkListItems = (zoneList: Array<ZoneDto>) : Array<BulkListItem> => {

        return zoneList.map((zone: ZoneDto) => {
            return {
                id: zone.id,
                name: zone.name,
                icon: zoneIcon
            }
        });
    }

    // When removed one item
    const removedOneCallback = (item: BulkListItem) => {
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListPatientZones_removedOne', {name: item.name})
        }));
    }

    // When removed more items
    const removedMoreCallback = () => {
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListPatientZones_removedMore')
        }));
    }

    // When basic click on item
    const clickedOnItemRedirect = (item: BulkListItem) => {
        navigation(`/zone/${item.id}`)
    }

    // When removed one
    const removeOneCallback = (item: BulkListItem) => {
        return dispatch(actionRemovePatientZoneList({requestEntity: {zoneList: [item.id]}, t: t, patientId: patient.id}))
    }

    // When removed one
    const removeMoreCallback = (selectedToDelete: number[], t: TFnType) => {
        return dispatch(actionRemovePatientZoneList({requestEntity: {zoneList: selectedToDelete}, t: t, patientId: patient.id}))

    }

    // Loading state
    if (typeof bannedZonesList === "undefined") {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state zone
    if (bannedZonesList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_patient_banned_zones')})} />
        )
    }

    return (
        <div className={`BulkListPatientZones`}>
            <BulkList
                pagination={{size: 10}}
                deleteProgress={deleteInProgressState}
                emptyTextTranslationString={'BulkListPatientZones_zoneBulk_empty_text'}
                emptyTitleTranslationString={'BulkListPatientZones_zoneBulk_empty_title'}
                assignButtonTranslationString={'BulkListPatientZones_zoneBulk_button'}
                titleTranslationString={'BulkListPatientZones_zoneBulk_title'}
                callbacks={{
                    removedOneCallback: removedOneCallback,
                    removedBulkCallback: removedMoreCallback,
                    onItemClickCallback: clickedOnItemRedirect,
                    callBackRemoveOne: removeOneCallback,
                    callBackRemoveBulk: removeMoreCallback
                }}
                itemList={parseZonesIntoBulkListItems(bannedZonesList)}
                dispatchShowAddModal={dispatchShowAddZoneModal} />
        </div>
    );
}

export default BulkListPatientZones;
