import { createAsyncThunk } from "@reduxjs/toolkit";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {HiveRepository} from "../../../models/repositories/HiveRepository";

/**
 * Get HIVE data cached
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HiveResponseDto | null}
 */
export const actionGetHiveCached = createAsyncThunk<HiveResponseDto | null>(
    "hive/getHiveCached",
    async () => {
        return await HiveRepository.getHive(true);
    }
);

/**
 * Get HIVE data without cache
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HiveResponseDto | null}
 */
export const actionGetHiveFresh = createAsyncThunk<HiveResponseDto | null>(
    "hive/getHiveFresh",
    async () => {
        return await HiveRepository.getHive(false);
    }
);
