import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionUpdateUser} from "../../actions/data/userAction";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {OrderOptions} from "../../../components/molecules/FunctionTable/FunctionTable";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum ListUserFilterTypes {
    ALL = 1,
    ACTIVE = 3,
    FULLTEXT = 7,
    ROLE = 9,
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwarePageState {

    /** Add or edit user modal shown **/
    addOrEditUserModalShown: boolean,
    /** Add or edit user modal user id **/
    addOrEditUserModalId: number|null,
    /** User notify options modal shown **/
    userNotifyOptionsModalShown: boolean,
    /** User notify options user id **/
    userNotifyOptionsModalUserId: number|null,
    /** Filter type **/
    filterType: ListUserFilterTypes,
    /** Filter by role **/
    role: RoleEnum|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Pagination current page **/
    paginationPage: number,
    /** Pagination ordering **/
    paginationOrder: OrderOptions|null,
}

const initialState: HardwarePageState = {
    addOrEditUserModalShown: false,
    userNotifyOptionsModalShown: false,
    userNotifyOptionsModalUserId: null,
    filterType: ListUserFilterTypes.ACTIVE,
    addOrEditUserModalId: null,
    fullTextValue: null,
    role: null,
    filterTitle: null,
    paginationPage: 0,
    paginationOrder: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const userPageSlice = createSlice({
    name: 'userPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.addOrEditUserModalShown = false;
            state.userNotifyOptionsModalShown = false;
            state.filterType = ListUserFilterTypes.ACTIVE;
            state.addOrEditUserModalId = null;
            state.userNotifyOptionsModalUserId = null;
            state.fullTextValue = null;
            state.role = null;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        destroyFilter: state => {
            state.filterType = ListUserFilterTypes.ACTIVE;
            state.fullTextValue = null;
            state.role = null;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        changePaginationOrder: (state, action: PayloadAction<OrderOptions>) => {
            state.paginationOrder = action.payload;
        },
        filterRole: (state, action: PayloadAction<{role: RoleEnum, title: string }>) => {
            state.filterType = ListUserFilterTypes.ROLE;
            state.role = action.payload.role;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterAll: state => {
            state.filterType = ListUserFilterTypes.ALL;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterFullText: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
            if (action.payload.inputValue === null) {
                state.filterType = ListUserFilterTypes.ACTIVE;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = ListUserFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
        changePaginationPage: (state, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
        showAddOrEditUserModal: (state, action: PayloadAction<{userId: number|null }>) => {
            state.userNotifyOptionsModalShown = false;
            state.userNotifyOptionsModalUserId = null;
            state.addOrEditUserModalShown = true;
            state.addOrEditUserModalId = action.payload.userId;
        },
        hideAddOrEditUserModal: state => {
            state.addOrEditUserModalShown = false;
            state.addOrEditUserModalId = null;
        },
        showUserNotifyOptionsModal: (state, action: PayloadAction<{userId: number|null }>) => {
            state.addOrEditUserModalShown = false;
            state.addOrEditUserModalId = null;
            state.userNotifyOptionsModalShown = true;
            state.userNotifyOptionsModalUserId = action.payload.userId;
        },
        hideUserNotifyOptionsModal: state => {
            state.userNotifyOptionsModalShown = false;
            state.userNotifyOptionsModalUserId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionUpdateUser.fulfilled, (state) => {
            state.addOrEditUserModalShown = false;
            state.addOrEditUserModalId = null;
            state.paginationOrder = null;
            state.paginationPage = 0;
        })
    }
})

export default userPageSlice.reducer
