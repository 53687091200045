import React, {FormEvent, useEffect} from "react";
import "./AddOrEditUser.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {DataParser} from "../../../models/utils/DataParser";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {addOrEditUserOrganismSlice} from "../../../store/slices/organisms/addOrEditUserSlice";
import {actionCreateUser, actionGetUserList, actionUpdateUser} from "../../../store/actions/data/userAction";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {UserUtil} from "../../../models/utils/UserUtil";
import personIcon from "../../../images/person.svg";
import {UserCreateRequestDto} from "../../../models/entities/Requests/UserCreateRequestDto";
import {UserUpdateRequestDto} from "../../../models/entities/Requests/UserUpdateRequestDto";
import {T, useTranslate} from "@tolgee/react";

/**
 * @enum {string}
 * @category Components
 */
export enum AddOrEditUserRoleColor {
    PINK = "pink",
    BLUE = "blue",
    DEFAULT = "default",
    YELLOW = "yellow"
}

type AddOrEditUserProps = {
    userId: number|null,
    dispatchHideAddOrEditUserModal: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideAddOrEditUserModal - hide modal Redux callback
 * @param {number|null} userId - user id for edit mode
 */
export const AddOrEditUser = ({dispatchHideAddOrEditUserModal, userId} : AddOrEditUserProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t, isLoading} = useTranslate();

    // Data state
    const userListState = useAppSelector((state) => state.userData.userList);

    // Form state
    const firstNameState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'firstName'));
    const lastNameState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'lastName'));
    const phoneState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'phone'));
    const emailState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'email'));
    const branchState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'branch'));
    const roleState = useAppSelector((state) => selectInputState(state.addOrEditUserOrganism, 'role'));
    const formValidState = useAppSelector((state) => selectValidState(state.addOrEditUserOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.addOrEditUserOrganism));

    // In edit mode init current role
    useEffect(() => {
        const parsedUser = DataParser.getUserFromList(userListState, userId);
        if (parsedUser) {
            dispatch(addOrEditUserOrganismSlice.actions.updateInput({inputName: 'role', inputValue: parsedUser.User.role,
                t: t}));
        }
    }, [dispatch, userId, userListState, t])

    // Re-load data && Destroy
    useEffect(() => { if (!userListState) { dispatch(actionGetUserList()); } }, [dispatch, userListState])
    useEffect(() => {
        return () => { dispatch(addOrEditUserOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!firstNameState || !firstNameState.value || !lastNameState || !lastNameState.value || !emailState || !emailState.value
            || !roleState || !roleState.value) {
            dispatch(addOrEditUserOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        // @todo created user should be returned in payload
        let result;
        if (userId) {
            const userRequest : UserUpdateRequestDto = {
                firstName: firstNameState.value.toString(),
                lastName: lastNameState.value.toString(),
                email: emailState.value.toString(),
                phone: phoneState && phoneState.value ? phoneState.value.toString() : null,
                branch: branchState && branchState.value ? branchState.value.toString() : null,
                role: parseInt(roleState.value.toString()),
            }
            result = await dispatch(actionUpdateUser({userId: userId, userRequest: userRequest, t: t}));
        } else {
            const userRequest : UserCreateRequestDto = {
                firstName: firstNameState.value.toString(),
                lastName: lastNameState.value.toString(),
                email: emailState.value.toString(),
                phone: phoneState && phoneState.value ? phoneState.value.toString() : null,
                branch: branchState && branchState.value ? branchState.value.toString() : null,
                role: parseInt(roleState.value.toString()),
            }
            result = await dispatch(actionCreateUser({userRequest: userRequest, t:t}));
            if (result.meta.requestStatus === "fulfilled" && result.payload && typeof result.payload === "object") {
                //navigation(`/user/${result.payload.createdPatient.Patient.id}`);
            }
        }

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(addOrEditUserOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(actionGetUserList());
        dispatch(dispatchHideAddOrEditUserModal());
    }

    // Prepare data for usage
    const parsedUser = DataParser.getUserFromList(userListState, userId);

    // Loading hardware
    if ((userId && typeof parsedUser === 'undefined') || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('AddOrEditUser_title')}
                modal={{dispatchHide: dispatchHideAddOrEditUserModal}}/>
        )
    }

    // Error state
    if (userId && parsedUser === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideAddOrEditUserModal ? {
                    name: t('AddOrEditUser_title'),
                    dispatchHide: dispatchHideAddOrEditUserModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description", {dataName: t('Base_name_user')})} />
        )
    }

    const parsedRole = roleState && roleState.value ? parseInt(roleState.value.toString())
        : (parsedUser ? parsedUser.User.role : null)

    const form = (
        <form onSubmit={ (event: FormEvent<HTMLFormElement>) => { void handleSubmit(event); }}>
            <div className="AddOrEditUser-content-form-inputs">
                <div className="AddOrEditUser-content-form-inputs-flex">
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditUser_firstName_placeholder')}
                        inputName="firstName"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditUser_firstName_label')}
                        disabled={false}
                        startValue={parsedUser ? parsedUser.User.firstName : null}
                        value={firstNameState?.value}
                        inputState={firstNameState}
                        dispatchOnChange={addOrEditUserOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditUserOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditUserOrganismSlice.actions.removeFocus} />
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditUser_lastName_placeholder')}
                        inputName="lastName"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditUser_lastName_label')}
                        disabled={false}
                        startValue={parsedUser ? parsedUser.User.lastName : null}
                        value={lastNameState?.value}
                        inputState={lastNameState}
                        dispatchOnChange={addOrEditUserOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditUserOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditUserOrganismSlice.actions.removeFocus} />
                </div>
                <div className="AddOrEditUser-content-form-inputs-flex">
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditUser_phone_placeholder')}
                        inputName="phone"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditUser_phone_label')}
                        disabled={false}
                        startValue={parsedUser ? parsedUser.User.phone : null}
                        value={phoneState?.value}
                        inputState={phoneState}
                        dispatchOnChange={addOrEditUserOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditUserOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditUserOrganismSlice.actions.removeFocus} />
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditUser_email_placeholder')}
                        inputName="email"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditUser_email_label')}
                        disabled={!!userId}
                        startValue={parsedUser ? parsedUser.User.email : null}
                        value={emailState?.value}
                        inputState={emailState}
                        dispatchOnChange={addOrEditUserOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditUserOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditUserOrganismSlice.actions.removeFocus} />
                </div>
                <div className="AddOrEditUser-content-form-inputs-flex">
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditUser_branch_placeholder')}
                        inputName="branch"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditUser_branch_label')}
                        disabled={false}
                        startValue={parsedUser && parsedUser.User.branch ? parsedUser.User.branch : null}
                        value={branchState?.value}
                        inputState={branchState}
                        dispatchOnChange={addOrEditUserOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditUserOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditUserOrganismSlice.actions.removeFocus} />
                </div>
                <div className={"AddOrEditUser-roles"}>
                    <div className={"AddOrEditUser-roles-title"}>
                        <T keyName={'AddOrEditUser_selectRole'} />
                    </div>
                    <div className={"AddOrEditUser-roles-content"}>
                        {[RoleEnum.ADMINISTRATOR, RoleEnum.TECHNICAL, RoleEnum.MEDICAL].map((role: RoleEnum) => {
                            return (
                                <div
                                    onClick={ () => {
                                        dispatch(addOrEditUserOrganismSlice.actions.updateInput(
                                            {inputName: 'role', inputValue: role, t: t}));
                                    }}
                                    className={`AddOrEditUser-roles-content-item clickable 
                                        ${UserUtil.getRoleFEMetaFromRole(role, t).modalLabelColor}
                                        ${parsedRole === role ? 'active' : ''}`}
                                    key={`AddOrEditUser-roles-content-item-${role}`}>
                                    <Image
                                        source={UserUtil.getRoleFEMetaFromRole(role, t).image}
                                        description={'Role icon'} />
                                    <span className={`AddOrEditUser-roles-content-item-text text`}>
                                        {UserUtil.getRoleFEMetaFromRole(role, t).text}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="AddOrEditUser-content-form-inputs-button">
                <Button
                    image={personIcon}
                    text={userId ? t('AddOrEditUser_button_edit') : t('AddOrEditUser_button_add')}
                    type={ButtonType.PRIMARY}
                    onClickDisabled={() => { dispatch(addOrEditUserOrganismSlice.actions.showInputValidStates()); }}
                    disabled={!formValidState}
                    isSubmit={true} />
            </div>
        </form>
    )

    return (
        <div className="AddOrEditUser">
            <div className="AddOrEditUser-content">
                <div className="AddOrEditUser-content-title">
                    <div className="AddOrEditUser-content-title-text">
                        {userId ? t('AddOrEditUser_button_edit') : t('AddOrEditUser_button_add')}
                    </div>
                    <div className="AddOrEditUser-content-title-close">
                        {dispatchHideAddOrEditUserModal &&
                            <Image onClick={() => dispatch(dispatchHideAddOrEditUserModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AddOrEditUser-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AddOrEditUser;
