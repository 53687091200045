import {HardwareTypeEnum} from "../entities/Enums/HardwareTypeEnum";
import picGateway from "../../images/picGateway.svg";
import picWearable from "../../images/picWearable.svg";
import picStatic from "../../images/picStatic.svg";
import {HardwareTypesColor} from "../../components/organisms/HardwareTypes/HardwareTypes";
import {TableCellDataTextColor} from "../../components/atoms/Table/TableColumn";
import {EasyFilterColor} from "../../components/molecules/EasyFilter/EasyFilter";

/**
 * @class
 * @category Utils
 */
export class HardwareUtil {

    /**
     * Get hardware type information from hardware type
     * @function
     * @category Utils
     * @param {HardwareTypeEnum} hardwareType - hardware type
     * @param {any} t - translator object
     */
    public static getHardwareTypeMetaFromHardwareType (hardwareType: HardwareTypeEnum, t: any) : {
        title: string,
        image: any|null,
        hwTypesColor: HardwareTypesColor,
        cellColor: TableCellDataTextColor,
        filterColor: EasyFilterColor,
        colorClass: string
    } {
        switch (hardwareType) {
            case HardwareTypeEnum.GATEWAY: return {
                title: t('HardwareUtil_gateway'),
                image: picGateway,
                hwTypesColor: HardwareTypesColor.BLUE,
                cellColor: TableCellDataTextColor.BLUE,
                filterColor: EasyFilterColor.BLUE,
                colorClass: 'blue'
            };
            case HardwareTypeEnum.REPEATER: return {
                title: t('HardwareUtil_repeater'),
                image: picGateway,
                hwTypesColor: HardwareTypesColor.PINK,
                cellColor: TableCellDataTextColor.PINK,
                filterColor: EasyFilterColor.PINK,
                colorClass: 'pink'
            };
            case HardwareTypeEnum.STATIC: return {
                title: t('HardwareUtil_static'),
                image: picStatic,
                hwTypesColor: HardwareTypesColor.GREEN,
                cellColor: TableCellDataTextColor.GREEN,
                filterColor: EasyFilterColor.GREEN,
                colorClass: 'green'
            };
            case HardwareTypeEnum.WEARABLE: return {
                title: t('HardwareUtil_wearable'),
                image: picWearable,
                hwTypesColor: HardwareTypesColor.ORANGE,
                cellColor: TableCellDataTextColor.ORANGE,
                filterColor: EasyFilterColor.ORANGE,
                colorClass: 'orange'
            };
            default: return {
                title: t('HardwareUtil_unknown'),
                image: null,
                hwTypesColor: HardwareTypesColor.DEFAULT,
                cellColor: TableCellDataTextColor.DEFAULT,
                filterColor: EasyFilterColor.DEFAULT,
                colorClass: 'default'
            }
        }
    }
}