import "./Breadcrumb.scss"
import React from "react";
import {useNavigate} from "react-router-dom";
import backIcon from "../../../images/back.svg";
import {T, useTranslate} from "@tolgee/react";

/**
 * @interface
 * @category Components
 */
export interface BreadcrumbItem {

    /** Item name **/
    name: string,
    /** Link **/
    link: string|null
}

/** 
 * @alias DropdownProps
 * @category Components
 */
type BreadcrumbProps = {

    /** Breadcrumb items **/
    items: Array<BreadcrumbItem>
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {Array<BreadcrumbItem>} items - breadcrumb items
 */
export const Breadcrumb = ({items} : BreadcrumbProps): React.JSX.Element => {

    const {t} = useTranslate();
    const navigate = useNavigate();

    // Use native browser history go back function
    const handleGoNavigationBack = () => {
        navigate(-1);
    }

    return (
        <div className={`Breadcrumb`}>
            <div className={`Breadcrumb-mobile`} onClick={handleGoNavigationBack.bind(this)}>
                <div className={`Breadcrumb-mobile-icon`}>
                    <img src={backIcon} alt={t('Breadcrumb_back')} />
                </div>
                <div className={`Breadcrumb-mobile-text`}>
                    <T keyName={'Breadcrumb_back'} />
                </div>
            </div>
            <div className={`Breadcrumb-desktop`}>
                <div className={`Breadcrumb-desktop-content`}>
                    {items.map((item: BreadcrumbItem, index: number) => {
                        return (
                            <div
                                key={`Breadcrumb-desktop-content-item-${index}`}
                                className={`Breadcrumb-desktop-content-item ${item.link ? 'link' : ''}`}>
                                <div
                                    onClick={ () => { if (item.link) { navigate(item.link); } }}
                                    className={`Breadcrumb-desktop-content-item-name name`}>
                                    {item.name}
                                </div>
                                {index < items.length - 1 &&
                                    <div className={`Breadcrumb-desktop-content-item-separator`}>
                                        /
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;
