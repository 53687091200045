import React, {useEffect} from "react";
import "./BulkListPatientHardware.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {actionGetHiveFresh} from "../../../store/actions/data/hiveAction";
import {TFnType, useTranslate} from "@tolgee/react";
import {actionAssignHardware} from "../../../store/actions/data/hardwareAction";
import BulkList, {BulkListItem} from "../../molecules/BulkList/BulkList";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {actionRemovePatientHardwareList} from "../../../store/actions/data/patientAction";
import {PatientDto} from "../../../models/entities/PatientDto";
import {bulkListPatientHardwareOrganismSlice} from "../../../store/slices/organisms/bulkListPatientHardwareSlice";

type BulkListPatientHardwareProps =  {
    patient: PatientDto,
    hardwareList: Array<HardwareWithSensorsResponseDto>,
    dispatchShowAddHardwareModal: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {PatientDto} patient - patient data
 * @param {HardwareWithSensorsResponseDto[]} hardwareList - list of hardware
 * @param {ActionCreatorWithoutPayload} dispatchShowAddHardwareModal - show modal Redux callback
 */
export const BulkListPatientHardware = ({hardwareList, dispatchShowAddHardwareModal, patient} : BulkListPatientHardwareProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    // Init
    const deleteInProgressState = useAppSelector((state) => state.bulkListZoneHardwareOrganism.deleteProgress);

    // Cleanup
    useEffect(() => { return () => { dispatch(bulkListPatientHardwareOrganismSlice.actions.destroyOrganism()); } }, [dispatch]);

    // Parse HW to bulk list items
    const parseHWIntoBulkListItems = (hardwareList: Array<HardwareWithSensorsResponseDto>) : Array<BulkListItem> => {

        return hardwareList.map((hardware: HardwareWithSensorsResponseDto) => {
            return {
                id: hardware.Hardware.id,
                name: hardware.Hardware.internalId ?? hardware.Hardware.uid,
                icon: HardwareUtil.getHardwareTypeMetaFromHardwareType(hardware.HardwareTemplate.type, t).image
            }
        });
    }

    // When removed one item
    const removedOneCallback = (item: BulkListItem) => {
        dispatch(actionGetHiveFresh());
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListPatientHardware_removedOne', {name: item.name})
        }));
    }

    // When removed more items
    const removedMoreCallback = () => {
        dispatch(actionGetHiveFresh());
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListPatientHardware_removedMore', )
        }));
    }

    // When basic click on item
    const clickedOnItemRedirect = (item: BulkListItem) => {
        navigation(`/devices/${item.id}`)
    }

    // When removed one
    const removeOneCallback = (item: BulkListItem) => {
        return dispatch(actionAssignHardware({
            t: t,
            patientId: patient.id,
            zoneId: null,
            hardwareId: item.id,
            hardwareAssignRequest: {patient: null, zone: null}
        }))
    }

    // When removed one
    const removeMoreCallback = (selectedToDelete: number[], t: TFnType) => {
        return dispatch(actionRemovePatientHardwareList({
            requestEntity: {hardwareList: selectedToDelete}, t: t, patientId: patient.id
        }));
    }

    return (
        <div className={`BulkListPatientHardware`}>
            <BulkList
                pagination={{size: 8}}
                deleteProgress={deleteInProgressState}
                emptyTextTranslationString={'BulkListPatientHardware_hardwareBulk_empty_text'}
                emptyTitleTranslationString={'BulkListPatientHardware_hardwareBulk_empty_title'}
                assignButtonTranslationString={'BulkListPatientHardware_hardwareBulk_button'}
                titleTranslationString={'BulkListPatientHardware_hardwareBulk_title'}
                callbacks={{
                    removedOneCallback: removedOneCallback,
                    removedBulkCallback: removedMoreCallback,
                    onItemClickCallback: clickedOnItemRedirect,
                    callBackRemoveOne: removeOneCallback,
                    callBackRemoveBulk: removeMoreCallback
                }}
                itemList={parseHWIntoBulkListItems(hardwareList)}
                dispatchShowAddModal={dispatchShowAddHardwareModal} />
        </div>
    );
}

export default BulkListPatientHardware;
