import React, {useEffect} from "react";
import {BuildingManagerTemplate} from "../templates/BuildingManagerTemplate/BuildingManagerTemplate";
import {actionGetInternalBuilding} from "../../store/actions/data/buildingAction";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {buildingManagerPageSlice} from "../../store/slices/pages/buildingManagerSlice";
import {buildingConstructorPageSlice} from "../../store/slices/pages/buildingConstructorSlice";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {BuildingConstructorTemplate} from "../templates/BuildingConstructorTemplate/BuildingConstructorTemplate";
import {Storage} from "../../models/utils/Storage";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const BuildingManagerPage = (): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const buildingState = useAppSelector((state) => state.buildingManagerPage.internalBuilding);
    const role = Storage.getSession()?.user.role;

    useEffect(() => { if (!buildingState) { dispatch(actionGetInternalBuilding());} }, [dispatch, buildingState])

    useEffect(() => { return () => {
        dispatch(buildingManagerPageSlice.actions.destroyPage());
        dispatch(buildingConstructorPageSlice.actions.destroyPage());
    } }, [dispatch]);

    // Wrong session state
    // noinspection DuplicatedCode
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    if (typeof buildingState === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (buildingState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_building')})} />
    }

    // Choose current view (is building already saved)
    if (buildingState.building) {
        return ( <BuildingManagerTemplate role={role} /> )
    } else {
        return ( <BuildingConstructorTemplate  role={role} /> )
    }
};

export default BuildingManagerPage;
