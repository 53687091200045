import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AbstractFormReducersState, AbstractFormUpdatePayload, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface BuildingPlanState extends AbstractFormReducersState {

    /** Is second step **/
    isNextStep: boolean,
    /** Current transparency **/
    transparency: number,
    /** Create current error **/
    error: string|null,
}

const initialState: BuildingPlanState = {
    isNextStep: false,
    error: null,
    transparency: 80,
    form: {
        inputs: [
            {name: 'name', validations: {required: true}},
            {name: 'floors', validations: {required: true}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingPlanOrganismSlice = createSlice({
    name: 'buildingPlanOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        destroyOrganism: (state: BuildingPlanState) => {
            state.error = null;
            state.transparency = 80;
            state.isNextStep = false;
        },
        setTransparency: (state: BuildingPlanState, action: PayloadAction<AbstractFormUpdatePayload>) => {
            if (action.payload.inputValue) {
                state.transparency = parseInt(action.payload.inputValue.toString());
            }
        },
        setNextStep: (state: BuildingPlanState) => {
            state.isNextStep = true;
        },
        setError: (state: BuildingPlanState, action: PayloadAction<{ error: string|null }>) => {
            state.error = action.payload.error;
        }
    }}
})

export default buildingPlanOrganismSlice.reducer