import React, {useRef} from "react";
import "./BuildingObserver.scss"
import fullscreen from "../../../images/fullscreen.svg"
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {CustomDraggableAllowedContent} from "../../molecules/CustomDraggable/CustomDraggable";
import {useAppDispatch} from "../../../store/hooks";
import {
    AbstractBuildingUpdateActivePayload,
    InternalBuildingBuilding,
    InternalBuildingFloor,
    UpdateFloorCallback
} from "../../../models/utils/AbstractBuildingReducers";
import plus from "../../../images/plus.svg";
import minus from "../../../images/minus.svg";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {HivePatientResponseDto} from "../../../models/entities/Responses/HivePatientResponseDto";
import {DraggablePlanElementHardwareComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementHardwareComponent";
import {DraggablePlanElementPatientComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementPatientComponent";
import {T, useTranslate} from "@tolgee/react";
import Building from "../../molecules/Building/Building";
import EasyFilter, {EasyFilterItem} from "../../molecules/EasyFilter/EasyFilter";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type BuildingObserverProps = {
    currentZoom: number,
    hive: HiveResponseDto,
    currentFloorIndex: number,
    activePatient: AbstractBuildingUpdateActivePayload|null,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    currentFloorElevation: number,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    building: InternalBuildingBuilding,
    allowedContent: CustomDraggableAllowedContent|null,
    initialized: boolean,
    dispatchShowFullscreen: ActionCreatorWithoutPayload,
    dispatchUpdateZoom: ActionCreatorWithPayload<{ zoom: number }>,
    dispatchInitialized: ActionCreatorWithoutPayload,
    dispatchRemoveActives: ActionCreatorWithoutPayload,
    updateFloorCallback: UpdateFloorCallback,
    dispatchUpdateActivePatient: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchUpdateActiveHardware: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchUpdateDraggableAllowedContent: ActionCreatorWithPayload<CustomDraggableAllowedContent>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {InternalBuildingBuilding} building - contains internal building data type
 * @param {CustomDraggableAllowedContent|null} allowedContent - allowed bounds
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - active hardware id
 * @param {number} currentFloorElevation - active floor elevation number
 * @param {updateFloorCallback} updateFloorCallback - update current viewed floor elevation,
 * @param {number} currentZoom - current zoom level
 * @param {ActionCreatorWithPayload<{ zoom: number }>} dispatchUpdateZoom - Redux update zoom level callback
 * @param {SensorTypeDto[]} sensorTypes - list of all sensors
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {ActionCreatorWithPayload<CustomDraggableAllowedContent>} dispatchUpdateDraggableAllowedContent - update plan bounds
 * @param {ActionCreatorWithoutPayload} dispatchShowFullscreen - show fullscreen modal
 * @param {HiveResponseDto} hive - hive data
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardware - update active hardware
 * @param {AbstractBuildingUpdateActivePayload|null} activePatient - active patient id
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActivePatient - Redux callback to update active patient
 * @param {number} currentFloorIndex - current selected floor index
 * @param {boolean} initialized - view is initialized
 * @param {ActionCreatorWithoutPayload} dispatchInitialized - set view initialized
 * @param {ActionCreatorWithoutPayload} dispatchRemoveActives - Redux callback to remove actives
 */
export const BuildingObserver = ({building, updateFloorCallback, currentZoom, dispatchUpdateZoom, sensorTypes, zoneList, currentFloorIndex,
    activeHardware, allowedContent, dispatchUpdateDraggableAllowedContent, dispatchShowFullscreen, hive, activePatient, initialized,
    dispatchUpdateActiveHardware, currentFloorElevation, dispatchUpdateActivePatient, dispatchInitialized,
    dispatchRemoveActives} : BuildingObserverProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const buildingContainerRef: React.MutableRefObject<HTMLDivElement|null> = useRef(null);

    // Filter HIVE hardware list for that one with location info
    const getHiveHardwareListWithLocation = (currentFloor: number) : Array<HardwareWithSensorsResponseDto> => {
        return hive.UnassignedHardware.filter((hiveHardware: HardwareWithSensorsResponseDto) => {
            return hiveHardware.HardwareLocation !== null
                && hiveHardware.BuildingFloor
                && ((hiveHardware.BuildingFloor.elevation - 1) === currentFloor);
        });
    }

    // Filter HIVE patient list for that one with location info
    const getHivePatientListWithLocation = (currentFloor: number) : Array<HivePatientResponseDto> => {
        return hive.HivePatients.filter((hivePatient: HivePatientResponseDto) => {
            return hivePatient.Patient.calcLocPlanOffsetLeft !== null
                && hivePatient.Patient.calcLocPlanOffsetTop !== null
                && hivePatient.Patient.calcLocBuildingFloorElevation !== null
                && (hivePatient.Patient.calcLocBuildingFloorElevation - 1) === currentFloor;
        });
    }

    const getHardwareElementsList = (allowedContent: CustomDraggableAllowedContent) : Array<React.JSX.Element>|null => {

        let output = getHiveHardwareListWithLocation(currentFloorIndex).map((hardware: HardwareWithSensorsResponseDto, index: number) => {
            return (
                <DraggablePlanElementHardwareComponent
                    activeHardware={activeHardware}
                    zoneList={zoneList}
                    allowedContent={allowedContent}
                    currentZoom={currentZoom}
                    dispatchUpdateActiveHardware={dispatchUpdateActiveHardware}
                    hardware={hardware}
                    key={`DraggablePlanElementHardwareComponent-${index}`}
                    sensorTypes={sensorTypes} />
            );
        })

        output = output.concat(getHivePatientListWithLocation(currentFloorIndex).map((patient: HivePatientResponseDto, index: number) => {
            return (
                <DraggablePlanElementPatientComponent
                    key={`DraggablePlanElementPatientComponent-${index}`}
                    patient={patient}
                    zoneList={zoneList}
                    allowedContent={allowedContent}
                    activePatient={activePatient}
                    currentZoom={currentZoom}
                    dispatchUpdateActivePatient={dispatchUpdateActivePatient}
                    sensorTypes={sensorTypes} />
            );
        }));

        return output;
    }

    const convertFloorsToEasyFilterItems = () : Array<EasyFilterItem> => {

        return building.floors.map((floor: InternalBuildingFloor) => {
            return {
                title: floor.elevation + '. ' + t('Base_name_floor'),
                active: currentFloorElevation === floor.elevation,
                callBackFilterChange: () => { updateFloorCallback(floor.elevation); }
            }
        })
    }

    return (
        <div className={"BuildingObserver"}>
            <div className={"BuildingObserver-content"} ref={buildingContainerRef}>
                <div className={"BuildingObserver-content-menu"}>
                    <div className={"BuildingObserver-content-menu-section"}>
                        <div className={"BuildingObserver-content-menu-section-zoom"}>
                            <div className={"BuildingObserver-content-menu-section-zoom-control"}
                                onClick={ () => { dispatch(dispatchUpdateZoom({
                                    zoom: BuildingUtil.calculateCurrentZoomDecrease(currentZoom)
                                })); } }>
                                <img src={minus} alt={t('Base_decrease_zoom')}/>
                            </div>
                            <div className={"BuildingObserver-content-menu-section-zoom-value"}>
                                {Math.round(currentZoom * 100)}%
                            </div>
                            <div className={"BuildingObserver-content-menu-section-zoom-control"}
                                 onClick={ () => { dispatch(dispatchUpdateZoom({
                                     zoom: BuildingUtil.calculateCurrentZoomIncrease(currentZoom)
                                 })); } }>
                                <img src={plus} alt={t('Base_increase_zoom')}/>
                            </div>
                        </div>
                    </div>
                    <div className={"BuildingObserver-content-menu-section"}>
                        <div className={"BuildingObserver-content-menu-section-floor"}>
                            <EasyFilter
                                dropdownMode={true}
                                items={convertFloorsToEasyFilterItems()} />
                        </div>
                    </div>
                    <div className={"BuildingObserver-content-menu-section"}>
                        <div className={"BuildingObserver-content-menu-section-controls"}>
                            <div className={"BuildingObserver-content-menu-section-controls-item"}>
                                <div className={"BuildingObserver-content-menu-section-controls-item-save"}
                                    onClick={ () => { dispatch(dispatchShowFullscreen()); } }>
                                    <img src={fullscreen} alt={t('Base_fullscreen')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Building
                    dispatchRemoveActives={dispatchRemoveActives}
                    dispatchInitialized={dispatchInitialized}
                    initialized={initialized}
                    dispatchUpdateZoom={dispatchUpdateZoom}
                    dispatchUpdateDraggableAllowedContent={dispatchUpdateDraggableAllowedContent}
                    buildingContainerElement={buildingContainerRef.current ? buildingContainerRef.current : null}
                    plan={building.floors[currentFloorIndex].plan}
                    currentZoom={currentZoom}
                    draggableElements={allowedContent ? getHardwareElementsList(allowedContent) : null}
                />
                <div className={'BuildingObserver-content-fullScreen'} onClick={ () => { dispatch(dispatchShowFullscreen()); } }>
                    <div className={'BuildingObserver-content-fullScreen-inside'}>
                        <div className={'BuildingObserver-content-fullScreen-inside-icon'}>
                            <img src={fullscreen} alt={t('Base_fullscreen')} />
                        </div>
                        <div className={'BuildingObserver-content-fullScreen-inside-text'}>
                            <T keyName={'Base_fullscreen'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingObserver;
