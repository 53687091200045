import {Communicator, RequestType} from "../utils/Communicator";
import {DataResponseDto} from "../entities/Responses/DataResponseDto";
import {SensorEditRequestDto} from "../entities/Requests/SensorEditRequestDto";
import {CacheExpiry} from "../utils/Storage";
import {SensorResponseDto} from "../entities/Responses/SensorResponseDto";
import {SensorTypeDto} from "../entities/SensorTypeDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class SensorRepository {

    /**
     * Get stats for sensor
     * @param {number} sensorId - sensor ID
     */
    public static async getDataListBySensor(sensorId: number) : Promise<DataResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `sensor/${sensorId}/data`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Update sensor
     * @param {SensorEditRequestDto} sensorEdit - info about update
     * @param {number} sensorId - sensor id
     * @param {TranslatorType} t - translator object
     */
    public static async updateSensor(sensorEdit: SensorEditRequestDto, sensorId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            endpoint: `sensor/${sensorId}`,
            body : sensorEdit,
            cache: {deleteKeys: ['getHive', 'getAlerts', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return; }
        throw new Error(t('SensorRepository_updateSensor'));
    }

    /**
     * Get sensor info by sensor ID
     * @param {number} sensorId - sensor id
     */
    public static async getSensor(sensorId: number) : Promise<SensorResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `sensor/${sensorId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get list of sensor types
     */
    public static async getSensorTypes() : Promise<Array<SensorTypeDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `sensorType`,
            cache: {cacheKey: `sensorType`, expiry: CacheExpiry.ONE_DAY, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get list of sensors for patient
     */
    public static async getSensorsForPatient(patientId: number) : Promise<Array<SensorResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `sensor?patient=${patientId}`,
        });

        if (response.ok) { return response.data.Sensors; }
        return null;
    }

    /**
     * Get list of sensors for hardware
     */
    public static async getSensorsForHardware(hardwareId: number) : Promise<Array<SensorResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `sensor?hardware=${hardwareId}`,
        });

        if (response.ok) { return response.data.Sensors; }
        return null;
    }
}
