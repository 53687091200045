import React from "react";
import {Storage} from "../../models/utils/Storage";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import AccountTemplate from "../templates/AccountTemplate/AccountTemplate";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const AccountPage = (): React.JSX.Element => {

    const role = Storage.getSession()?.user.role;
    const {t} = useTranslate();

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t('Base_errors_wrong_session')}
                subTitle={t('Base_errors_wrong_session_description')} />
        )
    }

    return (
        <AccountTemplate role={role} />
    )
};

export default AccountPage;
