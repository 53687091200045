import Papa from "papaparse";
import {Validator, ValidatorInput, ValidatorItemType} from "./Validator";

/**
 * @alias ParseCSVOutput
 * @category Utils
 */
export type ParseCSVOutput = {data: Array<any>, errors: Array<string>};

/**
 * @alias ParseCSVValidationOptions
 * @category Utils
 */
export type ParseCSVValidationOptions = ValidatorInput[];

/**
 * @class
 * @category Utils
 */
export class FileUtil {

    /**
     * Convert input file to base64
     * @function
     * @category Utils
     * @param {File} file - file from input
     */
    public static async convertToBase64(file: File) : Promise<string|null> {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result ? reader.result.toString() : null);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Convert input file to base64
     * @function
     * @category Utils
     * @param {string} base64Data - input base64 data
     */
    public static convertFromBase64(base64Data: string) : string {

        const cleanedData = base64Data
            .replace('data:text/csv;base64,', '')
            .replace('data:image/svg+xml;base64,', '')
        return window.atob(cleanedData);
    }

    /**
     * Convert BASE64 CSV string into data
     * @function
     * @category Utils
     * @param {string} base64CSV - CSV file in BASE64
     * @param {ParseCSVValidationOptions} validations - validator rules
     * @param {any} t - translator object
     */
    public static parseCSV(base64CSV: string, validations: ParseCSVValidationOptions, t: any) : ParseCSVOutput|null {

        // Get data
        const decoded = FileUtil.convertFromBase64(base64CSV);
        const parsed = Papa.parse(decoded, {delimiter: ";", skipEmptyLines: true, });
        let outputErrors : Array<string> = [];

        // Clean
        let cleaned = parsed.data.slice(1, parsed.data.length) as Array<Array<any>>;
        cleaned = cleaned.map((row: Array<any>) => {
            return row.map((column: any, index: number) => {
                if (typeof column === 'undefined' || (typeof column === 'string' && column.length === 0)) { return null; }
                if (validations[index] && validations[index].type === ValidatorItemType.NUMBER) { return parseInt(column); }
                return column;
            })
        })

        // Get line
        for (let x = 0; x < cleaned.length; x++) {
            const cleanedElement = cleaned[x];

            // Get validation and column
            for (let y = 0; y < validations.length; y++) {
                const validation = validations[y];
                const column = cleanedElement[y];

                // If required and empty col -> is error, If not required and empty col -> is ok
                if (validation.required && !column) {
                    outputErrors.push(t('FileUtil_line_missing_or_empty', {line: x+1, column: y}));
                }
                if (!validation.required && !column) { continue; }

                // Get validation result
                const validationResult = Validator.validate(validation, t, column);
                if (validationResult.errors.length > 0) {
                    outputErrors = outputErrors.concat(t('FileUtil_line_missing_or_empty',
                        {line: x+1, column: y, error: validationResult.errors[0]}));
                }
            }
        }

        // Output
        return parsed.errors.length > 0 ? null : {data: cleaned, errors:outputErrors};
    }
}