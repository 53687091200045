import React, {useEffect} from "react";
import {useAppDispatch} from "../../store/hooks";
import {Storage} from "../../models/utils/Storage";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {useTranslate} from "@tolgee/react";
import {zonesPageSlice} from "../../store/slices/pages/zonesPageSlice";
import ZonesTemplate from "../templates/ZonesTemplate/ZonesTemplate";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const ZonesPage = (): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const role = Storage.getSession()?.user.role;

    useEffect(() => { return () => { dispatch(zonesPageSlice.actions.destroyFilter()); } }, [dispatch])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    return (
        <ZonesTemplate
            role={role} />
    )
};

export default ZonesPage;
