import React, {FormEvent, useEffect} from "react";
import "./AddOrEditHardware.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {HardwareRequestDto} from "../../../models/entities/Requests/HardwareRequestDto";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    actionCreateHardware,
    actionGetHardwareListFresh,
    actionUpdateHardware
} from "../../../store/actions/data/hardwareAction";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {CustomSelect, CustomSelectValue} from "../../atoms/CustomSelect/CustomSelect";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {DataParser} from "../../../models/utils/DataParser";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {ComponentFormCreateHardwareState} from "../../../models/utils/AbstractCreateHardwareReducers";
import {T, useTranslate} from "@tolgee/react";
import {CreateHardwarePageScannedData} from "../../../store/slices/pages/createHardwarePageSlice";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {useNavigate} from "react-router-dom";
import {addOrEditHardwareOrganismSlice} from "../../../store/slices/organisms/addOrEditHardwareSlice";
import {actionGetHardware} from "../../../store/actions/organisms/addOrEditHardwareAction";

type AddOrEditHardwareProps = {
    isModal: boolean,
    hardwareId?: number,
    dispatchHideAddOrEditHardwareModal?: ActionCreatorWithoutPayload,
    hardwareTypeSelected?: HardwareTypeEnum,
    formState: ComponentFormCreateHardwareState,
    scannedData?: CreateHardwarePageScannedData|null,
    addMoreCallback?: EmptyFunc,
    muteSuccess?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {boolean} isModal - is modal variant
 * @param {ActionCreatorWithoutPayload|undefined} dispatchHideAddOrEditHardwareModal - hide modal Redux callback
 * @param {number|undefined}hardwareId - edit mode hardware id
 * @param {HardwareTypeEnum|undefined} hardwareTypeSelected - filter by hardware type
 * @param {ComponentFormCreateHardwareState} formState - create form state
 * @param {CreateHardwarePageScannedData|null|undefined} scannedData - scanned data input
 * @param {EmptyFunc|undefined} addMoreCallback - add more hardware button callback
 * @param {boolean} muteSuccess - don't show success message on success
 */
export const AddOrEditHardware = ({isModal, dispatchHideAddOrEditHardwareModal, hardwareId, hardwareTypeSelected, formState, scannedData,
    addMoreCallback, muteSuccess}: AddOrEditHardwareProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const clearForm = formState.clearForm;
    const {t, isLoading} = useTranslate();
    const navigation = useNavigate();

    // Data state
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);
    const hardwareState = useAppSelector((state) => state.addOrEditHardwareOrganism.hardware);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);

    // Re-load data
    useEffect(() => { if (!hardwareTemplateListState) { dispatch(actionGetHardwareTemplateList()); } }, [dispatch, hardwareTemplateListState])
    useEffect(() => { if (hardwareId) { dispatch(actionGetHardware(hardwareId)); } }, [dispatch, hardwareId])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Destroy
    useEffect(() => { return () => { dispatch(clearForm({t: t})); } }, [dispatch, clearForm, t])
    useEffect(() => { return () => { dispatch(addOrEditHardwareOrganismSlice.actions.destroyOrganism()); }}, [dispatch])

    // Submit form
    const handleSubmit = async (event?: FormEvent<HTMLFormElement>, addMoreCallback?: EmptyFunc) => {

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (!formState.modelState || !formState.modelState.value || !formState.uidState
            || !formState.uidState.value || !formState.hardwareTemplateState || !formState.hardwareTemplateState.value) {
            dispatch(formState.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const template = DataParser.getTemplateFromList(hardwareTemplateListState, parseInt(formState.hardwareTemplateState.value.toString()));
        if (!template) {
            dispatch(formState.setFormError({error: t('AddOrEditHardware_template_not_filled')}));
            return;
        }

        const hardwareRequest : HardwareRequestDto = {
            hardwareTemplate: template.HardwareTemplate.id,
            uid: formState.uidState.value.toString(),
            internalId: formState.internalIdState && formState.internalIdState.value ? formState.internalIdState.value.toString() : null,
        }

        let result;
        if (hardwareId) {
            result = await dispatch(actionUpdateHardware({hardwareId: hardwareId, hardwareRequest: hardwareRequest, t: t}));
        } else { result = await dispatch(actionCreateHardware({hardwareRequest: hardwareRequest, t: t})); }

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(formState.setFormError({error: result.payload}));
            return;
        }

        dispatch(formState.clearFormErrors());
        dispatch(actionGetHardwareListFresh());

        // Finish when add more requested (used in manual add)
        if (addMoreCallback) {
            dispatch(formState.clearForm({t: t}));
            if (!muteSuccess) {
                dispatch(mainSlice.actions.setTopLevelSuccessMessage({
                    message: t('AddOrEditHardware_hardwareCreated',
                        {hardwareName: hardwareRequest.internalId ?? hardwareRequest.uid})
                }));
            }
            addMoreCallback();
            return;
        }

        // Finish when add just one (used in manual add)
        if (!isModal) {
            navigation('/devices');
            return;
        }

        // Finish when edit
        if (dispatchHideAddOrEditHardwareModal) {
            dispatch(dispatchHideAddOrEditHardwareModal());
            dispatch(mainSlice.actions.setTopLevelSuccessMessage({
                message: t('AddOrEditHardware_hardwareEdited',
                    {hardwareName: hardwareRequest.internalId ?? hardwareRequest.uid})
            }));
        }
    }

    const getSensorTypeElement = (index: number, sensorTypes: Array<SensorTypeDto>, sensorTypeNumber: number) => {

        return (
            <div className={`AddOrEditHardware-sensors-content-item active`} key={`SensorItem-${index}`}>
                <div className={`AddOrEditHardware-sensors-content-item-image`}>
                    <Image
                        source={SensorUtil.getMetaForSensorType(sensorTypeNumber, t).icon}
                        description={SensorUtil.getMetaForSensorType(sensorTypeNumber, t).name} />
                </div>
                <div className={`AddOrEditHardware-sensors-content-item-text`}>
                    {SensorUtil.getMetaForSensorType(sensorTypeNumber, t).name}
                </div>
            </div>
        )
    }

    // Filter template list if requested with hardware type filter
    const parseHardwareTemplateListIntoSelect = () : Array<CustomSelectValue>|null|undefined => {

        if (!hardwareTemplateListState) { return hardwareTemplateListState; }

        const updatedList = hardwareTypeSelected ? hardwareTemplateListState.filter((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.type === hardwareTypeSelected;
        }) : hardwareTemplateListState;

        return DataParser.getTemplateSelectFromList(updatedList);
    }

    // Prepare data for usage
    const parsedHardwareTemplateList = parseHardwareTemplateListIntoSelect();
    const parsedHardwareTemplate = DataParser.getTemplateFromList(hardwareTemplateListState,
        formState.hardwareTemplateState && formState.hardwareTemplateState.value
            ? parseInt(formState.hardwareTemplateState.value.toString())
            : (hardwareState ? hardwareState.HardwareTemplate.id : undefined));

    // Loading hardware
    if (typeof sensorTypes === 'undefined' || typeof parsedHardwareTemplateList === 'undefined'
        || (hardwareId && typeof hardwareState === 'undefined') || isLoading) {
        return (
            <Loading
                type={isModal ? LoadingTypeEnum.MODAL : LoadingTypeEnum.FLAT}
                title={t('AddOrEditHardware_title_edit')}
                modal={{dispatchHide: dispatchHideAddOrEditHardwareModal}}/>
        )
    }

    // Error state hardware templates
    if (parsedHardwareTemplateList === null) {
        return (
            <ErrorComponent
                type={isModal ? ErrorComponentTypeEnum.MODAL : ErrorComponentTypeEnum.FLAT}
                modal={ dispatchHideAddOrEditHardwareModal ? {
                    name: t('AddOrEditHardware_title_edit'),
                    dispatchHide: dispatchHideAddOrEditHardwareModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware_template_list')})} />
        )
    }

    // Error state hardware data
    if ((hardwareId && hardwareState === null) || (hardwareId && parsedHardwareTemplate === null)) {
        return (
            <ErrorComponent
                type={isModal ? ErrorComponentTypeEnum.MODAL : ErrorComponentTypeEnum.FLAT}
                modal={ dispatchHideAddOrEditHardwareModal ? {
                    name: t('AddOrEditHardware_title_edit'),
                    dispatchHide: dispatchHideAddOrEditHardwareModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={isModal ? ErrorComponentTypeEnum.MODAL : ErrorComponentTypeEnum.FLAT}
                modal={ dispatchHideAddOrEditHardwareModal ? {
                    name: t('AddOrEditHardware_title_edit'),
                    dispatchHide: dispatchHideAddOrEditHardwareModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    const form = (
        <form onSubmit={handleSubmit.bind(this)}>
            <div className="AddOrEditHardware-content-form-inputs">
                <div className="AddOrEditHardware-content-form-inputs-flex">
                    <CustomSelect
                        required={true}
                        values={parsedHardwareTemplateList}
                        label={t('AddOrEditHardware_template_label')}
                        clearable={true}
                        inputState={formState.hardwareTemplateState}
                        dispatchOnChange={formState.updateInput}
                        defaultValue={parsedHardwareTemplate ? parsedHardwareTemplate.HardwareTemplate.id : scannedData?.templateId}
                        placeholder={t('AddOrEditHardware_template_placeholder')}
                        dispatchOnFocus={formState.showInputError}
                        dispatchOnBlur={formState.hideInputError}
                        inputName="hardwareTemplate"/>
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditHardware_model_placeholder')}
                        inputName="model"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditHardware_model_label')}
                        disabled={true}
                        startValue={parsedHardwareTemplate ? parsedHardwareTemplate.HardwareTemplate.model : null}
                        value={formState.modelState?.value}
                        inputState={formState.modelState}
                        dispatchOnChange={formState.updateInput}
                        dispatchOnFocus={formState.showInputError}
                        dispatchOnBlur={formState.hideInputError} />
                </div>
                <div className="AddOrEditHardware-content-form-inputs-flex">
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditHardware_id_placeholder')}
                        inputName="uid"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditHardware_id_label')}
                        value={formState.uidState?.value}
                        startValue={hardwareState ? hardwareState.Hardware.uid : scannedData?.uuid}
                        inputState={formState.uidState}
                        dispatchOnChange={formState.updateInput}
                        dispatchOnFocus={formState.showInputError}
                        dispatchOnBlur={formState.hideInputError} />
                    <InputText
                        placeholder={t('AddOrEditHardware_custom_placeholder')}
                        inputName="internalId"
                        inputType={InputTextType.TEXT}
                        value={formState.internalIdState?.value}
                        startValue={hardwareState ? hardwareState.Hardware.internalId : null}
                        label={t('AddOrEditHardware_custom_label')}
                        inputState={formState.internalIdState}
                        dispatchOnChange={formState.updateInput}
                        dispatchOnFocus={formState.showInputError}
                        dispatchOnBlur={formState.hideInputError} />
                </div>
                {parsedHardwareTemplate && parsedHardwareTemplate.SensorTypes &&
                    <div className={"AddOrEditHardware-sensors"}>
                        <div className={"AddOrEditHardware-sensors-title"}>
                            <T keyName={'AddOrEditHardware_available_sensors'} />:
                        </div>
                        <div className={"AddOrEditHardware-sensors-content"}>
                            {parsedHardwareTemplate.SensorTypes.map((sensor: number, index: number) => {
                                return ( getSensorTypeElement(index, sensorTypes, sensor) )
                            })}
                        </div>
                    </div>
                }
                {formState.formErrorState && <span className="formError">{formState.formErrorState}</span>}
            </div>
            <div className="AddOrEditHardware-content-form-inputs-button">
                {isModal ?
                    <Button
                        onClickDisabled={() => {
                            dispatch(formState.showInputValidStates());
                        }}
                        text={t('AddOrEditHardware_button_edit')}
                        type={ButtonType.PRIMARY}
                        disabled={!formState.formValidState}
                        isSubmit={true}/>
                    :
                    <Button
                        onClickDisabled={() => {
                            dispatch(formState.showInputValidStates());
                        }}
                        text={t('AddOrEditHardware_button_add')}
                        type={ButtonType.PRIMARY}
                        disabled={!formState.formValidState}
                        isSubmit={true}/>
                }
                {addMoreCallback &&
                    <Button
                        onClickDisabled={() => {
                            dispatch(formState.showInputValidStates());
                        }}
                        onClick={() => {
                            void handleSubmit(undefined, addMoreCallback);
                        }}
                        text={t('AddOrEditHardware_button_add_and_continue')}
                        type={ButtonType.TERNARY}
                        disabled={!formState.formValidState}
                        isSubmit={false} />
                }
            </div>
        </form>
    )

    if (isModal) {
        return (
            <div className="AddOrEditHardware modal">
                <div className="AddOrEditHardware">
                    <div className="AddOrEditHardware-content">
                        <div className="AddOrEditHardware-content-title">
                            <div className="AddOrEditHardware-content-title-text">
                                <T keyName={'AddOrEditHardware_title_edit'} />
                            </div>
                            <div className="AddOrEditHardware-content-title-close">
                                {dispatchHideAddOrEditHardwareModal &&
                                    <Image
                                        onClick={() => dispatch(dispatchHideAddOrEditHardwareModal())}
                                        source={close}
                                        description={t("Base_close_modal")}
                                        size={{width: 20, height: 20}} />
                                }
                            </div>
                        </div>
                        <div className="AddOrEditHardware-content-form">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="AddOrEditHardware">
            <div className="AddOrEditHardware-content">
                <div className="AddOrEditHardware-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AddOrEditHardware;
