import React, {FormEvent, useCallback, useEffect} from "react";
import "./BulkImportUpload.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectFormErrors, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {bulkImportUploadOrganismSlice} from "../../../store/slices/organisms/bulkImportUploadSlice";
import {FileUtil} from "../../../models/utils/FileUtil";
import {Validator, ValidatorItemType} from "../../../models/utils/Validator";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {actionCreateHardwareBulk} from "../../../store/actions/data/hardwareAction";
import {HardwareBulkRequestDto} from "../../../models/entities/Requests/HardwareBulkRequestDto";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {T, useTranslate} from "@tolgee/react";
import {useNavigate} from "react-router-dom";

type BulkImportUploadProps = {
    dispatchHideModal: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - Redux action to hide modal
 */
export const BulkImportUpload = ({dispatchHideModal} : BulkImportUploadProps): React.JSX.Element => {

    const navigation = useNavigate();
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.bulkImportUploadOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.bulkImportUploadOrganism));
    const formErrorsState = useAppSelector((state) => selectFormErrors(state.bulkImportUploadOrganism));
    const fileState = useAppSelector((state) => selectInputState(state.bulkImportUploadOrganism, 'file'));
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);
    const busyState = useAppSelector((state) => state.bulkImportUploadOrganism.busy);

    // Call-backs
    useEffect(() => { if (!hardwareTemplateListState) { dispatch(actionGetHardwareTemplateList()); } }, [dispatch, hardwareTemplateListState])
    useEffect(() => { return () => {
        dispatch(bulkImportUploadOrganismSlice.actions.destroyOrganism());
        dispatch(bulkImportUploadOrganismSlice.actions.clearForm({t: t}));
    } }, [dispatch, t])

    // Try to parse input value to CSV output data
    const getParsedCSV = useCallback((inputValue: string | number | null) : Array<any>|null => {

        // Check base value type
        if (inputValue === null) { return null; }
        if (typeof inputValue !== 'string') {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({
                error: t('BulkImportUpload_wrong_file'),
                invalidateInputName: 'file'
            }));
            return null;
        }

        // Check file type
        const validationOutput = Validator.validate({type: ValidatorItemType.CSV, required: true}, t, inputValue);
        if (validationOutput.errors.length > 0) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormErrors({
                errors: validationOutput.errors,
                invalidateInputName: 'file'
            }));
            return null;
        }

        // This could not happen
        if (!hardwareTemplateListState) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({
                error: t('BulkImportUpload_wrong_template_list'),
                invalidateInputName: 'file'
            }));
            return null;
        }

        // Prepare template IDs for validation
        const templateIds = hardwareTemplateListState.map((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.id.toString();
        });

        // Parse CSV
        const parsedCSV = FileUtil.parseCSV(inputValue,[
            {required: true},
            {required: true, type: ValidatorItemType.NUMBER, valueInSet: templateIds},
            {required: false},
        ], t);

        // Look if parsed
        if (parsedCSV === null) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({
                error: t('BulkImportUpload_wrong_csv'),
                invalidateInputName: 'file'
            }));
            return null;
        }

        // Look for validation errors
        if (parsedCSV.errors.length > 0) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormErrors({
                errors: parsedCSV.errors,
                invalidateInputName: 'file'
            }));
            return null;
        }

        return parsedCSV.data;
    }, [dispatch, hardwareTemplateListState, t]);

    // Submit upload form
    const handleUpload = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        // Check input value
        if (!fileState || !fileState.value || !fileState.uploadFileName || !formValidState) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        // Parse data
        const parsedData = getParsedCSV(fileState.value);
        if (parsedData === null) {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({error: t('BulkImportUpload_wrong_csv_decode')}));
            return;
        }

        // Process request object
        const request : HardwareBulkRequestDto = {hardwareList: parsedData.map((parsedDataItem: any) => {
            return {uid: parsedDataItem[0], internalId: parsedDataItem[2], hardwareTemplate: parseInt(parsedDataItem[1])};
        })};

        // Parse action result
        const result = await dispatch(actionCreateHardwareBulk({hardwareBulkRequest:request, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(bulkImportUploadOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        // Finish
        dispatch(dispatchHideModal());
        navigation('/devices');
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkImportUpload_imported', {items: request.hardwareList ? request.hardwareList.length : 0})
        }))
    }

    // Validate input file
    const handleValidate = useCallback((inputName: string, inputValue: string | number | null) => {
        if (getParsedCSV(inputValue) !== null) { dispatch(bulkImportUploadOrganismSlice.actions.clearFormErrors()); }
    }, [dispatch, getParsedCSV])

    // Loading templates
    if (typeof hardwareTemplateListState === "undefined" || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('BulkImportUpload_title')}
                modal={{dispatchHide: dispatchHideModal}}/>
        )
    }

    // Error loading templated
    if (hardwareTemplateListState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={{ name: t('BulkImportUpload_title'),  dispatchHide: dispatchHideModal }}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware_template_list')})} />
        )
    }

    return (
        <div className="BulkImportUpload">
            <div className="BulkImportUpload-modal">
                <div className="BulkImportUpload-modal-content">
                    <div className="BulkImportUpload-modal-content-title">
                        <div className="BulkImportUpload-modal-content-title-text">
                            <T keyName={'BulkImportUpload_title'} />
                        </div>
                        <div className="BulkImportUpload-modal-content-title-close">
                            <Image
                                onClick={() => dispatch(dispatchHideModal()) }
                                source={close}
                                description={t("Base_close_modal")}
                                size={{width: 20, height: 20}} />
                        </div>
                    </div>
                    <div className="BulkImportUpload-modal-content-form">
                        <form onSubmit={handleUpload.bind(this)}>
                            <div className="BulkImportUpload-modal-content-form-inputs">
                                <InputText
                                    startValue={null}
                                    inputState={fileState}
                                    inputName={'file'}
                                    value={fileState?.value}
                                    onChange={handleValidate}
                                    dispatchOnChange={bulkImportUploadOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={bulkImportUploadOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={bulkImportUploadOrganismSlice.actions.removeFocus}
                                    inputType={InputTextType.FILE}
                                    placeholder={t('BulkImportUpload_input_placeholder')}/>
                                {formErrorState && <span className="formError">{formErrorState}</span> }
                                {(formErrorsState && formErrorsState.length > 0) &&
                                    <ul className="formErrors">
                                        {formErrorsState.slice(0, 8).map((error: string, index: number) => {
                                            return (
                                                <li className={'formErrors-item'} key={`formErrors-${index}`}>
                                                    {error}
                                                </li>
                                            )
                                        })}
                                        {formErrorsState && formErrorsState.length > 8 &&
                                            <li className={'formErrors-item'}>
                                                <T keyName={'BulkImportUpload_more_errors'} params={{count: formErrorsState.length - 8}} />
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                            <div className="BulkImportUpload-modal-content-form-inputs-hint">
                                { // eslint-disable-next-line
                                } <T keyName={'BulkImportUpload_hint'} params={{a: <a href={'/BulkImportExampleFormat.csv'}/>}}/>
                            </div>
                            <div className="BulkImportUpload-modal-content-form-inputs-button">
                                <Button
                                    isProgress={busyState}
                                    text={t('BulkImportUpload_button')}
                                    type={ButtonType.PRIMARY}
                                    disabled={!formValidState || !!formErrorState || (!!formErrorsState && formErrorsState.length > 0)}
                                    onClickDisabled={() => { dispatch(bulkImportUploadOrganismSlice.actions.showInputValidStates()); }}
                                    isSubmit={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BulkImportUpload;
