import React from "react";
import {ListHardware} from "../../organisms/ListHardware/ListHardware";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {useAppSelector} from "../../../store/hooks";
import {AddOrEditHardware} from "../../organisms/AddOrEditHardware/AddOrEditHardware";
import {hardwarePageSlice} from "../../../store/slices/pages/hardwarePageSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type HardwareTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const HardwareTemplate = ({role} : HardwareTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const editHardwareModalShownState = useAppSelector((state) => state.hardwarePage.editHardwareModalShown);
    const editHardwareModalIdState = useAppSelector((state) => state.hardwarePage.editHardwareModalId);
    const filterTypeState = useAppSelector((state) => state.hardwarePage.filterType);
    const templateIdState = useAppSelector((state) => state.hardwarePage.templateId);
    const elevationState = useAppSelector((state) => state.hardwarePage.elevation);
    const wearableState = useAppSelector((state) => state.hardwarePage.wearable);
    const patientIdState = useAppSelector((state) => state.hardwarePage.patientId);
    const sensorTypeState = useAppSelector((state) => state.hardwarePage.sensorType);
    const fullTextValueState = useAppSelector((state) => state.hardwarePage.fullTextValue);
    const filterTitleState = useAppSelector((state) => state.hardwarePage.filterTitle);
    const paginationPageState = useAppSelector((state) => state.hardwarePage.paginationPage);
    const paginationOrderState = useAppSelector((state) => state.hardwarePage.paginationOrder);
    const typeState = useAppSelector((state) => state.hardwarePage.type);
    const hardwareTemplateState = useAppSelector((state) => selectInputState(state.hardwarePage, 'hardwareTemplate'));
    const modelState = useAppSelector((state) => selectInputState(state.hardwarePage, 'model'));
    const uidState = useAppSelector((state) => selectInputState(state.hardwarePage, 'uid'));
    const internalIdState = useAppSelector((state) => selectInputState(state.hardwarePage, 'internalId'));
    const formValidState = useAppSelector((state) => selectValidState(state.hardwarePage));
    const formErrorState = useAppSelector((state) => selectFormError(state.hardwarePage));

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('HardwareTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('HardwareTemplate_title')}
                        easySearch={{
                            searchValue: fullTextValueState,
                            dispatchSearchValueUpdate: hardwarePageSlice.actions.filterFullText
                        }}
                        breadItems={[
                            {name: t('Navigation_hardware'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <ListHardware
                                role={role}
                                pagination={{
                                    showCount: 10,
                                    page: paginationPageState,
                                    order: paginationOrderState,
                                    dispatchChangeOrder: hardwarePageSlice.actions.changePaginationOrder,
                                    dispatchChangePage: hardwarePageSlice.actions.changePaginationPage
                                }}
                                filter={{
                                    wearable: wearableState,
                                    fullTextValue: fullTextValueState ? fullTextValueState.toLowerCase() : null,
                                    filterTitle: filterTitleState,
                                    filterType: filterTypeState,
                                    sensorType: sensorTypeState,
                                    templateId: templateIdState,
                                    patientId: patientIdState,
                                    elevation: elevationState,
                                    type: typeState,
                                    dispatchFilterElevation: hardwarePageSlice.actions.filterElevation,
                                    dispatchFilterSensor: hardwarePageSlice.actions.filterSensor,
                                    dispatchFilterActive: hardwarePageSlice.actions.destroyFilter,
                                    dispatchFilterAll: hardwarePageSlice.actions.filterAll,
                                    dispatchFilterOwned: hardwarePageSlice.actions.filterOwned,
                                    dispatchFilterPatient: hardwarePageSlice.actions.filterPatient,
                                    dispatchFilterTemplate: hardwarePageSlice.actions.filterTemplate,
                                    dispatchFilterWearable: hardwarePageSlice.actions.filterWearable,
                                    dispatchFilterType: hardwarePageSlice.actions.filterType,
                                }}
                                dispatchShowAddOrEditHardwareModal={hardwarePageSlice.actions.showEditHardwareModal} />
                        </div>
                    </div>
                </div>
            </div>
            {editHardwareModalShownState && editHardwareModalIdState &&
                <AddOrEditHardware
                    isModal={true}
                    hardwareId={editHardwareModalIdState}
                    formState={{
                        hardwareTemplateState: hardwareTemplateState,
                        modelState: modelState,
                        uidState: uidState,
                        internalIdState: internalIdState,
                        formValidState: formValidState,
                        formErrorState: formErrorState,
                        clearForm: hardwarePageSlice.actions.clearForm,
                        setFormError: hardwarePageSlice.actions.setFormError,
                        clearFormErrors: hardwarePageSlice.actions.clearFormErrors,
                        updateInput: hardwarePageSlice.actions.updateInput,
                        showInputError: hardwarePageSlice.actions.setFocus,
                        hideInputError: hardwarePageSlice.actions.removeFocus,
                        showInputValidStates: hardwarePageSlice.actions.showInputValidStates,
                    }}
                    dispatchHideAddOrEditHardwareModal={hardwarePageSlice.actions.hideEditHardwareModal} />
            }
        </main>
    );
}

export default HardwareTemplate;
