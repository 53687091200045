import React, {FormEvent, useEffect} from "react";
import "./UserNotifyOptions.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionGetUserNotifyOptions, actionUpdateUserNotifyOptions} from "../../../store/actions/data/userAction";
import personIcon from "../../../images/person.svg";
import {T, useTranslate} from "@tolgee/react";
import {userNotifyOptionsOrganismSlice} from "../../../store/slices/organisms/userNotifyOptionsSlice";
import InputCheckbox from "../../atoms/InputCheckbox/InputCheckbox";
import {NotifyOptionsRequestDto} from "../../../models/entities/Requests/NotifyOptionsRequestDto";
import {mainSlice} from "../../../store/slices/extra/mainSlice";

type UserNotifyOptionsProps = {
    userId: number,
    dispatchHideUserNotifyOptionsModal: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideUserNotifyOptionsModal - hide modal Redux callback
 * @param {number} userId - user id to modify options for notifications
 */
export const UserNotifyOptions = ({dispatchHideUserNotifyOptionsModal, userId} : UserNotifyOptionsProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t, isLoading} = useTranslate();

    // Data state
    const userNotifyOptionsState = useAppSelector((state) => state.userNotifyOptionsOrganism.userNotifyOptions);

    // Form state
    const alertFCMState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertFCM'));
    const alertMailState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertMail'));
    const alertSMSState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertSMS'));
    const alertRepeatedFCMState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertRepeatedFCM'));
    const alertRepeatedMailState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertRepeatedMail'));
    const alertRepeatedSMSState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'alertRepeatedSMS'));
    const escalateSecondsThresholdState = useAppSelector((state) => selectInputState(state.userNotifyOptionsOrganism, 'escalateSecondsThreshold'));
    const formValidState = useAppSelector((state) => selectValidState(state.userNotifyOptionsOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.userNotifyOptionsOrganism));

    // In edit mode init current zone
    useEffect(() => { dispatch(actionGetUserNotifyOptions(userId)); }, [dispatch, userId])

    // Destroy
    useEffect(() => { return () => {
        dispatch(userNotifyOptionsOrganismSlice.actions.clearForm({t: t}));
        dispatch(userNotifyOptionsOrganismSlice.actions.destroyOrganism());
    } }, [dispatch, t])


    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!alertFCMState || Number.isNaN(alertFCMState.value) || !alertMailState || Number.isNaN(alertMailState.value) || !alertSMSState
            || Number.isNaN(alertSMSState.value) || !alertRepeatedFCMState || Number.isNaN(alertRepeatedFCMState.value) || !alertRepeatedMailState
            || Number.isNaN(alertRepeatedMailState.value) || !alertRepeatedSMSState || Number.isNaN(alertRepeatedSMSState.value)) {
            dispatch(userNotifyOptionsOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const userNotifyRequest : NotifyOptionsRequestDto = {
            alertFCM: parseInt(alertFCMState.value!.toString()) === 1,
            alertMail: parseInt(alertMailState.value!.toString()) === 1,
            alertSMS: parseInt(alertSMSState.value!.toString()) === 1,
            alertRepeatedFCM: parseInt(alertRepeatedFCMState.value!.toString()) === 1,
            alertRepeatedMail: parseInt(alertRepeatedMailState.value!.toString()) === 1,
            alertRepeatedSMS: parseInt(alertRepeatedSMSState.value!.toString()) === 1,
            escalateSecondsThreshold: escalateSecondsThresholdState && escalateSecondsThresholdState.value
                ? parseInt(escalateSecondsThresholdState.value.toString())
                : null,
        }

        const result = await dispatch(actionUpdateUserNotifyOptions({userId: userId, request: userNotifyRequest, t: t}));

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(userNotifyOptionsOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchHideUserNotifyOptionsModal());
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('UserNotifyOptions_successfully_saved')}))
    }

    // Loading hardware
    if ((typeof userNotifyOptionsState === 'undefined') || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('UserNotifyOptions_title')}
                modal={{dispatchHide: dispatchHideUserNotifyOptionsModal}}/>
        )
    }

    // Error state
    if (userNotifyOptionsState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={{
                    name: t('UserNotifyOptions_title'),
                    dispatchHide: dispatchHideUserNotifyOptionsModal,
                }}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description", {dataName: t('Base_name_notifyOptions')})} />
        )
    }

    console.log(userNotifyOptionsState);

    const form = (
        <form onSubmit={ (event: FormEvent<HTMLFormElement>) => { void handleSubmit(event); }}>
            <div className="UserNotifyOptions-content-form-inputs">
                <div className="UserNotifyOptions-content-form-inputs-type">
                    <div className="UserNotifyOptions-content-form-inputs-type-title">
                        <T keyName={'UserNotifyOptions_alertingTitle'}></T>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-type-hint">
                        <T keyName={'UserNotifyOptions_alertingHint'}></T>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-flex">
                        <InputCheckbox
                            label={t('UserNotifyOptions_alertFCM')}
                            inputName={'alertFCM'}
                            startValue={userNotifyOptionsState.alertFCM}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                        <InputCheckbox
                            label={t('UserNotifyOptions_alertMail')}
                            inputName={'alertMail'}
                            startValue={userNotifyOptionsState.alertMail}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-flex">
                        <InputCheckbox
                            startValue={userNotifyOptionsState.alertSMS}
                            label={t('UserNotifyOptions_alertSMS')}
                            inputName={'alertSMS'}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                    </div>
                </div>
                <div className="UserNotifyOptions-content-form-inputs-type">
                    <div className="UserNotifyOptions-content-form-inputs-type-title">
                        <T keyName={'UserNotifyOptions_alertingRepeatedTitle'}></T>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-type-hint">
                        <T keyName={'UserNotifyOptions_alertingRepeatedHint'}></T>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-flex">
                        <InputCheckbox
                            startValue={userNotifyOptionsState.alertRepeatedFCM}
                            label={t('UserNotifyOptions_alertFCM')}
                            inputName={'alertRepeatedFCM'}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                        <InputCheckbox
                            startValue={userNotifyOptionsState.alertRepeatedMail}
                            label={t('UserNotifyOptions_alertMail')}
                            inputName={'alertRepeatedMail'}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-flex">
                        <InputCheckbox
                            label={t('UserNotifyOptions_alertSMS')}
                            inputName={'alertRepeatedSMS'}
                            startValue={userNotifyOptionsState.alertRepeatedSMS}
                            onChangeCallBack={(inputName: string, checked: boolean) => {
                                dispatch(userNotifyOptionsOrganismSlice.actions.updateInput({
                                    inputName: inputName,
                                    inputValue: checked ? 1 : 0,
                                    t: t
                                }));
                            }}/>
                    </div>
                </div>
                <div className="UserNotifyOptions-content-form-inputs-type">
                    <div className="UserNotifyOptions-content-form-inputs-type-title">
                        <T keyName={'UserNotifyOptions_escalatingTitle'}></T>
                    </div>
                    <div className="UserNotifyOptions-content-form-inputs-type-hint">
                        <T keyName={'UserNotifyOptions_escalatingHint'}></T>
                    </div>
                    <div className={"UserNotifyOptions-content-form-inputs-type-escalating"}>
                        <InputText
                            required={false}
                            placeholder={t('UserNotifyOptions_escalateSecondsThreshold_placeholder')}
                            inputName="escalateSecondsThreshold"
                            inputType={InputTextType.NUMBER}
                            disabled={false}
                            startValue={userNotifyOptionsState.escalateSecondsThreshold}
                            value={escalateSecondsThresholdState?.value}
                            inputState={escalateSecondsThresholdState}
                            dispatchOnChange={userNotifyOptionsOrganismSlice.actions.updateInput}
                            dispatchOnFocus={userNotifyOptionsOrganismSlice.actions.setFocus}
                            dispatchOnBlur={userNotifyOptionsOrganismSlice.actions.removeFocus}/>
                    </div>
                </div>
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="UserNotifyOptions-content-form-inputs-button">
                <Button
                    image={personIcon}
                    text={t('UserNotifyOptions_button_edit')}
                    type={ButtonType.PRIMARY}
                    onClickDisabled={() => {
                        dispatch(userNotifyOptionsOrganismSlice.actions.showInputValidStates());
                    }}
                    disabled={!formValidState}
                    isSubmit={true}/>
            </div>
        </form>
    )
    return (
        <div className="UserNotifyOptions">
            <div className="UserNotifyOptions-content">
                <div className="UserNotifyOptions-content-title">
                    <div className="UserNotifyOptions-content-title-text">
                        {t('UserNotifyOptions_button_edit')}
                    </div>
                    <div className="UserNotifyOptions-content-title-close">
                        {dispatchHideUserNotifyOptionsModal &&
                            <Image onClick={() => dispatch(dispatchHideUserNotifyOptionsModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="UserNotifyOptions-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default UserNotifyOptions;
