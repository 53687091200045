import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "./Graph.scss"
import {SensorAlertStateEnum} from "../../../models/entities/Enums/SensorAlertStateEnum";
import highchartsAccessibility from "highcharts/modules/accessibility";
import xrange from 'highcharts/modules/xrange';
import {SensorDataChartTypeEnum} from "../../../models/entities/Enums/SensorDataChartTypeEnum";
import {GraphsUtil} from "../../../models/utils/GraphsUtil";
import {ZoneDto} from "../../../models/entities/ZoneDto";

export type LineTypes = "line" | "column";

type GraphInternalData = { [id: string] : GraphData[]; };

/**
 * @alias GraphPointData
 * @category Components
 */
export type GraphPointData = { x: number, x2?: number, y: number }[];

/**
 * @alias GraphData
 * @category Components
 */
export type GraphData = {

    /** Points data **/
    points: GraphPointData,
    /** Graph title **/
    title: string|null,
    /** Units **/
    unit: string|null,
    /** Show active on init **/
    alertState?: SensorAlertStateEnum,
    /** Chart type **/
    type: SensorDataChartTypeEnum;
    /** Chart min-max Y axis **/
    range: {min?: number|null, max?: number|null, step?: number}
};

type GraphProps = {
    data: Array<GraphData>,
    showLegend: boolean,
    zoneList: ZoneDto[],
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {GraphData[]} data - all data series
 * @param {boolean} showLegend - show graph legend
 * @param {ZoneDto[]} zoneList - list of zones
 */
export const Graph = ({data, showLegend, zoneList} : GraphProps): React.JSX.Element => {

    highchartsAccessibility(Highcharts);
    xrange(Highcharts);

    // Get chart elements using the chart types list
    const getGraphTypesToConstruct = () : React.JSX.Element[] => {

        let output : GraphInternalData = {};
        for (const graphData of data) {
            if (!output[graphData.type.toString()]) { output[graphData.type.toString()] = [] }
            output[graphData.type.toString()].push(graphData);
        }

        const outputElements : React.JSX.Element[] = [];
        for (const graphType in output) {
            const graphTypeEnum = graphType as SensorDataChartTypeEnum;
            outputElements.push(
                (
                    <div className="Graph-item" key={`Graph-item-${graphType}`}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={getOptions(graphTypeEnum, output[graphType])}
                        />
                    </div>
                )
            )
        }

        return outputElements;
    }

    // Create options for graph
    const getOptions = (graphType: SensorDataChartTypeEnum, seriesData: GraphData[]) : Highcharts.Options => {

        const parsedSeriesData = GraphsUtil.getSeries(graphType, seriesData);

        return {
            time: { useUTC: false },
            colors: graphType !== SensorDataChartTypeEnum.XRANGE
                ? ['#DC4245', '#e2aa56', '#3CBE7D']
                : ['#03a9f3'],
            chart: {
                zooming: {
                    singleTouch: true,
                    type: 'x',
                },
                height: 350,
                backgroundColor: '#222F43',
                events: graphType !== SensorDataChartTypeEnum.XRANGE ? undefined : {
                    load: GraphsUtil.updateXRangeDataOnChartLoadCallback()
                }
            },
            credits: {enabled: false},
            xAxis: {
                type: 'datetime',
                visible: true,
                labels: {style:{color: 'white'}},
                lineColor: 'white',
            },
            yAxis: {
                categories: graphType !== SensorDataChartTypeEnum.XRANGE ? [] : GraphsUtil.getXRangeCategories(seriesData, zoneList),
                type: graphType === SensorDataChartTypeEnum.XRANGE ? 'category' : undefined,
                lineColor: 'white',
                showEmpty: false,
                labels: {style:{color: 'white'}},
                title: undefined,
                reversed: graphType === SensorDataChartTypeEnum.XRANGE,
                visible: true,
                lineWidth: 0,
                gridLineColor: "transparent",
                gridLineWidth: 0},
            legend: {
                enabled: showLegend,
                useHTML: true,
                symbolHeight: 0,
                itemDistance: 10,
                symbolWidth: 0,
                squareSymbol: false,
                symbolRadius: 0,
                verticalAlign: "top",
                align: "right",
                margin: 54,
                y: -16,
                navigation: {activeColor: 'white', inactiveColor: 'white', arrowSize: 10},
                labelFormatter: GraphsUtil.getLabelFormatterCallback()
            },
            tooltip: {
                useHTML: true,
                formatter: GraphsUtil.getTooltipFormatterCallback(graphType),
            },
            title: {text: undefined},
            series: parsedSeriesData,
            plotOptions : {
                column: {pointWidth:  30},
                area : {fillColor: {stops: [[0, '#A1C8ED'], [1, '#FBFCFD']]}},
                series: {
                    events: {
                        legendItemClick: function (this) {
                            if (typeof this.userOptions.index !== 'undefined') {
                                for (const series of this.chart.series) { series.setVisible(false); }
                                this.chart.series[this.userOptions.index].setVisible(true);
                            } return !this.visible;
                        }
                    }
                }
            }
        }
    }

    return (
        <div className="Graph">
            {getGraphTypesToConstruct()}
        </div>
    );
}

export default Graph;
