import {createSlice} from '@reduxjs/toolkit'
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {
    actionActivateHardware,
    actionAssignHardware,
    actionCreateHardware, actionCreateHardwareBulk,
    actionDeleteHardware,
    actionGetHardwareList,
    actionGetHardwareTypesList,
    actionUpdateHardware
} from "../../actions/data/hardwareAction"
import {HardwareTypeDto} from "../../../models/entities/HardwareTypeDto";
import {actionRemovePatientHardwareList} from "../../actions/data/patientAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareState {

    /** List of hardware **/
    hardwareList?: Array<HardwareResponseDto>|null,
    /** List of hardware types **/
    hardwareTypes?: Array<HardwareTypeDto>|null,
}

const initialState: HardwareState = {
    hardwareList: undefined,
    hardwareTypes: undefined,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareDataSlice = createSlice({
    name: 'hardwareData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetHardwareTypesList.fulfilled, (state, {payload}) => {
            state.hardwareTypes = payload;
        })
        builder.addCase(actionGetHardwareList.fulfilled, (state, {payload}) => {
            state.hardwareList = payload;
        })
        builder.addCase(actionDeleteHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionDeleteHardware: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload;
        })
        builder.addCase(actionActivateHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionActivateHardware: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload;
        })
        builder.addCase(actionCreateHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionCreateHardware: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload;
        })
        builder.addCase(actionCreateHardwareBulk.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionCreateHardwareBulk: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload;
        })
        builder.addCase(actionUpdateHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionUpdateHardware: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload;
        })
        builder.addCase(actionAssignHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionAssignHardware: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload.hardwareList;
        })
        builder.addCase(actionRemovePatientHardwareList.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDataSlice-actionRemovePatientHardwareList: wrong payload, skip!')
                return;
            }

            state.hardwareList = payload.hardwareList;
        })
    }
})

export default hardwareDataSlice.reducer
