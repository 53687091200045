import "./CustomDraggable.scss"
import React, {useCallback, useEffect, useRef} from "react";
import {InternalBuildingLocationType} from "../../../models/utils/AbstractBuildingReducers";

/**
 * @alias CustomDraggableDoneCallback
 * @category Components
 */
export type CustomDraggableDoneCallback =
    (offsetXPct: number, offsetYPct: number, id: number, name: string, type: InternalBuildingLocationType) => void;

/**
 * @interface
 * @category Components
 */
export interface CustomDraggableAllowedContent {

    /** Top left allowed position on map **/
    topLeft: {x: number, y: number},
    /** Bottom right allowed position on map **/
    bottomRight: {x: number, y: number},
    /** Allowed positions width **/
    width: number,
    /** Allowed positions height **/
    height: number,
}

type CustomDraggableProps = {
    mainContent: React.JSX.Element,
    draggedContent: React.JSX.Element,
    allowedContent: CustomDraggableAllowedContent,
    doneCallBack: CustomDraggableDoneCallback,
    startCallBack: (id: number) => void,
    type: InternalBuildingLocationType,
    id: number,
    name: string,
    clickCallBack: (id: number) => void,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {React.JSX.Element} mainContent - main element view
 * @param {React.JSX.Element} draggedContent - element shown on drag
 * @param {CustomDraggableAllowedContent} allowedContent - allowed bounds on map
 * @param {CustomDraggableDoneCallback} doneCallBack - done callback
 * @param {number} id - DB ID
 * @param {InternalBuildingLocationType} type - internal building element type
 * @param {string} name - element name
 * @param {EmptyFunc} clickCallBack - clicked callback
 * @param {EmptyFunc} startCallBack - start drag callback
 */
export const CustomDraggable = ({mainContent, draggedContent, allowedContent, doneCallBack, id, type,
    name, clickCallBack, startCallBack} : CustomDraggableProps): React.JSX.Element => {

    const longPressTimeout = useRef<NodeJS.Timeout|null>(null);
    const [onDrag, setOnDrag] = React.useState<boolean>(false);
    const [x, setX] = React.useState<number|null>(null);
    const [y, setY] = React.useState<number|null>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const draggedRef = useRef<HTMLDivElement>(null);

    // When updated X,Y change UI
    useEffect(() => {
        if (draggedRef && draggedRef.current) {
            draggedRef.current.style.left = `${x}px`;
            draggedRef.current.style.top = `${y}px`;
        }
    }, [x, y, draggedRef]);

    // On drag move event, just update X,Y
    const onMove = (e: MouseEvent) => {
        if (draggedRef.current && draggedRef.current.children[0]) {
            const bounding = draggedRef.current.children[0].getBoundingClientRect();
            setX(e.clientX - Math.round(bounding.width/2));
            setY(e.clientY - Math.round(bounding.height/2));
            return;
        }
    }

    // Short click callback, finish dragging and call callback
    const onClickCallBack = useCallback(() => {

        clickCallBack(id);
        document.removeEventListener('mouseup', onClickCallBack);
        if (longPressTimeout && longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
        }
    }, [longPressTimeout, id, clickCallBack])

    // When clicked to trigger, wait if long press
    const onMousePressed = (e: React.MouseEvent) => {

        longPressTimeout.current = setTimeout(() => {
            document.removeEventListener('mouseup', onClickCallBack);
            onStart(e);
        }, 500);

        document.addEventListener('mouseup', onClickCallBack);
    }

    // Drag start event, update X,Y and add drag events
    const onStart = (e: React.MouseEvent) => {

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onStop);

        setTimeout(() => {
            if (draggedRef.current && draggedRef.current.children[0]) {
                const bounding = draggedRef.current.children[0].getBoundingClientRect();
                setX(e.clientX - Math.round(bounding.width/2));
                setY(e.clientY - Math.round(bounding.height/2));
            }
            setOnDrag(true);
            startCallBack(id);
        }, 100);
    }

    // Drag end event, get final location and fire callback, remove dragging events
    const onStop = () => {

        setOnDrag(false);
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onStop);
        if (!draggedRef.current || !draggedRef.current.children[0]) { return; }

        let bounding = draggedRef.current.children[0].getBoundingClientRect();
        let boundingLeft = bounding.left;
        let boundingTop = bounding.top;
        const centerX = boundingLeft + ((bounding.right - boundingLeft) / 2);
        const centerY = boundingTop + ((bounding.bottom - boundingTop) / 2);

        if ((boundingLeft >= allowedContent.topLeft.x && bounding.right <= allowedContent.bottomRight.x)
            && (boundingTop >= allowedContent.topLeft.y && bounding.bottom <= allowedContent.bottomRight.y)) {
            const offsetX = centerX - allowedContent.topLeft.x;
            const offsetY = centerY - allowedContent.topLeft.y;
            doneCallBack(offsetX/allowedContent.width*100, offsetY/allowedContent.height*100, id, name, type);
            return;
        }
    }

    return (
        <div draggable={false} className={`CustomDraggable ${onDrag ? 'onDrag' : ''}`}>
            <div draggable={false} className={'CustomDraggable-content'} ref={contentRef}
                 onMouseDown={ (event:React.MouseEvent<HTMLDivElement>) => { onMousePressed(event); }}>
                <div className={'handle'} draggable={false}>
                    {mainContent}
                </div>
                <div draggable={false} className={'draggedHandle'} ref={draggedRef}>
                    {draggedContent}
                </div>
            </div>
        </div>
    );
}

export default CustomDraggable;
