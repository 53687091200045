import {AbstractFormReducersState, AbstractFormUpdatePayload, getAbstractFormReducers, InputState} from "./AbstractFormReducers";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";

/**
 * @interface
 * @category Utils
 */
export interface CreateHardwareFormState extends AbstractFormReducersState {}

/**
 * @constant
 * @category Utils
 */
export const createHardwareFormInitialState: CreateHardwareFormState = {
    form: {
        inputs: [
            {name: 'hardwareTemplate', validations: {required: true}},
            {name: 'model', validations: {required: true}},
            {name: 'uid', validations: {required: true}},
            {name: 'internalId', validations: {required: false}},
            {name: 'submit'}
        ]
    },
}

/**
 * @interface
 * @category Utils
 */
export interface ComponentFormCreateHardwareState {
    hardwareTemplateState: InputState|null,
    modelState: InputState|null,
    uidState: InputState|null,
    internalIdState: InputState|null,
    formValidState: boolean,
    formErrorState: string|null|undefined,
    clearForm: ActionCreatorWithPayload<{t: any}>,
    setFormError: ActionCreatorWithPayload<{ error: string | null }>
    clearFormErrors: ActionCreatorWithoutPayload,
    updateInput: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    showInputError: ActionCreatorWithPayload<{inputName: string }>,
    hideInputError: ActionCreatorWithPayload<{inputName: string }>,
    showInputValidStates: ActionCreatorWithoutPayload,
}

/**
 * @constant
 * @category Utils
 */
export const getCreateHardwareReducers = () => {
    return getAbstractFormReducers();
}