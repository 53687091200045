import "./SMSMenu.scss"
import React, {FormEvent} from "react";
import {T, useTranslate} from "@tolgee/react";
import Button, {ButtonType} from "../../atoms/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {smsMenuOrganismSlice} from "../../../store/slices/organisms/smsMenuSlice";
import {SMSSimpleMessageRequestDto} from "../../../models/entities/Requests/SMSSimpleMessageRequestDto";
import {actionSendSMSMessage} from "../../../store/actions/organisms/smsMenuAction";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import personIcon from "../../../images/person.svg";
import {mainSlice} from "../../../store/slices/extra/mainSlice";

interface SMSMenuProps {
    dispatchHide: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 */
export const SMSMenu = ({dispatchHide} : SMSMenuProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    // Form state
    const messageState = useAppSelector((state) => selectInputState(state.smsMenuOrganism, 'message'));
    const phoneNumberState = useAppSelector((state) => selectInputState(state.smsMenuOrganism, 'phoneNumber'));
    const formValidState = useAppSelector((state) => selectValidState(state.smsMenuOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.smsMenuOrganism));

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!messageState || !messageState.value || !phoneNumberState || !phoneNumberState.value) {
            dispatch(smsMenuOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const smsRequest : SMSSimpleMessageRequestDto = {
            message: messageState.value.toString(),
            phoneNumber: phoneNumberState.value.toString()
        };

        const result = await dispatch(actionSendSMSMessage({request: smsRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(smsMenuOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchHide());
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('SMSMenu_successfully_sent')}))
    }

    const form = (
        <form onSubmit={ (event: FormEvent<HTMLFormElement>) => { void handleSubmit(event); }}>
            <div className="SMSMenu-content-form-inputs">
                <InputText
                    required={true}
                    placeholder={t('SMSMenu_message_placeholder')}
                    inputName="message"
                    inputType={InputTextType.TEXT}
                    label={t('SMSMenu_message_label')}
                    disabled={false}
                    value={messageState?.value}
                    inputState={messageState}
                    dispatchOnChange={smsMenuOrganismSlice.actions.updateInput}
                    dispatchOnFocus={smsMenuOrganismSlice.actions.setFocus}
                    dispatchOnBlur={smsMenuOrganismSlice.actions.removeFocus} />
                <InputText
                    required={true}
                    placeholder={t('SMSMenu_phoneNumber_placeholder')}
                    inputName="phoneNumber"
                    inputType={InputTextType.TEXT}
                    label={t('SMSMenu_phoneNumber_label')}
                    disabled={false}
                    value={phoneNumberState?.value}
                    inputState={phoneNumberState}
                    dispatchOnChange={smsMenuOrganismSlice.actions.updateInput}
                    dispatchOnFocus={smsMenuOrganismSlice.actions.setFocus}
                    dispatchOnBlur={smsMenuOrganismSlice.actions.removeFocus} />
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="SMSMenu-content-form-inputs-button">
                <Button
                    image={personIcon}
                    text={t('SMSMenu_sendSMS_btn')}
                    type={ButtonType.PRIMARY}
                    onClickDisabled={() => { dispatch(smsMenuOrganismSlice.actions.showInputValidStates()); }}
                    disabled={!formValidState}
                    isSubmit={true} />
            </div>
        </form>
    )


    return (
        <div className={`SMSMenu`}>
            <div className="SMSMenu-content">
                <div className="SMSMenu-content-header">
                    <T keyName={'SMSMenu_sendSMS'} />
                </div>
                <div className="SMSMenu-content-form">
                    {form}
                </div>
            </div>
        </div>
    );

}

export default SMSMenu;
