import {createSlice} from '@reduxjs/toolkit'
import {
    createHardwareFormInitialState,
    CreateHardwareFormState,
    getCreateHardwareReducers
} from "../../../models/utils/AbstractCreateHardwareReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {CreateHardwareFormState}
 */
interface HardwareCreateDialogScanState extends CreateHardwareFormState {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const initialState: HardwareCreateDialogScanState = {...createHardwareFormInitialState, ...{}}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareCreateDialogScanOrganismSlice = createSlice({
    name: 'hardwareCreateDialogScanOrganism',
    initialState,
    reducers: {...getCreateHardwareReducers(), ...{}},
})

export default hardwareCreateDialogScanOrganismSlice.reducer