import React from "react";
import "./CardZone.scss"
import {Button, ButtonType} from "../../atoms/Button/Button";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import edit from "../../../images/edit.svg";
import {T, useTranslate} from "@tolgee/react";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {ValueUtil} from "../../../models/utils/ValueUtil";

type CardZoneProps =  {
    zone: ZoneDto,
    dispatchShowEditZoneModal: ActionCreatorWithPayload<{zoneId: number}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ZoneDto} zone - zone response data
 * @param {ActionCreatorWithPayload<{zoneId: number}>} dispatchShowEditZoneModal - show modal Redux callback
 */
export const CardZone = ({zone, dispatchShowEditZoneModal} : CardZoneProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    return (
        <div className={`CardPatient`}>
            <div className="CardZone-functions">
                <div className="CardZone-functions-left">
                    <div className="CardZone-functions-left-title">
                        <T keyName={'CardZone_title'} />
                    </div>
                </div>
                <div className="CardZone-functions-right">
                    <div className="CardZone-functions-right-item">
                        <div className="CardZone-functions-right-item-button">
                            <Button
                                onClick={() => { dispatch(dispatchShowEditZoneModal({zoneId: zone.id})); }}
                                text={t('Base_goto_editDetails')}
                                image={edit}
                                type={ButtonType.PRIMARY} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="CardZone-content">
                <div className="CardZone-content-info">
                    <div className="CardZone-content-info-block">
                        <div className="CardZone-content-info-block-title">
                            <T keyName={'CardZone_name'} />
                        </div>
                        <div className="CardZone-content-info-block-value">
                            {zone.name}
                        </div>
                    </div>
                    <div className="CardZone-content-info-block">
                        <div className="CardZone-content-info-block-title">
                            <T keyName={'CardZone_templateActive'} />
                        </div>
                        <div className={`CardZone-content-info-block-value ${zone.zoneBanTemplate === null ? 'yes' : 'no'}`}>
                            {zone.zoneBanTemplate === null ? t('CardZone_template_banned_no') : t('CardZone_template_banned_yes')}
                        </div>
                    </div>
                    <div className="CardZone-content-info-block">
                        <div className="CardZone-content-info-block-title">
                            <T keyName={'CardZone_created'} />
                        </div>
                        <div className="CardZone-content-info-block-value">
                            {ValueUtil.getStringFromValue(t, zone.createdAt, undefined, false)}
                        </div>
                    </div>
                    <div className="CardZone-content-info-block">
                        <div className="CardZone-content-info-block-title">
                            <T keyName={'CardZone_updated'} />
                        </div>
                        <div className="CardZone-content-info-block-value">
                            {ValueUtil.getStringFromValue(t, zone.updatedAt, undefined, false)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardZone;
