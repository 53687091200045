import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {useAppSelector} from "../../../store/hooks";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import ListZones from "../../organisms/ListZones/ListZones";
import {zonesPageSlice} from "../../../store/slices/pages/zonesPageSlice";
import AddOrEditZone from "../../organisms/AddOrEditZone/AddOrEditZone";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type ZonesTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const ZonesTemplate = ({role} : ZonesTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const paginationPageState = useAppSelector((state) => state.zonesPage.paginationPage);
    const filterTypeState = useAppSelector((state) => state.zonesPage.filterType);
    const filterTitleState = useAppSelector((state) => state.zonesPage.filterTitle);
    const fullTextValueState = useAppSelector((state) => state.zonesPage.fullTextValue);
    const paginationOrderState = useAppSelector((state) => state.zonesPage.paginationOrder);
    const addOrEditZoneModalShownState = useAppSelector((state) => state.zonesPage.addOrEditZoneModalShown);
    const addOrEditZoneModalIdState = useAppSelector((state) => state.zonesPage.addOrEditZoneModalId);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('ZonesTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('ZonesTemplate_title')}
                        easySearch={{
                            searchValue: fullTextValueState,
                            dispatchSearchValueUpdate: zonesPageSlice.actions.filterFullText
                        }}
                        breadItems={[
                            {name: t('Navigation_zones'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <ListZones
                                dispatchShowAddOrEditZoneModal={zonesPageSlice.actions.showAddOrEditZoneModal}
                                pagination={{
                                    showCount: 10,
                                    order: paginationOrderState,
                                    dispatchChangeOrder: zonesPageSlice.actions.changePaginationOrder,
                                    page: paginationPageState,
                                    dispatchChangePage: zonesPageSlice.actions.changePaginationPage
                                }}
                                filter={{
                                    fullTextValue: fullTextValueState ? fullTextValueState.toLowerCase() : null,
                                    filterType: filterTypeState,
                                    filterTitle: filterTitleState,
                                    dispatchFilterActive: zonesPageSlice.actions.destroyFilter,
                                    dispatchFilterAll: zonesPageSlice.actions.filterAll,
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            {addOrEditZoneModalShownState &&
                <AddOrEditZone
                    zoneId={addOrEditZoneModalIdState}
                    dispatchHideAddOrEditZoneModal={zonesPageSlice.actions.hideAddOrEditZoneModal} />
            }
        </main>
    );
}

export default ZonesTemplate;
