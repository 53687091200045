import React, {useEffect} from "react";
import "./HardwareCreateDialogScan.scss"
import AddOrEditHardware from "../AddOrEditHardware/AddOrEditHardware";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {hardwareCreateDialogScanOrganismSlice} from "../../../store/slices/organisms/hardwareCreateDialogScanSlice";
import QRImg from "../../../images/QR.svg";
import okTick from "../../../images/inputOK.svg";
import Button, {ButtonType} from "../../atoms/Button/Button";
import {T, useTranslate} from "@tolgee/react";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {CreateHardwarePageScannedData} from "../../../store/slices/pages/createHardwarePageSlice";

type HardwareCreateDialogScanProps = {

    dispatchScanRequested: ActionCreatorWithoutPayload,
    scannedData: CreateHardwarePageScannedData|null,
    dispatchSetScannedData: ActionCreatorWithPayload<{scannedData: CreateHardwarePageScannedData | null}>
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchScanRequested - Redux callback to request scan
 * @param {CreateHardwarePageScannedData|null} scannedData - scanned data
 * @param {ActionCreatorWithPayload<{scannedData: CreateHardwarePageScannedData | null}>} dispatchSetScannedData - Redux callback to remove scanned data
 */
export const HardwareCreateDialogScan = ({dispatchScanRequested, scannedData, dispatchSetScannedData} : HardwareCreateDialogScanProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const hardwareTemplateState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogScanOrganism, 'hardwareTemplate'));
    const modelState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogScanOrganism, 'model'));
    const uidState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogScanOrganism, 'uid'));
    const internalIdState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogScanOrganism, 'internalId'));
    const formValidState = useAppSelector((state) => selectValidState(state.hardwareCreateDialogScanOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.hardwareCreateDialogScanOrganism));

    useEffect(() => {
        dispatch(hardwareCreateDialogScanOrganismSlice.actions.clearForm({t: t}));
    }, [dispatch, t]);

    return (
        <div className={"HardwareCreateDialogScan"}>
            <div className="HardwareCreateDialogScan-content">
                {scannedData ?
                    <div className="HardwareCreateDialogScan-content-scanned">
                        <div className="HardwareCreateDialogScan-content-scanned-left">
                            <div className="HardwareCreateDialogScan-content-scanned-left-image">
                                <img src={QRImg} alt={t('HardwareQRScan_qr')}/>
                            </div>
                            <div className="HardwareCreateDialogScan-content-scanned-left-text">
                                <div className="HardwareCreateDialogScan-content-scanned-left-text-item">
                                    <T keyName={'HardwareCreateDialogScan_qrCodeLoadedSuccess'} params={{b: <b />}} />
                                </div>
                                <div className="HardwareCreateDialogScan-content-scanned-left-text-tick">
                                    <img src={okTick} alt={t('HardwareCreateDialogScan_qrCodeLoadedSuccess')} />
                                </div>
                            </div>
                        </div>
                        <div className="HardwareCreateDialogScan-content-scanned-right">
                            <AddOrEditHardware
                                isModal={false}
                                scannedData={scannedData}
                                muteSuccess={true}
                                addMoreCallback={ () => {
                                    dispatch(dispatchSetScannedData({scannedData: null}));
                                    dispatch(dispatchScanRequested());
                                }}
                                formState={{
                                    hardwareTemplateState: hardwareTemplateState,
                                    modelState: modelState,
                                    uidState: uidState,
                                    internalIdState: internalIdState,
                                    formValidState: formValidState,
                                    formErrorState: formErrorState,
                                    clearForm: hardwareCreateDialogScanOrganismSlice.actions.clearForm,
                                    setFormError: hardwareCreateDialogScanOrganismSlice.actions.setFormError,
                                    clearFormErrors: hardwareCreateDialogScanOrganismSlice.actions.clearFormErrors,
                                    updateInput: hardwareCreateDialogScanOrganismSlice.actions.updateInput,
                                    showInputError: hardwareCreateDialogScanOrganismSlice.actions.setFocus,
                                    hideInputError: hardwareCreateDialogScanOrganismSlice.actions.removeFocus,
                                    showInputValidStates: hardwareCreateDialogScanOrganismSlice.actions.showInputValidStates,
                                }}/>
                        </div>
                    </div>
                    :
                    <div className="HardwareCreateDialogScan-content-notScanned">
                        <div className="HardwareCreateDialogScan-content-notScanned-inside">
                            <div className="HardwareCreateDialogScan-content-notScanned-inside-image">
                                <img src={QRImg} alt={t('HardwareQRScan_qr')}/>
                            </div>
                            <div className="HardwareCreateDialogScan-content-notScanned-inside-button">
                                <Button
                                    onClick={() => { dispatch(dispatchScanRequested()); }}
                                    text={t('HardwareQRScan_button')}
                                    type={ButtonType.PRIMARY}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default HardwareCreateDialogScan;
