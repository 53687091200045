import React, {useEffect} from "react";
import "./HardwareGraphs.scss"
import {Graph, GraphData, GraphPointData} from "../../molecules/Graph/Graph";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionGetSensorData} from "../../../store/actions/organisms/hardwareGraphsAction";
import {DataDto} from "../../../models/entities/DataDto";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {hardwareGraphsOrganismSlice} from "../../../store/slices/organisms/hardwareGraphsSlice";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {T, useTranslate} from "@tolgee/react";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {SensorDataChartTypeEnum} from "../../../models/entities/Enums/SensorDataChartTypeEnum";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type HardwareGraphsProps = {
    sensorsData: Array<SensorResponseDto>,
    zoneList: Array<ZoneDto>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {SensorDto[]} sensorsData - sensors data
 * @param {ZoneDto[]} zoneList - zone list
 */
export const HardwareGraphs = ({sensorsData, zoneList} : HardwareGraphsProps): React.JSX.Element|null => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const sensorDataState = useAppSelector((state) => state.hardwareGraphsOrganism.sensorsData);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);

    // Init
    useEffect(() => { sensorsData.forEach((sensor: SensorResponseDto) => { dispatch(actionGetSensorData(sensor.Sensor.id)); }); }, [dispatch, sensorsData])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])
    useEffect(() => { return () => { dispatch(hardwareGraphsOrganismSlice.actions.destroyOrganism()); } }, [dispatch])

    // Parse input data for graph
    const parseSensorsDataToGraphData = (sensorTypes: Array<SensorTypeDto>, skipEmptyValues: boolean) : Array<GraphData>|null|undefined => {

        let outputGraphData : Array<GraphData> = [];
        if (Object.keys(sensorDataState).length === 0) { return undefined; }
        for (let key in sensorDataState) {

            const currentSensor = sensorsData.find((sensor: SensorResponseDto) => { return sensor.Sensor.id === parseInt(key); });
            if (!currentSensor) { console.warn(`Impossible to find sensor with id ${key}!`); continue; }

            let dataResponse = sensorDataState[key];
            if (dataResponse === null || !dataResponse.Data) { return null; }
            const dataValues = dataResponse.Data;
            if (dataValues.length === 0 && skipEmptyValues) { continue; }
            const pointValues : GraphPointData = dataValues.map((data: DataDto) => {
                return currentSensor.SensorTemplate.dataChartType === SensorDataChartTypeEnum.XRANGE
                    ? {x: new Date(data.createdAt).getTime(), x2: new Date(data.createdAt).getTime() + 600000, y: data.value}
                    : {x: new Date(data.createdAt).getTime(), y: data.value}
            });

            const sensorType = DataParser.getSensorTypeFromList(sensorTypes, currentSensor.SensorTemplate.type);
            const title = currentSensor.Sensor.customName ?? SensorUtil.getMetaForSensorType(currentSensor.SensorTemplate.type, t).name;
            const unit =  sensorType ? sensorType.unit : null;

            // Skip if NONE type
            if (currentSensor.SensorTemplate.dataChartType === SensorDataChartTypeEnum.NONE) {
                continue;
            }

            outputGraphData.push({
                range: {
                    min: currentSensor.SensorTemplate.rangeMin,
                    max: currentSensor.SensorTemplate.rangeMax,
                    step: currentSensor.SensorTemplate.rangeStep ?? undefined
                },
                type: currentSensor.SensorTemplate.dataChartType,
                points: pointValues,
                title: title,
                unit: unit,
                alertState: currentSensor.Sensor.alertState
            });
        }

        return outputGraphData;
    }

    // Loading sensor types
    if (typeof sensorTypes === "undefined" || isLoading) {
        return (
            <Loading type={LoadingTypeEnum.CARD} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    // Get parsed graph data
    const graphData = parseSensorsDataToGraphData(sensorTypes, true);
    const graphCount = parseSensorsDataToGraphData(sensorTypes, false)?.length ?? null;

    // Loading hardware
    if (sensorsData.length === 0) {
        return (
            <Empty
                subTitle={t('HardwareGraphs_withoutSensors_subtitle')}
                title={t('HardwareGraphs_withoutSensors_title')}
                type={EmptyTypeEnum.CARD_FLUID}/>
        )
    }

    // Loading hardware
    if (typeof graphData === 'undefined' || typeof sensorTypes === "undefined") {
        return (
            <Loading type={LoadingTypeEnum.CARD} />
        )
    }

    // Error state graph data
    if (graphData === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_graphData')})} />
        )
    }

    if (graphCount === 0) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD}
                title={t('Base_graph_empty_title')}
                subTitle={t('Base_graph_none_subtitle')} />
        )
    }

    // Empty graph data
    if (graphData.length === 0) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD}
                title={t('Base_graph_empty_title')}
                subTitle={t('Base_graph_empty_subtitle')} />
        )
    }

    return (
        <div className="HardwareGraphs">
            <div className="HardwareGraphs-content">
                <div className="HardwareGraphs-content-title">
                    <T keyName={'Base_graph_title'} />
                </div>
                <div className="HardwareGraphs-content-graph">
                    <Graph
                        zoneList={zoneList}
                        showLegend={true}
                        data={graphData}/>
                </div>
            </div>
        </div>
    )
}

export default HardwareGraphs;
