// noinspection JSUnusedAssignment,UnreachableCodeJS,UnreachableCodeJS

import React, {FormEvent, useEffect} from "react";
import "./GoogleAuth.scss"
import QRIcon from "../../../images/QR.svg";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {googleAuthOrganismSlice} from "../../../store/slices/organisms/googleAuthSlice";
import Swal from "sweetalert2";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {T, useTranslate} from "@tolgee/react";

type GoogleAuthProps = {
    dispatchShowRecoveryCodesModal: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const GoogleAuth = ({dispatchShowRecoveryCodesModal} : GoogleAuthProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const pinState = useAppSelector((state) => selectInputState(state.googleAuthOrganism, 'pin'));
    const formValidState = useAppSelector((state) => selectValidState(state.googleAuthOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.googleAuthOrganism));

    useEffect(() => {
        return () => { dispatch(googleAuthOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!pinState || !pinState.value) {
            dispatch(googleAuthOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }
    }

    const handleReset = async () => {

        Swal.fire({
            title: t('GoogleAuth_confirm_title'),
            text: t('GoogleAuth_confirm_body'),
            showCancelButton: true,
            confirmButtonText: `Confirm`,
            denyButtonText: `Decline`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // TODO CALL BACKEND
                //const result = await dispatch(actionResetGA())
                //if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                //    dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload.toString()}));
                //    return;
                //}
                //dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: 'Google Auth authorization was successfully disabled.'}));
            }
        })
    }

    const handleRecoveryCodes = async () => {
        dispatch(dispatchShowRecoveryCodesModal());
    }

    // eslint-disable-next-line
    return (
        <div className={"GoogleAuth"}>
            <div className="GoogleAuth-content">
                <div className="GoogleAuth-content-left">
                    <div className="GoogleAuth-content-left-title">
                        <T keyName={'GoogleAuth_register_title'} />
                    </div>
                    <div className="GoogleAuth-content-left-subTitle">
                        <T keyName={'GoogleAuth_register_subtitle'} />
                    </div>
                </div>
                <div className="GoogleAuth-content-right">
                    <div className="GoogleAuth-content-right-preamble">
                        <T keyName={'GoogleAuth_register_preamble'} />
                    </div>
                    <div className="GoogleAuth-content-right-manage">
                        <div className="GoogleAuth-content-right-manage-button">
                            <Button
                                text={t('GoogleAuth_register_button_delete')}
                                onClick={ () => {  void handleReset(); } }
                                type={ButtonType.DELETE} />
                        </div>
                        <div className="GoogleAuth-content-right-manage-button">
                            <Button
                                onClick={ () => { void handleRecoveryCodes(); } }
                                text={t('GoogleAuth_register_button_recovery')}
                                type={ButtonType.PRIMARY} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    // eslint-disable-next-line
    return (
        <div className={"GoogleAuth"}>
            <div className="GoogleAuth-content">
                <div className="GoogleAuth-content-left">
                    <div className="GoogleAuth-content-left-title">
                        <T keyName={'GoogleAuth_content_title'} />
                    </div>
                    <div className="GoogleAuth-content-left-subTitle">
                        <T keyName={'GoogleAuth_content_subtitle'} />
                    </div>
                </div>
                <div className="GoogleAuth-content-right">
                    <div className="GoogleAuth-content-right-preamble">
                        { // eslint-disable-next-line
                        } <T keyName={'GoogleAuth_content_preamble'} params={{a: <a/>}} />
                    </div>
                    <div className={'GoogleAuth-content-right-qr'}>
                        <div className="GoogleAuth-content-right-qr-code">
                            <img src={QRIcon} alt={t('GoogleAuth_content_qr')} />
                        </div>
                        <div className="GoogleAuth-content-right-qr-help">
                            <p>
                                <b><T keyName={'GoogleAuth_content_help_cantScan'}/></b>
                            </p>
                            <p>
                                <T keyName={'GoogleAuth_content_help_details'} />
                            </p>
                            <p>
                                <T keyName={'GoogleAuth_content_help_account'} />: masterapp-gitlab.masterinter.net:kotolan@master.cz<br />
                                <T keyName={'GoogleAuth_content_help_key'} />: <code>I5KK 7Qz2 e7zp uptg nnfg kawb s33w 77k0</code><br />
                                <T keyName={'GoogleAuth_content_help_timeBased'} />: Yes
                            </p>
                        </div>
                    </div>
                    <div className="GoogleAuth-content-right-pin">
                        <form onSubmit={handleSubmit.bind(this)}>
                            <div className="GoogleAuth-content-right-pin-form-inputs">
                                <div>
                                    <InputText
                                        required={true}
                                        placeholder={t('Enter PIN code from APP')}
                                        inputName="pin"
                                        inputType={InputTextType.TEXT}
                                        label={t('GoogleAuth_pin_label')}
                                        disabled={false}
                                        value={pinState?.value}
                                        inputState={pinState}
                                        dispatchOnChange={googleAuthOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={googleAuthOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={googleAuthOrganismSlice.actions.removeFocus} />
                                </div>
                                {formErrorState && <span className="formError">{formErrorState}</span>}
                            </div>
                            <div className="GoogleAuth-content-right-pin-form-inputs-button">
                                <Button
                                    text={t('GoogleAuth_button')}
                                    type={ButtonType.PRIMARY}
                                    onClickDisabled={() => { dispatch(googleAuthOrganismSlice.actions.showInputValidStates()); }}
                                    disabled={!formValidState}
                                    isSubmit={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoogleAuth;
