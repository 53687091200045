import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionCreateZone, actionUpdateZone} from "../../actions/data/zoneAction";
import {actionGetZone} from "../../actions/organisms/addOrEditZoneAction";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface AddOrEditZoneState extends AbstractFormReducersState {
    zone?: ZoneResponseDto|null
}

const initialState: AddOrEditZoneState = {
    form: {
        inputs: [
            {name: 'name', validations: {required: true}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const addOrEditZoneOrganismSlice = createSlice({
    name: 'addOrEditZoneOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        destroyOrganism: (state: AddOrEditZoneState) => {
            state.zone = undefined;
        }
    }},
    extraReducers: (builder) => {
        builder.addCase(actionCreateZone.fulfilled, (state) => {
            state.form.error = null;
        })
        builder.addCase(actionUpdateZone.fulfilled, (state) => {
            state.form.error = null;
        })
        builder.addCase(actionGetZone.fulfilled, (state, {payload}) => {
            state.zone = payload;
        })
    }
})

export default addOrEditZoneOrganismSlice.reducer
