import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export enum HardwareCreateDialogViewType {
    QR_SCAN = 1,
    MANUAL = 2,
    BULK_IMPORT = 3
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareCreateDialogState {

    /** Hive box full text value to search **/
    viewType: HardwareCreateDialogViewType
}

const initialState: HardwareCreateDialogState = {
    viewType: HardwareCreateDialogViewType.QR_SCAN
};

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareCreateDialogOrganismSlice = createSlice({
    name: 'hardwareCreateDialogOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.viewType = HardwareCreateDialogViewType.QR_SCAN;
        },
        changeViewType: (state, action: PayloadAction<{ viewType: HardwareCreateDialogViewType }>) => {
            state.viewType = action.payload.viewType;
        }
    },
})

export default hardwareCreateDialogOrganismSlice.reducer