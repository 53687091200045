import {createSlice} from '@reduxjs/toolkit'
import {actionRemovePatientHardwareList} from "../../actions/data/patientAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface BulkListZoneHardwareState {
    deleteProgress: boolean
}

const initialState: BulkListZoneHardwareState = {
    deleteProgress: false
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const bulkListZoneHardwareOrganismSlice = createSlice({
    name: 'bulkListZoneHardwareOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.deleteProgress = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(actionRemovePatientHardwareList.pending, (state) => {
            state.deleteProgress = true;
        })
        builder.addCase(actionRemovePatientHardwareList.fulfilled, (state) => {
            state.deleteProgress = false;
        })
        builder.addCase(actionRemovePatientHardwareList.rejected, (state) => {
            state.deleteProgress = false;
        })
    }
})

export default bulkListZoneHardwareOrganismSlice.reducer
