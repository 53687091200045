import React, {FormEvent} from "react";
import "./RegisterBlock.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {AuthorizationRepository} from "../../../models/repositories/AuthorizationRepository";
import {Storage} from "../../../models/utils/Storage";
import {AresRepository} from "../../../models/repositories/AresRepository";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {useNavigate} from "react-router-dom";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {WelcomePageViewType} from "../../../store/slices/pages/welcomePageSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {registerBlockOrganismSlice, RegisterBlockViewType} from "../../../store/slices/organisms/registerBlockSlice";
import {UserRegisterRequestDto} from "../../../models/entities/Requests/UserRegisterRequestDto";
import {T, useTranslate} from "@tolgee/react";

interface RegisterBlockProps {
    dispatchChangeView: ActionCreatorWithPayload<{viewType: WelcomePageViewType}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{viewType: WelcomePageViewType}>} dispatchChangeView - change view type
 */
export const RegisterBlock = ({dispatchChangeView}: RegisterBlockProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidStatePhaseThree = useAppSelector((state) =>
        selectValidState(state.registerBlockOrganism, ['fullName', 'email', 'password', 'passwordAgain']));
    const formValidStatePhaseSecond = useAppSelector((state) =>
        selectValidState(state.registerBlockOrganism, ['companyId', 'vat', 'companyName', 'companyAddress']));
    const formValidStatePhaseFirst = useAppSelector((state) =>
        selectValidState(state.registerBlockOrganism, ['companyId']));

    const formErrorState = useAppSelector((state) => selectFormError(state.registerBlockOrganism));
    const phaseState = useAppSelector((state) => state.registerBlockOrganism.phase);
    const firstName = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'firstName'));
    const lastName = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'lastName'));
    const email = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'email'));
    const password = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'password'));
    const passwordAgain = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'passwordAgain'));
    const companyAddress = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'companyAddress'));
    const companyName = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'companyName'));
    const companyId = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'companyId'));
    const vat = useAppSelector((state) => selectInputState(state.registerBlockOrganism, 'vat'));
    const navigate = useNavigate();

    // Submit ICO and get ARES data
    const handleSubmitPhaseOne = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!companyId || !companyId.value) {
            dispatch(registerBlockOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        try {
            const aresResponse = await AresRepository.getAres({companyIdNumber: companyId.value.toString()}, t);
            const address = aresResponse.address;
            const companyAddress = `${address.street}, ${address.number}, ${address.postNumber} ${address.city}`;
            dispatch(registerBlockOrganismSlice.actions.updateInput(
                {inputName: 'companyName', inputValue: aresResponse.name, t: t}));
            dispatch(registerBlockOrganismSlice.actions.updateInput(
                {inputName: 'companyAddress', inputValue: companyAddress, t: t}));
            dispatch(registerBlockOrganismSlice.actions.updateInput(
                {inputName: 'companyId', inputValue: aresResponse.idNumber, t: t}));
            dispatch(registerBlockOrganismSlice.actions.updateInput(
                {inputName: 'vat', inputValue: aresResponse.taxIdNumber, t: t}));
        } catch (e: any) {
            dispatch(registerBlockOrganismSlice.actions.setFormError({error: e.toString()}));
            return;
        }

        dispatch(registerBlockOrganismSlice.actions.setFormError({error: null}));
        dispatch(registerBlockOrganismSlice.actions.setPhase({viewType: RegisterBlockViewType.COMPANY}));
    }

    // Confirm company
    const handleSubmitPhaseTwo = (event: FormEvent<HTMLFormElement>) : void => {

        event.preventDefault();
        event.stopPropagation();
        dispatch(registerBlockOrganismSlice.actions.setPhase({viewType: RegisterBlockViewType.USER}));
    }

    // Submit user and company create data
    const handleSubmitPhaseThree = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!email || !email.value || !password || !password.value || !firstName || !firstName.value || !companyId || !companyId.value
            || !companyName || !companyName.value || !companyAddress || !companyAddress.value || !vat || !vat.value
            || !passwordAgain || !passwordAgain.value || !lastName || !lastName.value) {
            dispatch(registerBlockOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        if (password.value !== passwordAgain.value) {
            dispatch(registerBlockOrganismSlice.actions.setFormError({error: t('RegisterBlock_error_password_match')}));
            return;
        }

        const userRequest: UserRegisterRequestDto = {
            firstName: firstName.value.toString(),
            lastName: lastName.value.toString(),
            email: email.value.toString(),
            password: password.value.toString(),
        };

        try {
            await AuthorizationRepository.register(userRequest, {companyIdNumber: companyId.value.toString()}, t);
            const tokenResponse = await AuthorizationRepository.logIn(email.value.toString(), password.value.toString(), t);
            Storage.saveSession(tokenResponse);
            navigate('/dashboard/');
        } catch (e: any) {
            dispatch(registerBlockOrganismSlice.actions.setFormError({error: e.toString()}));
            return;
        }

        dispatch(registerBlockOrganismSlice.actions.setFormError({error: null}));
    }

    if (phaseState === RegisterBlockViewType.USER) {
        return (
            <div className="RegisterBlock">
                <div className="RegisterBlock-header">
                    <T keyName={'RegisterBlock_title'} />
                </div>
                <div className="LoginBlock-description">
                    <T keyName={'RegisterBlock_subtitle_personal'} />
                </div>
                <div className="RegisterBlock-form">
                    <form onSubmit={handleSubmitPhaseThree.bind(this)}>
                        <div className="RegisterBlock-form-inputs">
                            <InputText
                                inputState={null}
                                value={null}
                                inputName={Math.random().toString()}
                                inputType={InputTextType.AUTOFILL_FIX}
                                placeholder={Math.random().toString()} />
                            <InputText
                                value={firstName?.value}
                                placeholder={t('RegisterBlock_name_placeholder')}
                                inputName="firstName"
                                label={t('RegisterBlock_name_label')}
                                inputState={firstName}
                                inputType={InputTextType.TEXT}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                value={lastName?.value}
                                placeholder={t('RegisterBlock_lastName_placeholder')}
                                inputName="lastName"
                                label={t('RegisterBlock_lastName_label')}
                                inputState={lastName}
                                inputType={InputTextType.TEXT}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                value={email?.value}
                                placeholder={t('RegisterBlock_email_placeholder')}
                                label={t('RegisterBlock_email_label')}
                                inputName="email"
                                inputState={email}
                                inputType={InputTextType.TEXT}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                value={password?.value}
                                label={t('RegisterBlock_password_label')}
                                placeholder={t('RegisterBlock_password_placeholder')}
                                inputName="password"
                                inputState={password}
                                inputType={InputTextType.PASSWORD}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                value={passwordAgain?.value}
                                placeholder={t('RegisterBlock_passwordAgain_placeholder')}
                                label={t('RegisterBlock_passwordAgain_label')}
                                inputName="passwordAgain"
                                inputType={InputTextType.PASSWORD}
                                inputState={passwordAgain}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            {formErrorState && <span className="formError">{formErrorState}</span>}
                        </div>
                        <div className="RegisterBlock-form-button">
                            <Button
                                options={{textCentered: true, positionCentered: true}}
                                text={t('RegisterBlock_button_personal')}
                                type={ButtonType.PRIMARY}
                                onClickDisabled={() => { dispatch(registerBlockOrganismSlice.actions.showInputValidStates()); }}
                                disabled={!formValidStatePhaseThree}
                                isSubmit={true} />
                        </div>
                    </form>
                </div>
                <div className="RegisterBlock-logIn"
                     onClick={() => dispatch(dispatchChangeView({viewType: WelcomePageViewType.LOGIN}))}>
                    <T keyName={'RegisterBlock_logIn'} />
                </div>
            </div>
        );
    }

    if (phaseState === RegisterBlockViewType.COMPANY) {
        return (
            <div className="RegisterBlock">
                <div className="RegisterBlock-header">
                    <T keyName={'RegisterBlock_title'} />
                </div>
                <div className="LoginBlock-description">
                    <T keyName={'RegisterBlock_subtitle_company'} />
                </div>
                <div className="RegisterBlock-form">
                    <form onSubmit={handleSubmitPhaseTwo.bind(this)}>
                        <div className="RegisterBlock-form-inputs">
                            <InputText
                                inputState={null}
                                inputName={Math.random().toString()}
                                inputType={InputTextType.AUTOFILL_FIX}
                                placeholder={Math.random().toString()} />
                            <InputText
                                startValue={companyId?.value}
                                disabled={true}
                                value={companyId?.value}
                                inputName="companyId"
                                label={t('RegisterBlock_companyNo_label')}
                                inputType={InputTextType.TEXT}
                                placeholder={t('RegisterBlock_companyNo_placeholder')}
                                inputState={companyId}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                startValue={vat?.value}
                                value={vat?.value}
                                disabled={true}
                                inputName="vat"
                                label={t('RegisterBlock_vat_label')}
                                inputType={InputTextType.TEXT}
                                placeholder={ValueUtil.getStringFromValue(t, vat?.value)}
                                inputState={vat}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                startValue={companyName?.value}
                                value={companyName?.value}
                                disabled={true}
                                inputName="companyName"
                                label={t('RegisterBlock_companyName_label')}
                                inputType={InputTextType.TEXT}
                                inputState={companyName}
                                placeholder={ValueUtil.getStringFromValue(t, companyName?.value)}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            <InputText
                                startValue={companyAddress?.value}
                                value={companyAddress?.value}
                                disabled={true}
                                inputName="companyAddress"
                                label={t('RegisterBlock_companyAddress_label')}
                                inputType={InputTextType.TEXT}
                                inputState={companyAddress}
                                placeholder={ValueUtil.getStringFromValue(t, companyAddress?.value)}
                                dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                                dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                                dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                            {formErrorState && <span className="formError">{formErrorState}</span>}
                        </div>
                        <div className="RegisterBlock-form-button">
                            <Button
                                options={{textCentered: true, positionCentered: true}}
                                text={t('RegisterBlock_button_company')}
                                type={ButtonType.PRIMARY}
                                onClickDisabled={() => { dispatch(registerBlockOrganismSlice.actions.showInputValidStates()); }}
                                disabled={!formValidStatePhaseSecond}
                                isSubmit={true} />
                        </div>
                    </form>
                </div>
                <div className="RegisterBlock-logIn"
                     onClick={() => dispatch(registerBlockOrganismSlice.actions.setPhase({viewType: RegisterBlockViewType.IDENTIFY})) }>
                    <T keyName={'RegisterBlock_goBack'} />
                </div>
            </div>
        )
    }

    return (
        <div className="RegisterBlock">
            <div className="RegisterBlock-header">
                <T keyName={'RegisterBlock_title'} />
            </div>
            <div className="LoginBlock-description">
                <T keyName={'RegisterBlock_subtitle_identify'} />
            </div>
            <div className="RegisterBlock-form">
                <form onSubmit={handleSubmitPhaseOne.bind(this)}>
                    <div className="RegisterBlock-form-inputs">
                        <InputText
                            placeholder="Identification number"
                            label="Company ID"
                            inputName="companyId"
                            value={companyId?.value}
                            inputType={InputTextType.TEXT}
                            inputState={companyId}
                            dispatchOnChange={registerBlockOrganismSlice.actions.updateInput}
                            dispatchOnFocus={registerBlockOrganismSlice.actions.setFocus}
                            dispatchOnBlur={registerBlockOrganismSlice.actions.removeFocus} />
                        {formErrorState && <span className="formError">{formErrorState}</span>}
                    </div>
                    <div className="RegisterBlock-form-button">
                        <Button
                            options={{textCentered: true, positionCentered: true}}
                            text={t('RegisterBlock_button_identify')}
                            type={ButtonType.PRIMARY}
                            onClickDisabled={() => { dispatch(registerBlockOrganismSlice.actions.showInputValidStates()); }}
                            disabled={!formValidStatePhaseFirst}
                            isSubmit={true} />
                    </div>
                </form>
            </div>
            <div className="RegisterBlock-logIn" onClick={() => dispatch(dispatchChangeView({viewType: WelcomePageViewType.LOGIN}))}>
                <T keyName={'RegisterBlock_logIn'} />
            </div>
        </div>
    );
}

export default RegisterBlock;
