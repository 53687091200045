import React, {FormEvent, useEffect} from "react";
import "./AddOrEditZone.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import personIcon from "../../../images/person.svg";
import {useTranslate} from "@tolgee/react";
import {addOrEditZoneOrganismSlice} from "../../../store/slices/organisms/addOrEditZoneSlice";
import {actionGetZone} from "../../../store/actions/organisms/addOrEditZoneAction";
import {ZoneRequestDto} from "../../../models/entities/Requests/ZoneRequestDto";
import {actionCreateZone, actionUpdateZone} from "../../../store/actions/data/zoneAction";

type AddOrEditZoneProps = {
    zoneId: number|null,
    dispatchHideAddOrEditZoneModal: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideAddOrEditZoneModal - hide modal Redux callback
 * @param {number|null} zoneId - zone id for edit mode
 */
export const AddOrEditZone = ({dispatchHideAddOrEditZoneModal, zoneId} : AddOrEditZoneProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t, isLoading} = useTranslate();
    const zoneState = useAppSelector((state) => state.addOrEditZoneOrganism.zone);

    // Form state
    const nameState = useAppSelector((state) => selectInputState(state.addOrEditZoneOrganism, 'name'));
    const formValidState = useAppSelector((state) => selectValidState(state.addOrEditZoneOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.addOrEditZoneOrganism));

    // In edit mode init current zone
    useEffect(() => { if (zoneId) { dispatch(actionGetZone(zoneId)); } }, [dispatch, zoneId])

    // Destroy
    useEffect(() => { return () => {
        dispatch(addOrEditZoneOrganismSlice.actions.clearForm({t: t}));
        dispatch(addOrEditZoneOrganismSlice.actions.destroyOrganism());
    } }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!nameState || !nameState.value) {
            dispatch(addOrEditZoneOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const zoneRequest : ZoneRequestDto = {
            name: nameState.value.toString(),
        }

        const result = zoneId
            ? await dispatch(actionUpdateZone({zoneId: zoneId, zoneRequest: zoneRequest, t: t}))
            : await dispatch(actionCreateZone({zoneRequest: zoneRequest, t: t}));

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(addOrEditZoneOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchHideAddOrEditZoneModal());
    }

    // Loading
    if ((zoneId && typeof zoneState === 'undefined') || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('AddOrEditZone_title')}
                modal={{dispatchHide: dispatchHideAddOrEditZoneModal}}/>
        )
    }

    // Error state
    if (zoneId && zoneState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideAddOrEditZoneModal ? {
                    name: t('AddOrEditZone_title'),
                    dispatchHide: dispatchHideAddOrEditZoneModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description", {dataName: t('Base_name_zone')})} />
        )
    }

    const form = (
        <form onSubmit={ (event: FormEvent<HTMLFormElement>) => { void handleSubmit(event); }}>
            <div className="AddOrEditZone-content-form-inputs">
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditZone_name_placeholder')}
                        inputName="name"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditZone_name_label')}
                        disabled={false}
                        startValue={zoneState ? zoneState.Zone.name : null}
                        value={nameState?.value}
                        inputState={nameState}
                        dispatchOnChange={addOrEditZoneOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditZoneOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditZoneOrganismSlice.actions.removeFocus} />
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="AddOrEditZone-content-form-inputs-button">
                <Button
                    image={personIcon}
                    text={zoneId ? t('AddOrEditZone_button_edit') : t('AddOrEditZone_button_add')}
                    type={ButtonType.PRIMARY}
                    onClickDisabled={() => { dispatch(addOrEditZoneOrganismSlice.actions.showInputValidStates()); }}
                    disabled={!formValidState}
                    isSubmit={true} />
            </div>
        </form>
    )

    return (
        <div className="AddOrEditZone">
            <div className="AddOrEditZone-content">
                <div className="AddOrEditZone-content-title">
                    <div className="AddOrEditZone-content-title-text">
                        {zoneId ? t('AddOrEditZone_button_edit') : t('AddOrEditZone_button_add')}
                    </div>
                    <div className="AddOrEditZone-content-title-close">
                        {dispatchHideAddOrEditZoneModal &&
                            <Image onClick={() => dispatch(dispatchHideAddOrEditZoneModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AddOrEditZone-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AddOrEditZone;
