import {LogResponseDto} from "../../../models/entities/Responses/LogResponseDto";
import {TableRows} from "../../atoms/Table/Table";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import React, {useEffect} from "react";
import {FunctionTable} from "../../molecules/FunctionTable/FunctionTable";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import "./ListLog.scss"
import {HardwareDto} from "../../../models/entities/HardwareDto";
import {AlertDto} from "../../../models/entities/AlertDto";
import {PatientDto} from "../../../models/entities/PatientDto";
import {listLogOrganismSlice} from "../../../store/slices/organisms/listLogSlice";
import {
    actionGetAlertLogList,
    actionGetHardwareLogList,
    actionGetLogList,
    actionGetPatientLogList
} from "../../../store/actions/organisms/listLogAction";
import {PaginationDto} from "../../../models/entities/Pagination";
import {useTranslate} from "@tolgee/react";
import {LogUtil} from "../../../models/utils/LogUtil";
import {TableRowDataTitle} from "../../atoms/Table/TableRow";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {SensorUtil} from "../../../models/utils/SensorUtil";

/**
 * @alias HardwareLogPagination
 * @category Components
 */
type HardwareLogPagination = {

    /** Currently shown count **/
    showCount: number;
}

interface HardwareLogProps {
    hardware?: HardwareDto,
    alert?: AlertDto,
    patient?: PatientDto,
    pagination: HardwareLogPagination,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {HardwareDto|undefined} hardware - log hardware entity
 * @param {HardwareLogPagination} pagination - pagination options
 * @param {AlertDto|undefined} alert - log alert entity
 * @param {PatientDto|undefined} patient - log patient entity
 */
export const ListLog = ({hardware, pagination, alert, patient}: HardwareLogProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const logListState = useAppSelector((state) => state.listLogOrganism.logList);
    const logsPageState = useAppSelector((state) => state.listLogOrganism.paginationPage);
    const dispatch = useAppDispatch();

    // Init
    useEffect(() => {

        if (hardware) {
            dispatch(actionGetHardwareLogList({
                hardwareId : hardware.id, pagination : {pageSize: pagination.showCount, currentPage: logsPageState}
            }));
        }

        else if (alert) {
            dispatch(actionGetAlertLogList({
                alertId: alert.id, pagination: {pageSize: pagination.showCount, currentPage: logsPageState}
            }));
        }

        else if (patient) {
            dispatch(actionGetPatientLogList({
                patientId: patient.id, pagination: {pageSize: pagination.showCount, currentPage: logsPageState}
            }));
        }

        else {
            dispatch(actionGetLogList({pageSize: pagination.showCount, currentPage: logsPageState}));
        }

    }, [dispatch, hardware, alert, patient, logsPageState, pagination.showCount])

    // Clean
    useEffect(() => { return () => { dispatch(listLogOrganismSlice.actions.destroyOrganism()); } }, [dispatch])

    // Get link depending on action type
    const getActionLinkFromLogItem = (log: LogResponseDto, t: any) : TableRowDataTitle|null => {

        if (log.Log.logAlert) { return {link: `/alert/${log.Log.logAlert}`, title: t('ListLog_gotoAlertDetail')}; }
        if (log.Log.logHardware) { return {link: `/devices/${log.Log.logHardware}`, title: t('ListLog_gotoHardwareDetail')}; }
        if (log.Log.logUser) { return {link: `/user/${log.Log.logUser}`, title: t('ListLog_gotoUserDetail')}; }
        return null;
    }

    // Create table data for logs
    const parseLogList = () : {rows: TableRows, pagination: PaginationDto}|null|undefined => {

        if (!logListState) { return logListState; }
        return {pagination: logListState.Pagination, rows: logListState.Logs.map((log : LogResponseDto) => {
            const actionLink = getActionLinkFromLogItem(log, t);
            const ago = SensorUtil.getTimeFormatAgoForDateWithFallBackText(
                Date.now() - Date.parse(log.Log.createdAt), t, false, true);
            return {link: actionLink ? actionLink : undefined,
                rowTitle: ValueUtil.getStringFromValue(t, log.Log.createdAt),
                rowSubtitle: `${log.LogEvent.text} - ${ago}`,
                columns: [
                    {text: LogUtil.logEventIdToTextValue(log.LogEvent, t)},
                    {label: LogUtil.logEventTypeToTextValue(log.LogEvent, t)},
                    {text: log.Log.customText ?? (log.User ? `${log.User.firstName} ${log.User.lastName}` : null) ?? '-'},
                    {text: ValueUtil.getStringFromValue(t, log.Log.createdAt)},
                ]
            }
        })};
    }

    // Init data
    const parsedLogList = parseLogList();

    // Loading hardware
    if (typeof parsedLogList === 'undefined' || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state
    if (parsedLogList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_logs')})} />
        )
    }

    return (
        <div className="ListLog">
            <FunctionTable
                empty={{title: t('ListLog_empty_title'), subTitle: t('ListLog_empty_subtitle')}}
                fePagination={{
                    size: pagination.showCount,
                    page: logsPageState,
                    dispatchChangePage: listLogOrganismSlice.actions.changeLogsPaginationPage
                }}
                bePagination={{
                    total:parsedLogList.pagination.totalItemCount
                }}
                data={{
                    rows: parsedLogList.rows,
                    columns: [
                        {name: t('ListLog_col_event')},
                        {name: t('ListLog_col_initiator')},
                        {name: t('ListLog_col_value')},
                        {name: t('ListLog_col_date')}
                    ]
                }} />
        </div>
    );
}

export default ListLog;
