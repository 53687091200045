import "./QRScan.scss"
import React from "react";
import {QrReader} from "react-qr-reader";
import cameraNotAllowedImg from "../../../images/cameraNotAllowed.svg";
import {T, useTranslate} from "@tolgee/react";

/**
 * @alias ResultCallbackFunction
 * @category Components
 * @subcategory Molecules
 */
export type ResultCallbackFunction = (result: string) => void;

type QRScanProps = {
    resultCallBack: (result: string) => void;
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {ResultCallbackFunction} resultCallBack - result callback on read
 */
export const QRScan = ({resultCallBack} : QRScanProps): React.JSX.Element => {

    const [notAllowed, setNotAllowed] = React.useState<boolean|null>(null);
    const {t} = useTranslate();

    if (notAllowed) {
        return  (
            <div className={`QRScan`}>
                <div className={`QRScan-notAllowed`}>
                    <div className={`QRScan-notAllowed-inside`}>
                        <div className={`QRScan-notAllowed-inside-items`}>
                            <div className={`QRScan-notAllowed-inside-items-image`}>
                                <img src={cameraNotAllowedImg} alt={t('QRScan_enable_camera')} />
                            </div>
                            <div className={`QRScan-notAllowed-inside-items-text`}>
                                <T keyName={'QRScan_enable_camera'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`QRScan`}>
            <div className={`QRScan-content`}>
                <QrReader
                    onResult={(result, error: Error | undefined | null) => {
                        if (!!result) {
                            resultCallBack(result.getText());
                        }
                        if (!!error) {
                            if (error.name === 'NotAllowedError') {
                                setNotAllowed(true);
                            }
                        }
                    }}
                    constraints={{facingMode: 'environment'}}/>
            </div>
        </div>
    );
}

export default QRScan;
