import "./CustomDate.scss"
import React, {useEffect} from "react";
import DatePicker from 'react-date-picker'
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {InputState, AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";
import {Value} from "react-calendar/src/shared/types";
import 'react-date-picker/dist/DatePicker.css';

type CustomDateProps = {
    inputState: InputState|null,
    label?: string,
    inputName: string,
    value?: string|null|number,
    defaultValue?: string|null,
    dispatchOnChange?: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    required?: boolean,
    clearable?: boolean,
    dispatchOnFocus?: ActionCreatorWithPayload<{inputName: string}>,
    dispatchOnBlur?: ActionCreatorWithPayload<{inputName: string}>,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnBlur - blur event dispatch
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnFocus - focus event dispatch
 * @param {string|undefined} label - label before select element
 * @param {string} inputName - select input name
 * @param {string|null|number|undefined} value - options used
 * @param {string|null|undefined} defaultValue - default option value
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>|undefined} dispatchOnChange - dispatch event called on change
 * @param {InputState|null} inputState - input Redux state
 * @param {boolean|undefined} required - value is required
 */
export const CustomDate = ({label, inputName, value, defaultValue, dispatchOnChange, inputState, required, dispatchOnBlur, dispatchOnFocus}
    : CustomDateProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    // On init propagate value
    useEffect(() => {
        if (dispatchOnChange && typeof defaultValue !== "undefined") {
            dispatch(dispatchOnChange({
                inputName: inputName,
                inputValue: defaultValue ? defaultValue : null,
                t: t
            }));
        }
    }, [dispatch, inputName, dispatchOnChange, defaultValue, t]);

    // Check if is valid date
    const validDate = (date: any) => { return !isNaN(date) && date instanceof Date; }

    // Convert input string value into date object
    const convertStringToDate = (dateString: string) : Date|null => {

        const convertedValue = Date.parse(dateString);
        if (isNaN(convertedValue)) { return null; }
        const convertedValueDate = convertedValue ? new Date(convertedValue) : null;
        return convertedValueDate && validDate(convertedValueDate) ? convertedValueDate : null;
    }

    // On change propagate changes
    const handleChange = (value: Value) => {
        if (dispatchOnChange) {
            dispatch(dispatchOnChange({
                inputName: inputName,
                inputValue: value && value instanceof Date && validDate(value) ? value.toISOString() : null,
                t: t
            }));
        }
    }

    // Input is focused && blurred
    const handleFocus = () => { if (dispatchOnFocus) { /*dispatch(dispatchOnFocus({inputName: inputName}));*/ } }
    const handleBlur = () => { if (dispatchOnBlur) { /*dispatch(dispatchOnBlur({inputName: inputName}));*/ } }

    // Calculate current layout state
    const valid = inputState && typeof inputState.invalid !== 'undefined' && !inputState.invalid;
    const invalid = inputState && typeof inputState.invalid !== 'undefined' && inputState.invalid;
    const showValid = (valid && value)  || (inputState && valid && inputState.validationShown);
    let convertedValue = value ? convertStringToDate(value.toString()) : null;

    // Show invalid if 1) not focused AND (has some value OR validations shown forced)
    const showInvalid = inputState && inputState.focused === false && invalid && (value !== null || inputState.validationShown);

    return (
        <div className={`CustomDate ${showValid ? 'ok' : ''} ${showInvalid ? 'nok' : ''} `}>
            <label className="CustomDate-label">
                {label &&
                    <div className={`CustomDate-label-block`}>
                        {label} {required ? "*" : ''}
                    </div>
                }
                <div className={`CustomDate-label-date ${invalid ? 'invalid' : 'valid'}`}>
                    {/*
                         // @ts-ignore */}
                    <DatePicker onFocus={handleFocus.bind(this)} onBlur={handleBlur.bind(this)}
                        openCalendarOnFocus={false}
                        format={'d/M/y'}
                        showLeadingZeros={false}
                        onChange={handleChange}
                        name={inputName}
                        maxDate={new Date()}
                        value={convertedValue ? convertedValue : undefined}
                    />
                </div>
            </label>

            <div className={"error"}>
                {showInvalid && inputState?.errors ? '*' + inputState.errors : ''}&nbsp;
            </div>

        </div>
    );
}

export default CustomDate;
