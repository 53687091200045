import "./FilterMain.scss"
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import {Image} from "../../atoms/Image/Image";
import filter from "../../../images/filter.svg";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import search from "../../../images/search.svg";
import React from "react";
import {AbstractFormUpdatePayload, InputState} from "../../../models/utils/AbstractFormReducers";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useTranslate} from "@tolgee/react";
import {ImageType} from "../../../models/entities/Utils/Image";
import Button, {ButtonType} from "../../atoms/Button/Button";

/**
 * @alias FilterMainProps
 * @category Components
 */

type FilterMainProps = {
    customImage?: ImageType,
    filterTypeState: AbstractFilterType,
    title: string|null,
    filterSearchState: InputState|null,
    openedState: boolean,
    updateInput?: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    toggleDropDown: EmptyFunc, }

/**
 * @component
 * @category Components
 * @subcategory Extras
 * @param {AbstractFilterType} filterTypeState - filter state type
 * @param {string|null} title - filter current search title
 * @param {InputState|null} filterSearchState - filter search Redux state
 * @param {boolean} openedState - is opened state
 * @param {undefined|ImageType} customImage - custom image in closed main block
 * @param {EmptyFunc} toggleDropDown - toggle dropdown shown callback
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>|undefined} updateInput - update search input
 */
export const FilterMain = ({filterTypeState, title, filterSearchState, openedState, customImage, toggleDropDown, updateInput} : FilterMainProps): React.JSX.Element => {

    const {t} = useTranslate();

    const getMobileElement = () => {
      return (
          <div className="FilterMain-mobileSearch">
              <div className="FilterMain-mobileSearch-left">
                  {filterTypeState === AbstractFilterType.FILTER_ALL ?
                      <div className="FilterMain-mobileSearch-left-placeholder">
                          <div className="FilterMain-mobileSearch-left-placeholder-text">
                              {title ?? t('FilterMain_filter_items')}
                          </div>
                          <div className="FilterMain-mobileSearch-left-placeholder-icon">
                              <Image source={customImage ?? filter} description={t('FilterMain_show_dropdown')}/>
                          </div>
                      </div>
                      :
                      <div className="FilterMain-mobileSearch-left-input">
                          <form>
                              <InputText
                                  inputState={null}
                                  hasClear={true}
                                  withoutValidations={true}
                                  image={{source: search, title: t("Base_search")}}
                                  placeholder={t('Base_search')}
                                  inputName="filterSearch"
                                  inputType={InputTextType.TEXT}
                                  value={filterSearchState?.value}
                                  onImageClick={() => {toggleDropDown();}}
                                  dispatchOnChange={updateInput} />
                          </form>
                      </div>
                  }
              </div>
              <div className="FilterMain-mobileSearch-right">
                  <Button
                      onClick={ () => { toggleDropDown(); }}
                      text={'Close'}
                      type={ButtonType.SECONDARY} />
              </div>
          </div>
      )
    }

    if (filterTypeState === AbstractFilterType.FILTER_ALL) {
        return (
            <div>
                <div className="FilterMain main" onClick={() => { toggleDropDown(); }}>
                    <div className="FilterMain-text">
                        {title ?? t('FilterMain_filter_items')}
                    </div>
                    <div className="FilterMain-icon">
                        <Image source={customImage ?? filter} description={t('FilterMain_show_dropdown')}/>
                    </div>
                </div>
                {openedState && getMobileElement()}
            </div>
        )
    }

    return (
        <div>
            <div className="FilterMain-search search">
                <div className="FilterMain-search-input">
                    <form>
                        <InputText
                            inputState={null}
                            withoutValidations={true}
                            hasClear={true}
                            image={{source: search, title: t('Base_search')}}
                            placeholder={t('Base_search')}
                            inputName="filterSearch"
                            inputType={InputTextType.TEXT}
                            value={filterSearchState?.value}
                            dispatchOnChange={updateInput} />
                    </form>
                </div>
            </div>
            {openedState && getMobileElement()}
        </div>
    )
}

export default FilterMain;
