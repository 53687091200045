import React, {useEffect} from "react";
import "./ListUsers.scss"
import {TableRows} from "../../atoms/Table/Table";
import remove from "../../../images/delete.svg"
import edit from "../../../images/edit.svg"
import notifyIcon from "../../../images/notify.svg"
import {FunctionTable, OrderOptions} from "../../molecules/FunctionTable/FunctionTable";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {TableCellData, TableCellDataAction, TableCellDataActionTypeEnum} from "../../atoms/Table/TableColumn";
import {DotType} from "../../atoms/Dot/Dot";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {EasyFilter} from "../../molecules/EasyFilter/EasyFilter";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionDeleteUser, actionGetUserList} from "../../../store/actions/data/userAction";
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {ListUserFilterTypes} from "../../../store/slices/pages/userPageSlice";
import userIcon from "../../../images/user.svg"
import {UserUtil} from "../../../models/utils/UserUtil";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {Button, ButtonType} from "../../atoms/Button/Button";
import peopleIcon from "../../../images/person.svg"
import {Storage} from "../../../models/utils/Storage";
import {UserDto} from "../../../models/entities/UserDto";
import {useTranslate} from "@tolgee/react";
import {TableDetailLinkType} from "../../atoms/Table/TableDetail";

/**
 * @alias ListUsersPagination
 * @category Components
 */
type ListUsersPagination = {

    /** Currently shown count **/
    showCount: number,
    /** Currently shown page **/
    page: number,
    /** Current order **/
    order: OrderOptions|null,
    /** Redux action to change order **/
    dispatchChangeOrder: ActionCreatorWithPayload<OrderOptions>
    /** Redux action to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

/**
 * @alias ListUsersFilter
 * @category Components
 */
type ListUsersFilter = {

    /** Current filter type **/
    filterType: ListUserFilterTypes,
    /** Filter by user role **/
    role: RoleEnum|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Redux action to filter all items **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux action to filter active **/
    dispatchFilterActive: ActionCreatorWithoutPayload,
    /** Redux action to filter by role **/
    dispatchFilterRole: ActionCreatorWithPayload<{role: RoleEnum, title: string}>,
}

type ListUsersProps = {
    pagination: ListUsersPagination,
    filter: ListUsersFilter
    dispatchShowAddOrEditUserModal: ActionCreatorWithPayload<{ userId: number|null }>,
    dispatchShowUserNotifyOptionsModal: ActionCreatorWithPayload<{ userId: number|null }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ListUsersFilter} filter - filter options
 * @param {ListUsersPagination} pagination - pagination options
 * @param {ActionCreatorWithPayload<{ userId: number|null }>} dispatchShowAddOrEditUserModal - dispatch to show edit user modal
 * @param {ActionCreatorWithPayload<{ userId: number|null }>} dispatchShowUserNotifyOptionsModal - dispatch to show user notify options modal
 */
export const ListUsers = ({filter, pagination, dispatchShowAddOrEditUserModal, dispatchShowUserNotifyOptionsModal} : ListUsersProps): React.JSX.Element => {

    // Redux
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const userList = useAppSelector((state) => state.userData.userList);
    const storageData = Storage.getSession();

    // Re-load data
    useEffect(() => { if (!userList) { dispatch(actionGetUserList()); } }, [dispatch, userList])

    // Filter hardware list using filter states
    const filterUserList = (userList: Array<UserResponseDto>) : Array<UserResponseDto> => {

        return userList.filter((item: UserResponseDto) => {

            const fullName = `${item.User.firstName} ${item.User.lastName}`;
            if (item.User.role === RoleEnum.HARDWARE) { return false; }
            if (filter.filterType === ListUserFilterTypes.ALL) { return true; }
            else if (filter.filterType === ListUserFilterTypes.ACTIVE) { return item.User.active; }
            else if (filter.filterType === ListUserFilterTypes.ROLE && filter.role !== null) { return filter.role === item.User.role; }
            else if (filter.filterType === ListUserFilterTypes.FULLTEXT && filter.fullTextValue !== null) {
                return fullName.toLowerCase().includes(filter.fullTextValue)
                    || item.User.email.toLowerCase().includes(filter.fullTextValue);
            }
            return false;
        });
    }

    // Create table format for hardware
    const parseUserList = (userList: Array<UserResponseDto>, currentUser: UserDto) : TableRows => {

        return userList.map((user : UserResponseDto) => {

            let actions : Array<TableCellDataAction> = [];

            // Notify options
            actions.push({
                type: TableCellDataActionTypeEnum.PRIMARY,
                title: t('ListUsers_notifyOptions_title'),
                linkType: TableDetailLinkType.PRIMARY,
                image: notifyIcon,
                trigger: async () => { dispatch(dispatchShowUserNotifyOptionsModal({userId: user.User.id})); }
            });

            // Edit
            if (currentUser.id !== user.User.id) {
                actions.push({
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    title: t('Base_goto_editDetails'),
                    linkType: TableDetailLinkType.PRIMARY,
                    image: edit,
                    trigger: async () => {
                        dispatch(dispatchShowAddOrEditUserModal({userId: user.User.id}));
                    }
                });
            }

            // Delete
            if (user.User.active && currentUser.id !== user.User.id) {
                actions.push({
                    confirm: {
                        title: t('ListUsers_delete_question'),
                        successTitle: t('ListUsers_delete_success',
                            {userName: `${user.User.firstName} ${user.User.lastName}`})},
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    linkType: TableDetailLinkType.DELETE,
                    title: t('ListUsers_delete_title'),
                    image: remove,
                    trigger: async () => {
                        const result = await dispatch(actionDeleteUser({userId: user.User.id, t:t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                           throw new Error(result.payload);
                        }
                    }
                });
            }

            // Init first columns
            const columns : Array<TableCellData> = [
                {dot: {type: user.User.active ? DotType.SUCCESS : DotType.ERROR}},
                {image: {source: userIcon, text: t('ListUser_profile_picture')}, text: `${user.User.firstName} ${user.User.lastName}`},
                {text: user.User.phone},
                {text: user.User.email},
                {role: user.User.role},
                {text: user.User.branch},
            ];

            // Finish adding columns
            columns.push({actions: actions});

            // Construct object
            const roleMetas = UserUtil.getRoleFEMetaFromRole(user.User.role, t);
            return {
                link: {link: "/user/" + user.User.id, title: t('ListUsers_gotoUserDetail')},
                rowTitle: `${user.User.firstName} ${user.User.lastName}`,
                rowSubtitle: roleMetas.text,
                columns: columns,
            }
        });
    }

    // Loading state
    if (typeof userList === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state hardware list
    if (userList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_user_list')})} />
        )
    }

    // Error state session data
    if (storageData === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    // Prepare table output data
    const parsedUserList = parseUserList(filterUserList(userList), storageData.user);

    return (
        <div className={'ListUsers'}>
            <div className="ListUsers-functions">
                <div className="ListUsers-functions-left">
                    <EasyFilter items={[
                        {
                            title: t('ListUser_filter_active'),
                            active: filter.filterType === ListUserFilterTypes.ACTIVE,
                            dispatchFilterChange: filter.dispatchFilterActive
                        },
                        {
                            title: t('ListUser_filter_all'),
                            active: filter.filterType === ListUserFilterTypes.ALL,
                            dispatchFilterChange: filter.dispatchFilterAll
                        },
                        {
                            title: UserUtil.getRoleFEMetaFromRole(RoleEnum.ADMINISTRATOR, t).text,
                            color: UserUtil.getRoleFEMetaFromRole(RoleEnum.ADMINISTRATOR, t).filterColor,
                            active: filter.filterType === ListUserFilterTypes.ROLE && filter.role === RoleEnum.ADMINISTRATOR,
                            callBackFilterChange: () => {
                                dispatch(filter.dispatchFilterRole({
                                    role: RoleEnum.ADMINISTRATOR,
                                    title: UserUtil.getRoleFEMetaFromRole(RoleEnum.ADMINISTRATOR, t).text}));
                            }
                        },
                        {
                            title: UserUtil.getRoleFEMetaFromRole(RoleEnum.TECHNICAL, t).text,
                            color: UserUtil.getRoleFEMetaFromRole(RoleEnum.TECHNICAL, t).filterColor,
                            active: filter.filterType === ListUserFilterTypes.ROLE && filter.role === RoleEnum.TECHNICAL,
                            callBackFilterChange: () => {
                                dispatch(filter.dispatchFilterRole({
                                    role: RoleEnum.TECHNICAL,
                                    title: UserUtil.getRoleFEMetaFromRole(RoleEnum.TECHNICAL, t).text}));
                            }
                        },
                        {
                            title: UserUtil.getRoleFEMetaFromRole(RoleEnum.MEDICAL, t).text,
                            color: UserUtil.getRoleFEMetaFromRole(RoleEnum.MEDICAL, t).filterColor,
                            active: filter.filterType === ListUserFilterTypes.ROLE && filter.role === RoleEnum.MEDICAL,
                            callBackFilterChange: () => {
                                dispatch(filter.dispatchFilterRole({
                                    role: RoleEnum.MEDICAL,
                                    title: UserUtil.getRoleFEMetaFromRole(RoleEnum.MEDICAL, t).text}));
                            }
                        },
                    ]} />
                </div>
                <div className="ListUsers-functions-right">
                    <div className="ListUsers-functions-right-item">
                        <div className="ListUsers-functions-right-item-button">
                            <Button
                                image={peopleIcon}
                                onClick={() => { dispatch(dispatchShowAddOrEditUserModal({userId: null})); }}
                                text={t('ListUser_button_create')}
                                type={ButtonType.CREATE}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ListUsers-table">
                <FunctionTable
                    empty={{
                        title: t('ListUsers_empty_title'),
                        subTitle: t('ListUsers_empty_subtitle', {dataName: t('Base_name_user')})
                    }}
                    fePagination={{
                        size: pagination.showCount,
                        page: pagination.page,
                        dispatchChangePage: pagination.dispatchChangePage,
                        dispatchChangeOrder: pagination.dispatchChangeOrder,
                        order: pagination.order,
                    }}
                    data={{rows: parsedUserList, columns: [
                        {name: t('ListUser_col_state'), state: true},
                        {name: t('ListUser_col_name')},
                        {name: t('ListUser_col_phone')},
                        {name: t('ListUser_col_email')},
                        {name: t('ListUser_col_role')},
                        {name: t('ListUser_col_branch')},
                        {name: t('ListUser_col_actions'), action: true}
                    ]}} />
            </div>
        </div>
    );
}

export default ListUsers;
