import React, {FormEvent, useEffect} from "react";
import "./EditSensor.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {SensorEditRequestDto} from "../../../models/entities/Requests/SensorEditRequestDto";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import arrowUp from "../../../images/arrowUp.svg"
import arrowDown from "../../../images/arrowDown.svg"
import save from "../../../images/save.svg"
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {InputRange} from "../../atoms/InputRange/InputRange";
import {actionGetSensor, actionUpdateSensor} from "../../../store/actions/organisms/editSensorAction";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {actionGetPatient} from "../../../store/actions/data/patientAction";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {DataParser} from "../../../models/utils/DataParser";
import {actionGetSensorList} from "../../../store/actions/data/hardwareAction";
import {editSensorOrganismSlice} from "../../../store/slices/organisms/editSensorSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {T, useTranslate} from "@tolgee/react";
import {SensorTypeEnum} from "../../../models/entities/Enums/SensorTypeEnum";

interface EditSensorProps {
    sensorId : number,
    hardwareId : number,
    patientId ?: number,
    dispatchHideModal: ActionCreatorWithoutPayload,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {number} sensorId - sensor identification
 * @param {number} hardwareId - hardware identification
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - dispatch to hide modal
 * @param {number|undefined} patientId - patient id if called from patient detail
 * @param {RoleEnum} role - patient role
 */
export const EditSensor = ({sensorId, hardwareId, dispatchHideModal, patientId, role}: EditSensorProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const sensor = useAppSelector((state) => state.sensorEditOrganism.sensorData);
    const formValidState = useAppSelector((state) => selectValidState(state.sensorEditOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.sensorEditOrganism));
    const nameState = useAppSelector((state) => selectInputState(state.sensorEditOrganism, 'name'));
    const limitMinState = useAppSelector((state) => selectInputState(state.sensorEditOrganism, 'limitMin'));
    const limitMaxState = useAppSelector((state) => selectInputState(state.sensorEditOrganism, 'limitMax'));
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const intervalState = useAppSelector((state) => selectInputState(state.sensorEditOrganism, 'interval'));

    // Init && destroy data
    useEffect(() => {
        dispatch(actionGetSensor(sensorId));
        return () => {
            dispatch(editSensorOrganismSlice.actions.destroyOrganism());
            dispatch(editSensorOrganismSlice.actions.clearForm({t: t}));
        }
    }, [dispatch, sensorId, t])

    // Load sensor types
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Edit form send
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        const editRequest: SensorEditRequestDto = {
            limitMin: limitMinState && limitMinState.value !== null && typeof limitMinState.value !== 'undefined'
                ? parseFloat(limitMinState.value.toString()) : null,
            limitMax: limitMaxState && limitMaxState.value !== null && typeof limitMaxState.value !== 'undefined'
                ? parseFloat(limitMaxState.value.toString()) : null,
            dataReadInterval: intervalState && intervalState.value !== null && typeof intervalState.value !== 'undefined'
                ? parseInt(intervalState.value.toString()) : undefined,
            customName: nameState && nameState.value ? nameState.value.toString() : null
        }

        const result = await dispatch(actionUpdateSensor({sensorId: sensorId, sensorRequest: editRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(editSensorOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchHideModal());
        dispatch(actionGetSensorList(hardwareId));
        if (patientId) { dispatch(actionGetPatient(patientId)) }
    }

    // Loading state
    if (sensor === undefined || sensorTypes === undefined || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                modal={{dispatchHide: dispatchHideModal}}
                title={t('EditSensor_title')}/>
        )
    }

    // Error state sensor
    if (sensor === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideModal ? {
                    name: t('EditSensor_title'),
                    dispatchHide: dispatchHideModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideModal ? {
                    name: t('EditSensor_title'),
                    dispatchHide: dispatchHideModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    const sensorType = DataParser.getSensorTypeFromList(sensorTypes, sensor.SensorTemplate.type);
    const isSensorWithoutLimits = [SensorTypeEnum.SOS, SensorTypeEnum.FALL_DETECTION].includes(sensor.SensorTemplate.type);


    return (
        <div className="EditSensor">
            <div className="EditSensor-modal">
                <div className="EditSensor-modal-content">
                    <div className="EditSensor-modal-content-title">
                        <div className="EditSensor-modal-content-title-text">
                            <T keyName={'EditSensor_title'} />
                        </div>
                        <div className="EditSensor-modal-content-title-close">
                            <Image onClick={() => dispatch(dispatchHideModal()) }
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}} />
                        </div>
                    </div>
                    <div className="EditSensor-modal-content-form">
                        <form onSubmit={handleSubmit.bind(this)}>
                            <div className="EditSensor-modal-content-form-inputs">
                                <InputText
                                    label="Custom name"
                                    inputName="name"
                                    inputType={InputTextType.TEXT}
                                    startValue={sensor.Sensor.customName ? sensor.Sensor.customName : null}
                                    value={nameState?.value}
                                    placeholder="Custom name"
                                    inputState={nameState}
                                    dispatchOnChange={editSensorOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={editSensorOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={editSensorOrganismSlice.actions.removeFocus} />
                                {role === RoleEnum.SUPERUSER &&
                                    <InputText
                                        label={t('EditSensor_interval_label')}
                                        inputName="interval"
                                        inputState={intervalState}
                                        inputType={InputTextType.NUMBER}
                                        placeholder={t('EditSensor_interval_placeholder')}
                                        value={intervalState?.value}
                                        startValue={sensor.Sensor.dataReadInterval ?? sensor.SensorTemplate.dataReadInterval}
                                        dispatchOnFocus={editSensorOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={editSensorOrganismSlice.actions.removeFocus}
                                        dispatchOnChange={editSensorOrganismSlice.actions.updateInput}/>
                                }
                                {!isSensorWithoutLimits &&
                                    <InputRange
                                        label={{text: t('EditSensor_maximumValue'), image: {source: arrowUp, growing: true}}}
                                        inputName="limitMax"
                                        unit={sensorType ? sensorType.unit : null}
                                        value={limitMaxState?.value}
                                        startValue={sensor.Sensor.limitMax ?? sensor.SensorTemplate.limitMax}
                                        dispatchOnChange={editSensorOrganismSlice.actions.updateInput}
                                        max={sensor.SensorTemplate.rangeMax}
                                        step={sensor.SensorTemplate.rangeStep}
                                        min={sensor.SensorTemplate.rangeMin} />
                                }
                                {!isSensorWithoutLimits &&
                                    <InputRange
                                        label={{text: t('EditSensor_minimalValue'), image: {source: arrowDown, growing: false}}}
                                        inputName="limitMin"
                                        unit={sensorType ? sensorType.unit : null}
                                        value={limitMinState?.value}
                                        startValue={sensor.Sensor.limitMin ?? sensor.SensorTemplate.limitMin}
                                        dispatchOnChange={editSensorOrganismSlice.actions.updateInput}
                                        step={sensor.SensorTemplate.rangeStep}
                                        max={sensor.SensorTemplate.rangeMax}
                                        min={sensor.SensorTemplate.rangeMin} />
                                }
                                {formErrorState && <span className="formError">{formErrorState}</span>}
                            </div>
                            <div className="EditSensor-modal-content-form-inputs-button">
                                <Button
                                    text={t('EditSensor_button')}
                                    type={ButtonType.PRIMARY}
                                    image={save}
                                    disabled={!formValidState}
                                    onClickDisabled={() => { dispatch(editSensorOrganismSlice.actions.showInputValidStates()); }}
                                    isSubmit={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditSensor;
