import {createSlice} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface AccountPageState {

    /** Edit sensor modal is shown **/
    recoveryCodesModalShown: boolean,
}

const initialState: AccountPageState = {
    recoveryCodesModalShown: false,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const accountsPageSlice = createSlice({
    name: 'accountsPage',
    initialState,
    reducers: {
        destroyPage: (state) => {
            state.recoveryCodesModalShown = false;
        },
        showRecoveryCodesModal: (state) => {
            state.recoveryCodesModalShown = true;
        },
        hideRecoveryCodesModal: (state) => {
            state.recoveryCodesModalShown = false;
        },
    },
})

export default accountsPageSlice.reducer