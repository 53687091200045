import {SensorTypeDto} from "../entities/SensorTypeDto";
import {HivePatientResponseDto} from "../entities/Responses/HivePatientResponseDto";
import {HiveItem, HiveLineType} from "../../components/molecules/Hive/Hive";
import {DataParser} from "./DataParser";
import {SensorTypeEnum} from "../entities/Enums/SensorTypeEnum";
import {SensorUtil} from "./SensorUtil";
import {HardwareWithSensorsResponseDto} from "../entities/Responses/HardwareWithSensorsResponseDto";
import {EasyBoxItem} from "../../components/molecules/EasyBox/EasyBox";
import {AbstractBuildingUpdateActivePayload, InternalBuildingBuilding} from "./AbstractBuildingReducers";
import {SensorAlertStateEnum} from "../entities/Enums/SensorAlertStateEnum";
import {BuildingUtil} from "./BuildingUtil";
import {SensorResponseDto} from "../entities/Responses/SensorResponseDto";
import {HardwareDto} from "../entities/HardwareDto";
import {ImageType} from "../entities/Utils/Image";
import {PatientDto} from "../entities/PatientDto";
import {ZoneDto} from "../entities/ZoneDto";

/**
 * @interface
 * @category Utils
 */
export interface HiveMetasLabel {

    /** Alerting text **/
    text: string,
    /** Alerting sensor icon **/
    icon?: ImageType,
}

/**
 * @interface
 * @category Utils
 */
export interface HiveMetas {

    /** Hive meta link to redirect **/
    link: string|null,
    /** Hive meta title **/
    title: string,
    /** Alert label info **/
    label: HiveMetasLabel,
    /** Type of meta (warning, error...) */
    type: HiveMetasType,
    /** Information about every sensor **/
    sensors: Array<HiveMetasSensor>|null,
}

/**
 * @interface
 * @category Utils
 */
export interface HiveMetasSensor {

    /** Sensor icon **/
    icon: any,
    /** Some probably physical value **/
    textValue?: string|null,
    /** Last sensor update value **/
    updatedText?: string|null,
    /** Easy box sensor unit **/
    unit: string|null,
    /** Sensor name **/
    name: string|null,
    /** Sensor has alert or warning **/
    isAlertingOrWarning: boolean,
    /** Value is rising **/
    rising: boolean|null,
    /** Show trend arrow **/
    showTrend: boolean,
}

/**
 * @enum {string}
 * @category Utils
 */
export enum HiveMetasType {
    ERROR = "error",
    WARNING = "warning",
    SUCCESS = "success",
    SILVER = "silver"
}

/**
 * @interface
 * @category Utils
 */
export interface hivePatientsToBoxItemsParams {

    /** List of hive patients **/
    hivePatients: Array<HivePatientResponseDto>,
    /** List of sensor types **/
    sensorTypes: Array<SensorTypeDto>,
    /** List of zones **/
    zoneList: Array<ZoneDto>,
    /** Callback when clicked on item **/
    detailCallback: (patientId: number) => void,
    /** Currently active hardware **/
    activePatient: AbstractBuildingUpdateActivePayload|null,
}

/**
 * @interface
 * @category Utils
 */
export interface hiveHardwareListToBoxItemsParams {

    /** List of HIVE hardware **/
    hiveHardware: Array<HardwareWithSensorsResponseDto>,
    /** List of sensor types **/
    sensorTypes: Array<SensorTypeDto>,
    /** List of zones **/
    zoneList: Array<ZoneDto>,
    /** Building data **/
    building: InternalBuildingBuilding,
    /** Callback when clicked on item **/
    detailCallback: (hardwareId: number) => void,
    /** Currently active hardware **/
    activeHardware: AbstractBuildingUpdateActivePayload|null,
}

/**
 * @interface
 * @category Utils
 */
interface HiveMetasParameters {
    sensors: Array<SensorResponseDto>;
    sensorTypes: Array<SensorTypeDto>;
    zoneList: Array<ZoneDto>
    t: any;
    tempBeatMode: boolean;
    patient?: PatientDto;
    hw?: HardwareDto;
}

/**
 * @interface
 * @category Utils
 */
interface BuildingPlanLabelMetasParameters {
    sensorAlert: SensorResponseDto | undefined,
    sensorResolved: SensorResponseDto | undefined,
    sensorTypes: Array<SensorTypeDto>,
    t: any
}

/**
 * @interface
 * @category Utils
 */
interface BuildingPlanLabelMetasMetas {
    sensorDisplayed: SensorResponseDto,
    sensorDisplayedType: SensorTypeDto,
    labelText: string
}

/**
 * @class
 * @category Utils
 */
export class HiveUtil {


    /**
     * Get meta info for HIVE items
     * @param {HiveMetasParameters} params - parameters
     */
    public static getHiveMetas(params: HiveMetasParameters): HiveMetas {

        // Get showed sensors
        const sensorsMetas = HiveUtil.getSensorMetas(params.sensors, params.sensorTypes, params.zoneList, params.tempBeatMode, params.t);

        // Alarming sensors
        const sensorAlert = params.sensors.find((sensor: SensorResponseDto) =>
            sensor.Sensor.alertState === SensorAlertStateEnum.ALERTING);
        const sensorResolved = params.sensors.find((sensor: SensorResponseDto) =>
            sensor.Sensor.alertState === SensorAlertStateEnum.RESOLVED);

        // In building plan we need sensor for label
        let buildingPlanLabelMetas = this.getBuildingPlanLabelMetas({
            sensorAlert: sensorAlert,
            sensorResolved: sensorResolved,
            sensorTypes: params.sensorTypes,
            t: params.t
        });

        // If all sensors without value show as silver
        const allSensorsWithoutValue = process.env.REACT_APP_EMPTY_STATUS_FOR_CLIENTS === 'yes'
            && params.sensors.filter((sensor: SensorResponseDto) => {
            return sensor.Sensor.lastReadAt !== null;
        }).length === 0;

        return {
            label: buildingPlanLabelMetas ? {
                text: buildingPlanLabelMetas.labelText,
                icon: SensorUtil.getMetaForSensorType(buildingPlanLabelMetas.sensorDisplayed.SensorTemplate.type, params.t).icon
            } : {text: params.t('Base_normal_state')},
            link: params.patient
                ? `/patient/${params.patient.id}`
                : (params.hw ? `/devices/${params.hw.id}` : null),
            title: params.patient
                ? `${params.patient.firstName} ${params.patient.lastName}`
                : (params.hw ? params.hw.internalId ?? params.hw.uid : ''),
            type: allSensorsWithoutValue
                ? HiveMetasType.SILVER
                : sensorAlert ? HiveMetasType.ERROR : (sensorResolved ? HiveMetasType.WARNING : HiveMetasType.SUCCESS),
            sensors: sensorsMetas,
        }
    }

    /**
     * Get label text and sensor type for building plan view hive
     * @param sensorAlert - alerting sensor if any
     * @param sensorResolved - resolving sensor if any
     * @param sensorTypes - list of sensor types
     * @param t - translator
     */
    private static getBuildingPlanLabelMetas = ({sensorAlert, sensorResolved, sensorTypes, t}: BuildingPlanLabelMetasParameters)
        : null|BuildingPlanLabelMetasMetas => {

        const sensorDisplayed = sensorAlert ?? sensorResolved;
        if (!sensorDisplayed) { return null; }
        const sensorDisplayedType = DataParser.getSensorTypeFromList(sensorTypes, sensorDisplayed.SensorTemplate.type);
        if (!sensorDisplayedType) { return null; }

        const sensorDisplayedTypeMetas = SensorUtil.getMetaForSensorType(sensorDisplayedType.id, t);
        const sensorDisplayedRising = sensorDisplayed.Sensor.lastValue
            && sensorDisplayed.Sensor.limitMax
            && sensorDisplayed.Sensor.lastValue >= sensorDisplayed.Sensor.limitMax;

        let labelText = (sensorDisplayedRising ? t('Base_high') + ' ' : t('Base_low') + ' ') + sensorDisplayedTypeMetas.name;

        if (sensorDisplayed.SensorTemplate.type === SensorTypeEnum.BUTTON && labelText) { labelText = t('HiveUtil_button_pressed'); }
        if (sensorDisplayed.SensorTemplate.type === SensorTypeEnum.CONNECTION && labelText) { labelText = t('HiveUtil_connection_offline'); }
        if (sensorDisplayed.SensorTemplate.type === SensorTypeEnum.SOS && labelText) { labelText = t('HiveUtil_sos_pressed'); }
        if (sensorDisplayed && sensorDisplayed.SensorTemplate.type === SensorTypeEnum.FALL_DETECTION && labelText) { labelText = t('HiveUtil_fall_detected'); }
        return {sensorDisplayed, sensorDisplayedType, labelText};
    };

    /**
     * Convert sensors into sensor metas for box or hive elements
     * @param {SensorDto[]} sensors - list of sensors
     * @param {SensorTypeDto[]} types - list of sensor types
     * @param {boolean} tempBeatMethod - look for heartbeat and temperature sensors
     * @param {any} t - translator object
     * @param {ZoneDto[]} zoneList - list of zones
     * @function
     * @category Utils
     */
    private static getSensorMetas(sensors: Array<SensorResponseDto>, types: Array<SensorTypeDto>, zoneList: ZoneDto[], tempBeatMethod: boolean, t: any) : Array<HiveMetasSensor> {

        // First priority are sensors with alerts
        let firstSensor : SensorResponseDto|null;
        let secondSensor : SensorResponseDto|null;
        const alertedSensors = sensors.filter((sensor: SensorResponseDto) => sensor.Sensor.alertState === SensorAlertStateEnum.ALERTING);
        firstSensor = alertedSensors[0] ? alertedSensors[0] : null;
        secondSensor = alertedSensors[1] ? alertedSensors[1] : null;

        // Second priority sensors with progress
        const resolvedSensors = sensors.filter((sensor: SensorResponseDto) => sensor.Sensor.alertState === SensorAlertStateEnum.RESOLVED);
        firstSensor = firstSensor ?? (resolvedSensors[0] ? resolvedSensors[0] : null);
        secondSensor = secondSensor ?? (resolvedSensors[1] ? resolvedSensors[1] : null);

        // Third priority TEMPERATURE and HEARTBEAT as default sensors in this mode
        // Is first sensors is temperature prevent second one to be the same!
        if (tempBeatMethod) {
            firstSensor = firstSensor ?? DataParser.getSensorByType(sensors, SensorTypeEnum.TEMPERATURE);
            secondSensor = secondSensor ?? (firstSensor && firstSensor.SensorTemplate.type === SensorTypeEnum.HEARTBEAT
                ? DataParser.getSensorByType(sensors, SensorTypeEnum.TEMPERATURE)
                : DataParser.getSensorByType(sensors, SensorTypeEnum.HEARTBEAT));
        }

        // Assign any by index
        // If first sensor is any using alerting rule make sure the second one is not the same!
        firstSensor = firstSensor ?? (sensors[0] ? sensors[0] : null);
        secondSensor = secondSensor ?? (sensors.find((item: SensorResponseDto) => { return item.Sensor.id !== firstSensor?.Sensor.id; }) ?? null) ;

        // Get sensors type
        const firstSensorType = firstSensor ? DataParser.getSensorTypeFromList(types, firstSensor.SensorTemplate.type) : null;
        const secondSensorType = secondSensor ? DataParser.getSensorTypeFromList(types, secondSensor.SensorTemplate.type) : null;

        // Parse first one
        const outSensors : Array<HiveMetasSensor> = [];
        if (firstSensor && firstSensorType) {
            outSensors.push({
                showTrend: SensorUtil.showTrendOrMinMaxBySensorType(firstSensor.SensorTemplate.type),
                rising: firstSensor.Sensor.lastValue && firstSensor.Sensor.limitMax
                    ? (firstSensor.Sensor.lastValue >= firstSensor.Sensor.limitMax)
                    : null,
                isAlertingOrWarning: firstSensor.Sensor.alertState !== SensorAlertStateEnum.NONE,
                icon: SensorUtil.getMetaForSensorType(firstSensor.SensorTemplate.type, t).icon,
                textValue: SensorUtil.getValueInterpretationForSensorType(firstSensor, zoneList, t),
                unit: firstSensorType.unit,
                updatedText: SensorUtil.getTimeFormatAgoForDateWithFallBackText(firstSensor.Sensor.lastReadAtDiff, t, true),
                name: SensorUtil.getMetaForSensorType(firstSensor.SensorTemplate.type, t).name,
            });
        }

        // Parse second one
        if (secondSensor && secondSensorType) {
            outSensors.push({
                showTrend: SensorUtil.showTrendOrMinMaxBySensorType(secondSensor.SensorTemplate.type),
                rising: secondSensor.Sensor.lastValue && secondSensor.Sensor.limitMax
                    ? (secondSensor.Sensor.lastValue >= secondSensor.Sensor.limitMax)
                    : null,
                isAlertingOrWarning: secondSensor.Sensor.alertState !== SensorAlertStateEnum.NONE,
                icon: SensorUtil.getMetaForSensorType(secondSensor.SensorTemplate.type, t).icon,
                textValue: SensorUtil.getValueInterpretationForSensorType(secondSensor, zoneList, t),
                updatedText: SensorUtil.getTimeFormatAgoForDateWithFallBackText(secondSensor.Sensor.lastReadAtDiff, t, true),
                unit: secondSensorType.unit,
                name: SensorUtil.getMetaForSensorType(secondSensor.SensorTemplate.type, t).name,
            });
        }

        return outSensors;
    }

    /**
     * Convert hive hardware list into easy box list
     * @todo Locating handling
     * @param {hiveHardwareListToBoxItemsParams} params - function params (see type info)
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static hiveHardwareListToBoxItems(params: hiveHardwareListToBoxItemsParams, t: any): Array<EasyBoxItem> {

        return params.hiveHardware.map((hardware: HardwareWithSensorsResponseDto) => {

            const locationFloor = BuildingUtil.getFloorFromHardwareId(params.building, hardware.Hardware.id);
            const hiveMetas = this.getHiveMetas({
                sensors : hardware.Sensors,
                sensorTypes : params.sensorTypes, t : t,
                tempBeatMode : false,
                patient : undefined,
                hw : hardware.Hardware,
                zoneList: params.zoneList
            });

            const item : EasyBoxItem = {
                searchTags: [hardware.Hardware.uid, hardware.Hardware.internalId, hardware.HardwareTemplate.model, hardware.HardwareTemplate.name],
                label: hiveMetas.label,
                type: hiveMetas.type,
                sensors: hiveMetas.sensors,
                id: hardware.Hardware.id,
                link: hiveMetas.link,
                active: hardware.Hardware.id === params.activeHardware?.id,
                title: hiveMetas.title,
                actionCallBack: locationFloor ? () => { params.detailCallback(hardware.Hardware.id); } : null,
            }

            return item;
        });
    }

    /**
     * Convert hive patients list into list of easy box items
     * @todo Locating handling
     * @param {hivePatientsToBoxItemsParams} params - function params (see type info)
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static hivePatientsToBoxItems(params: hivePatientsToBoxItemsParams, t: any): Array<EasyBoxItem> {

        return params.hivePatients.map((hivePatient: HivePatientResponseDto) => {

            const hiveMetas = this.getHiveMetas({
                sensors : hivePatient.Sensors,
                sensorTypes : params.sensorTypes,
                t : t,
                tempBeatMode : true,
                patient : hivePatient.Patient,
                zoneList: params.zoneList
            });

            return {
                searchTags: [hivePatient.Patient.phone, hivePatient.Patient.room, hivePatient.Patient.residence, hivePatient.Patient.placeOfBirth],
                locatingState: hivePatient.Patient.locatingState,
                label: hiveMetas.label,
                type: hiveMetas.type,
                sensors: hiveMetas.sensors,
                active: hivePatient.Patient.id === params.activePatient?.id,
                link: hiveMetas.link,
                title: hiveMetas.title,
                id: hivePatient.Sensors.length > 0 ? hivePatient.Sensors[0].Sensor.hardware : null,
                actionCallBack: hivePatient.Patient.calcLocBuildingFloorElevation ? () => {
                    params.detailCallback(hivePatient.Patient.id);
                } : null,
            }
        });
    }

    /**
     * Convert hardware list into list of hive items
     * @param {HardwareWithSensorsResponseDto[]} hwList - hardware list
     * @param {SensorTypeDto[]} sensorTypes - sensor types
     * @param {ZoneDto[] }zoneList - list of zones
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static hiveHardwareListToHiveItems(hwList: HardwareWithSensorsResponseDto[], sensorTypes: SensorTypeDto[], zoneList: ZoneDto[], t: any): HiveItem[] {

        return hwList.map((hardware: HardwareWithSensorsResponseDto) => {

            const hiveMetas = this.getHiveMetas({
                sensors : hardware.Sensors,
                sensorTypes : sensorTypes,
                t : t,
                tempBeatMode : false,
                patient : undefined,
                hw : hardware.Hardware,
                zoneList: zoneList
            });

            const firstSensor = hiveMetas.sensors && hiveMetas.sensors[0] ? hiveMetas.sensors[0] : null;
            const secondSensor = hiveMetas.sensors && hiveMetas.sensors[1] ? hiveMetas.sensors[1] : null;

            const hiveItem : HiveItem =  {
                link: hiveMetas.link,
                type: hiveMetas.type,
                first: {
                    showTrend: firstSensor ? firstSensor.showTrend : false,
                    lastUpdatedText: firstSensor ? firstSensor.updatedText : null,
                    smallerText: false,
                    lineType: firstSensor && firstSensor.isAlertingOrWarning ? HiveLineType.ACCENT : HiveLineType.NORMAL,
                    rising: firstSensor ? firstSensor.rising : null,
                    value: firstSensor ? firstSensor.textValue : null,
                    unit:  firstSensor ? firstSensor.unit : null,
                    icon: firstSensor ? firstSensor.icon : null,
                },
                name: {
                    sub: hardware.Hardware.internalId ? hardware.Hardware.internalId : null,
                    accent: hardware.Hardware.uid
                }
            }

            if (secondSensor) {
                hiveItem.second = {
                    showTrend: secondSensor ? secondSensor.showTrend : false,
                    lastUpdatedText: secondSensor ? secondSensor.updatedText : null,
                    smallerText: false,
                    lineType:  secondSensor.isAlertingOrWarning ? HiveLineType.ACCENT : HiveLineType.NORMAL,
                    rising: secondSensor.rising,
                    value: secondSensor.textValue,
                    unit: secondSensor.unit,
                    icon: secondSensor.icon,
                };
            }

            return hiveItem;
        })
    }

    /**
     * Convert hive patients into list of hive items
     * @param {HivePatientResponseDto[]} hivePatients - list of patients
     * @param {SensorTypeDto[]} sensorTypes - sensor types
     * @param {ZoneDto[]} zoneList - list of zones
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static hivePatientsToHiveItems(hivePatients: HivePatientResponseDto[], sensorTypes: SensorTypeDto[], zoneList: ZoneDto[], t: any): HiveItem[] {

        return hivePatients.map((patient: HivePatientResponseDto) => {

            const hiveMetas = this.getHiveMetas({
                sensors : patient.Sensors,
                sensorTypes : sensorTypes,
                t : t,
                tempBeatMode : true,
                patient : patient.Patient,
                zoneList: zoneList
            });

            const firstSensor = hiveMetas.sensors && hiveMetas.sensors[0] ? hiveMetas.sensors[0] : null;
            const secondSensor = hiveMetas.sensors && hiveMetas.sensors[1] ? hiveMetas.sensors[1] : null;

            const hiveItem : HiveItem = {
                link: hiveMetas.link,
                type: hiveMetas.type,
                first: {
                    showTrend: firstSensor ? firstSensor.showTrend : false,
                    smallerText: false,
                    lastUpdatedText: firstSensor ? firstSensor.updatedText : null,
                    lineType: firstSensor && firstSensor.isAlertingOrWarning ? HiveLineType.ACCENT : HiveLineType.NORMAL,
                    rising: firstSensor ? firstSensor.rising : null,
                    value: firstSensor ? firstSensor.textValue : null,
                    unit: firstSensor ? firstSensor.unit : null,
                    icon: firstSensor ? firstSensor.icon : null,
                },
                name: {
                    sub: patient.Patient.firstName,
                    accent: patient.Patient.lastName
                }
            }

            if (secondSensor) {
                hiveItem.second = {
                    showTrend: secondSensor ? secondSensor.showTrend : false,
                    smallerText: false,
                    lastUpdatedText: secondSensor ? secondSensor.updatedText : null,
                    lineType: secondSensor.isAlertingOrWarning ? HiveLineType.ACCENT : HiveLineType.NORMAL,
                    rising: secondSensor.rising,
                    value: secondSensor.textValue,
                    unit: secondSensor.unit,
                    icon: secondSensor.icon,
                }
            }

            return hiveItem;
        });
    }

    public static getPriorityFromHiveType(type: HiveMetasType|undefined) : number {

        if (!type) { return 0; }
        switch (type) {
            case HiveMetasType.ERROR: return 4;
            case HiveMetasType.SILVER: return 1;
            case HiveMetasType.SUCCESS: return 2;
            case HiveMetasType.WARNING: return 3;
        }
    }
}
