import React from "react";
import "./BuildingConstructorTemplate.scss"
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {BuildingPlan} from "../../organisms/BuildingPlan/BuildingPlan";
import {useAppSelector} from "../../../store/hooks";
import {BuildingPlanUpload} from "../../organisms/BuildingPlanUpload/BuildingPlanUpload";
import {buildingConstructorPageSlice} from "../../../store/slices/pages/buildingConstructorSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type BuildingConstructorTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const BuildingConstructorTemplate = ({role} : BuildingConstructorTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const uploadModalState = useAppSelector((state) => state.buildingConstructorPage.uploadModal);
    const uploadModalFloorState = useAppSelector((state) => state.buildingConstructorPage.uploadModalFloor);
    const uploadModalCallbackUpdateState = useAppSelector((state) => state.buildingConstructorPage.uploadModalCallBackUpdate);

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    <title>{t('BuildingConstructorTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('BuildingConstructorTemplate_title')}
                        breadItems={[
                            {name: t('Navigation_building_editor'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <BuildingPlan
                                dispatchShowFileUploadModal={buildingConstructorPageSlice.actions.showBuildingPlanUploadModal} />
                        </div>
                    </div>
                </div>

            </div>
            {uploadModalState && uploadModalFloorState && uploadModalCallbackUpdateState &&
                <BuildingPlanUpload
                    floor={uploadModalFloorState}
                    callbackUpdateFileInput={uploadModalCallbackUpdateState}
                    dispatchHideModal={buildingConstructorPageSlice.actions.hideBuildingPlanUploadModal} />
            }
        </main>
    );
}

export default BuildingConstructorTemplate;
