import React, {useEffect} from "react";
import "./ListPatient.scss"
import {TableRows} from "../../atoms/Table/Table";
import {FunctionTable, OrderOptions} from "../../molecules/FunctionTable/FunctionTable";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionActivatePatient, actionDeletePatient, actionGetPatientList} from "../../../store/actions/data/patientAction";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {ListPatientFilterTypes} from "../../../store/slices/pages/patientPageSlice";
import edit from "../../../images/edit.svg";
import {TableCellDataAction, TableCellDataActionTypeEnum} from "../../atoms/Table/TableColumn";
import {EasyFilter} from "../../molecules/EasyFilter/EasyFilter";
import {Button, ButtonType} from "../../atoms/Button/Button";
import patient from "../../../images/patient.svg";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {DotType} from "../../atoms/Dot/Dot";
import remove from "../../../images/delete.svg";
import activate from "../../../images/activatePatient.svg";
import {PatientSimpleResponseDto} from "../../../models/entities/Responses/PatientSimpleResponseDto";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {DataParser} from "../../../models/utils/DataParser";
import {FilterPatient} from "../FilterPatient/FilterPatient";
import {HardwareSimpleResponseDto} from "../../../models/entities/Responses/HardwareSimpleResponseDto";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {PatientWithHardwareListResponseDto} from "../../../models/entities/Responses/PatientWithHardwareListResponseDto";
import {useTranslate} from "@tolgee/react";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {TableDetailLinkType} from "../../atoms/Table/TableDetail";

/**
 * @alias ListPatientPagination
 * @category Components
 */
type ListPatientPagination = {

    /** Currently shown count **/
    showCount: number,
    /** Current page **/
    page: number,
    /** Current order **/
    order: OrderOptions|null,
    /** Redux action to change order **/
    dispatchChangeOrder: ActionCreatorWithPayload<OrderOptions>
    /** Redux action to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

/**
 * @alias ListPatientFilter
 * @category Components
 */
type ListPatientFilter = {

    /** Current filter type **/
    filterType: ListPatientFilterTypes,
    /** Full text filter value **/
    fullTextValue: string|null,
    /** Redux action to filter all **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux action to filter active **/
    dispatchFilterActive: ActionCreatorWithoutPayload,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by hardware **/
    hardwareId: number|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by elevation number **/
    elevation: number|null,
    /** Redux action to filter by hardware **/
    dispatchFilterHardware: ActionCreatorWithPayload<{hardwareId: number, title: string}>,
    /** Redux action to filter by template **/
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    /** Redux action to filter by sensor type **/
    dispatchFilterSensor: ActionCreatorWithPayload<{sensorType: number, title: string}>,
    /** Redux action to filter by elevation number **/
    dispatchFilterElevation: ActionCreatorWithPayload<{elevationId: number, title: string}>,
}

type ListPatientProps = {
    pagination: ListPatientPagination,
    filter: ListPatientFilter
    dispatchShowAddOrEditPatientModal: ActionCreatorWithPayload<{ patientId: number|null }>,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ListPatientPagination} pagination - pagination options
 * @param {ListPatientFilter} filter - filter options
 * @param {ActionCreatorWithPayload<{ patientId: number|null }>} - dispatchShowAddOrEditPatientModal - show modal Redux callback
 * @param {RoleEnum} role - user session role
 */
export const ListPatient = ({pagination, filter, dispatchShowAddOrEditPatientModal, role} : ListPatientProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const patientList = useAppSelector((state) => state.patientData.patientList);

    // Get fresh data
    useEffect(() => { if (!patientList) { dispatch(actionGetPatientList()); } }, [dispatch, patientList])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Filter patient list using filter states
    const filterPatientList = (patientList: PatientWithHardwareListResponseDto) : Array<PatientSimpleResponseDto> => {

        return patientList.Patients.filter((item: PatientSimpleResponseDto) => {

            const patientName = `${item.Patient.firstName} ${item.Patient.lastName}`;
            if (filter.filterType === ListPatientFilterTypes.ALL) { return true; }
            else if (filter.filterType === ListPatientFilterTypes.ACTIVE) { return item.Patient.active; }
            else if (filter.filterType === ListPatientFilterTypes.FULLTEXT && filter.fullTextValue !== null) {
                return patientName.toLowerCase().includes(filter.fullTextValue)
                    || (item.Patient.placeOfBirth !== null && item.Patient.placeOfBirth.toLowerCase().includes(filter.fullTextValue.toLowerCase()))
                    || (item.Patient.dateOfBirth !== null && item.Patient.dateOfBirth.toLowerCase().includes(filter.fullTextValue.toLowerCase()));
            }
            else if (filter.filterType === ListPatientFilterTypes.TEMPLATE && filter.templateId !== null) {
                return item.Hardware.findIndex((hardware: HardwareSimpleResponseDto) => {
                    return hardware.hardwareTemplate === filter.templateId;
                }) !== -1;
            }
            else if (filter.filterType === ListPatientFilterTypes.ELEVATION && filter.elevation !== null) {
                return item.Patient.calcLocBuildingFloorElevation !== null
                    && item.Patient.calcLocBuildingFloorElevation === filter.elevation;
            }
            else if (filter.filterType === ListPatientFilterTypes.HARDWARE && filter.hardwareId !== null) {
                return item.Hardware.findIndex((hardware: HardwareSimpleResponseDto) => {
                    return hardware.id === filter.hardwareId;
                }) !== -1;
            }
            else if (filter.filterType === ListPatientFilterTypes.SENSOR && filter.sensorType !== null) {
                return item.Hardware.findIndex((hardware: HardwareSimpleResponseDto) => {
                    return hardware.SensorTypes.findIndex((sensor: number) => { return sensor === filter.sensorType; }) !== -1;
                }) !== -1;
            }
            return false;
        });
    }


    // Get table data from patient data
    const parsePatientsList = (patientList: Array<PatientSimpleResponseDto>, sensorTypes: Array<SensorTypeDto>) : TableRows => {

        return patientList.map((patient : PatientSimpleResponseDto) => {

            // Get images
            let sensors = DataParser.getSensorTypesFromHardwareList(patient.Hardware);
            sensors = sensors.filter(DataParser.onlyUniqueFilterFunction);

            // Edit
            let actions : Array<TableCellDataAction> = [];
            if (dispatchShowAddOrEditPatientModal) {
                actions.push({
                    title: t('Base_goto_editDetails'),
                    image: edit,
                    linkType: TableDetailLinkType.PRIMARY,
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    trigger: async () => { dispatch(dispatchShowAddOrEditPatientModal({patientId: patient.Patient.id})); }
                });
            }

            // Delete
            if (patient.Patient.active) {
                actions.push({
                    confirm: {
                        title: t('ListPatient_delete_question'),
                        successTitle: t('ListPatient_delete_success',
                            {patientName: `${patient.Patient.firstName} ${patient.Patient.lastName}`})},
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    linkType: TableDetailLinkType.DELETE,
                    title: t('ListPatient_delete_title'), image: remove,
                    trigger: async () => {
                        const result = await dispatch(actionDeletePatient({patientId: patient.Patient.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            } else {
                actions.push({
                    confirm: {
                        title: t('ListPatient_activate_question'),
                        successTitle: t('ListPatient_activate_success',
                            {patientName: `${patient.Patient.firstName} ${patient.Patient.lastName}`})},
                    type: TableCellDataActionTypeEnum.SECONDARY,
                    linkType: TableDetailLinkType.PRIMARY,
                    isReActivateIcon: true,
                    title: t('ListPatient_activate_title'),
                    image: activate,
                    trigger: async () => {
                        const result = await dispatch(actionActivatePatient({patientId: patient.Patient.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            }

            const birthDateString = ValueUtil.getStringFromValue(t, patient.Patient.dateOfBirth, undefined, true);
            const subtitle = t('ListPatient_subtitle', {dateString: birthDateString, place: patient.Patient.placeOfBirth});

            return {
                link: {link: "/patient/" + patient.Patient.id, title: t('ListPatient_gotoPatientDetail')},
                rowTitle: `${patient.Patient.firstName} ${patient.Patient.lastName}`,
                rowSubtitle: subtitle,
                columns: [
                    {dot: {
                        type: patient.Patient.active ? DotType.SUCCESS : DotType.ERROR,
                        stateName: t(DotType.SUCCESS ? 'Base_state_active' : 'Base_state_inactive')
                    }},
                    {text: patient.Patient.firstName},
                    {text: patient.Patient.lastName},
                    {text: patient.Patient.room},
                    {date: patient.Patient.dateOfBirth},
                    {date: patient.Patient.placeOfBirth},
                    {sensors: SensorUtil.getImagesFromSensorTypesNumbers(sensors, sensorTypes, t)},
                    {actions: actions}
                ]
            }
        });
    }

    // Loading state
    if (typeof patientList === "undefined" || typeof sensorTypes === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state patients
    if (patientList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_patient_list')})} />
        )
    }

    // Error state sensor types
    if (sensorTypes === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_sensor_types')})} />
        )
    }

    // Init data
    const parsedList = parsePatientsList(filterPatientList(patientList), sensorTypes);

    return (
        <div className={'ListPatient'}>
            <div className="ListPatient-functions">
                <div className="ListPatient-functions-left">
                    <EasyFilter items={[
                        {
                            title: t('ListPatient_filter_active'),
                            active: filter.filterType === ListPatientFilterTypes.ACTIVE,
                            dispatchFilterChange: filter.dispatchFilterActive
                        },
                        {
                            title: t('ListPatient_filter_all'),
                            active: filter.filterType === ListPatientFilterTypes.ALL,
                            dispatchFilterChange: filter.dispatchFilterAll
                        },
                    ]} />
                </div>
                <div className="ListPatient-functions-right">
                    <div className="ListPatient-functions-right-item">
                        <div className="ListPatient-functions-right-item-button">
                            <Button
                                image={patient}
                                onClick={() => { dispatch(dispatchShowAddOrEditPatientModal({patientId: null})) }}
                                text={t('ListPatient_button_create')}
                                type={ButtonType.CREATE}/>
                        </div>
                    </div>
                    <div className="ListHardware-functions-right-item">
                        <FilterPatient
                            role={role}
                            sensorTypes={sensorTypes}
                            title={filter.filterTitle}
                            dispatchFilterElevation={filter.dispatchFilterElevation}
                            dispatchFilterHardware={filter.dispatchFilterHardware}
                            dispatchFilterSensor={filter.dispatchFilterSensor}
                            dispatchFilterTemplate={filter.dispatchFilterTemplate}/>
                    </div>
                </div>
            </div>
            <div className="ListPatient-table">
                <FunctionTable
                    fePagination={{
                        size: pagination.showCount,
                        page: pagination.page,
                        dispatchChangePage: pagination.dispatchChangePage,
                        dispatchChangeOrder: pagination.dispatchChangeOrder,
                        order: pagination.order,
                    }}
                    data={{rows: parsedList, columns: [
                        {name: t('ListPatient_col_state'), state: true},
                        {name: t('ListPatient_col_name')},
                        {name: t('ListPatient_col_surname')},
                        {name: t('ListPatient_col_room')},
                        {name: t('ListPatient_col_birth')},
                        {name: t('ListPatient_col_birthplace')},
                        {name: t('ListPatient_col_sensors'), forceDisableOrder: true},
                        {name: t('ListPatient_col_actions'), action: true}
                    ]}}/>
            </div>
        </div>
    );
}

export default ListPatient;
