import React, {useEffect} from "react";
import "./ViewHive.scss"
import {Hive, HiveItem} from "../../molecules/Hive/Hive";
import {EasyFilter} from "../../molecules/EasyFilter/EasyFilter";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {HivePatientResponseDto} from "../../../models/entities/Responses/HivePatientResponseDto";
import {DashboardFilterTypes} from "../../../store/slices/pages/dashboardPageSlice";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {FilterHive} from "../FilterHive/FilterHive";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {actionGetSensorTypes} from "../../../store/actions/data/sensorAction";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {HiveUtil} from "../../../models/utils/HiveUtil";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionGetHiveCached, actionGetHiveFresh} from "../../../store/actions/data/hiveAction";
import {useTranslate} from "@tolgee/react";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import compare_func from "compare-func";
import {actionGetZoneList} from "../../../store/actions/data/zoneAction";
import {ZoneDto} from "../../../models/entities/ZoneDto";

/**
 * @alias ViewHiveFilter
 * @category Components
 */
type ViewHiveFilter = {

    /** Current dashboard filter type **/
    type: DashboardFilterTypes,
    /** Filter by patient **/
    patient: number | null,
    /** Filter by sensor **/
    sensor: number | null,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by wearable **/
    wearable: boolean|null,
    /** Filter by elevation **/
    elevation: number|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Redux action to filter all **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux action to filter assigned **/
    dispatchFilterAssigned: ActionCreatorWithoutPayload,
    /** Redux action to filter template **/
    dispatchFilterTemplate: ActionCreatorWithPayload<{templateId: number, title: string}>,
    /** Redux action to filter wearable **/
    dispatchFilterWearable: ActionCreatorWithPayload<{wearable: boolean, title: string}>,
    /** Redux action to filter free **/
    dispatchFilterFree: ActionCreatorWithoutPayload,
    /** Redux action to filter by sensor type **/
    dispatchFilterSensor: ActionCreatorWithPayload<{ sensorType: number, title: string }>,
    /** Redux action to filter by patient **/
    dispatchFilterPatient: ActionCreatorWithPayload<{ patientId: number, title: string }>,
    /** Redux action to filter by elevation **/
    dispatchFilterElevation: ActionCreatorWithPayload<{ elevationId: number, title: string }>,
}

type ViewHiveProps = {
    role: RoleEnum,
    filter: ViewHiveFilter,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @todo what if there will be more than 2 sensors
 * @todo trim UID hardware name
 * @param {ViewHiveFilter} filter - filter options
 * @param {RoleEnum} role - session user role
 */
export const ViewHive = ({filter, role}: ViewHiveProps): React.JSX.Element => {

    // Init Redux
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const hiveState = useAppSelector((state) => state.hiveData.hive);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);

    // Re-load data periodically
    useEffect(() => {
        dispatch(actionGetHiveCached());
        const checkMs = process.env.REACT_APP_STATUS_REFRESH_MS ? parseInt(process.env.REACT_APP_STATUS_REFRESH_MS) : 10000;
        const interval = setInterval(() => { dispatch(actionGetHiveFresh()); }, checkMs);
        return () => { clearInterval(interval); }
    }, [dispatch])

    // Load sensor types and zones
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])
    useEffect(() => { if (!zoneList) { dispatch(actionGetZoneList()); } }, [dispatch, zoneList])

    // Parse HIVE patient sensors into HiveBlocks data
    const parseHiveAssigned = (hive: HiveResponseDto, sensorTypes: Array<SensorTypeDto>, zoneList: Array<ZoneDto>) : Array<HiveItem> => {

        if (filter.type === DashboardFilterTypes.FREE) { return []; }

        const hivePatients = hive.HivePatients.filter((hivePatient: HivePatientResponseDto) => {
            const patientName = `${hivePatient.Patient.firstName} ${hivePatient.Patient.lastName}`;
            if (filter.type === DashboardFilterTypes.PATIENT && filter.patient !== null) {
                return hivePatient.Patient.id === filter.patient;
            } else if (filter.type === DashboardFilterTypes.FULLTEXT && filter.fullTextValue) {
                return patientName.toLocaleLowerCase().includes(filter.fullTextValue);
            } else if (filter.type === DashboardFilterTypes.ELEVATION && filter.elevation) {
                return hivePatient.Patient.calcLocBuildingFloorElevation === filter.elevation;
            } else if (filter.type === DashboardFilterTypes.SENSOR && filter.sensor) {
                return typeof hivePatient.Sensors.find((sensor: SensorResponseDto) => { return sensor.SensorTemplate.type; }) !== 'undefined';
            }
            return true;
        })

        return HiveUtil.hivePatientsToHiveItems(hivePatients, sensorTypes, zoneList, t);
    }

    // Parse HIVE free sensors into HiveBlocks data
    const parseHiveFree = (hive: HiveResponseDto, sensorTypes: Array<SensorTypeDto>, zoneList: Array<ZoneDto>) : Array<HiveItem> => {

        if (filter.type === DashboardFilterTypes.ASSIGNED) { return []; }

        const hiveHardware = hive.UnassignedHardware.filter((hardware: HardwareWithSensorsResponseDto) => {

            if (hardware.HardwareTemplate.type === HardwareTypeEnum.WEARABLE && hardware.Patient === null) {
                return false;
            } else if (filter.type === DashboardFilterTypes.PATIENT && filter.patient !== null) {
                return false;
            } else if (role !== RoleEnum.TECHNICAL && filter.type === DashboardFilterTypes.ASSIGNED) {
                return false;
            } else if (filter.type === DashboardFilterTypes.ELEVATION && filter.elevation) {
                return (hardware.BuildingFloor !== null && hardware.BuildingFloor.elevation === filter.elevation);
            } else if (filter.type === DashboardFilterTypes.SENSOR && filter.sensor) {
                return hardware.Sensors.filter((sensor: SensorResponseDto) => { return sensor.Sensor.id === filter.sensor; }).length > 0
            } else if (filter.type === DashboardFilterTypes.TEMPLATE && filter.templateId) {
                return hardware.HardwareTemplate.id === filter.templateId;
            } else if (filter.type === DashboardFilterTypes.WEARABLE && filter.wearable !== null) {
                if (filter.wearable) { return hardware.HardwareTemplate.type === HardwareTypeEnum.WEARABLE; }
                else { return hardware.HardwareTemplate.type !== HardwareTypeEnum.WEARABLE;  }
            }
            else if (filter.type === DashboardFilterTypes.FULLTEXT && filter.fullTextValue) {
                return (hardware.Hardware.uid.toLocaleLowerCase().includes(filter.fullTextValue))
                    || (hardware.Hardware.internalId && hardware.Hardware.internalId.toLocaleLowerCase().includes(filter.fullTextValue))
                    || (hardware.HardwareTemplate.name.toLocaleLowerCase().includes(filter.fullTextValue))
                    || (hardware.HardwareTemplate.model.toLocaleLowerCase().includes(filter.fullTextValue))
                    || hardware.Sensors.filter((sensor: SensorResponseDto) => {
                        return sensor.Sensor.customName && sensor.Sensor.customName.toLocaleLowerCase() === filter.fullTextValue;
                    }).length > 0;
            }
            return true;
        });

        return HiveUtil.hiveHardwareListToHiveItems(hiveHardware, sensorTypes, zoneList, t);
    }

    // Sort hive list - make alerts first
    const sortHiveList = (assigned: Array<HiveItem>, free: Array<HiveItem>) : Array<HiveItem> => {
       return assigned.concat(free).sort(compare_func((el: HiveItem) => HiveUtil.getPriorityFromHiveType(el.type))).reverse();
    }

    // Loading state
    if (typeof hiveState === "undefined" || typeof sensorTypes === "undefined" || typeof zoneList === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD}/> )
    }

    // Error state
    if (hiveState === null || sensorTypes === null || zoneList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_patient_list')})} />
        )
    }

    // Get fresh parsed data
    const dataAssigned = parseHiveAssigned(hiveState, sensorTypes, zoneList);
    const dataFree = parseHiveFree(hiveState, sensorTypes, zoneList);
    const outputList = sortHiveList(dataAssigned, dataFree);

    return (
        <div className={"ViewHive"}>
            <div className="ViewHive-functions">
                <div className="ViewHive-functions-left">
                    {role === RoleEnum.ADMINISTRATOR &&
                        <EasyFilter
                            items={[
                                {
                                    title: t('ViewHive_filter_all'),
                                    active: filter.type === DashboardFilterTypes.ALL,
                                    dispatchFilterChange: filter.dispatchFilterAll
                                },
                                {
                                    title: t('ViewHive_filter_patients'),
                                    active: filter.type === DashboardFilterTypes.ASSIGNED,
                                    dispatchFilterChange: filter.dispatchFilterAssigned
                                },
                                {
                                    title: t('ViewHive_filter_hardware'),
                                    active: filter.type === DashboardFilterTypes.FREE,
                                    dispatchFilterChange: filter.dispatchFilterFree
                                },
                            ]}
                        />
                    }
                </div>
                <div className="ViewHive-functions-right">
                    <div className="ViewHive-functions-right-item">
                        <FilterHive
                            role={role}
                            sensorTypes={sensorTypes}
                            title={filter.filterTitle}
                            dispatchFilterElevation={filter.dispatchFilterElevation}
                            dispatchFilterTemplate={filter.dispatchFilterTemplate}
                            dispatchFilterWearable={filter.dispatchFilterWearable}
                            dispatchFilterSensor={filter.dispatchFilterSensor}
                            dispatchFilterPatient={filter.dispatchFilterPatient} />
                    </div>
                </div>
            </div>
            {outputList.length > 0 ?
                <div className={"ViewHive-content"}>
                    {outputList.map((item: HiveItem, index: number) => {
                        return (
                            <div key={`Hive-${index}`} className={"ViewHive-content-hive"}>
                                <Hive content={item}/>
                            </div>
                        )
                    })}
                </div>
            :
                <div>
                    <Empty
                        type={EmptyTypeEnum.FLAT}
                        title={t('Base_without_data_title')}
                        subTitle={t('Base_without_data_subtitle', {dataName: t('Base_name_hardware')})} />
                </div>
            }
        </div>
    );
}

export default ViewHive;
