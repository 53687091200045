import "./AddDial.scss"
import React, {FormEvent} from "react";
import {T, useTranslate} from "@tolgee/react";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {addOrEditPatientOrganismSlice} from "../../../store/slices/organisms/addOrEditPatientSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {addDialOrganismSlice} from "../../../store/slices/organisms/addDialSlice";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {PhoneRequestDto} from "../../../models/entities/Requests/PhoneRequestDto";
import {actionCreateContact} from "../../../store/actions/data/phoneAction";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";

type AddDialProps = {
    dispatchHide: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {ActionCreatorWithoutPayload} dispatchHide - hide modal or dropdown
 */
export const AddDial = ({dispatchHide} : AddDialProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // Form state
    const nameState = useAppSelector((state) => selectInputState(state.addDialOrganism, 'name'));
    const phoneState = useAppSelector((state) => selectInputState(state.addDialOrganism, 'phone'));
    const formValidState = useAppSelector((state) => selectValidState(state.addDialOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.addDialOrganism));

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!nameState || !nameState.value || !phoneState || !phoneState.value) {
            dispatch(addDialOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const phoneRequest : PhoneRequestDto = {
            name: nameState.value.toString(),
            phone: phoneState.value.toString(),
        }

        const result = await dispatch(actionCreateContact({request: phoneRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(addDialOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchHide());
    }

    return (
        <div className={`AddDial`}>
            <div className="AddDial-content">
                <div className="AddDial-content-header">
                    <T keyName={'AddDial_add_contact'} />
                </div>
                <div className="AddDial-content-form">
                    <form onSubmit={handleSubmit.bind(this)}>
                        <div className="AddDial-content-form-inputs">
                            <InputText
                                required={true}
                                placeholder={t('AddDial_name_placeholder')}
                                inputName="name"
                                inputType={InputTextType.TEXT}
                                label={t('AddDial_name_label')}
                                disabled={false}
                                value={nameState?.value}
                                inputState={nameState}
                                dispatchOnChange={addDialOrganismSlice.actions.updateInput}
                                dispatchOnFocus={addDialOrganismSlice.actions.setFocus}
                                dispatchOnBlur={addDialOrganismSlice.actions.removeFocus} />
                            <InputText
                                required={true}
                                placeholder={t('AddDial_phone_placeholder')}
                                inputName="phone"
                                inputType={InputTextType.TEXT}
                                label={t('AddDial_phone_label')}
                                disabled={false}
                                value={phoneState?.value}
                                inputState={phoneState}
                                dispatchOnChange={addDialOrganismSlice.actions.updateInput}
                                dispatchOnFocus={addDialOrganismSlice.actions.setFocus}
                                dispatchOnBlur={addDialOrganismSlice.actions.removeFocus} />
                        </div>
                        {formErrorState && <span className="formError">{formErrorState}</span>}
                        <div className="AddDial-content-form-button">
                            <Button
                                text={t('AddDial_submit_button')}
                                type={ButtonType.PRIMARY}
                                onClickDisabled={() => { dispatch(addOrEditPatientOrganismSlice.actions.showInputValidStates()); }}
                                disabled={!formValidState}
                                isSubmit={true} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddDial;
