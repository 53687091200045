import {createSlice} from '@reduxjs/toolkit'
import {actionGetSensorData} from "../../actions/organisms/hardwareGraphsAction";
import {DataResponseDto} from "../../../models/entities/Responses/DataResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
export type HardwareGraphsSensorData = {
    [sensorId: number] : DataResponseDto|null;
};

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareGraphsSlice {

    /** Sensor data for hardware graph **/
    sensorsData: HardwareGraphsSensorData,
}

const initialState: HardwareGraphsSlice = {
    sensorsData: {}
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareGraphsOrganismSlice = createSlice({
    name: 'hardwareGraphsOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => { state.sensorsData = {}; },
    },
    extraReducers: (builder) => {
        builder.addCase(actionGetSensorData.fulfilled, (state, {payload}) => {
            state.sensorsData[payload.sensorId] = payload.response;
        })
    }
})

export default hardwareGraphsOrganismSlice.reducer