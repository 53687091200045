import {Communicator, RequestType} from "../utils/Communicator";
import {BuildingResponseBodyDto} from "../entities/Responses/BuildingResponseBodyDto";
import {BuildingRequestDto} from "../entities/Requests/BuildingRequestDto";
import {BuildingUpdateRequestDto} from "../entities/Requests/BuildingUpdateRequestDto";
import {BuildingNextFloorRequestDto} from "../entities/Requests/BuildingNextFloorRequestDto";
import {CacheExpiry} from "../utils/Storage";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class BuildingRepository {

    /**
     * Get building plan
     */
    public static async getBuildingPlan() : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `building`,
            cache: {cacheKey: "getBuildingPlan", expiry: CacheExpiry.FIVE_MINUTES, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Create building plan
     * @param {BuildingRequestDto} request - request info to create building plan
     * @param {TranslatorType} t - translator object
     */
    public static async createBuildingPlan(request: BuildingRequestDto, t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `building`,
            body: request,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_createBuildingPlan'))
    }

    /**
     * Update info about building
     * @param {BuildingUpdateRequestDto} request - building update info
     * @param {TranslatorType} t - translator object
     */
    public static async updateBuildingPlan(request: BuildingUpdateRequestDto, t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            endpoint: `building`,
            body: request,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_updateBuildingPlan'))
    }

    /**
     * Remove all building floors
     * @param {TranslatorType} t - translator object
     */
    public static async removeBuildingFloors(t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `building`,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_removeBuildingFloors'))
    }

    /**
     * Remove current building floor
     * @param {number} elevation - floor elevation to remove
     * @param {TranslatorType} t - translator object
     */
    public static async removeBuildingFloor(elevation: number, t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `building/floor/${elevation}`,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_removeBuildingFloor'))
    }

    /**
     * Remove all hardware locations
     * @param {TranslatorType} t - translator object
     */
    public static async removeHardwareLocations(t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `building/reset`,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_removeHardwareLocations'))
    }

    /**
     * Create new floor in building plan
     * @param {TranslatorType} t - translator object
     * @param {BuildingNextFloorRequestDto} request - info about next floor
     */
    public static async addBuildingFloorToPlan(request: BuildingNextFloorRequestDto, t: TranslatorType) : Promise<BuildingResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `building/floor`,
            body: request,
            cache: {deleteKeys: ['getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('BuildingRepository_addBuildingFloorToPlan'))
    }
}
