import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionUpdatePatient} from "../../actions/data/patientAction";
import {OrderOptions} from "../../../components/molecules/FunctionTable/FunctionTable";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum ListPatientFilterTypes {
    ALL = 1,
    ACTIVE = 3,
    FULLTEXT = 6,
    TEMPLATE = 4,
    SENSOR = 5,
    HARDWARE = 6,
    ELEVATION = 7,
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface PatientPageState {

    /** Add or edit patient modal shown **/
    addOrEditPatientModalShown: boolean,
    /** Add or edit patient modal patient ID **/
    addOrEditPatientModalId: number|null,
    /** Filter type **/
    filterType: ListPatientFilterTypes,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Current pagination page **/
    paginationPage: number,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by HW template **/
    templateId: number|null,
    /** Filter by hardware **/
    hardwareId: number|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by elevation floor **/
    elevation: number|null,
    /** Pagination order **/
    paginationOrder: OrderOptions|null,
}

const initialState: PatientPageState = {
    addOrEditPatientModalShown: false,
    filterType: ListPatientFilterTypes.ACTIVE,
    addOrEditPatientModalId: null,
    fullTextValue: null,
    paginationPage: 0,
    filterTitle: null,
    templateId: null,
    hardwareId: null,
    sensorType: null,
    elevation: null,
    paginationOrder: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const patientPageSlice = createSlice({
    name: 'patientPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.addOrEditPatientModalShown = false;
            state.filterType = ListPatientFilterTypes.ACTIVE;
            state.addOrEditPatientModalId = null;
            state.fullTextValue = null;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.templateId = null;
            state.sensorType = null;
            state.hardwareId = null;
            state.elevation = null;
            state.paginationOrder = null;
        },
        destroyFilter: state => {
            state.filterType = ListPatientFilterTypes.ACTIVE;
            state.fullTextValue = null;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.templateId = null;
            state.elevation = null;
            state.sensorType = null;
            state.hardwareId = null;
            state.paginationOrder = null;
        },
        changePaginationOrder: (state, action: PayloadAction<OrderOptions>) => {
            state.paginationOrder = action.payload;
        },
        filterAll: state => {
            state.filterType = ListPatientFilterTypes.ALL;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.paginationOrder = null;
        },
        filterTemplate: (state, action: PayloadAction<{templateId: number, title: string }>) => {
            state.filterType = ListPatientFilterTypes.TEMPLATE;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.templateId = action.payload.templateId;
            state.paginationOrder = null;
        },
        filterHardware: (state, action: PayloadAction<{hardwareId: number, title: string }>) => {
            state.filterType = ListPatientFilterTypes.HARDWARE;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.hardwareId = action.payload.hardwareId;
            state.paginationOrder = null;
        },
        filterElevation: (state, action: PayloadAction<{elevationId: number, title: string }>) => {
            state.filterType = ListPatientFilterTypes.ELEVATION;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.elevation = action.payload.elevationId;
            state.paginationOrder = null;
        },
        filterSensor: (state, action: PayloadAction<{sensorType: number, title: string }>) => {
            state.filterType = ListPatientFilterTypes.SENSOR;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.hardwareId = action.payload.sensorType;
            state.paginationOrder = null;
        },
        filterFullText: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.paginationPage = 0;
            state.filterTitle = null;
            state.paginationOrder = null;
            if (action.payload.inputValue === null) {
                state.filterType = ListPatientFilterTypes.ACTIVE;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = ListPatientFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
        changePaginationPage: (state, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
        showEditPatientModal: (state, action: PayloadAction<{patientId: number|null }>) => {
            state.addOrEditPatientModalShown = true;
            state.addOrEditPatientModalId = action.payload.patientId;
        },
        hideAddOrEditPatientModal: state => {
            state.addOrEditPatientModalShown = false;
            state.addOrEditPatientModalId = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionUpdatePatient.fulfilled, (state) => {
            state.addOrEditPatientModalShown = false;
            state.addOrEditPatientModalId = null;
            state.paginationOrder = null;
            state.paginationPage = 0;
        })
    }
})

export default patientPageSlice.reducer