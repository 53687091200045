import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {actionGetSensorList} from "../../actions/data/hardwareAction";
import {actionUpdateAlertState} from "../../actions/organisms/sensorStateAction";
import {
    createHardwareFormInitialState,
    CreateHardwareFormState,
    getCreateHardwareReducers
} from "../../../models/utils/AbstractCreateHardwareReducers";
import {SensorResponseListResponseDto} from "../../../models/entities/Responses/SensorResponseListResponseDto";
import {actionGetHardware} from "../../actions/pages/hardwareDetailAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareDetailPageState extends CreateHardwareFormState {

    /** QR generator modal is shown **/
    qrGenModalShown: boolean,
    /** Edit sensor modal is shown **/
    editSensorModalShown: boolean,
    /** Edit sensor modal sensor ID **/
    editSensorModalSensorId: number|null,
    /** Edit sensor hardware ID **/
    editSensorModalHardwareId: number|null,
    /** List of sensors for HW **/
    sensorList?: SensorResponseListResponseDto|null,
    /** Hardware data **/
    hardware?: HardwareResponseDto|null,
    /** Edit HW modal is shown **/
    editHardwareModalShown: boolean,
    /** Edit HW modal HW ID **/
    editHardwareModalHardwareId: number|null,
}

const initialState: HardwareDetailPageState = {...createHardwareFormInitialState, ...{
    editSensorModalShown: false,
    editSensorModalSensorId: null,
    editSensorModalHardwareId: null,
    editHardwareModalHardwareId: null,
    editHardwareModalShown: false,
    qrGenModalShown: false,
}};

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareDetailPageSlice = createSlice({
    name: 'hardwareDetailPage',
    initialState,
    reducers: {...getCreateHardwareReducers(), ...{
        destroyPage: (state: HardwareDetailPageState) => {
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.sensorList = undefined;
            state.hardware = undefined;
            state.qrGenModalShown = false;
        },
        showEditSensorModal: (state: HardwareDetailPageState, action: PayloadAction<{sensorId: number, hardwareId: number }>) => {

            state.editHardwareModalHardwareId = null;
            state.editHardwareModalShown = false;
            state.qrGenModalShown = false;

            state.editSensorModalShown = true;
            state.editSensorModalSensorId = action.payload.sensorId;
            state.editSensorModalHardwareId = action.payload.hardwareId;
        },
        hideEditSensorModal: (state: HardwareDetailPageState) => {
            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
        },
        showEditHardwareModal: (state: HardwareDetailPageState, action: PayloadAction<{hardwareId: number }>) => {

            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.qrGenModalShown = false;

            state.editHardwareModalShown = true;
            state.editHardwareModalHardwareId = action.payload.hardwareId;
        },
        hideEditHardwareModal: (state: HardwareDetailPageState) => {
            state.editHardwareModalHardwareId = null;
            state.editHardwareModalShown = false;
        },
        hideQRGenModal: (state: HardwareDetailPageState) => {
            state.qrGenModalShown = false;
        },
        showQRGenModal: (state: HardwareDetailPageState) => {

            state.editSensorModalShown = false;
            state.editSensorModalSensorId = null;
            state.editSensorModalHardwareId = null;
            state.editHardwareModalShown = false;
            state.editHardwareModalHardwareId = null;

            state.qrGenModalShown = true;
        },
    }},
    extraReducers: (builder) => {
        builder.addCase(actionGetSensorList.fulfilled, (state: HardwareDetailPageState, {payload}) => {
            state.sensorList = payload;
        })
        builder.addCase(actionGetHardware.fulfilled, (state: HardwareDetailPageState, {payload}) => {
            state.hardware = payload;
        })
        builder.addCase(actionUpdateAlertState.fulfilled, (state: HardwareDetailPageState, {payload}) => {

            if (typeof payload === "string") {
                console.warn('hardwareDetailPageSlice-actionUpdateAlertState: wrong state or payload, skip!')
                return;
            }

            if (!state.sensorList) { return; }
            for (let x = 0; x < state.sensorList.Sensors.length; x++) {
                if (state.sensorList.Sensors[x].Sensor.id === payload.Sensor.id) {
                    state.sensorList.Sensors[x].Sensor = payload.Sensor;
                }
            }
        })
    }
})

export default hardwareDetailPageSlice.reducer
