import React, {useEffect} from "react";
import "./HardwareScanRequest.scss"
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import close from "../../../images/closeWhite.svg";
import {Image} from "../../atoms/Image/Image";
import QRScan from "../../molecules/QRScan/QRScan";
import {hardwareScanRequestOrganismSlice} from "../../../store/slices/organisms/hardwareScanRequestSlice";
import {Validator, ValidatorItemType} from "../../../models/utils/Validator";
import {actionGetHardwareTemplateList} from "../../../store/actions/data/hardwareTemplateAction";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {T, useTranslate} from "@tolgee/react";
import {CreateHardwarePageScannedData} from "../../../store/slices/pages/createHardwarePageSlice";

type HardwareScanRequestProps =  {
    dispatchHideScanRequestModal: ActionCreatorWithoutPayload,
    dispatchSetScannedData: ActionCreatorWithPayload<{ scannedData: CreateHardwarePageScannedData|null }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideScanRequestModal - hide scan modal
 * @param {ActionCreatorWithPayload<{ scannedData: CreateHardwarePageScannedData|null }>} dispatchSetScannedData - update input value
 */
export const HardwareScanRequest = ({dispatchHideScanRequestModal, dispatchSetScannedData} : HardwareScanRequestProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const errorState = useAppSelector((state) => state.hardwareScanRequestOrganism.error);
    const hardwareTemplateListState = useAppSelector((state) => state.hardwareTemplateData.hardwareTemplateList);

    useEffect(() => { if (!hardwareTemplateListState) { dispatch(actionGetHardwareTemplateList()); } }, [dispatch, hardwareTemplateListState])
    useEffect(() => { return () => { dispatch(hardwareScanRequestOrganismSlice.actions.destroyOrganism()); } })

    const handleProcessResult = (result: string, templateList: Array<HardwareTemplateResponseDto>) => {

        // Check JSON
        try { JSON.parse(result); } catch (e) {
            dispatch(hardwareScanRequestOrganismSlice.actions.setError(
                {error: t('HardwareScanRequest_error_read')}));
            return;
        }

        // Check format
        const parsed = JSON.parse(result);
        if (!parsed.UUID || !parsed.TemplateId) {
            dispatch(hardwareScanRequestOrganismSlice.actions.setError(
                {error: t('HardwareScanRequest_error_data')}));
            return;
        }

        // Prepare template IDs for validation
        const templateIds = templateList.map((template: HardwareTemplateResponseDto) => {
            return template.HardwareTemplate.id.toString();
        });

        // Check data
        const uuidValidationOutput = Validator.validate({required: true}, t, parsed.UUID);
        const templateValidationOutput = Validator.validate(
            {required: true, type: ValidatorItemType.NUMBER, valueInSet: templateIds},
            t,
            parsed.TemplateId);
        let validationErrors = uuidValidationOutput.errors.concat(templateValidationOutput.errors);
        if (validationErrors.length > 0) {
            dispatch(hardwareScanRequestOrganismSlice.actions.setError(
                {error: t('HardwareScanRequest_error_values')}));
            return;
        }

        // Ok
        dispatch(dispatchSetScannedData({scannedData: {templateId: parsed.TemplateId, uuid: parsed.UUID}}));
        dispatch(hardwareScanRequestOrganismSlice.actions.setError({error: null}));
        dispatch(dispatchHideScanRequestModal());
    }

    // Loading templates
    if (typeof hardwareTemplateListState === "undefined" || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('HardwareScanRequest_title')}
                modal={{dispatchHide: dispatchHideScanRequestModal}}/>
        )
    }

    // Error loading templated
    if (hardwareTemplateListState === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={{ name: 'Scan hardware QR code',  dispatchHide: dispatchHideScanRequestModal }}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware_template_list')})} />
        )
    }

    return (
        <div className="HardwareScanRequest">
            <div className="HardwareScanRequest-content">
                <div className="HardwareScanRequest-content-title">
                    <div className="HardwareScanRequest-content-title-text">
                        <T keyName={'HardwareScanRequest_title'} />
                    </div>
                    <div className="HardwareScanRequest-content-title-close">
                        <Image onClick={() => dispatch(dispatchHideScanRequestModal())}
                               source={close}
                               description={t("Base_close_modal")}
                               size={{width: 20, height: 20}} />
                    </div>
                </div>
                <div className="HardwareScanRequest-content-scan">
                    <div className="HardwareScanRequest-content-scan-camera">
                        <QRScan resultCallBack={ (result: string) => {
                            handleProcessResult(result, hardwareTemplateListState);
                        }} />
                    </div>
                    {errorState &&
                        <div className="HardwareScanRequest-content-scan-error">
                            {errorState}
                        </div>
                    }
                    {!errorState &&
                        <div className="HardwareScanRequest-content-scan-intro">
                            <T keyName={'HardwareScanRequest_hint'} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default HardwareScanRequest;
