import "./DialMenu.scss"
import React, {useEffect} from "react";
import {T, useTranslate} from "@tolgee/react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionDeleteContact, actionGetPhoneList} from "../../../store/actions/data/phoneAction";
import Loading, {LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import dialClose from "../../../images/dialClose.svg";
import {PhoneResponseDto} from "../../../models/entities/Responses/PhoneResponseDto";
import Button, {ButtonType} from "../../atoms/Button/Button";
import plusIcon from "../../../images/plus.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import Empty, {EmptyTypeEnum} from "../../extras/Empty/Empty";

type DialMenuProps = {
    dispatchShowAddForm: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 */
export const DialMenu = ({dispatchShowAddForm} : DialMenuProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const phoneList = useAppSelector((state) => state.phoneData.phoneList);
    const dispatch = useAppDispatch();

    // Init data
    useEffect(() => { if (!phoneList) { dispatch(actionGetPhoneList()); } }, [dispatch, phoneList])

    const handleDelete = async (event: React.MouseEvent, contactId: number) => {

        event.preventDefault();
        event.stopPropagation();
        const result = await dispatch(actionDeleteContact({number: contactId, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
        }
    }

    if (typeof phoneList === "undefined" || isLoading) {
        return (
            <div className={`DialMenu`}>
                <div className="DialMenu-content">
                    <Loading type={LoadingTypeEnum.FLAT}/>
                </div>
            </div>
        )
    }

    if (phoneList === null) {
        return (
            <div className={`DialMenu`}>
                <div className="DialMenu-content">
                    <ErrorComponent type={ErrorComponentTypeEnum.FLAT}
                           subTitle={t('DialMenu_loadErrorSubTitle')}
                           title={t('DialMenu_loadErrorTitle')}/>
                </div>
            </div>
        )
    }

    if (phoneList.length === 0) {
        return (
            <div className={`DialMenu`}>
                <div className="DialMenu-content">
                    <Empty
                        type={EmptyTypeEnum.FLAT}
                        subTitle={t('DialMenu_loadEmptySubTitle')}
                        title={t('DialMenu_loadEmptyTitle')}/>
                    <div className="DialMenu-content-bottom">
                        <div className="DialMenu-content-bottom-content">
                            <Button
                                onClick={ () => { dispatch(dispatchShowAddForm()); }}
                                image={plusIcon}
                                text={t('DialMenu_addContact')}
                                type={ButtonType.PRIMARY} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`DialMenu`}>
            <div className="DialMenu-content">
                <div className="DialMenu-content-header">
                    <T keyName={'DialMenu_call_help'} />
                </div>
                <div className="DialMenu-content-list">
                    {phoneList.map((phone: PhoneResponseDto, index: number) => {
                        return (
                            <a href={`tel:${phone.phone}`} className="DialMenu-content-list-item" key={`DialMenu-content-list-item-${index}`}>
                                <div className="DialMenu-content-list-item-left">
                                    {phone.name}
                                </div>
                                <div className="DialMenu-content-list-item-right">
                                    <div className="DialMenu-content-list-item-right-num">
                                        {phone.phone}
                                    </div>
                                    <div className="DialMenu-content-list-item-right-close">
                                        <img
                                            onClick={(event: React.MouseEvent) => { void handleDelete(event, phone.id); }}
                                            src={dialClose}
                                            alt={t('DialMenu_remove_contact')} />
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                </div>
                <div className="DialMenu-content-bottom">
                    <div className="DialMenu-content-bottom-content">
                        <Button
                            onClick={ () => { dispatch(dispatchShowAddForm()); }}
                            image={plusIcon}
                            text={t('DialMenu_addContact')}
                            type={ButtonType.PRIMARY} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default DialMenu;
