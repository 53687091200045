import React, {useRef} from "react";
import "./BuildingFullScreen.scss"
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {InternalBuildingBuilding} from "../../../models/utils/AbstractBuildingReducers";
import {CustomDraggableAllowedContent} from "../../molecules/CustomDraggable/CustomDraggable";
import {DraggablePlanElementManagerComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementManagerComponent";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {DraggablePlanElementHardwareComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementHardwareComponent";
import {HivePatientResponseDto} from "../../../models/entities/Responses/HivePatientResponseDto";
import {DraggablePlanElementPatientComponent} from "../../molecules/DraggablePlanElement/DraggablePlanElementPatientComponent";
import {T, useTranslate} from "@tolgee/react";
import {buildingFullScreenOrganismSlice} from "../../../store/slices/organisms/buildingFullScreenSlice";
import Building from "../../molecules/Building/Building";
import {ZoneDto} from "../../../models/entities/ZoneDto";

/**
 * @alias BuildingFullScreenObserver
 * @category Components
 */
type BuildingFullScreenObserver = {

    /** Hive data **/
    hive: HiveResponseDto,
    /** List of sensor types **/
    sensorTypes: Array<SensorTypeDto>,
    /** List of zones **/
    zoneList: Array<ZoneDto>,
}

type BuildingFullScreenProps = {
    observer?: BuildingFullScreenObserver
    dispatchHideModal: ActionCreatorWithoutPayload,
    building: InternalBuildingBuilding,
    currentFloorIndex: number,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - hide full screen modal
 * @param {InternalBuildingBuilding} building - building data
 * @param {number} currentFloorIndex - current shown floor
 * @param {BuildingFullScreenObserver|undefined} observer - if observer view - store here data
 */
export const BuildingFullScreen = ({dispatchHideModal, building, currentFloorIndex, observer} : BuildingFullScreenProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const buildingContainerRef: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    const allowedDraggableContentState = useAppSelector((state) => state.buildingFullScreenOrganism.draggableAllowedContent);
    const activeHardwareState = useAppSelector((state) => state.buildingFullScreenOrganism.activeHardware);
    const zoomState = useAppSelector((state) => state.buildingFullScreenOrganism.zoom);
    const initializedState = useAppSelector((state) => state.buildingFullScreenOrganism.initialized);

    // Filter HIVE hardware list for that one with location info
    const getHiveHardwareListWithLocation = () : Array<HardwareWithSensorsResponseDto>|null => {
        if (!observer) { return null; }
        return observer.hive.UnassignedHardware.filter((hiveHardware: HardwareWithSensorsResponseDto) => {
            return hiveHardware.HardwareLocation !== null
                && hiveHardware.BuildingFloor
                && ((hiveHardware.BuildingFloor.elevation - 1) === currentFloorIndex);
        });
    }

    // Filter HIVE patient list for that one with location info
    const getHivePatientListWithLocation = () : Array<HivePatientResponseDto>|null => {

        if (!observer) { return null; }
        return observer.hive.HivePatients.filter((hivePatient: HivePatientResponseDto) => {
            return hivePatient.Patient.calcLocPlanOffsetLeft !== null
                && hivePatient.Patient.calcLocPlanOffsetTop !== null
                && hivePatient.Patient.calcLocBuildingFloorElevation !== null
                && (hivePatient.Patient.calcLocBuildingFloorElevation - 1) === currentFloorIndex;
        });
    }

    const getHardwareElementsList = (allowedContent: CustomDraggableAllowedContent, observer?: BuildingFullScreenObserver) : Array<React.JSX.Element>|null => {

        if (observer) {
            const hardware = getHiveHardwareListWithLocation();
            let output = !hardware ? [] : hardware.map((hardware: HardwareWithSensorsResponseDto, index: number) => {
                return (
                    <DraggablePlanElementHardwareComponent
                        activeHardware={null}
                        allowedContent={allowedContent}
                        currentZoom={1}
                        zoneList={observer.zoneList}
                        hardware={hardware}
                        key={`DraggablePlanElementHardwareComponent-${index}`}
                        sensorTypes={observer.sensorTypes}/>
                );
            })

            const patients = getHivePatientListWithLocation();
            output = output.concat(!patients ? [] : patients.map((patient: HivePatientResponseDto, index: number) => {
                return (
                    <DraggablePlanElementPatientComponent
                        key={`DraggablePlanElementPatientComponent-${index}`}
                        patient={patient}
                        zoneList={observer.zoneList}
                        allowedContent={allowedContent}
                        activePatient={null}
                        currentZoom={1}
                        sensorTypes={observer.sensorTypes}/>
                )
            }));
            return output;
        }

        return building.floors[currentFloorIndex].locations.map((location, index: number) => {
            return (
                <DraggablePlanElementManagerComponent
                    key={`DraggablePlanElementManagerComponent-${index}`}
                    activeHardware={activeHardwareState}
                    allowedContent={allowedContent}
                    dragged={false}
                    location={location}/>
            )
        })
    }

    return (
        <div className={"BuildingFullScreen"}>
            <div className="BuildingFullScreen-modal">
                <div className="BuildingFullScreen-modal-content" ref={buildingContainerRef}>
                    <div className="BuildingFullScreen-modal-content-title">
                        <div className="BuildingFullScreen-modal-content-title-text">
                            <T keyName={'BuildingFullScreen_title'} />
                        </div>
                        <div className="BuildingFullScreen-modal-content-title-close">
                            <Image
                                onClick={() => dispatch(dispatchHideModal()) }
                                source={close}
                                description={t("Base_close_modal")}
                                size={{width: 20, height: 20}} />
                        </div>
                    </div>
                    <Building
                        initialized={initializedState}
                        currentZoom={zoomState}
                        dispatchInitialized={buildingFullScreenOrganismSlice.actions.setInitialized}
                        dispatchUpdateZoom={buildingFullScreenOrganismSlice.actions.updateZoomValue}
                        dispatchUpdateDraggableAllowedContent={buildingFullScreenOrganismSlice.actions.updateDraggableAllowedContent}
                        buildingContainerElement={buildingContainerRef.current}
                        plan={building.floors[currentFloorIndex].plan}
                        draggableElements={allowedDraggableContentState ? getHardwareElementsList(allowedDraggableContentState, observer) : null}
                    />
                </div>
            </div>
        </div>
    );
}

export default BuildingFullScreen;
