export type ValueUtilParsableValue = number|null|string|boolean|undefined;

/**
 * @class
 * @category Utils
 */
export class ValueUtil {

    /**
     * Get parsed string value
     * @function
     * @category Utils
     * @param {any} t - translator object
     * @param {ValueUtilParsableValue|undefined} value - string value
     * @param {boolean|undefined} precise - long float format
     * @param {boolean|undefined} justDate - get just date
     * @param {boolean|undefined} justTime - get just time
     */
    public static getStringFromValue(t: any, value?: ValueUtilParsableValue, precise?: boolean, justDate?: boolean, justTime?: boolean) : string {

        if (typeof value === 'string' && !isNaN(Date.parse(value)) && value.slice(-1) === 'Z') {

            const date = new Date(value);
            const day = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
            const month = `${(date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)}`;
            const hour = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}`;
            const minute = `${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
            const second = `${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;

            if (justDate) { return `${day}. ${month}. ${date.getFullYear()}`; }
            if (justTime) { return `${hour}:${minute}:${second}`}
            return `${day}. ${month}. ${date.getFullYear()} ${hour}:${minute}:${second}`;
        }
        if (typeof value === 'boolean') { return value ? t('ValueUtil_yes') : t('ValueUtil_no'); }
        if (typeof value === 'string') { return value; }
        if (typeof value === 'number') {
            const multiply = precise ? 1000000 : 100;
            return (Math.round(value * multiply) / multiply).toString();
        }
        return '-';
    }

    /**
     * Get duration as value and append text
     * @function
     * @category Utils
     * @param {Date} timeBigger - bigger timestamp
     * @param {Date} timeLower - lower timestamp
     * @param {any} t - translator object
     */
    public static getDurationFromMillisecondsWithText(timeBigger: Date, timeLower: Date, t: any) : { value: number, text: string }|null {

        let value = timeBigger.getTime() - timeLower.getTime();
        if (!value) { return null; }
        value = value / 1000;
        let hours = Math.floor(value / 3600);
        let minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = Math.floor(value - (hours * 3600) - (minutes * 60));

        if (hours > 0) { return {value: hours, text: t('ValueUtil_hours', {hours: hours})}; }
        if (minutes > 0) { return {value: minutes, text: t('ValueUtil_minutes', {minutes: minutes})}; }
        if (seconds > 0) { return {value: seconds, text: t('ValueUtil_seconds', {seconds: seconds})}; }

        return null;
    }
}