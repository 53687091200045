import React, {FormEvent, useEffect} from "react";
import "./CreateSupport.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {Storage} from "../../../models/utils/Storage";
import {UserDto} from "../../../models/entities/UserDto";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {SupportRequestDto} from "../../../models/entities/Requests/SupportRequestDto";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {actionCreateSupport} from "../../../store/actions/organisms/createSupportAction";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {createSupportOrganismSlice} from "../../../store/slices/organisms/createSupportSlice";
import {T, useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const CreateSupport = (): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.createSupportOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.createSupportOrganism));
    const subjectState = useAppSelector((state) => selectInputState(state.createSupportOrganism, 'subject'));
    const messageState = useAppSelector((state) => selectInputState(state.createSupportOrganism, 'message'));
    const nameState = useAppSelector((state) => selectInputState(state.createSupportOrganism, 'name'));
    const emailState = useAppSelector((state) => selectInputState(state.createSupportOrganism, 'email'));

    // Cleanup
    useEffect(() => {
        return () => { dispatch(createSupportOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit ticket create
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!subjectState || !subjectState.value || !messageState || !messageState.value
            || !nameState || !nameState.value || !emailState || !emailState.value) {
            dispatch(createSupportOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const supportRequest : SupportRequestDto = {
            message: messageState.value.toString(),
            subject: subjectState.value.toString(),
        }

        const result = await dispatch(actionCreateSupport({supportRequest: supportRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(createSupportOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(createSupportOrganismSlice.actions.updateInput({inputName: 'subject', inputValue: null, t: t}));
        dispatch(createSupportOrganismSlice.actions.updateInput({inputName: 'message', inputValue: null, t: t}));
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({message: t('CreateSupport_ticketCreated')}))
    }

    // Get error from session
    const getUser = () : UserDto|null => {

        const session = Storage.getSession();
        if (!session) { return null; }
        return session.user;
    }

    // Get fresh data
    const user = getUser();
    const userName = user ? `${user.firstName} ${user.lastName}` : null;
    const userEmail = user ? user.email : null;

    // Error state
    if (userName === null || userEmail === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    return (
        <div className="CreateSupport">
            <div className="CreateSupport-content">
                <div className="CreateSupport-content-title weak">
                    <T keyName={'CreateSupport_title'} />
                </div>
                <div className="CreateSupport-content-form">
                    <form onSubmit={handleSubmit.bind(this)}>
                        <div className="CreateSupport-content-form-inputs">
                            <div className="CreateSupport-content-form-inputs-flex">
                                <InputText
                                    required={true}
                                    placeholder={t('CreateSupport_name_placeholder')}
                                    inputName="name"
                                    inputType={InputTextType.TEXT}
                                    label={t('CreateSupport_name_label')}
                                    disabled={true}
                                    startValue={ValueUtil.getStringFromValue(t, userName)}
                                    value={nameState?.value}
                                    inputState={nameState}
                                    dispatchOnChange={createSupportOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={createSupportOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={createSupportOrganismSlice.actions.removeFocus} />
                                <InputText
                                    required={true}
                                    placeholder={t('CreateSupport_email_placeholder')}
                                    inputName="email"
                                    inputType={InputTextType.TEXT}
                                    label={t('CreateSupport_email_label')}
                                    disabled={true}
                                    startValue={ValueUtil.getStringFromValue(t, userEmail)}
                                    value={emailState?.value}
                                    inputState={emailState}
                                    dispatchOnChange={createSupportOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={createSupportOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={createSupportOrganismSlice.actions.removeFocus} />
                            </div>
                            <div className="CreateSupport-content-form-inputs-flex">
                                <InputText
                                    required={true}
                                    placeholder={t('CreateSupport_subject_placeholder')}
                                    inputName="subject"
                                    inputType={InputTextType.TEXT}
                                    label={t('CreateSupport_subject_label')}
                                    disabled={false}
                                    startValue={null}
                                    value={subjectState?.value}
                                    inputState={subjectState}
                                    dispatchOnChange={createSupportOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={createSupportOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={createSupportOrganismSlice.actions.removeFocus} />
                            </div>
                            <div className="CreateSupport-content-form-inputs-flex">
                                <InputText
                                    fullWidth={true}
                                    required={true}
                                    placeholder={t('CreateSupport_message_placeholder')}
                                    inputName="message"
                                    inputType={InputTextType.TEXTAREA}
                                    label={t('CreateSupport_message_label')}
                                    disabled={false}
                                    startValue={null}
                                    value={messageState?.value}
                                    inputState={messageState}
                                    dispatchOnChange={createSupportOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={createSupportOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={createSupportOrganismSlice.actions.removeFocus} />
                            </div>
                            {formErrorState && <span className="formError">{formErrorState}</span>}
                            <div className="CreateSupport-content-form-inputs-button">
                                <Button
                                    text={t('CreateSupport_button')}
                                    type={ButtonType.PRIMARY}
                                    disabled={!formValidState}
                                    onClickDisabled={() => { dispatch(createSupportOrganismSlice.actions.showInputValidStates()); }}
                                    isSubmit={true} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateSupport;
