import {createSlice} from '@reduxjs/toolkit'
import {
    actionActivatePatient,
    actionCreatePatient,
    actionDeletePatient,
    actionGetPatientList, actionRemovePatientHardwareList,
    actionUpdatePatient
} from "../../actions/data/patientAction";
import {actionAssignHardware} from "../../actions/data/hardwareAction";
import {PatientWithHardwareListResponseDto} from "../../../models/entities/Responses/PatientWithHardwareListResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface PatientState {

    /** List of patients **/
    patientList?: PatientWithHardwareListResponseDto|null,
    /** Create new patient error **/
    createError?: string
}

const initialState: PatientState = {
    patientList: undefined,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const patientDataSlice = createSlice({
    name: 'patientData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetPatientList.fulfilled, (state, {payload}) => {
            state.patientList = payload;
        })
        builder.addCase(actionCreatePatient.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionCreatePatient: wrong payload, skip!')
                return;
            }

            state.patientList = payload.patientList;
        })
        builder.addCase(actionUpdatePatient.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionUpdatePatient: wrong payload, skip!')
                return;
            }

            state.patientList = payload;
        })
        builder.addCase(actionDeletePatient.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionDeletePatient: wrong payload, skip!')
                return;
            }

            state.patientList = payload;
        })
        builder.addCase(actionActivatePatient.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionActivatePatient: wrong payload, skip!')
                return;
            }

            state.patientList = payload;
        })
        builder.addCase(actionAssignHardware.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionAssignHardware: wrong payload, skip!')
                return;
            }

            state.patientList = payload.patientList;
        })
        builder.addCase(actionRemovePatientHardwareList.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientDataSlice-actionRemovePatientHardwareList: wrong payload, skip!')
                return;
            }

            state.patientList = payload.patientList;
        })
    }
})

export default patientDataSlice.reducer
