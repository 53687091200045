import "./Building.scss"
import React, {useEffect, useRef} from "react";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import throttle from "lodash.throttle";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {CustomDraggableAllowedContent} from "../CustomDraggable/CustomDraggable";
import {useTranslate} from "@tolgee/react";
import {clickedToElementWithClassRecursive} from "../../../models/utils/UIUtil";
import Loading, {LoadingTypeEnum} from "../../extras/Loading/Loading";

type MouseMovePositionType = {
    x: number;
    y: number;
    top: number;
    left: number;
}

/**
 * @alias BuildingProps
 * @category Components
 */
type BuildingProps = {

    dispatchUpdateDraggableAllowedContent: ActionCreatorWithPayload<CustomDraggableAllowedContent>,
    buildingContainerElement: HTMLDivElement|null,
    plan: string
    currentZoom?: number,
    draggableElements: Array<React.JSX.Element>|null,
    dispatchUpdateZoom: ActionCreatorWithPayload<{ zoom: number }>,
    initialized: boolean
    dispatchInitialized: ActionCreatorWithoutPayload,
    dispatchRemoveActives?: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {ActionCreatorWithPayload<CustomDraggableAllowedContent>} dispatchUpdateDraggableAllowedContent - update allowed bounds callback
 * @param {HTMLDivElement|null} buildingContainerElement - container element (calculate exact size from parent hack - used for overflow fix)
 * @param {string} plan - building plan image source
 * @param {number|undefined} currentZoom - current zoom level
 * @param {Array<React.JSX.Element>|null} draggableElements - list of draggable elements
 * @param {ActionCreatorWithPayload<{ zoom: number }>} dispatchUpdateZoom - redux callback to update zoom
 * @param {boolean} initialized - view is initialized
 * @param {ActionCreatorWithoutPayload} dispatchInitialized - set initialized view
 * @param {ActionCreatorWithoutPayload|undefined} dispatchRemoveActives - remove active elements
 */
export const Building = ({dispatchUpdateDraggableAllowedContent, buildingContainerElement, plan, currentZoom, draggableElements,
    dispatchUpdateZoom, initialized, dispatchInitialized, dispatchRemoveActives}: BuildingProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const CANVAS_VIRTUAL_SIZE: number = 10000;
    const DRAGGABLE_ELEMENT_CLASS_NAME: string = 'CustomDraggable';
    const DRAGGABLE_PLAN_ELEMENT_CLASS_NAME: string = 'DraggablePlanElement';
    const buildingImageRef: React.MutableRefObject<HTMLImageElement|null> = useRef(null);
    const buildingScrollRef: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    const [mouseMovePossition, _setMouseMovePossition] = React.useState<MouseMovePositionType>({x: 0, y: 0, top: 0, left: 0});
    const mouseMovePossitionRef = React.useRef(mouseMovePossition);
    const wheelTimeout: React.MutableRefObject<NodeJS.Timeout|null> = React.useRef(null)

    // After initialization set position for scroll
    useEffect(() => {
        if (initialized) {
            const scrollElement = buildingScrollRef.current;
            if (!scrollElement) { return; }
            scrollElement.scrollTop = (CANVAS_VIRTUAL_SIZE - scrollElement.clientHeight) / 2;
            scrollElement.scrollLeft = (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
        }
    }, [initialized])

    // Override state setter and update also reference (hack fix for updating state in listener callback
    const setMouseMovePossition = (data: MouseMovePositionType) => {
        mouseMovePossitionRef.current = data;
        _setMouseMovePossition(data);
    };

    // This is used to hack to scroll disable
    useEffect(() => {

        const cancelWheel = (e: any) => wheelTimeout.current && e.preventDefault()
        document.body.addEventListener('wheel', cancelWheel, {passive:false})
        return () => document.body.removeEventListener('wheel', cancelWheel)
    }, []);

    // Update allowed bounds (on page scroll)
    useEffect(() => {

        window.addEventListener('scroll', throttle(() => {
            if (buildingImageRef.current === null) { return; }
            BuildingUtil.updateAllowedBounds(buildingImageRef.current, dispatch, dispatchUpdateDraggableAllowedContent);
        }, 500));
    }, [dispatch, dispatchUpdateDraggableAllowedContent])

    // Initialize after image load (get right zoom level)
    const handleInitializeAllowedBounds = async (event: React.SyntheticEvent<HTMLImageElement>) => {

        const scrollElement = buildingScrollRef.current;
        if (!scrollElement) { return; }
        const zoomWidth = (scrollElement.clientWidth / event.currentTarget.naturalWidth) - 0.05;
        const zoomHeight = (scrollElement.clientHeight / event.currentTarget.naturalHeight) - 0.05;
        dispatch(dispatchUpdateZoom({zoom: Math.min(zoomWidth, zoomHeight)}));
        dispatch(dispatchInitialized());
    }

    // Update allowed bounds (on zoom)
    const handleImageZoomUpdateAllowedBounds = () => {

        if (buildingImageRef.current === null) { return; }
        BuildingUtil.updateAllowedBounds(buildingImageRef.current, dispatch, dispatchUpdateDraggableAllowedContent);
    }

    // Update allowed bounds (scroll plan)
    const handleScrollUpdateAllowedBounds = () => {

        if (buildingImageRef.current) {
            BuildingUtil.updateAllowedBounds(buildingImageRef.current, dispatch, dispatchUpdateDraggableAllowedContent);
        }
    }

    // Callback when dragging whole building plan and scrolling to new position
    const handleOnMouseMove = (e: MouseEvent) => {

        if (!buildingScrollRef.current) { return; }
        const dx = e.clientX -  mouseMovePossitionRef.current.x;
        const dy = e.clientY -  mouseMovePossitionRef.current.y;
        buildingScrollRef.current.scrollTop =  mouseMovePossitionRef.current.top - dy;
        buildingScrollRef.current.scrollLeft =  mouseMovePossitionRef.current.left - dx;
    }

    // Callback when clicked left mouse button on building plan (probably what to drag to scroll?)
    const handleLeftMousePressed = (event: React.MouseEvent<HTMLDivElement>) => {

        if (!buildingScrollRef.current) { return; }

        // We need to be sure that we have not clicked to some draggable
        const eventTarget = event.target as HTMLElement;
        const foundDraggableElement = clickedToElementWithClassRecursive(eventTarget, DRAGGABLE_ELEMENT_CLASS_NAME)
        const foundPlanElement = clickedToElementWithClassRecursive(eventTarget, DRAGGABLE_PLAN_ELEMENT_CLASS_NAME)
        if (foundDraggableElement || foundPlanElement) { return; }

        setMouseMovePossition({
            left: buildingScrollRef.current.scrollLeft,
            top: buildingScrollRef.current.scrollTop,
            x: event.clientX,
            y: event.clientY
        });

        buildingScrollRef.current.style.cursor = 'move';
        document.addEventListener('mousemove', handleOnMouseMove);
        document.addEventListener('mouseup', handleLeftMouseDropped);
    }

    // Callback when finished with dragging
    const handleLeftMouseDropped = () => {

        document.removeEventListener('mousemove', handleOnMouseMove);
        if (!buildingScrollRef.current) { return; }
        buildingScrollRef.current.style.cursor = 'initial';
    }

    // When zoom requested using wheel
    const handleWheelUsed = (event: React.WheelEvent<HTMLDivElement>) => {

        // This is a hack to prevent scrolling
        if (wheelTimeout.current) { clearTimeout(wheelTimeout.current); }
        wheelTimeout.current = setTimeout(() => { wheelTimeout.current = null }, 300)

        // This is the calculation for zooming level change
        if (!dispatchUpdateZoom || !currentZoom) { return; }
        let delta = (-event.deltaY) / 100 / 13;
        let zoom = currentZoom + delta;
        zoom = zoom < 0.125 ? 0.125 : zoom;
        zoom = zoom > 8 ? 8 : zoom;
        dispatch(dispatchUpdateZoom({zoom: zoom}));
    }

    // When clicked inside building plan but not into draggable, remove actives
    const handleClickedInsideEditor = (event: React.MouseEvent<HTMLDivElement>) => {

        if (!dispatchRemoveActives) { return; }
        const foundDraggableElement = clickedToElementWithClassRecursive(event.target as HTMLElement, DRAGGABLE_ELEMENT_CLASS_NAME)
        const foundPlanElement = clickedToElementWithClassRecursive(event.target as HTMLElement, DRAGGABLE_PLAN_ELEMENT_CLASS_NAME)
        if (!foundDraggableElement && !foundPlanElement) { dispatch(dispatchRemoveActives()); }
    }

    return (
        <div
            className={`Building ${initialized ? 'initialized' : ''}`}
            onWheel={handleWheelUsed.bind(this)}
            onMouseDown={handleLeftMousePressed.bind(this)}>
            <div className={`Building-loader loader`}>
                <Loading type={LoadingTypeEnum.FLAT} />
            </div>
            <div className={`Building-content content`}>
                <div
                    onClick={handleClickedInsideEditor.bind(this)}
                    onScroll={handleScrollUpdateAllowedBounds.bind(this)}
                    ref={buildingScrollRef}
                    style={{width: buildingContainerElement ? `${buildingContainerElement.clientWidth}px` : undefined}}
                    className={"Building-content-main"}>
                    <div
                        style={{width: `${CANVAS_VIRTUAL_SIZE}px`, height: `${CANVAS_VIRTUAL_SIZE}px`}}
                        className={"Building-content-main-building"}>
                        <div className={"Building-content-main-building-container"}>
                            <img
                                draggable={false}
                                onTransitionEnd={handleImageZoomUpdateAllowedBounds.bind(this)}
                                style={{
                                    width: `${buildingImageRef.current 
                                        ? buildingImageRef.current?.naturalWidth * (currentZoom ?? 1) + 'px' : 'initial'}`,
                                    height: `${buildingImageRef.current
                                        ? buildingImageRef.current?.naturalHeight * (currentZoom ?? 1) + 'px' : 'initial'}`,
                                    minWidth: `${currentZoom}px`
                                }}
                                onLoad={handleInitializeAllowedBounds.bind(this)}
                                className={'building-img'}
                                ref={buildingImageRef}
                                src={plan}
                                alt={t('Base_buildingPlan')} />
                            {draggableElements &&
                                <div className={"Building-content-main-building-hardware"}>
                                    {draggableElements.map((element: React.JSX.Element) => { return element; })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Building;
