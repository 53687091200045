import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {useAppSelector} from "../../../store/hooks";
import {userPageSlice} from "../../../store/slices/pages/userPageSlice";
import {ListUsers} from "../../organisms/ListUsers/ListUsers";
import {AddOrEditUser} from "../../organisms/AddOrEditUser/AddOrEditUser";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import UserNotifyOptions from "../../organisms/UserNotifyOptions/UserNotifyOptions";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type UsersTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - current user session role
 */
export const UsersTemplate = ({role} : UsersTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const addOrEditUserModalShownState = useAppSelector((state) => state.userPage.addOrEditUserModalShown);
    const addOrEditUserModalIdState = useAppSelector((state) => state.userPage.addOrEditUserModalId);
    const userNotifyOptionsShownState = useAppSelector((state) => state.userPage.userNotifyOptionsModalShown);
    const userNotifyOptionsUserIdState = useAppSelector((state) => state.userPage.userNotifyOptionsModalUserId);
    const filterTypeState = useAppSelector((state) => state.userPage.filterType);
    const roleState = useAppSelector((state) => state.userPage.role);
    const fullTextValueState = useAppSelector((state) => state.userPage.fullTextValue);
    const filterTitleState = useAppSelector((state) => state.userPage.filterTitle);
    const paginationPageState = useAppSelector((state) => state.userPage.paginationPage);
    const paginationOrderState = useAppSelector((state) => state.userPage.paginationOrder);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('UsersTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        breadItems={[
                            {name: t('Navigation_users'), link: null}
                        ]}
                        role={role}
                        title={t('UsersTemplate_title')}
                        easySearch={{
                            searchValue: fullTextValueState,
                            dispatchSearchValueUpdate: userPageSlice.actions.filterFullText
                        }} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <ListUsers
                                pagination={{
                                    showCount: 10,
                                    order: paginationOrderState,
                                    page: paginationPageState,
                                    dispatchChangeOrder: userPageSlice.actions.changePaginationOrder,
                                    dispatchChangePage: userPageSlice.actions.changePaginationPage
                                }}
                                filter={{
                                    fullTextValue: fullTextValueState ? fullTextValueState.toLowerCase() : null,
                                    filterTitle: filterTitleState,
                                    filterType: filterTypeState,
                                    role: roleState,
                                    dispatchFilterActive: userPageSlice.actions.destroyFilter,
                                    dispatchFilterAll: userPageSlice.actions.filterAll,
                                    dispatchFilterRole: userPageSlice.actions.filterRole,
                                }}
                                dispatchShowUserNotifyOptionsModal={userPageSlice.actions.showUserNotifyOptionsModal}
                                dispatchShowAddOrEditUserModal={userPageSlice.actions.showAddOrEditUserModal} />
                        </div>
                    </div>
                </div>
            </div>
            {addOrEditUserModalShownState &&
                <AddOrEditUser
                    userId={addOrEditUserModalIdState}
                    dispatchHideAddOrEditUserModal={userPageSlice.actions.hideAddOrEditUserModal} />
            }
            {userNotifyOptionsShownState && userNotifyOptionsUserIdState &&
                <UserNotifyOptions
                    userId={userNotifyOptionsUserIdState}
                    dispatchHideUserNotifyOptionsModal={userPageSlice.actions.hideUserNotifyOptionsModal} />
            }
        </main>
    );
}

export default UsersTemplate;
