import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {AlertDetailTemplate} from "../templates/AlertDetailTemplate/AlertDetailTemplate";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {alertDetailPageSlice} from "../../store/slices/pages/alertDetailPageSlice";
import {actionGetAlert, actionGetAlertSensor} from "../../store/actions/data/alertAction";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {actionGetSensorTypes} from "../../store/actions/data/sensorAction";
import {Storage} from "../../models/utils/Storage";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const AlertDetailPage = (): React.JSX.Element => {

    // Init
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = Storage.getSession()?.user.role;

    // Initialize states
    const alertState = useAppSelector((state) => state.alertDetailPage.alert);
    const sensorState = useAppSelector((state) => state.alertDetailPage.sensor);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);

    // Dispatch data load & clean data
    useEffect(() => { if (params.id) { dispatch(actionGetAlert(parseInt(params.id))); } }, [dispatch, params]);
    useEffect(() => { if (alertState) { dispatch(actionGetAlertSensor(alertState.Alert.sensor)); } }, [dispatch, alertState]);
    useEffect(() => { return () => { dispatch(alertDetailPageSlice.actions.destroyPage()); } }, [dispatch])
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    // Check & Wait for required data
    if (!params.id) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_not_found_title")}
            subTitle={"Base_errors_page_not_found_description"} />
    }

    if (typeof alertState === "undefined" || typeof sensorState === "undefined" || typeof sensorTypes === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (alertState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_alert')})} />
    }

    if (sensorState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_sensor')})} />
    }

    if (sensorTypes === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_sensor_types')})} />
    }

    return (<AlertDetailTemplate
        sensorTypes={sensorTypes}
        role={role}
        sensor={sensorState}
        alert={alertState} />)
};

export default AlertDetailPage;
