import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {patientDetailPageSlice} from "../../store/slices/pages/patientDetailPageSlice";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {actionGetUserList} from "../../store/actions/data/userAction";
import {DataParser} from "../../models/utils/DataParser";
import {UserDetailTemplate} from "../templates/UserDetailTemplate/UserDetailTemplate";
import {Storage} from "../../models/utils/Storage";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const UserDetailPage = (): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = Storage.getSession()?.user.role;
    const {t, isLoading} = useTranslate();

    // Initialize states
    const usersState = useAppSelector((state) => state.userData.userList);

    // Dispatch data load & clean data
    useEffect(() => { dispatch(actionGetUserList()); }, [dispatch, params]);
    useEffect(() => { return () => { dispatch(patientDetailPageSlice.actions.destroyPage()); } }, [dispatch])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    // Check & Wait for required data
    if (!params.id) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_not_found_title")}
            subTitle={t("Base_errors_page_not_found_description")} />
    }

    const parsedPatient = DataParser.getUserFromList(usersState, parseInt(params.id));

    if (typeof parsedPatient === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (parsedPatient === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_user')})} />
    }

    return (
        <UserDetailTemplate
            role={role}
            user={parsedPatient} />
    )
};

export default UserDetailPage;
