import "./Dot.scss";
import React from "react";

/**
 * @enum {string}
 * @category Components
 */
export enum DotType {
    SUCCESS = "success",
    ERROR = "error",
    PRIMARY = "primary",
    WARNING = "warning"
}

type DotProps = {
    type ?: DotType,
    highlight?: boolean
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {DotType} type - dot color type
 * @param {boolean} highlight - dot is animated
 */
export const Dot = ({type, highlight} : DotProps): React.JSX.Element => {

    return (
        <div className={`Dot parent ${highlight ? 'highlight' : ''} ${type ? type : DotType.PRIMARY}`}>
            <div className={`Dot child ${highlight ? 'highlight' : ''} ${type ? type : DotType.PRIMARY}`}>
            </div>
        </div>
    );
}

export default Dot;
