import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionGetAlertLogList, actionGetHardwareLogList, actionGetLogList, actionGetPatientLogList } from '../../actions/organisms/listLogAction';
import {LogResponseBodyDto} from "../../../models/entities/Responses/LogResponseBodyDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface ListLogSlice {

    /** Log list **/
    logList?: LogResponseBodyDto|null,
    /** Current pagination page **/
    paginationPage: number,
}

const initialState: ListLogSlice = {
    paginationPage: 0
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const listLogOrganismSlice = createSlice({
    name: 'listLogOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.logList = undefined;
            state.paginationPage = 0;
        },
        changeLogsPaginationPage: (state, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionGetHardwareLogList.fulfilled, (state, {payload}) => {
            state.logList = payload;
        })
        builder.addCase(actionGetAlertLogList.fulfilled, (state, {payload}) => {
            state.logList = payload;
        })
        builder.addCase(actionGetPatientLogList.fulfilled, (state, {payload}) => {
            state.logList = payload;
        })
        builder.addCase(actionGetLogList.fulfilled, (state, {payload}) => {
            state.logList = payload;
        })
    }
})

export default listLogOrganismSlice.reducer