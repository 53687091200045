import React, {useEffect} from "react";
import "./BuildingManagerTemplate.scss"
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {BuildingManager} from "../../organisms/BuildingManager/BuildingManager";
import {ListLog} from "../../organisms/ListLog/ListLog";
import {HardwareLookup} from "../../organisms/HardwareLookup/HardwareLookup";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {buildingManagerPageSlice} from "../../../store/slices/pages/buildingManagerSlice";
import {InternalBuildingLocationType} from "../../../models/utils/AbstractBuildingReducers";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../../extras/ErrorComponent/ErrorComponent";
import {BuildingFullScreen} from "../../organisms/BuildingFullScreen/BuildingFullScreen";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {BuildingPlanUpload} from "../../organisms/BuildingPlanUpload/BuildingPlanUpload";
import {actionAddFloorToBuildingPlan} from "../../../store/actions/data/buildingAction";
import {useTranslate} from "@tolgee/react";
import {useSearchParams} from "react-router-dom";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {actionGetHardwareList} from "../../../store/actions/data/hardwareAction";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type BuildingManagerTemplateProps = {
    role: RoleEnum,
}

/**
 * Building draggable manager
 * - building data lowered to template for performance increase behind log redraw
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - user session role
 */
export const BuildingManagerTemplate = ({role} : BuildingManagerTemplateProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();

    const [searchParams, setParams] = useSearchParams();
    const buildingState = useAppSelector((state) => state.buildingManagerPage.internalBuilding);
    const floorElevationState = useAppSelector((state) => state.buildingManagerPage.floorElevation);
    const floorIndexState = useAppSelector((state) => state.buildingManagerPage.floorIndex);
    const activeHardwareState = useAppSelector((state) => state.buildingManagerPage.activeHardware);
    const draggableAllowedContentState = useAppSelector((state) => state.buildingManagerPage.draggableAllowedContent);
    const fullscreenShownState = useAppSelector((state) => state.buildingManagerPage.modalFullScreenShown);
    const zoomState = useAppSelector((state) => state.buildingManagerPage.zoom);
    const anyChangesMadeState = useAppSelector((state) => state.buildingManagerPage.anyChangesMade);
    const uploadModalState = useAppSelector((state) => state.buildingManagerPage.uploadModal);
    const initializedState = useAppSelector((state) => state.buildingManagerPage.initialized);
    const hardwareList = useAppSelector((state) => state.hardwareData.hardwareList);

    // Initialize floor from URL on start
    useEffect(() => {
        BuildingUtil.initializeFloorFromUrl(searchParams, dispatch, buildingManagerPageSlice.actions.updateFloorElevation);
    }, [searchParams, dispatch]);

    // Init hardware list
    useEffect(() => { if (!hardwareList) { dispatch(actionGetHardwareList()); } }, [dispatch, hardwareList])

    if (typeof buildingState === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (buildingState === null || !buildingState.building) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_building')})} />
    }

    // Callback when drag end event is fired
    const handleDragEndCallBack = (offsetXPct: number, offsetYPct: number, hardwareId: number, hardwareName: string,
        type: InternalBuildingLocationType) => {

        dispatch(buildingManagerPageSlice.actions.updateHardwareBuildingPosition({
            offsetXPct: offsetXPct,
            offsetYPct: offsetYPct,
            hardwareId: hardwareId,
            hardwareName: hardwareName,
            type: type
        }));
    }

    return (
        <main>
            <div className="BuildingManagerTemplate layout layout-flexHeight">
                <Helmet>
                    <title>{t('BuildingManagerTemplate_title', {buildingName: buildingState.building.name})}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('BuildingManagerTemplate_title', {buildingName: buildingState.building.name})}
                        breadItems={[
                            {name: t('Navigation_building_editor'), link: null},
                        ]}/>
                    <div className="layout-right-displayError">
                        <ErrorComponent
                            variant={ErrorComponentVariant.UNAUTHORIZED}
                            type={ErrorComponentTypeEnum.CARD}
                            title={t('Base_not_allowed_device_title')}
                            subTitle={t('Base_not_allowed_device_description')} />
                    </div>
                    <div className="layout-right-content flex">
                        <div className="layout-right-content-item third">
                            <HardwareLookup
                                hardwareList={hardwareList}
                                initialStateOpened={true}
                                activeHardware={activeHardwareState}
                                dispatchUpdateActiveHardware={buildingManagerPageSlice.actions.updateActiveHardware}
                                hardwareType={HardwareTypeEnum.GATEWAY}
                                allowedContent={draggableAllowedContentState}
                                doneCallBack={ (
                                    offsetXPct: number, offsetYPct: number, hardwareId: number,
                                    hardwareName: string, type: InternalBuildingLocationType) => {
                                    handleDragEndCallBack(offsetXPct, offsetYPct, hardwareId, hardwareName, type);
                                } } />
                            <HardwareLookup
                                hardwareList={hardwareList}
                                activeHardware={activeHardwareState}
                                hardwareType={HardwareTypeEnum.REPEATER}
                                dispatchUpdateActiveHardware={buildingManagerPageSlice.actions.updateActiveHardware}
                                allowedContent={draggableAllowedContentState}
                                doneCallBack={ (
                                    offsetXPct: number, offsetYPct: number, hardwareId: number,
                                    hardwareName: string, type: InternalBuildingLocationType) => {
                                    handleDragEndCallBack(offsetXPct, offsetYPct, hardwareId, hardwareName, type);
                                } } />
                            <HardwareLookup
                                hardwareList={hardwareList}
                                activeHardware={activeHardwareState}
                                dispatchUpdateActiveHardware={buildingManagerPageSlice.actions.updateActiveHardware}
                                hardwareType={HardwareTypeEnum.STATIC}
                                allowedContent={draggableAllowedContentState}
                                doneCallBack={ (
                                    offsetXPct: number, offsetYPct: number, hardwareId: number,
                                    hardwareName: string, type: InternalBuildingLocationType) => {
                                    handleDragEndCallBack(offsetXPct, offsetYPct, hardwareId, hardwareName, type);
                                } } />
                        </div>
                        <div className="layout-right-content-item withoutThird">
                            <BuildingManager
                                initialized={initializedState}
                                dispatchRemoveActives={buildingManagerPageSlice.actions.removeActives}
                                dispatchInitialized={buildingManagerPageSlice.actions.setInitialized}
                                saveDisabled={!anyChangesMadeState}
                                dispatchUpdateZoom={buildingManagerPageSlice.actions.updateZoomValue}
                                dispatchShowFullscreen={buildingManagerPageSlice.actions.showFullScreen}
                                dispatchRemoveHardwareBuildingPosition={buildingManagerPageSlice.actions.removeHardwareBuildingPosition}
                                dispatchUpdateActiveHardware={buildingManagerPageSlice.actions.updateActiveHardware}
                                dispatchUpdateDraggableAllowedContent={buildingManagerPageSlice.actions.updateDraggableAllowedContent}
                                dispatchAddFloor={buildingManagerPageSlice.actions.showBuildingPlanUploadModal}
                                allowedContent={draggableAllowedContentState}
                                building={buildingState.building}
                                currentZoom={zoomState}
                                activeHardware={activeHardwareState}
                                currentFloorElevation={floorElevationState}
                                currentFloorIndex={floorIndexState}
                                doneCallBack={ (
                                    offsetXPct: number, offsetYPct: number, hardwareId: number,
                                    hardwareName: string, type: InternalBuildingLocationType) => {
                                    handleDragEndCallBack(offsetXPct, offsetYPct, hardwareId, hardwareName, type);
                                } }
                                updateFloorCallback={(floor: number) => {
                                    BuildingUtil.updateUrlFromFloorChange (
                                        setParams,
                                        dispatch,
                                        floor,
                                        buildingManagerPageSlice.actions.updateFloorElevation
                                    );
                                }} />
                        </div>
                    </div>
                    <div className="layout-right-content">
                        <div className="layout-right-content-item">
                            <ListLog
                                pagination={{showCount: 8}} />
                        </div>
                    </div>
                </div>
            </div>
            {fullscreenShownState &&
                <BuildingFullScreen
                    dispatchHideModal={buildingManagerPageSlice.actions.hideFullScreen}
                    currentFloorIndex={floorIndexState}
                    building={buildingState.building} />
            }
            {uploadModalState &&
                <BuildingPlanUpload
                    dispatchHideModal={buildingManagerPageSlice.actions.hideBuildingPlanUploadModal}
                    floor={buildingState.building.floors.length + 1}
                    callbackUpdateFileInput={ async (file, sizeX, sizeY) => {
                        const result = await dispatch(actionAddFloorToBuildingPlan({
                            t: t,
                            buildingAddFloorRequest: {plan: file.fileValue, sizeXMeters: sizeX, sizeYMeters: sizeY}
                        }));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
                            return;
                        }
                    }} />
            }
        </main>
    );
}

export default BuildingManagerTemplate;
