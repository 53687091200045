import React from "react";
import "./SMSModal.scss"
import backArrow from "../../../images/backArrow.svg"
import {T, useTranslate} from "@tolgee/react";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import SMSMenu from "../SMSMenu/SMSMenu";

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const SMSModal = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const smsModalShown = useAppSelector((state) => state.main.smsModalShown);

    return (
        <div className="SMSModal">
            {smsModalShown &&
                <div className="SMSModal-navigation">
                    <div
                        onClick={ () => { dispatch(mainSlice.actions.smsModalHide()); }}
                        className="SMSModal-navigation-back">
                        <div className="SMSModal-navigation-back-icon">
                            <img src={backArrow} alt={t('SMSModal_back')}  />
                        </div>
                        <div className="SMSModal-navigation-back-text">
                            <T keyName={'SMSModal_back'} />
                        </div>
                    </div>
                    <div className="SMSModal-navigation-inside">
                        <SMSMenu dispatchHide={mainSlice.actions.smsModalHide} />
                    </div>
                </div>
            }
        </div>
    );
}

export default SMSModal;
