import { createAsyncThunk } from "@reduxjs/toolkit";
import {AlertDataResponseDto} from "../../../models/entities/Responses/AlertDataResponseDto";
import {AlertRepository} from "../../../models/repositories/AlertRepository";

/**
 * Get alert data by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} alertId - alert id
 * @return {AlertDataResponseDto[]|null}
 */
export const actionGetAlertData = createAsyncThunk<Array<AlertDataResponseDto> | null, number>(
    "alertGraph/getAlertData",
    async (alertId: number) => {
        return await AlertRepository.getAlertStatList(alertId);
    }
);