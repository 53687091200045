import { createAsyncThunk } from "@reduxjs/toolkit";
import {HardwareRepository} from "../../../models/repositories/HardwareRepository";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {HardwareRequestDto} from "../../../models/entities/Requests/HardwareRequestDto";
import {HardwareAssignRequestDto} from "../../../models/entities/Requests/HardwareAssignRequestDto";
import {PatientRepository} from "../../../models/repositories/PatientRepository";
import {HardwareTypeDto} from "../../../models/entities/HardwareTypeDto";
import {PatientWithHardwareListResponseDto} from "../../../models/entities/Responses/PatientWithHardwareListResponseDto";
import {HardwareBulkRequestDto} from "../../../models/entities/Requests/HardwareBulkRequestDto";
import {SensorResponseListResponseDto} from "../../../models/entities/Responses/SensorResponseListResponseDto";
import {ZoneRepository} from "../../../models/repositories/ZoneRepository";
import {PatientResponseDto} from "../../../models/entities/Responses/PatientResponseDto";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get hardware list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HardwareResponseDto[] | null}
 */
export const actionGetHardwareList = createAsyncThunk<Array<HardwareResponseDto> | null>(
    "hardware/getHardwareList",
    async () => {
        return await HardwareRepository.getHardwareList(true);
    }
);

/**
 * Get hardware list fresh
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HardwareResponseDto[] | null}
 */
export const actionGetHardwareListFresh = createAsyncThunk<Array<HardwareResponseDto> | null>(
    "hardware/getHardwareListFresh",
    async () => {
        return await HardwareRepository.getHardwareList(false);
    }
);

/**
 * Get hardware type list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HardwareTypeDto[] | null}
 */
export const actionGetHardwareTypesList = createAsyncThunk<Array<HardwareTypeDto> | null>(
    "hardware/getHardwareTypesList",
    async () => {
        return await HardwareRepository.getHardwareTypesList();
    }
);

/**
 * Delete hardware by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareId: number, t: TranslatorType}}
 * @return {HardwareResponseDto[] | null | string}
 */
export const actionDeleteHardware = createAsyncThunk<Array<HardwareResponseDto> | null | string,
    {hardwareId: number, t: TranslatorType}>(
    "hardware/deleteHardware",
    async (payload: {hardwareId: number, t: TranslatorType}, { rejectWithValue }) => {
        try {  await HardwareRepository.deleteHardware(payload.hardwareId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await HardwareRepository.getHardwareList(false);
    }
);

/**
 * Activate hardware by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareId: number, t: TranslatorType}}
 * @return {HardwareResponseDto[] | null | string}
 */
export const actionActivateHardware = createAsyncThunk<Array<HardwareResponseDto> | null | string,
    {hardwareId: number, t: TranslatorType}>(
    "hardware/activateHardware",
    async (payload: {hardwareId: number, t: TranslatorType}, { rejectWithValue }) => {
        try {  await HardwareRepository.activateHardware(payload.hardwareId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await HardwareRepository.getHardwareList(false);
    }
);

/**
 * Create new hardware
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareRequest: HardwareRequestDto, t: TranslatorType}}
 * @return {HardwareResponseDto[] | null}
 */
export const actionCreateHardware = createAsyncThunk<Array<HardwareResponseDto> | null | string,
    {hardwareRequest: HardwareRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "hardware/createHardware",
    async (payload: {hardwareRequest: HardwareRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await HardwareRepository.createHardware(payload.hardwareRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await HardwareRepository.getHardwareList(false);
    }
);

/**
 * Update hardware by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareId: number, hardwareRequest: HardwareRequestDto, t: TranslatorType}}
 * @return {HardwareResponseDto[] | null | string}
 */
export const actionUpdateHardware = createAsyncThunk<Array<HardwareResponseDto> | null | string,
    {hardwareId: number, hardwareRequest: HardwareRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "hardware/updateHardware",
    async (payload: {hardwareId: number, hardwareRequest: HardwareRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await HardwareRepository.updateHardware(payload.hardwareId, payload.hardwareRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await HardwareRepository.getHardwareList(false);
    }
);

/**
 * Assign hardware to patient
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareId: number, hardwareAssignRequest: HardwareAssignRequestDto, zoneId: number|null, patientId: number|null}} assignRequest - info to assign HW
 * @return {{hardwareList: HardwareResponseDto[]|null, patientList: PatientWithHardwareListResponseDto|null}|string}
 */
export const actionAssignHardware = createAsyncThunk<{
    hardwareList: Array<HardwareResponseDto>|null,
    patientList: PatientWithHardwareListResponseDto|null,
    zone: ZoneResponseDto|null|undefined,
    patient: PatientResponseDto|null|undefined
} |string, {
    hardwareId: number,
    hardwareAssignRequest: HardwareAssignRequestDto,
    zoneId: number|null,
    patientId: number|null
    t: TranslatorType
}, {rejectValue: string}>(
    "hardware/assignHardware",
    async (payload: {hardwareId: number, hardwareAssignRequest: HardwareAssignRequestDto, zoneId: number|null, patientId: number|null, t: TranslatorType},
           { rejectWithValue }) => {
        try { await HardwareRepository.assignHardware(payload.hardwareId, payload.hardwareAssignRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        const requestedZone = payload.hardwareAssignRequest.zone ?? payload.zoneId;
        const requestedPatient = payload.hardwareAssignRequest.patient ?? payload.patientId;
        return {
            zone: requestedZone ? await ZoneRepository.getZone(requestedZone) : undefined,
            patient: requestedPatient ? await PatientRepository.getPatient(requestedPatient) : undefined,
            hardwareList: await HardwareRepository.getHardwareList(false),
            patientList: await PatientRepository.getPatientList()
        }
    }
);

/**
 * Get sensor list for hardware ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} hardwareId - hardware ID
 * @return {SensorResponseListResponseDto | null}
 */
export const actionGetSensorList = createAsyncThunk<SensorResponseListResponseDto | null, number>(
    "hardware/getSensorList",
    async (hardwareId: number) => {
        return await HardwareRepository.getSensors(hardwareId);
    }
);

/**
 * Create new hardware BULK
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareBulkRequest: HardwareBulkRequestDto, t: TranslatorType}}
 * @return {HardwareResponseDto[] | null | string}
 */
export const actionCreateHardwareBulk = createAsyncThunk<Array<HardwareResponseDto> | null | string,
    {hardwareBulkRequest: HardwareBulkRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "hardware/createHardwareBulk",
    async (payload: {hardwareBulkRequest: HardwareBulkRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await HardwareRepository.createHardwareBulk(payload.hardwareBulkRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await HardwareRepository.getHardwareList(false);
    }
);
