import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionCreateUser, actionUpdateUser} from "../../actions/data/userAction";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface AddOrEditUserState extends AbstractFormReducersState {}

const initialState: AddOrEditUserState = {
    form: {
        inputs: [
            {name: 'firstName', validations: {required: true}},
            {name: 'lastName', validations: {required: true}},
            {name: 'phone', validations: {required: false}},
            {name: 'email', validations: {required: true, type: ValidatorItemType.EMAIL}},
            {name: 'branch', validations: {required: false}},
            {name: 'role', validations: {required: true}, value: null, invalid: true},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const addOrEditUserOrganismSlice = createSlice({
    name: 'addOrEditUserOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}},
    extraReducers: (builder) => {
        builder.addCase(actionCreateUser.fulfilled, (state) => {
            state.form.error = null;
        })
        builder.addCase(actionUpdateUser.fulfilled, (state) => {
            state.form.error = null;
        })
    }
})

export default addOrEditUserOrganismSlice.reducer