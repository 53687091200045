/**
 * @class
 * @category Utils
 */
import {LogEventDto} from "../entities/LogEventDto";
import {LogEventTypeEnum} from "../entities/Enums/LogEventTypeEnum";

export class LogUtil {

    /**
     * Convert log event to string value
     * @param {LogEventDto} logEvent - log event object
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static logEventIdToTextValue(logEvent: LogEventDto, t: any) : string {

        switch (logEvent.id) {
            case 101: return t('LogUtil_new_alert_triggered');
            case 102: return t('LogUtil_ongoing_alert');
            case 103: return t('LogUtil_alert_ended');
            case 104: return t('LogUtil_alert_resolved');
            case 105: return t('LogUtil_alert_unresolved');
            case 106: return t('LogUtil_alert_resolution_note_updated');
            case 201: return t('LogUtil_new_hardware_added');
            case 202: return t('LogUtil_hardware_updated');
            case 203: return t('LogUtil_hardware_disabled');
            case 204: return t('LogUtil_hardware_enabled');
            case 205: return t('LogUtil_hardware_assigned');
            case 206: return t('LogUtil_hardware_unassigned');
            case 301: return t('LogUtil_new_sensor_added');
            case 302: return t('LogUtil_sensor_updated');
            case 305: return t('LogUtil_sensor_data_received');
            case 401: return t('LogUtil_new_patient_added');
            case 402: return t('LogUtil_patient_updated');
            case 403: return t('LogUtil_patient_disabled');
            case 404: return t('LogUtil_patient_enabled');
            case 501: return t('LogUtil_new_user_added');
            case 502: return t('LogUtil_user_updated');
            case 503: return t('LogUtil_user_disabled');
            case 504: return t('LogUtil_user_enabled');
            case 505: return t('LogUtil_user_logged_in');
            default: return logEvent.text;
        }
    }

    /**
     * Convert log event type to string value
     * @param {LogEventDto} logEvent - log event object
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static logEventTypeToTextValue(logEvent: LogEventDto, t: any) : string {

        switch (logEvent.type) {
            case LogEventTypeEnum.ALERT: return t('Base_name_alert').toUpperCase();
            case LogEventTypeEnum.HARDWARE: return t('Base_name_hardware').toUpperCase();
            case LogEventTypeEnum.OTHER: return t('Base_name_other').toUpperCase();
            case LogEventTypeEnum.PATIENT: return t('Base_name_patient').toUpperCase();
            case LogEventTypeEnum.SENSOR: return t('Base_name_sensor').toUpperCase();
            case LogEventTypeEnum.USER: return t('Base_name_user').toUpperCase();
            default: return logEvent.type;
        }
    }
}