import {InputState} from "./AbstractFormReducers";
import {
    InternalBuilding,
    InternalBuildingBuilding,
    InternalBuildingFloor,
    InternalBuildingLocation,
    InternalBuildingLocationType
} from "./AbstractBuildingReducers";
import {HardwareLocationResponseDto} from "../entities/Responses/HardwareLocationResponseDto";
import {BuildingResponseDto} from "../entities/Responses/BuildingResponseDto";
import {BuildingFloorResponseDto} from "../entities/Responses/BuildingFloorResponseDto";
import {HardwareTypeEnum} from "../entities/Enums/HardwareTypeEnum";
import {ActionCreatorWithPayload, ThunkDispatch} from "@reduxjs/toolkit";
import {CustomDraggableAllowedContent} from "../../components/molecules/CustomDraggable/CustomDraggable";
import {HiveResponseDto} from "../entities/Responses/HiveResponseDto";
import {HivePatientResponseDto} from "../entities/Responses/HivePatientResponseDto";

/**
 * @class
 * @category Utils
 */
export class BuildingUtil {

    /**
     * Get floor from file upload input name
     * @function
     * @param {InputState} inputState - file upload input state
     */
    public static getFloorFromFileInputState(inputState: InputState) {
        return parseInt(inputState.name.split('-')[2])+1
    }

    /**
     * Get Location Type from Hardware Type
     * @function
     * @param {HardwareTypeEnum} hardwareType - hardware type
     */
    public static getInternalBuildingLocationTypeFromHardwareType(hardwareType: HardwareTypeEnum) : InternalBuildingLocationType {
        switch (hardwareType) {
            case HardwareTypeEnum.STATIC: return InternalBuildingLocationType.ORANGE;
            case HardwareTypeEnum.GATEWAY: return InternalBuildingLocationType.BLUE;
            case HardwareTypeEnum.REPEATER: return InternalBuildingLocationType.PINK;
            default: return InternalBuildingLocationType.DEFAULT;
        }
    }

    /**
     * Get hardware type from Location Type
     * @function
     * @param {InternalBuildingLocationType} locationType - internal location type
     */
    public static getHardwareTypeFromInternalBuildingLocation(locationType: InternalBuildingLocationType) : HardwareTypeEnum {
        switch (locationType) {
            case InternalBuildingLocationType.ORANGE: return HardwareTypeEnum.STATIC;
            case InternalBuildingLocationType.BLUE: return HardwareTypeEnum.GATEWAY;
            default: return HardwareTypeEnum.GATEWAY;
        }
    }

    /**
     * Calculate canvas dimensions for building map
     * @function
     * @param {number} currentZoom - actual zoom level
     * @param {boolean} original
     */
    public static getCanvasDimensions(currentZoom: number, original: boolean) : {width: number, height: number} {

        if (original) {
            if (window.innerWidth >= 1920) {
                return {width: 904, height: 703}
            } return {width: 805, height: 627};
        }

        if (window.innerWidth >= 1920) {
            return {width: 904 * currentZoom, height: 703 * currentZoom}
        } return {width: 805 * currentZoom, height: 627 * currentZoom};
    }

    /**
     * Get floor from hardware id using building info
     * @param {InternalBuildingBuilding} internalBuilding - internal building data
     * @param {number} hardwareId - hardware ID
     * @function
     */
    public static getFloorFromHardwareId(internalBuilding: InternalBuildingBuilding, hardwareId: number) : InternalBuildingFloor|null {

        const activeFloor = internalBuilding.floors.find((floor: InternalBuildingFloor) => {
            return floor.locations.findIndex((location: InternalBuildingLocation) => {
                return location.hardwareId === hardwareId;
            }) !== -1;
        });

        return activeFloor ? activeFloor : null;
    }

    /**
     * Get floor from patient using HIVE data
     * @param {HiveResponseDto} hive - hive data
     * @param {number} patientId - patient ID
     * @function
     */
    public static getFloorFromPatientId(hive: HiveResponseDto, patientId: number) : number|null {

        const currentPatient = hive.HivePatients.find((patient: HivePatientResponseDto) => { return patient.Patient.id === patientId; })
        return currentPatient ? currentPatient.Patient.calcLocBuildingFloorElevation : null;
    }

    /**
     * Convert BE result to internal building
     * @param {BuildingResponseDto} buildingResponse - building response from BE
     * @function
     */
    public static convertBuildingResponseBodyIntoInternalBuilding(buildingResponse: BuildingResponseDto) : InternalBuilding {

        return {
            building: {
                name: buildingResponse.name,
                transparency: buildingResponse.transparency,
                floors: buildingResponse.Floors.map((floor: BuildingFloorResponseDto) => {
                    return {
                        elevation: floor.elevation,
                        plan: floor.plan,
                        locations: floor.HardwareLocations.map((location: HardwareLocationResponseDto) => {
                            return {
                                active: false,
                                hardwareId: location.Hardware.id,
                                hardwareName: location.Hardware.internalId ?? location.Hardware.uid,
                                leftOffsetPct: location.HardwareLocation.planOffsetLeft,
                                topOffsetPct: location.HardwareLocation.planOffsetTop,
                                type: BuildingUtil.getInternalBuildingLocationTypeFromHardwareType(location.HardwareTemplate.type)
                            }
                        })
                    }
                })
            }
        };
    }

    /**
     * Generalized function to update allowed bounds
     * @param {HTMLImageElement} buildingImage - building image reference
     * @param {ThunkDispatch<any, any, any>} dispatch - dispatch
     * @param {ActionCreatorWithPayload<CustomDraggableAllowedContent>} dispatchUpdateDraggableAllowedContent - Redux action to update allowed bounds
     * @function
     */
    public static updateAllowedBounds(buildingImage: HTMLImageElement, dispatch: ThunkDispatch<any, any, any>,
        dispatchUpdateDraggableAllowedContent: ActionCreatorWithPayload<CustomDraggableAllowedContent>) {

        const bounding = buildingImage.getBoundingClientRect();
        dispatch(dispatchUpdateDraggableAllowedContent({
            topLeft: {x: bounding.left, y: bounding.top},
            bottomRight: {x: bounding.right, y: bounding.bottom},
            height: bounding.height,
            width: bounding.width,
        }));
    }

    /**
     * Increase current zoom using levels below
     * @param current - current zoom to be modified
     */
    public static calculateCurrentZoomIncrease(current: number) : number {

        if (current <= 0.125) { return 0.25; }
        else if (current <= 0.25) { return 0.5; }
        else if (current <= 0.5) { return 0.8; }
        else if (current <= 0.8) { return 1; }
        else if (current <= 1) { return 1.2; }
        else if (current <= 1.2) { return 1.5; }
        else if (current <= 1.5) { return 2; }
        else if (current <= 4) { return 4; }
        else return 1;
    }

    /**
     * Decrease zoom using levels below
     * @param current - current zoom
     */
    public static calculateCurrentZoomDecrease(current: number) : number {

        if (current >= 4) { return 2; }
        else if (current >= 2) { return 1.5; }
        else if (current >= 1.5) { return 1; }
        else if (current >= 1) { return 0.8; }
        else if (current >= 0.8) { return 0.5; }
        else if (current >= 0.5) { return 0.25; }
        else if (current >= 0.25) { return 0.125; }
        else if (current >= 0.125) { return 0.125; }
        else if (current < 0.125) { return 0.125; }
        else return 1;
    }

    /**
     * Update URL when floor changed
     * @param setParams - set parameters object from useSearchParams
     * @param dispatch - redux callback object
     * @param floor - current floor
     * @param dispatchChangeFloor - redux callback to change floor
     */
    public static updateUrlFromFloorChange(setParams: any, dispatch: any, floor: number,
        dispatchChangeFloor: ActionCreatorWithPayload<{floor: number}>) : void {

        dispatch(dispatchChangeFloor({floor: floor}));
        setParams({elevation: `${floor}`});
    }

    /**
     * Initialize floor on page init (this will have priority)
     * @param searchParams - search parameters object from useSearchParams
     * @param dispatch - redux callback object
     * @param dispatchChangeFloor - redux callback to change floor
     */
    public static initializeFloorFromUrl(searchParams: URLSearchParams, dispatch: any,
        dispatchChangeFloor: ActionCreatorWithPayload<{floor: number}>) : void {

        const elevation = searchParams.get("elevation");
        const isNumber = elevation !== null && !Number.isNaN(elevation);
        const floorFromUrl = elevation !== null && isNumber ? parseInt(elevation) : null;
        if (!floorFromUrl) { return; }
        dispatch(dispatchChangeFloor({floor: floorFromUrl}));
    }
}

