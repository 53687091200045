import { createAsyncThunk } from "@reduxjs/toolkit";
import {AuthorizationRepository} from "../../../models/repositories/AuthorizationRepository";
import {TokenResponseDto} from "../../../models/entities/Responses/TokenResponseDto";
import {FcmTokenRequestDto} from "../../../models/entities/Requests/FcmTokenRequestDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Log in user
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{ email: string, password: string, t: TranslatorType }} request - recovery request
 * @return {TokenResponseDto|string}
 */
export const actionLogIn = createAsyncThunk<TokenResponseDto|string, { email: string, password: string, t: TranslatorType }, {rejectValue: string}>(
    "loginBlock/logIn",
    async (payload: { email: string, password: string, t: TranslatorType }, { rejectWithValue }) => {
        try { return await AuthorizationRepository.logIn(payload.email, payload.password, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);

/**
 * Send Firebase API messaging token
 * @todo merge log in with this ?
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{fcmToken: FcmTokenRequestDto, authToken: string, t: TranslatorType}} request - token request
 * @return {void|string}
 */
export const actionSendFireBaseToken = createAsyncThunk<string|void,
    {fcmToken: FcmTokenRequestDto, authToken: string, t: TranslatorType}, {rejectValue: string}>(
    "loginBlock/sendFireBaseToken",
    async (payload: {fcmToken: FcmTokenRequestDto, authToken: string, t: TranslatorType}, { rejectWithValue }) => {
        try { await AuthorizationRepository.sendFirebaseMessagingToken(payload.fcmToken, payload.authToken, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);



