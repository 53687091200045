import {ErrorResponse} from "../entities/Responses/ErrorResponse";
import {CacheExpiry, Storage} from "./Storage";
import * as Sentry from "@sentry/react";
import * as axios from 'axios';
import {AxiosHeaderValue} from "axios";

/**
 * @enum {string}
 * @category Utils
 */
export enum RequestType {
    POST = "POST",
    GET = "GET",
    DELETE = "DELETE",
    PUT = "PUT"
}

/**
 * @interface
 * @category Utils
 */
export interface CommunicatorRequestParameters {

    /** Endpoint part after slash **/
    endpoint: string,
    /** Don't send auth header **/
    disableAuth?: boolean,
    /** Append body to request **/
    body?: object,
    /** Append query to request **/
    query?: object
    /** Set request type **/
    type?: RequestType,
    /** Timeout override **/
    timeout?: number,
    /** Force logout on unauthorized disable **/
    disableForceLogout?: boolean,
    /** Use custom bearer token **/
    forceAuthToken?: string,
    /** Cache info **/
    cache ?: {
        /** Delete keys from DB **/
        deleteKeys?: Array<string>,
        /** Key to key-based cache **/
        cacheKey?: string,
        /** Persist time (backup infinite) **/
        expiry?: CacheExpiry,
        /** Don't try to load from cache (backup works) **/
        useCache?: boolean,
    }
}

/**
 * @interface
 * @category Utils
 */
export interface CommunicatorResult {

    /** Just to know is everything ok **/
    ok: boolean,
    /** Returned data **/
    data?: any,
    /** Returned error from server **/
    apiError?: ErrorResponse,
    /** Some exception on FE **/
    libException?: any
    /** Result was cached **/
    cached?: boolean,
    /** Returned HTTP code **/
    httpCode?: number
}

interface RawAxiosHeaders { [key: string]: AxiosHeaderValue; }

/**
 * @class
 * @category Utils
 */
export class Communicator {

    /**
     * Check is correct result
     * @category Utils
     * @function
     * @param {number} status - HTTP code
     */
    private static validateStatus = (status: number) : boolean => { return status < 500; }

    /**
     * Perform request to API
     * @category Utils
     * @function
     * @param {CommunicatorRequestParameters} parameters - request info
     */
    public static async performRequest(parameters: CommunicatorRequestParameters) : Promise<CommunicatorResult> {

        // @ts-ignore
        const queryString = parameters.query ? '?' + Object.keys(parameters.query).map(key => key + '=' + parameters.query[key]).join('&') : '';

        // Init
        const url = process.env.REACT_APP_ENDPOINT + '/' + parameters.endpoint + queryString;
        const useCache = process.env.REACT_APP_CACHE === "yes";
        const timeout = parameters.timeout ?? (process.env.REACT_APP_TIMEOUT ? parseInt(process.env.REACT_APP_TIMEOUT) : 10000);

        // Try to load backup data
        let backupData = null;
        if (parameters.type === RequestType.GET && parameters.cache && parameters.cache.cacheKey) {
            const backupObject = Storage.getResultFromStorage(parameters.cache.cacheKey, true);
            backupData = backupObject ? backupObject.data : null;
        }

        // If requested to use cache globally and locally, also cacheKey is set try to load from cache
        if (parameters.type === RequestType.GET && useCache && parameters.cache && parameters.cache.cacheKey && parameters.cache.useCache) {
            const cacheObject = Storage.getResultFromStorage(parameters.cache.cacheKey);
            if (cacheObject) {
                const cachedData = cacheObject.data;
                cachedData.cached = true;
                cachedData.httpCode = undefined;
                console.info(`CACHED ${parameters.type} on ${url}`, parameters);
                return cachedData;
            }
        }

        // Try to get token
        let headers : RawAxiosHeaders = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        };

        const token = parameters.forceAuthToken ?? Storage.getToken();
        if (!parameters.disableAuth && token === null) {
            console.info(`EXPIRED ${parameters.type} on ${url}`, parameters)
            Storage.forcedLogOut();
            return {ok: false, data: null};
        } else { headers['Authorization'] =  'Bearer ' + token }

        try {

            // Request
            let response;
            if (parameters.type === RequestType.PUT) {
                response = await axios.default.put(url, parameters.body, {headers: headers, validateStatus: this.validateStatus, timeout: timeout});
            } else if (parameters.type === RequestType.DELETE) {
                response = await axios.default.delete(url,{headers: headers, validateStatus: this.validateStatus, timeout: timeout});
            } else if (parameters.type === RequestType.POST) {
                response = await axios.default.post(url, parameters.body, {headers: headers, validateStatus: this.validateStatus, timeout: timeout});
            } else if (parameters.type === RequestType.GET) {
                response = await axios.default.get(url, {headers: headers, validateStatus: this.validateStatus, timeout: timeout});
            }

            // Empty response
            if (!response) {
                const result : CommunicatorResult = {ok: false, cached: false, data: null};
                console.error(`Empty response calling ${parameters.type} on ${url}`, parameters)
                Sentry.captureMessage('Empty Axios response', {
                    extra: {'parameters': parameters},
                    tags: {endpoint: parameters.endpoint}
                });
                return result;
            }

            // Ok
            else if (response.status === 200) {
                const result : CommunicatorResult = {ok: true, data: response.data, httpCode: response.status, cached: false};
                console.info(`OK ${parameters.type} on ${url}`, parameters)
                if (parameters.cache && parameters.cache.cacheKey && parameters.cache.expiry) {
                    Storage.saveResultToStorage(parameters.cache.cacheKey, result, parameters.cache.expiry);
                }
                if (parameters.cache && parameters.cache.deleteKeys) {
                    Storage.clearResultFromCache(parameters.cache.deleteKeys);
                }
                return result;
            }

            // Wrong token
            else if (response.status === 401 && response.data && response.data.code) {
                if (response.data.code === 'INVALID_ACCESS_TOKEN' && !parameters.disableForceLogout) {
                    Storage.forcedLogOut();
                }
                return {apiError: response.data, ok: false, httpCode: response.status, cached: false, data: null};
            }

            // Client error
            else {
                const result : CommunicatorResult = {apiError: response.data, ok: false, httpCode: response.status, cached: false, data: null};
                console.error(`${response.status} ${parameters.type} on ${url}`, parameters)
                Sentry.captureMessage(response.statusText, {
                    extra: {'apiError': response.data, 'httpCode': response.status, 'parameters': parameters},
                    tags: {endpoint: parameters.endpoint}
                });
                return result;
            }
        }

        // Fatal error
        catch (error: any) {

            if (!error.status && backupData && parameters.type === RequestType.GET) {
                backupData.cached = true;
                backupData.httpCode = undefined;
                console.error(`Error ${parameters.type} on ${url} - using backup`, parameters, error)
                return backupData;
            }

            const result : CommunicatorResult = {libException: error, ok: false, data: null};
            console.error(`Error ${parameters.type} on ${url}`, parameters, result)
            Sentry.captureException(error, {
                extra: {'parameters': parameters},
                tags: {endpoint: parameters.endpoint}
            });
            return result;
        }
    }
}
