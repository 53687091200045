import React from "react";
import {useNavigate} from "react-router-dom";
import "./NavigationItem.scss"
import {Image} from "../../atoms/Image/Image";
import {useAppDispatch} from "../../../store/hooks";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {ImageType} from "../../../models/entities/Utils/Image";
import Badge from "../../atoms/Badge/Badge";

/** 
 * @alias NavigationItemPageLink
 * @category Components
 */
type NavigationItemPageLink = {

    /** Is currently displayed **/
    current ?: boolean,
    /** Navigation on click **/
    link: string,
}

/** 
 * @alias NavigationItemAction
 * @category Components
 */
type NavigationItemAction = {

    /** Click callback **/
    clickCallback?: EmptyFunc,
}

/** 
 * @alias NavigationItemImageSize
 * @category Components
 */
type NavigationItemImageSize = {

    /** Width **/
    width: number,
    /** Height **/
    height: number
}

type NavigationItemProps = {
    name: string,
    image: ImageType,
    imageSize?: NavigationItemImageSize,
    isPageLink?: NavigationItemPageLink
    isAction?: NavigationItemAction,

    badgeCount?: number,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {string} name - title for the item
 * @param {NavigationItemAction|undefined} isAction - if clicked on navigation item perform some action
 * @param {ImageType} image - link image
 * @param {NavigationItemImageSize|undefined} imageSize - override image size
 * @param {NavigationItemPageLink|undefined} isPageLink - if clicked perform page change
 * @param {number|undefined} badgeCount - show badge with count number
 */
export const NavigationItem = ({isAction, name, isPageLink, image, imageSize, badgeCount} : NavigationItemProps): React.JSX.Element|null => {

    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    const handleNavigatePageLink = (to: string) => {

        dispatch(mainSlice.actions.navigationMobileHide());
        navigation(to);
    }


    if (isPageLink) {
        return (
            <div className="NavigationItem">
                <div
                    onClick={ () => { handleNavigatePageLink(isPageLink.link); } }
                    className={`NavigationItem-link link ${isPageLink.current ? 'active' : ''}`}>
                    <div className="NavigationItem-link-image">
                        <Image source={image} description={name} size={imageSize}/>
                    </div>
                    <div className="NavigationItem-link-text link-text">
                        {name}
                    </div>
                    {badgeCount &&
                        <div className="NavigationItem-link-badge link-badge">
                            <Badge text={badgeCount.toString()} />
                        </div>
                    }
                </div>
            </div>
        );
    } else if (isAction) {
        return (
            <div className="NavigationItem" onClick={ () => { if (isAction.clickCallback) { isAction.clickCallback(); } } }>
                <div className={`NavigationItem-link link`}>
                    <div className="NavigationItem-link-image">
                        <Image source={image} description={name} size={imageSize}/>
                    </div>
                    <div className="NavigationItem-link-text link-text">
                        {name} {badgeCount ? badgeCount : ''}
                    </div>
                    {badgeCount &&
                        <div className="NavigationItem-link-badge link-badge">
                            <Badge text={badgeCount.toString()} />
                        </div>
                    }
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default NavigationItem;
