import React, {FormEvent, useEffect} from "react";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import "./AlertNoteModal.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {alertNoteModalOrganismSlice} from "../../../store/slices/organisms/alertNoteModalSlice";
import {UpdateAlertRequestDto} from "../../../models/entities/Requests/UpdateAlertRequestDto";
import {AlertResolutionStatusEnum} from "../../../models/entities/Enums/AlertResolutionStatusEnum";
import {actionUpdateAlertState} from "../../../store/actions/organisms/sensorStateAction";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {T, useTranslate} from "@tolgee/react";

type AlertNoteModalProps = {
    hideModalDispatch: ActionCreatorWithoutPayload,
    alertId: number,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} hideModalDispatch - callback to hide this modal
 * @param {number} alertId - ID of alert to update
 */
export const AlertNoteModal = ({hideModalDispatch, alertId} : AlertNoteModalProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.alertNoteModalOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.alertNoteModalOrganism));
    const noteState = useAppSelector((state) => selectInputState(state.alertNoteModalOrganism, 'note'));

    // Cleanup
    useEffect(() => {
        return () => { dispatch(alertNoteModalOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Edit profile submit
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!noteState || !noteState.value) {
            dispatch(alertNoteModalOrganismSlice.actions.setFormError({error: t('AlertNoteModal_missing_note')}));
            return;
        }

        const editAlertStateRequest: UpdateAlertRequestDto = {
            resolutionNote: noteState.value.toString(),
            resolutionStatus: AlertResolutionStatusEnum.RESOLVED
        }

        const result = await dispatch(actionUpdateAlertState({alertId: alertId, request: editAlertStateRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(hideModalDispatch());
        dispatch(alertNoteModalOrganismSlice.actions.setFormError({error: null}));
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('AlertNoteModal_successfully_resolved', {alertId: alertId})
        }));
    }

     return (
        <div className="AlertNoteModal">
            <div className="AlertNoteModal-content">
                <div className="AlertNoteModal-content-title">
                    <div className="AlertNoteModal-content-title-text">
                        <T keyName={'AlertNoteModal_title'} />
                    </div>
                    <div className="AddOrEditUser-content-title-close">
                        {hideModalDispatch &&
                            <Image
                                onClick={() => dispatch(hideModalDispatch())}
                                source={close}
                                description={t("Base_close_modal")}
                                size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AlertNoteModal-content-form">
                    <form onSubmit={handleSubmit.bind(this)}>
                        <div className="AlertNoteModal-content-form-inputs">
                            <InputText
                                placeholder={t('AlertNoteModal_placeholder')}
                                label={t('AlertNoteModal_label')}
                                inputName="note"
                                inputType={InputTextType.TEXTAREA}
                                inputState={noteState}
                                value={noteState?.value}
                                dispatchOnChange={alertNoteModalOrganismSlice.actions.updateInput}
                                dispatchOnFocus={alertNoteModalOrganismSlice.actions.setFocus}
                                dispatchOnBlur={alertNoteModalOrganismSlice.actions.removeFocus} />
                            {formErrorState && <span className="formError">{formErrorState}</span>}
                        </div>
                        <div className="AlertNoteModal-content-form-inputs-button">
                            <Button
                                text={t('AlertNoteModal_button')}
                                type={ButtonType.PRIMARY}
                                onClickDisabled={() => { dispatch(alertNoteModalOrganismSlice.actions.showInputValidStates()); }}
                                disabled={!formValidState}
                                isSubmit={true} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AlertNoteModal;
