import { createAsyncThunk } from "@reduxjs/toolkit";
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {HardwareTemplateRepository} from "../../../models/repositories/HardwareTemplateRepository";

/**
 * Get hardware template list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {HardwareTemplateResponseDto[] | null}
 */
export const actionGetHardwareTemplateList = createAsyncThunk<Array<HardwareTemplateResponseDto> | null>(
    "hardwareTemplate/getHardwareTemplateList",
    async () => {
        return await HardwareTemplateRepository.getHardwareTemplateList();
    }
);