import {createSlice} from '@reduxjs/toolkit'
import {
    actionDeleteAlert,
    actionGetAlertActiveCount,
    actionGetAlertList,
    actionReadAlert,
    actionSetActiveCount
} from "../../actions/data/alertAction";
import {actionUpdateAlertState} from "../../actions/organisms/sensorStateAction";
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface AlertState {

    /** List of alerts **/
    alertList?: Array<AlertResponseDto>|null,
    activeCount?: number|null,
}

const initialState: AlertState = {
    alertList: undefined,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const alertDataSlice = createSlice({
    name: 'alertData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionSetActiveCount.fulfilled, (state, {payload}) => {
            state.activeCount = payload;
        })
        builder.addCase(actionGetAlertActiveCount.fulfilled, (state, {payload}) => {
            state.activeCount = payload ? payload.count : null;
        })
        builder.addCase(actionGetAlertList.fulfilled, (state, {payload}) => {
            state.alertList = payload;
        })
        builder.addCase(actionDeleteAlert.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('alertDataSlice-actionDeleteAlert: wrong payload, skip!')
                return;
            }

            state.alertList = payload.alertListResponse;
            state.activeCount = payload.newCount ? payload.newCount.count : null;
        })
        builder.addCase(actionReadAlert.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('alertDataSlice-actionReadAlert: wrong payload, skip!')
                return;
            }

            state.alertList = payload.alertListResponse;
            state.activeCount = payload.newCount ? payload.newCount.count : null;
        })
        builder.addCase(actionUpdateAlertState.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('alertDataSlice-actionUpdateAlertState: wrong state or payload, skip!')
                return;
            }

            if (!state.alertList) { return; }
            for (let x = 0; x < state.alertList.length; x++) {
                if (state.alertList[x].Alert.id === payload.Alert.id) {
                    state.alertList[x].Alert = payload.Alert;
                    state.alertList[x].Sensor = payload.Sensor;
                }
            }
        })
    }
})

export default alertDataSlice.reducer
