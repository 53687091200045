import {createSlice} from '@reduxjs/toolkit'
import {HardwareTemplateResponseDto} from "../../../models/entities/Responses/HardwareTemplateResponseDto";
import {actionGetHardwareTemplateList} from "../../actions/data/hardwareTemplateAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareTemplateState {

    /** List of hardware templates **/
    hardwareTemplateList?: Array<HardwareTemplateResponseDto>|null,
}

const initialState: HardwareTemplateState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareTemplateDataSlice = createSlice({
    name: 'hardwareTemplateData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetHardwareTemplateList.fulfilled, (state, {payload}) => {
            state.hardwareTemplateList = payload;
        })
    }
})

export default hardwareTemplateDataSlice.reducer