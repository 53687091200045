import {Communicator, RequestType} from "../utils/Communicator";
import {AresResponseDto} from "../entities/Responses/AresResponseDto";
import {AresRequestDto} from "../entities/Requests/AresRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class AresRepository {

    /**
     * Get ares info
     * @param {AresRequestDto} aresRequest - ares request info
     * @param {TranslatorType} t - translator object
     */
    public static async getAres(aresRequest: AresRequestDto, t: TranslatorType) : Promise<AresResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `ares`,
            disableAuth: true,
            body : aresRequest,
        });

        if (response.ok) { return response.data; }
        if (response.apiError?.code === 'FETCH_ARES_FAILED') { throw new Error(t('AresRepository_getAresErrCompany')); }
        throw new Error(t('AresRepository_getAres'));
    }
}
