import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";
import {actionCreateHardwareBulk} from "../../actions/data/hardwareAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface BulkImportUploadState extends AbstractFormReducersState {
    busy: boolean
}

const initialState: BulkImportUploadState = {
    busy: false,
    form: {
        inputs: [
            {name: 'file', validations: {required: true, type: ValidatorItemType.CSV}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const bulkImportUploadOrganismSlice = createSlice({
    name: 'bulkImportUploadOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        destroyOrganism: (state: BulkImportUploadState) => {
            state.busy = false;
        }
    }},
    extraReducers: (builder) => {
        builder.addCase(actionCreateHardwareBulk.pending, (state) => { state.busy = true; })
        builder.addCase(actionCreateHardwareBulk.rejected, (state) => { state.busy = false; })
        builder.addCase(actionCreateHardwareBulk.fulfilled, (state) => { state.busy = false; })
    }
})

export default bulkImportUploadOrganismSlice.reducer