import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {createHardwarePageSlice} from "../../../store/slices/pages/createHardwarePageSlice";
import {useAppSelector} from "../../../store/hooks";
import {useTranslate} from "@tolgee/react";
import HardwareCreateDialog from "../../organisms/HardwareCreateDialog/HardwareCreateDialog";
import HardwareScanRequest from "../../organisms/HardwareScanRequest/HardwareScanRequest";
import BulkImportUpload from "../../organisms/BulkImportUpload/BulkImportUpload";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type CreateHardwareTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const CreateHardwareTemplate = ({role} : CreateHardwareTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const scanRequestState = useAppSelector((state) => state.createHardwarePage.scanRequested);
    const uploadModalState = useAppSelector((state) => state.createHardwarePage.uploadModal);
    const scannedDataState = useAppSelector((state) => state.createHardwarePage.scannedData);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('CreateHardwareTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('CreateHardwareTemplate_title')}
                        breadItems={[
                            {name: t('Navigation_hardware'), link: '/devices'},
                            {name: t('CreateHardwareTemplate_title'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <HardwareCreateDialog
                                scannedData={scannedDataState}
                                dispatchSetScannedData={createHardwarePageSlice.actions.setScannedData}
                                dispatchScanRequested={createHardwarePageSlice.actions.showScanModal}
                                dispatchShowBulkImportModal={createHardwarePageSlice.actions.showBulkUploadModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {scanRequestState &&
                <HardwareScanRequest
                    dispatchSetScannedData={createHardwarePageSlice.actions.setScannedData}
                    dispatchHideScanRequestModal={createHardwarePageSlice.actions.hideScanModal}/>
            }
            {uploadModalState &&
                <BulkImportUpload
                    dispatchHideModal={createHardwarePageSlice.actions.hideBulkUploadModal} />
            }
        </main>
    );
}

export default CreateHardwareTemplate;
