import React from "react";
import {CustomDraggableAllowedContent} from "../CustomDraggable/CustomDraggable";
import {useAppDispatch} from "../../../store/hooks";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {HiveUtil} from "../../../models/utils/HiveUtil";
import {DraggablePlanElement} from "./DraggablePlanElement";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {useTranslate} from "@tolgee/react";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type DraggablePlanElementHardwareComponentProps = {
    hardware: HardwareWithSensorsResponseDto,
    allowedContent: CustomDraggableAllowedContent,
    currentZoom: number,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    dispatchUpdateActiveHardware?: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {HardwareWithSensorsResponseDto} hardware - hardware data with sensors
 * @param {CustomDraggableAllowedContent} allowedContent - bounds for map
 * @param {number} currentZoom - actual zoom level
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - actual selected hardware
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardware - update selected hardware
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of zones
 */
export const DraggablePlanElementHardwareComponent = ({hardware, allowedContent, currentZoom, activeHardware,
    dispatchUpdateActiveHardware, sensorTypes, zoneList} : DraggablePlanElementHardwareComponentProps): React.JSX.Element|null => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    if (hardware.HardwareLocation === null) { return null; }
    const locationType = BuildingUtil.getInternalBuildingLocationTypeFromHardwareType(hardware.HardwareTemplate.type);

    const hiveMetas = HiveUtil.getHiveMetas({
        sensors : hardware.Sensors,
        sensorTypes : sensorTypes,
        t : t,
        zoneList: zoneList,
        tempBeatMode : false,
        patient : undefined,
        hw : hardware.Hardware
    });

    return (
        <DraggablePlanElement
            dragged={false}
            small={true}
            type={hiveMetas.type}
            zoom={currentZoom}
            allowedContent={allowedContent}
            active={hardware.Hardware.id === activeHardware?.id}
            onClick={ dispatchUpdateActiveHardware ? () => {
                dispatch(dispatchUpdateActiveHardware({
                    id: hardware.Hardware.id,
                    callerType: AbstractBuildingActiveType.FROM_BUILDING_PLAN
                }));
            } : undefined }
            name={hiveMetas.title}
            hardware={{
                id: hardware.Hardware.id,
                locationType: locationType,
                name: hiveMetas.title,
                lastCommunication: hardware.Hardware.communicatedAt
            }}
            bubble={{alert: hiveMetas.label, sensors: hiveMetas.sensors}}
            location={{
                leftOffsetPct: hardware.HardwareLocation.planOffsetLeft,
                topOffsetPct: hardware.HardwareLocation.planOffsetTop,
            }}
        />
    )
}

export default DraggablePlanElementHardwareComponent;
