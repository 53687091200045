import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface LoginBlockState extends AbstractFormReducersState {}

const initialState: LoginBlockState = {
    form: {
        inputs: [
            {name: 'email', validations: {required: true, type: ValidatorItemType.EMAIL}},
            {name: 'password', validations: {required: true, minLength: 8}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const loginBlockOrganismSlice = createSlice({
    name: 'loginBlockOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}}
})

export default loginBlockOrganismSlice.reducer