import {createSlice} from '@reduxjs/toolkit'
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {actionRefreshPatientSensors} from "../../actions/organisms/patientSensorsAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface PatientSensorsSlice {
    patientSensors?: Array<SensorResponseDto>|null,

}

const initialState: PatientSensorsSlice = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const patientSensorsOrganismSlice = createSlice({
    name: 'patientSensorsOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state: PatientSensorsSlice) => {
            state.patientSensors = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionRefreshPatientSensors.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('patientSensorsOrganismSlice-actionRefreshPatientSensors: wrong payload, skip!')
                return;
            }

            state.patientSensors = payload;
        })
    }
})

export default patientSensorsOrganismSlice.reducer
