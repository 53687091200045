import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {CreateSupport} from "../../organisms/CreateSupport/CreateSupport";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type SupportTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - current user role
 */
export const SupportTemplate = ({role} : SupportTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('SupportTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('SupportTemplate_title')}
                        breadItems={[
                            {name: t('Navigation_support'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item half fullHeight">
                            <CreateSupport />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default SupportTemplate;
