import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AbstractFormReducersState, AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface BoxHiveState extends AbstractFormReducersState {

    /** Hive box full text value to search **/
    search: string|null
}

const initialState: BoxHiveState = {
    search: null,
    form: {inputs: [{name: 'search', validations: {required: false}},]}
};

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const boxHiveOrganismSlice = createSlice({
    name: 'boxHiveOrganism',
    initialState,
    reducers: {
        updateSearchValue: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.search = action.payload.inputValue ? action.payload.inputValue.toString() : null;
        }
    },
})

export default boxHiveOrganismSlice.reducer