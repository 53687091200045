import React, {FormEvent, useEffect} from "react";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import "./ResetBlock.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {resetBlockOrganismSlice} from "../../../store/slices/organisms/resetBlockSlice";
import {actionResetPassword} from "../../../store/actions/organisms/resetBlockAction";
import {useNavigate} from "react-router-dom";
import {T, useTranslate} from "@tolgee/react";
import {PasswordSetRequestDto} from "../../../models/entities/Requests/PasswordSetRequestDto";
import {Storage} from "../../../models/utils/Storage";
import * as Sentry from "@sentry/react";

type ResetBlockProps = {
    hash: string,
    email: string,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {string} hash - request hash
 * @param {string} email - request email
 */
export const ResetBlock = ({hash, email}: ResetBlockProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const formValidState = useAppSelector((state) => selectValidState(state.resetBlockOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.resetBlockOrganism));
    const passwordAgain = useAppSelector((state) => selectInputState(state.resetBlockOrganism, 'passwordAgain'));
    const password = useAppSelector((state) => selectInputState(state.resetBlockOrganism, 'password'));

    // Cleanup
    useEffect(() => {
        return () => { dispatch(resetBlockOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t]);

    // Submit reset
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!passwordAgain || !passwordAgain.value || !password || !password.value) {
            dispatch(resetBlockOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const request : PasswordSetRequestDto = {
            newPassword: password.value.toString(),
            resetHash: hash,
            email: email,
        };

        const result = await dispatch(actionResetPassword({request: request, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(resetBlockOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        if (result.payload && typeof result.payload !== 'string') {
            Storage.saveSession(result.payload);
            navigation('/dashboard/');
        } else {
            Sentry.captureMessage("ResetBlock: actionResetPassword returns wrong payload!", {
                extra: {'payload': result.payload},
            });
        }
    }

    return (
       <div className="ResetBlock">
           <div className="ResetBlock-header">
               <T keyName={'ResetBlock_title'} />
           </div>
           <div className="ResetBlock-description">
               <T keyName={'ResetBlock_subtitle'} />
           </div>
           <div className="ResetBlock-form">
               <form onSubmit={handleSubmit.bind(this)}>
                   <div className="ResetBlock-form-inputs">
                       <InputText
                           placeholder={t('ResetBlock_password_placeholder')}
                           inputName="password"
                           label={t('ResetBlock_password_label')}
                           inputState={password}
                           value={password?.value}
                           inputType={InputTextType.PASSWORD}
                           dispatchOnChange={resetBlockOrganismSlice.actions.updateInput}
                           dispatchOnFocus={resetBlockOrganismSlice.actions.setFocus}
                           dispatchOnBlur={resetBlockOrganismSlice.actions.removeFocus} />
                       <InputText
                           placeholder={t('ResetBlock_passwordAgain_placeholder')}
                           inputName="passwordAgain"
                           value={passwordAgain?.value}
                           label={t('ResetBlock_passwordAgain_label')}
                           inputState={passwordAgain}
                           inputType={InputTextType.PASSWORD}
                           dispatchOnChange={resetBlockOrganismSlice.actions.updateInput}
                           dispatchOnFocus={resetBlockOrganismSlice.actions.setFocus}
                           dispatchOnBlur={resetBlockOrganismSlice.actions.removeFocus} />
                       {formErrorState && <span className="formError">{formErrorState}</span>}
                   </div>
                   <div className="ResetBlock-form-button">
                       <Button
                           options={{textCentered: true, positionCentered: true}}
                           text={t('ResetBlock_button')}
                           type={ButtonType.PRIMARY}
                           onClickDisabled={() => { dispatch(resetBlockOrganismSlice.actions.showInputValidStates()); }}
                           disabled={!formValidState}
                           isSubmit={true} />
                   </div>
               </form>
           </div>
           <div className="ResetBlock-logIn" onClick={() => navigation('/')}>
               <T keyName={'ResetBlock_logIn'} />
           </div>
       </div>
    );
}

export default ResetBlock;
