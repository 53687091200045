import "./EasyList.scss"
import {CustomDraggable, CustomDraggableAllowedContent, CustomDraggableDoneCallback} from "../CustomDraggable/CustomDraggable";
import React, {useCallback, useEffect, useRef} from "react";
import arrowUpIcon from "../../../images/arrowUpSmall.svg";
import arrowDownIcon from "../../../images/arrowDownSmall.svg";
import searchIcon from "../../../images/search.svg";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {InternalBuildingLocationType} from "../../../models/utils/AbstractBuildingReducers";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {InputCheckbox} from "../../atoms/InputCheckbox/InputCheckbox";
import {DraggablePlanElementEasyListComponent} from "../DraggablePlanElement/DraggablePlanElementEasyListComponent";
import {useTranslate} from "@tolgee/react";

/**
 * @interface
 * @category Components
 */
export interface EasyListItem {

    /** Item title **/
    title: string,
    /** Item DB id **/
    id: number,
    /** Internal building element type **/
    type: InternalBuildingLocationType,
    /** Element is active **/
    active?: boolean,
    /** Item was not activated in easy list **/
    activatedOutside?: boolean,
    /** Element is on map **/
    placed?: boolean,
    /** Also search in this strings **/
    searchTags: Array<string|null|undefined>,
}

/**
 * @alias EasyListDragging
 * @category Components
 */
type EasyListDragging = {

    /** Allowed content bounds **/
    allowedContent: CustomDraggableAllowedContent|null,
    /** Drag done callback **/
    doneCallBack: CustomDraggableDoneCallback,
}

type EasyListProps = {
    title: string,
    type: InternalBuildingLocationType,
    items: Array<EasyListItem>|undefined|null,
    clickCallBack: (id: number) => void,
    startCallBack: (id: number) => void,
    dragging: EasyListDragging,
    initialStateOpened?: boolean
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {EasyListDragging} dragging - dragging data and settings
 * @param {string} title - box title
 * @param {EasyListItem[]|undefined|null} items - list of all box items
 * @param {InternalBuildingLocationType} type - box type (used as color preference)
 * @param {EmptyFunc} clickCallBack - callback on click
 * @param {EmptyFunc} startCallBack - callback on drag start
 * @param {boolean|undefined} initialStateOpened - opened on init
 */
export const EasyList = ({dragging, title, items, type, clickCallBack, startCallBack, initialStateOpened} : EasyListProps): React.JSX.Element => {

    const SCROLL_ACTIVE_OFFSET: number = 13;
    const ACTIVE_ELEMENT_CLASS: string = 'current';
    const [minimized, setMinimized] = React.useState<boolean>(initialStateOpened ? !initialStateOpened : true);
    const [search, setSearch] = React.useState<string|null>(null);
    const [showJustFree, setShowJustFree] = React.useState<boolean>(false);
    const {t, isLoading} = useTranslate();
    const itemsContainerRef = useRef<HTMLDivElement|null>(null);

    useEffect(() => {

        const activeItem = items ? items.find((item: EasyListItem) => item.active === true) : null;
        setMinimized(activeItem ? false : minimized);

        const containerElement = itemsContainerRef.current;
        if (activeItem && activeItem.activatedOutside && containerElement) {
            const activeElements = containerElement.getElementsByClassName(ACTIVE_ELEMENT_CLASS);
            const activeElement = activeElements.item(0) as HTMLElement;
            if (activeElement) { containerElement.scrollTop = activeElement.offsetTop - SCROLL_ACTIVE_OFFSET; }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);


    // Search callback
    const updateSearchCallback = useCallback((inputName: string, inputValue: string|null|number) => {
        setSearch(inputValue ? inputValue.toString() : null);
    }, []);

    // Filter callback
    const updateFilterCallback = useCallback((inputName: string, checked: boolean) => {
        setShowJustFree(checked);
    }, []);

    // Get easy list current item used in draggable
    const getEasyListElement = (item: EasyListItem, dragged: boolean) : React.JSX.Element => {
        return (
            <DraggablePlanElementEasyListComponent dragged={dragged} item={item} />
        )
    }

    // Filter easy list items
    const filterItems = (easyListItems: Array<EasyListItem>) : Array<EasyListItem> => {

        return easyListItems.filter((item: EasyListItem) => {

            // If requested to be placed and not placed
            if (showJustFree && !item.placed) { return false; }

            // If title contains search or search tags contains search
            return !((search && search.length > 0)
                && !(
                    item.title.toLowerCase().includes(search.toLowerCase())
                    || item.searchTags.find((tag: string | undefined | null) => {
                        return tag?.toLowerCase().includes(search);
                    })
                ));
        })
    }

    if (dragging.allowedContent === null || typeof items === "undefined" || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.CARD} />
        )
    }

    if (items === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_list')})} />
        )
    }

    const allowedContent = dragging.allowedContent;
    const filteredItems = filterItems(items);

    return (
        <div className={`EasyList ${minimized ? 'minimized' : ''} ${type}`}>
            <div className={`EasyList-content`}>
                <div className={`EasyList-content-title`}>
                    <div className={`EasyList-content-title-text`}>
                        {title}
                    </div>
                    <div
                        onClick={ () => { setMinimized(!minimized); } }
                        className={`EasyList-content-title-minimize`}>
                        <img
                            src={!minimized ? arrowUpIcon : arrowDownIcon}
                            alt={!minimized ? t('Base_minimize') : t('Base_maximize')} />
                    </div>
                </div>
                <div className={`EasyList-content-search`}>
                    <InputText
                        inputState={null}
                        value={search}
                        hasClear={true}
                        withoutValidations={true}
                        flat={true}
                        image={{source: searchIcon, title: t('Base_search')}}
                        inputName={'search'}
                        inputType={InputTextType.TEXT}
                        onChange={updateSearchCallback}
                        placeholder={t('Base_search')} />
                </div>
                <div className={`EasyList-content-inside`}>
                    <div className={`EasyList-content-inside-sub`} ref={itemsContainerRef}>
                        {filteredItems.map((item: EasyListItem, index: number) => {
                            return (
                                <CustomDraggable
                                    name={item.title}
                                    id={item.id}
                                    clickCallBack={clickCallBack}
                                    type={item.type}
                                    key={`EasyList-content-inside-sub-${index}`}
                                    startCallBack={startCallBack}
                                    doneCallBack={dragging.doneCallBack}
                                    allowedContent={allowedContent}
                                    mainContent={getEasyListElement(item, false)}
                                    draggedContent={getEasyListElement(item, true)}/>
                            )
                        })}
                    </div>
                </div>
                <div className={`EasyList-content-filterFree`}>
                    <div className={`EasyList-content-filterFree-check`}>
                        <InputCheckbox
                            label={t('EasyList_show_free')}
                            inputName={'freeHardware'}
                            onChangeCallBack={updateFilterCallback} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EasyList;
