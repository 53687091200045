import React from "react";
import {Header} from "../../organisms/Header/Header";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {HardwareStates} from "../../organisms/HardwareStates/HardwareStates";
import {HardwareGraphs} from "../../organisms/HardwareGraphs/HardwareGraphs";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {hardwareDetailPageSlice} from "../../../store/slices/pages/hardwareDetailPageSlice";
import {useAppSelector} from "../../../store/hooks";
import {EditSensor} from "../../organisms/EditSensor/EditSensor";
import {HardwareInfo} from "../../organisms/HardwareInfo/HardwareInfo";
import {ListLog} from "../../organisms/ListLog/ListLog";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {buildingObserverPageSlice} from "../../../store/slices/pages/buildingObserverSlice";
import {buildingManagerPageSlice} from "../../../store/slices/pages/buildingManagerSlice";
import AddOrEditHardware from "../../organisms/AddOrEditHardware/AddOrEditHardware";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";
import HardwareQRGen from "../../organisms/HardwareQRGen/HardwareQRGen";
import {SensorResponseListResponseDto} from "../../../models/entities/Responses/SensorResponseListResponseDto";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type HardwareDetailTemplateProps = {
    hardwareData: HardwareResponseDto,
    sensorsData: SensorResponseListResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {HardwareResponseDto} hardwareData - hardware list data
 * @param {SensorResponseListResponseDto} sensorsData - sensor data
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types for HW
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {RoleEnum} role - role from session for user
 */
export const HardwareDetailTemplate = ({hardwareData, sensorsData, sensorTypes, zoneList, role}: HardwareDetailTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const qrGenModalShownState = useAppSelector((state) => state.hardwareDetailPage.qrGenModalShown);
    const editSensorModalShownState = useAppSelector((state) => state.hardwareDetailPage.editSensorModalShown);
    const editSensorModalSensorIdState = useAppSelector((state) => state.hardwareDetailPage.editSensorModalSensorId);
    const editSensorModalHardwareIdState = useAppSelector((state) => state.hardwareDetailPage.editSensorModalHardwareId);
    const editHardwareModalShownState = useAppSelector((state) => state.hardwareDetailPage.editHardwareModalShown);
    const editHardwareModalHardwareIdState = useAppSelector((state) => state.hardwareDetailPage.editHardwareModalHardwareId);
    const hardwareTemplateState = useAppSelector((state) => selectInputState(state.hardwareDetailPage, 'hardwareTemplate'));
    const modelState = useAppSelector((state) => selectInputState(state.hardwareDetailPage, 'model'));
    const uidState = useAppSelector((state) => selectInputState(state.hardwareDetailPage, 'uid'));
    const internalIdState = useAppSelector((state) => selectInputState(state.hardwareDetailPage, 'internalId'));
    const formValidState = useAppSelector((state) => selectValidState(state.hardwareDetailPage));
    const formErrorState = useAppSelector((state) => selectFormError(state.hardwareDetailPage));
    const title = t('HardwareDetailTemplate_title', {hardwareName: hardwareData.Hardware.internalId ?? hardwareData.Hardware.uid});

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    <title>
                        {title}
                    </title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={title}
                        breadItems={[
                            {name: t('Navigation_hardware'), link: '/devices'},
                            {name: title, link: null},
                        ]}/>
                    <div className="layout-right-content">
                        <div className="layout-right-content-item">
                            <HardwareInfo
                                dispatchShowQRGenModal={hardwareDetailPageSlice.actions.showQRGenModal}
                                dispatchShowAddOrEditHardwareModal={hardwareDetailPageSlice.actions.showEditHardwareModal}
                                dispatchUpdateActiveHardwarePlan={buildingObserverPageSlice.actions.updateActiveHardware}
                                dispatchUpdateActiveHardwareEditor={buildingManagerPageSlice.actions.updateActiveHardware}
                                role={role}
                                hardware={hardwareData}/>
                        </div>
                        <div className="layout-right-content-item">
                            <HardwareStates
                                zoneList={zoneList}
                                sensorTypes={sensorTypes}
                                hardware={hardwareData.Hardware}
                                dispatchShowSensorEditModal={hardwareDetailPageSlice.actions.showEditSensorModal}
                                sensorsData={sensorsData}/>
                        </div>
                        <div className="layout-right-content-item">
                            <HardwareGraphs
                                zoneList={zoneList}
                                sensorsData={sensorsData.Sensors} />
                        </div>
                        <div className="layout-right-content-item">
                            <ListLog
                                pagination={{showCount: 8}}
                                hardware={hardwareData.Hardware} />
                        </div>
                    </div>
                </div>
            </div>
            {editSensorModalShownState && editSensorModalSensorIdState && editSensorModalHardwareIdState &&
                <EditSensor
                    role={role}
                    dispatchHideModal={hardwareDetailPageSlice.actions.hideEditSensorModal}
                    sensorId={editSensorModalSensorIdState}
                    hardwareId={editSensorModalHardwareIdState} />
            }
            {editHardwareModalShownState && editHardwareModalHardwareIdState &&
                <AddOrEditHardware
                    isModal={true}
                    hardwareId={editHardwareModalHardwareIdState}
                    formState={{
                        hardwareTemplateState: hardwareTemplateState,
                        modelState: modelState,
                        uidState: uidState,
                        internalIdState: internalIdState,
                        formValidState: formValidState,
                        formErrorState: formErrorState,
                        clearForm: hardwareDetailPageSlice.actions.clearForm,
                        setFormError: hardwareDetailPageSlice.actions.setFormError,
                        clearFormErrors: hardwareDetailPageSlice.actions.clearFormErrors,
                        updateInput: hardwareDetailPageSlice.actions.updateInput,
                        showInputError: hardwareDetailPageSlice.actions.setFocus,
                        hideInputError: hardwareDetailPageSlice.actions.removeFocus,
                        showInputValidStates: hardwareDetailPageSlice.actions.showInputValidStates,
                    }}
                    dispatchHideAddOrEditHardwareModal={hardwareDetailPageSlice.actions.hideEditHardwareModal} />
            }
            {qrGenModalShownState &&
                <HardwareQRGen
                    dispatchHideModal={hardwareDetailPageSlice.actions.hideQRGenModal}
                    hardware={hardwareData} />
            }
        </main>
    );
}

export default HardwareDetailTemplate;
