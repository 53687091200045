import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import GoogleAuth from "../../organisms/GoogleAuth/GoogleAuth";
import "./AccountTemplate.scss"
import {useAppSelector} from "../../../store/hooks";
import GoogleAuthCodes from "../../organisms/GoogleAuthCodes/GoogleAuthCodes";
import {accountsPageSlice} from "../../../store/slices/pages/accountPageSlice";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type AccountTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const AccountTemplate = ({role} : AccountTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const recoveryCodesModalState = useAppSelector((state) => state.accountsPage.recoveryCodesModalShown);

    return (
        <main>
            <div className="layout GoogleAuthTemplate">
                <Helmet>
                    <title>{t('AccountTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('AccountTemplate_title')}
                        breadItems={[
                            {name: t('Header_2fa'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fullHeight fill">
                            <div className={'layout-right-content-item-card'}>
                                <div className={'layout-right-content-item-card-content'}>
                                    <GoogleAuth
                                        dispatchShowRecoveryCodesModal={accountsPageSlice.actions.showRecoveryCodesModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {recoveryCodesModalState &&
                <GoogleAuthCodes
                    dispatchHideModal={accountsPageSlice.actions.hideRecoveryCodesModal} />
            }
        </main>
    );
}

export default AccountTemplate;
