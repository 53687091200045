/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum AlertResolutionStatusEnum {

    UNRESOLVED = 0,
    RESOLVED = 1,
}
