import "./Toastify.scss"
import ok from "../../../images/ok.svg";
import nok from "../../../images/nok.svg";
import notify from "../../../images/notifyBlue.svg";
import {T} from "@tolgee/react";
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import backArrow from "../../../images/backArrow.svg"
import {NavigateFunction} from "react-router/dist/lib/hooks";

/**
 * @enum {string}
 * @category Components
 */
export enum ToastifyType {
    SUCCESS = 'success',
    NOTIFY = 'notify',
    ERROR = 'error'
}

/**
 * @interface ButtonOptions
 * @category Components
 */
export interface ToastifyNavigation {

    /** URL address without host to redirect **/
    address: string,
    /** Use navigation hook **/
    navigateHook: NavigateFunction
}

type ToastifyProps =  {
    message: string;
    type: ToastifyType;
    title?: string;
    subtitle?: string|null;
    navigation?: ToastifyNavigation | null;
}

/**
 * @component
 * @category Components
 * @subcategory Extras
 * @todo translation throw error if used hook
 * @param {string} message - notification message
 * @param {ToastifyType} type - notification type
 * @param {string|undefined} title - notification title message
 * @param {string|undefined} subtitle - notification subtitle message
 * @param {ToastifyNavigation|undefined|null} navigation - redirect to detail
 */
export const Toastify = ({message, type, title, subtitle, navigation}: ToastifyProps): React.JSX.Element => {

    return (
        <div className={`Toastify ${type}`}>
            <div className="Toastify-title">
                <div className="Toastify-title-image">
                    {type === ToastifyType.SUCCESS &&
                        <img src={ok} alt={'Success'}/>
                    }
                    {type === ToastifyType.NOTIFY &&
                        <img src={notify} alt={'Notify'}/>
                    }
                    {type === ToastifyType.ERROR &&
                        <img src={nok} alt={'Error'}/>
                    }
                </div>
                <div className="Toastify-title-text">
                    {type === ToastifyType.ERROR && title && <span>{title ?? '-'}</span>}
                    {type === ToastifyType.ERROR && !title && <T keyName={'Toastify_error'} /> }
                    {type === ToastifyType.NOTIFY && <span>{title ?? '-'}</span> }
                    {type === ToastifyType.SUCCESS && <T keyName={'Toastify_success'} /> }
                </div>
            </div>
            {subtitle &&
                <div className="Toastify-subtitle">
                    <div className="Toastify-subtitle-text">
                        <>{ReactHtmlParser(subtitle)}</>
                    </div>
                </div>
            }
            <div className="Toastify-description">
                <>{ReactHtmlParser(message)}</>
                {navigation &&
                    <div className={'Toastify-description-link'}
                         onClick={ () => { if (navigation) { navigation.navigateHook(navigation.address); }} }>
                        <div className={'Toastify-description-link-text'} >
                            <T keyName={'Toastify_detail'} />
                        </div>
                        <div className={'Toastify-description-link-icon'} >
                            <img src={backArrow} alt={'Detail'}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Toastify;
