import React, {FormEvent, useEffect} from "react";
import "./AddOrEditPatient.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {DataParser} from "../../../models/utils/DataParser";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {actionCreatePatient, actionGetPatient, actionGetPatientList, actionUpdatePatient} from "../../../store/actions/data/patientAction";
import {PatientRequestDto} from "../../../models/entities/Requests/PatientRequestDto";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {useNavigate} from "react-router-dom";
import {CustomDate} from "../../atoms/CustomDate/CustomDate";
import {addOrEditPatientOrganismSlice} from "../../../store/slices/organisms/addOrEditPatientSlice";
import {useTranslate} from "@tolgee/react";

type AddOrEditPatientProps = {
    patientId: number|null,
    dispatchHideAddOrEditPatientModal: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideAddOrEditPatientModal - hide modal Redux callback
 * @param {number|null} patientId - patient id for edit mode
 */
export const AddOrEditPatient = ({dispatchHideAddOrEditPatientModal, patientId} : AddOrEditPatientProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const {t, isLoading} = useTranslate();

    // Data state
    const patientListState = useAppSelector((state) => state.patientData.patientList);

    // Form state
    const firstNameState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'firstName'));
    const lastNameState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'lastName'));
    const dateOfBirthState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'dateOfBirth'));
    const placeOfBirthState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'placeOfBirth'));
    const roomState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'location'));
    const residenceState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'residence'));
    const phoneState = useAppSelector((state) => selectInputState(state.addOrEditPatientOrganism, 'phone'));
    const formValidState = useAppSelector((state) => selectValidState(state.addOrEditPatientOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.addOrEditPatientOrganism));

    // Re-load data && Destroy
    useEffect(() => { if (!patientListState) { dispatch(actionGetPatientList()); } }, [dispatch, patientListState])
    useEffect(() => {
        return () => { dispatch(addOrEditPatientOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!firstNameState || !firstNameState.value || !lastNameState || !lastNameState.value) {
            dispatch(addOrEditPatientOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const patientRequest : PatientRequestDto = {
            room: roomState && roomState.value ? roomState.value.toString() : null,
            residence: residenceState && residenceState.value ? residenceState.value.toString() : null,
            phone: phoneState && phoneState.value ? phoneState.value.toString() : null,
            firstName: firstNameState.value.toString(),
            lastName: lastNameState.value.toString(),
            dateOfBirth: dateOfBirthState?.value?.toString() ?? null,
            placeOfBirth: placeOfBirthState?.value?.toString() ?? null,
        }

        let result;
        if (patientId) {
            result = await dispatch(actionUpdatePatient({patientId: patientId, patientRequest: patientRequest, t: t}));
        } else {
            result = await dispatch(actionCreatePatient({patientRequest: patientRequest, t:t }));
            if (result.meta.requestStatus === "fulfilled" && result.payload && typeof result.payload === "object" && result.payload.createdPatient) {
                navigation(`/patient/${result.payload.createdPatient.Patient.id}`);
                return;
            }
        }

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(addOrEditPatientOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        if (patientId) { dispatch(actionGetPatient(patientId)); }
        dispatch(dispatchHideAddOrEditPatientModal());
    }

    // Prepare data for usage
    const parsedPatient = DataParser.getPatientFromList(patientListState, patientId);

    // Loading hardware
    if ((patientId && typeof parsedPatient === 'undefined') || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('AddOrEditPatient_title')}
                modal={{dispatchHide: dispatchHideAddOrEditPatientModal}}/>
        )
    }

    // Error state
    if (patientId && parsedPatient === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideAddOrEditPatientModal ? {
                    name: t('AddOrEditPatient_title'),
                    dispatchHide: dispatchHideAddOrEditPatientModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_patient')})} />
        )
    }

    const form = (
        <form onSubmit={handleSubmit.bind(this)}>
            <div className="AddOrEditPatient-content-form-inputs">
                <div className="AddOrEditPatient-content-form-inputs-flex">
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditPatient_firstName_placeholder')}
                        inputName="firstName"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_firstName_label')}
                        disabled={false}
                        startValue={parsedPatient ? parsedPatient.firstName : null}
                        value={firstNameState?.value}
                        inputState={firstNameState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                    <InputText
                        required={true}
                        placeholder={t('AddOrEditPatient_lastName_placeholder')}
                        inputName="lastName"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_lastName_label')}
                        disabled={false}
                        startValue={parsedPatient ? parsedPatient.lastName : null}
                        value={lastNameState?.value}
                        inputState={lastNameState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                </div>
                <div className="AddOrEditPatient-content-form-inputs-flex">
                    <CustomDate
                        defaultValue={parsedPatient ? parsedPatient.dateOfBirth : null}
                        value={dateOfBirthState?.value}
                        inputState={dateOfBirthState}
                        required={false}
                        label={t('AddOrEditPatient_birth_label')}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        inputName={'dateOfBirth'} />
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditPatient_birthPlace_placeholder')}
                        inputName="placeOfBirth"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_birthPlace_label')}
                        disabled={false}
                        startValue={parsedPatient ? parsedPatient.placeOfBirth : null}
                        value={placeOfBirthState?.value}
                        inputState={placeOfBirthState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                </div>
                <div className="AddOrEditPatient-content-form-inputs-flex">
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditPatient_location_placeholder')}
                        inputName="location"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_location_label')}
                        disabled={false}
                        startValue={parsedPatient && parsedPatient.room ? parsedPatient.room : null}
                        value={roomState?.value}
                        inputState={roomState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditPatient_residence_placeholder')}
                        inputName="residence"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_residence_label')}
                        disabled={false}
                        startValue={parsedPatient && parsedPatient.residence ? parsedPatient.residence : null}
                        value={residenceState?.value}
                        inputState={residenceState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                </div>
                <div className="AddOrEditPatient-content-form-inputs-flex">
                    <InputText
                        required={false}
                        placeholder={t('AddOrEditPatient_phone_placeholder')}
                        inputName="phone"
                        inputType={InputTextType.TEXT}
                        label={t('AddOrEditPatient_phone_label')}
                        disabled={false}
                        startValue={parsedPatient && parsedPatient.phone ? parsedPatient.phone : null}
                        value={phoneState?.value}
                        inputState={phoneState}
                        dispatchOnChange={addOrEditPatientOrganismSlice.actions.updateInput}
                        dispatchOnFocus={addOrEditPatientOrganismSlice.actions.setFocus}
                        dispatchOnBlur={addOrEditPatientOrganismSlice.actions.removeFocus} />
                </div>
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="AddOrEditPatient-content-form-inputs-button">
                <Button
                    text={patientId ? t('AddOrEditPatient_button_edit') : t('AddOrEditPatient_button_add')}
                    type={ButtonType.PRIMARY}
                    onClickDisabled={() => { dispatch(addOrEditPatientOrganismSlice.actions.showInputValidStates()); }}
                    disabled={!formValidState}
                    isSubmit={true} />
            </div>
        </form>
    )

    return (
        <div className="AddOrEditPatient">
            <div className="AddOrEditPatient-content">
                <div className="AddOrEditPatient-content-title">
                    <div className="AddOrEditPatient-content-title-text">
                        {patientId ? t('AddOrEditPatient_button_edit') : t('AddOrEditPatient_button_add')}
                    </div>
                    <div className="AddOrEditPatient-content-title-close">
                        {dispatchHideAddOrEditPatientModal &&
                            <Image onClick={() => dispatch(dispatchHideAddOrEditPatientModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AddOrEditPatient-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AddOrEditPatient;
