import { createAsyncThunk } from "@reduxjs/toolkit";
import {PhoneResponseDto} from "../../../models/entities/Responses/PhoneResponseDto";
import {PhoneRepository} from "../../../models/repositories/PhoneRepository";
import {PhoneRequestDto} from "../../../models/entities/Requests/PhoneRequestDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get alert list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {PhoneResponseDto[] | null}
 */
export const actionGetPhoneList = createAsyncThunk<Array<PhoneResponseDto> | null>(
    "phone/getPhoneList",
    async () => {
        return await PhoneRepository.getPhoneList();
    }
);


/**
 * Create new contact
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{request: PhoneRequestDto, t: TranslatorType}}
 * @return {PhoneResponseDto | string}
 */
export const actionCreateContact = createAsyncThunk<PhoneResponseDto | string,
    {request: PhoneRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "phone/createContact",
    async (payload: {request: PhoneRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        let result = null;
        try { result = await PhoneRepository.createPhone(payload.request, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return result;
    }
);

/**
 * Create new contact
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{number: number, t: TranslatorType}}
 * @return {PhoneResponseDto | string}
 */
export const actionDeleteContact = createAsyncThunk<PhoneResponseDto | string,
    {number: number, t: TranslatorType}, {rejectValue: string}>(
    "phone/deleteContact",
    async (payload:{number: number, t: TranslatorType}, { rejectWithValue }) => {
        let result = null;
        try { result = await PhoneRepository.deletePhone(payload.number, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return result;
    }
);
