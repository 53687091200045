/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum RoleEnum {
    ADMINISTRATOR = 1,
    TECHNICAL = 2,
    MEDICAL = 3,
    SUPERUSER = 4,
    HARDWARE = 5
}