import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum DashboardFilterTypes {
    ASSIGNED = 1,
    FREE = 2,
    ALL = 3,
    PATIENT = 4,
    SENSOR = 5,
    FULLTEXT = 6,
    WEARABLE = 7,
    TEMPLATE = 8,
    ELEVATION = 9
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface DashboardPageState {

    /** Current filter **/
    filterType: DashboardFilterTypes,
    /** Filter by patient **/
    patientId: number|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by wearable **/
    wearable: boolean|null,
    /** Filter by elevation **/
    elevation: number|null,
}

const initialState: DashboardPageState = {
    filterType: DashboardFilterTypes.ALL,
    patientId: null,
    fullTextValue: null,
    sensorType: null,
    filterTitle: null,
    wearable: null,
    templateId: null,
    elevation: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const dashboardPageSlice = createSlice({
    name: 'dashboardPage',
    initialState,
    reducers: {
        destroyFilter: (state) => {
            state.filterType = DashboardFilterTypes.ALL;
            state.patientId = null;
            state.sensorType = null;
            state.fullTextValue = null;
            state.filterTitle = null;
            state.wearable = null;
            state.elevation = null;
            state.templateId = null;
        },
        filterPatient: (state, action: PayloadAction<{patientId: number, title: string }>) => {
            state.filterType = DashboardFilterTypes.PATIENT;
            state.patientId = action.payload.patientId;
            state.filterTitle = action.payload.title;
        },
        filterSensor: (state, action: PayloadAction<{sensorType: number, title: string }>) => {
            state.filterType = DashboardFilterTypes.SENSOR;
            state.sensorType = action.payload.sensorType;
            state.filterTitle = action.payload.title;
        },
        filterWearable: (state, action: PayloadAction<{wearable: boolean, title: string }>) => {
            state.filterType = DashboardFilterTypes.WEARABLE;
            state.wearable = action.payload.wearable;
            state.filterTitle = action.payload.title;
        },
        filterTemplate: (state, action: PayloadAction<{templateId: number, title: string }>) => {
            state.filterType = DashboardFilterTypes.TEMPLATE;
            state.templateId = action.payload.templateId;
            state.filterTitle = action.payload.title;
        },
        filterElevation: (state, action: PayloadAction<{elevationId: number, title: string }>) => {
            state.filterType = DashboardFilterTypes.ELEVATION;
            state.elevation = action.payload.elevationId;
            state.filterTitle = action.payload.title;
        },
        filterAll: state => {
            state.filterType = DashboardFilterTypes.ALL;
            state.filterTitle = null;
        },
        filterAssigned: state => {
            state.filterType = DashboardFilterTypes.ASSIGNED;
            state.filterTitle = null;
        },
        filterFree: state => {
            state.filterType = DashboardFilterTypes.FREE;
            state.filterTitle = null;
        },
        filterFullText: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.filterTitle = null;
            if (action.payload.inputValue === null) {
                state.filterType = DashboardFilterTypes.ALL;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = DashboardFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
    },
})

export default dashboardPageSlice.reducer