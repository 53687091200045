import "./ProfileMenu.scss"
import React from "react";
import {useNavigate} from "react-router-dom";
import person from "../../../images/personIcon.svg";
import account from "../../../images/eye.svg";
import Role from "../Role/Role";
import edit from "../../../images/edit.svg";
import {T, useTranslate} from "@tolgee/react";
import Button, {ButtonType} from "../../atoms/Button/Button";
import {Storage} from "../../../models/utils/Storage";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";

/** 
 * @alias ProfileMenuProps
 * @category Components
 */
type ProfileMenuProps = {

    /** Logged user role **/
    role: RoleEnum,
    /** Hide profile menu Redux callback **/
    dispatchHideProfile: ActionCreatorWithoutPayload
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {RoleEnum} role - logged user role
 * @param {ActionCreatorWithoutPayload} dispatchHideProfile - hide profile menu Redux callback
 */
export const ProfileMenu = ({role, dispatchHideProfile} : ProfileMenuProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const session = Storage.getSession();

    // Navigate to somewhere
    const handleNavigate = (where: string) => {

        dispatch(dispatchHideProfile());
        navigation(where);
    }

    return (
        <div className={`ProfileMenu`}>
            <div className="ProfileMenu-content">
                <div className="ProfileMenu-content-top">
                    <div className="ProfileMenu-content-top-left">
                        <img src={person} alt={t('Base_profile_picture')}/>
                    </div>
                    <div className="ProfileMenu-content-top-right">
                        <div className="ProfileMenu-content-top-right-name">
                            {session ? `${session.user.firstName} ${session.user.lastName}` : '-'}
                        </div>
                        <div className="ProfileMenu-content-top-right-role">
                            <Role role={role}/>
                        </div>
                    </div>
                </div>
                <div className="ProfileMenu-content-middle">
                    <div
                        onClick={() => { if (session) { handleNavigate(`/user/${session.user.id}`); } }}
                        className="ProfileMenu-content-middle-item">
                        <div className="ProfileMenu-content-middle-item-icon">
                            <img src={edit} alt={t('Header_editProfile')}/>
                        </div>
                        <div className="ProfileMenu-content-middle-item-text text">
                            <T keyName={'Header_editProfile'}/>
                        </div>
                    </div>
                    {process.env.REACT_APP_FT_2FA === 'yes' &&
                        <div
                            onClick={() => { handleNavigate('/account'); }}
                            className="ProfileMenu-content-middle-item">
                            <div className="ProfileMenu-content-middle-item-icon">
                                <img src={account} alt={t('Header_2fa')}/>
                            </div>
                            <div className="ProfileMenu-content-middle-item-text text">
                                <T keyName={'Header_2fa'}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="ProfileMenu-content-bottom">
                    <Button
                        onClick={ () => { Storage.forcedLogOut(); } }
                        text={t('Header_logout')}
                        type={ButtonType.PRIMARY}/>
                </div>
            </div>
        </div>
    );
}

export default ProfileMenu;
