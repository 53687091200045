import { createAsyncThunk } from "@reduxjs/toolkit";
import {PasswordSetRequestDto} from "../../../models/entities/Requests/PasswordSetRequestDto";
import {AuthorizationRepository} from "../../../models/repositories/AuthorizationRepository";
import {TokenResponseDto} from "../../../models/entities/Responses/TokenResponseDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Reset user password
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{request: PasswordSetRequestDto, t: TranslatorType}} request - password request
 * @return {TokenResponseDto|string}
 */
export const actionResetPassword = createAsyncThunk<TokenResponseDto|string, {request: PasswordSetRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "resetBlock/resetPassword",
    async (payload: {request: PasswordSetRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { return await AuthorizationRepository.setNewPassword(payload.request, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
