/**
 * @enum {number}
 * @category Utils
 */
export enum ValidatorItemType {
    EMAIL,
    NUMBER,
    DATE,
    SVG,
    CSV

}

/**
 * @interface
 * @category Utils
 */
export interface ValidatorInput {

    /** Validation type **/
    type?: ValidatorItemType,
    /** Value required **/
    required: boolean,
    /** Min length of value **/
    minLength?: number,
    /** Value is in this array **/
    valueInSet?: Array<string>
}

/**
 * @interface
 * @category Utils
 */
export interface ValidatorOutput {

    /** Validation success **/
    ok: boolean,
    /** List of validation error **/
    errors: Array<string>
}

/**
 * @class
 * @category Utils
 */
export class Validator {

    /**
     * Validate input value
     * @function
     * @category Utils
     * @param {ValidatorInput} options - validation options
     * @param {any} t - translator object
     * @param {string|number|null|undefined} value - input value
     */
    public static validate(options: ValidatorInput, t: any, value?: string|number|null) : ValidatorOutput {

        // Required
        if (options.required && (typeof value === 'undefined' || value === null || value.toString().length === 0)) {
            return {ok: false, errors: [t('Validator_field_required')]};
        }

        // Empty
        if (value === null || typeof value === 'undefined' || value.toString().length === 0) {
            return {ok: true, errors: []};
        }

        // Min length
        if (options.minLength && value.toString().length < options.minLength) {
            return {ok: false, errors: [t('Validator_minimal_length', {length: options.minLength})]}
        }

        // Value in set
        if (options.valueInSet && !options.valueInSet.includes(value.toString())) {
            return {ok: false, errors: [t('Validator_out_of_set', {setText:options.valueInSet.join(', ')})]}
        }

        // E-mail
        if (typeof value === 'string' && options.type === ValidatorItemType.EMAIL && !this.validateEmailString(value)) {
            return {ok: false, errors: [t('Validator_wrong_mail')]}
        }

        // SVG
        if (typeof value === 'string' && options.type === ValidatorItemType.SVG && !this.validateSvgFile(value)) {
            return {ok: false, errors: [t('Validator_wrong_image_format')]}
        }

        // CSV
        if (typeof value === 'string' && options.type === ValidatorItemType.CSV && !this.validateCsvFile(value)) {
            return {ok: false, errors: [t('Validator_wrong_image_format')]}
        }

        // Number
        if (options.type === ValidatorItemType.NUMBER && !this.validateNumberString(value.toString())) {
            return {ok: false, errors: [t('Validator_wrong_number')]}
        }

        // Longitude
        if (options.type === ValidatorItemType.DATE && !this.validateDateString(value.toString())) {
            return {ok: false, errors: [t('Validator_wrong_date')]}
        }

        return {ok: true, errors: []};
    }

    /**
     * Validate email
     * @function
     * @category Utils
     * @param {string} value - input value
     * @private
     */
    private static validateEmailString(value: string) : boolean {

        return new RegExp ([
            '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])||',
            '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'].join('')).test(String(value).toLowerCase());
    }

    /**
     * Validate number
     * @function
     * @category Utils
     * @param {string} value - input value
     * @private
     */
    private static validateNumberString(value: string) : boolean {
        return new RegExp (['^[0-9\\.]+$'].join('')).test(String(value).toLowerCase());
    }

    /**
     * Validate date
     * @function
     * @category Utils
     * @param {string} value - input value
     * @private
     */
    private static validateDateString(value: string) : boolean {
        const parsedTimestamp = Date.parse(value);
        return !isNaN(parsedTimestamp) && parsedTimestamp <= new Date().getTime();
    }

    /**
     * Validate file
     * @function
     * @category Utils
     * @param {string} value - input value
     * @private
     */
    private static validateSvgFile(value: string) : boolean {
        return value.includes('data:image/svg+xml;base64', 0);
    }

    /**
     * Validate file
     * @function
     * @category Utils
     * @param {string} value - input value
     * @private
     */
    private static validateCsvFile(value: string) : boolean {
        return value.includes('data:text/csv;base64', 0);
    }
}