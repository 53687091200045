import React, {useEffect} from "react";
import "./PatientSensors.scss"
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {HardwareWithSensorsResponseDto} from "../../../models/entities/Responses/HardwareWithSensorsResponseDto";
import {SensorState} from "../SensorState/SensorState";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {PatientDto} from "../../../models/entities/PatientDto";
import {actionRefreshPatientSensors} from "../../../store/actions/organisms/patientSensorsAction";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {patientSensorsOrganismSlice} from "../../../store/slices/organisms/patientSensorsSlice";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type PatientSensorsProps = {
    patient: PatientDto,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    hardwareList: Array<HardwareWithSensorsResponseDto>,
    dispatchShowSensorEditModal: ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {HardwareWithSensorsResponseDto[]} hardwareList - list of hardware
 * @param {ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>} dispatchShowSensorEditModal - show modal Redux callback
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {PatientDto} patient
 */
export const PatientSensors = ({hardwareList, dispatchShowSensorEditModal, sensorTypes, zoneList, patient} : PatientSensorsProps): React.JSX.Element|null => {

    const dispatch = useAppDispatch();
    const patientSensorsRefreshed = useAppSelector((state) => state.patientSensorsOrganism.patientSensors);

    useEffect(() => { return () => { dispatch(patientSensorsOrganismSlice.actions.destroyOrganism()); } }, [dispatch])
    useEffect( () => {
        const checkMs = process.env.REACT_APP_DETAIL_REFRESH_MS ? parseInt(process.env.REACT_APP_DETAIL_REFRESH_MS) : 10000;
        const interval = setInterval(() => { dispatch(actionRefreshPatientSensors({patientId: patient.id})); }, checkMs);
        return () => { clearInterval(interval); }
    }, [patient, dispatch]);

    const sortSensors = (sensors: Array<SensorResponseDto>) : Array<SensorResponseDto> => {
        const inputSensors = [...sensors];
        return inputSensors.sort((a: SensorResponseDto, b: SensorResponseDto) => {
            if (a.Sensor.lastReadAt !== null && b.Sensor.lastReadAt === null) { return -1; }
            if (a.Sensor.lastReadAt === null && b.Sensor.lastReadAt !== null) { return 1; }
            return 0;
        });
    }

    const getSensorsFromHwList = (hardwareList: Array<HardwareWithSensorsResponseDto>) : Array<SensorResponseDto> => {

        let output : Array<SensorResponseDto> = [];
        hardwareList.forEach((hardware: HardwareWithSensorsResponseDto) => {
            hardware.Sensors.forEach((sensor: SensorResponseDto) => {
                output.push(sensor);
            });
        });
        return output;
    }

    // Get sensors from page load patient data or from refreshed data
    const sensors = patientSensorsRefreshed ? patientSensorsRefreshed : getSensorsFromHwList(hardwareList);

    return (
        <div className="PatientSensors">
            <div className="PatientSensors-cards">
                {sortSensors(sensors).map((item: SensorResponseDto) => {
                   return (
                       <div key={`patientSensorCard-${item.Sensor.id}`} className="PatientSensors-cards-item">
                            <SensorState
                                sensorTypes={sensorTypes}
                                zoneList={zoneList}
                                sensor={item}
                                dispatchShowSensorEditModal={dispatchShowSensorEditModal} />
                       </div>
                   )
                })}
            </div>
        </div>
    );
}

export default PatientSensors;
