import {createSlice} from '@reduxjs/toolkit'
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {actionGetAlert, actionGetAlertSensor} from "../../actions/data/alertAction";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface AlertDetailPageState {

    /** Alert data **/
    alert?: AlertResponseDto|null,
    /** Sensor data **/
    sensor?: SensorResponseDto|null,
}

const initialState: AlertDetailPageState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const alertDetailPageSlice = createSlice({
    name: 'alertDetailPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.alert = undefined;
            state.sensor = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionGetAlert.fulfilled, (state, {payload}) => {
            if (!payload) { state.sensor = null; }
            state.alert = payload;
        })
        builder.addCase(actionGetAlertSensor.fulfilled, (state, {payload}) => {
            state.sensor = payload;
        })
    }
})

export default alertDetailPageSlice.reducer