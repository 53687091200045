import React, {useMemo} from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {CardPatient} from "../../organisms/CardPatient/CardPatient";
import {PatientResponseDto} from "../../../models/entities/Responses/PatientResponseDto";
import {BulkListPatientHardware} from "../../organisms/BulkListPatientHardware/BulkListPatientHardware";
import {useAppSelector} from "../../../store/hooks";
import {patientDetailPageSlice} from "../../../store/slices/pages/patientDetailPageSlice";
import {PatientSensors} from "../../organisms/PatientSensors/PatientSensors";
import {HardwareGraphs} from "../../organisms/HardwareGraphs/HardwareGraphs";
import {DataParser} from "../../../models/utils/DataParser";
import {ListLog} from "../../organisms/ListLog/ListLog";
import {EditSensor} from "../../organisms/EditSensor/EditSensor";
import {AddOrEditPatient} from "../../organisms/AddOrEditPatient/AddOrEditPatient";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {buildingObserverPageSlice} from "../../../store/slices/pages/buildingObserverSlice";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {AssignHardware} from "../../organisms/AssignHardware/AssignHardware";
import BulkListPatientZones from "../../organisms/BulkListPatientZones/BulkListPatientZones";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import AssignZone from "../../organisms/AssignZone/AssignZone";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type PatientDetailTemplateProps = {
    patient: PatientResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    role: RoleEnum,
    bannedZonesList: ZoneDto[]
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {PatientResponseDto} patient - patient data
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {RoleEnum} role - role for user from session
 * @param {ZoneDto[]} bannedZonesList - list of banned zones
 */
export const PatientDetailTemplate = ({patient, sensorTypes, zoneList, role, bannedZonesList}: PatientDetailTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const addPatientHardwareModalState = useAppSelector((state) => state.patientDetailPage.addPatientHardwareModalShown);
    const addPatientZoneModalState = useAppSelector((state) => state.patientDetailPage.addPatientZoneModalShown);
    const editSensorModalShownState = useAppSelector((state) => state.patientDetailPage.editSensorModalShown);
    const editSensorModalSensorIdState = useAppSelector((state) => state.patientDetailPage.editSensorModalSensorId);
    const editSensorModalHardwareIdState = useAppSelector((state) => state.patientDetailPage.editSensorModalHardwareId);
    const editPatientModalShownState = useAppSelector((state) => state.patientDetailPage.editPatientModalShown);
    const editPatientModalIdState = useAppSelector((state) => state.patientDetailPage.editPatientModalId);
    const title = t('PatientDetailTemplate_title', {patientName: `${patient.Patient.firstName} ${patient.Patient.lastName}`});

    const sensorList = useMemo(() => {
        return DataParser.getSensorsFromHardwareList(patient.Hardware)
    }, [patient.Hardware]);

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    {title}
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={title}
                        breadItems={[
                            {name: t('Navigation_patients'), link: '/patients'},
                            {name: title, link: null},
                        ]} />
                    <div className="layout-right-content layout-right-content-flex flex">
                        <div className="layout-right-content-item half">
                            <CardPatient
                                dispatchUpdateActivePatientPlan={buildingObserverPageSlice.actions.updateActivePatient}
                                role={role}
                                dispatchShowEditPatientModal={patientDetailPageSlice.actions.showEditPatientModal}
                                patient={patient} />
                        </div>
                        <div className="layout-right-content-item half">
                            <BulkListPatientHardware
                                patient={patient.Patient}
                                dispatchShowAddHardwareModal={patientDetailPageSlice.actions.showAddPatientHardwareModal}
                                hardwareList={patient.Hardware} />
                        </div>
                    </div>
                    <div className="layout-right-content-item">
                        <BulkListPatientZones
                            bannedZonesList={bannedZonesList}
                            patient={patient.Patient}
                            dispatchShowAddZoneModal={patientDetailPageSlice.actions.showAddPatientZoneModal} />
                    </div>
                    {patient.Hardware.length > 0 &&
                        <div className="layout-right-content-item">
                            <PatientSensors
                                patient={patient.Patient}
                                zoneList={zoneList}
                                sensorTypes={sensorTypes}
                                hardwareList={patient.Hardware}
                                dispatchShowSensorEditModal={patientDetailPageSlice.actions.showEditSensorModal}/>
                        </div>
                    }
                    <div className="layout-right-content-item">
                        <HardwareGraphs
                            zoneList={zoneList}
                            sensorsData={sensorList} />
                    </div>
                    <div className="layout-right-content-item">
                        <ListLog
                            pagination={{showCount: 8}}
                            patient={patient.Patient} />
                    </div>
                </div>
            </div>
            {addPatientZoneModalState &&
                <AssignZone
                    bannedZonesList={bannedZonesList}
                    patient={patient.Patient}
                    dispatchHideModal={patientDetailPageSlice.actions.hideAddPatientZoneModal} />
            }
            {addPatientHardwareModalState &&
                <AssignHardware
                    hwTypesSelectFrom={[HardwareTypeEnum.WEARABLE]}
                    patient={patient.Patient}
                    dispatchHideModal={patientDetailPageSlice.actions.hideAddPatientHardwareModal}
                    zone={null} />
            }
            {editPatientModalShownState && editPatientModalIdState &&
                <AddOrEditPatient
                    patientId={editPatientModalIdState}
                    dispatchHideAddOrEditPatientModal={patientDetailPageSlice.actions.hideEditPatientModal} />
            }
            {editSensorModalShownState && editSensorModalHardwareIdState && editSensorModalSensorIdState &&
                <EditSensor
                    role={role}
                    patientId={patient.Patient.id}
                    dispatchHideModal={patientDetailPageSlice.actions.hideEditHardwareModal}
                    sensorId={editSensorModalSensorIdState}
                    hardwareId={editSensorModalHardwareIdState} />
            }
        </main>
    );
}

export default PatientDetailTemplate;
