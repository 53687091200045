import "./EasySearch.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import React from "react";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import searchIcon from "../../../images/search.svg"
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";

type EasySearchProps = {
    dispatchSearchValueUpdate: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    searchValue?: string|null,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>} dispatchSearchValueUpdate - fulltext search update callback
 * @param {string|null|undefined} searchValue - current fulltext search value
 */
export const EasySearch = ({dispatchSearchValueUpdate, searchValue} : EasySearchProps): React.JSX.Element => {

    const {t} = useTranslate();

    return (
        <div className={`EasySearch`}>
            <div className={`EasySearch-content`}>
                <InputText
                    withoutValidations={true}
                    inputState={null}
                    hasClear={true}
                    dontCallUpdateOnInit={true}
                    image={{source: searchIcon, title: t('Base_search')}}
                    placeholder={t('Base_search')}
                    inputName="easySearch"
                    inputType={InputTextType.TEXT}
                    value={searchValue}
                    dispatchOnChange={dispatchSearchValueUpdate} />
            </div>
        </div>
    );
}

export default EasySearch;
