import "./Loading.scss";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import React from "react";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {useTranslate} from "@tolgee/react";

/** 
 * @enum {number}
 * @category Components
 */
export enum LoadingTypeEnum {
    MODAL = 1,
    CARD,
    FULL,
    FLAT
}

/** 
 * @alias LoadingModal
 * @category Components
 */
type LoadingModal = {

    /** Redux callback to hide modal **/
    dispatchHide?: ActionCreatorWithoutPayload
}

type LoadingProps = {
    type: LoadingTypeEnum,
    modal?: LoadingModal
    title?: string
}

/**
 * @component
 * @category Components
 * @subcategory Extras
 * @param {LoadingTypeEnum} type - view type state
 * @param {LoadingModal|undefined} modal - modal options
 * @param {string|undefined} title - title text
 */
export const Loading = ({type, modal, title} : LoadingProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    if (type === LoadingTypeEnum.MODAL && modal) {
        return (
            <div className={`Loading-modal`}>
                <div className={`Loading-modal-content`}>
                    <div className={`Loading-modal-content-title`}>
                        {title &&
                            <div className={`Loading-modal-content-title-text`}>
                                {title}
                            </div>
                        }
                        <div className={`Loading-modal-content-title-close`}>
                            <Image onClick={() => { if (modal.dispatchHide) { dispatch(modal.dispatchHide()); } }}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        </div>
                    </div>
                    <div className={`Loading-modal-content-image`}>
                        <div className={'spinner'}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === LoadingTypeEnum.FULL) {
        return (
            <div className={`Loading-full`}>
                <div className={`Loading-full-content`}>
                    <div className={`Loading-full-content-image`}>
                        <div className={'spinner'}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === LoadingTypeEnum.CARD) {
        return (
            <div className={`Loading-card`}>
                <div className={`Loading-card-content`}>
                    {title &&
                        <div className={`Loading-card-content-title weak`}>
                            {title}
                        </div>
                    }
                    <div className={`Loading-card-content-image`}>
                        <div className={'spinner'}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`Loading-flat`}>
            <div className={`Loading-flat-content`}>
                <div className={`Loading-flat-content-image`}>
                    <div className={'spinner'}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
