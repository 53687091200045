import "./Role.scss"
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import React from "react";
import {UserUtil} from "../../../models/utils/UserUtil";
import {useTranslate} from "@tolgee/react";

type RoleProps = {
    role: RoleEnum
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {RoleEnum} role - role type
 * @constructor
 */
export const Role = ({role} : RoleProps): React.JSX.Element => {

    const {t} = useTranslate();
    const meta = UserUtil.getRoleFEMetaFromRole(role, t);
    const roleImage = meta.image;

    return (
        <div className={`Role ${meta.id}`}>
            {roleImage &&
                <div className="Role-icon">
                    <img src={roleImage} alt={t('Base_name_role')} />
                </div>
            }
            <div className={`Role-title title`}>
                {meta.text}
            </div>
        </div>
    );
}

export default Role;
