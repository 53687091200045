import {createAsyncThunk} from "@reduxjs/toolkit";
import {NotifyMessageNotificationData} from "../../../models/entities/Utils/Messaging/NotifyMessageNotificationData";

/**
 * Create new notification from FireBase
 * @category Redux
 * @subcategory Actions
 * @function
 * @param { NotifyMessage } notification - notification object
 * @return { NotifyMessage }
 */
export const actionShowNotification = createAsyncThunk<NotifyMessageNotificationData, NotifyMessageNotificationData>(
    "mainSlice/showNotification",
    async (notification: NotifyMessageNotificationData) => {
        return notification;
    }
);

/**
 * Set new Firebase Messaging Token
 * @category Redux
 * @subcategory Actions
 * @function
 * @param { string|null } token - token string
 * @return { string|null } token - token string
 */
export const actionSetMessagingToken = createAsyncThunk<string|null, string|null>(
    "mainSlice/setMessagingToken",
    async (token: string|null) => {
        return token;
    }
);
