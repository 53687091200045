import "./EasyCalendar.scss"
import React, {useCallback, useRef} from "react";
import Calendar from 'react-calendar'
import {DateUtil} from "../../../models/utils/DateUtil";
import tick from "../../../images/tick.svg";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {T, useTolgee, useTranslate} from "@tolgee/react";
import FilterMain from "../../extras/Filter/FilterMain";
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import calendar from "../../../images/calendar.svg";
import {Value} from "react-calendar/src/shared/types";
import 'react-calendar/dist/Calendar.css';

/**
 * @category Components
 */
enum EasyCalendarFilterType {
    RANGE = 1,
    INTERVAL = 2
}

/**
 * @category Components
 */
enum EasyCalendarIntervalType {
    LAST_24_HOURS = 1,
    LAST_7_DAYS = 2,
    LAST_30_DAYS = 3,
    LAST_90_DAYS = 4,
    LAST_365_DAYS = 5,
    ALL_TIME = 6
}

/**
 * @interface
 * @category Components
 */
export interface EasyCalendarFilterPayload {

    /** Filter from date **/
    dateFrom: Date|null,
    /** Filter to date **/
    dateTo: Date|null
}

type EasyCalendarProps = {
    dispatchChange: ActionCreatorWithPayload<EasyCalendarFilterPayload>,
    date: EasyCalendarFilterPayload|null,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {ActionCreatorWithPayload<EasyCalendarFilterPayload>} dispatchChange - propagate filter change to Redux
 * @param {EasyCalendarFilterPayload|null} date - filtering date
 * @todo clear functionality
 */
export const EasyCalendar = ({dispatchChange, date} : EasyCalendarProps): React.JSX.Element => {

    // Easy Calendar Shown
    const [popUpShown, setPopUpShown] = React.useState<boolean>(false);

    // Range calendar is shown
    const [calendarFromShown, setCalendarFromShown] = React.useState<boolean>(false);
    const [calendarToShown, setCalendarToShown] = React.useState<boolean>(false);

    // Filter type
    const [filterType, setFilterType] = React.useState<EasyCalendarFilterType|null>(null);

    // Interval type
    const [intervalType, setIntervalType] = React.useState<EasyCalendarIntervalType|null>(null);

    // Init base states && dispatch
    const tolgee = useTolgee();
    const currentLocale = tolgee.getLanguage();
    const containerRef: React.MutableRefObject<null|HTMLDivElement> = useRef(null);
    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    // Called when closing the pop-up
    const handleClose = () => {

        setPopUpShown(false);
        setCalendarFromShown(false);
        setCalendarToShown(false);
    }

    // Change calendar in range
    const handleCalendarOnChange = (value: Value) => {

        setFilterType(EasyCalendarFilterType.RANGE);
        if (calendarFromShown && value instanceof Date) {
            dispatch(dispatchChange({dateFrom: value, dateTo: date ? date.dateTo : null}));
        }
        if (calendarToShown && value instanceof Date) {
            dispatch(dispatchChange({dateFrom: date ? date.dateFrom : null, dateTo: value}));
            handleClose();
        }
    }

    // Change interval
    const handleIntervalOnChange = (type: EasyCalendarIntervalType, fromDate: Date|null) => {

        setFilterType(EasyCalendarFilterType.INTERVAL);
        setIntervalType(type);
        dispatch(dispatchChange({dateFrom: fromDate, dateTo: null}));
        handleClose();
    }

    // Get list of intervals used with metas
    const getIntervals = () => {
        return [
            {
                title: t('EasyCalendar_last24hours'),
                date: new Date(new Date().setDate((new Date()).getDate() - 1)),
                type: EasyCalendarIntervalType.LAST_24_HOURS
            }, {
                title: t('EasyCalendar_last7days'),
                date: new Date(new Date().setDate((new Date()).getDate() - 7)),
                type: EasyCalendarIntervalType.LAST_7_DAYS
            }, {
                title: t('EasyCalendar_last30days'),
                date: new Date(new Date().setDate((new Date()).getDate() - 30)),
                type: EasyCalendarIntervalType.LAST_30_DAYS
            }, {
                title: t('EasyCalendar_last90days'),
                date: new Date(new Date().setDate((new Date()).getDate() - 90)),
                type: EasyCalendarIntervalType.LAST_90_DAYS
            }, {
                title: t('EasyCalendar_lastYear'),
                date: new Date(new Date().setDate((new Date()).getDate() - 365)),
                type: EasyCalendarIntervalType.LAST_365_DAYS
            }, {
                title: t('EasyCalendar_allTime'),
                date: null, type: EasyCalendarIntervalType.ALL_TIME
            },
        ]
    }

    // Get main block content
    const getTitle = () : string|null => {

        // When no date on input (don't show it)
        if (!date) { return null; }

        if (filterType === EasyCalendarFilterType.RANGE) {
            if (date.dateFrom && date.dateTo === null) {
                return `${DateUtil.getCurrentDateFormatted('/', date.dateFrom)} - now`;
            } else if (date.dateFrom === null && date.dateTo) {
                return t('EasyCalendar_past').toLowerCase() + ` - ${DateUtil.getCurrentDateFormatted('/', date.dateTo)}`;
            } else if (date.dateFrom && date.dateTo) {
                return `${DateUtil.getCurrentDateFormatted('/', date.dateFrom)} - ${DateUtil.getCurrentDateFormatted('/', date.dateTo)}`;
            }
        }

        if (filterType === EasyCalendarFilterType.INTERVAL) {
            switch (intervalType) {
                case EasyCalendarIntervalType.ALL_TIME: return t('EasyCalendar_allTime');
                case EasyCalendarIntervalType.LAST_365_DAYS: return t('EasyCalendar_lastYear');
                case EasyCalendarIntervalType.LAST_90_DAYS: return t('EasyCalendar_last90days');
                case EasyCalendarIntervalType.LAST_30_DAYS: return t('EasyCalendar_last30days');
                case EasyCalendarIntervalType.LAST_7_DAYS: return t('EasyCalendar_last7days');
                case EasyCalendarIntervalType.LAST_24_HOURS: return t('EasyCalendar_last24hours')
            }
        }

        return null;
    }

    // Scroll to calendar when clicked on some input
    const scrollContainerToBottom = useCallback(() => {
        setTimeout(() => {
            if (!containerRef || !containerRef.current) { return; }
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }, 100);
    }, [])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { handleClose(); });

    return (
        <div className={`EasyCalendar ${popUpShown ? 'active' : 'hidden'}`} ref={wrapperRef}>

            <FilterMain
                customImage={calendar}
                openedState={popUpShown}
                filterTypeState={AbstractFilterType.FILTER_ALL}
                title={getTitle()}
                filterSearchState={null}
                toggleDropDown={ () => { setPopUpShown(!popUpShown); }} />

            <div className="EasyCalendar-content content">
                <div className="EasyCalendar-content-inside">
                    {popUpShown &&
                        <div className={`EasyCalendar-content-inside-calendar`} ref={containerRef}>
                            <div className={`EasyCalendar-content-inside-calendar-intervals`}>
                                {getIntervals().map((item, index) => {
                                    return (
                                        <div
                                            onClick={() => { handleIntervalOnChange(item.type, item.date); }}
                                            key={`EasyCalendar-content-inside-calendar-intervals-item-${index}`}
                                            className={`EasyCalendar-content-inside-calendar-intervals-item 
                                        ${date && filterType === EasyCalendarFilterType.INTERVAL && intervalType === item.type ? 'withTick' : ''}`}>
                                            {filterType === EasyCalendarFilterType.INTERVAL && intervalType === item.type &&
                                                <div className={`EasyCalendar-content-inside-calendar-intervals-item-tick tick`}>
                                                    <img src={tick} alt={t('EasyCalendar_intervalSelected')}/>
                                                </div>
                                            }
                                            <div className={`EasyCalendar-content-inside-calendar-intervals-item-text`}>
                                                {item.title}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`EasyCalendar-content-inside-calendar-range`}>
                                <div className={`EasyCalendar-content-inside-calendar-range-title ${date && filterType === EasyCalendarFilterType.RANGE ? 'withTick' : ''}`}>
                                    {filterType === EasyCalendarFilterType.RANGE &&
                                        <div className={`EasyCalendar-content-inside-calendar-range-title-tick tick`}>
                                            <img src={tick} alt={t('EasyCalendar_rangeSelected')}/>
                                        </div>
                                    }
                                    <div className={`EasyCalendar-content-inside-calendar-range-title-text`}>
                                        <T keyName={'EasyCalendar_range'} />
                                    </div>
                                </div>
                                <div className={`EasyCalendar-content-inside-calendar-range-items`}>
                                    <div className={`EasyCalendar-content-inside-calendar-range-items-item`}>
                                        <input
                                            className={calendarFromShown ? 'active' : ''}
                                            onChange={ () => {} }
                                            onFocus={() => {
                                                setCalendarToShown(false);
                                                setCalendarFromShown(true);
                                                scrollContainerToBottom();
                                            }}
                                            value={date && date.dateFrom ? DateUtil.getCurrentDateFormatted('-', date.dateFrom) : ''}
                                            type={"text"}/>
                                    </div>
                                    <div className={`EasyCalendar-content-inside-calendar-range-items-item`}>
                                        <input
                                            className={calendarToShown ? 'active' : ''}
                                            onChange={ () => {} }
                                            onFocus={() => {
                                                setCalendarFromShown(false);
                                                setCalendarToShown(true);
                                                scrollContainerToBottom();
                                            }}
                                            value={date && date.dateTo ? DateUtil.getCurrentDateFormatted('-', date.dateTo) : ''}
                                            type={"text"}/>
                                    </div>
                                </div>
                            </div>
                            {(calendarFromShown || calendarToShown) &&
                                <div className={`EasyCalendar-content-inside-calendar-includedCalendar`}>
                                    <Calendar
                                        locale={currentLocale}
                                        value={calendarFromShown
                                            ? (date && date.dateFrom ? date.dateFrom : null)
                                            : (date && date.dateTo ? date.dateTo : null)}
                                        onChange={handleCalendarOnChange}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default EasyCalendar;
