/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum FcmMessageTypeEnum {

    ALERT_HARDWARE = 1,
    ALERT_PATIENT = 2,
    ALERT_NEW_COUNT = 3,
    SOUND = 5,
    MONITORING = 10,
}
