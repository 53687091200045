import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionGetInternalBuilding} from "../../actions/data/buildingAction";
import {
    abstractBuildingInitialState,
    AbstractBuildingReducersState, destroyGetAbstractBuilding,
    getAbstractBuildingReducers
} from "../../../models/utils/AbstractBuildingReducers";
import {actionGetHiveCached, actionGetHiveFresh} from "../../actions/data/hiveAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractBuildingReducersState}
 */
interface BuildingObserverState extends AbstractBuildingReducersState {}

const initialState: BuildingObserverState = {...{paginationPage: null}, ...abstractBuildingInitialState}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingObserverPageSlice = createSlice({
    name: 'buildingObserverPage',
    initialState,
    reducers: {...getAbstractBuildingReducers(), ...{
        destroyPage: (state: BuildingObserverState) => {
            destroyGetAbstractBuilding(state);
        },
        changePaginationPage: (state: BuildingObserverState, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
            state.activeHardware = null;
            state.activePatient = null;
        },
    }},
    extraReducers: (builder) => {
        builder.addCase(actionGetInternalBuilding.fulfilled, (state, {payload}) => {
            state.internalBuilding = payload;
        })
        builder.addCase(actionGetHiveCached.fulfilled, (state, {payload}) => {
            state.hive = payload;
        })
        builder.addCase(actionGetHiveFresh.fulfilled, (state, {payload}) => {
            state.hive = payload;
        })
    }
})

export default buildingObserverPageSlice.reducer