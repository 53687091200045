import React, {useEffect} from "react";
import "./BulkListZoneHardware.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {useTranslate} from "@tolgee/react";
import {actionAssignHardware} from "../../../store/actions/data/hardwareAction";
import BulkList, {BulkListItem} from "../../molecules/BulkList/BulkList";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {bulkListZoneHardwareOrganismSlice} from "../../../store/slices/organisms/bulkListZoneHardwareSlice";
import {actionRemoveZoneHardwareList} from "../../../store/actions/data/zoneAction";

type BulkListZoneHardwareProps =  {
    zone: ZoneDto,
    hardwareList: Array<HardwareResponseDto>,
    dispatchShowAddHardwareModal: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ZoneDto} zone - ZONE data
 * @param {HardwareResponseDto[]} hardwareList - list of hardware
 * @param {ActionCreatorWithoutPayload} dispatchShowAddHardwareModal - show modal Redux callback
 */
export const BulkListZoneHardware = ({hardwareList, dispatchShowAddHardwareModal, zone} : BulkListZoneHardwareProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();

    // Init
    const deleteInProgressState = useAppSelector((state) => state.bulkListZoneHardwareOrganism.deleteProgress);

    // Cleanup
    useEffect(() => { return () => { dispatch(bulkListZoneHardwareOrganismSlice.actions.destroyOrganism()); } }, [dispatch]);

    // Parse HW to bulk list items
    const parseHWIntoBulkListItems = (hardwareList: Array<HardwareResponseDto>) : Array<BulkListItem> => {

        return hardwareList.map((hardware: HardwareResponseDto) => {
            return {
                id: hardware.Hardware.id,
                name: hardware.Hardware.internalId ?? hardware.Hardware.uid,
                icon: HardwareUtil.getHardwareTypeMetaFromHardwareType(hardware.HardwareTemplate.type, t).image
            }
        });
    }

    // When removed one item
    const removedOneCallback =  async (item: BulkListItem) => {

        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListZoneHardware_removedOne', {name: item.name})
        }));
    }

    // When removed more items
    const removedMoreCallback = () => {
        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t('BulkListZoneHardware_removedMore')
        }));
    }

    // When basic click on item
    const clickedOnItemRedirect = (item: BulkListItem) => {
        navigation(`/devices/${item.id}`)
    }

    // When removed one
    const removeOneCallback = (item: BulkListItem) => {
        return dispatch(actionAssignHardware({
            t: t,
            patientId: null,
            zoneId: zone.id,
            hardwareId: item.id,
            hardwareAssignRequest: {patient: null, zone: null}
        }))
    }

    // When removed more
    const removeMoreCallback = (selectedToDelete: number[]) => {
        return dispatch(actionRemoveZoneHardwareList({
            requestEntity: {hardwareList: selectedToDelete},
            zoneId: zone.id,
            t: t
        }));
    }

    return (
        <div className={`BulkListZoneHardware`}>
            <BulkList
                pagination={{size: 8}}
                deleteProgress={deleteInProgressState}
                emptyTextTranslationString={'BulkListZoneHardware_hardwareBulk_empty_text'}
                emptyTitleTranslationString={'BulkListZoneHardware_hardwareBulk_empty_title'}
                assignButtonTranslationString={'BulkListZoneHardware_hardwareBulk_button'}
                titleTranslationString={'BulkListZoneHardware_hardwareBulk_button'}
                callbacks={{
                    removedOneCallback: removedOneCallback,
                    removedBulkCallback: removedMoreCallback,
                    onItemClickCallback: clickedOnItemRedirect,
                    callBackRemoveOne: removeOneCallback,
                    callBackRemoveBulk: removeMoreCallback
                }}
                itemList={parseHWIntoBulkListItems(hardwareList)}
                dispatchShowAddModal={dispatchShowAddHardwareModal} />
        </div>
    );
}

export default BulkListZoneHardware;
