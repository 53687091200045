import React from "react";
import "./BulkList.scss"
import removeRedIcon from "../../../images/removeRed.svg";
import removeIcon from "../../../images/remove.svg";
import tickIcon from "../../../images/ok.svg";
import {useAppDispatch} from "../../../store/hooks";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithoutPayload, PayloadAction} from "@reduxjs/toolkit";
import {Pagination} from "../Pagination/Pagination";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {T, TFnType, useTranslate} from "@tolgee/react";
import {ImageType} from "../../../models/entities/Utils/Image";

/**
 * @alias BulkListPagination
 * @category Molecules
 */
type BulkListPagination = { size: number };

export interface BulkListItem {
    id: number,
    name: string,
    icon: ImageType
}

export interface BulkListCallbacks {

    onItemClickCallback: (item: BulkListItem) => void,
    removedOneCallback: (item: BulkListItem) => void,
    removedBulkCallback: () => void,
    callBackRemoveOne: (item: BulkListItem) => Promise<PayloadAction<any, any, any>>,
    callBackRemoveBulk: (selectedToDelete: number[], t: TFnType) => Promise<PayloadAction<any, any, any>>,
}

type BulkListProps =  {
    titleTranslationString: string,
    assignButtonTranslationString: string,
    emptyTitleTranslationString: string,
    emptyTextTranslationString: string,
    callbacks: BulkListCallbacks,
    itemList: Array<BulkListItem>,
    dispatchShowAddModal: ActionCreatorWithoutPayload,
    pagination: BulkListPagination,
    deleteProgress: boolean,

}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {string} titleTranslationString - translation of title
 * @param {string} assignButtonTranslationString - translation of assign button
 * @param {BulkListItem[]} itemList - list of items
 * @param {BulkListPagination|undefined} pagination - pagination options
 * @param {ActionCreatorWithoutPayload} dispatchShowAddModal - show modal Redux callback
 * @param {BulkListCallbacks} callbacks - redux action to bulk delete
 * @param {boolean} deleteProgress - if selecting to delete
 * @param {string} emptyTitleTranslationString - translation key of title when empty
 * @param {string} emptyTextTranslationString - translation key of content when empty
 */
export const BulkList = ({itemList, pagination, dispatchShowAddModal, callbacks, titleTranslationString, deleteProgress,
    assignButtonTranslationString, emptyTitleTranslationString, emptyTextTranslationString} : BulkListProps): React.JSX.Element => {

    const [selectedToDeleteState, setSelectedToDeleteState] = React.useState<number[]>([]);
    const [deleteRequestedState, setDeleteRequestedState] = React.useState<boolean>(false);
    const [pageState, setPageState] = React.useState<number>(0);

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // Create paginated data
    const getPaginatedData = () : Array<BulkListItem> => {

        if (!pagination) { return itemList; }
        const startIndex = pageState * pagination.size;
        const endIndex = startIndex + pagination.size;
        return itemList.slice(startIndex, endIndex);
    };

    // Remove one item or more items, then call callback
    const handleRemove = async (item: BulkListItem|null) => {

        const result = item ? await callbacks.callBackRemoveOne(item) : await callbacks.callBackRemoveBulk(selectedToDeleteState, t);
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        setDeleteRequestedState(false);
        if (item) { callbacks.removedOneCallback(item); }
        else { callbacks.removedBulkCallback(); }
    }

    // Decide what to do when clicked on element in the grid
    const handleClickOnItem = (item: BulkListItem) => {

        if (deleteRequestedState) {
            const currentSelectedToDeleteState = [...selectedToDeleteState];
            const index = currentSelectedToDeleteState.indexOf(item.id);
            if (index > -1) { currentSelectedToDeleteState.splice(index, 1); }
            else { currentSelectedToDeleteState.push(item.id); }
            setSelectedToDeleteState(currentSelectedToDeleteState);
        } else {
            callbacks.onItemClickCallback(item);
        }
    }

    // Initialize data
    const paginatedData = getPaginatedData();

    return (
        <div className={`BulkList`}>
            <div className="BulkList-functions">
                <div className="BulkList-functions-left">
                    <div className="BulkList-functions-left-title">
                        <T keyName={titleTranslationString}/>
                    </div>
                </div>
                <div className="BulkList-functions-right">
                    {paginatedData.length > 0 &&
                        <div className="BulkList-functions-right-item">
                            <Button
                                onClick={() => { setDeleteRequestedState(!deleteRequestedState); }}
                                text={deleteRequestedState ? t('BulkList_cancel') : t('BulkList_remove')}
                                image={deleteRequestedState ? undefined : removeRedIcon}
                                type={ButtonType.FLAT_DELETE} />
                        </div>
                    }
                </div>
            </div>
            <div className="BulkList-content">
                {paginatedData.length === 0
                    ?
                    <Empty
                        type={EmptyTypeEnum.FLAT}
                        title={t(emptyTitleTranslationString)}
                        subTitle={t(emptyTextTranslationString)} />
                    :
                    <div className="BulkList-content-list">
                        <div className="BulkList-content-list-items">
                            {paginatedData.map((item: BulkListItem, index: number) => {
                                return (
                                    <div key={`BulkList-hardware-${index}`}
                                         onClick={handleClickOnItem.bind(this, item)}
                                         className="BulkList-content-list-items-item">
                                        <div className="BulkList-content-list-items-item-image">
                                            <div className="BulkList-content-list-items-item-image-inside">
                                                <div className="BulkList-content-list-items-item-image-inside-icon">
                                                    <img src={item.icon} alt={t('BulkList_item')} />
                                                </div>
                                                {!deleteRequestedState &&
                                                    <div className="BulkList-content-list-items-item-image-inside-remove single"
                                                         onClick={ (event: React.MouseEvent<HTMLDivElement>) => {
                                                             event.preventDefault(); event.stopPropagation();
                                                             void handleRemove(item);
                                                         }}>
                                                        <div className="BulkList-content-list-items-item-image-inside-remove-icon">
                                                            <img src={removeIcon} alt={t('BulkList_remove')} />
                                                        </div>
                                                    </div>
                                                }
                                                {deleteRequestedState &&
                                                    <div className="BulkList-content-list-items-item-image-inside-remove multi">
                                                        <div className="BulkList-content-list-items-item-image-inside-remove-icon">
                                                            {selectedToDeleteState.includes(item.id) &&
                                                                <img src={tickIcon} alt={t('BulkList_selected')} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="BulkList-content-list-items-item-title">
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
                {pagination &&
                    <Pagination
                        page={pageState}
                        size={pagination.size}
                        total={itemList.length}
                        callBackChangePage={(page: number) => { setPageState(page); }} />
                }
                <div className="BulkList-content-action">
                    {deleteRequestedState ?
                        <Button
                            disabled={selectedToDeleteState.length === 0}
                            onClick={handleRemove.bind(this, null)}
                            isProgress={deleteProgress}
                            text={t('BulkList_removeSelected')}
                            type={ButtonType.DELETE} />
                        :
                        <Button
                            onClick={() => { dispatch(dispatchShowAddModal()); }}
                            text={t(assignButtonTranslationString)}
                            type={ButtonType.PRIMARY} />
                    }
                </div>
            </div>
        </div>
    );
}

export default BulkList;
