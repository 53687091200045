import {Communicator, RequestType} from "../utils/Communicator";
import {AlertResponseDto} from "../entities/Responses/AlertResponseDto";
import {AlertDataResponseDto} from "../entities/Responses/AlertDataResponseDto";
import {CacheExpiry} from "../utils/Storage";
import {UpdateAlertRequestDto} from "../entities/Requests/UpdateAlertRequestDto";
import {AlertCountResponseDto} from "../entities/Responses/AlertCountResponseDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class AlertRepository {

    /**
     * Get alert list
     */
    public static async getAlerts() : Promise<Array<AlertResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "alert",
            cache: {cacheKey: 'getAlerts', expiry: CacheExpiry.FIVE_SECONDS, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get alert by id
     * @param {number} alertId - alert ID to get
     */
    public static async getAlert(alertId: number) : Promise<AlertResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `alert/${alertId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Delete alert by ID
     * @param {number} alertId - id to delete
     * @param {TranslatorType} t - translator object
     */
    public static async deleteAlert(alertId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `alert/${alertId}`,
            cache: {deleteKeys: ['getAlerts']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('AlertRepository_deleteAlert'));
    }

    /**
     * Get alert stats for graph
     * @param {number} alertId - alert id to get stats for
     */
    public static async getAlertStatList(alertId: number) : Promise<Array<AlertDataResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `alert/${alertId}/data`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Change alert state
     * @param {UpdateAlertRequestDto} request - request payload with note and state
     * @param {number} alertId - ID of alert to change
     * @param {TranslatorType} t - translator obj
     */
    public static async updateAlert(alertId: number, request: UpdateAlertRequestDto, t: TranslatorType) : Promise<AlertResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            body: request,
            endpoint: `alert/${alertId}`,
            cache: {deleteKeys: ['getAlerts']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('AlertRepository_updateAlert'))
    }

    /**
     * Change alert read state
     * @param {number} alertId - ID of alert to change
     * @param {TranslatorType} t - translator obj
     */
    public static async readAlert(alertId: number, t: TranslatorType) : Promise<AlertResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `alert/${alertId}/read`,
            cache: {deleteKeys: ['getAlertActiveCount', 'getAlerts']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('AlertRepository_readAlert'))
    }

    /**
     * Get alert active count list
     * @return AlertCountResponseDto
     * @param {boolean} fresh - don't use cache
     */
    public static async getAlertActiveCount(fresh: boolean) : Promise<AlertCountResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "alert/new/count",
            cache: {cacheKey: 'getAlertActiveCount', expiry: CacheExpiry.FIVE_SECONDS, useCache: !fresh}
        });

        if (response.ok) { return response.data; }
        return null;
    }
}
