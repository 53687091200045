import { createAsyncThunk } from "@reduxjs/toolkit";
import {PasswordResetRequestDto} from "../../../models/entities/Requests/PasswordResetRequestDto";
import {AuthorizationRepository} from "../../../models/repositories/AuthorizationRepository";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Recovery user password
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{request: PasswordResetRequestDto, t: TranslatorType}} request - recovery request
 * @return {void|string}
 */
export const actionRecoveryPassword = createAsyncThunk<void|string, {request: PasswordResetRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "recoveryBlock/recoveryPassword",
    async (payload: {request: PasswordResetRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await AuthorizationRepository.reset(payload.request, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
