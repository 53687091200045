import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {actionSetMessagingToken} from "../../store/actions/extra/mainSliceAction";
import {Storage} from "../../models/utils/Storage"
import {captureException} from "@sentry/react";
import {MessagingUtil} from "../../models/utils/MessagingUtil";
import {DispatchType} from "../../models/entities/Utils/Redux";
import {TranslatorType} from "../../models/entities/Utils/Translator";
import {FirebaseApp} from "@firebase/app";
import {deleteToken, MessagePayload, Messaging} from "@firebase/messaging";
import {actionSendFireBaseToken} from "../../store/actions/organisms/loginBlockAction";
import {mainSlice} from "../../store/slices/extra/mainSlice";

interface FirebaseHandlerParams {
    firebase: FirebaseApp;
    dispatch: DispatchType;
    translator: TranslatorType;
}

/**
 * Get token from FCB API
 * @param {Messaging} messaging - messaging API
 * @param {DispatchType} dispatch - Redux dispatch
 * @param {TranslatorType} translator - Translator
 */
const firebaseHandlerGetToken = async (messaging: Messaging, dispatch: DispatchType, translator: TranslatorType): Promise<void> => {

    try {
        let currentToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_FB_WEB_PUSH_CERT});
        if (currentToken) {
            console.info('[FCM] Token is:', currentToken);
            dispatch(actionSetMessagingToken(currentToken));
            const authToken = Storage.getToken();
            if (authToken) {
                const resultFcmToken = await dispatch(actionSendFireBaseToken(
                    {fcmToken: {token: currentToken}, authToken: authToken, t: translator}));
                if (typeof resultFcmToken.payload === "string") {
                    dispatch(mainSlice.actions.setTopLevelErrorMessage({message: resultFcmToken.payload}));
                }
            }
        } else { console.warn('[FCM] Missing token'); }
    } catch (err: any) {
        captureException(err);
        console.warn('[FCM] An error occurred while retrieving token. ', err);
    }
}

/**
 * Handle FCM functionality
 * @param {FirebaseApp} firebase - Firebase APP
 * @param {DispatchType} dispatch - Redux dispatch
 * @param {TranslatorType} translator - Translator
 */
export const firebaseHandler = ({firebase, dispatch, translator}: FirebaseHandlerParams) => {

    // If notifications disabled in config
    if (process.env.REACT_APP_ENABLE_FCM !== 'yes') { return; }
    const messaging =  getMessaging(firebase);
    const messageUtil = new MessagingUtil(dispatch, translator);

    // Permission check and handle
    navigator.permissions.query({name: 'notifications'}).then(function(permission) {

        // On page init
        if (permission.state === "granted") {
            console.info('[FCM] Requesting token');
            void firebaseHandlerGetToken(messaging, dispatch, translator);
        } else {
            console.info('[FCM] Removing token if any');
            void deleteToken(messaging);
            dispatch(actionSetMessagingToken(null));
        }

        // On permission change
        permission.onchange = function() {
            if (this.state === "granted") {
                console.info('[FCM] Requesting token');
                void firebaseHandlerGetToken(messaging, dispatch, translator);
            } else {
                console.info('[FCM] Removing token');
                void deleteToken(messaging);
                dispatch(actionSetMessagingToken(null));
            }
        };
    });

    // On FE message callback
    onMessage(messaging,  (payload: MessagePayload) => {

        console.info('[FCM] App notification:', payload);
        if (Storage.getSession() === null) { return; }
        messageUtil.resolveMessage(payload, false, false);
    });

    // Set reference for client in worker
    navigator.serviceWorker.getRegistrations().then((registrations) => {

        const worker = registrations[0] && registrations[0].active ? registrations[0].active : null;
        if (!worker) { console.warn('[FCM] Wrong worker state:', worker); return; }
        console.info('[FCM] Creating tunnel');
        worker.postMessage({register: 'Client successfully registered.'});

        setInterval(() => {
            console.info('[FCM] Refreshing tunnel');
            worker.postMessage({register: 'Refreshed client registration.'});
        }, process.env.REACT_APP_WORKER_TUNEL_REFRESH_INTERVAL_MS ? parseInt(process.env.REACT_APP_WORKER_TUNEL_REFRESH_INTERVAL_MS) : 60000);
    });

    // Translate messages from worker
    navigator.serviceWorker.addEventListener("message", async (event) => {

        const registrations = await navigator.serviceWorker.getRegistrations();
        const worker = registrations[0].active;
        if (!worker) { return; }

        const notifyMessage = messageUtil.resolveMessage(event.data, true, document.visibilityState !== "visible");
        if (document.visibilityState !== "visible" && notifyMessage) {
            worker.postMessage({notifyMessage: notifyMessage});
            return;
        }
    });
}

