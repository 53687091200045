import {createSlice} from '@reduxjs/toolkit'
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {
    actionBanZoneInTemplate,
    actionCreateZone,
    actionDeleteZone,
    actionGetZoneList,
    actionUnbanZoneInTemplate,
    actionUndeleteZone,
    actionUpdateZone
} from "../../actions/data/zoneAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface ZoneState {

    /** List of zones **/
    zoneList?: Array<ZoneDto>|null,
}

const initialState: ZoneState = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const zoneDataSlice = createSlice({
    name: 'zoneData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetZoneList.fulfilled, (state, {payload}) => {
            state.zoneList = payload;
        })
        builder.addCase(actionBanZoneInTemplate.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload; }
        })
        builder.addCase(actionUnbanZoneInTemplate.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload; }
        })
        builder.addCase(actionCreateZone.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload; }
        })
        builder.addCase(actionUpdateZone.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload.zoneList; }
        })
        builder.addCase(actionDeleteZone.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload; }
        })
        builder.addCase(actionUndeleteZone.fulfilled, (state, {payload}) => {
            if (typeof payload !== "string") { state.zoneList = payload; }
        })
    }
})

export default zoneDataSlice.reducer
