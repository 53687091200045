import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CustomDraggableAllowedContent} from "../../../components/molecules/CustomDraggable/CustomDraggable";
import {AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface BuildingFullScreenState {

    /** Allowed map bounds **/
    draggableAllowedContent: CustomDraggableAllowedContent|null,
    /** Currently active hardware **/
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    /** Current zoom level **/
    zoom: number,
    /** View is initialized **/
    initialized: boolean
}

const initialState: BuildingFullScreenState = {

    draggableAllowedContent: null,
    activeHardware: null,
    zoom: 1,
    initialized: false,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingFullScreenOrganismSlice = createSlice({
    name: 'buildingFullScreen',
    initialState,
    reducers: {
        updateZoomValue: (state, action: PayloadAction<{ zoom: number }>) => {
            state.zoom = action.payload.zoom;
        },
        updateDraggableAllowedContent: (state, action: PayloadAction<CustomDraggableAllowedContent>) => {
            state.draggableAllowedContent = action.payload;
        },
        updateActiveHardware: (state, action: PayloadAction<AbstractBuildingUpdateActivePayload>) => {
            state.activeHardware = action.payload;
        },
        setInitialized: (state) => {
            state.initialized = true;
        },
    },
})

export default buildingFullScreenOrganismSlice.reducer