import { createAsyncThunk } from "@reduxjs/toolkit";
import {UserRepository} from "../../../models/repositories/UserRepository";
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {UserCreateRequestDto} from "../../../models/entities/Requests/UserCreateRequestDto";
import {UserUpdateRequestDto} from "../../../models/entities/Requests/UserUpdateRequestDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";
import {NotifyOptionsDto} from "../../../models/entities/NotifyOptionsDto";
import {NotifyOptionsRequestDto} from "../../../models/entities/Requests/NotifyOptionsRequestDto";

/**
 * Get user list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {UserResponseDto[]|null}
 */
export const actionGetUserList = createAsyncThunk<Array<UserResponseDto> | null>(
    "user/getUserList",
    async () => {
        return await UserRepository.getUserList();
    }
);

/**
 * Delete user by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{userId: number, t: TranslatorType}}
 * @return {UserResponseDto[]|null}
 */
export const actionDeleteUser = createAsyncThunk<Array<UserResponseDto> | null | string,
    {userId: number, t: TranslatorType}, {rejectValue: string}>(
    "user/deleteUser",
    async (payload: {userId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await UserRepository.deleteUser(payload.userId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await UserRepository.getUserList();
    }
);

/**
 * Create new user
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{userRequest: UserCreateRequestDto, t: TranslatorType}}
 * @return {UserResponseDto[]|null|string}
 */
export const actionCreateUser = createAsyncThunk<Array<UserResponseDto> | null | string,
    {userRequest: UserCreateRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "user/createUser",
    async (payload: {userRequest: UserCreateRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await UserRepository.createUser(payload.userRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await UserRepository.getUserList();
    }
);

/**
 * Update user by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{userId: number, userRequest: UserUpdateRequestDto, t: TranslatorType}} updateRequest - user update info
 * @return {UserResponseDto[]|null|string}
 */
export const actionUpdateUser = createAsyncThunk<Array<UserResponseDto> | null | string,
    {userId: number, userRequest: UserUpdateRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "user/updateUser",
    async (payload: {userId: number, userRequest: UserUpdateRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await UserRepository.updateUser(payload.userId, payload.userRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await UserRepository.getUserList();
    }
);


/**
 * Get user notify options by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} userId - user ID
 * @return {NotifyOptionsDto | null}
 */
export const actionGetUserNotifyOptions = createAsyncThunk<NotifyOptionsDto | null, number>(
    "user/getUserNotifyOptions",
    async (userId: number) => {
        return await UserRepository.getUserNotificationOptions(userId);
    }
);

/**
 * Update user notify options by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{userId: number, request: NotifyOptionsRequestDto, t: TranslatorType}} request - user notify options update info
 * @return {NotifyOptionsDto|null|string}
 */
export const actionUpdateUserNotifyOptions = createAsyncThunk<NotifyOptionsDto | null | string,
    {userId: number, request: NotifyOptionsRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "user/updateUserNotifyOptions",
    async (payload: {userId: number, request: NotifyOptionsRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { return await UserRepository.updateUserNotificationOptions(payload.userId, payload.request, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
