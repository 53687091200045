import React from "react";
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {ViewHive} from "../../organisms/ViewHive/ViewHive";
import {useAppSelector} from "../../../store/hooks";
import {dashboardPageSlice} from "../../../store/slices/pages/dashboardPageSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type DashboardTemplateProps = {
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {RoleEnum} role - logged user role
 */
export const DashboardTemplate = ({role} : DashboardTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const filterTypeState = useAppSelector((state) => state.dashboardPage.filterType);
    const filterPatientState = useAppSelector((state) => state.dashboardPage.patientId);
    const filterFullTextState = useAppSelector((state) => state.dashboardPage.fullTextValue);
    const filterSensorState = useAppSelector((state) => state.dashboardPage.sensorType);
    const templateIdState = useAppSelector((state) => state.dashboardPage.templateId);
    const wearableState = useAppSelector((state) => state.dashboardPage.wearable);
    const filterTitleState = useAppSelector((state) => state.dashboardPage.filterTitle);
    const elevationState = useAppSelector((state) => state.dashboardPage.elevation);

    return (
        <main>
            <div className="layout">
                <Helmet>
                    <title>{t('DashboardTemplate_title')}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('DashboardTemplate_title')}
                        easySearch={{
                            searchValue: filterFullTextState,
                            dispatchSearchValueUpdate: dashboardPageSlice.actions.filterFullText
                        }}
                        breadItems={[
                            {name: t('Navigation_dashboard'), link: null},
                        ]} />
                    <div className="layout-right-content flex fullHeight">
                        <div className="layout-right-content-item fill fullHeight">
                            <ViewHive
                                role={role}
                                filter={{
                                    filterTitle: filterTitleState,
                                    fullTextValue: filterFullTextState ? filterFullTextState.toLowerCase() : null,
                                    type: filterTypeState,
                                    patient: filterPatientState,
                                    sensor: filterSensorState,
                                    templateId: templateIdState,
                                    wearable: wearableState,
                                    elevation: elevationState,
                                    dispatchFilterElevation: dashboardPageSlice.actions.filterElevation,
                                    dispatchFilterTemplate: dashboardPageSlice.actions.filterTemplate,
                                    dispatchFilterWearable: dashboardPageSlice.actions.filterWearable,
                                    dispatchFilterSensor: dashboardPageSlice.actions.filterSensor,
                                    dispatchFilterPatient: dashboardPageSlice.actions.filterPatient,
                                    dispatchFilterAll: dashboardPageSlice.actions.filterAll,
                                    dispatchFilterAssigned: dashboardPageSlice.actions.filterAssigned,
                                    dispatchFilterFree: dashboardPageSlice.actions.filterFree
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default DashboardTemplate;
