import "./ErrorComponent.scss";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import logo from "../../../images/logo.svg";
import React from "react";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {useTranslate} from "@tolgee/react";

/**
 * @enum {number}
 * @category Components
 */
export enum ErrorComponentVariant {
    INTERNAL = 1,
    UNAUTHORIZED = 2
}

/**
 * @enum {number}
 * @category Components
 */
export enum ErrorComponentTypeEnum {
    MODAL = 1,
    CARD,
    FULL,
    FLAT
}

/**
 * @alias ErrorComponentModal
 * @category Components
 */
type ErrorComponentModal = {

    /** Redux hide modal callback **/
    dispatchHide: ActionCreatorWithoutPayload,
    /** Modal title **/
    name: string
}

type ErrorComponentProps = {
    type: ErrorComponentTypeEnum,
    modal?: ErrorComponentModal
    title: string,
    subTitle: string,
    variant?: ErrorComponentVariant
}

/**
 * @component
 * @category Components
 * @subcategory Extras
 * @param {ErrorComponentTypeEnum} type - view type
 * @param {ErrorComponentModal|undefined} modal - modal options
 * @param {string} title - title text
 * @param {string} subTitle - subtitle text
 * @param {ErrorComponentVariant|undefined} variant - error variant
 * @todo implement variant
 */
export const ErrorComponent = ({type, modal, title, subTitle} : ErrorComponentProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    if (type === ErrorComponentTypeEnum.MODAL && modal) {
        return (
            <div className={`ErrorComponent-modal`}>
                <div className={`ErrorComponent-modal-content`}>
                    <div className={`ErrorComponent-modal-content-title`}>
                        {modal.name &&
                            <div className={`ErrorComponent-modal-content-title-text`}>
                                {modal.name}
                            </div>
                        }
                        <div className={`ErrorComponent-modal-content-title-close`}>
                            <Image onClick={() => { if (modal.dispatchHide) { dispatch(modal.dispatchHide()); } }}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        </div>
                    </div>
                    <div className={`ErrorComponent-modal-content-block`}>
                        <div className={`ErrorComponent-modal-content-block-inside`}>
                            <div className="ErrorComponent-modal-content-block-inside-image modal">
                                <img src={logo} alt={t('ErrorComponent_logo')} />
                            </div>
                            <div className="ErrorComponent-modal-content-block-inside-title">
                                {title}
                            </div>
                            <div className="ErrorComponent-modal-content-block-inside-subTitle">
                                {subTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === ErrorComponentTypeEnum.FULL) {
        return (
            <div className={`ErrorComponent-full`}>
                <div className={`ErrorComponent-full-content`}>
                    <div className="ErrorComponent-full-content-block">
                        <div className="ErrorComponent-full-content-block-image full">
                            <img src={logo} alt={t('Error_logo')} />
                        </div>
                        <div className="ErrorComponent-full-content-block-title">
                            {title}
                        </div>
                        <div className="ErrorComponent-full-content-block-subTitle">
                            {subTitle}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type === ErrorComponentTypeEnum.CARD) {
        return (
            <div className={`ErrorComponent-card`}>
                <div className={`ErrorComponent-card-content`}>
                    <div className={`ErrorComponent-card-content-title weak`}>
                    </div>
                    <div className="ErrorComponent-card-content-block">
                        <div className="ErrorComponent-card-content-block-inside">
                            <div className="ErrorComponent-card-content-block-inside-image card">
                                <img src={logo} alt={'Brand'}/>
                            </div>
                            <div className="ErrorComponent-card-content-block-inside-title">
                                {title}
                            </div>
                            <div className="ErrorComponent-card-content-block-inside-subTitle">
                                {subTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`ErrorComponent-flat`}>
            <div className={`ErrorComponent-flat-content`}>
                <div className="ErrorComponent-flat-content-block">
                    <div className="ErrorComponent-flat-content-block-inside">
                        <div className="ErrorComponent-flat-content-block-inside-image card">
                            <img src={logo} alt={'Brand'} />
                        </div>
                        <div className="ErrorComponent-flat-content-block-inside-title">
                            {title}
                        </div>
                        <div className="ErrorComponent-flat-content-block-inside-subTitle">
                            {subTitle}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorComponent;
