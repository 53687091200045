import "./CustomSelect.scss"
import React, {useEffect} from "react";
import Select, {ClearIndicatorProps, components, DropdownIndicatorProps, SingleValue} from 'react-select'
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {Image} from "../Image/Image";
import search from "../../../images/arrowDownSmallGrey.svg";
import clear from "../../../images/close.svg";
import {AbstractFormUpdateCallBack, AbstractFormUpdatePayload, InputState} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";

const DropdownIndicator = (props: DropdownIndicatorProps<CustomSelectValue, false>) => {
    return (
        <components.DropdownIndicator {...props} className={ props.hasValue ? 'hidden' : 'shown'}>
            {!props.hasValue
                ? <Image className={'CustomSelect-searchIcon'} description={'Search'} source={search}/>
                : <div> </div>
            }
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props: ClearIndicatorProps<CustomSelectValue, false>) => {
    return (
        <components.ClearIndicator {...props}>
            {props.hasValue
                ? <Image className={'CustomSelect-clearIcon'} description={'Clear'} source={clear}/>
                : <div> </div>
            }
        </components.ClearIndicator>
    );
};

/**
 * @interface
 * @todo translations
 * @category Components
 */
export interface CustomSelectValue {

    /** Select value as number **/
    value: number,
    /** Select displayed text **/
    label: string
}

type CustomSelectProps = {
    inputState: InputState|null,
    label?: string,
    disabled?: boolean,
    placeholder: string,
    inputName: string,
    notFullWidth?: boolean,
    values: Array<CustomSelectValue>,
    defaultValue?: number | null,
    onChange?: AbstractFormUpdateCallBack,
    dispatchOnChange?: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    required?: boolean,
    clearable?: boolean,
    dispatchOnFocus?: ActionCreatorWithPayload<{inputName: string}>,
    dispatchOnBlur?: ActionCreatorWithPayload<{inputName: string}>,
    withoutValidations?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string|undefined} label - label before select element
 * @param {string} placeholder - placeholder for select
 * @param {string} inputName - select input name
 * @param {CustomSelectValue[]} values - options used
 * @param {number|null|undefined} defaultValue - default option value
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>|undefined} dispatchOnChange - dispatch event called on change
 * @param {InputState|null} inputState - Redux input state
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnBlur - on blur event dispatch
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnFocus - on focus event dispatch
 * @param {boolean|undefined} required - value is required
 * @param {boolean|undefined} clearable - select can be cleared
 * @param {AbstractFormUpdateCallBack|undefined} onChange - on change callback
 * @param {boolean|undefined} notFullWidth - don't fill up with full width
 * @param {boolean|undefined} disabled - disabled input
 * @param {boolean|undefined} withoutValidations - don't validate
 */
export const CustomSelect = ({label, placeholder, inputName, values, defaultValue, dispatchOnChange, inputState, dispatchOnBlur, dispatchOnFocus,
    required, clearable, onChange, notFullWidth, disabled, withoutValidations}: CustomSelectProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // On init propagate default value to Redux
    useEffect(() => {
        if (dispatchOnChange) {
            dispatch(dispatchOnChange({inputName: inputName, inputValue: defaultValue ? defaultValue : null, t: t}));
        }
    }, [dispatch, inputName, dispatchOnChange, defaultValue, t]);

    // On change update Redux store value and call basic callback
    const handleChange = (newValue: SingleValue<CustomSelectValue>) => {

        if (onChange) { onChange(inputName, newValue ? newValue.value : null); }
        if (dispatchOnChange) {
            dispatch(dispatchOnChange({inputName: inputName, inputValue: newValue ? newValue.value : null, t: t}));
        }
    }

    // Input is focused && blurred
    const handleFocus = () => { if (dispatchOnFocus) { dispatch(dispatchOnFocus({inputName: inputName})); } }
    const handleBlur = () => { if (dispatchOnBlur) { dispatch(dispatchOnBlur({inputName: inputName})); } }

    // Get current default value state
    const defaultValuesParsed = defaultValue !== null && typeof defaultValue !== 'undefined'
        ? values.filter((value: CustomSelectValue) => { return value.value === defaultValue; }) : null;
    const defaultValueParsed = defaultValuesParsed && defaultValuesParsed.length === 1 ? defaultValuesParsed[0] : null;

    // Get current layout state
    const valid = inputState && typeof inputState.invalid !== 'undefined' && !inputState.invalid;
    const invalid = inputState && typeof inputState.invalid !== 'undefined' && inputState.invalid;
    const showValid = (valid && defaultValueParsed)  || (inputState && valid && inputState.validationShown);

    // Show invalid if 1) not focused AND (has some value OR validations shown forced)
    const showInvalid = inputState && inputState.focused === false && invalid && (defaultValueParsed !== null || inputState.validationShown);

    return (
        <div className={`CustomSelect
            ${!withoutValidations && showValid ? 'ok' : ''}
            ${disabled ? 'disabled' : ''}
            ${notFullWidth ? 'notFullWidth' : ''}
            ${!withoutValidations && showInvalid ? 'nok' : ''}
            ${defaultValuesParsed === null ? 'withoutDefault' : 'withDefault'}`}>

            <label className="CustomSelect-label">
                {label &&
                    <div className={`CustomSelect-label-block`}>
                        {label} {required ? "*" : ''}
                    </div>
                }
                <div className={`CustomSelect-label-select ${invalid ? 'invalid' : 'valid'}`}>
                    <Select
                        isDisabled={disabled}
                        components={{DropdownIndicator, ClearIndicator}}
                        options={values}
                        placeholder={placeholder}
                        name={inputName}
                        onFocus={handleFocus.bind(this)}
                        onBlur={handleBlur.bind(this)}
                        isClearable={clearable}
                        onChange={handleChange.bind(this)}
                        value={defaultValueParsed ? defaultValueParsed : null}
                        className="CustomSelect-label-select-element"
                        classNamePrefix="CustomSelect-label-select-element"/>
                </div>
            </label>

            {!withoutValidations &&
                <div className={"error"}>
                    {showInvalid && inputState?.errors ? '*' + inputState.errors : ''}&nbsp;
                </div>
            }

        </div>
    );
}

export default CustomSelect;
