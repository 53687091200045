import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionSendSMSMessage} from "../../actions/organisms/smsMenuAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface SMSMenuState extends AbstractFormReducersState {}

const initialState: SMSMenuState = {
    form: {
        inputs: [
            {name: 'message', validations: {required: true}},
            {name: 'phoneNumber', validations: {required: true}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const smsMenuOrganismSlice = createSlice({
    name: 'smsMenuOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}},
    extraReducers: (builder) => {
        builder.addCase(actionSendSMSMessage.fulfilled, (state) => {
            state.form.error = null;
        })
    }
})

export default smsMenuOrganismSlice.reducer
