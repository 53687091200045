import {TokenResponseDto} from "../entities/Responses/TokenResponseDto";

/**
 * @alias StorageSession
 * @category Utils
 */
export type StorageSession = TokenResponseDto;

/**
 * @interface
 * @category Utils
 */
interface CacheObject {

    /** Cache data **/
    data: any,
    /** Timestamp to expire cache **/
    expiry: number,
}

/**
 * @enum {number}
 * @category Utils
 */
export enum CacheExpiry {
    ONE_DAY = 86400000,
    FIVE_MINUTES = 300000,
    FIVE_SECONDS = 5000
}

/**
 * @class
 * @category Utils
 */
export class Storage {

    /**
     * Create new user session with auth data
     * @function
     * @category Utils
     * @param {TokenResponseDto} authorizationData - auth data
     */
    public static saveSession(authorizationData: TokenResponseDto) : void {
        localStorage.setItem('authorizationData', JSON.stringify(authorizationData));
    }

    // /**
    //  * Refresh auth data in session
    //  * @todo refresh token
    //  * @function
    //  * @category Utils
    //  */
    // public static async refreshSession(): Promise<void> {
    //
    //     const session = Storage.getSession();
    //     if (session === null) { return; }
    //     const sessionExpiry = new Date(session.expiry);
    //     const currentTimeStamp = new Date();
    //     currentTimeStamp.setDate(currentTimeStamp.getDate()+1);
    //     if (currentTimeStamp > sessionExpiry) {
    //         const tokenResponse = await UserRepository.getToken();
    //         if (tokenResponse) { Storage.saveSession(tokenResponse); }
    //     }
    // }

    /**
     * Force log-out user
     * @function
     * @category Utils
     */
    public static forcedLogOut() : void {

        localStorage.clear();
        window.location.replace('/');
    }

    /**
     * Get session for user
     * @function
     * @category Utils
     */
    public static getSession() : StorageSession|null {

        const storageData = localStorage.getItem('authorizationData');
        if (!storageData) { return null; }
        return JSON.parse(storageData);
    }

    /**
     * Get token from session of user
     * @function
     * @category Utils
     */
    public static getToken() : string|null {

        const session = Storage.getSession();
        if (session === null) { return null; }

        const currentDate = new Date();
        const tokenDate = new Date(session.expiry);
        if (tokenDate < currentDate) {
            this.forcedLogOut();
            return null;
        }

        return session.token;
    }

    /**
     * Get user from session
     * @function
     * @category Utils
     */
    public static getUser() : number|null {

        const session = Storage.getSession();
        if (session === null) { return null; }
        return session.user.id;
    }

    /**
     * Save result to cache
     * @function
     * @category Utils
     * @param {string} key - cache key
     * @param {any} data - cache data
     * @param {CacheExpiry} expiry - expiry type
     */
    public static saveResultToStorage(key: string, data: any, expiry: CacheExpiry) : void {

        const userId = this.getUser();
        if (!userId) { return; }

        const timestamp = new Date().getTime();
        const cacheObject : CacheObject = { data: data,  expiry: timestamp + expiry }

        try {
            localStorage.setItem(`cache/${userId}/${key}`, JSON.stringify(cacheObject));
        } catch (exception: unknown) {
            const authData = localStorage.getItem('authorizationData');
            localStorage.clear();
            if (!authData) { this.forcedLogOut(); return; }
            localStorage.setItem('authorizationData', authData);
        }
    }

    /**
     * Get data from cache
     * @function
     * @category Utils
     * @param {string} key - cache key
     * @param {boolean|undefined} backup - is backup mode (just case of offline)
     */
    public static getResultFromStorage(key: string, backup?: boolean) : CacheObject|null {

        const userId = this.getUser();
        if (!userId) { return null; }

        const storageData = localStorage.getItem(`cache/${userId}/${key}`);
        if (!storageData) { return null; }

        const parsedData : CacheObject = JSON.parse(storageData);
        if (backup) { return parsedData; }

        const timestamp = new Date().getTime();
        if (timestamp > parsedData.expiry) {
            return null;
        }

        return parsedData;
    }

    /**
     * Remove cache keys from cache
     * @function
     * @category Utils
     * @param {string[]} keys - cache key list
     */
    public static clearResultFromCache(keys: Array<string>) : void {

        const userId = this.getUser();
        if (!userId) { return; }
        keys.forEach((key: string) => {
            localStorage.removeItem(`cache/${userId}/${key}`);
        });
    }
}