import React, {FormEvent} from "react";
import "./CardUser.scss"
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {UserUtil} from "../../../models/utils/UserUtil";
import userIcon from "../../../images/user.svg"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {cardUserOrganismSlice} from "../../../store/slices/organisms/cardUserSlice";
import { CustomSelect } from "../../atoms/CustomSelect/CustomSelect";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {actionGetUserList, actionUpdateUser} from "../../../store/actions/data/userAction";
import {Role} from "../../molecules/Role/Role";
import {UserUpdateRequestDto} from "../../../models/entities/Requests/UserUpdateRequestDto";
import InputCheckbox from "../../atoms/InputCheckbox/InputCheckbox";
import {T, useTranslate} from "@tolgee/react";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {Storage} from "../../../models/utils/Storage";
import {ProfileUpdateRequestDto} from "../../../models/entities/Requests/ProfileUpdateRequestDto";
import {UserRepository} from "../../../models/repositories/UserRepository";

type CardUserProps =  {
    user: UserResponseDto,
    isMe: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {UserResponseDto} user - user response data
 * @param {boolean} isMe - is my profile
 */
export const CardUser = ({user, isMe} : CardUserProps): React.JSX.Element => {

    // Form state
    const storageData = Storage.getSession();
    const {t} = useTranslate();
    const firstNameState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'firstName'));
    const lastNameState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'lastName'));
    const emailState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'email'));
    const roleState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'role'));
    const branchState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'branch'));
    const phoneState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'phone'));
    const passwordState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'password'));
    const passwordAgainState = useAppSelector((state) => selectInputState(state.cardUserOrganism, 'passwordAgain'));
    const formValidState = useAppSelector((state) => selectValidState(state.cardUserOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.cardUserOrganism));
    const dispatch = useAppDispatch();

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        const parsedRole = roleState && roleState.value ? parseInt(roleState.value.toString()) : user.User.role;

        if (!firstNameState || !firstNameState.value || !lastNameState || !lastNameState.value || !emailState || !emailState.value || !parsedRole) {
            dispatch(cardUserOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const userRequest : UserUpdateRequestDto = {
            firstName: firstNameState.value.toString(),
            lastName: lastNameState.value.toString(),
            email: emailState.value.toString(),
            phone: phoneState && phoneState.value ? phoneState.value.toString() : null,
            branch: branchState && branchState.value ? branchState.value.toString() : null,
            role: isMe ? undefined : parsedRole,
        }

        let result = await dispatch(actionUpdateUser({userId: user.User.id, userRequest: userRequest, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(cardUserOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        // Update session if is me
        if (isMe) {
            const storageData = Storage.getSession();
            if (storageData) {
                storageData.user.phone = phoneState && phoneState.value ? phoneState.value.toString() : storageData.user.phone;
                storageData.user.branch = branchState && branchState.value ? branchState.value.toString() : storageData.user.branch;
                storageData.user.email = emailState.value.toString();
                storageData.user.firstName = firstNameState.value.toString();
                storageData.user.lastName = lastNameState.value.toString();
                Storage.saveSession(storageData);
            }
        }

        // Update password
        if (passwordState && passwordState.value) {

            if (!passwordAgainState || !passwordAgainState.value || (passwordState.value !== passwordAgainState.value)) {
                dispatch(cardUserOrganismSlice.actions.setFormError({error: t('CardUser_password_match')}));
                return;
            }

            const editProfileRequest: ProfileUpdateRequestDto = { password: passwordState.value.toString() }
            try { await UserRepository.editProfile(editProfileRequest, t); } catch (e: any) {
                dispatch(cardUserOrganismSlice.actions.setFormError({error: e.toString()}));
                return;
            }

            dispatch(cardUserOrganismSlice.actions.updateInput({inputName: 'password', inputValue: null, t: t}));
            dispatch(cardUserOrganismSlice.actions.updateInput({inputName: 'passwordAgain', inputValue: null, t: t}));
            dispatch(cardUserOrganismSlice.actions.setFormError({error: null}));
            dispatch(mainSlice.actions.setTopLevelSuccessMessage(
                {message: t('CardUser_profile_with_password_updated')}))
            dispatch(actionGetUserList());
            return;
        }

        // Success
        dispatch(cardUserOrganismSlice.actions.setFormError({error: null}));
        dispatch(mainSlice.actions.setTopLevelSuccessMessage(
            {message: t(isMe ? 'CardUser_profile_updated' : 'CardUser_user_info_updated')}))
        dispatch(actionGetUserList());
    }

    const disabledBecauseOfCurrentUser = storageData === null || storageData.user.id === user.User.id;

    return (
        <div className={`CardUser`}>
            <div className="CardUser-content">
                <div className="CardUser-content-firstLine">
                    <div className="CardUser-content-firstLine-left">
                        <div className="CardUser-content-firstLine-left-image">
                            <img src={userIcon} alt={t('CardUser_profile')} />
                        </div>
                        <div className="CardUser-content-firstLine-left-name">
                            <div className="CardUser-content-firstLine-left-name-fullName">
                                {user.User.firstName} {user.User.lastName}
                            </div>
                            <div className={`CardUser-content-firstLine-left-name-role`}>
                               <Role role={user.User.role} />
                            </div>
                        </div>
                    </div>
                    <div className="CardUser-content-firstLine-parameters">
                        <div className="CardUser-content-firstLine-parameters-right">
                            <div className="CardUser-content-firstLine-parameters-right-first">
                                <div className="CardUser-content-firstLine-parameters-right-item item">
                                    <div className="CardUser-content-firstLine-parameters-right-item-content">
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-name">
                                            <T keyName={'CardUser_email'}/>
                                        </div>
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-value">
                                            {user.User.email}
                                        </div>
                                    </div>
                                </div>

                                <div className="CardUser-content-firstLine-parameters-right-item item">
                                    <div className="CardUser-content-firstLine-parameters-right-item-content">
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-name">
                                            <T keyName={'CardUser_role'}/>
                                        </div>
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-value">
                                            <Role role={user.User.role} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="CardUser-content-firstLine-parameters-right-second">
                                <div className="CardUser-content-firstLine-parameters-right-item item">
                                    <div className="CardUser-content-firstLine-parameters-right-item-content">
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-name">
                                            <T keyName={'CardUser_phone'}/>
                                        </div>
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-value">
                                            {user.User.phone ?? '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="CardUser-content-firstLine-parameters-right-item item">
                                    <div className="CardUser-content-firstLine-parameters-right-item-content">
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-name">
                                            <T keyName={'CardUser_branch'}/>
                                        </div>
                                        <div className="CardUser-content-firstLine-parameters-right-item-content-value">
                                            {user.User.branch ?? '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CardUser-content-secondLine">
                    <div className="CardUser-content-secondLine-title">
                        <T keyName={'CardUser_title'} />
                    </div>
                    <div className="CardUser-content-secondLine-form">
                        <form onSubmit={handleSubmit.bind(this)}>
                            <div className="CardUser-content-secondLine-form-inputs">
                                <div className="CardUser-content-secondLine-form-inputs-flex">
                                    <InputText
                                        disabled={disabledBecauseOfCurrentUser}
                                        required={true}
                                        placeholder={t('CardUser_firstName_placeholder')}
                                        inputName="firstName"
                                        inputType={InputTextType.TEXT}
                                        label={t('CardUser_firstName_label')}
                                        startValue={user.User.firstName}
                                        value={firstNameState?.value}
                                        inputState={disabledBecauseOfCurrentUser ? null : firstNameState}
                                        dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus} />
                                    <InputText
                                        disabled={disabledBecauseOfCurrentUser}
                                        required={true}
                                        placeholder={t('CardUser_lastName_placeholder')}
                                        inputName="lastName"
                                        inputType={InputTextType.TEXT}
                                        label={t('CardUser_lastName_label')}
                                        startValue={user.User.lastName}
                                        value={lastNameState?.value}
                                        inputState={disabledBecauseOfCurrentUser ? null : lastNameState}
                                        dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus} />
                                    <InputText
                                        disabled={disabledBecauseOfCurrentUser}
                                        required={true}
                                        placeholder={t('CardUser_email_placeholder')}
                                        inputName="email"
                                        inputType={InputTextType.TEXT}
                                        label={t('CardUser_email_label')}
                                        startValue={user.User.email}
                                        value={emailState?.value}
                                        inputState={disabledBecauseOfCurrentUser ? null : emailState}
                                        dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus} />
                                    {!isMe &&
                                        <CustomSelect
                                            disabled={disabledBecauseOfCurrentUser}
                                            required={true}
                                            values={[
                                                {
                                                    label: UserUtil.getRoleFEMetaFromRole(RoleEnum.ADMINISTRATOR, t).text,
                                                    value: RoleEnum.ADMINISTRATOR
                                                }, {
                                                    label: UserUtil.getRoleFEMetaFromRole(RoleEnum.TECHNICAL, t).text,
                                                    value: RoleEnum.TECHNICAL
                                                }, {
                                                    label: UserUtil.getRoleFEMetaFromRole(RoleEnum.MEDICAL, t).text,
                                                    value: RoleEnum.MEDICAL
                                                },
                                            ]}
                                            label={t('CardUser_role_label')}
                                            notFullWidth={true}
                                            inputName={'role'}
                                            inputState={disabledBecauseOfCurrentUser ? null : roleState}
                                            dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                            defaultValue={user.User.role}
                                            placeholder={t('CardUser_role_placeholder')}
                                            dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                            dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus}/>
                                    }
                                    <InputText
                                        disabled={disabledBecauseOfCurrentUser}
                                        placeholder={t('CardUser_branch_placeholder')}
                                        inputName="branch"
                                        inputType={InputTextType.TEXT}
                                        label={t('CardUser_branch_label')}
                                        startValue={user.User.branch}
                                        value={branchState?.value}
                                        inputState={disabledBecauseOfCurrentUser ? null : branchState}
                                        dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus} />
                                    <InputText
                                        disabled={disabledBecauseOfCurrentUser}
                                        placeholder={t('CardUser_phone_placeholder')}
                                        inputName="phone"
                                        inputType={InputTextType.TEXT}
                                        label={t('CardUser_phone_label')}
                                        startValue={user.User.phone}
                                        value={phoneState?.value}
                                        inputState={disabledBecauseOfCurrentUser ? null : phoneState}
                                        dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                        dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                        dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus} />
                                </div>
                                {isMe &&
                                    <div className="CardUser-content-secondLine-form-inputs-title">
                                        <T keyName={'CardUser_change_password'} />
                                    </div>
                                }
                                {isMe &&
                                    <div className="CardUser-content-secondLine-form-inputs-flex">
                                        <InputText
                                            disabled={disabledBecauseOfCurrentUser}
                                            required={false}
                                            placeholder={t('CardUser_password_placeholder')}
                                            inputName="password"
                                            inputType={InputTextType.PASSWORD}
                                            label={t('CardUser_password_label')}
                                            value={passwordState?.value}
                                            inputState={disabledBecauseOfCurrentUser ? null : passwordState}
                                            dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                            dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                            dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus}/>
                                        <InputText
                                            disabled={disabledBecauseOfCurrentUser}
                                            required={false}
                                            placeholder={t('CardUser_passwordAgain_placeholder')}
                                            inputName="passwordAgain"
                                            inputType={InputTextType.PASSWORD}
                                            label={t('CardUser_passwordAgain_label')}
                                            value={passwordAgainState?.value}
                                            inputState={disabledBecauseOfCurrentUser ? null : passwordAgainState}
                                            dispatchOnChange={cardUserOrganismSlice.actions.updateInput}
                                            dispatchOnFocus={cardUserOrganismSlice.actions.setFocus}
                                            dispatchOnBlur={cardUserOrganismSlice.actions.removeFocus}/>
                                    </div>
                                }
                                {!isMe &&
                                    <div className="CardUser-content-secondLine-form-inputs-2fa">
                                        <div className="CardUser-content-secondLine-form-inputs-2fa-checkbox">
                                            <InputCheckbox
                                                label={t('CardUser_require2FA')}
                                                inputName={'2fa'}
                                                onChangeCallBack={(inputName: string, checked: boolean) => {
                                                    dispatch(cardUserOrganismSlice.actions.updateInput({
                                                        inputName: inputName,
                                                        inputValue: checked ? 1 : 0,
                                                        t: t
                                                    }));
                                                }}/>
                                        </div>
                                    </div>
                                }
                                {formErrorState && <span className="formError">{formErrorState}</span>}
                            </div>
                            <div className="CardUser-content-secondLine-form-button">
                                <Button
                                    onClickDisabled={() => { dispatch(cardUserOrganismSlice.actions.showInputValidStates()); }}
                                    text={t('CardUser_button')}
                                    type={ButtonType.PRIMARY}
                                    disabled={!formValidState || disabledBecauseOfCurrentUser}
                                    isSubmit={true} />
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CardUser;
