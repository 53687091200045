import React, {useEffect} from "react";
import "./CardAlert.scss"
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {Image} from "../../atoms/Image/Image";
import limitDown from "../../../images/arrowDown.svg";
import limitUp from "../../../images/arrowUp.svg";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {DataParser} from "../../../models/utils/DataParser";
import {T, useTranslate} from "@tolgee/react";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import building from "../../../images/building.svg";
import {Button, ButtonType} from "../../atoms/Button/Button";
import eye from "../../../images/eye.svg";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";
import {actionReadAlert} from "../../../store/actions/data/alertAction";

type CardAlertProps =  {
    role: RoleEnum
    alert: AlertResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    dispatchUpdateActiveHardwarePlan: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchUpdateActiveHardwareEditor: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    performReadCallback: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {AlertResponseDto} alert - alert data response
 * @param {SensorTypeDto[]} sensorTypes - sensor types list
 * @param {RoleEnum} role - logged user role
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardwareEditor - dispatch to set active HW in building editor
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardwarePlan - dispatch to set active HW in building plan
 * @param {boolean} performReadCallback - call set read callback
 */
export const CardAlert = ({alert, sensorTypes, role, dispatchUpdateActiveHardwareEditor, dispatchUpdateActiveHardwarePlan, performReadCallback}
    : CardAlertProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const {t} = useTranslate();

    const sensorType = DataParser.getSensorTypeFromList(sensorTypes, alert.SensorTemplate.type);
    const durationParsed = ValueUtil.getDurationFromMillisecondsWithText(
        new Date(alert.Alert.lastOccurrenceAt),
        new Date(alert.Alert.firstOccurrenceAt),
        t
    );

    // Set alert as read
    // @todo handle string result
    useEffect(() => {
        if (alert.Alert.read || !performReadCallback) { return; }
        dispatch(actionReadAlert({alertId: alert.Alert.id, t: t}));
    }, [dispatch, alert.Alert, performReadCallback, t]);

    const getRow = (min: boolean) => {
      return (
          <tr>
              <td>
                  <div className={'column'} >
                      <div className={`trend ${min ? 'down' : 'up'}`}>
                          <Image
                              source={min ? limitDown : limitUp}
                              description={min ? t('CardAlert_underLimit') : t('CardAlert_overLimit')} />
                      </div>
                      <div className={`valueContainer ${min ? 'down' : 'up'}`}>
                          <div className={'value'}>{min ? alert.Alert.extremeMin : alert.Alert.extremeMax}</div>
                          {sensorType &&
                              <div className={'unit'}>{sensorType.unit}</div>
                          }
                      </div>
                  </div>
              </td>
              <td>
                  <div className={'column'} >
                      {durationParsed ?
                          <span>
                              <span className={'value'}>{durationParsed.value}</span>
                              <span className={'unit'}>{durationParsed.text}</span>
                          </span>
                          :
                          <span>-</span>
                      }
                  </div>
              </td>
              <td>
                  <div className={'column'} >
                      <span className={'time'}>{ValueUtil.getStringFromValue(t, alert.Alert.firstOccurrenceAt)}</span>
                  </div>
              </td>
          </tr>
      )
    }

    const getAccent = (min: boolean) => {
        return (
            <div className={'CardAlert-content-mobile-accent-value-accent'}>
                <div className={`CardAlert-content-mobile-accent-value-accent-trend ${min ? 'down' : 'up'}`}>
                    <div className={`CardAlert-content-mobile-accent-value-accent-trend-image`}>
                        <Image
                            source={min ? limitDown : limitUp}
                            description={min ? t('CardAlert_underLimit') : t('CardAlert_overLimit')} />
                    </div>
                </div>
                <div className={`CardAlert-content-mobile-accent-value-accent-value ${min ? 'down' : 'up'}`}>
                    <div className={`CardAlert-content-mobile-accent-value-accent-value-item`}>
                        {min ? alert.Alert.extremeMin : alert.Alert.extremeMax}
                    </div>
                    {sensorType &&
                        <div className={`CardAlert-content-mobile-accent-value-accent-value-unit`}>
                            {sensorType.unit}
                        </div>
                    }
                </div>
            </div>
        )
    }

    const getResolutionTable = () => {

        return (
            <table>
                <thead>
                    <tr>
                        <th><T keyName={'CardAlert_resolvedBy'} /></th>
                        <th><T keyName={'CardAlert_time'} /></th>
                        <th><T keyName={'CardAlert_note'} /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={'column'}>
                                {alert.ResolvingUser ? `${alert.ResolvingUser.firstName} ${alert.ResolvingUser.lastName}` : '-'}
                            </div>
                        </td>
                        <td>
                            <div className={'column'}>
                                {alert.Alert.resolvedAt &&
                                    <div>
                                        <div>
                                            {ValueUtil.getStringFromValue(t, alert.Alert.resolvedAt.toLocaleString(),
                                                undefined, true)}
                                        </div>
                                        <div className={'subDate'}>
                                            {ValueUtil.getStringFromValue(t, alert.Alert.resolvedAt.toLocaleString(),
                                                undefined, false, true)}
                                        </div>
                                    </div>
                                }
                            </div>
                        </td>
                        <td>
                            <div className={'column'}>
                                {ValueUtil.getStringFromValue(t, alert.Alert.resolvedNote)}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    if (!alert.Alert.extremeMin && !alert.Alert.extremeMax) {
        return (
            <Empty
                type={EmptyTypeEnum.CARD_FLUID}
                title={t('CardAlert_noLimit_title')}
                subTitle={t('CardAlert_noLimit_description')} />
        )
    }

    return (
        <div className={`CardAlert`}>
            <div className={`CardAlert-functions`}>
                <div className="CardAlert-functions-left">
                    <div className="CardAlert-functions-left-title">
                        <T keyName={'CardAlert_title'} />
                    </div>
                </div>
                <div className="CardAlert-functions-right">
                    {role !== RoleEnum.MEDICAL && alert.BuildingFloor && alert.BuildingFloor.elevation &&
                        <div className="CardAlert-functions-right-item">
                            <div className="CardAlert-functions-right-item-button">
                                <Button
                                    image={building}
                                    onClick={() => {
                                        dispatch(dispatchUpdateActiveHardwareEditor({
                                            id: alert.Hardware.id,
                                            callerType: AbstractBuildingActiveType.DETAIL_PAGE
                                        }))
                                        navigation('/editor');
                                    }}
                                    text={t('Base_goto_building_editor')}
                                    type={ButtonType.PRIMARY}/>
                            </div>
                        </div>
                    }
                    {role !== RoleEnum.TECHNICAL && alert.BuildingFloor && alert.BuildingFloor.elevation &&
                        <div className="CardAlert-functions-right-item">
                            <div className="CardAlert-functions-right-item-button">
                                <Button
                                    image={eye}
                                    onClick={() => {
                                        dispatch(dispatchUpdateActiveHardwarePlan({
                                            id: alert.Hardware.id,
                                            callerType: AbstractBuildingActiveType.DETAIL_PAGE
                                        }))
                                        navigation('/building');
                                    }}
                                    text={t('Base_goto_building_plan')}
                                    type={ButtonType.IMAGE_EYE}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="CardAlert-content">
                <div className="CardAlert-content-desktop">
                    <div className="CardAlert-content-desktop-detail">
                        <table>
                            <thead>
                                <tr>
                                    <th><T keyName={'CardAlert_extreme'}/></th>
                                    <th><T keyName={'CardAlert_duration'}/></th>
                                    <th><T keyName={'CardAlert_occurrence'}/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {alert.Alert.extremeMin && getRow(true)}
                                {alert.Alert.extremeMax && getRow(false)}
                            </tbody>
                        </table>
                        {alert.Alert.resolvedAt && getResolutionTable() }
                    </div>
                </div>
                <div className="CardAlert-content-mobile">
                    <div className="CardAlert-content-mobile-accent">
                        <div className="CardAlert-content-mobile-accent-label">
                            <T keyName={'CardAlert_extreme'}/>
                        </div>
                        <div className="CardAlert-content-mobile-accent-value">
                            {alert.Alert.extremeMin && getAccent(true)}
                            {alert.Alert.extremeMax && getAccent(false)}
                        </div>
                    </div>
                    <div className="CardAlert-content-mobile-info">
                        <div className="CardAlert-content-mobile-info-left">
                            <div className="CardAlert-content-mobile-info-left-label">
                                <T keyName={'CardAlert_duration'}/>
                            </div>
                            <div className="CardAlert-content-mobile-info-left-value">
                                <div className="CardAlert-content-mobile-info-left-value-item">
                                    {durationParsed ? durationParsed.value : '-'}
                                </div>
                                {durationParsed &&
                                    <div className="CardAlert-content-mobile-info-left-value-unit">
                                        {durationParsed.text}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="CardAlert-content-mobile-info-right">
                            <div className="CardAlert-content-mobile-info-right-label">
                                <T keyName={'CardAlert_occurrence'}/>
                            </div>
                            <div className="CardAlert-content-mobile-info-right-time">
                                {ValueUtil.getStringFromValue(t, alert.Alert.firstOccurrenceAt, undefined, undefined, true)}
                            </div>
                        </div>
                    </div>
                    {alert.Alert.resolvedAt &&
                        <div className="CardAlert-content-mobile-info">
                            <div className="CardAlert-content-mobile-info-left">
                                <div className="CardAlert-content-mobile-info-left-label">
                                    <T keyName={'CardAlert_resolvedBy'} />
                                </div>
                                <div className="CardAlert-content-mobile-info-left-simpleValue">
                                    {alert.ResolvingUser ? `${alert.ResolvingUser.firstName} ${alert.ResolvingUser.lastName}` : '-'}
                                </div>
                            </div>
                            <div className="CardAlert-content-mobile-info-right">
                                <div className="CardAlert-content-mobile-info-right-label">
                                    <T keyName={'CardAlert_dateTime'}/>
                                </div>
                                <div className="CardAlert-content-mobile-info-left-simpleValue">
                                    {ValueUtil.getStringFromValue(t, alert.Alert.resolvedAt.toLocaleString())}
                                </div>
                            </div>
                        </div>
                    }
                    {alert.Alert.resolvedNote &&
                        <div className="CardAlert-content-mobile-info">
                            <div className="CardAlert-content-mobile-info-left">
                                <div className="CardAlert-content-mobile-info-left-label">
                                    <T keyName={'CardAlert_note'} />
                                </div>
                                <div className="CardAlert-content-mobile-info-left-simpleValue">
                                    {alert.Alert.resolvedNote ? alert.Alert.resolvedNote : '-'}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CardAlert;
