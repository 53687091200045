import React, {useEffect} from "react";
import "./HardwareTypes.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {actionGetHardwareTypesList} from "../../../store/actions/data/hardwareAction";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {HardwareTypeDto} from "../../../models/entities/HardwareTypeDto";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";

/**
 * @enum {string}
 * @category Components
 */
export enum HardwareTypesColor {
    ORANGE = "orange",
    GREEN = "green",
    BLUE = "blue",
    PINK = "pink",
    DEFAULT = "default"
}

type HardwareTypesProps = {
    dispatchHardwareTypeChange: ActionCreatorWithPayload<{ hardwareType: HardwareTypeEnum }>,
    role: RoleEnum,
}

/**
 * @param {ActionCreatorWithPayload<{ hardwareType: HardwareTypeEnum }>} dispatchHardwareTypeChange - Redux callback to change hardware type
 * @param {RoleEnum} role - current user role
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const HardwareTypes = ({dispatchHardwareTypeChange, role} : HardwareTypesProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();
    const hardwareTypesList = useAppSelector((state) => state.hardwareData.hardwareTypes);

    useEffect(() => { if (!hardwareTypesList) { dispatch(actionGetHardwareTypesList()); } }, [dispatch, hardwareTypesList])

    const filterHardwareTypes = (hardwareTypes: Array<HardwareTypeDto>) : Array<HardwareTypeDto> => {

        return hardwareTypes.filter((type: HardwareTypeDto) => {
            if (role === RoleEnum.TECHNICAL && type.id === HardwareTypeEnum.WEARABLE ) { return false; }
            return !(role === RoleEnum.MEDICAL && type.id !== HardwareTypeEnum.WEARABLE);
        });
    }

    if (typeof hardwareTypesList === "undefined" || isLoading) {
        return <Loading type={LoadingTypeEnum.CARD} />
    }

    if (hardwareTypesList === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.CARD}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description",
                {dataName: t('Base_name_hardware_types')})} />
    }

    return (
        <div className={"HardwareTypes"}>
            <div className={"HardwareTypes-content"}>
                {filterHardwareTypes(hardwareTypesList).map((hardwareType: HardwareTypeDto, index: number) => {
                    return (
                        <div
                            onClick={ () => { dispatch(dispatchHardwareTypeChange({hardwareType: hardwareType.id})); }}
                            key={`HardwareTypes-content-item-${index}`}
                            className={`HardwareTypes-content-item 
                                ${HardwareUtil.getHardwareTypeMetaFromHardwareType(hardwareType.id, t).hwTypesColor}`}>
                            <div className={'HardwareTypes-content-item-inside'}>
                                <div className={'HardwareTypes-content-item-inside-in'}>
                                    <div className={'HardwareTypes-content-item-inside-in-top'}>
                                        <div className={'HardwareTypes-content-item-inside-in-top-left title'}>
                                            {HardwareUtil.getHardwareTypeMetaFromHardwareType(hardwareType.id, t).title}
                                        </div>
                                        <div className={'HardwareTypes-content-item-inside-in-top-right'}>
                                        </div>
                                    </div>
                                    <div className={'HardwareTypes-content-item-inside-in-image'}>
                                        <img
                                            src={HardwareUtil.getHardwareTypeMetaFromHardwareType(hardwareType.id, t).image}
                                            alt={HardwareUtil.getHardwareTypeMetaFromHardwareType(hardwareType.id, t).title} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default HardwareTypes;
