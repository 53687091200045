/**
 * @class
 * @category Utils
 */
export class DateUtil {

    /**
     * Get date formatted
     * @function
     * @category Utils
     * @param {string} separator - separator character
     * @param {Date|undefined} customDate - use date as parameter
     */
    public static getCurrentDateFormatted(separator: string, customDate?: Date) {

        const today = customDate ? customDate : new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        let ddStr = dd.toString();
        let mmStr = mm.toString();
        if(dd < 10) { ddStr = `0${dd}`; }
        if(mm < 10) { mmStr = `0${mm}`; }

        return `${ddStr}${separator}${mmStr}${separator}${yyyy}`;
    }

    /**
     * Get HH:mm formatted current date
     * @function
     * @category Utils
     */
    public static getCurrentTimeFormatted() {

        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();

        let hoursStr = hours.toString();
        let minutesStr = minutes.toString();

        if (hours < 10) { hoursStr = `0${hours}`; }
        if (minutes < 10) { minutesStr = `0${minutes}`; }

        return `${hoursStr}:${minutesStr}`;
    }
}
