/**
 * @enum {number}
 * @category Entities
 * @subcategory Enums
 */
export enum HardwareTypeEnum {

    GATEWAY = 1,
    STATIC = 2,
    WEARABLE = 3,
    REPEATER = 4,
}
