import {Image} from "../Image/Image";
import React from "react";
import arrowDown from "../../../images/arrowDownSmall.svg";
import arrowUp from "../../../images/arrowUpSmall.svg";
import "./TableTitle.scss"
import {Dot, DotType} from "../Dot/Dot";
import {useTranslate} from "@tolgee/react";

/** 
 * @alias TableRowProps
 * @category Components
 */
export type TableTitleState = {

    /** Dot type **/
    type: DotType
}

type TableTitleProps = {
    title: string,
    subtitle?: string|null,
    toggleDetailCallback: ShowRowDetailCallback,
    state?: TableTitleState,
    detailShown: boolean,
}

/** 
 * @alias ShowRowDetailCallback
 * @category Components
 */
export type ShowRowDetailCallback = (event: any) => void;

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string} title - mobile table row title
 * @param {string} subtitle - second line subtext for mobile table row
 * @param {ShowRowDetailCallback} toggleDetailCallback - expand row
 * @param {TableTitleState|undefined} state - row item state dot
 * @param {boolean} detailShown - table row is expanded
 */
export const TableTitle = ({title, subtitle, toggleDetailCallback, state, detailShown} : TableTitleProps): React.JSX.Element => {

    const {t} = useTranslate();

    return (
        <td
            onClick={(e: any) => { void toggleDetailCallback(e); }}
            className={`TableTitle ${detailShown ? 'detailShown' : ''}`}>
            <div className={"TableTitle-content content"}>
                <div className={"TableTitle-content-left"}>
                    <div className={"TableTitle-content-left-first"}>
                        {state &&
                            <div className={"TableTitle-content-left-first-state"}>
                                <Dot type={state.type}/>
                            </div>
                        }
                        <div className={"TableTitle-content-left-first-title"}>
                            {title}
                        </div>
                    </div>
                    {subtitle &&
                        <div className={"TableTitle-content-left-second"}>
                            <div className={"TableTitle-content-left-second-subtitle"}>
                                {subtitle}
                            </div>
                        </div>
                    }
                </div>
                <div className={"TableTitle-content-right"}>
                    <Image
                        source={detailShown ? arrowUp : arrowDown}
                        description={detailShown ? t('TableTitle_show_less') : t('TableTitle_show_more')} />
                </div>
            </div>
        </td>
    )
}

export default TableTitle;
