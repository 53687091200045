import React, {useEffect, useRef} from "react";
import "./BuildingPlanBuilding.scss"
import placeholder from "../../../images/buildingPlaceholder.svg";
import {InputRange} from "../../atoms/InputRange/InputRange";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {useTranslate} from "@tolgee/react";

type BuildingPlanBuildingProps = {
    isNextStep: boolean,
    transparency: number,
    dispatchChangeTransparency: ActionCreatorWithPayload<AbstractFormUpdatePayload>
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {isNextStep} isNextStep - is second step
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>} dispatchChangeTransparency - Redux callback to change transparency
 * @param {transparency} transparency - current transparency level
 */
export const BuildingPlanBuilding = ({isNextStep, dispatchChangeTransparency, transparency} : BuildingPlanBuildingProps): React.JSX.Element => {

    const {t} = useTranslate();
    const placeholderRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (placeholderRef.current) {
            placeholderRef.current.style.opacity = `${transparency}%`;
        }
    }, [transparency, placeholderRef])

    return (
        <div className={"BuildingPlanBuilding"}>
            <div className={"BuildingPlanBuilding-content"}>
                <div className={"BuildingPlanBuilding-content-range"}>
                    <InputRange
                        label={{text: t('BuildingPlanBuilding_transparency_label')}}
                        inputName={'transparency'}
                        unit={'%'}
                        hideValue={!isNextStep}
                        disabled={!isNextStep}
                        min={50}
                        value={transparency}
                        startValue={transparency}
                        step={1}
                        dispatchOnChange={dispatchChangeTransparency}
                        max={100} />
                </div>
                <div className={"BuildingPlanBuilding-content-placeholder"}>
                    <img ref={placeholderRef} src={placeholder} alt={t('Base_name_building')} />
                </div>
            </div>
        </div>
    );
}

export default BuildingPlanBuilding;
