import {createAsyncThunk} from "@reduxjs/toolkit";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {BuildingUpdateRequestDto} from "../../../models/entities/Requests/BuildingUpdateRequestDto";
import {InternalBuilding} from "../../../models/utils/AbstractBuildingReducers";
import {BuildingRepository} from "../../../models/repositories/BuildingRepository";
import {BuildingRequestDto} from "../../../models/entities/Requests/BuildingRequestDto";
import {BuildingNextFloorRequestDto} from "../../../models/entities/Requests/BuildingNextFloorRequestDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get internal building object
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {InternalBuilding | null}
 */
export const actionGetInternalBuilding = createAsyncThunk<InternalBuilding | null>(
    "building/getInternalBuilding",
    async () => {

        const payload = await BuildingRepository.getBuildingPlan();
        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}

        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);

/**
 * Create new building plan
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{buildingCreateRequest: BuildingRequestDto, t: TranslatorType}}
 * @return {InternalBuilding | null | string}
 */
export const actionCreateBuildingPlan = createAsyncThunk<InternalBuilding | null | string,
    {buildingCreateRequest: BuildingRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "building/createBuildingPlan",
    async (request: {buildingCreateRequest: BuildingRequestDto, t: TranslatorType}, { rejectWithValue }) => {

        let payload;
        try { payload = await BuildingRepository.createBuildingPlan(request.buildingCreateRequest, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }

        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}
        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);

/**
 * Update building plan
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {BuildingUpdateRequestDto} buildingUpdateRequest - building information
 * @return {InternalBuilding | null | string}
 */
export const actionUpdateBuildingPlan = createAsyncThunk<InternalBuilding | null | string,
    {buildingUpdateRequest: BuildingUpdateRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "building/updateBuildingPlan",
    async (request: {buildingUpdateRequest: BuildingUpdateRequestDto, t: TranslatorType}, { rejectWithValue }) => {

        let payload;
        try { payload = await BuildingRepository.updateBuildingPlan(request.buildingUpdateRequest, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }

        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}
        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);

/**
 * Remove all building locations
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{t: TranslatorType}}
 * @return {InternalBuilding | null | string}
 */
export const actionResetBuildingPlan = createAsyncThunk<InternalBuilding | null | string,
    {t: TranslatorType}, {rejectValue: string}>(
    "building/resetBuildingPlan",
    async (request: {t: TranslatorType}, { rejectWithValue }) => {

        let payload;
        try { payload = await BuildingRepository.removeHardwareLocations(request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }

        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}
        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);

/**
 * Remove all building floors
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{t: TranslatorType}}
 * @return {InternalBuilding | null | string}
 */
export const actionRemoveBuildingPlan = createAsyncThunk<InternalBuilding | null | string ,
    {t: TranslatorType}, {rejectValue: string}>(
    "building/removeBuildingPlan",
    async (request: {t: TranslatorType}, { rejectWithValue }) => {

        let payload;
        try { payload = await BuildingRepository.removeBuildingFloors(request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }

        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}
        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);


/**
 * Remove all building floors
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{elevation: number, t: TranslatorType}}
 * @return {InternalBuilding | null | string}
 */
export const actionRemoveBuildingFloor = createAsyncThunk<InternalBuilding | null | string,
    {elevation: number, t: TranslatorType}, {rejectValue: string}>(
    "building/removeBuildingFloor",
    async (request: {elevation: number, t: TranslatorType}, { rejectWithValue }) => {

        let payload;
        try { payload = await BuildingRepository.removeBuildingFloor(request.elevation, request.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }

        if (!payload) { return null; }
        if (payload.Building === null) { return {building: null}}
        return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);

/**
 * Add new floor to the building
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {BuildingNextFloorRequestDto} buildingAddFloorRequest - floor info
 * @return {InternalBuilding | null | string}
 */
export const actionAddFloorToBuildingPlan = createAsyncThunk<InternalBuilding | null | string,
    {buildingAddFloorRequest: BuildingNextFloorRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "building/addFloorToBuildingPlan",
    async (request: {buildingAddFloorRequest: BuildingNextFloorRequestDto, t: TranslatorType}, { rejectWithValue }) => {

            let payload;
            try { payload = await BuildingRepository.addBuildingFloorToPlan(request.buildingAddFloorRequest, request.t); }
            catch (e: any) { return rejectWithValue(e.toString()); }

            if (!payload) { return null; }
            if (payload.Building === null) { return {building: null}}
            return BuildingUtil.convertBuildingResponseBodyIntoInternalBuilding(payload.Building);
    }
);
