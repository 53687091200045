import React from "react";
import "./CardPatient.scss"
import {PatientResponseDto} from "../../../models/entities/Responses/PatientResponseDto";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {useAppDispatch} from "../../../store/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import eye from "../../../images/eye.svg"
import edit from "../../../images/edit.svg";
import {T, useTranslate} from "@tolgee/react";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";

type CardPatientProps =  {
    patient: PatientResponseDto,
    role: RoleEnum,
    dispatchShowEditPatientModal: ActionCreatorWithPayload<{patientId: number}>,
    dispatchUpdateActivePatientPlan: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {PatientResponseDto} patient - patient response data
 * @param {ActionCreatorWithPayload<{patientId: number}>} dispatchShowEditPatientModal - show modal Redux callback
 * @param {ActionCreatorWithPayload<{ patient: number }>} dispatchUpdateActivePatientPlan - dispatch to set active patient in building plan
 * @param {RoleEnum} role - current user role
 */
export const CardPatient = ({patient, dispatchShowEditPatientModal, dispatchUpdateActivePatientPlan, role} : CardPatientProps): React.JSX.Element => {

    const {t} = useTranslate();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <div className={`CardPatient`}>
            <div className="CardPatient-functions">
                <div className="CardPatient-functions-left">
                    <div className="CardPatient-functions-left-title">
                        <T keyName={'CardPatient_title'} />
                    </div>
                </div>
                <div className="CardPatient-functions-right">
                    <div className="CardPatient-functions-right-item">
                        <div className="CardPatient-functions-right-item-button">
                            <Button
                                onClick={() => { dispatch(dispatchShowEditPatientModal({patientId: patient.Patient.id})); }}
                                text={t('Base_goto_editDetails')}
                                image={edit}
                                type={ButtonType.PRIMARY} />
                        </div>
                    </div>
                    {patient.Patient.calcLocBuildingFloorElevation !== null && role !== RoleEnum.TECHNICAL &&
                        <div className="CardPatient-functions-right-item">
                            <div className="CardPatient-functions-right-item-button">
                                <Button
                                    onClick={() => {
                                        dispatch(dispatchUpdateActivePatientPlan({
                                            id: patient.Patient.id,
                                            callerType: AbstractBuildingActiveType.DETAIL_PAGE
                                        }))
                                        navigation('/building');
                                    }}
                                    image={eye}
                                    text={t('Base_goto_building_plan')}
                                    type={ButtonType.IMAGE_EYE}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="CardPatient-content">
                <div className="CardPatient-content-identity">
                    <div className="CardPatient-content-identity-left">
                        <div className="LetterImage">
                            <div className="LetterImage-content">
                                <div className="LetterImage-content-letter">
                                    {patient.Patient.firstName.charAt(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="CardPatient-content-identity-right">
                        <div className="CardPatient-content-identity-right-name">
                            {patient.Patient.firstName} {patient.Patient.lastName}
                        </div>
                        <div className="CardPatient-content-identity-right-location">
                            {patient.Patient.calcLocBuildingFloorElevation
                                ? t('CardPatient_located', {elevation: patient.Patient.calcLocBuildingFloorElevation})
                                : t('CardPatient_withoutLocation')}
                        </div>
                    </div>
                </div>
                <div className="CardPatient-content-info">
                    <div className="CardPatient-content-info-block">
                        <div className="CardPatient-content-info-block-title">
                            <T keyName={'CardPatient_birth'} />
                        </div>
                        <div className="CardPatient-content-info-block-value">
                            {ValueUtil.getStringFromValue(t, patient.Patient.dateOfBirth, undefined, true)}
                        </div>
                    </div>
                    <div className="CardPatient-content-info-block">
                        <div className="CardPatient-content-info-block-title">
                            <T keyName={'CardPatient_birthPlace'}/>
                        </div>
                        <div className="CardPatient-content-info-block-value">
                            {patient.Patient.placeOfBirth ?? '-'}
                        </div>
                    </div>
                    <div className="CardPatient-content-info-block">
                        <div className="CardPatient-content-info-block-title">
                            <T keyName={'CardPatient_residence'}/>
                        </div>
                        <div className="CardPatient-content-info-block-value">
                            {patient.Patient.residence ?? '-'}
                        </div>
                    </div>
                    <div className="CardPatient-content-info-block">
                        <div className="CardPatient-content-info-block-title">
                            <T keyName={'CardPatient_phone'}/>
                        </div>
                        <div className="CardPatient-content-info-block-value">
                            {patient.Patient.phone ?? '-'}
                        </div>
                    </div>
                    <div className="CardPatient-content-info-block">
                        <div className="CardPatient-content-info-block-title">
                            <T keyName={'CardPatient_room'} />
                        </div>
                        <div className="CardPatient-content-info-block-value">
                            {patient.Patient.room ?? '-'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardPatient;
