import "./EasyChoose.scss"
import React from "react";

/**
 * @interface
 * @category Components
 */
export interface EasyChooseItem {

    /** Item name **/
    name: string,
    /** Link **/
    callback: EmptyFunc,
    /** Is active **/
    active: boolean,
}

/** 
 * @alias EasyChooseProps
 * @category Components
 */
type EasyChooseProps = {

    /** Breadcrumb items **/
    items: Array<EasyChooseItem>
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {Array<EasyChooseItem>} items - breadcrumb items
 */
export const EasyChoose = ({items} : EasyChooseProps): React.JSX.Element => {

    const [maximizedOnMobile, setMaximizedOnMobile] = React.useState<boolean>(false);

    const getItemsBlock = (items: Array<EasyChooseItem>, isMobile: boolean) => {

        return (
            <div className={`EasyChoose-desktop-content content ${isMobile ? 'isMobile' : 'isDesktop'}`}>
                {items.map((item: EasyChooseItem, index: number) => {
                    return (
                        <div
                            key={`EasyChoose-desktop-content-item-${index}`}
                            onClick={ () => {
                                if (!item.active) {
                                    item.callback();
                                    setMaximizedOnMobile(false);
                                } else {
                                    setMaximizedOnMobile(!maximizedOnMobile);
                                }
                            } }
                            className={`EasyChoose-desktop-content-item item ${item.active ? 'active' : ''}`}>
                            {item.name}
                        </div>
                    )
                })}
            </div>
        )
    }

    const getSortedItemsActiveFirst = () : Array<EasyChooseItem> => {

        return items.sort((a: EasyChooseItem, b: EasyChooseItem) => {
            if (a.active && b.active) { return 0; }
            if (!a.active && !b.active) { return 0; }
            if (a.active && !b.active) { return -1; }
            return 1;
        });
    }

    return (
        <div
            className={`EasyChoose ${maximizedOnMobile ? 'maximizedOnMobile' : ''}`}>
            <div className={`EasyChoose-desktop desktop`}>
                {getItemsBlock(items, false)}
                {getItemsBlock(getSortedItemsActiveFirst(), true)}
            </div>
        </div>
    );
}

export default EasyChoose;
