import { createAsyncThunk } from "@reduxjs/toolkit";
import {AlertRepository} from "../../../models/repositories/AlertRepository";
import {UpdateAlertRequestDto} from "../../../models/entities/Requests/UpdateAlertRequestDto";
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Update alert state
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{alertId: number, request: UpdateAlertRequestDto, t: TranslatorType}} payload - request payload
 * @return {AlertResponseDto|string}
 */
export const actionUpdateAlertState = createAsyncThunk<AlertResponseDto|string,
    { alertId: number, request: UpdateAlertRequestDto, t: TranslatorType }>(
    "sensorState/updateAlertState",
    async (payload: { alertId: number, request: UpdateAlertRequestDto, t: TranslatorType }, { rejectWithValue }) => {
        try { return await AlertRepository.updateAlert(payload.alertId, payload.request, payload.t) }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
