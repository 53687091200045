import React, {FormEvent, useEffect} from "react";
import "./AssignHardware.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {DataParser} from "../../../models/utils/DataParser";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {actionAssignHardware, actionGetHardwareList} from "../../../store/actions/data/hardwareAction";
import {CustomSelect} from "../../atoms/CustomSelect/CustomSelect";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {PatientDto} from "../../../models/entities/PatientDto";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {actionGetHiveFresh} from "../../../store/actions/data/hiveAction";
import {T, useTranslate} from "@tolgee/react";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {assignHardwareOrganismSlice} from "../../../store/slices/organisms/assignHardwareSlice";

type AssignHardwareProps = {
    patient: PatientDto|null,
    zone: ZoneDto|null,
    dispatchHideModal: ActionCreatorWithoutPayload,
    hwTypesSelectFrom: HardwareTypeEnum[],
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - hide modal Redux callback
 * @param {PatientDto|null} patient - patient for hardware assign
 * @param {ZoneDto|null} zone - zone for hardware assign
 * @param {HardwareTypeEnum[]} hwTypesSelectFrom - list of allowed HW types
 */
export const AssignHardware = ({dispatchHideModal, patient, zone, hwTypesSelectFrom} : AssignHardwareProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t, isLoading} = useTranslate();

    // Data state
    const hardwareListState = useAppSelector((state) => state.hardwareData.hardwareList);

    // Form state
    const hardwareState = useAppSelector((state) => selectInputState(state.assignHardwareOrganism, 'hardware'));
    const formValidState = useAppSelector((state) => selectValidState(state.assignHardwareOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.assignHardwareOrganism));

    // Re-load data && Destroy
    useEffect(() => { if (!hardwareListState) { dispatch(actionGetHardwareList()); } }, [dispatch, hardwareListState])
    useEffect(() => { return () => {
        dispatch(assignHardwareOrganismSlice.actions.clearForm({t: t}));
    } }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!hardwareState || !hardwareState.value) {
            dispatch(assignHardwareOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const selectedHardware = hardwareState && hardwareState.value && typeof hardwareState.value === 'number'
            ? DataParser.getHardwareFromList(hardwareListState,  hardwareState.value)
            : null;

        if (selectedHardware === null || typeof selectedHardware === 'undefined') {
            dispatch(assignHardwareOrganismSlice.actions.setFormError({
                error: t('AssignHardware_could_not_find_hardware')
            }));
            return;
        }

        dispatch(dispatchHideModal());

        const result = await dispatch(actionAssignHardware({
            t: t,
            patientId: patient?.id ?? null,
            zoneId: zone?.id ?? null,
            hardwareId: selectedHardware.Hardware.id,
            hardwareAssignRequest: {patient: patient?.id ?? null, zone: zone?.id ?? null}
        }));

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }

        dispatch(actionGetHiveFresh());

        if (patient) {
            dispatch(mainSlice.actions.setTopLevelSuccessMessage({
                message: t("AssignHardware_add_patient_finished", {
                    hardwareTemplateName: selectedHardware.HardwareTemplate.name,
                    hardwareName: selectedHardware.Hardware.internalId ?? selectedHardware.Hardware.uid,
                    patientName: `${patient.firstName} ${patient.lastName}`
                })
            }));
            return;
        }

        if (zone) {
            dispatch(mainSlice.actions.setTopLevelSuccessMessage({
                message: t("AssignHardware_add_zone_finished", {
                    hardwareTemplateName: selectedHardware.HardwareTemplate.name,
                    hardwareName: selectedHardware.Hardware.internalId ?? selectedHardware.Hardware.uid,
                    zoneName: zone.name
                })
            }));
        }
    }

    // Prepare data for usage
    const parsedHardwareList = hardwareListState
        ? DataParser.getHardwareSelectFromList(hardwareListState.filter((item: HardwareResponseDto) => {
            return item.Patient === null && item.Zone === null && item.Hardware.active && hwTypesSelectFrom.includes(item.HardwareTemplate.type);
        })) : hardwareListState;

    // Loading hardware
    if (typeof parsedHardwareList === 'undefined' || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('AssignHardware_title')}
                modal={{dispatchHide: dispatchHideModal}}/>
        )
    }

    // Error state
    if (parsedHardwareList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideModal ? {
                    name: t('AssignHardware_title'),
                    dispatchHide: dispatchHideModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_hardware_list')})} />
        )
    }

    const selectedHardware = hardwareState && hardwareState.value && typeof hardwareState.value === 'number'
        ? DataParser.getHardwareFromList(hardwareListState,  hardwareState.value)
        : null;

    const form = (
        <form onSubmit={handleSubmit.bind(this)}>
            <div className="AssignHardware-content-form-inputs">
                <div>
                    <CustomSelect
                        required={true}
                        values={parsedHardwareList}
                        inputState={hardwareState}
                        dispatchOnFocus={assignHardwareOrganismSlice.actions.setFocus}
                        dispatchOnBlur={assignHardwareOrganismSlice.actions.removeFocus}
                        dispatchOnChange={assignHardwareOrganismSlice.actions.updateInput}
                        defaultValue={selectedHardware ? selectedHardware.Hardware.id : null}
                        placeholder={t('AssignHardware_template_label')}
                        inputName="hardware"/>
                </div>
                {selectedHardware &&
                    <span className="AssignHardware-content-form-inputs-selected">
                        <T keyName={'AssignHardware_selected_hardware_text'}
                           params={{
                               b: <b/>,
                               hardwareTemplateName: selectedHardware.HardwareTemplate.name,
                               hardwareName: selectedHardware.Hardware.internalId ?? selectedHardware.Hardware.uid}} />
                    </span>
                }
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="AssignHardware-content-form-inputs-button">
                <Button
                    text={t('AssignHardware_button')}
                    type={ButtonType.PRIMARY}
                    disabled={!formValidState}
                    onClickDisabled={() => { dispatch(assignHardwareOrganismSlice.actions.showInputValidStates()); }}
                    isSubmit={true} />
            </div>
        </form>
    )

    return (
        <div className="AssignHardware">
            <div className="AssignHardware-content">
                <div className="AssignHardware-content-title">
                    <div className="AssignHardware-content-title-text">
                        <T keyName={'AssignHardware_button'} />
                    </div>
                    <div className="AssignHardware-content-title-close">
                        {dispatchHideModal &&
                            <Image onClick={() => dispatch(dispatchHideModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AssignHardware-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AssignHardware;
