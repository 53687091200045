import React, {ChangeEvent, useEffect} from "react";
import "./InputText.scss"
import {Image} from "../Image/Image";
import clear from "../../../images/close.svg";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {AbstractFormUpdateCallBack, AbstractFormUpdatePayload, InputState} from "../../../models/utils/AbstractFormReducers";
import {FileUtil} from "../../../models/utils/FileUtil";
import {ImageType} from "../../../models/entities/Utils/Image";
import {useTranslate} from "@tolgee/react";

/**
 * @category Components
 * @enum {string}
 */
export enum InputTextType {
    TEXT = "TEXT",
    PASSWORD = "PASSWORD",
    AUTOFILL_FIX = "AUTOFILL_FIX",
    NUMBER = "NUMBER",
    TEXTAREA = "TEXTAREA",
    FILE = "FILE"
}

/**
 * @alias InputTextImage
 */
type InputTextImage = {

    /** Image source **/
    source: ImageType,
    /** Image title **/
    title: string
}

type InputTextProps = {
    inputState: InputState|null,
    inputName: string,
    inputType: InputTextType
    value?: string|null|number,
    startValue?: string|null|number,
    flat?: boolean,
    label?: string,
    hasClear?: boolean,
    placeholder: string,
    disabled ?: boolean,
    required?: boolean,
    onImageClick?: EmptyFunc,
    fullWidth?: boolean,
    image?: InputTextImage,
    onChange?: AbstractFormUpdateCallBack,
    dispatchOnChange?: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    dispatchOnFocus?: ActionCreatorWithPayload<{inputName: string}>,
    dispatchOnBlur?: ActionCreatorWithPayload<{inputName: string}>,
    withoutValidations?: boolean,
    dontCallUpdateOnInit?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {boolean|undefined} hasClear - input can be cleared
 * @param {string} inputName - input element name
 * @param {InputTextType} inputType - input element type
 * @param {string|undefined} label - label for the input
 * @param {string} placeholder - input placeholder
 * @param {boolean|undefined} disabled - input is disabled
 * @param {boolean|undefined} required - the field is required
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>|undefined} dispatchOnChange - dispatch Change event to Redux
 * @param {string|null|number|undefined} startValue - starting value
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnBlur - blur event dispatch
 * @param {ActionCreatorWithPayload<{inputName: string}>|undefined} dispatchOnFocus - focus event dispatch
 * @param {InputTextImage|undefined} image - append image to input (end of the field)
 * @param {EmptyFunc|undefined} onImageClick - append event to image click
 * @param {string|null|number|undefined} value - current store value (for controlled input always required)
 * @param {boolean|undefined} fullWidth - is showed with 100% width
 * @param {InputState|null} inputState - input state generalized abstract state
 * @param {AbstractFormUpdateCallBack|undefined} onChange - on change basic callback
 * @param {boolean|undefined} flat - use flat design
 * @param {boolean|undefined} withoutValidations - don't validate
 * @param {boolean|undefined} dontCallUpdateOnInit - used in fulltext (no need to call on init)
 * @todo file upload handle errors
 * @todo use new component for  file
 */
export const InputText = ({inputName, inputType, label, placeholder, disabled, required, dispatchOnChange, startValue, dispatchOnBlur, hasClear,
    dispatchOnFocus, image, onImageClick, value, fullWidth, inputState, onChange, flat, withoutValidations, dontCallUpdateOnInit}: InputTextProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // On input load initialize
    useEffect(() => {

        const inputValue = typeof startValue !== "undefined" && startValue !== null ? startValue : null;
        if (onChange) { onChange(inputName, inputValue); }
        if (dispatchOnChange && !dontCallUpdateOnInit) {
            dispatch(dispatchOnChange({ inputName: inputName, inputValue: inputValue, t: t }));
        }
    }, [dispatch, inputName, dispatchOnChange, startValue, onChange, t, dontCallUpdateOnInit]);

    // On value change
    const handleChange = (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {

        const inputValue = (inputType === InputTextType.NUMBER)
            ? (event.target.value.toString().length > 0 ? parseInt(event.target.value) : null)
            : (event.target.value.toString().length > 0 ? event.target.value : null);

        if (onChange) { onChange(inputName, inputValue); }
        if (dispatchOnChange) { dispatch(dispatchOnChange({ inputName: inputName,  inputValue: inputValue, t: t })); }
    }

    // Get file on file change
    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files || event.target.files.length !== 1) { return; }

        let inputValue = null;
        try {
            const file = event.target.files[0];
            inputValue = await FileUtil.convertToBase64(file);
        } catch (error: any) { return; }

        if (onChange) { onChange(inputName, inputValue); }
        if (dispatchOnChange) {
            dispatch(dispatchOnChange({
                inputName: inputName,
                inputValue: inputValue,
                uploadFileName: event.target.files[0].name,
                t: t
            }));
        }
    }

    // Clear input value (used if is search)
    const handleClearInput = () => {

        if (onChange) { onChange(inputName, null); }
        if (dispatchOnChange) { dispatch(dispatchOnChange({ inputName: inputName,  inputValue: null, t: t })); }
    }

    // Get input type
    const getType = () : string => {

        switch (inputType) {
            case InputTextType.AUTOFILL_FIX: return "text";
            case InputTextType.PASSWORD: return "password";
            case InputTextType.TEXT: return "text";
            case InputTextType.NUMBER: return "number";
            default: return "text";
        }
    }

    // Input is focused && blurred
    const handleFocus = () => { if (dispatchOnFocus) { dispatch(dispatchOnFocus({inputName: inputName})); } }
    const handleBlur = () => { if (dispatchOnBlur) { dispatch(dispatchOnBlur({inputName: inputName})); } }

    // Get current layout state
    const valid = !inputState?.forcedInvalid && inputState && typeof inputState.invalid !== 'undefined' && !inputState.invalid;
    const invalid = inputState?.forcedInvalid || (inputState && typeof inputState.invalid !== 'undefined' && inputState.invalid);
    const showValid = (valid && value)  || (inputState && valid && inputState.validationShown);
    const showClear = hasClear && value && value.toString().length > 0;

    // Show invalid if 1) not focused AND (has some value OR validations shown forced)
    const showInvalid = inputState && inputState.focused === false && invalid && (value !== null || inputState.validationShown);

    if (inputType === InputTextType.FILE) {
        return (
            <div className={`FileUpload
                ${showValid ? 'ok' : ''}
                ${showInvalid ? 'nok' : ''}`}>
                <div className={`FileUpload-content`}>
                    <input id={inputName} className={'FileUpload-content-input'} type={'file'} onChange={onFileChange} />
                    <label className={'FileUpload-content-button'} htmlFor={inputName}>
                        {placeholder}
                    </label>
                    <div className={'FileUpload-content-fileName'}>
                        {inputState && inputState.uploadFileName ? inputState.uploadFileName : 'No file selected'}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`InputText 
            ${flat ? 'flat' : ''}
            ${!withoutValidations && showValid ? 'ok' : ''}
            ${!withoutValidations && showInvalid ? 'nok' : ''}
            ${fullWidth ? 'fullWidth' : ''}
            ${inputType === InputTextType.AUTOFILL_FIX ? 'autofill' : ''}`}>

            <label>
                {label &&
                    <div className="InputText-label">
                        {label} {required ? '*' : ''}
                    </div>
                }
                {inputType === InputTextType.TEXTAREA &&
                    <textarea
                        placeholder={placeholder}
                        autoComplete="new-password"
                        name={inputName}
                        onFocus={handleFocus.bind(this)}
                        onBlur={handleBlur.bind(this)}
                        disabled={disabled}
                        className={'InputText-element element'}
                        onChange={handleChange.bind(this)}
                        value={value !== null && typeof value !== 'undefined' ? value : ''} >
                    </textarea>
                }
                {inputType !== InputTextType.TEXTAREA &&
                    <input
                        type={getType()}
                        disabled={disabled}
                        name={inputName}
                        value={value !== null && typeof value !== 'undefined' ? value : ''}
                        autoComplete="new-password"
                        onFocus={handleFocus.bind(this)}
                        onBlur={handleBlur.bind(this)}
                        placeholder={placeholder}
                        className={`InputText-element element`}
                        onChange={handleChange.bind(this)}/>
                }
            </label>

            {!showValid && !showInvalid && image && !showClear &&
                <Image
                    onClick={() => { if (onImageClick) { onImageClick(); } }}
                    className={`image ${label ? 'withLabel' : ''}`}
                    source={image.source} description={image.title} />
            }

            {!showValid && !showInvalid && showClear &&
                <Image
                    onClick={() => { handleClearInput(); }}
                    className={`image ${label ? 'withLabel' : ''}`}
                    source={clear} description={t('InputText_clear')} />
            }

            {!withoutValidations &&
                <div className={"error"}>
                    {showInvalid && inputState?.errors ? '*' + inputState.errors : ''}&nbsp;
                </div>
            }
        </div>
    );
}
