import "./EasyBox.scss"
import React, {useCallback} from "react";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {Pagination} from "../Pagination/Pagination";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import eye from "../../../images/eye.svg";
import errIcon from "../../../images/inputNOK.svg"
import eyeDisabled from "../../../images/eyeDisabled.svg";
import profile from "../../../images/user.svg";
import eyeAlertDisabled from "../../../images/eyeAlertDisabled.svg";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useNavigate} from "react-router-dom";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import searchIcon from "../../../images/search.svg";
import {T, useTranslate} from "@tolgee/react";
import Role from "../Role/Role";
import {PatientLocatingStateEnum} from "../../../models/entities/Enums/PatientLocatingStateEnum";
import {HiveMetasSensor, HiveMetasType} from "../../../models/utils/HiveUtil";
import {useAppDispatch} from "../../../store/hooks";
import offline from "../../../images/offline.svg";

/**
 * @alias EasyBoxItemSensor
 * @category Components
 */
export type EasyBoxItemSensor = HiveMetasSensor;

/**
 * @interface
 * @category Components
 */
export interface EasyBoxItem {

    /** Easy box item is active **/
    active?: boolean,
    /** Item title **/
    title: string,
    /** Item DB id **/
    id: number|null,
    /** Easy box type **/
    type?: HiveMetasType,
    /** List of sensors **/
    sensors?: Array<EasyBoxItemSensor>|null,
    /** Role **/
    role?: RoleEnum,
    /** Shown label info **/
    label?: { text: string, image?: any },
    /** Shown subtitle text **/
    subtitle?: string|null,
    /** Link redirect **/
    link: string|null,
    /** Callback on click **/
    actionCallBack?: EmptyFunc|null,
    /** Show locating info **/
    locatingState?: PatientLocatingStateEnum,
    /** Also search in this strings **/
    searchTags: Array<string|null|undefined>,
}

/**
 * @alias EasyBoxPagination
 * @category Components
 */
type EasyBoxPagination = {

    /** Shown count **/
    count: number,
    /** Shown page **/
    page: number,
    /** Redux callback to show page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

type EasyBoxProps = {
    items: Array<EasyBoxItem>|undefined|null,
    title: string,
    search: string|null
    searchDispatch: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    pagination: EasyBoxPagination
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {EasyBoxItem[]|undefined|null} items - list of items
 * @param {EasyBoxPagination} pagination - pagination info
 * @param {string} title - box title
 * @param {string|null} search - search text
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>} searchDispatch - Redux search dispatch
 */
export const EasyBox = ({items, pagination, title, search, searchDispatch} : EasyBoxProps): React.JSX.Element => {

    const navigation = useNavigate();
    const {t, isLoading} = useTranslate();
    const dispatch = useAppDispatch();

    // When search value changed -> set page to 1
    const dispatchPaginationChangeCallback = pagination.dispatchChangePage;
    const updatePaginationOnSearch = useCallback(() => {
        dispatch(dispatchPaginationChangeCallback({page: 0}));
    }, [dispatch, dispatchPaginationChangeCallback]);

    // Filter items in easy box using search
    const getFilteredItems = (items: Array<EasyBoxItem>) : Array<EasyBoxItem> => {
        return items.filter((item: EasyBoxItem) => {
            if (!search || search.length === 0) { return true; }
            return item.title.toLowerCase().includes(search.toLowerCase())
                || (item.subtitle && item.subtitle.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                || (item.searchTags.find((tag: string|undefined|null) => { return tag?.toLowerCase().includes(search); }));
        });
    }

    // Crop items in easy box using pagination
    const getPaginatedData = (items: Array<EasyBoxItem>) : Array<EasyBoxItem> => {

        if (!pagination) { return items; }
        const startIndex = pagination.page * pagination.count;
        const endIndex = startIndex + pagination.count;
        return items.slice(startIndex, endIndex);
    };

    // Get empty element
    const getEmptyElement = () : React.JSX.Element => {

        return (
            <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors'}>
                <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item'} >
                    <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value'}>
                        <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value-icon'}>
                            <img src={offline} alt={t('Base_not_communicated')}/>
                        </div>
                        <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value-number'}>
                            <T keyName={'Base_not_communicated'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // Get sensor element
    const getSensorElement = (sensors: Array<EasyBoxItemSensor>) : React.JSX.Element => {

        return (
            <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors'}>
                {sensors.map((sensor, index: number) => {
                    return (
                        <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item'}
                             key={`EasyBox-content-inside-item-data-top-left-first-info-sensors-item-${index}`}>
                            <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value'}>
                                <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value-icon'}>
                                    <img src={sensor.icon} alt={sensor.name ? `${sensor.name}` : t('Base_name_sensor')}/>
                                </div>
                                <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value-number'}>
                                    {sensor.textValue ?? '-'}
                                </div>
                                {sensor.unit &&
                                    <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-value-unit'}>
                                        {sensor.unit}
                                    </div>
                                }
                            </div>
                            <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-item-updated'}>
                                {sensor.updatedText ?? '-'}
                            </div>

                        </div>
                    )
                })}
            </div>
        )
    }

    // Get easy list element for item
    const getEasyListElement = (item: EasyBoxItem, index: number) : React.JSX.Element => {

        const sensors = item.sensors ? item.sensors.slice(0, 3) : null;
        const hasLocatingState = item.locatingState === PatientLocatingStateEnum.ACTIVE || item.locatingState === PatientLocatingStateEnum.FAILED;

        return (
            <div
                key={`EasyBox-content-inside-item-${index}`}
                className={`EasyBox-content-inside-item`}
                onClick={ () => { if (item.link) { navigation(item.link); } } }>

                <div className={`EasyBox-content-inside-item-data ${item.active ? 'active' : ''} ${item.type}`}>

                    <div className={`EasyBox-content-inside-item-data-top`}>
                        <div className={'EasyBox-content-inside-item-data-top-left'}>
                            <div className={'EasyBox-content-inside-item-data-top-left-first'}>
                                <div className={'EasyBox-content-inside-item-data-top-left-first-photo'}>
                                    <img src={profile} alt={t('EasyBox_photo')} />
                                </div>
                                <div className={'EasyBox-content-inside-item-data-top-left-first-info'}>
                                    <div className={'EasyBox-content-inside-item-data-top-left-first-info-name name'}>
                                        {item.title}
                                    </div>
                                    {item.role &&
                                        <div className={'EasyBox-content-inside-item-data-top-left-first-info-role'}>
                                            <Role role={item.role} />
                                        </div>
                                    }
                                    {item.type === HiveMetasType.SILVER && getEmptyElement()}
                                    {item.type !== HiveMetasType.SILVER && sensors && sensors.length > 0 && getSensorElement(sensors)}
                                    {item.type !== HiveMetasType.SILVER && sensors && sensors.length === 0 &&
                                        <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors'}>
                                            <div className={'EasyBox-content-inside-item-data-top-left-first-info-sensors-empty'}>
                                                <T keyName={'EasyBox_without_sensors'} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'EasyBox-content-inside-item-data-top-right'}>
                    {item.actionCallBack ?
                        <div className={`EasyBox-content-inside-item-data-top-right-action`}
                             onClick={
                                 (event: React.MouseEvent<HTMLDivElement>) => {
                                     if (item.id && item.actionCallBack) {
                                         event.preventDefault();
                                         event.stopPropagation();
                                         item.actionCallBack();
                                     }
                                 }}>
                            <img
                                src={eye}
                                alt={t('EasyBox_find_on_map')}/>
                        </div>
                        :
                        <div className={`EasyBox-content-inside-item-data-top-right-action disabled`}>
                            <img
                                src={item.type === HiveMetasType.ERROR ? eyeAlertDisabled : eyeDisabled}
                                alt={t('EasyBox_not_on_map')}/>
                        </div>
                    }
                    </div>
                    </div>

                    {(item.label || hasLocatingState) &&
                        <div className={`EasyBox-content-inside-item-data-bottom`}>
                            {item.label &&
                                <div className={`EasyBox-content-inside-item-data-bottom-label label`}>
                                    {item.label.image &&
                                        <div className={'EasyBox-content-inside-item-data-bottom-label-icon'}>
                                            <img src={item.label.image} alt={'Base_name_sensor'}/>
                                        </div>
                                    }
                                    <div className={'EasyBox-content-inside-item-data-bottom-label-text'}>
                                        {item.type === HiveMetasType.SILVER ? t('Base_not_communicated') : item.label.text}
                                    </div>
                                </div>
                            }
                            {(item.locatingState === PatientLocatingStateEnum.ACTIVE || item.locatingState === PatientLocatingStateEnum.FAILED) &&
                                <div className={`EasyBox-content-inside-item-data-bottom-locating ${item.locatingState}`}>
                                    {item.locatingState === PatientLocatingStateEnum.FAILED &&
                                        <div className={`EasyBox-content-inside-item-data-bottom-locating-tick`}>
                                            <img src={errIcon} alt={t('Base_failed_location')} />
                                        </div>
                                    }
                                    <div className={`EasyBox-content-inside-item-data-bottom-locating-text`}>
                                        <T keyName={item.locatingState === PatientLocatingStateEnum.ACTIVE
                                            ? 'Base_loading_location' : 'Base_failed_location'} />
                                    </div>
                                    {item.locatingState === PatientLocatingStateEnum.ACTIVE &&
                                        <div className={`EasyBox-content-inside-item-data-bottom-locating-spinner`}>
                                            <div className={`Spinner warning`}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    if (typeof items === "undefined" || isLoading) {
        return (
            <Loading type={LoadingTypeEnum.CARD} />
        )
    }

    if (items === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_list')})} />
        )
    }

    const filteredItems = getFilteredItems(items);
    const strippedItems = getPaginatedData(filteredItems);

    return (
        <div className={`EasyBox`}>
            <div className={`EasyBox-content`}>
                <div className={`EasyBox-content-title`}>
                    <div className={`EasyBox-content-title-text`}>
                        {title}
                    </div>
                </div>
                <div className={`EasyBox-content-search`}>
                    <InputText
                        hasClear={true}
                        inputState={null}
                        value={search}
                        image={{source: searchIcon, title: t('Base_search')}}
                        withoutValidations={true}
                        flat={true}
                        inputName={'search'}
                        inputType={InputTextType.TEXT}
                        onChange={updatePaginationOnSearch}
                        dispatchOnChange={searchDispatch}
                        placeholder={t('Base_search')} />
                </div>
                <div className={`EasyBox-content-inside`}>
                    {strippedItems.map((item: EasyBoxItem, index: number) => {
                        return getEasyListElement(item, index);
                    })}
                </div>
                <div className={`EasyBox-content-pagination`}>
                    <Pagination
                        hideStats={true}
                        page={pagination.page}
                        size={pagination.count}
                        total={filteredItems.length}
                        callBackChangePage={(page: number) => { dispatch(pagination.dispatchChangePage({page: page})); }} />
                </div>
            </div>
        </div>
    );
}

export default EasyBox;
