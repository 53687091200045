import {AbstractFormReducersState, getAbstractFormReducers} from "./AbstractFormReducers";
import {PayloadAction} from "@reduxjs/toolkit";

/**
 * @enum {number}
 * @category Utils
 */
export enum AbstractFilterType {
    FILTER_ALL = 1,
    FILTER_TEMPLATE = 2,
    FILTER_SENSOR = 3,
    FILTER_PATIENT = 4,
    FILTER_HARDWARE = 5,
    FILTER_WEARABLE = 6,
    FILTER_ELEVATION = 7,
    FILTER_VALUE_TREND = 8,
    FILTER_HARDWARE_TYPE = 9
}

/**
 * @interface
 * @category Utils
 */
export interface AbstractFilterState extends AbstractFormReducersState {

    /** Filter is opened **/
    opened: boolean,
    /** Filter type **/
    filterType: AbstractFilterType,
}

/**
 * @constant
 * @category Utils
 */
export const abstractFilterInitialState: AbstractFilterState = {
    filterType: AbstractFilterType.FILTER_ALL,
    form: { inputs: [ {name: 'filterSearch', validations: {required: true}} ] },
    opened: false,
}

/**
 * @function
 * @category Utils
 */
export const getAbstractFilterReducers = () => {
    return {...getAbstractFormReducers(), ...{
            hideDropDown: (state: AbstractFilterState) => {
                state.opened = false;
                state.filterType = AbstractFilterType.FILTER_ALL;
                state.form.inputs[0].value = undefined;
            },
            toggleDropDown: (state: AbstractFilterState) => {
                if (state.opened) { state.form.inputs[0].value = undefined; }
                state.opened = !state.opened;
                state.filterType = AbstractFilterType.FILTER_ALL;
            },
            setFilterType: (state: AbstractFilterState, action: PayloadAction<{ filterType: AbstractFilterType }>) => {
                state.filterType = action.payload.filterType;
            },
        }
    }
}