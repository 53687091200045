import {Communicator, RequestType} from "../utils/Communicator";
import {CacheExpiry} from "../utils/Storage";
import {HardwareTemplateResponseDto} from "../entities/Responses/HardwareTemplateResponseDto";

/**
 * @class
 * @category Repositories
 */
export class HardwareTemplateRepository {

    /**
     * Get list of hardware templates
     */
    public static async getHardwareTemplateList() : Promise<Array<HardwareTemplateResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "hardwareTemplate",
            cache: {cacheKey: "getHardwareTemplateList", expiry: CacheExpiry.ONE_DAY, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }
}