import React, {useEffect} from "react";
import "./BuildingObserverTemplate.scss"
import {Header} from "../../organisms/Header/Header";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {InternalBuildingBuilding} from "../../../models/utils/AbstractBuildingReducers";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {buildingObserverPageSlice} from "../../../store/slices/pages/buildingObserverSlice";
import {BoxHive} from "../../organisms/BoxHive/BoxHive";
import {BuildingFullScreen} from "../../organisms/BuildingFullScreen/BuildingFullScreen";
import {ListLog} from "../../organisms/ListLog/ListLog";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {BuildingObserver} from "../../organisms/BuildingObserver/BuildingObserver";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HiveResponseDto} from "../../../models/entities/Responses/HiveResponseDto";
import {useTranslate} from "@tolgee/react";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {useSearchParams} from "react-router-dom";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type BuildingObserverTemplateProps = {
    building: InternalBuildingBuilding,
    role: RoleEnum,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    hive: HiveResponseDto,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {InternalBuildingBuilding} building - building internal data
 * @param {RoleEnum} role - logged user role
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of sensor types
 * @param {HiveResponseDto} hive - hive data
 * @constructor
 */
export const BuildingObserverTemplate = ({building, role, sensorTypes, zoneList, hive} : BuildingObserverTemplateProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    const [searchParams, setParams] = useSearchParams();
    const floorElevationState = useAppSelector((state) => state.buildingObserverPage.floorElevation);
    const floorIndexState = useAppSelector((state) => state.buildingObserverPage.floorIndex);
    const activeHardwareState = useAppSelector((state) => state.buildingObserverPage.activeHardware);
    const activePatientState = useAppSelector((state) => state.buildingObserverPage.activePatient);
    const draggableAllowedContentState = useAppSelector((state) => state.buildingObserverPage.draggableAllowedContent);
    const paginationPageState = useAppSelector((state) => state.buildingObserverPage.paginationPage);
    const fullscreenShownState = useAppSelector((state) => state.buildingObserverPage.modalFullScreenShown);
    const zoomState = useAppSelector((state) => state.buildingObserverPage.zoom);
    const initializedState = useAppSelector((state) => state.buildingObserverPage.initialized);

    // Initialize floor from URL on start
    useEffect(() => {
        BuildingUtil.initializeFloorFromUrl(searchParams, dispatch, buildingObserverPageSlice.actions.updateFloorElevation);
    }, [searchParams, dispatch]);

    return (
        <main>
            <div className="BuildingObserverTemplate layout layout-flexHeight">
                <Helmet>
                    <title>{t('BuildingObserverTemplate_title', {buildingName: building.name})}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={t('BuildingObserverTemplate_title', {buildingName: building.name})}
                        breadItems={[
                            {name: t('Navigation_building_plan'), link: null},
                        ]} />
                    <div className="layout-right-content layout-right-content-flex">
                        <div className="layout-right-content-item third fullHeight fill">
                            <BoxHive
                                hive={hive}
                                zoneList={zoneList}
                                sensorTypes={sensorTypes}
                                role={role}
                                activeHardware={activeHardwareState}
                                activePatient={activePatientState}
                                building={building}
                                dispatchChangeActiveHardware={buildingObserverPageSlice.actions.updateActiveHardware}
                                dispatchChangeActivePatient={buildingObserverPageSlice.actions.updateActivePatient}
                                pagination={{
                                    page: paginationPageState,
                                    dispatchChangePage: buildingObserverPageSlice.actions.changePaginationPage,
                                    count: 4
                                }} />
                        </div>
                        <div className="layout-right-content-item fullHeight withoutThird">
                            <BuildingObserver
                                initialized={initializedState}
                                hive={hive}
                                zoneList={zoneList}
                                sensorTypes={sensorTypes}
                                dispatchRemoveActives={buildingObserverPageSlice.actions.removeActives}
                                dispatchInitialized={buildingObserverPageSlice.actions.setInitialized}
                                dispatchUpdateZoom={buildingObserverPageSlice.actions.updateZoomValue}
                                dispatchUpdateActiveHardware={buildingObserverPageSlice.actions.updateActiveHardware}
                                dispatchUpdateActivePatient={buildingObserverPageSlice.actions.updateActivePatient}
                                dispatchShowFullscreen={buildingObserverPageSlice.actions.showFullScreen}
                                dispatchUpdateDraggableAllowedContent={buildingObserverPageSlice.actions.updateDraggableAllowedContent}
                                currentZoom={zoomState}
                                allowedContent={draggableAllowedContentState}
                                building={building}
                                activeHardware={activeHardwareState}
                                activePatient={activePatientState}
                                currentFloorIndex={floorIndexState}
                                currentFloorElevation={floorElevationState}
                                updateFloorCallback={(floor: number) => {
                                    BuildingUtil.updateUrlFromFloorChange (
                                        setParams,
                                        dispatch,
                                        floor,
                                        buildingObserverPageSlice.actions.updateFloorElevation
                                    );
                                }} />
                        </div>
                    </div>
                    <div className="layout-right-content">
                        <div className="layout-right-content-item">
                            <ListLog
                                pagination={{showCount: 8}} />
                        </div>
                    </div>
                </div>
            </div>
            {fullscreenShownState &&
                <BuildingFullScreen
                    observer={{hive: hive, sensorTypes: sensorTypes, zoneList: zoneList}}
                    currentFloorIndex={floorIndexState}
                    building={building}
                    dispatchHideModal={buildingObserverPageSlice.actions.hideFullScreen} />
            }
        </main>
    );
}

export default BuildingObserverTemplate;
