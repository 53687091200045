import React from "react";
import {EasyListItem} from "../EasyList/EasyList";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import placedIcon from "../../../images/placed.svg";
import {useTranslate} from "@tolgee/react";

type DraggablePlanElementEasyListComponentProps = {
    item: EasyListItem,
    dragged: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {EasyListItem} item - easy list item
 * @param {boolean} dragged - item is dragged
 */
export const DraggablePlanElementEasyListComponent = ({item, dragged} : DraggablePlanElementEasyListComponentProps): React.JSX.Element|null => {

    const {t} = useTranslate();
    const meta = BuildingUtil.getHardwareTypeFromInternalBuildingLocation(item.type);
    const icon = HardwareUtil.getHardwareTypeMetaFromHardwareType(meta, t).image;

    return (
        <div draggable={false} className={`EasyList-content-inside-sub-item ${dragged ? 'dragged' : ''} ${item.type} ${item.active ? 'current' : ''}`}>
            {item.placed &&
                <div className={'EasyList-content-inside-sub-item-placed placed'}>
                    <img src={placedIcon} alt={t('DraggablePlanElementEasyListComponent_item_in_building_plan')} />
                </div>
            }
            <div draggable={false} className={'EasyList-content-inside-sub-item-block block'}>
                <div draggable={false} className={'EasyList-content-inside-sub-item-block-icon icon'}>
                    <img draggable={false} src={icon} alt={t('Base_name_building_plan_element')} />
                </div>
            </div>
            {!dragged &&
                <div className={'EasyList-content-inside-sub-item-title'}>
                    {item.title}
                </div>
            }
        </div>
    )
}

export default DraggablePlanElementEasyListComponent;
