import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {HardwarePage} from "./components/pages/HardwarePage";
import {AlertsPage} from "./components/pages/AlertsPage";
import {WelcomePage} from "./components/pages/WelcomePage";
import {HardwareDetailPage} from "./components/pages/HardwareDetailPage";
import {AlertDetailPage} from "./components/pages/AlertDetailPage";
import {UsersPage} from "./components/pages/UsersPage";
import {DashboardPage} from "./components/pages/DashboardPage";
import {RouterHandler} from "./components/extras/RouterHandler";
import {CreateHardwarePage} from "./components/pages/CreateHardwarePage";
import {PatientsPage} from "./components/pages/PatientsPage";
import {PatientDetailPage} from "./components/pages/PatientDetailPage";
import {SupportPage} from "./components/pages/SupportPage";
import {BuildingManagerPage} from "./components/pages/BuildingManagerPage";
import {BuildingObserverPage} from "./components/pages/BuildingObserverPage";
import {loadHandler} from "./components/extras/LoadHandler";
import {UserDetailPage} from "./components/pages/UserDetailPage";
import AccountPage from "./components/pages/AccountPage";
import {firebaseHandler} from "./components/extras/FirebaseHandler";
import {useAppDispatch} from "./store/hooks";
import {useTranslate} from "@tolgee/react";
import {FirebaseApp} from "@firebase/app";
import {isSupported} from "firebase/messaging";
import ZonesPage from "./components/pages/ZonesPage";
import ZoneDetailPage from "./components/pages/ZoneDetailPage";

type AppProps = {
  firebase: FirebaseApp
};

/**
 * @param {FirebaseApp} firebase - Firebase Messaging API
 * @constructor
 */
export const App = ({firebase}: AppProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    useEffect(() => {
        isSupported().then((result: boolean) => {
            if (result) {
                firebaseHandler({
                    firebase: firebase,
                    dispatch: dispatch,
                    translator: t,
                });
            }
            else { console.warn('Firebase API not supported!'); }
        })
    }, [dispatch, firebase, t])

    useEffect(() => {
        loadHandler();
    }, []);

    return (
        <Router>
            <RouterHandler/>
            <Routes>
                <Route path="/account" element={<AccountPage/>} />
                <Route path="/devices/:id" element={<HardwareDetailPage/>} />
                <Route path="/devices/create" element={<CreateHardwarePage/>} />
                <Route path="/alert/:id" element={<AlertDetailPage/>} />
                <Route path="/devices" element={<HardwarePage/>} />
                <Route path="/alerts" element={<AlertsPage/>} />
                <Route path="/users" element={<UsersPage/>} />
                <Route path="/user/:id" element={<UserDetailPage/>} />
                <Route path="/dashboard" element={<DashboardPage/>} />
                <Route path="/building" element={<BuildingObserverPage/>} />
                <Route path="/editor" element={<BuildingManagerPage/>} />
                <Route path="/zones" element={<ZonesPage/>} />
                <Route path="/zone/:id" element={<ZoneDetailPage/>} />
                <Route path="/support" element={<SupportPage/>} />
                <Route path="/patients" element={<PatientsPage/>} />
                <Route path="/patient/:id" element={<PatientDetailPage/>} />
                <Route path="/password-reset" element={<WelcomePage/>} />
                <Route path="/" element={<WelcomePage/>} />
            </Routes>
        </Router>
    );
}
