import React, {useEffect} from "react";
import "./BuildingPlan.scss"
import save from "../../../images/saveWhite.svg"
import {BuildingPlanForm} from "../BuildingPlanForm/BuildingPlanForm";
import {BuildingPlanBuilding} from "../BuildingPlanBuilding/BuildingPlanBuilding";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {buildingPlanOrganismSlice} from "../../../store/slices/organisms/buildingPlanSlice";
import {InputState, selectFormError, selectInputsState, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {BuildingConstructorShowUploadModalProps} from "../../../store/slices/pages/buildingConstructorSlice";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {actionCreateBuildingPlan, actionGetInternalBuilding} from "../../../store/actions/data/buildingAction";
import {BuildingRequestDto} from "../../../models/entities/Requests/BuildingRequestDto";
import {T, useTranslate} from "@tolgee/react";

type BuildingPlanProps = {
    dispatchShowFileUploadModal: ActionCreatorWithPayload<BuildingConstructorShowUploadModalProps>
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<BuildingConstructorShowUploadModalProps>} dispatchShowFileUploadModal - Redux callback to show upload modal
 */
export const BuildingPlan = ({dispatchShowFileUploadModal} : BuildingPlanProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const errorState = useAppSelector((state) => state.buildingPlanOrganism.error);
    const nextStepState = useAppSelector((state) => state.buildingPlanOrganism.isNextStep);
    const transparency = useAppSelector((state) => state.buildingPlanOrganism.transparency);
    const nameState = useAppSelector((state) => selectInputState(state.buildingPlanOrganism, 'name'));
    const floorsState = useAppSelector((state) => selectInputState(state.buildingPlanOrganism, 'floors'));
    const formValidState = useAppSelector((state) => selectValidState(state.buildingPlanOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.buildingPlanOrganism));
    const fileInputsState = useAppSelector((state) => selectInputsState(state.buildingPlanOrganism, 'file-floor'));

    // Cleanup form
    useEffect(() => {
        return () => {
            dispatch(buildingPlanOrganismSlice.actions.clearForm({t: t}));
            dispatch(buildingPlanOrganismSlice.actions.destroyOrganism());
        }
    }, [dispatch, t])

    // Save whole building creation settings
    const handleSaveChanges = async () => {

        if (!nextStepState || !transparency || !nameState || !nameState.value || !floorsState || !floorsState.value
            || !fileInputsState || fileInputsState.length !== parseInt(floorsState.value.toString())) {
            dispatch(buildingPlanOrganismSlice.actions.setError({error: t("Base_errors_wrong_input")}));
            return;
        }

        // Only filled floor plans
        const filteredFloors = fileInputsState.filter((inputState: InputState) => {
            return inputState.value !== null && typeof inputState.value !== 'undefined'
                && inputState.metaData !== null && typeof inputState.metaData !== 'undefined';
        }) as Array<{ name: string, value: string | number, metaData: any }>

        // Check if all filled
        if (filteredFloors.length !== fileInputsState.length) {
            dispatch(buildingPlanOrganismSlice.actions.setError({error: t("Base_errors_wrong_input")}));
            return;
        }

        // Create request
        const buildingRequest: BuildingRequestDto = {
            transparency: transparency,
            name: nameState.value.toString(),
            floors: filteredFloors
                .map((fileInput: { name: string, value: string | number, metaData: any }) => {
                    return {
                        elevation: BuildingUtil.getFloorFromFileInputState(fileInput),
                        plan: fileInput.value.toString(),
                        sizeXMeters: fileInput.metaData.sizeX,
                        sizeYMeters: fileInput.metaData.sizeY
                    }
                })
        };

        const result = await dispatch(actionCreateBuildingPlan({buildingCreateRequest: buildingRequest, t: t}))
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(buildingPlanOrganismSlice.actions.setError({error: result.payload}));
            return;
        }

        dispatch(buildingPlanOrganismSlice.actions.setError({error: null}));
        dispatch(actionGetInternalBuilding());
    }

    return (
        <div className={"BuildingPlan"}>
            <div className={"BuildingPlan-content"}>
                <div className="BuildingPlan-content-title">
                    <div className="BuildingPlan-content-title-left">
                        <T keyName={'BuildingPlan_title'} />
                    </div>
                </div>
                <div className="BuildingPlan-content-main">
                    <div className="BuildingPlan-content-main-form">
                        <BuildingPlanForm
                            dispatchShowFileUploadModal={dispatchShowFileUploadModal}
                            fileInputsState={fileInputsState}
                            dispatchShowInputError={buildingPlanOrganismSlice.actions.setFocus}
                            dispatchHideInputError={buildingPlanOrganismSlice.actions.removeFocus}
                            dispatchShowInputValidStates={buildingPlanOrganismSlice.actions.showInputValidStates}
                            dispatchUpdateInput={buildingPlanOrganismSlice.actions.updateInput}
                            formValidState={formValidState}
                            floorsState={floorsState}
                            dispatchAddInputs={buildingPlanOrganismSlice.actions.addInputs}
                            dispatchRemoveAllGeneratedInputs={buildingPlanOrganismSlice.actions.removeAllGeneratedInputs}
                            formErrorState={formErrorState}
                            nameState={nameState}
                            dispatchNextStep={buildingPlanOrganismSlice.actions.setNextStep}
                            isNextStep={nextStepState} />
                    </div>
                    <div className="BuildingPlan-content-main-building">
                        <BuildingPlanBuilding
                            transparency={transparency}
                            dispatchChangeTransparency={buildingPlanOrganismSlice.actions.setTransparency}
                            isNextStep={nextStepState} />
                    </div>
                </div>
                {errorState && nextStepState &&
                    <div className={'BuildingPlan-content-error'}>
                        {errorState}
                    </div>
                }
                {nextStepState &&
                    <div className="BuildingPlan-content-buttons">
                        <Button
                            onClickDisabled={() => { dispatch(buildingPlanOrganismSlice.actions.showInputValidStates()); }}
                            text={t('BuildingPlan_button_cancel')}
                            type={ButtonType.CANCEL}
                            isSubmit={true}/>
                        <Button
                            onClick={handleSaveChanges.bind(this)}
                            onClickDisabled={() => { dispatch(buildingPlanOrganismSlice.actions.showInputValidStates()); }}
                            text={t('BuildingPlan_button_save')}
                            image={save}
                            type={ButtonType.PRIMARY}
                            disabled={!formValidState}
                            isSubmit={true}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default BuildingPlan;
