import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface ResetBlockState extends AbstractFormReducersState {}

const initialState: ResetBlockState = {
    form: {
        inputs: [
            {name: 'passwordAgain', validations: {required: true}},
            {name: 'password', validations: {required: true, minLength: 8}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const resetBlockOrganismSlice = createSlice({
    name: 'resetBlockOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}}
})

export default resetBlockOrganismSlice.reducer