import {createSlice} from '@reduxjs/toolkit'
import {UserResponseDto} from "../../../models/entities/Responses/UserResponseDto";
import {actionCreateUser, actionDeleteUser, actionGetUserList} from "../../actions/data/userAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface UserState {

    /** List of users **/
    userList?: Array<UserResponseDto>|null,
    /** Create user error **/
    createError: string|null
}

const initialState: UserState = {
    userList: undefined,
    createError: null
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetUserList.fulfilled, (state, {payload}) => {
            state.userList = payload;
        })
        builder.addCase(actionDeleteUser.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('userDataSlice-actionDeleteUser: wrong payload, skip!')
                return;
            }

            state.userList = payload;
        })
        builder.addCase(actionCreateUser.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('userDataSlice-actionCreateUser: wrong payload, skip!')
                return;
            }

            state.userList = payload;
        })
    }
})

export default userDataSlice.reducer
