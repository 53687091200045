import React from "react";
import "./HeaderMobile.scss"
import menu from "../../../images/topMenu.svg"
import profile from "../../../images/personIcon.svg"
import backArrow from "../../../images/backArrow.svg"
import {T, useTranslate} from "@tolgee/react";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import ProfileMenu from "../../molecules/ProfileMenu/ProfileMenu";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {headerOrganismSlice} from "../../../store/slices/organisms/headerSlice";

/**
 * @alias HeaderMobileProps
 * @category Components
 */
type HeaderMobileProps = {

    /** Logged user role **/
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {RoleEnum} role - logged user role
 */
export const HeaderMobile = ({role} : HeaderMobileProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const profileMobileMenuShown = useAppSelector((state) => state.main.profileMobileShown);

    return (
        <div className="HeaderMobile">
            {profileMobileMenuShown &&
                <div className="HeaderMobile-navigation">
                    <div
                        onClick={ () => { dispatch(mainSlice.actions.profileMobileHide()); }}
                        className="HeaderMobile-navigation-back">
                        <div className="HeaderMobile-navigation-back-icon">
                            <img src={backArrow} alt={t('HeaderMobile_back')}  />
                        </div>
                        <div className="HeaderMobile-navigation-back-text">
                            <T keyName={'HeaderMobile_back'} />
                        </div>
                    </div>
                    <div className="HeaderMobile-navigation-inside">
                        <ProfileMenu role={role} dispatchHideProfile={headerOrganismSlice.actions.hideDropdownProfile} />
                    </div>
                </div>
            }
            <div className="HeaderMobile-content">
                <div className="HeaderMobile-content-left">
                    <div
                        onClick={ () => { dispatch(mainSlice.actions.navigationMobileShow()); } }
                        className="HeaderMobile-content-left-item">
                        <img src={menu} alt={t('HeaderMobile_menu')} />
                    </div>
                </div>
                <div className="HeaderMobile-content-right">
                    <div
                        onClick={ () => { dispatch(mainSlice.actions.profileMobileShow()); } }
                        className="HeaderMobile-content-right-profile">
                        <img src={profile} alt={t('Base_profile_picture')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderMobile;
