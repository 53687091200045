import { createAsyncThunk } from "@reduxjs/toolkit";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";

/**
 * Get sensor types
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {SensorTypeDto[]|null}
 */
export const actionGetSensorTypes = createAsyncThunk<Array<SensorTypeDto> | null>(
    "sensor/getSensorTypes",
    async () => {
        return await SensorRepository.getSensorTypes();
    }
);
