import "./InputRange.scss";
import React, {ChangeEvent, useCallback, useEffect, useRef} from "react";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {Image} from "../Image/Image";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {ImageType} from "../../../models/entities/Utils/Image";
import {useTranslate} from "@tolgee/react";

/**
 * @alias InputRangeLabel
 * @category Components
 */
type InputRangeLabel = {

    /** Label text above range input **/
    text: string,
    /** Label image above range input **/
    image?: {
        /** Image source **/
        source: ImageType,
        /** Is maximal value (show red) **/
        growing?: boolean
    }
}

type InputRangeProps = {
    max?: number|null,
    label: InputRangeLabel,
    min?: number|null,
    inputName: string,
    value?: string|null|number,
    startValue?: string|null|number,
    unit: string|null,
    step?: number|null,
    disabled?: boolean,
    hideValue?: boolean,
    dispatchOnChange?: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @todo fix problem with too fast moving
 * @param {number|null|undefined} max - maximum value or 100
 * @param {number|null|undefined} min - minimum value or 0
 * @param {string|null|number|undefined} startValue - default value
 * @param {string|null|number|undefined} value - controlled value
 * @param {number|null|undefined} step - step number (can be float)
 * @param {string} inputName - input name
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>|undefined} dispatchOnChange - on change dispatch
 * @param {string|null} unit - unit
 * @param {InputRangeLabel} label - label text with image
 * @param {boolean|undefined} disabled - input range is disabled
 * @param {boolean|function} hideValue - hide current value
 */
export const InputRange = ({max, min, inputName, value, startValue, dispatchOnChange, unit, label, step, disabled, hideValue} : InputRangeProps): React.JSX.Element => {

    // Initialize base state
    const inputRef = useRef<HTMLInputElement>(null);
    const currentRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    // Update UI callback, show current data on range input
    const updateUI = useCallback((value: number) => {
        const finalMin = min ? min : 0;
        const finalMax = max ? max : 100;
        if (inputRef.current && value) {
            const percent = Math.round(100 * (value - finalMin) / (finalMax - finalMin) * 100) / 100;
            const offset = Math.round((((value - finalMin) / (finalMax - finalMin)) * ((inputRef.current.offsetWidth - 8) - 8)) + 8 - 20 + 2);
            if (disabled) {
                inputRef.current.style.background = `linear-gradient(to right, #4A596E 0%, #4A596E ${percent}%, #4A596E ${percent}%, #4A596E 100%)`;
            } else {
                inputRef.current.style.background = `linear-gradient(to right, #3CBE7D 0%, #3CBE7D ${percent}%, #87a4bc ${percent}%, #87a4bc 100%)`;
            }
            if (currentRef.current) { currentRef.current.style.left = offset.toString() + 'px'; }
        }
    }, [min, max, disabled])

    // On init
    useEffect(() => {
        if (dispatchOnChange && typeof startValue !== "undefined" && startValue !== null) {
            dispatch(dispatchOnChange({inputName: inputName, inputValue: startValue, t: t}));
            updateUI(parseInt(startValue.toString()));
        }
    }, [dispatch, inputName, dispatchOnChange, startValue, updateUI, t]);

    // On change
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) { return; }
        const value =  event.target.value.toString().length > 0 ? parseFloat(event.target.value) : null;
        if (dispatchOnChange) { dispatch(dispatchOnChange({ inputName: inputName,  inputValue: value, t: t })); }
        if (inputRef.current && value !== null) { updateUI(value) }
    }

    return (
        <div className={`InputRange ${disabled ? 'disabled' : ''} ${hideValue ? 'hideValue' : ''}`}>
            <div className={`InputRange-title`}>
                {label.image &&
                    <div className={`InputRange-title-image ${label.image.growing
                        ? t('Base_trend_increase') 
                        : t('Base_trend_lowering')}`}>
                        <Image source={label.image.source} description={label.text}/>
                    </div>
                }
                <div className={`InputRange-title-text`}>
                    {label.text}
                </div>
            </div>
            <div className={`InputRange-content`}>
                <label className={`InputRange-content-label`}>
                    <input
                        ref={inputRef}
                        onChange={handleChange.bind(this)}
                        type="range"
                        disabled={!!disabled}
                        step={step ? step : 1}
                        min={min ? min : 0}
                        value={value !== null && typeof value !== 'undefined' ? value : 0}
                        max={max ? max : 100}
                        className="InputRange-content-label-element element" />
                    <div ref={currentRef} className={'InputRange-content-label-current current'}>
                        {step && value ? SensorUtil.getNumberValueFormatFromStep(step, parseFloat(value.toString())) : (value ?? '-')}{unit ? unit : ''}
                    </div>
                </label>
                <div className={'InputRange-content-hint'}>
                    <div className={'InputRange-content-hint-item'}>
                        {step && min ? SensorUtil.getNumberValueFormatFromStep(step, min) : (min ?? '-')}{unit ? unit : ''}
                    </div>
                    <div className={'InputRange-content-hint-item'}>
                        {step && max ? SensorUtil.getNumberValueFormatFromStep(step, max) : (max ?? '-')}{unit ? unit : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InputRange;
