import {Communicator, RequestType} from "../utils/Communicator";
import {SupportRequestDto} from "../entities/Requests/SupportRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class SupportRepository {

    /**
     * Create new support message
     * @param {SupportRequestDto} supportRequest - support message info
     * @param {TranslatorType} t - translator object
     */
    public static async createSupport(supportRequest: SupportRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `support`,
            body: supportRequest
        });

        if (response.ok) { return; }
        throw new Error(t('SupportRepository_createSupport'));
    }
}
