import "./Hive.scss"
import arrowDown from "../../../images/arrowDown.svg";
import arrowUp from "../../../images/arrowUp.svg";
import {useNavigate} from "react-router-dom";
import {ImageType} from "../../../models/entities/Utils/Image";
import {T, useTranslate} from "@tolgee/react";
import {HiveMetasType} from "../../../models/utils/HiveUtil";
import offline from "../../../images/offline.svg";
import React from "react";

/**
 * @enum {string}
 * @category Components
 */
export enum HiveLineType {
    ACCENT = 'accent',
    NORMAL = 'normal'
}

/**
 * @interface
 * @category Components
 */
interface HiveItemLine {

    /** Hive line value **/
    value?: string | null,
    /** Show text smaller **/
    smallerText: boolean,
    /** Hive line unit **/
    unit: string|null,
    /** Hive line icon **/
    icon: ImageType,
    /** Line type **/
    lineType: HiveLineType,
    /** Is descending trend **/
    rising?: boolean|null,
    /** Last updated date time **/
    lastUpdatedText?: string|null,
    /** Show trend arrow **/
    showTrend: boolean,
}

/**
 * @alias HiveName
 * @category Components
 */
export type HiveName = {

    /** Subtitle **/
    sub: string|null,
    /** Accent title **/
    accent: string|null
}

/**
 * @interface
 * @category Components
 */
export interface HiveItem {

    /** On click navigation **/
    link?: string|null,
    /** Hive type **/
    type: HiveMetasType,
    /** First line **/
    first: HiveItemLine,
    /** Second line **/
    second?: HiveItemLine,
    /** Hive card name **/
    name: HiveName,
}

type HiveProps = {
    content: HiveItem,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {HiveItem} content - hive data
 */
export const Hive = ({content} : HiveProps): React.JSX.Element => {

    const {t} = useTranslate();
    const navigation = useNavigate();

    return (
        <div className={`Hive ${content.type} ${content.link ? 'link' : ''}`}
             onClick={() => { if (content.link) { navigation(content.link); } }}>

            {content.type === HiveMetasType.SILVER &&
                <div className={`Hive-empty`}>
                    <div className={`Hive-empty-left empty`}>
                        <div className={"Hive-empty-left-icon icon"}>
                            <img src={offline} alt={t('Base_not_communicated')}/>
                        </div>
                        <div className={"Hive-empty-left-inside"}>
                            <div className={`Hive-empty-left-inside-value value`}>
                                <T keyName={'Base_not_communicated'} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {content.type !== HiveMetasType.SILVER &&
                <div className={`Hive-first`}>
                <div className={`Hive-first-left ${content.first.lineType}`}>
                    <div className={"Hive-first-left-icon icon"}>
                        <img src={content.first.icon} alt={t('Base_name_sensor')}/>
                    </div>
                    <div className={"Hive-first-left-inside"}>

                        <div className={`Hive-first-left-inside-value ${content.first.smallerText ? 'message' : 'value'}`}>
                            {content.first.value ? content.first.value : '-'}
                            {content.first.unit &&
                                <span className={"Hive-first-left-inside-value-unit unit"}>
                                    &nbsp;{content.first.unit}
                                </span>
                            }
                        </div>

                        <div className={"Hive-first-left-inside-measured"}>
                            {content.first.lastUpdatedText ?? '-'}
                        </div>

                    </div>
                </div>
                {content.first.rising !== null && typeof content.first.rising !== 'undefined' &&
                    <div className={`Hive-first-right ${content.first.lineType}`}>
                        <div className={"Hive-first-right-trend trend"}>
                            {content.first.rising ?
                                <img src={arrowUp} alt={t('Base_trend_increase')}/>
                                :
                                <img src={arrowDown} alt={t('Base_trend_lowering')}/>
                            }
                        </div>
                    </div>
                }
            </div>
            }
            {content.type !== HiveMetasType.SILVER && content.second &&
                <div className={`Hive-second`}>
                    <div className={`Hive-second-left ${content.second.lineType}`}>
                        <div className={"Hive-second-left-icon icon"}>
                            <img src={content.second.icon} alt={t("Base_name_sensor")}/>
                        </div>

                        <div className={"Hive-second-left-inside"}>

                            <div className={`Hive-second-left-inside-value ${content.second.smallerText ? 'message' : 'value'}`}>
                                {content.second.value ?? '-'}
                                {content.second.unit &&
                                    <span className={"Hive-second-left-inside-value-unit unit"}>
                                        &nbsp;{content.second.unit}
                                    </span>
                                }
                            </div>

                            <div className={"Hive-second-left-inside-measured"}>
                                {content.second.lastUpdatedText ?? '-'}
                            </div>

                        </div>
                    </div>
                    {content.second.rising !== null && typeof content.second.rising !== 'undefined' &&
                        <div className={"Hive-second-right"}>
                            <div className={"Hive-second-right-trend trend"}>
                                {content.second.rising ?
                                    <img src={arrowUp} alt={t('Base_trend_increase')}/>
                                    :
                                    <img src={arrowDown} alt={t('Base_trend_lowering')}/>
                                }
                            </div>
                        </div>
                    }
                </div>

            }
            <div className={"Hive-name"}>
                {content.name.sub &&
                    <div className={"Hive-name-sub"}>
                        {content.name.sub}
                    </div>
                }
                {content.name.accent &&
                    <div className={"Hive-name-accent ellipsis reverse-ellipsis"}>
                        <span>
                            {content.name.accent}
                        </span>
                    </div>
                }
            </div>
        </div>
    );
}

export default Hive;
