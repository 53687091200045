import {HardwareResponseDto} from "../entities/Responses/HardwareResponseDto";
import {HardwareTemplateResponseDto} from "../entities/Responses/HardwareTemplateResponseDto";
import {CustomSelectValue} from "../../components/atoms/CustomSelect/CustomSelect";
import {PatientDto} from "../entities/PatientDto";
import {SensorTypeEnum} from "../entities/Enums/SensorTypeEnum";
import {HardwareWithSensorsResponseDto} from "../entities/Responses/HardwareWithSensorsResponseDto";
import {PatientSimpleResponseDto} from "../entities/Responses/PatientSimpleResponseDto";
import {HardwareSimpleResponseDto} from "../entities/Responses/HardwareSimpleResponseDto";
import {SensorTypeDto} from "../entities/SensorTypeDto";
import {UserResponseDto} from "../entities/Responses/UserResponseDto";
import {PatientWithHardwareListResponseDto} from "../entities/Responses/PatientWithHardwareListResponseDto";
import {SensorUtil} from "./SensorUtil";
import {SensorResponseDto} from "../entities/Responses/SensorResponseDto";
import {ZoneDto} from "../entities/ZoneDto";
import {HardwareDto} from "../entities/HardwareDto";

/**
 * @class
 * @category Utils
 */
export class DataParser {

    /**
     * Get concrete hardware from list of hardware
     * @function
     * @category Utils
     * @param {HardwareResponseDto[]|null|undefined} hardwareList - list of hardware
     * @param {number|undefined}hardwareId - hardware id
     */
    static getHardwareFromList(hardwareList: Array<HardwareResponseDto>|null|undefined, hardwareId?: number) : HardwareResponseDto|null|undefined {
        if (!hardwareId) { return null; }
        if (!hardwareList) { return hardwareList; }
        const result = hardwareList.find((hardware: HardwareResponseDto) => {
            return hardware.Hardware.id === hardwareId;
        }); return result ? result : null;
    }

    /**
     * Get concrete zone from list of zones
     * @function
     * @category Utils
     * @param {ZoneDto[]|null|undefined} zoneList - list of hardware
     * @param {number|undefined}zoneId - zone id
     */
    static getZoneFromList(zoneList: Array<ZoneDto>|null|undefined, zoneId?: number) : ZoneDto|null|undefined {
        if (!zoneId) { return null; }
        if (!zoneList) { return zoneList; }
        const result = zoneList.find((zone: ZoneDto) => {
            return zone.id === zoneId;
        }); return result ? result : null;
    }

    /**
     * Get template from template list
     * @function
     * @category Utils
     * @param {HardwareTemplateResponseDto[]|null|undefined} templateList - template list
     * @param {number|null|undefined} templateId - template id
     */
    static getTemplateFromList(templateList: Array<HardwareTemplateResponseDto>|null|undefined, templateId?: number|null) : HardwareTemplateResponseDto|null|undefined {
        if (!templateId) { return null; }
        if (!templateList) { return templateList; }
        const result = templateList.find((hardwareTemplate: HardwareTemplateResponseDto) => {
            return hardwareTemplate.HardwareTemplate.id === templateId;
        }); return result ? result : null;
    }

    /**
     * Get template select input list
     * @function
     * @category Utils
     * @param {HardwareTemplateResponseDto[]|null|undefined}templateList - template list
     */
    static getTemplateSelectFromList(templateList: Array<HardwareTemplateResponseDto>|null|undefined) : Array<CustomSelectValue> | null | undefined {
        if (!templateList) { return templateList; }
        return templateList.map((hardwareTemplate: HardwareTemplateResponseDto) => {
            return {label: hardwareTemplate.HardwareTemplate.name, value: hardwareTemplate.HardwareTemplate.id}
        });
    }

    /**
     * Get list for select input for patients
     * @function
     * @category Utils
     * @param {PatientWithHardwareListResponseDto} patientList - list of patients
     */
    static getPatientSelectFromList(patientList: PatientWithHardwareListResponseDto) : Array<CustomSelectValue> {

        return patientList.Patients
            .filter((patient: PatientSimpleResponseDto) => {
                return patient.Patient.active;
            })
            .map((patient: PatientSimpleResponseDto) => {
                return {label: `${patient.Patient.firstName} ${patient.Patient.lastName}`, value: patient.Patient.id}
            });
    }

    /**
     * Get list for select input for zones
     * @function
     * @category Utils
     * @param {ZoneDto[]} zoneList - list of zones
     */
    static getZoneSelectFromList(zoneList: Array<ZoneDto>) : Array<CustomSelectValue> {

        return zoneList
            .filter((zone: ZoneDto) => { return zone.active; })
            .map((zone: ZoneDto) => { return {label: zone.name, value: zone.id} });
    }

    /**
     * Get sensor by type from sensor list
     * @function
     * @category Utils
     * @param {Array<SensorResponseDto>} sensors - list of sensors
     * @param {SensorTypeEnum} type - sensor type
     */
    public static getSensorByType (sensors: Array<SensorResponseDto>, type: SensorTypeEnum) : SensorResponseDto|null {

        const sensorWithType = sensors.find((sensor: SensorResponseDto) => { return sensor.SensorTemplate.type === type; });
        if (!sensorWithType) { return null; }
        return sensorWithType;
    }

    /**
     * Get patient from patient list
     * @function
     * @category Utils
     * @param {PatientWithHardwareListResponseDto|null|undefined} patientList - patient list
     * @param {number|null} patientId - patient ID
     */
    static getPatientFromList(patientList: PatientWithHardwareListResponseDto|null|undefined, patientId: number|null) : PatientDto|null|undefined {
        if (!patientId) { return null; }
        if (!patientList) { return patientList; }
        const result = patientList.Patients.find((patient: PatientSimpleResponseDto) => {
            return patient.Patient.id === patientId;
        }); return result ? result.Patient : null;
    }

    /**
     * Get user from user list
     * @function
     * @category Utils
     * @param {UserResponseDto[]|null|undefined} userList - list of all users
     * @param {number|null} userId - user id
     */
    static getUserFromList(userList: Array<UserResponseDto>|null|undefined, userId: number|null) : UserResponseDto|null|undefined {
        if (!userId) { return null; }
        if (!userList) { return userList; }
        const result = userList.find((user: UserResponseDto) => {
            return user.User.id === userId;
        }); return result ? result : null;
    }

    /**
     * Get hardware select input list
     * @function
     * @category Utils
     * @param {HardwareResponseDto[]|null|undefined} hardwareList - list of hardware
     */
    static getHardwareSelectFromList(hardwareList: Array<HardwareResponseDto>|null|undefined) : Array<CustomSelectValue> | null | undefined {
        if (!hardwareList) { return hardwareList; }
        return hardwareList.map((hardware: HardwareResponseDto) => {
            return {label: `${hardware.Hardware.uid}`, value: hardware.Hardware.id}
        });
    }

    /**
     * Get list of all sensors from hardware list
     * @function
     * @category Utils
     * @param {HardwareWithSensorsResponseDto[]} hardwareList - list of hardware
     */
    static getSensorsFromHardwareList(hardwareList: Array<HardwareWithSensorsResponseDto>) : Array<SensorResponseDto> {

        let output : Array<SensorResponseDto> = [];
        hardwareList.forEach((hardware: HardwareWithSensorsResponseDto) => {
            hardware.Sensors.forEach((sensor: SensorResponseDto) => {
                output.push(sensor);
            });
        });
        return output;
    }

    /**
     * Get list of all hardware dto from hardware list with sensors
     * @function
     * @category Utils
     * @param {HardwareWithSensorsResponseDto[]} hardwareList - list of hardware
     */
    static getHardwareDtoListFromHardwareWithSensorsList(hardwareList: Array<HardwareWithSensorsResponseDto>) : Array<HardwareDto> {
        return hardwareList.map((item: HardwareWithSensorsResponseDto) => { return item.Hardware; });
    }

    /**
     * Get sensor types from hardware list
     * @function
     * @category Utils
     * @param {HardwareSimpleResponseDto[]} hardwareList
     */
    static getSensorTypesFromHardwareList(hardwareList: Array<HardwareSimpleResponseDto>) : Array<number> {

        let output : Array<number> = [];
        hardwareList.forEach((hardware: HardwareSimpleResponseDto) => {
            hardware.SensorTypes.forEach((type: number) => {
                output.push(type);
            });
        });
        return output;
    }

    /**
     * @function
     * @category Utils
     * @param {unknown} value
     * @param {number} index
     * @param {any[]} self
     */
    static onlyUniqueFilterFunction(value: unknown, index: number, self: Array<any>) {
        return self.indexOf(value) === index;
    }

    /**
     * Get select input list for sensor types
     * @function
     * @category Utils
     * @param {SensorTypeDto[]} sensorTypes - list of all sensor types
     * @param {any} t - translator object
     */
    public static getSensorTypesListForSelect(sensorTypes: Array<SensorTypeDto>, t: any) : Array<CustomSelectValue> {

        return sensorTypes.map((sensorType: SensorTypeDto) => {
            return {value: sensorType.id, label: SensorUtil.getMetaForSensorType(sensorType.id, t).name};
        });
    }

    /**
     * Get sensor type from sensor types list
     * @function
     * @category Utils
     * @param {SensorTypeDto[]|null|undefined} sensorTypes - list of sensor types
     * @param {number} sensorTypeId - sensor type id
     */
    static getSensorTypeFromList(sensorTypes: Array<SensorTypeDto>|null|undefined, sensorTypeId: number) : SensorTypeDto|null|undefined {
        if (!sensorTypes) { return sensorTypes; }
        const result = sensorTypes.find((sensorType: SensorTypeDto) => {
            return sensorType.id === sensorTypeId;
        }); return result ? result : null;
    }

    /**
     * Get zone from zone list using zone ID
     * @function
     * @category Utils
     * @param {Array<ZoneDto>|null|undefined} zoneList - list of zones
     * @param {number} zoneId - zone ID
     */
    static getZoneFromListById(zoneList: Array<ZoneDto>|null|undefined, zoneId: number) : ZoneDto|null|undefined {
        if (!zoneList) { return zoneList; }
        const result = zoneList.find((zone: ZoneDto) => {
            return zone.id === zoneId;
        }); return result ? result : null;
    }
}
