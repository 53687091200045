import {createSlice} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface BuildingManagerState {

    /** Action menu shown **/
    deleteDropdownShown: boolean,
}

const initialState: BuildingManagerState = {
    deleteDropdownShown: false,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const buildingManagerOrganismSlice = createSlice({
    name: 'buildingManagerOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.deleteDropdownShown = false;
        },
        toggleDeleteDropDown: (state) => {
            state.deleteDropdownShown = !state.deleteDropdownShown;
        },
        hideDeleteDropDown: (state) => {
            state.deleteDropdownShown = false;
        },
    },
})

export default buildingManagerOrganismSlice.reducer