import "./Pagination.scss"
import React from "react";
import {T} from "@tolgee/react";

/**
 * @alias PaginationCallBackChangePageType
 * @param {number} page - page number
 */
export type PaginationCallBackChangePageType = (page: number) => void;

type PaginationProps = {
    page: number,
    size: number,
    total: number,
    hideStats?: boolean,
    callBackChangePage: PaginationCallBackChangePageType,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {number} page - current page
 * @param {number} size - page size
 * @param {number} total - total elements
 * @param {PaginationCallBackChangePageType} callBackChangePage - Redux change page callback
 * @param {boolean|undefined} hideStats - hide stats text
 */
export const Pagination = ({page, size, total, callBackChangePage, hideStats} : PaginationProps): React.JSX.Element => {

    const showed = (page + 1) * size <= total ? (page + 1) * size : total;

    const getPaginationPagesPages = () : Array<number>|null => {

        const totalPages = Math.ceil(total / size) - 1;
        const currentPage = page;

        const pLeft = [];
        const pRight = [];

        if ((currentPage + 1) <= totalPages) { pRight.push(currentPage + 1); }
        if ((currentPage + 2) <= totalPages) { pRight.push(currentPage + 2); }
        if ((currentPage - 1) >= 0) { pLeft.unshift(currentPage - 1); }
        if ((currentPage - 2) >= 0) { pLeft.unshift(currentPage - 2); }

        const pLeftItems = pLeft.length;
        const pRightItems = pRight.length;

        if (pLeftItems === 1 && (currentPage + 3) <= totalPages) { pRight.push(currentPage + 3); }
        if (pLeftItems === 0 && (currentPage + 4) <= totalPages) { pRight.push(currentPage + 4); }
        if (pRightItems === 1 && (currentPage - 3) >= 0) { pLeft.unshift(currentPage - 3); }
        if (pRightItems === 0 && (currentPage - 4) >= 0) { pLeft.unshift(currentPage - 4); }
        if (currentPage === totalPages && (currentPage - 5) >= 0) { pLeft.unshift(currentPage - 5); }
        if (currentPage === 0 && (currentPage + 5) <= totalPages) { pRight.push(currentPage + 5); }

        const output = pLeft.concat([currentPage], pRight);
        if (output.length === 1) { return null; }
        return output;
    }

    const displayedPages = getPaginationPagesPages();
    const showPagination = displayedPages && displayedPages.length > 0;

    return (
        <div className={'Pagination'}>

            {showPagination && !hideStats &&
                <div className="Pagination-left">
                    <T keyName={'Pagination_showed'} params={{showed: showed, total: total, b: <b/>}} />
                </div>
            }

            {showPagination && displayedPages &&
                <div className="Pagination-right">
                    {displayedPages.map((value: number) => {
                        return (
                            <div
                                key={`page-${value}`}
                                onClick={() => callBackChangePage(value)}
                                className={`Pagination-right-item ${page === value ? 'active' : ''}`}>
                                {(value + 1).toString()}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    );
}

export default Pagination;
