import React from "react";
import "./AlertDetailTemplate.scss"
import {Header} from "../../organisms/Header/Header";
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {ListLog} from "../../organisms/ListLog/ListLog";
import {Helmet} from "react-helmet-async";
import {Navigation} from "../../organisms/Navigation/Navigation";
import {AlertInfo} from "../../organisms/AlertInfo/AlertInfo";
import {CardAlert} from "../../organisms/CardAlert/CardAlert";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {AlertGraphs} from "../../organisms/AlertGraphs/AlertGraphs";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {buildingObserverPageSlice} from "../../../store/slices/pages/buildingObserverSlice";
import {buildingManagerPageSlice} from "../../../store/slices/pages/buildingManagerSlice";
import {useTranslate} from "@tolgee/react";
import HeaderMobile from "../../organisms/HeaderMobile/HeaderMobile";
import DialModal from "../../organisms/DialModal/DialModal";
import SMSModal from "../../organisms/SMSModal/SMSModal";

type AlertDetailTemplateProps = {
    alert: AlertResponseDto,
    sensor: SensorResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    role: RoleEnum,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @todo flex class mishmash
 * @param {AlertResponseDto} alert - alert data response
 * @param {SensorResponseDto} sensor - sensor data response
 * @param {SensorTypeDto[]} sensorTypes - sensor types list
 * @param {RoleEnum} role - session user role
 */
export const AlertDetailTemplate = ({alert, sensor, sensorTypes, role}: AlertDetailTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const title = t('AlertDetailTemplate_title', {id: alert.Alert.id});

    return (
        <main>
            <div className="layout layout-flexHeight">
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <div className={`layout-left`}>
                    <Navigation
                        role={role} />
                </div>
                <div className="layout-right">
                    <HeaderMobile role={role} />
                    <DialModal />
                    <SMSModal />
                    <Header
                        role={role}
                        title={title}
                        breadItems={[
                            {name: t('Navigation_alerts'), link: '/alerts'},
                            {name: title, link: null},
                        ]} />
                    <div className="layout-right-content layout-right-content-flex flex">
                        <div className="layout-right-content-item half">
                            <CardAlert
                                performReadCallback={true}
                                role={role}
                                dispatchUpdateActiveHardwarePlan={buildingObserverPageSlice.actions.updateActiveHardware}
                                dispatchUpdateActiveHardwareEditor={buildingManagerPageSlice.actions.updateActiveHardware}
                                sensorTypes={sensorTypes}
                                alert={alert} />
                        </div>
                        <div className="layout-right-content-item half">
                            <AlertInfo
                                sensorTypes={sensorTypes}
                                sensor={sensor}
                                alert={alert} />
                        </div>
                    </div>
                    <div className="layout-right-content-item">
                        <AlertGraphs
                            alert={alert.Alert}
                            sensor={{Sensor: alert.Sensor, SensorTemplate: alert.SensorTemplate}} />
                    </div>
                    <div className="layout-right-content-item">
                        <ListLog
                            alert={alert.Alert}
                            pagination={{showCount: 8}} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AlertDetailTemplate;
