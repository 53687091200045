import "./EasyFilter.scss"
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import {Image} from "../../atoms/Image/Image";
import arrow from "../../../images/arrowDownSmallBlue.svg";
import add from "../../../images/plus.svg";
import React, {useRef} from "react";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {useTranslate} from "@tolgee/react";

/** 
 * @enum {string}
 * @category Components
 */
export enum EasyFilterColor {
    YELLOW = "yellow",
    ORANGE = "orange",
    BLUE = "blue",
    PINK = "pink",
    GREEN = "green",
    DEFAULT = "default",
    RED = "red"
}

/** 
 * @interface 
 * @category Components
 */
export interface EasyFilterItem {

    /** Filter item title **/
    title: string,
    /** Text color **/
    color?: EasyFilterColor,
    /** Is active **/
    active?: boolean,
    /** Redux change callback **/
    dispatchFilterChange?: ActionCreatorWithoutPayload,
    /** Change callback **/
    callBackFilterChange?: EmptyFunc;
}

/** 
 * @alias EasyFilterAddItem
 * @category Components
 */
type EasyFilterAddItem = {

    /** Add item callback **/
    callBack?: EmptyFunc
}

type EasyFilterProps = {
    items: Array<EasyFilterItem>,
    addItem?: EasyFilterAddItem,
    dropdownMode?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {EasyFilterItem[]} items - list of easy filters items
 * @param {EasyFilterAddItem|undefined} addItem - add item options
 * @param {boolean|undefined} dropdownMode - always show as dropdown
 */
export const EasyFilter = ({items, addItem, dropdownMode} : EasyFilterProps): React.JSX.Element => {

    // Init state
    const dispatch = useAppDispatch();
    const [showed, setShowed] = React.useState<boolean>(false);

    // Outside click trigger
    const {t} = useTranslate();
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { if (showed) { setShowed(false); } });

    // Try to find out title for default value
    const getEasyFilterMobileTitle = () : string => {

        const activeItem = items.find((item: EasyFilterItem) => item.active);
        return activeItem ? activeItem.title : t('EasyFilter_chooseFilter')
    }

    return (
        <div className={`EasyFilter`}>
            <div className={`EasyFilter-laptop  ${dropdownMode ? 'dropdownMode' : ''}`}>
                <div className={`EasyFilter-laptop-content`}>
                    {items.map((item: EasyFilterItem, index: number) => {
                        return (
                            <div
                                onClick={() => {
                                    if (item.dispatchFilterChange) { dispatch(item.dispatchFilterChange()); }
                                    if (item.callBackFilterChange) { item.callBackFilterChange(); }
                                }}
                                className={`EasyFilter-laptop-content-item ${item.color ? item.color : ''} ${item.active ? 'active' : ''}`}
                                key={`EasyFilter-item-laptop-${index}`}>
                                {item.title}
                            </div>
                        )
                    })}
                    {addItem &&
                        <div
                            onClick={() => { if (addItem.callBack) { addItem.callBack(); }}}
                            className={`EasyFilter-laptop-content-add`} >
                            <img src={add} alt={t('EasyFilter_add_button')} className={'EasyFilter-laptop-content-add-icon'} />
                        </div>
                    }
                </div>
            </div>
            <div ref={wrapperRef} className={`EasyFilter-mobile ${dropdownMode ? 'dropdownMode' : ''} ${showed ? 'active' : ''}`}>
                <div className={'EasyFilter-mobile-main main'} onClick={() => { setShowed(!showed); }}>
                    <div className="EasyFilter-mobile-main-text">
                        <span>
                           {getEasyFilterMobileTitle()}
                        </span>
                    </div>
                    <div className="EasyFilter-mobile-main-icon">
                        <Image source={arrow} description={t('EasyFilter_showDropDown')}/>
                    </div>
                </div>
                <div className="EasyFilter-mobile-content content">
                    <div className="EasyFilter-mobile-content-inside">
                        {items.map((item: EasyFilterItem, index: number) => {
                            return (
                                <div
                                    onClick={() => {
                                        setShowed(false);
                                        if (item.dispatchFilterChange) { dispatch(item.dispatchFilterChange()); }
                                        if (item.callBackFilterChange) { item.callBackFilterChange(); }
                                    }}
                                    className={`EasyFilter-mobile-content-inside-item`}
                                    key={`EasyFilter-mobile-content-${index}`}>
                                    {item.title}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EasyFilter;
