import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionGetUserNotifyOptions, actionUpdateUserNotifyOptions} from "../../actions/data/userAction";
import {ValidatorItemType} from "../../../models/utils/Validator";
import {NotifyOptionsDto} from "../../../models/entities/NotifyOptionsDto";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface UserNotifyOptionsState extends AbstractFormReducersState {
    userNotifyOptions?: NotifyOptionsDto|null
}

const initialState: UserNotifyOptionsState = {
    form: {
        inputs: [
            {name: 'alertFCM', validations: {required: false}},
            {name: 'alertMail', validations: {required: false}},
            {name: 'alertSMS', validations: {required: false}},
            {name: 'alertRepeatedFCM', validations: {required: false}},
            {name: 'alertRepeatedMail', validations: {required: false}},
            {name: 'alertRepeatedSMS', validations: {required: false}},
            {name: 'escalateSecondsThreshold', validations: {required: false, type: ValidatorItemType.NUMBER}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const userNotifyOptionsOrganismSlice = createSlice({
    name: 'userNotifyOptionsOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{
        destroyOrganism: (state: UserNotifyOptionsState) => {
            state.userNotifyOptions = undefined;
        }
    }},
    extraReducers: (builder) => {
        builder.addCase(actionUpdateUserNotifyOptions.fulfilled, (state) => {
            state.form.error = null;
        })
        builder.addCase(actionGetUserNotifyOptions.fulfilled, (state, {payload}) => {
            state.userNotifyOptions = payload;
        })
    }
})

export default userNotifyOptionsOrganismSlice.reducer
