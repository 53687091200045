import { createAsyncThunk } from "@reduxjs/toolkit";
import {SupportRequestDto} from "../../../models/entities/Requests/SupportRequestDto";
import {SupportRepository} from "../../../models/repositories/SupportRepository";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Create support message
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{supportRequest: SupportRequestDto, t: TranslatorType}} supportRequest - support message info
 * @return {void}
 */
export const actionCreateSupport = createAsyncThunk<void | string,
    {supportRequest: SupportRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "createSupport/createSupport",
    async (payload: {supportRequest: SupportRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await SupportRepository.createSupport(payload.supportRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return;
    }
);
