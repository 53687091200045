import React, {useEffect} from "react";
import "./HardwareCreateDialog.scss"
import {T, useTranslate} from "@tolgee/react";
import EasyChoose from "../../molecules/EasyChoose/EasyChoose";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {hardwareCreateDialogOrganismSlice, HardwareCreateDialogViewType} from "../../../store/slices/organisms/hardwareCreateDialogSlice";
import Button, {ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import HardwareCreateDialogManual from "../HardwareCreateDialogManual/HardwareCreateDialogManual";
import {HardwareCreateDialogScan} from "../HardwareCreateDialogScan/HardwareCreateDialogScan";
import {CreateHardwarePageScannedData} from "../../../store/slices/pages/createHardwarePageSlice";
import bulkUploadIcon from "../../../images/bulkUpload.svg"

type HardwareCreateDialogProps =  {
    scannedData: CreateHardwarePageScannedData|null,
    dispatchShowBulkImportModal: ActionCreatorWithoutPayload,
    dispatchScanRequested: ActionCreatorWithoutPayload,
    dispatchSetScannedData: ActionCreatorWithPayload<{scannedData: CreateHardwarePageScannedData | null}>
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchShowBulkImportModal - show bulk import modal Redux action
 * @param {ActionCreatorWithoutPayload} dispatchScanRequested - redux callback to show scan request modal
 * @param {CreateHardwarePageScannedData|null} scannedData - scanned data
 * @param {ActionCreatorWithPayload<{scannedData: CreateHardwarePageScannedData | null}>} dispatchSetScannedData - Redux callback to remove scanned data
 */
export const HardwareCreateDialog = ({dispatchShowBulkImportModal, dispatchScanRequested, scannedData, dispatchSetScannedData}
    : HardwareCreateDialogProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const viewTypeState = useAppSelector((state) => state.hardwareCreateDialogOrganism.viewType);

    useEffect(() => { return () => { dispatch(hardwareCreateDialogOrganismSlice.actions.destroyOrganism()); } }, [dispatch])

    return (
        <div className={"HardwareCreateDialog"}>
            <div className="HardwareCreateDialog-content">
                <div className="HardwareCreateDialog-content-title">
                    <T keyName={'HardwareCreateDialog_title'} />
                </div>
                <div className="HardwareCreateDialog-content-choose">
                    <EasyChoose items={[
                        {
                            active: viewTypeState === HardwareCreateDialogViewType.QR_SCAN,
                            name: t('HardwareCreateDialog_qrCodeScan'),
                            callback: () => {
                                dispatch(hardwareCreateDialogOrganismSlice.actions.changeViewType({
                                    viewType: HardwareCreateDialogViewType.QR_SCAN
                                }));
                            },
                        }, {
                            active: viewTypeState === HardwareCreateDialogViewType.MANUAL,
                            name: t('HardwareCreateDialog_addManual'),
                            callback: () => {
                                dispatch(hardwareCreateDialogOrganismSlice.actions.changeViewType({
                                    viewType: HardwareCreateDialogViewType.MANUAL
                                }));
                            },
                        }, {
                            active: viewTypeState === HardwareCreateDialogViewType.BULK_IMPORT,
                            name: t('HardwareCreateDialog_bulkImport'),
                            callback: () => {
                                dispatch(hardwareCreateDialogOrganismSlice.actions.changeViewType({
                                    viewType: HardwareCreateDialogViewType.BULK_IMPORT
                                }));
                            },
                    }]} />
                </div>
                <div className="HardwareCreateDialog-content-inside">
                    {viewTypeState === HardwareCreateDialogViewType.QR_SCAN &&
                        <div className={'HardwareCreateDialog-content-inside'}>
                            <div className={'HardwareCreateDialog-content-inside-scan'}>
                                <HardwareCreateDialogScan
                                    scannedData={scannedData}
                                    dispatchSetScannedData={dispatchSetScannedData}
                                    dispatchScanRequested={dispatchScanRequested} />
                            </div>
                        </div>
                    }
                    {viewTypeState === HardwareCreateDialogViewType.BULK_IMPORT &&
                        <div className={'HardwareCreateDialog-content-inside'}>
                            <div className={"HardwareCreateDialog-content-inside-bulk"}>
                                <div className={"HardwareCreateDialog-content-inside-bulk-container"}>
                                    <div className={"HardwareCreateDialog-content-inside-bulk-container-pic"}>
                                        <img src={bulkUploadIcon} alt={t('HardwareTypes_button_bulk')} />
                                    </div>
                                    <div className={"HardwareCreateDialog-content-inside-bulk-container-text"}>
                                        <T keyName={'HardwareCreateDialog_bulk_import_text'} />
                                    </div>
                                    <div className={"HardwareCreateDialog-content-inside-bulk-container-button"}>
                                        <Button
                                            onClick={ () => { dispatch(dispatchShowBulkImportModal()); } }
                                            text={t('HardwareTypes_button_bulk')}
                                            type={ButtonType.PRIMARY} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {viewTypeState === HardwareCreateDialogViewType.MANUAL &&
                        <div className={'HardwareCreateDialog-content-inside-manual'}>
                            <HardwareCreateDialogManual />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default HardwareCreateDialog;
