import {createSlice, PayloadAction} from '@reduxjs/toolkit'

/** 
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum WelcomePageViewType {
    LOGIN = 1,
    REGISTER = 2,
    RECOVERY = 3,
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface WelcomePageState {

    /** Current welcome page view type **/
    viewType: WelcomePageViewType
}

const initialState: WelcomePageState = {
    viewType: WelcomePageViewType.LOGIN
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const welcomePageSlice = createSlice({
    name: 'welcomePage',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<{viewType: WelcomePageViewType }>) => {
            state.viewType = action.payload.viewType;
        },
        destroyPage: (state) => {
            state.viewType = WelcomePageViewType.LOGIN;
        },
    },
})

export default welcomePageSlice.reducer