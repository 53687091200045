import {Communicator, RequestType} from "../utils/Communicator";
import {CacheExpiry} from "../utils/Storage";
import {PatientRequestDto} from "../entities/Requests/PatientRequestDto";
import {PatientResponseDto} from "../entities/Responses/PatientResponseDto";
import {PatientWithHardwareListResponseDto} from "../entities/Responses/PatientWithHardwareListResponseDto";
import {HardwareUnassignBulkRequestDto} from "../entities/Requests/HardwareUnassignBulkRequestDto";
import {ZoneUnassignBulkRequestDto} from "../entities/Requests/ZoneUnassignBulkRequestDto";
import {ZoneDto} from "../entities/ZoneDto";
import {ZoneBanRequestDto} from "../entities/Requests/ZoneBanRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class PatientRepository {

    /**
     * Locate patient by ID
     * @param {number} patientId - patient ID
     * @param {TranslatorType} t - translator object
     */
    public static async locatePatient(patientId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `patient/${patientId}/locate`,
        });

        if (response.ok) { return; }
        throw new Error(t('PatientRepository_locatePatient'))
    }

    /**
     * Get list of all patients
     */
    public static async getPatientList() : Promise<PatientWithHardwareListResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "patient",
            cache: {cacheKey: "getPatientList", expiry: CacheExpiry.FIVE_MINUTES, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get list of all patient banned zones
     * @param {number} patientId - patient ID
     */
    public static async getBannedZonesList(patientId: number) : Promise<ZoneDto[]|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `zoneBan/${patientId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get patient by id
     * @param {number} patientId - patient ID
     */
    public static async getPatient(patientId: number) : Promise<PatientResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `patient/${patientId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Update patient by patient ID
     * @param {number} patientId - patient ID
     * @param {PatientRequestDto} patientRequest - info about update
     * @param {TranslatorType} t - translator object
     */
    public static async updatePatient(patientId: number, patientRequest: PatientRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            endpoint: `patient/${patientId}`,
            body: patientRequest,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PatientRepository_updatePatient'));
    }

    /**
     * Create new patient
     * @param {PatientRequestDto} patientRequest - info about new patient
     * @param {TranslatorType} t - translator object
     */
    public static async createPatient(patientRequest: PatientRequestDto, t: TranslatorType) : Promise<PatientResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "patient",
            body: patientRequest,
            cache: {deleteKeys: ['getHive', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        if (response.apiError?.code === 'CREATE_PATIENT_EXISTS') { throw new Error(t('PatientRepository_createPatientErrExists')); }
        throw new Error(t('PatientRepository_createPatient'))
    }

    /**
     * Delete patient by patient ID
     * @param {number} patientRequest - patient ID
     * @param {TranslatorType} t - translator object
     */
    public static async deletePatient(patientRequest: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `patient/${patientRequest}`,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PatientRepository_deletePatient'));
    }

    /**
     * Undelete patient by patient ID
     * @param {number} patientId - patient to undelete
     * @param {TranslatorType} t - translator object
     */
    public static async activatePatient(patientId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `patient/${patientId}/undelete`,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return; }
        throw new Error(t('PatientRepository_activatePatient'));
    }

    /**
     * Un-assign list of hardware IDs from patient
     * @param {number} patientId - patient to remove hardware list
     * @param {TranslatorType} t - translator object
     * @param {HardwareUnassignBulkRequestDto} request - request object
     * @return {Promise<PatientResponseDto>}
     */
    public static async unAssignHardwareFromPatient(patientId: number, request: HardwareUnassignBulkRequestDto, t: TranslatorType) : Promise<PatientResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            body: request,
            endpoint: `patient/${patientId}/hardware/unassign`,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PatientRepository_unAssignHardwareFromPatient'));
    }

    /**
     * Un-assign list of zone IDs from patient
     * @param {number} patientId - patient to remove hardware list
     * @param {TranslatorType} t - translator object
     * @param {ZoneUnassignBulkRequestDto} request - request object
     * @return {Promise<PatientResponseDto>}
     */
    public static async unAssignZonesFromPatient(patientId: number, request: ZoneUnassignBulkRequestDto, t: TranslatorType) : Promise<PatientResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            body: request,
            endpoint: `patient/${patientId}/zone/unassign`,
            cache: {deleteKeys: []}
        });

        if (response.ok) { return response.data; }
        if (request.zoneList.length > 1) {
            throw new Error(t('PatientRepository_unAssignZonesFromPatientZoneList'));
        } else {
            throw new Error(t('PatientRepository_unAssignZonesFromPatientZone'));
        }
    }

    /**
     * Ban zone for patient ID
     * @param {number} patientId - patient ID to ban
     * @param {TranslatorType} t - translator object
     * @param {ZoneBanRequestDto} banRequest - zone ID to be banned
     * @return {Promise<PatientResponseDto>}
     */
    public static async banZoneForPatient(patientId: number, banRequest: ZoneBanRequestDto, t: TranslatorType) : Promise<ZoneDto[]> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            body: banRequest,
            endpoint: `zoneBan/${patientId}`,
            cache: {deleteKeys: []}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PatientRepository_banZoneForPatient'));
    }
}
