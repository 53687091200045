import { createAsyncThunk } from "@reduxjs/toolkit";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {DataResponseDto} from "../../../models/entities/Responses/DataResponseDto";

/**
 * Get sensor data by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} sensorId - sensor ID
 * @return {{sensorId: number, response: DataResponseDto | null}}
 */
export const actionGetSensorData = createAsyncThunk<{sensorId: number, response: DataResponseDto | null}, number>(
    "hardwareGraphs/getSensorData",
    async (sensorId: number) => {
        return {sensorId: sensorId, response: await SensorRepository.getDataListBySensor(sensorId)};
    }
);
