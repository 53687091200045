import { createAsyncThunk } from "@reduxjs/toolkit";
import {AlertRepository} from "../../../models/repositories/AlertRepository";
import {AlertResponseDto} from "../../../models/entities/Responses/AlertResponseDto";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {AlertCountResponseDto} from "../../../models/entities/Responses/AlertCountResponseDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get alert list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {AlertResponseDto[] | null}
 */
export const actionGetAlertList = createAsyncThunk<Array<AlertResponseDto> | null>(
    "alert/loadAlertList",
    async () => {
        return await AlertRepository.getAlerts();
    }
);


/**
 * Set active alert count
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} count - active count
 * @return {number}
 */
export const actionSetActiveCount = createAsyncThunk<number, number>(
    "alert/setActiveCount",
    async (count: number) => {
        return count;
    }
);

/**
 * Get alert active count - fresh data
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {AlertCountResponseDto | null}
 */
export const actionGetAlertActiveCount = createAsyncThunk<AlertCountResponseDto | null>(
    "alert/getAlertActiveCount",
    async () => {
        return await AlertRepository.getAlertActiveCount(true);
    }
);

/**
 * Delete alert by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{alertId: number, t: TranslatorType}}
 * @return {AlertResponseDto[] | null}
 */
export const actionDeleteAlert = createAsyncThunk<{alertListResponse: Array<AlertResponseDto> | null, newCount: AlertCountResponseDto | null} | string,
    {alertId: number, t: TranslatorType}>(
    "alert/deleteAlert",
    async (payload: {alertId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await AlertRepository.deleteAlert(payload.alertId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return {
            alertListResponse: await AlertRepository.getAlerts(),
            newCount: await AlertRepository.getAlertActiveCount(true)
        };
    }
);

/**
 * Get alert by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} alertId - alert id
 * @return {AlertResponseDto | null}
 */
export const actionGetAlert = createAsyncThunk<AlertResponseDto | null, number>(
    "alert/getAlert",
    async (alertId: number) => {
        return await AlertRepository.getAlert(alertId);
    }
);

/**
 * Get sensor by ID for alerts
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} sensorId - sensor id
 * @return {SensorResponseDto | null}
 */
export const actionGetAlertSensor = createAsyncThunk<SensorResponseDto | null, number>(
    "alert/getAlertSensor",
    async (sensorId: number) => {
        return await SensorRepository.getSensor(sensorId);
    }
);

/**
 * Set alert state to be read
 * @category Redux
 * @subcategory Actions
 * @function
 * @todo mby no need to refresh
 * @param {{alertId: number, t: TranslatorType}}
 * @return {AlertResponseDto[] | null}
 */
export const actionReadAlert = createAsyncThunk<
    {alertListResponse: Array<AlertResponseDto> | null, newCount: AlertCountResponseDto | null} | string,
    {alertId: number, t: TranslatorType}>(
    "alert/readAlert",
    async (payload: {alertId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await AlertRepository.readAlert(payload.alertId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return {
            alertListResponse: await AlertRepository.getAlerts(),
            newCount: await AlertRepository.getAlertActiveCount(true)
        };
    }
);
