import React, {ChangeEvent, useCallback, useEffect, useRef} from "react";
import "./SensorState.scss"
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Image} from "../../atoms/Image/Image";
import {SensorUtil} from "../../../models/utils/SensorUtil";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import arrowDown from "../../../images/arrowDown.svg"
import arrowUp from "../../../images/arrowUp.svg"
import edit from "../../../images/edit.svg"
import {SensorLastValueTrendEnum} from "../../../models/entities/Enums/SensorLastValueTrendEnum";
import {DataParser} from "../../../models/utils/DataParser";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {SensorAlertStateEnum} from "../../../models/entities/Enums/SensorAlertStateEnum";
import {actionUpdateAlertState} from "../../../store/actions/organisms/sensorStateAction";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {AlertResolutionStatusEnum} from "../../../models/entities/Enums/AlertResolutionStatusEnum";
import {sensorStateOrganismSlice} from "../../../store/slices/organisms/sensorStateSlice";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {T, useTranslate} from "@tolgee/react";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {ZoneDto} from "../../../models/entities/ZoneDto";

interface SensorStateProps {

    sensor: SensorResponseDto,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>
    dispatchShowSensorEditModal: ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {SensorResponseDto} sensor - sensor data
 * @param {number} hardwareId - hardware id
 * @param {ActionCreatorWithPayload<{ sensorId: number, hardwareId: number }>} dispatchShowSensorEditModal - dispatch show sensor edit modal
 * @param {SensorTypeDto[]} sensorTypes - list of sensor types
 * @param {ZoneDto[]} zoneList - list of zones
 */
export const SensorState = ({sensor, dispatchShowSensorEditModal, sensorTypes, zoneList}: SensorStateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const noteSensorIdState = useAppSelector((state) => state.sensorStateOrganism.noteSensorId);
    const noteShownStateDraft = useAppSelector((state) => state.sensorStateOrganism.noteShown) && (noteSensorIdState === sensor.Sensor.id);
    const noteStateDraft = useAppSelector((state) => state.sensorStateOrganism.currentNote);
    const noteShownState = noteShownStateDraft && (noteSensorIdState === sensor.Sensor.id);
    const noteState = noteSensorIdState === sensor.Sensor.id ? noteStateDraft : null;
    const sensorType = DataParser.getSensorTypeFromList(sensorTypes, sensor.SensorTemplate.type);

    useEffect(() => { return () => { dispatch(sensorStateOrganismSlice.actions.destroyOrganism()); } }, [dispatch])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { if (noteShownState) {
        dispatch(sensorStateOrganismSlice.actions.hideNote());
    } });

    /**
     * Update alert state UI callback
     * @param {SensorAlertStateEnum} state - target state of alert
     */
    const handleUpdateAlertState = async (state: AlertResolutionStatusEnum) => {

        if (!sensor.Sensor.currentAlert) { return; }
        const result = await dispatch(actionUpdateAlertState({t: t, alertId: sensor.Sensor.currentAlert, request: {resolutionStatus: state}}));
        dispatch(sensorStateOrganismSlice.actions.showNote({noteSensorId: sensor.Sensor.id}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }
    }

    /**
     * Contains function with throttle to send update to BE
     */
    const handleUpdateAlertNote = useCallback(async () => {

        if (!sensor.Sensor.currentAlert) {
            dispatch(sensorStateOrganismSlice.actions.hideNote());
            return;
        }

        const result = await dispatch(actionUpdateAlertState({
            t: t,
            alertId: sensor.Sensor.currentAlert,
            request: {
                resolutionStatus: sensor.Sensor.alertState === SensorAlertStateEnum.ALERTING
                    ? AlertResolutionStatusEnum.UNRESOLVED
                    : AlertResolutionStatusEnum.RESOLVED,
                resolutionNote: noteState ? noteState : ''
            }
        }));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }
        dispatch(sensorStateOrganismSlice.actions.hideNote());
    }, [dispatch, noteState, sensor, t]);

    /**
     * Change note value in Redux store
     * @param event - event from textarea
     */
    const handleChangeNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {

        const note = event.target.value.toString().length > 0 ? event.target.value.toString() : null;
        dispatch(sensorStateOrganismSlice.actions.setNote({note: note}));
    }, [dispatch]);

    /**
     * Get note element content
     */
    const getNoteElement = useCallback(() => {

        return (
          <div className={'SensorState-resolution-inside-item-note note'} ref={wrapperRef}>
              <div className={'SensorState-resolution-inside-item-note-triangle noteTriangle'}>
                  <div className={'SensorState-resolution-inside-item-note-triangle-sub noteTriangleSub'}>
                  </div>
              </div>
              <div className={'SensorState-resolution-inside-item-note-content noteContent'}>
                    <textarea
                        defaultValue={sensor.Sensor.alertResolvedNote ? sensor.Sensor.alertResolvedNote : undefined}
                        onChange={ (event: ChangeEvent<HTMLTextAreaElement>) => handleChangeNote(event)}
                        placeholder={t('SensorState_enter_note')} />
              </div>
                  <div className={`SensorState-resolution-inside-item-note-action noteAction`}>
                      <Button
                          text={t('SensorState_save_note')}
                          onClick={() => { void handleUpdateAlertNote(); }}
                          type={ButtonType.SECONDARY} />
                  </div>
          </div>
      )
    }, [sensor, handleChangeNote, handleUpdateAlertNote, t])

    return (
        <div className={`SensorState ${sensor.Sensor.alertState}`}>
            <div className={`SensorState-title`}>
                {sensor.Sensor.customName ?? SensorUtil.getMetaForSensorType(sensor.SensorTemplate.type, t).name}
            </div>
            <div className="SensorState-content">
                <div className="SensorState-content-left">
                    <div className={`SensorState-content-left-first imageContainer`}>
                        <div className="SensorState-content-left-first-inside">
                            <div className="SensorState-content-left-first-inside-image">
                                <Image
                                    source={SensorUtil.getMetaForSensorType(sensor.SensorTemplate.type, t).icon}
                                    description={SensorUtil.getMetaForSensorType(sensor.SensorTemplate.type, t).name}/>
                            </div>
                        </div>
                    </div>
                    <div className="SensorState-content-left-second">
                        <div className="SensorState-content-left-second-top">
                            <div className={`SensorState-content-left-second-top-left`}>
                                <div className={`SensorState-content-left-second-top-left-value`}>
                                    {SensorUtil.getValueInterpretationForSensorType(sensor, zoneList, t)}
                                </div>
                                {sensor.Sensor.lastReadAt !== null && sensorType &&
                                    <div className={`SensorState-content-left-second-top-left-unit`}>
                                        {sensorType.unit}
                                    </div>
                                }
                            </div>
                            {sensor.Sensor.lastValueTrend !== null && SensorUtil.showTrendOrMinMaxBySensorType(sensor.SensorTemplate.type) &&
                                <div className="SensorState-content-left-second-top-right">
                                    {sensor.Sensor.lastValueTrend === SensorLastValueTrendEnum.DOWN &&
                                        <Image source={arrowDown} description={t('Base_trend_lowering')}/>
                                    }
                                    {sensor.Sensor.lastValueTrend === SensorLastValueTrendEnum.UP &&
                                        <Image source={arrowUp} description={t('Base_trend_increase')}/>
                                    }
                                </div>
                            }
                        </div>
                        <div className="SensorState-content-left-second-bottom">
                            {SensorUtil.getTimeFormatAgoForDateWithFallBackText(
                                sensor.Sensor.lastReadAtDiff, t, false, sensor.Sensor.lastReadAtDiff !== null)}
                        </div>
                    </div>
                </div>
                <div className="SensorState-content-right">
                    {SensorUtil.showTrendOrMinMaxBySensorType(sensor.SensorTemplate.type) &&
                        <div className="SensorState-content-right-top">
                            <Button
                                image={edit}
                                text={t('SensorState_button_edit')}
                                type={ButtonType.SECONDARY}
                                onClick={() => {
                                    dispatch(dispatchShowSensorEditModal({sensorId: sensor.Sensor.id, hardwareId: sensor.Sensor.hardware}));
                                }}/>
                        </div>
                    }
                    {sensor.Sensor.lastReadAt !== null && SensorUtil.showTrendOrMinMaxBySensorType(sensor.SensorTemplate.type) &&
                        <div className="SensorState-content-right-bottom">
                            <div className="SensorState-content-right-bottom-value">
                                <div className="SensorState-content-right-bottom-value-item">
                                    Min {sensor.Sensor.limitMin ?? '-'} {sensorType ? sensorType.unit : ''}
                                </div>
                                <div className="SensorState-content-right-bottom-value-item">
                                    Max {sensor.Sensor.limitMax ?? '-'} {sensorType ? sensorType.unit : ''}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {sensor.Sensor.currentAlert && sensor.Sensor.alertState !== SensorAlertStateEnum.NONE &&
                <div className={`SensorState-resolution ${sensor.Sensor.alertState}`}>
                    <div className={"SensorState-resolution-inside inside"}>
                        <div
                            className={`SensorState-resolution-inside-item item 
                                ${sensor.Sensor.alertState === SensorAlertStateEnum.ALERTING ? 'active activeTRG' : ''}`}>
                            {sensor.Sensor.alertState === SensorAlertStateEnum.ALERTING && noteShownState && getNoteElement()}
                            <div
                                onClick={() => { void handleUpdateAlertState(AlertResolutionStatusEnum.UNRESOLVED); }}
                                className={'SensorState-resolution-inside-item-text text'}>
                                <T keyName={'SensorState_alert_inProgress'} />
                            </div>
                        </div>
                        <div
                            className={`SensorState-resolution-inside-item item
                                ${sensor.Sensor.alertState === SensorAlertStateEnum.RESOLVED ? 'active activeTRG' : ''}`}>
                            {sensor.Sensor.alertState === SensorAlertStateEnum.RESOLVED && noteShownState && getNoteElement()}
                            <div
                                onClick={() => { void handleUpdateAlertState(AlertResolutionStatusEnum.RESOLVED); }}
                                className={'SensorState-resolution-inside-item-text text'}>
                                <T keyName={'SensorState_alert_resolved'} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default SensorState;
