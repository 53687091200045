import React, {useEffect} from "react";
import {DashboardTemplate} from "../templates/DashboardTemplate/DashboardTemplate";
import {useAppDispatch} from "../../store/hooks";
import {dashboardPageSlice} from "../../store/slices/pages/dashboardPageSlice";
import {Storage} from "../../models/utils/Storage";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {useTranslate} from "@tolgee/react";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const DashboardPage = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const role = Storage.getSession()?.user.role;
    const {t} = useTranslate();

    useEffect(() => { return () => { dispatch(dashboardPageSlice.actions.destroyFilter()); } }, [dispatch, role])

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    return (
        <DashboardTemplate
            role={role} />
    )
};

export default DashboardPage;
