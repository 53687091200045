import {createSlice} from '@reduxjs/toolkit'
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";
import {actionRefreshHardwareSensors} from "../../actions/organisms/hardwareStatesAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface HardwareStatesSlice {
    hardwareSensors?: Array<SensorResponseDto>|null,

}

const initialState: HardwareStatesSlice = {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareStatesOrganismSlice = createSlice({
    name: 'hardwareStatesOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state: HardwareStatesSlice) => {
            state.hardwareSensors = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionRefreshHardwareSensors.fulfilled, (state, {payload}) => {
            state.hardwareSensors = payload;
        })
    }
})

export default hardwareStatesOrganismSlice.reducer
