import React from "react";
import "./HardwareInfo.scss"
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {Image} from "../../atoms/Image/Image";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {Button, ButtonType} from "../../atoms/Button/Button";
import building from "../../../images/building.svg";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useNavigate} from "react-router-dom";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../store/hooks";
import edit from "../../../images/edit.svg"
import eye from "../../../images/eye.svg"
import qr from "../../../images/QR.svg"
import {T, useTranslate} from "@tolgee/react";
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";

type HardwareInfoProps = {
    hardware: HardwareResponseDto,
    role: RoleEnum,
    dispatchUpdateActiveHardwarePlan: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchUpdateActiveHardwareEditor: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    dispatchShowAddOrEditHardwareModal: ActionCreatorWithPayload<{ hardwareId: number }>,
    dispatchShowQRGenModal: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {HardwareResponseDto} hardware - hardware data
 * @param {RoleEnum} role - current user role
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardwareEditor - dispatch to set active HW in building editor
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardwarePlan - dispatch to set active HW in building plan
 * @param {ActionCreatorWithPayload<{ hardwareId: number }>} dispatchShowAddOrEditHardwareModal - show hardware edit modal callback
 * @param {ActionCreatorWithoutPayload} dispatchShowQRGenModal - hide QR generator modal
 */
export const HardwareInfo = ({hardware, role, dispatchUpdateActiveHardwareEditor, dispatchShowQRGenModal,
    dispatchUpdateActiveHardwarePlan, dispatchShowAddOrEditHardwareModal} : HardwareInfoProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const meta = HardwareUtil.getHardwareTypeMetaFromHardwareType(hardware.HardwareTemplate.type, t);
    const colorClassFromHardwareType = meta.colorClass;

    return (
        <div className={"HardwareInfo"}>
            <div className="HardwareInfo-functions">
                <div className="HardwareInfo-functions-left">
                </div>
                <div className="HardwareInfo-functions-right">
                    <div className="HardwareInfo-functions-right-item edit">
                        <div className="HardwareInfo-functions-right-item-button">
                            <Button
                                image={edit}
                                onClick={() => { dispatch(dispatchShowAddOrEditHardwareModal({hardwareId: hardware.Hardware.id})) }}
                                text={t('Base_goto_editDetails')}
                                type={ButtonType.PRIMARY}/>
                        </div>
                    </div>
                    <div className="HardwareInfo-functions-right-item">
                        <div className="HardwareInfo-functions-right-item-button">
                            <Button
                                image={qr}
                                onClick={() => { dispatch(dispatchShowQRGenModal()); }}
                                text={t('HardwareInfo_button_qr_generator')}
                                type={ButtonType.PRIMARY}/>
                        </div>
                    </div>
                    {role !== RoleEnum.MEDICAL && hardware.BuildingFloor && hardware.BuildingFloor.elevation &&
                        <div className="HardwareInfo-functions-right-item">
                            <div className="HardwareInfo-functions-right-item-button">
                                    <Button
                                        image={building}
                                        onClick={() => {
                                            dispatch(dispatchUpdateActiveHardwareEditor({
                                                id: hardware.Hardware.id,
                                                callerType: AbstractBuildingActiveType.DETAIL_PAGE
                                            }))
                                            navigation('/editor');
                                        }}
                                        text={t('Base_goto_building_editor')}
                                        type={ButtonType.PRIMARY}/>
                            </div>
                        </div>
                    }
                    {role !== RoleEnum.TECHNICAL && hardware.BuildingFloor && hardware.BuildingFloor.elevation &&
                        <div className="HardwareInfo-functions-right-item">
                            <div className="HardwareInfo-functions-right-item-button">
                                <Button
                                    image={eye}
                                    onClick={() => {
                                        dispatch(dispatchUpdateActiveHardwarePlan({
                                            id: hardware.Hardware.id,
                                            callerType: AbstractBuildingActiveType.DETAIL_PAGE
                                        }))
                                        navigation('/building');
                                    }}
                                    text={t('Base_goto_building_plan')}
                                    type={ButtonType.IMAGE_EYE}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="HardwareInfo-content">
                <div className="HardwareInfo-content-parameters">
                    <div className="HardwareInfo-content-parameters-left">
                        <div className="HardwareInfo-content-parameters-left-image">
                            <Image
                                source={meta.image}
                                description={t('Base_name_hardware')}/>
                        </div>
                    </div>
                    <div className="HardwareInfo-content-parameters-right">
                        <div className="HardwareInfo-content-parameters-right-first">
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_category'} />
                                    </div>
                                    <div className="HardwareInfo-content-parameters-right-item-content-value">
                                        {hardware.HardwareTemplate.name}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_model'} />
                                    </div>
                                    <div className="HardwareInfo-content-parameters-right-item-content-value">
                                        {hardware.HardwareTemplate.model}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_owner'} />
                                    </div>
                                    {hardware.Patient ?
                                        <div
                                            onClick={ () => { if (hardware.Patient) { navigation(`/patient/${hardware.Patient.id}`) } } }
                                            className="HardwareInfo-content-parameters-right-item-content-value link">
                                            {hardware.Patient.firstName + ' ' + hardware.Patient.lastName}
                                        </div>
                                        :
                                        <div className="HardwareInfo-content-parameters-right-item-content-value">
                                            -
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_uid'} />
                                    </div>
                                    <div className={`HardwareInfo-content-parameters-right-item-content-value`}>
                                        {hardware.Hardware.uid}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_last_communication'} />
                                    </div>
                                    <div className="HardwareInfo-content-parameters-right-item-content-value">
                                        {hardware.Hardware.communicatedAt ? ValueUtil.getStringFromValue(t, hardware.Hardware.communicatedAt) : '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="HardwareInfo-content-parameters-right-second">
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className={`HardwareInfo-content-parameters-right-item-content-name`}>
                                        <T keyName={'HardwareInfo_type'} />
                                    </div>
                                    <div className={`HardwareInfo-content-parameters-right-item-content-value ${colorClassFromHardwareType}`}>
                                        {meta.title}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_state'} />
                                    </div>
                                    <div className={`HardwareInfo-content-parameters-right-item-content-value 
                                        ${hardware.Hardware.active ? 'green' : 'red'}`}>
                                        {hardware.Hardware.active ? t('Base_active') : t('Base_inactive')}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_location'} />
                                    </div>
                                    {hardware.HardwareLocation && hardware.BuildingFloor && hardware.BuildingFloor.elevation ?
                                        <div className="HardwareInfo-content-parameters-right-item-content-value">
                                            {hardware.BuildingFloor.elevation}. <T keyName={'Base_name_floor'} />
                                        </div>
                                        :
                                        <div className="HardwareInfo-content-parameters-right-item-content-value">
                                            -
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_custom'} />
                                    </div>
                                    <div className="HardwareInfo-content-parameters-right-item-content-value">
                                        {hardware.Hardware.internalId ?? '-'}
                                    </div>
                                </div>
                            </div>
                            <div className="HardwareInfo-content-parameters-right-item item">
                                <div className="HardwareInfo-content-parameters-right-item-content">
                                    <div className="HardwareInfo-content-parameters-right-item-content-name">
                                        <T keyName={'HardwareInfo_zone'}/>
                                    </div>
                                    {hardware.Zone ?
                                        <div
                                            onClick={ () => { if (hardware.Zone) { navigation(`/zone/${hardware.Zone.id}`) } } }
                                            className="HardwareInfo-content-parameters-right-item-content-value link">
                                            {hardware.Zone?.name ?? '-'}
                                        </div>
                                        :
                                        <div className="HardwareInfo-content-parameters-right-item-content-value">
                                           -
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HardwareInfo;
