import {ValueUtil, ValueUtilParsableValue} from "../../../models/utils/ValueUtil";
import {Image} from "../Image/Image";
import React from "react";
import {showConfirmDialog} from "../../../models/utils/UIUtil";
import "./TableColumn.scss"
import {Dot, DotType} from "../Dot/Dot";
import {CustomSelect, CustomSelectValue} from "../CustomSelect/CustomSelect";
import {useAppDispatch} from "../../../store/hooks";
import limitDown from "../../../images/arrowDown.svg";
import limitUp from "../../../images/arrowUp.svg";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {Role} from "../../molecules/Role/Role";
import {Help} from "../Help/Help";
import {AbstractFormUpdateCallBack} from "../../../models/utils/AbstractFormReducers";
import {ImageType} from "../../../models/entities/Utils/Image";
import {useTranslate} from "@tolgee/react";
import Label from "../Label/Label";
import yesIcon from "../../../images/inputOK.svg";
import noIcon from "../../../images/inputNOK.svg";
import {TableDetailLinkType} from "./TableDetail";

/**
 * @enum {string}
 * @category Components
 */
export enum TableCellDataTextColor {
    GREEN = "green",
    YELLOW = "yellow",
    BLUE = "blue",
    PINK = "pink",
    DEFAULT = "default",
    ORANGE = "orange"
}

/**
 * @interface
 * @category Components
 */
interface TableCellDataSelect {

    /** Options for select in table column **/
    options: Array<CustomSelectValue>,
    /** Select is clearable **/
    clearable: boolean,
    /** Callback when value changed **/
    callback: AbstractFormUpdateCallBack,
    /** Default value **/
    default?: number|null,
    /** Select name **/
    name: string,
    /** Shown placeholder in select **/
    placeholder: string,
    /** Default text shown **/
    defaultText?: string|null
}

/**
 * @interface
 * @category Components
 */
export interface TableCellData {

    /** Basic text is shown **/
    text?: ValueUtilParsableValue,
    /** Shown empty text **/
    emptyText?: string|null,
    /** Label element is shown **/
    label?: ValueUtilParsableValue,
    /** Is limit value **/
    limit?: {min?: number|null, max?: number|null, unit: string|null},
    /** Is extreme value **/
    extreme?: {min?: number|null, max?: number|null, unit: string|null},
    /** Is date **/
    date?: string|null,
    /** Is datetime **/
    dateTime?: string,
    /** Is colored text **/
    colorText?: {  text: ValueUtilParsableValue, color: TableCellDataTextColor }
    /** Is list of sensors **/
    sensors?: Array<TableCellDataSensor>,
    /** Is image **/
    image?: TableCellDataImage|null,
    /** Is bool **/
    boolean?: {flag: boolean|null, text?: string},
    /** Is user role **/
    role?: RoleEnum|null,
    /** Is dot **/
    dot?: {type: DotType, stateName?: string, highlight?: boolean},
    /** Is input type select **/
    select?: TableCellDataSelect,
    /** Is list of actions **/
    actions?: Array<TableCellDataAction>
}

/**
 * @interface
 * @category Components
 */
export interface TableCellDataImage {

    /** Image name **/
    text: string,
    /** Image source address **/
    source: ImageType,
}

/**
 * @interface
 * @category Components
 */
export interface TableCellDataSensor {

    /** Sensor name shown in help **/
    name: string,
    /** Sensor type icon **/
    icon: any,
}

/**
 * @enum
 * @category Components
 * @return {string}
 */
export enum TableCellDataActionTypeEnum {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TERNARY = "ternary",
}

/**
* @interface
* @category Components
*/
export interface TableCellDataAction {

    /** Action type **/
    type: TableCellDataActionTypeEnum,
    /** Action image **/
    image: ImageType,
    /** Action title **/
    title: string,
    /** Show confirm dialog **/
    confirm ?: { title: string, successTitle: string },
    /** Trigger on confirm **/
    trigger: () => Promise<void>,
    /** Extra css for re-activate **/
    isReActivateIcon ?: boolean,
    /** Button type for mobile use **/
    linkType: TableDetailLinkType,
}


type TableColumnProps = {
    first: boolean
    cellData: TableCellData,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {TableCellData} cellData - cell data
 * @param {boolean} first - is first col
 */
export const TableColumn = ({cellData, first} : TableColumnProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    return (
        <td className={`TableColumn TableColumn-column ${cellData.actions ? 'action' : ''} ${cellData.dot ? 'dot' : ''} ${first ? 'first' : ''}`}>
            <div className="TableColumn-column-container">
                {cellData.image &&
                    <div className="TableColumn-column-container-image">
                        <div className={`TableColumn-column-container-image-item`}>
                            <Image source={cellData.image.source} description={cellData.image.text}/>
                        </div>
                    </div>
                }
                {cellData.role &&
                    <div className="TableColumn-column-container-role">
                        <div className={`TableColumn-column-container-role-item`}>
                            <Role role={cellData.role} />
                        </div>
                    </div>
                }
                {typeof cellData.text !== 'undefined' &&
                    <span>{ValueUtil.getStringFromValue(t, cellData.text)}</span>
                }
                {typeof cellData.emptyText !== 'undefined' &&
                    <span>{ValueUtil.getStringFromValue(t, cellData.emptyText)}</span>
                }
                {typeof cellData.label !== 'undefined' &&
                    <div className='TableColumn-column-container-label'>
                        <Label text={ValueUtil.getStringFromValue(t, cellData.label)} />
                    </div>
                }
                {typeof cellData.boolean !== 'undefined' &&
                    <div className='TableColumn-column-container-boolean'>
                        <div className='TableColumn-column-container-boolean-image'>
                            {cellData.boolean.flag
                                ? <img src={yesIcon} alt={t('TableColumn_boolean_yes')}/>
                                : <img src={noIcon} alt={t('TableColumn_boolean_no')}/>}
                        </div>
                        {cellData.boolean.text &&
                            <div className={`TableColumn-column-container-boolean-text ${cellData.boolean.flag ? 'yes' : 'no'}`}>
                                {cellData.boolean.text}
                            </div>
                        }
                    </div>
                }
                {typeof cellData.limit !== 'undefined' &&
                    <div className='TableColumn-column-container-limits'>
                        {!!(cellData.limit.min) &&
                            <div className='TableColumn-column-container-limits-limit min'>
                                <div className='TableColumn-column-container-limits-limit-first first'>
                                    <Image source={limitDown} description={t('TableColumn_down_arrow')} />
                                </div>
                                <div className='TableColumn-column-container-limits-limit-last last'>
                                    {cellData.limit.min} {cellData.limit.unit ? cellData.limit.unit : ''}
                                </div>
                                <Help
                                    accent={t('TableColumn_limit_minimal_before')}
                                    afterText={t('TableColumn_limit_minimal_after')} />
                            </div>
                        }
                        {!!(cellData.limit.max) &&
                            <div className='TableColumn-column-container-limits-limit max'>
                                <div className='TableColumn-column-container-limits-limit-first first'>
                                    <Image source={limitUp} description={t('TableColumn_up_arrow')} />
                                </div>
                                <div className='TableColumn-column-container-limits-limit-last last'>
                                    {cellData.limit.max} {cellData.limit.unit ? cellData.limit.unit : ''}
                                </div>
                                <Help
                                    accent={t('TableColumn_limit_maximal_before')}
                                    afterText={t('TableColumn_limit_maximal_after')} />
                            </div>
                        }
                    </div>
                }
                {typeof cellData.extreme !== 'undefined' &&
                    <div className='TableColumn-column-container-extremes'>
                        {cellData.extreme.min !== null && typeof cellData.extreme.min !== 'undefined' &&
                            <div className='TableColumn-column-container-extremes-extreme min'>
                                {!(cellData.extreme.max !== null && typeof cellData.extreme.max !== 'undefined') &&
                                    <div className='TableColumn-column-container-extremes-extreme-first first'>
                                        <Image source={limitDown} description={t('TableColumn_down_arrow')}/>
                                    </div>
                                }
                                <div className='TableColumn-column-container-extremes-extreme-last last'>
                                    {cellData.extreme.min} {cellData.extreme.unit ? cellData.extreme.unit : ''}
                                </div>
                                <Help
                                    accent={t('TableColumn_value_minimal_before')}
                                    afterText={t('TableColumn_value_after')} />
                             </div>
                        }
                        {cellData.extreme.max !== null && typeof cellData.extreme.max !== 'undefined' &&
                            <div className='TableColumn-column-container-extremes-extreme max'>
                                {!(cellData.extreme.min !== null && typeof cellData.extreme.min !== 'undefined') &&
                                    <div className='TableColumn-column-container-extremes-extreme-first first'>
                                        <Image source={limitUp} description={t('TableColumn_up_arrow')}/>
                                    </div>
                                }
                                <div className='TableColumn-column-container-extremes-extreme-last last'>
                                    {cellData.extreme.max} {cellData.extreme.unit ? cellData.extreme.unit : ''}
                                </div>
                                <Help
                                    accent={t('TableColumn_value_maximal_before')}
                                    afterText={t('TableColumn_value_after')} />
                            </div>
                        }
                    </div>
                }
                {typeof cellData.date !== 'undefined' &&
                    <span>{ValueUtil.getStringFromValue(t, cellData.date, undefined, true)}</span>
                }
                {typeof cellData.dateTime !== 'undefined' &&
                    <div className='TableColumn-column-container-dateTime'>
                        <div className='TableColumn-column-container-dateTime-time'>
                            {ValueUtil.getStringFromValue(t, cellData.dateTime, undefined, undefined, true)}
                        </div>
                        <div className='TableColumn-column-container-dateTime-date'>
                            {ValueUtil.getStringFromValue(t, cellData.dateTime, undefined, true)}
                        </div>
                    </div>
                }
                {typeof cellData.colorText !== 'undefined' &&
                    <span className={`TableColumn-column-container-colorText ${cellData.colorText.color}`}>
                        {ValueUtil.getStringFromValue(t, cellData.colorText.text)}
                    </span>
                }
                {typeof cellData.dot !== 'undefined' &&
                    <div className={`TableColumn-column-container-dot`}>
                        <Dot highlight={cellData.dot.highlight} type={cellData.dot.type} />
                        {cellData.dot.stateName &&
                            <Help
                                 accent={cellData.dot.stateName}
                                 preText={t('TableColumn_state') + ': '} />
                        }
                    </div>
                }
                {typeof cellData.select !== 'undefined' &&
                    <CustomSelect
                        withoutValidations={true}
                        inputState={null}
                        placeholder={cellData.select.placeholder}
                        inputName={cellData.select.name}
                        defaultValue={cellData.select.default ? cellData.select.default : null}
                        onChange={cellData.select.callback}
                        clearable={cellData.select.clearable}
                        values={cellData.select.options} />
                }
                {cellData.sensors &&
                    <div className="TableColumn-column-container-images">
                        {cellData.sensors.map((image: TableCellDataSensor, indexImage: number) => {
                            return (
                                <div key={`column-image-${indexImage}`} className={`TableColumn-column-container-images-item`}>
                                    <div className={`TableColumn-column-container-images-item-image`}>
                                        <Image source={image.icon} description={image.name}/>
                                    </div>
                                    <Help accent={image.name} afterText={t('TableColumn_sensor_available').toLowerCase()} />
                                </div>

                            )
                        })}
                    </div>
                }
                {cellData.actions &&
                <div className="TableColumn-column-container-actions">
                    {cellData.actions.map((action: TableCellDataAction, indexAction: number) => {
                        return (
                            <div
                                onClick={(e) => { void showConfirmDialog(action.trigger, e, action, dispatch); }}
                                key={`column-action-${indexAction}`}
                                className={`TableColumn-column-container-actions-item ${action.type} ${action.isReActivateIcon ? 'reActivate' : ''}`}>
                                <Image size={{width: 20, height: 20}} source={action.image} description={action.title}/>
                                <Help preText={t('TableColumn_click_to_do_sth')} accent={action.title.toLowerCase()} />
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        </td>
    )
}

export default TableColumn;
