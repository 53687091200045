import React, {FormEvent, useEffect} from "react";
import "./AssignZone.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {DataParser} from "../../../models/utils/DataParser";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {CustomSelect} from "../../atoms/CustomSelect/CustomSelect";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {PatientDto} from "../../../models/entities/PatientDto";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {T, useTranslate} from "@tolgee/react";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {actionGetZoneList} from "../../../store/actions/data/zoneAction";
import {assignZoneOrganismSlice} from "../../../store/slices/organisms/assignZoneSlice";
import {actionBanZoneForPatient} from "../../../store/actions/data/patientAction";

type AssignZoneProps = {
    patient: PatientDto,
    dispatchHideModal: ActionCreatorWithoutPayload,
    bannedZonesList: ZoneDto[]
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - hide modal Redux callback
 * @param {PatientDto} patient - patient for hardware assign
 * @param {ZoneDto[]} bannedZonesList - list of banned zones
 */
export const AssignZone = ({dispatchHideModal, patient, bannedZonesList} : AssignZoneProps): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const {t, isLoading} = useTranslate();

    // Data state
    const zoneListState = useAppSelector((state) => state.zoneData.zoneList);

    // Form state
    const zoneState = useAppSelector((state) => selectInputState(state.assignZoneOrganism, 'zone'));
    const formValidState = useAppSelector((state) => selectValidState(state.assignZoneOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.assignZoneOrganism));

    // Re-load data && Destroy
    useEffect(() => { if (!zoneListState) { dispatch(actionGetZoneList()); } }, [dispatch, zoneListState])
    useEffect(() => { return () => { dispatch(assignZoneOrganismSlice.actions.clearForm({t: t})); } }, [dispatch, t])

    // Submit form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        if (!zoneState || !zoneState.value) {
            dispatch(assignZoneOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const selectedZone = zoneState && zoneState.value && typeof zoneState.value === 'number'
            ? DataParser.getZoneFromList(zoneListState,  zoneState.value)
            : null;

        if (selectedZone === null || typeof selectedZone === 'undefined') {
            dispatch(assignZoneOrganismSlice.actions.setFormError({
                error: t('AssignZone_could_not_find_zone')
            }));
            return;
        }

        dispatch(dispatchHideModal());

        const result = await dispatch(actionBanZoneForPatient({
            patientId: patient.id,
            t: t,
            zoneId: selectedZone.id
        }));

        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(mainSlice.actions.setTopLevelErrorMessage({message: result.payload}));
            return;
        }


        dispatch(mainSlice.actions.setTopLevelSuccessMessage({
            message: t("AssignZone_ban_zone_finished", {
                zoneName: selectedZone.name,
                patientName: `${patient.firstName} ${patient.lastName}`
            })
        }));
    }

    // Prepare data for usage
    const parsedZoneList = zoneListState
        ? DataParser.getZoneSelectFromList(zoneListState.filter((item: ZoneDto) => {
            return item.active && bannedZonesList.findIndex((zone: ZoneDto) => { return zone.id === item.id; }) === -1;
        }))
        : zoneListState;

    // Loading hardware
    if (typeof parsedZoneList === 'undefined' || isLoading) {
        return (
            <Loading
                type={LoadingTypeEnum.MODAL}
                title={t('AssignZone_title')}
                modal={{dispatchHide: dispatchHideModal}}/>
        )
    }

    // Error state
    if (parsedZoneList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.MODAL}
                modal={ dispatchHideModal ? {
                    name: t('AssignZone_title'),
                    dispatchHide: dispatchHideModal
                } : undefined}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_zone_list')})} />
        )
    }

    const selectedZone = zoneState && zoneState.value && typeof zoneState.value === 'number'
        ? DataParser.getZoneFromList(zoneListState,  zoneState.value)
        : null;

    const form = (
        <form onSubmit={handleSubmit.bind(this)}>
            <div className="AssignZone-content-form-inputs">
                <div>
                    <CustomSelect
                        required={true}
                        values={parsedZoneList}
                        inputState={zoneState}
                        dispatchOnFocus={assignZoneOrganismSlice.actions.setFocus}
                        dispatchOnBlur={assignZoneOrganismSlice.actions.removeFocus}
                        dispatchOnChange={assignZoneOrganismSlice.actions.updateInput}
                        defaultValue={selectedZone ? selectedZone.id : null}
                        placeholder={t('AssignZone_zone_label')}
                        inputName="zone"/>
                </div>
                {selectedZone &&
                    <span className="AssignZone-content-form-inputs-selected">
                        <T keyName={'AssignZone_selected_zone_text'}
                           params={{b: <b/>, zoneName: selectedZone.name}} />
                    </span>
                }
                {formErrorState && <span className="formError">{formErrorState}</span>}
            </div>
            <div className="AssignZone-content-form-inputs-button">
                <Button
                    text={t('AssignZone_button')}
                    type={ButtonType.PRIMARY}
                    disabled={!formValidState}
                    onClickDisabled={() => { dispatch(assignZoneOrganismSlice.actions.showInputValidStates()); }}
                    isSubmit={true} />
            </div>
        </form>
    )

    return (
        <div className="AssignZone">
            <div className="AssignZone-content">
                <div className="AssignZone-content-title">
                    <div className="AssignZone-content-title-text">
                        <T keyName={'AssignHardware_button'} />
                    </div>
                    <div className="AssignZone-content-title-close">
                        {dispatchHideModal &&
                            <Image onClick={() => dispatch(dispatchHideModal())}
                                   source={close}
                                   description={t("Base_close_modal")}
                                   size={{width: 20, height: 20}}/>
                        }
                    </div>
                </div>
                <div className="AssignZone-content-form">
                    {form}
                </div>
            </div>
        </div>
    );
}

export default AssignZone;
