import {SensorTypeDto} from "../entities/SensorTypeDto";
import {TableCellDataSensor} from "../../components/atoms/Table/TableColumn";
import {DataParser} from "./DataParser";
import {SensorTypeEnum} from "../entities/Enums/SensorTypeEnum";
import {SensorResponseDto} from "../entities/Responses/SensorResponseDto";
import sosIcon from "../../images/sensors/SOS.svg";
import fallIcon from "../../images/sensors/Fall.svg";
import batteryIcon from "../../images/sensors/Battery.svg";
import buttonIcon from "../../images/sensors/Button.svg";
import contactIcon from "../../images/sensors/Contact.svg";
import bloodPressureIcon from "../../images/sensors/BloodPressure.svg";
import HRVIcon from "../../images/sensors/HRV.svg";
import heartbeatIcon from "../../images/sensors/Heartbeat.svg";
import humidityIcon from "../../images/sensors/Humidity.svg";
import hardwareWhite from "../../images/hardwareWhite.svg";
import sleepIcon from "../../images/sensors/Sleep.svg";
import zoneIcon from "../../images/sensors/Zone.svg";
import spo2Icon from "../../images/sensors/SPO.svg";
import stepsIcon from "../../images/sensors/Steps.svg";
import stressIcon from "../../images/sensors/Stress.svg";
import temperatureIcon from "../../images/sensors/Temperature.svg";
import connectionIcon from "../../images/sensors/Connection.svg";
import {ZoneDto} from "../entities/ZoneDto";

/**
 * @class
 * @category Utils
 */
export class SensorUtil {

    /**
     * Get ago string value for date
     * @param dateTimeDiff - milliseconds ago
     * @param {any} t - translator object
     * @param {boolean} short - short format
     * @function
     * @category Utils
     */
    private static getTimeFormatAgoForDate(dateTimeDiff: number, t: any, short: boolean) : string|null {

        const secondsDifference = dateTimeDiff/1000;
        if (secondsDifference < 0) { return null; }

        if (secondsDifference >= 86400) {
            const days = Math.floor(secondsDifference/86400);
            return t(short ? 'SensorUtil_days_ago_short' : 'SensorUtil_days_ago', {days: days});
        }

        if (secondsDifference >= 3600) {
            const hours = Math.floor(secondsDifference/3600);
            return t(short ? 'SensorUtil_hours_ago_short' : 'SensorUtil_hours_ago', {hours: hours});
        }

        if (secondsDifference >= 60) {
            const minutes = Math.floor(secondsDifference/60);
            return t(short ? 'SensorUtil_minutes_ago_short' : 'SensorUtil_minutes_ago', {minutes: minutes});
        }

        if (secondsDifference >= 1) {
            const seconds = Math.floor(secondsDifference);
            return t(short ? 'SensorUtil_seconds_ago_short' : 'SensorUtil_seconds_ago', {seconds: seconds});
        }

        return t('SensorUtil_now').toLowerCase();
    }

    /**
     * Get ago string value for date (when N/A use fallback string)
     * @param dateTimeDiff - milliseconds ago
     * @param {any} t - translator object
     * @param {boolean} short - short format
     * @param hideMeasuredText - don't show text appended
     */
    public static getTimeFormatAgoForDateWithFallBackText(dateTimeDiff: number|null, t: any, short: boolean, hideMeasuredText?: boolean) : string {


        const measuredText = hideMeasuredText ? '' : t('Base_measured');
        const notMeasuredText = hideMeasuredText ? '' : t('Base_not_measured')
        if (!dateTimeDiff) { return notMeasuredText; }

        if (short) {
            const duration = this.getTimeFormatAgoForDate(dateTimeDiff, t, true);
            return duration ? duration : notMeasuredText;

        } else {
            const duration = this.getTimeFormatAgoForDate(dateTimeDiff, t, false)
            return duration ? measuredText + ' ' + duration : notMeasuredText;
        }
    }

    /**
     * Get value of sensor rounded to step
     * @param {number} rangeStep - step value
     * @param {number} value - value of sensor
     * @function
     * @category Utils
     */
    public static getNumberValueFormatFromStep(rangeStep: number, value: number) : string {

        if (rangeStep < 1.0) { return value.toFixed(1);   }
        return value.toFixed(0);
    }

    /**
     * Get info about sensor type
     * @param {number|null} sensorTypeId - sensor type
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static getMetaForSensorType(sensorTypeId: number|null, t: any) : {name: string, icon: any} {

        if (!sensorTypeId) { return {name: t('Base_name_sensor'), icon: hardwareWhite}; }

        switch (sensorTypeId) {
            case SensorTypeEnum.SOS: return {name: t('SensorUtil_type_sos'), icon: sosIcon};
            case SensorTypeEnum.FALL_DETECTION: return {name: t('SensorUtil_type_fall_detection'), icon: fallIcon};
            case SensorTypeEnum.BATTERY: return {name: t('SensorUtil_type_battery'), icon: batteryIcon};
            case SensorTypeEnum.BUTTON: return {name: t('SensorUtil_type_button'), icon: buttonIcon};
            case SensorTypeEnum.CONTACT: return {name: t('SensorUtil_type_contact'), icon: contactIcon};
            case SensorTypeEnum.BLOOD_PRESSURE: return {name: t('SensorUtil_type_blood_pressure'), icon:bloodPressureIcon};
            case SensorTypeEnum.HEART_RATE_VARIABILITY: return {name: t('SensorUtil_type_hrv'), icon: HRVIcon};
            case SensorTypeEnum.HEARTBEAT: return {name: t('SensorUtil_type_heartbeat'), icon: heartbeatIcon};
            case SensorTypeEnum.HUMIDITY: return {name: t('SensorUtil_type_humidity'), icon: humidityIcon};
            case SensorTypeEnum.OCCUPANCY: return {name: t('SensorUtil_type_occupancy'), icon: hardwareWhite};
            case SensorTypeEnum.SLEEP: return {name: t('SensorUtil_type_sleep'), icon: sleepIcon};
            case SensorTypeEnum.SPO2: return {name: t('SensorUtil_type_spo2'), icon: spo2Icon};
            case SensorTypeEnum.STEPS: return {name: t('SensorUtil_type_steps'), icon: stepsIcon};
            case SensorTypeEnum.STRESS: return {name: t('SensorUtil_type_stress'), icon: stressIcon};
            case SensorTypeEnum.TAMPER: return {name: t('SensorUtil_type_tamper'), icon: hardwareWhite};
            case SensorTypeEnum.TEMPERATURE: return {name: t('SensorUtil_type_temperature'), icon: temperatureIcon};
            case SensorTypeEnum.CONNECTION: return {name: t('SensorUtil_type_connection'), icon: connectionIcon};
            case SensorTypeEnum.ZONE: return {name: t('SensorUtil_type_zone'), icon: zoneIcon};
            default: return {name: t('Base_name_sensor'), icon: hardwareWhite};
        }
    }

    /**
     * Get images list for sensor type numbers
     * @param {number[]|null} sensorTypesNumbers - list of sensor type numbers
     * @param {SensorTypeDto[]} sensorTypes - list of all sensor types
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static getImagesFromSensorTypesNumbers(sensorTypesNumbers: Array<number>|null, sensorTypes: Array<SensorTypeDto>, t: any) : Array<TableCellDataSensor> {

        if (!sensorTypesNumbers) return [];
        const sensorTypesParsed : Array<SensorTypeDto> = sensorTypesNumbers.map((sensorTypeNumber: number) => {
            const sensorType = DataParser.getSensorTypeFromList(sensorTypes, sensorTypeNumber);
            return sensorType ? sensorType : null;
        }).filter((sensorType : SensorTypeDto|null) => {
            return sensorType !== null;
        }) as Array<SensorTypeDto>;

        return sensorTypesParsed.sort((a: SensorTypeDto, b: SensorTypeDto) => {
            return a.name.localeCompare(b.name);
        }).map((sensorType: SensorTypeDto) => {
            return {
                name: SensorUtil.getMetaForSensorType(sensorType.id, t).name,
                icon: SensorUtil.getMetaForSensorType(sensorType.id, t).icon
            }
        });
    }

    /**
     * Get number of digits after floating point
     * @param {number} number - input number
     * @function
     * @category Utils
     */
    private static getNumberOfDigitsAfterDecimal(number: number) : number {

        if (Number.isInteger(number)) { return 0; }
        return number.toString().split('.')[1].length;
    }

    /**
     * Convert sensor value to string value using some rules
     * @param {SensorResponseDto} sensor - sensor object
     * @param {ZoneDto[]} zoneList - list of zones
     * @param {any} t - translator object
     * @function
     * @category Utils
     */
    public static getValueInterpretationForSensorType(sensor: SensorResponseDto, zoneList: ZoneDto[], t: any) : string {
        return this.getValueInterpretationForSensor(sensor.Sensor.lastValue, null, sensor.SensorTemplate.rangeStep, sensor.SensorTemplate.type, zoneList, t);
    }

    /**
     * Get value text for sensor
     * - We have different resolution for zones in APP and notifications
     * 1) App use zone list to resolve the name
     * 2) Notification use direct valueString
     * @param {number|null} lastValue - current value
     * @param {string|null}lastValueString - string value
     * @param {number|null} rangeStep - precision
     * @param {SensorTypeEnum} sensorType - sensor type info
     * @param {any} t - translator
     * @param {ZoneDto[]|null} zoneList - list of zones
     */
    public static getValueInterpretationForSensor(lastValue: number|null, lastValueString: string|null, rangeStep: number|null, sensorType: SensorTypeEnum, zoneList: ZoneDto[]|null, t: any) : string {

        if (lastValue === null) { return '-'; }

        if (sensorType === SensorTypeEnum.SOS) {
            return lastValue && lastValue === 1 ? t('SensorUtil_sos') : t('SensorUtil_no_sos');
        } else if (sensorType === SensorTypeEnum.FALL_DETECTION) {
            return lastValue && lastValue === 1 ? t('SensorUtil_fall') : t('SensorUtil_no_fall');
        } else if (sensorType === SensorTypeEnum.CONNECTION) {
            return lastValue && lastValue === 1 ? t('SensorUtil_connected') : t('SensorUtil_notConnected');
        } else if (sensorType === SensorTypeEnum.BUTTON) {
            return lastValue && lastValue === 1 ? t('SensorUtil_pressed') : t('SensorUtil_notPressed');
        } else if (sensorType === SensorTypeEnum.ZONE && zoneList) {
            return lastValue
                ? (DataParser.getZoneFromListById(zoneList, lastValue)?.name ?? t('SensorUtil_zone_na'))
                : t('SensorUtil_no_zone');
        } else if (sensorType === SensorTypeEnum.ZONE && !zoneList) {
            return lastValueString ?? t('SensorUtil_no_zone');
        }

        const digits = rangeStep ? SensorUtil.getNumberOfDigitsAfterDecimal(rangeStep) : 1;
        return lastValue.toFixed(digits);
    }

    public static showTrendOrMinMaxBySensorType(sensorType: SensorTypeEnum) : boolean {

        switch (sensorType) {
            case SensorTypeEnum.BUTTON: return false;
            case SensorTypeEnum.CONNECTION: return false;
            case SensorTypeEnum.FALL_DETECTION: return false;
            case SensorTypeEnum.SOS: return false;
            case SensorTypeEnum.ZONE: return false;
            default: return true;
        }
    }
}
