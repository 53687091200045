import {createSlice} from '@reduxjs/toolkit'
import {
    createHardwareFormInitialState,
    CreateHardwareFormState,
    getCreateHardwareReducers
} from "../../../models/utils/AbstractCreateHardwareReducers";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {CreateHardwareFormState}
 */
interface HardwareCreateDialogManualState extends CreateHardwareFormState {}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const initialState: HardwareCreateDialogManualState = {...createHardwareFormInitialState, ...{}}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwareCreateDialogManualOrganismSlice = createSlice({
    name: 'hardwareCreateDialogManualOrganism',
    initialState,
    reducers: {...getCreateHardwareReducers(), ...{}},
})

export default hardwareCreateDialogManualOrganismSlice.reducer