/**
 * @enum {string}
 * @category Entities
 * @subcategory Enums
 */
export enum SensorDataChartTypeEnum {

    SCATTER = 'SCATTER',
    LINE = 'LINE',
    NONE = 'NONE',
    XRANGE = 'XRANGE',
}
