import {createSlice} from '@reduxjs/toolkit'
import {abstractFilterInitialState, AbstractFilterState, getAbstractFilterReducers} from '../../../models/utils/AbstractFilterReducers';

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFilterState}
 */
interface FilterFloorState extends AbstractFilterState {}

const initialState: FilterFloorState = abstractFilterInitialState;

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const filterFloorOrganismSlice = createSlice({
    name: 'filterFloorOrganism',
    initialState,
    reducers: {...getAbstractFilterReducers(), ...{}},
})

export default filterFloorOrganismSlice.reducer