import React, {FormEvent, useEffect} from "react";
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import "./RecoveryBlock.scss"
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {WelcomePageViewType} from "../../../store/slices/pages/welcomePageSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {recoveryBlockOrganismSlice} from "../../../store/slices/organisms/recoveryBlockSlice";
import {actionRecoveryPassword} from "../../../store/actions/organisms/recoveryBlockAction";
import {T, useTranslate} from "@tolgee/react";
import {PasswordResetRequestDto} from "../../../models/entities/Requests/PasswordResetRequestDto";

type RecoveryBlockProps = {
    dispatchChangeView: ActionCreatorWithPayload<{viewType: WelcomePageViewType}>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithPayload<{viewType: WelcomePageViewType}>} dispatchChangeView - dispatch change view type
 */
export const RecoveryBlock = ({dispatchChangeView}: RecoveryBlockProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.recoveryBlockOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.recoveryBlockOrganism));
    const email = useAppSelector((state) => selectInputState(state.recoveryBlockOrganism, 'email'));

    // Cleanup
    useEffect(() => {
        return () => { dispatch(recoveryBlockOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit login
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!email || !email.value) {
            dispatch(recoveryBlockOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        const request : PasswordResetRequestDto = {
            email: email.value.toString(),
        };

        const result = await dispatch(actionRecoveryPassword({request: request, t: t}));
        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
            dispatch(recoveryBlockOrganismSlice.actions.setFormError({error: result.payload}));
            return;
        }

        dispatch(dispatchChangeView({viewType: WelcomePageViewType.LOGIN}));
    }

    return (
       <div className="RecoveryBlock">
           <div className="RecoveryBlock-header">
               <T keyName={'RecoveryBlock_title'} />
           </div>
           <div className="RecoveryBlock-description">
               <T keyName={'RecoveryBlock_subtitle'} />
           </div>
           <div className="RecoveryBlock-form">
               <form autoComplete="off" onSubmit={handleSubmit.bind(this)}>
                   <div className="RecoveryBlock-form-inputs">
                       <InputText
                           inputState={null}
                           inputType={InputTextType.AUTOFILL_FIX}
                           inputName={Math.random().toString()}
                           placeholder={Math.random().toString()} />
                       <InputText
                           placeholder={t('RecoveryBlock_email_placeholder')}
                           inputName="email"
                           label={t('RecoveryBlock_email_label')}
                           inputState={email}
                           value={email?.value}
                           inputType={InputTextType.TEXT}
                           dispatchOnChange={recoveryBlockOrganismSlice.actions.updateInput}
                           dispatchOnFocus={recoveryBlockOrganismSlice.actions.setFocus}
                           dispatchOnBlur={recoveryBlockOrganismSlice.actions.removeFocus} />
                       {formErrorState && <span className="formError">{formErrorState}</span>}
                   </div>
                   <div className="RecoveryBlock-form-button">
                       <Button
                           options={{textCentered: true, positionCentered: true}}
                           text={t('RecoveryBlock_button')}
                           type={ButtonType.PRIMARY}
                           onClickDisabled={() => { dispatch(recoveryBlockOrganismSlice.actions.showInputValidStates()); }}
                           disabled={!formValidState}
                           isSubmit={true} />
                   </div>
               </form>
           </div>
           <div className="RecoveryBlock-logIn" onClick={() => dispatch(dispatchChangeView({viewType: WelcomePageViewType.LOGIN}))}>
               <T keyName={'RecoveryBlock_logIn'} />
           </div>
       </div>
    );
}

export default RecoveryBlock;
