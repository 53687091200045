import {Communicator, RequestType} from "../utils/Communicator";
import {PhoneResponseDto} from "../entities/Responses/PhoneResponseDto";
import {CacheExpiry} from "../utils/Storage";
import {PhoneRequestDto} from "../entities/Requests/PhoneRequestDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class PhoneRepository {

    /**
     * Get list of help phone numbers
     */
    public static async getPhoneList() : Promise<Array<PhoneResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `phone`,
            cache: { cacheKey: "getPhoneList", expiry: CacheExpiry.FIVE_MINUTES }
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Create new phone
     * @param {PhoneRequestDto} request
     * @param {TranslatorType} t - translator object
     */
    public static async createPhone(request: PhoneRequestDto, t: TranslatorType) : Promise<PhoneResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `phone`,
            body: request,
            cache: { deleteKeys: ['getPhoneList'] }
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PhoneRepository_createPhone'))
    }


    /**
     * Create new phone
     * @param {number} number
     * @param {TranslatorType} t - translator object
     */
    public static async deletePhone(number: number, t: TranslatorType) : Promise<PhoneResponseDto> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `phone/${number}`,
            cache: { deleteKeys: ['getPhoneList'] }
        });

        if (response.ok) { return response.data; }
        throw new Error(t('PhoneRepository_deletePhone'))
    }
}
