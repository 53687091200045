import React, {useEffect} from "react";
import "./ListZones.scss"
import {TableRows} from "../../atoms/Table/Table";
import remove from "../../../images/delete.svg";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {FunctionTable, OrderOptions} from "../../molecules/FunctionTable/FunctionTable";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Loading, LoadingTypeEnum} from "../../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum} from "../../extras/ErrorComponent/ErrorComponent";
import {EasyFilter, EasyFilterColor} from "../../molecules/EasyFilter/EasyFilter";
import {TableCellData, TableCellDataAction, TableCellDataActionTypeEnum} from "../../atoms/Table/TableColumn";
import {useTranslate} from "@tolgee/react";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {
    actionBanZoneInTemplate,
    actionDeleteZone,
    actionGetZoneList, actionUnbanZoneInTemplate,
    actionUndeleteZone
} from "../../../store/actions/data/zoneAction";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {ListZonesFilterTypes} from "../../../store/slices/pages/zonesPageSlice";
import {DotType} from "../../atoms/Dot/Dot";
import peopleIcon from "../../../images/person.svg";
import edit from "../../../images/edit.svg";
import activate from "../../../images/activateZone.svg";
import restrict from "../../../images/restrict.svg";
import allow from "../../../images/allow.svg";
import {TableDetailLinkType} from "../../atoms/Table/TableDetail";

/**
 * @alias ListZonesPagination
 * @category Components
 */
type ListZonesPagination = {

    /** Show count **/
    showCount: number,
    /** Current page **/
    page: number,
    /** Current oder **/
    order: OrderOptions|null,
    /** Redux callback to change order **/
    dispatchChangeOrder: ActionCreatorWithPayload<OrderOptions>
    /** Redux callback to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
}

/**
 * @alias ListZonesFilter
 * @category Components
 */
type ListZonesFilter = {

    /** Current filter type **/
    filterType: ListZonesFilterTypes,
    /** Redux callback to filter all items **/
    dispatchFilterAll: ActionCreatorWithoutPayload,
    /** Redux action to filter by active **/
    dispatchFilterActive: ActionCreatorWithoutPayload,
    /** Current filter title **/
    filterTitle: string|null,
    /** Full text value to search **/
    fullTextValue: string|null,
}

type ListZonesProps = {
    pagination: ListZonesPagination,
    filter: ListZonesFilter,
    dispatchShowAddOrEditZoneModal: ActionCreatorWithPayload<{ zoneId: number|null }>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ListZonesPagination} pagination - pagination options
 * @param {ListZonesFilter} filter - filter options
 * @param {RoleEnum} role - logged user role
 * @param {ActionCreatorWithPayload<{ zoneId: number|null }>} dispatchShowAddOrEditZoneModal - dispatch to show edit zone modal
 */
export const ListZones = ({pagination, filter, dispatchShowAddOrEditZoneModal} : ListZonesProps): React.JSX.Element => {

    const {t, isLoading} = useTranslate();
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);
    const dispatch = useAppDispatch();

    // Init
    useEffect(() => { if (!zoneList) { dispatch(actionGetZoneList()); } }, [dispatch, zoneList])

    // Filter zone list using filter states
    const filterZoneList = (zones: Array<ZoneDto>) : Array<ZoneDto> => {

        return zones.filter((item: ZoneDto) => {

            if (filter.filterType === ListZonesFilterTypes.ALL) { return true; }
            else if (filter.filterType === ListZonesFilterTypes.ACTIVE) { return item.active; }
            else if (filter.filterType === ListZonesFilterTypes.FULLTEXT && filter.fullTextValue !== null) {
                return (item.name.toLowerCase().includes(filter.fullTextValue));
            }
            return false;
        });
    }

    const parseZonesList = (zones: Array<ZoneDto>) : TableRows => {

        return zones.map((zone : ZoneDto) => {

            const actions : Array<TableCellDataAction> = [];

            // Zone ban template
            if (zone.zoneBanTemplate !== null) {
                actions.push({
                    confirm: {
                        title: t('ListZones_unban_question'),
                        successTitle: t('ListZones_unban_success', {zoneName: zone.name})},
                    type: TableCellDataActionTypeEnum.SECONDARY,
                    linkType: TableDetailLinkType.PRIMARY,
                    title: t('ListZones_unban_title'),
                    image: allow,
                    trigger: async () => {
                        const result = await dispatch(actionUnbanZoneInTemplate({zoneId: zone.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            } else {
                actions.push({
                    confirm: {
                        title: t('ListZones_ban_question'),
                        successTitle: t('ListZones_ban_success', {zoneName: zone.name})},
                    type: TableCellDataActionTypeEnum.TERNARY,
                    linkType: TableDetailLinkType.PRIMARY,
                    title: t('ListZones_ban_title'),
                    image: restrict,
                    trigger: async () => {
                        const result = await dispatch(actionBanZoneInTemplate({zoneId: zone.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            }

            // Add/Edit
            actions.push({
                type: TableCellDataActionTypeEnum.PRIMARY,
                title: t('Base_goto_editDetails'),
                linkType: TableDetailLinkType.PRIMARY,
                image: edit,
                trigger: async () => { dispatch(dispatchShowAddOrEditZoneModal({zoneId: zone.id})); }
            });

            // Delete
            if (zone.active) {
                actions.push({
                    confirm: {
                        title: t('ListZones_delete_confirm'),
                        successTitle: t('ListZones_delete_success', {zoneName: zone.id})
                    },
                    linkType: TableDetailLinkType.DELETE,
                    title: t('ListZones_delete_title'),
                    image: remove,
                    type: TableCellDataActionTypeEnum.PRIMARY,
                    trigger: async () => {
                        const result = await dispatch(actionDeleteZone({zoneId: zone.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            } else {
                actions.push({
                    confirm: {
                        title: t('ListZones_activate_question'),
                        successTitle: t('ListZones_activate_success', {zoneName: zone.name})},
                    type: TableCellDataActionTypeEnum.SECONDARY,
                    linkType: TableDetailLinkType.SECONDARY,
                    isReActivateIcon: true,
                    title: t('ListZones_activate_title'),
                    image: activate,
                    trigger: async () => {
                        const result = await dispatch(actionUndeleteZone({zoneId: zone.id, t: t}));
                        if (result.meta.requestStatus === "rejected" && typeof result.payload === "string") {
                            throw new Error(result.payload);
                        }
                    }
                });
            }

            let columns : Array<TableCellData> = [
                {dot: {
                    type: zone.active ? DotType.SUCCESS : DotType.ERROR,
                    stateName: t(DotType.SUCCESS ? 'Base_state_active' : 'Base_state_inactive')
                }},
                {boolean: {
                    flag: zone.zoneBanTemplate === null,
                    text: t(zone.zoneBanTemplate !== null ? 'ListZones_template_banned_yes' : 'ListZones_template_banned_no')}
                },
                {text: zone.name},
                {actions: actions}
            ];

            return {
                link: {link: "/zone/" + zone.id, title: t('ListZones_gotoZonesDetail')},
                rowTitle: zone.name,
                columns: columns
            }
        });
    }

    // Loading state
    if (typeof zoneList === "undefined" || isLoading) {
        return ( <Loading type={LoadingTypeEnum.CARD} /> )
    }

    // Error state zone
    if (zoneList === null) {
        return (
            <ErrorComponent
                type={ErrorComponentTypeEnum.CARD}
                title={t("Base_errors_component_load_title")}
                subTitle={t("Base_errors_component_load_description",
                    {dataName: t('Base_name_zone_list')})} />
        )
    }

    // Prepare table output data
    const parsedZonesList = parseZonesList(filterZoneList(zoneList));

    return (
        <div className={'ListZones'}>
            <div className="ListZones-functions">
                <div className="ListZones-functions-left">
                    <EasyFilter items={[
                        {title: t('ListZones_filter_active'),
                            active: filter.filterType === ListZonesFilterTypes.ACTIVE,
                            dispatchFilterChange: filter.dispatchFilterActive
                        },
                        {title: t('ListZones_all'),
                            color: EasyFilterColor.DEFAULT,
                            active: filter.filterType === ListZonesFilterTypes.ALL,
                            dispatchFilterChange: filter.dispatchFilterAll},
                    ]} />
                </div>
                <div className="ListZones-functions-right">
                    <div className="ListZones-functions-right-item">
                        <div className="ListZones-functions-right-item-button">
                            <Button
                                image={peopleIcon}
                                onClick={() => { dispatch(dispatchShowAddOrEditZoneModal({zoneId: null})); }}
                                text={t('ListZones_button_create')}
                                type={ButtonType.CREATE}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ListZones-table">
                <FunctionTable
                    empty={{
                        title: t('ListZones_empty_title'),
                        subTitle: t('ListZones_empty_subtitle')
                    }}
                    fePagination={{
                        size: pagination.showCount,
                        page: pagination.page,
                        dispatchChangePage: pagination.dispatchChangePage,
                        dispatchChangeOrder: pagination.dispatchChangeOrder,
                        order: pagination.order,
                    }}
                    data={{rows: parsedZonesList, columns:  [
                            {name: t('ListZones_col_state'), state: true},
                            {name: t('ListZones_col_banTemplate')},
                            {name: t('ListZones_col_name')},
                            {name: t('ListZones_col_actions'), action: true}]
                    }} />
            </div>
        </div>
    );
}

export default ListZones;
