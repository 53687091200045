import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {Storage} from "../../models/utils/Storage";
import {ToastContainer} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {mainSlice} from "../../store/slices/extra/mainSlice";
import {Helmet} from "react-helmet-async";

export const RouterHandler = (): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const session = Storage.getSession();
    console.log(session);
    const logged = session !== null;
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const navigationLaptopHiddenState = useAppSelector((state) => state.main.navigationLaptopHidden);
    const navigationMobileShownState = useAppSelector((state) => state.main.navigationMobileShown);
    const profileMobileShownState = useAppSelector((state) => state.main.profileMobileShown);
    const dialModalShownState = useAppSelector((state) => state.main.dialModalShown);
    const smsModalShownState = useAppSelector((state) => state.main.smsModalShown);

    // Init navigator in main slice
    useEffect(() => {
        dispatch(mainSlice.actions.setNavigateHook({navigateHook: navigate}));
    }, [dispatch, navigate]);

    useEffect(() => {
        //void Storage.refreshSession();
        window.scrollTo(0, 0);
        if (logged && location === '/') { navigate('/dashboard/'); }
        if (!logged && location !== '/') { navigate('/'); }
    });

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        if (navigationLaptopHiddenState) { rootElement.classList.add('laptopNavigationHidden'); }
        else { rootElement.classList.remove('laptopNavigationHidden'); }
    }, [navigationLaptopHiddenState])

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        if (navigationMobileShownState) { rootElement.classList.add('mobileNavigationShown'); }
        else { rootElement.classList.remove('mobileNavigationShown'); }
    }, [navigationMobileShownState])

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        if (profileMobileShownState) { rootElement.classList.add('profileNavigationShown'); }
        else { rootElement.classList.remove('profileNavigationShown'); }
    }, [profileMobileShownState])

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        if (dialModalShownState) { rootElement.classList.add('dialModalShown'); }
        else { rootElement.classList.remove('dialModalShown'); }
    }, [dialModalShownState])

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        if (smsModalShownState) { rootElement.classList.add('smsModalShown'); }
        else { rootElement.classList.remove('smsModalShown'); }
    }, [smsModalShownState])


    return (
        <div className={"RouterHandler"}>
            <ToastContainer limit={3} />
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
        </div>
    );
}
