import "./Button.scss";
import {Image} from "../Image/Image";
import React from "react";
import {ImageType} from "../../../models/entities/Utils/Image";
import loadingIcon from "../../../images/loading.gif";
import buttonCreateIcon from "../../../images/buttonCreate.svg";
import {useTranslate} from "@tolgee/react";

/**
 * @enum {string}
 * @category Components
 */
export enum ButtonType {
    PRIMARY = "primary",
    DELETE = "delete",
    CANCEL = "cancel",
    SECONDARY = "secondary",
    TERNARY = "ternary",
    IMAGE_EYE = "imageEye",
    CREATE = "create",
    FLAT_DELETE = "flatDelete",
}

/** 
 * @alias ButtonOptions
 * @category Components
 */
type ButtonOptions = {

    /** Text in button is centered **/
    textCentered?: boolean,
    /** Button is positioned in center **/
    positionCentered?: boolean,
}

type ButtonProps = {
    text: string,
    type?: ButtonType,
    onClick?: EmptyFunc,
    onClickDisabled?: EmptyFunc,
    image?: ImageType,
    disabled?: boolean,
    isSubmit?: boolean,
    options?: ButtonOptions,
    isProgress?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {string} text - button text
 * @param {ButtonType} type - button type for design purposes
 * @param {EmptyFunc|undefined} onClick - on click callback
 * @param {ImageType|undefined} image - button prepended image
 * @param {boolean|undefined} disabled - disabled button
 * @param {boolean|undefined} isSubmit - button type is submit
 * @param {EmptyFunc|undefined} onClickDisabled - do action when disabled - used in forms
 * @param {ButtonOptions|undefined} options - small patch button options
 * @param {boolean|undefined} isProgress - currently busy
 */
export const Button = ({text, type, onClick, image, disabled, isSubmit, onClickDisabled, options, isProgress}: ButtonProps): React.JSX.Element => {

    const {t} = useTranslate();

    // On click basic callback
    const handleClick = (event: React.MouseEvent) => {

        if (disabled || onClick) {
            event.preventDefault(); event.stopPropagation();
        }

        if (disabled) {
            if (onClickDisabled) { onClickDisabled(); }
        }  else if (onClick) { onClick(); }
    }

    // Get main class for styling
    const getButtonMainClass = () => {

        let className = 'Button ' + (type ?? 'primary');
        if (options && options.textCentered) { className += ' Button-options-textCentered'; }
        if (options && options.positionCentered) { className += ' Button-options-positionCentered'; }
        return className;
    }

    return (
        <div className={getButtonMainClass()}>
            <button
                type={isSubmit ? 'submit' : undefined}
                className={`element ${(disabled || isProgress) ? 'disabled' : ''}`}
                onClick={ (event: React.MouseEvent) => {
                    handleClick(event);
                }}>

                {image &&
                    <Image
                        className={'buttonImage'}
                        source={image}
                        description={text}
                    />
                }
                {isProgress &&
                    <Image
                        className={'progress'}
                        source={loadingIcon}
                        description={t('Button_busy')}
                    />
                }

                {type === ButtonType.CREATE &&
                    <Image
                        className={'createImage'}
                        source={buttonCreateIcon}
                        description={text}
                    />
                }

                <span className={'text'}>
                    {text}
                </span>

            </button>
        </div>
    );
}

export default Button;
