import {PayloadAction} from "@reduxjs/toolkit";
import {Validator, ValidatorInput} from "./Validator";
import {ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";

/**
 * @interface
 * @category Utils
 */
export interface FormState {

    /** Current form error **/
    error?: string|null,
    /** If we need more errors **/
    errors?: Array<string>|null,
    /** List of inputs **/
    inputs: Array<InputState>
}

/**
 * @interface
 * @category Utils
 */
export interface InputState {

    /** Input name **/
    name: string,
    /** Input value **/
    value?: string|number|null,
    /** Input validations **/
    validations?: ValidatorInput,
    /** Input is invalid **/
    invalid?: boolean,
    /** Force invalid flag **/
    forcedInvalid?: boolean,
    /** Input errors **/
    errors?: Array<string>,
    /** Input errors shown **/
    focused ?: boolean,
    /** Validations shown **/
    validationShown?: boolean,
    /** Uploaded file name **/
    uploadFileName?: string,
    /** Input is generated **/
    generated?: boolean,
    /** Anything in metadata **/
    metaData?: any
}

/**
 * @interface
 * @category Utils
 */
export interface AbstractFormReducersState {

    /** Form state **/
    form: FormState
}

/**
 * @interface
 * @category Utils
 */
export interface AbstractFormUpdatePayload {

    /** Input name **/
    inputName: string,
    /** Translator object **/
    t: any,
    /** Input value **/
    inputValue: string|number|null,
    /** Input file name **/
    uploadFileName?: string,
    /** Anything in metadata **/
    metaData?: any
}

/**
 * @alias AbstractFormUpdateCallBack
 * @category Utils
 */
export type AbstractFormUpdateCallBack = (inputName: string, inputValue: string | number | null) => void;

/**
 * @alias AbstractFormUpdateCheckBoxCallBack
 * @category Utils
 */
export type AbstractFormUpdateCheckBoxCallBack = (inputName: string, checked: boolean) => void;


/**
 * @function
 * @category Utils
 */
export const getAbstractFormReducers = () => {
    return {
        showInputValidStates: (state: AbstractFormReducersState) => {
            for (let i = 0; i < state.form.inputs.length; i++) {
                state.form.inputs[i].validationShown = true;
            }
        },
        removeAllGeneratedInputs: (state: AbstractFormReducersState) => {
            state.form.inputs = state.form.inputs.filter((input: InputState) => {
                return !input.generated;
            })
        },
        addInputs: (state: AbstractFormReducersState, action: PayloadAction<{inputStates: Array<InputState>, t: any}>) => {

            const updatedInputStates = action.payload.inputStates.map((inputState: InputState) => {
                inputState.generated = true;
                const validations = inputState.validations;
                if (validations) {
                    const validationOutput = Validator.validate(validations, action.payload.t, inputState.value);
                    inputState.invalid = !validationOutput.ok;
                    inputState.errors = validationOutput.errors;
                }
                return inputState;
            });
            state.form.inputs = state.form.inputs.concat(updatedInputStates);
        },
        updateInput: (state: AbstractFormReducersState, action: PayloadAction<AbstractFormUpdatePayload>) : ValidateSliceCaseReducers<any, any>=> {

            let index = state.form.inputs.findIndex((input : InputState) => { return input.name === action.payload.inputName });
            if (index === -1) {
                console.warn(`AbstractFormReducers can't find input: ${action.payload.inputName}`);
                return ;
            }

            state.form.inputs[index].metaData = action.payload.metaData;
            state.form.inputs[index].uploadFileName = action.payload.uploadFileName;
            state.form.inputs[index].value = action.payload.inputValue !== null && typeof action.payload.inputValue !== 'undefined'
                ? action.payload.inputValue : null;
            const validations = state.form.inputs[index].validations;
            if (validations) {
                const validationOutput = Validator.validate(validations, action.payload.t, action.payload.inputValue);
                state.form.inputs[index].invalid = !validationOutput.ok;
                state.form.inputs[index].errors = validationOutput.errors;
            }
        },
        clearForm: (state: AbstractFormReducersState, action: PayloadAction<{t: any}>) => {
            state.form.error = undefined;
            state.form.errors = undefined;
            for (let i = 0; i < state.form.inputs.length; i++) {
                state.form.inputs[i].value = undefined;
                state.form.inputs[i].focused = undefined;
                state.form.inputs[i].validationShown = undefined;
                const validations = state.form.inputs[i].validations;
                if (validations) {
                    const validationOutput = Validator.validate(validations, action.payload.t, null);
                    state.form.inputs[i].invalid = !validationOutput.ok;
                    state.form.inputs[i].errors = validationOutput.errors;
                } else {
                    state.form.inputs[i].invalid = undefined;
                    state.form.inputs[i].errors = undefined;
                }
            }
        },
        setFormError: (state: AbstractFormReducersState, action: PayloadAction<{error: string|null, invalidateInputName?: string }>) => {

            state.form.error = action.payload.error;
            state.form.errors = null;
            if (action.payload.invalidateInputName) {
                for (let i = 0; i < state.form.inputs.length; i++) {
                    state.form.inputs[i].forcedInvalid = state.form.inputs[i].name === action.payload.invalidateInputName;
                }
            }
        },
        setFormErrors: (state: AbstractFormReducersState, action: PayloadAction<{errors: Array<string>|null, invalidateInputName?: string }>) => {

            state.form.errors = action.payload.errors;
            state.form.error = null;
            if (action.payload.invalidateInputName) {
                for (let i = 0; i < state.form.inputs.length; i++) {
                    state.form.inputs[i].forcedInvalid = state.form.inputs[i].name === action.payload.invalidateInputName;
                }
            }
        },
        clearFormErrors: (state: AbstractFormReducersState) => {

            state.form.error = null;
            state.form.errors = null;

            for (let i = 0; i < state.form.inputs.length; i++) {
                state.form.inputs[i].forcedInvalid = false;
            }
        },
        setFocus: (state: AbstractFormReducersState, action: PayloadAction<{inputName: string }>) => {
            let index = state.form.inputs.findIndex((input : InputState) => input.name === action.payload.inputName);
            state.form.inputs[index].focused = true;
        },
        removeFocus: (state: AbstractFormReducersState, action: PayloadAction<{inputName: string }>) => {
            let index = state.form.inputs.findIndex((input : InputState) => input.name === action.payload.inputName);
            state.form.inputs[index].focused = false;
        },
    }
}

/**
 * @function
 * @category Utils
 * @param {AbstractFormReducersState} state - abstract form state
 */
export const selectFormError = (state: AbstractFormReducersState) : string|null|undefined => {
    return state.form.error;
}

/**
 * @function
 * @category Utils
 * @param {AbstractFormReducersState} state - abstract form state
 */
export const selectFormErrors = (state: AbstractFormReducersState) : Array<string>|null|undefined => {
    return state.form.errors;
}


/**
 * @function
 * @category Utils
 * @param {AbstractFormReducersState} state - abstract form state
 * @param {string} inputName - input name
 */
export const selectInputState = (state: AbstractFormReducersState, inputName: string) : InputState|null => {

    let index = state.form.inputs.findIndex(input => input.name === inputName);
    return state.form.inputs[index];
}

/**
 * @function
 * @category Utils
 * @param {AbstractFormReducersState} state - abstract form state
 * @param {string|undefined} filterWithName - filter just input with name
 */
export const selectInputsState = (state: AbstractFormReducersState, filterWithName?: string) : Array<InputState> => {
    return !filterWithName ? state.form.inputs : state.form.inputs.filter((input: InputState) => {
        return input.name.includes(filterWithName);
    });
}

/**
 * @function
 * @category Utils
 * @param {AbstractFormReducersState} state - abstract form state
 * @param {string[]} inputNames - list of filtered input names
 */
export const selectValidState = (state: AbstractFormReducersState, inputNames?: Array<string>) : boolean => {

    for (let i = 0; i < state.form.inputs.length; i++) {
        if (inputNames && !inputNames.includes(state.form.inputs[i].name)) { continue; }
        if (state.form.inputs[i].invalid) {
            return false;
        }
    } return true;
}
