import {Communicator, RequestType} from "../utils/Communicator";
import {HardwareResponseDto} from "../entities/Responses/HardwareResponseDto";
import {HardwareRequestDto} from "../entities/Requests/HardwareRequestDto";
import {CacheExpiry} from "../utils/Storage";
import {HardwareAssignRequestDto} from "../entities/Requests/HardwareAssignRequestDto";
import {HardwareTypeDto} from "../entities/HardwareTypeDto";
import {HardwareBulkRequestDto} from "../entities/Requests/HardwareBulkRequestDto";
import {SensorResponseListResponseDto} from "../entities/Responses/SensorResponseListResponseDto";
import {TranslatorType} from "../entities/Utils/Translator";

/**
 * @class
 * @category Repositories
 */
export class HardwareRepository {

    /**
     * Get list of hardware
     * @param {boolean} useCache - use cached request
     */
    public static async getHardwareList(useCache: boolean) : Promise<Array<HardwareResponseDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "hardware",
            cache: {cacheKey: "getHardwareList", expiry: CacheExpiry.FIVE_MINUTES, useCache: useCache}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get list of sensor for hardware
     * @param {number} hardwareId - hardware id as filter
     */
    public static async getSensors(hardwareId: number) : Promise<SensorResponseListResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `hardware/${hardwareId}/sensor`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get hardware types list
     */
    public static async getHardwareTypesList() : Promise<Array<HardwareTypeDto>|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: "hardwareType",
            cache: {cacheKey: "getHardwareTypesList", expiry: CacheExpiry.ONE_DAY, useCache: true}
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get hardware by ID
     * @param {number} hardwareId - hardware ID
     */
    public static async getHardware(hardwareId: number) : Promise<HardwareResponseDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `hardware/${hardwareId}`,
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Delete hardware by ID
     * @param {number} hardwareId - hardware ID
     * @param {TranslatorType} t - translator object
     */
    public static async deleteHardware(hardwareId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.DELETE,
            endpoint: `hardware/${hardwareId}`,
            cache: {deleteKeys: ['getHive', 'getAlerts', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return; }
        throw new Error(t('HardwareRepository_deleteHardware'))
    }

    /**
     * Activate hardware by ID
     * @param {number} hardwareId - hardware ID
     * @param {TranslatorType} t - translator object
     */
    public static async activateHardware(hardwareId: number, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `hardware/${hardwareId}/undelete`,
            cache: {deleteKeys: ['getHive', 'getAlerts', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return; }
        throw new Error(t('HardwareRepository_activateHardware'))
    }

    /**
     * Update hardware info for hardware ID
     * @param {number} hardwareId - hardware ID
     * @param {HardwareRequestDto} hardwareRequest - info to update hardware
     * @param {TranslatorType} t - translator object
     */
    public static async updateHardware(hardwareId: number, hardwareRequest: HardwareRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.PUT,
            endpoint: `hardware/${hardwareId}`,
            body: hardwareRequest,
            cache: {deleteKeys: ['getHive', 'getAlerts', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('HardwareRepository_updateHardware'))
    }

    /**
     * Create new hardware
     * @param {HardwareRequestDto} hardwareRequest - info about new hardware
     * @param {TranslatorType} t - translator object
     */
    public static async createHardware(hardwareRequest: HardwareRequestDto, t: TranslatorType) : Promise<void> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "hardware",
            body: hardwareRequest,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }

        if (response.apiError?.code === 'CREATE_HARDWARE_EXISTS') { throw new Error(t('HardwareRepository_createHardwareErrExists')); }
        throw new Error(t('HardwareRepository_createHardware'))
    }

    /**
     * Assign hardware to patient
     * @param {number} hardwareId - hardware ID
     * @param {TranslatorType} t - translator object
     * @param {HardwareAssignRequestDto} hardwareAssignRequest - patient info to assign
     */
    public static async assignHardware(hardwareId: number, hardwareAssignRequest: HardwareAssignRequestDto, t: TranslatorType) : Promise<void|null> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: `hardware/${hardwareId}/assign`,
            body: hardwareAssignRequest,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan', 'getZones']}
        });

        if (response.ok) { return response.data; }
        throw new Error(t('HardwareRepository_assignHardware'))
    }

    /**
     * Create new hardware - BULK
     * @param {TranslatorType} t - translator object
     * @param {HardwareBulkRequestDto} hardwareBulkRequest - bulk request object
     */
    public static async createHardwareBulk(hardwareBulkRequest: HardwareBulkRequestDto, t: TranslatorType) : Promise<void> {

        const response = await Communicator.performRequest({
            type: RequestType.POST,
            endpoint: "hardware/bulk",
            timeout: 60000,
            body: hardwareBulkRequest,
            cache: {deleteKeys: ['getHive', 'getHardwareList', 'getPatientList', 'getBuildingPlan']}
        });

        if (response.ok) { return response.data; }

        if (response.apiError?.code === 'CREATE_HARDWARE_EXISTS') { throw new Error('Hardware already exists!'); }
        throw new Error(t('HardwareRepository_createHardwareBulk'))
    }
}
