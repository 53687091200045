import React from "react";
import {LoginBlock} from "../../organisms/LoginBlock/LoginBlock";
import "./WelcomeTemplate.scss"
import {RegisterBlock} from "../../organisms/RegisterBlock/RegisterBlock";
import {useAppSelector} from "../../../store/hooks";
import {welcomePageSlice, WelcomePageViewType} from "../../../store/slices/pages/welcomePageSlice";
import {Helmet} from "react-helmet-async";
import screenA1x from '../../../images/Screen/screen.avif';
import screenA2x from '../../../images/Screen/screen@2x.avif';
import screenA3x from '../../../images/Screen/screen@3x.avif';
import screen1x from '../../../images/Screen/screen.png';
import screen2x from '../../../images/Screen/screen@2x.png';
import screen3x from '../../../images/Screen/screen@3x.png';
import logo from "../../../images/logo.svg";
import ResetBlock from "../../organisms/ResetBlock/ResetBlock";
import RecoveryBlock from "../../organisms/RecoveryBlock/RecoveryBlock";
import {useTranslate} from "@tolgee/react";

type WelcomeTemplateProps = {
    hash: string|null,
    email: string|null,
}

/**
 * @component
 * @category Components
 * @subcategory Templates
 * @param {string|null} hash - has auth hash to reset password
 * @param {string|null} email - has auth email to reset password
 */
export const WelcomeTemplate = ({hash, email} : WelcomeTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();
    const viewTypeState = useAppSelector((state) => state.welcomePage.viewType);

    return (
        <main>
            <div className="WelcomeTemplate">
                <Helmet>
                    <title>{t('WelcomeTemplate_title')}</title>
                </Helmet>
                <div className="WelcomeTemplate-content">
                    <div className="WelcomeTemplate-content-left">
                        <div className="WelcomeTemplate-content-left-logo">
                            <div className="WelcomeTemplate-content-left-logo-image">
                                <img src={logo} alt={'Logo'} />
                            </div>
                            <div className="WelcomeTemplate-content-left-logo-text">
                                Angelo
                            </div>
                        </div>
                        {hash !== null && email !== null &&
                            <div className="WelcomeTemplate layoutCentered">
                                <ResetBlock
                                    hash={hash}
                                    email={email} />
                            </div>
                        }
                        {hash === null && viewTypeState === WelcomePageViewType.LOGIN &&
                            <div className="WelcomeTemplate layoutCentered">
                                <LoginBlock
                                    dispatchChangeView={welcomePageSlice.actions.changeView}/>
                            </div>
                        }
                        {hash === null && viewTypeState === WelcomePageViewType.REGISTER &&
                            <div className="WelcomeTemplate layoutCentered">
                                <RegisterBlock
                                    dispatchChangeView={welcomePageSlice.actions.changeView}/>
                            </div>
                        }
                        {hash === null && viewTypeState === WelcomePageViewType.RECOVERY &&
                            <div className="WelcomeTemplate layoutCentered">
                                <RecoveryBlock
                                    dispatchChangeView={welcomePageSlice.actions.changeView}/>
                            </div>
                        }
                    </div>
                    <div className="WelcomeTemplate-content-right">
                        <div className="WelcomeTemplate-content-right-image">
                            <picture>
                                <source srcSet={`${screenA1x} 493w, ${screenA2x} 985w, ${screenA3x} 1478w`} type="image/avif"/>
                                <source srcSet={`${screen1x} 493w, ${screen2x} 985w, ${screen3x} 1478w`} type="image/png"/>
                                <img src={screen1x} alt={'System'}/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default WelcomeTemplate;
