import {createSlice} from '@reduxjs/toolkit'
import {PhoneResponseDto} from "../../../models/entities/Responses/PhoneResponseDto";
import {actionCreateContact, actionDeleteContact, actionGetPhoneList} from "../../actions/data/phoneAction";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface PhoneState {

    /** List of phone numbers **/
    phoneList?: Array<PhoneResponseDto>|null,
}

const initialState: PhoneState = {
    phoneList: undefined,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const phoneDataSlice = createSlice({
    name: 'phoneData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actionGetPhoneList.fulfilled, (state, {payload}) => {
            state.phoneList = payload;
        })
        builder.addCase(actionCreateContact.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('phoneDataSlice-actionCreateContact: wrong payload, skip!')
                return;
            }

            if (state.phoneList) { state.phoneList.unshift(payload); }
        })
        builder.addCase(actionDeleteContact.fulfilled, (state, {payload}) => {

            if (typeof payload === "string") {
                console.warn('phoneDataSlice-actionDeleteContact: wrong payload, skip!')
                return;
            }

            if (state.phoneList) {
                const index = state.phoneList.findIndex((phone: PhoneResponseDto) => phone.id === payload.id);
                state.phoneList.splice(index, 1);
            }
        })
    }
})

export default phoneDataSlice.reducer
