import { createAsyncThunk } from "@reduxjs/toolkit";
import {ZoneDto} from "../../../models/entities/ZoneDto";
import {ZoneRepository} from "../../../models/repositories/ZoneRepository";
import {ZoneRequestDto} from "../../../models/entities/Requests/ZoneRequestDto";
import {HardwareUnassignBulkRequestDto} from "../../../models/entities/Requests/HardwareUnassignBulkRequestDto";
import {ZoneResponseDto} from "../../../models/entities/Responses/ZoneResponseDto";
import {TranslatorType} from "../../../models/entities/Utils/Translator";

/**
 * Get alert list
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {ZoneDto[] | null}
 */
export const actionGetZoneList = createAsyncThunk<Array<ZoneDto> | null>(
    "zone/loadAlertList",
    async () => {
        return await ZoneRepository.getZones();
    }
);

/**
 * Get zone ban template
 * List of zones default banned
 * @category Redux
 * @subcategory Actions
 * @function
 * @return {number[] | null}
 */
export const actionGetZoneBanTemplate = createAsyncThunk<Array<number> | null>(
    "zone/getZoneBanTemplate",
    async () => {
        return (await ZoneRepository.getZoneBanTemplate(false))?.map((zone: ZoneDto) => {
            return zone.id;
        }) ?? null;
    }
);

/**
 * Ban zone in template
 * @todo return zoneBanTemplate in banZoneInTemplateById
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, t: TranslatorType}}
 * @return {ZoneDto[] | string}
 */
export const actionBanZoneInTemplate = createAsyncThunk<Array<ZoneDto> | string | null,
    {zoneId: number, t: TranslatorType}>(
    "zone/banZoneInTemplate",
    async (payload: {zoneId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await ZoneRepository.banZoneInTemplateById({zone: payload.zoneId}, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return ZoneRepository.getZones();
    }
);

/**
 * Unban zone in template
 * @todo return zoneBanTemplate in unbanZoneInTemplateById
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, t: TranslatorType}}
 * @return {ZoneDto[] | string}
 */
export const actionUnbanZoneInTemplate = createAsyncThunk<Array<ZoneDto> | string | null,
    {zoneId: number, t: TranslatorType}>(
    "zone/unbanZoneInTemplate",
    async (payload: {zoneId: number, t: TranslatorType}, { rejectWithValue }) => {
        try {  await ZoneRepository.unbanZoneInTemplateById({zone: payload.zoneId}, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return ZoneRepository.getZones();
    }
);

/**
 * Delete alert by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, t: TranslatorType}}
 * @return {ZoneDto[] | null | string}
 */
export const actionDeleteZone = createAsyncThunk<Array<ZoneDto> | null | string,
    {zoneId: number, t: TranslatorType}>(
    "zone/deleteZone",
    async (payload: {zoneId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await ZoneRepository.deleteZone(payload.zoneId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await ZoneRepository.getZones();
    }
);

/**
 * Create new zone
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneRequest: ZoneRequestDto, t: TranslatorType}}
 * @return {ZoneDto[]|null|string}
 */
export const actionCreateZone = createAsyncThunk<Array<ZoneDto> | null | string,
    {zoneRequest: ZoneRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "zone/createZone",
    async (payload: {zoneRequest: ZoneRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        try { await ZoneRepository.createZone(payload.zoneRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await ZoneRepository.getZones();
    }
);

/**
 * Update zone by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, zoneRequest: ZoneRequestDto}}
 * @return {{zone: ZoneDto, zoneList: ZoneDto[]|null}|string}
 */
export const actionUpdateZone = createAsyncThunk<{zoneList: Array<ZoneDto>|null, zone: ZoneResponseDto} | string,
    {zoneId: number, zoneRequest: ZoneRequestDto, t: TranslatorType}, {rejectValue: string}>(
    "zone/updateZone",
    async (payload: {zoneId: number, zoneRequest: ZoneRequestDto, t: TranslatorType}, { rejectWithValue }) => {
        let result;
        try { result = await ZoneRepository.updateZone(payload.zoneId, payload.zoneRequest, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return {
            zone: result,
            zoneList: await ZoneRepository.getZones()
        };
    }
);

/**
 * Un-Delete alert by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{zoneId: number, t: TranslatorType}}
 * @return {ZoneDto[] | null | string}
 */
export const actionUndeleteZone = createAsyncThunk<Array<ZoneDto> | null | string,
    {zoneId: number, t: TranslatorType}>(
    "zone/undeleteZone",
    async (payload: {zoneId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { await ZoneRepository.undeleteZone(payload.zoneId, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
        return await ZoneRepository.getZones();
    }
);

/**
 * Remove HW from zone
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{requestEntity: HardwareUnassignBulkRequestDto, zoneId: number, t: TranslatorType}}
 * @return {ZoneResponseDto | string}
 */
export const actionRemoveZoneHardwareList = createAsyncThunk<
    ZoneResponseDto | string,
    {requestEntity: HardwareUnassignBulkRequestDto, zoneId: number, t: TranslatorType}, {rejectValue: string}>(
    "zone/removeZoneHardwareList",
    async (payload: {requestEntity: HardwareUnassignBulkRequestDto, zoneId: number, t: TranslatorType}, { rejectWithValue }) => {
        try { return await ZoneRepository.unAssignHardwareFromZone(payload.zoneId, payload.requestEntity, payload.t); }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
