import React, {FormEvent} from "react";
import "./BuildingPlanForm.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {useAppDispatch} from "../../../store/hooks";
import {AbstractFormUpdatePayload, InputState} from "../../../models/utils/AbstractFormReducers";
import {CustomSelect} from "../../atoms/CustomSelect/CustomSelect";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {ValidatorItemType} from "../../../models/utils/Validator";
import {BuildingConstructorShowUploadModalProps} from "../../../store/slices/pages/buildingConstructorSlice";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {buildingPlanOrganismSlice} from "../../../store/slices/organisms/buildingPlanSlice";
import {T, useTranslate} from "@tolgee/react";

type BuildingPlanFormProps = {
    isNextStep: boolean,
    dispatchNextStep: ActionCreatorWithoutPayload,
    nameState: InputState|null,
    floorsState: InputState|null,
    fileInputsState: Array<InputState>,
    formErrorState: string|null|undefined,
    formValidState: boolean,
    dispatchShowInputValidStates: ActionCreatorWithoutPayload,
    dispatchUpdateInput: ActionCreatorWithPayload<AbstractFormUpdatePayload>,
    dispatchShowInputError: ActionCreatorWithPayload<{ inputName: string }>,
    dispatchHideInputError: ActionCreatorWithPayload<{ inputName: string }>,
    dispatchRemoveAllGeneratedInputs: ActionCreatorWithoutPayload,
    dispatchAddInputs: ActionCreatorWithPayload<{ inputStates: Array<InputState>, t: any }>,
    dispatchShowFileUploadModal: ActionCreatorWithPayload<BuildingConstructorShowUploadModalProps>,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {boolean} isNextStep - is second step
 * @param {ActionCreatorWithoutPayload} dispatchNextStep - Redux action to change step to second
 * @param {InputState|null} nameState - name input state
 * @param {InputState|null} floorsState - number of floors input state
 * @param {boolean} formValidState - set up form valid state
 * @param {string|null|undefined} formErrorState - set up form error state
 * @param {ActionCreatorWithPayload<AbstractFormUpdatePayload>} dispatchUpdateInput - Redux action to update inputs
 * @param {ActionCreatorWithoutPayload} dispatchShowInputValidStates - Redux action to show input validations
 * @param {ActionCreatorWithPayload<{ inputName: string }>} dispatchShowInputError - Redux callback to show input errors
 * @param {ActionCreatorWithPayload<{ inputName: string }>} dispatchHideInputError - Redux callback to hide input errors
 * @param {ActionCreatorWithoutPayload} dispatchRemoveAllGeneratedInputs - Redux callback to remove all generated inputs
 * @param {InputState[]} fileInputsState - file input state
 * @param {ActionCreatorWithPayload<{ inputStates: Array<InputState> }>} dispatchAddInputs - Redux state to add inputs
 * @param {ActionCreatorWithPayload<BuildingConstructorShowUploadModalProps>} dispatchShowFileUploadModal - Redux callback to show upload modal
 */
export const BuildingPlanForm = ({isNextStep, dispatchNextStep, nameState, floorsState, formValidState, formErrorState, dispatchUpdateInput,
    dispatchShowInputValidStates, dispatchShowInputError, dispatchHideInputError, dispatchRemoveAllGeneratedInputs, fileInputsState,
    dispatchAddInputs, dispatchShowFileUploadModal} : BuildingPlanFormProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // Submit form
    const handleSubmitFirstStep = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        // Check form
        if (!nameState || !nameState.value || !floorsState || !floorsState.value) {
            dispatch(buildingPlanOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        // Remove all inputs
        dispatch(dispatchRemoveAllGeneratedInputs());

        // Create list of new inputs
        const newInputs : Array<InputState> = [];
        for (let i = 0; i < parseInt(floorsState.value.toString()); i++) {
            newInputs.push({name: `file-floor-${i}`, value: null, validations: {required: true, type: ValidatorItemType.SVG}});
        }

        // Add inputs
        dispatch(dispatchAddInputs({inputStates: newInputs, t: t}));
        dispatch(dispatchNextStep());
    }


    // When clicked to upload button callback
    const handleUploadButton = (inputState: InputState) => {

        dispatch(dispatchShowFileUploadModal({
            floor: BuildingUtil.getFloorFromFileInputState(inputState),
            callBackUpdate: (file, sizeX, sizeY) => {
                dispatch(dispatchUpdateInput({
                    inputName: inputState.name,
                    inputValue: file.fileValue,
                    uploadFileName: file.fileName,
                    t: t,
                    metaData: {sizeX: sizeX, sizeY: sizeY}
                }))
            }
        }))
    }

    return (
        <div className={"BuildingPlanForm"}>
            <div className={"BuildingPlanForm-form"}>
                <form onSubmit={handleSubmitFirstStep.bind(this)}>
                    <div className="BuildingPlanForm-form-inputs">
                        <div className="BuildingPlanForm-form-inputs-flex">
                            <InputText
                                required={true}
                                placeholder={t('BuildingPlanForm_buildingName_placeholder')}
                                inputName="name"
                                inputType={InputTextType.TEXT}
                                label={t('BuildingPlanForm_buildingName_label')}
                                disabled={false}
                                startValue={null}
                                value={nameState?.value}
                                inputState={nameState}
                                dispatchOnChange={dispatchUpdateInput}
                                dispatchOnFocus={dispatchShowInputError}
                                dispatchOnBlur={dispatchHideInputError} />
                            <CustomSelect
                                required={true}
                                values={[{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3}, {label: '4', value: 4}]}
                                label={t('BuildingPlanForm_buildingFloor_label')}
                                inputState={floorsState}
                                dispatchOnChange={dispatchUpdateInput}
                                defaultValue={floorsState && floorsState.value ? parseInt(floorsState.value.toString()) : null}
                                placeholder={t('BuildingPlanForm_buildingFloor_placeholder')}
                                dispatchOnFocus={dispatchShowInputError}
                                dispatchOnBlur={dispatchHideInputError}
                                inputName="floors"/>
                        </div>
                        {formErrorState && <span className="formError">{formErrorState}</span>}
                        {!isNextStep &&
                            <div className="BuildingPlanForm-form-inputs-hint">
                                <T keyName={'BuildingPlanForm_hint'} params={{b: <b/>}} />
                            </div>
                        }
                        {!isNextStep &&
                            <div className="BuildingPlanForm-form-inputs-button">
                                <Button
                                    onClickDisabled={() => { dispatch(dispatchShowInputValidStates()); }}
                                    text={t('BuildingPlanForm_button_continue')}
                                    type={ButtonType.PRIMARY}
                                    disabled={!formValidState}
                                    isSubmit={true}/>
                            </div>
                        }
                    </div>
                </form>
                {isNextStep &&
                    <div className="BuildingPlanForm-form-inputs-files">
                        <table>
                            <tbody>
                                {fileInputsState.map((fileInputState: InputState, index: number) => {
                                    return (
                                        <tr key={`BuildingPlanForm-form-inputs-files-row-${index}`}>
                                            <td>
                                                {BuildingUtil.getFloorFromFileInputState(fileInputState)}. floor
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={ () => { handleUploadButton(fileInputState); }}
                                                    text={t('BuildingPlanForm_button_upload')}
                                                    type={ButtonType.PRIMARY} />
                                            </td>
                                            <td>
                                                {fileInputState?.uploadFileName}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    );
}

export default BuildingPlanForm;
