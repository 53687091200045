import React, {useEffect} from "react";
import {NavigationItem} from "../../molecules/NavigationItem/NavigationItem";
import "./Navigation.scss"
import {Image} from "../../atoms/Image/Image";
import logo from "../../../images/logo.svg";
import {useLocation} from 'react-router-dom'
import dashboard from "../../../images/home.svg"
import hearth from "../../../images/heart.svg"
import hardware from "../../../images/hardware.svg"
import users from "../../../images/people.svg"
import patient from "../../../images/patient.svg"
import support from "../../../images/support.svg"
import constructor from "../../../images/building.svg"
import buildingPlan from "../../../images/buildingPlan.svg"
import arrowLeftIcon from "../../../images/arrowLeftIcon.svg"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useTranslate} from "@tolgee/react";
import {actionGetAlertActiveCount} from "../../../store/actions/data/alertAction";
import backClose from "../../../images/backClose.svg";
import zoneIcon from "../../../images/zones.svg";

type NavigationProps = {
    role: RoleEnum
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {RoleEnum} role - current user role
 */
export const Navigation = ({role} : NavigationProps): React.JSX.Element|null => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const location = useLocation().pathname;
    const navigationLaptopHiddenState = useAppSelector((state) => state.main.navigationLaptopHidden);
    const alertActiveCountState = useAppSelector((state) => state.alertData.activeCount);
    const fcmTokenState = useAppSelector((state) => state.main.firebaseMessagingToken);

    // Init interval loop to get active messages count
    useEffect(() => { if (!alertActiveCountState) { dispatch(actionGetAlertActiveCount()); } }, [dispatch, alertActiveCountState])

    // Init interval loop to get unread messages count
    useEffect(() => {
        if (fcmTokenState !== null) { return; }
        const checkMs = process.env.REACT_APP_ALERT_UNREAD_COUNT_REFRESH_MS ? parseInt(process.env.REACT_APP_ALERT_UNREAD_COUNT_REFRESH_MS) : 60000;
        const interval = setInterval(() => { dispatch(actionGetAlertActiveCount()); }, checkMs);
        return () => { clearInterval(interval); }
    }, [dispatch, fcmTokenState])

    return (
        <div className={`Navigation`}>
            <div className={`Navigation`}>
                <div
                    onClick={ () => { dispatch(mainSlice.actions.navigationMobileHide()); } }
                    className="Navigation-close">
                    <div className={'Navigation-close-icon'}>
                        <img src={backClose} alt={t('HeaderMobile_back')}  />
                    </div>
                </div>
                <div className="Navigation-logo">
                    <div className="Navigation-logo-image">
                        <Image source={logo} description={t('Navigation_logo')} size={{height: 60, width: 60}} />
                    </div>
                    <div className="Navigation-logo-text">
                        Angelo
                    </div>
                </div>
                {!navigationLaptopHiddenState ?
                    <div className="Navigation-scroll" onClick={() => { dispatch(mainSlice.actions.navigationLaptopHide()); }}>
                        <div className="Navigation-scroll-icon">
                            <Image source={arrowLeftIcon} description={t('Base_minimize')}/>
                        </div>
                    </div>
                    :
                    <div className="Navigation-scroll" onClick={() => { dispatch(mainSlice.actions.navigationLaptopShow()); }}>
                        <div className="Navigation-scroll-icon revert">
                            <Image source={arrowLeftIcon} description={t('Base_maximize')}/>
                        </div>
                    </div>
                }
                <header className="Navigation-header">
                    <div className="Navigation-header-base">

                        <NavigationItem
                            image={dashboard}
                            isPageLink={{link: "/dashboard", current: location === "/dashboard"}}
                            name={t('Navigation_dashboard')}
                            imageSize={{width: 20, height: 20}} />

                        <NavigationItem
                            image={hearth}
                            badgeCount={alertActiveCountState ? alertActiveCountState : undefined}
                            isPageLink={{link: "/alerts", current: location.search('alert') !== -1}}
                            name={t('Navigation_alerts')}
                            imageSize={{width: 23, height: 19}} />

                        <NavigationItem
                            image={hardware}
                            isPageLink={{link: "/devices", current: location.search('devices') !== -1}}
                            name={t('Navigation_hardware')}
                            imageSize={{width: 23, height: 19}} />

                        {role !== RoleEnum.TECHNICAL &&
                            <NavigationItem
                                image={patient}
                                isPageLink={{link: "/patients", current: location === "/patients"}}
                                name={t('Navigation_patients')}
                                imageSize={{width: 25, height: 19}}/>
                        }

                        {role !== RoleEnum.MEDICAL &&
                            <NavigationItem
                                image={constructor}
                                isPageLink={{link: "/editor", current: location === "/editor"}}
                                name={t('Navigation_building_editor')}
                                imageSize={{width: 23, height: 23}}/>
                        }

                        <NavigationItem
                            image={buildingPlan}
                            isPageLink={{link: "/building", current: location === "/building"}}
                            name={t('Navigation_building_plan')}
                            imageSize={{width: 23, height: 23}}/>

                        <NavigationItem
                            image={zoneIcon}
                            isPageLink={{link: "/zones", current: location === "/zones"}}
                            name={t('Navigation_zones')}
                            imageSize={{width: 23, height: 23}}/>

                        {role === RoleEnum.ADMINISTRATOR &&
                        <NavigationItem
                            image={users}
                            isPageLink={{link: "/users", current: location.search('user') !== -1}}
                            name={t('Navigation_users')}
                            imageSize={{width: 25, height: 19}} />
                        }

                        <NavigationItem
                            image={support}
                            isPageLink={{link: "/support", current: location === "/support"}}
                            name={t('Navigation_support')}
                            imageSize={{width: 23, height: 23}} />

                    </div>
                </header>
            </div>
        </div>
    );
}

export default Navigation;
