import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {EasyCalendarFilterPayload} from "../../../components/molecules/EasyCalendar/EasyCalendar";
import {OrderOptions} from "../../../components/molecules/FunctionTable/FunctionTable";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum ListAlertsFilterTypes {
    ALL = 1,
    PROGRESS = 2,
    FINISHED = 3,
    RESOLVED = 4,
    TEMPLATE = 5,
    SENSOR = 6,
    FULLTEXT = 7,
    PATIENT = 8,
    DATE = 9,
    ELEVATION = 10,
    VALUE_TREND = 11,
    UNREAD = 12,
}

/**
 * @enum {number}
 * @category Redux
 * @subcategory Slices
 */
export enum AlertsPageValueTrend {

    LOWERING = 1,
    RISING = 2
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface AlertsPageState {

    /** Current filter **/
    filterType: ListAlertsFilterTypes,
    /** Current pagination page **/
    paginationPage: number,
    /** Filter title **/
    filterTitle: string|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by value trend **/
    valueTrend: AlertsPageValueTrend|null,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by elevation **/
    elevation: number|null,
    /** Filter by full text **/
    fullTextValue: string|null,
    /** Filter by patient **/
    patientId: number|null,
    /** Filter by date **/
    filterDate: EasyCalendarFilterPayload|null,
    /** Pagination order **/
    paginationOrder: OrderOptions|null,
    /** Modal to enter resolve note shown **/
    noteModalShown: boolean,
    /** Alert ID for note **/
    noteModalAlertId: number|null,

}

const initialState: AlertsPageState = {
    filterType: ListAlertsFilterTypes.ALL,
    paginationPage: 0,
    patientId: null,
    filterTitle: null,
    sensorType: null,
    valueTrend: null,
    templateId: null,
    fullTextValue: null,
    filterDate: null,
    elevation: null,
    paginationOrder: null,
    noteModalShown: false,
    noteModalAlertId: null
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const alertsPageSlice = createSlice({
    name: 'alertsPage',
    initialState,
    reducers: {
        destroyPage: state => {
            state.filterType = ListAlertsFilterTypes.ALL;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.filterDate = null;
            state.sensorType = null;
            state.templateId = null;
            state.noteModalShown = false;
            state.noteModalAlertId = null;
            state.valueTrend = null;
            state.elevation = null;
            state.fullTextValue = null;
            state.patientId = null;
            state.paginationOrder = null;
        },
        destroyFilter: state => {
            state.filterType = ListAlertsFilterTypes.ALL;
            state.paginationPage = 0;
            state.filterTitle = null;
            state.sensorType = null;
            state.elevation = null;
            state.templateId = null;
            state.filterDate = null;
            state.fullTextValue = null;
            state.valueTrend = null;
            state.patientId = null;
            state.paginationOrder = null;
        },
        showNoteModal: (state, action: PayloadAction<{ alertId: number }>) => {
            state.noteModalShown = true;
            state.noteModalAlertId = action.payload.alertId;
        },
        hideNoteModal: (state) => {
            state.noteModalShown = false;
            state.noteModalAlertId = null;
        },
        changePaginationPage: (state, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
        changePaginationOrder: (state, action: PayloadAction<OrderOptions>) => {
            state.paginationOrder = action.payload;
        },
        filterDate: (state, action: PayloadAction<EasyCalendarFilterPayload>) => {
            state.filterType = ListAlertsFilterTypes.DATE;
            state.filterTitle = null;
            state.filterDate = {dateFrom: action.payload.dateFrom, dateTo: action.payload.dateTo};
            state.paginationOrder = null;
            state.paginationPage = 0;
        },
        filterElevation: (state, action: PayloadAction<{elevationId: number, title: string }>) => {
            state.filterType = ListAlertsFilterTypes.ELEVATION;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.elevation = action.payload.elevationId;
            state.paginationOrder = null;
        },
        filterValueTrend: (state, action: PayloadAction<{valueTrend: AlertsPageValueTrend, title: string }>) => {
            state.filterType = ListAlertsFilterTypes.VALUE_TREND;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.valueTrend = action.payload.valueTrend;
            state.paginationOrder = null;
        },
        filterFinished: (state) => {
            state.filterType = ListAlertsFilterTypes.FINISHED;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterResolved: (state) => {
            state.filterType = ListAlertsFilterTypes.RESOLVED;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterProgress: (state) => {
            state.filterType = ListAlertsFilterTypes.PROGRESS;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterUnread: (state) => {
            state.filterType = ListAlertsFilterTypes.UNREAD;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterTemplate: (state, action: PayloadAction<{templateId: number, title: string }>) => {
            state.filterType = ListAlertsFilterTypes.TEMPLATE;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.templateId = action.payload.templateId;
            state.paginationOrder = null;
        },
        filterSensor: (state, action: PayloadAction<{sensorType: number, title: string }>) => {
            state.filterType = ListAlertsFilterTypes.SENSOR;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.sensorType = action.payload.sensorType;
            state.paginationOrder = null;
        },
        filterPatient: (state, action: PayloadAction<{patientId: number, title: string }>) => {
            state.filterType = ListAlertsFilterTypes.PATIENT;
            state.paginationPage = 0;
            state.filterTitle = action.payload.title;
            state.paginationOrder = null;
            state.patientId = action.payload.patientId;
        },
        filterFullText: (state, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.paginationPage = 0;
            state.filterTitle = null;
            state.paginationOrder = null;
            if (action.payload.inputValue === null) {
                state.filterType = ListAlertsFilterTypes.ALL;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = ListAlertsFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
    }
})

export default alertsPageSlice.reducer
