import React, {useEffect, useRef} from "react";
import "./FilterFloor.scss"
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {selectInputState} from "../../../models/utils/AbstractFormReducers";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {AbstractFilterType} from "../../../models/utils/AbstractFilterReducers";
import {FilterMain} from "../../extras/Filter/FilterMain";
import {actionGetInternalBuilding} from "../../../store/actions/data/buildingAction";
import {InternalBuildingFloor, UpdateFloorCallback} from "../../../models/utils/AbstractBuildingReducers";
import {T, useTranslate} from "@tolgee/react";
import {filterFloorOrganismSlice} from "../../../store/slices/organisms/filterFloorSlice";
import Button from "../../atoms/Button/Button";

type FilterFloorProps = {
    title: string|null,
    updateFloorCallback: UpdateFloorCallback,
    dispatchAddFloor?: ActionCreatorWithoutPayload,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {string|null} title - filter title
 * @param {ActionCreatorWithoutPayload|undefined} dispatchAddFloor - create new floor callback redux
 * @param {updateFloorCallback} updateFloorCallback - update floor callback
 */
export const FilterFloor = ({title, dispatchAddFloor, updateFloorCallback} : FilterFloorProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const openedState = useAppSelector((state) => state.filterFloorOrganism.opened);
    const filterSearchState = useAppSelector((state) => selectInputState(state.filterFloorOrganism, 'filterSearch'));
    const buildingState = useAppSelector((state) => state.buildingData.building);

    // Re-load data
    useEffect(() => {
        if (!buildingState) { dispatch(actionGetInternalBuilding()); }
    }, [dispatch, buildingState])

    // Clean-up
    useEffect(() => { return () => { dispatch(filterFloorOrganismSlice.actions.hideDropDown()); } }, [dispatch])

    // Outside click trigger
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { dispatch(filterFloorOrganismSlice.actions.hideDropDown()); });

    // Search is initialized
    const searchValue = filterSearchState && filterSearchState.value && filterSearchState.value.toString().length > 0
        ? filterSearchState.value.toString().toLowerCase() : null;

    // Search in floors
    let elevationsFiltered = buildingState && buildingState.building && searchValue
        ? buildingState.building.floors.filter((floor: InternalBuildingFloor) => {
            return floor.elevation.toString().toLocaleLowerCase().includes(searchValue);
        }) : (!buildingState ? buildingState : (buildingState.building ? buildingState.building.floors : null));

    return (
        <div className={`FilterFloor ${openedState ? 'active' : 'hidden'}`} ref={wrapperRef}>
            <FilterMain
                openedState={openedState}
                filterTypeState={openedState ? AbstractFilterType.FILTER_ELEVATION : AbstractFilterType.FILTER_ALL}
                title={title}
                filterSearchState={filterSearchState}
                toggleDropDown={ () => { dispatch(filterFloorOrganismSlice.actions.toggleDropDown()); } }
                updateInput={filterFloorOrganismSlice.actions.updateInput} />
            <div className="FilterFloor-content content">
                {openedState && typeof elevationsFiltered === 'undefined' &&
                    <div className="FilterFloor-content-inside">
                        <div className="FilterFloor-content-inside-placeholder">
                        </div>
                    </div>
                }
                {openedState && elevationsFiltered === null &&
                    <div className="FilterFloor-content-inside">
                        <div className="FilterFloor-content-inside-placeholder">
                            <T keyName={'Base_filter_error_loading'} params={{dataName: t('Base_name_building')}} />
                        </div>
                    </div>
                }
                {openedState && elevationsFiltered &&
                    <div className="FilterFloor-content-inside">
                        {elevationsFiltered.map((floor: InternalBuildingFloor, index: number) => {
                            return (
                                <div
                                    key={`Filter-elevation-${index}`}
                                    onClick={() => {
                                        updateFloorCallback(floor.elevation);
                                        dispatch(filterFloorOrganismSlice.actions.hideDropDown());
                                    }}
                                    className="FilterFloor-content-inside-item">
                                    <div className="FilterFloor-content-inside-item-text">
                                        {floor.elevation + '. ' + t('Base_name_floor')}
                                    </div>
                                </div>
                            )
                        })}
                        <div className={'FilterFloor-content-inside-button'}>
                            {dispatchAddFloor &&
                                <Button
                                    onClick={ () => {
                                        dispatch(filterFloorOrganismSlice.actions.hideDropDown());
                                        dispatch(dispatchAddFloor());
                                    }}
                                    text={t('BuildingManager_button_addFloor')} />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default FilterFloor;
