import {TableCellData, TableCellDataAction, TableCellDataSensor} from "./TableColumn";
import React from "react";
import {TableRowData} from "./TableRow";
import {TableColumns} from "./Table";
import "./TableDetail.scss"
import {ValueUtil} from "../../../models/utils/ValueUtil";
import {showConfirmDialog} from "../../../models/utils/UIUtil";
import {useAppDispatch} from "../../../store/hooks";
import {CustomSelect} from "../CustomSelect/CustomSelect";
import limitDown from "../../../images/arrowDown.svg";
import limitUp from "../../../images/arrowUp.svg"
import {Image} from "../Image/Image";
import {Role} from "../../molecules/Role/Role";
import {useTranslate} from "@tolgee/react";
import {useNavigate} from "react-router-dom";
import backArrow from "../../../images/backArrowWhite.svg"
import Label from "../Label/Label";
import yesIcon from "../../../images/inputOK.svg";
import noIcon from "../../../images/inputNOK.svg";

type TableDetailProps = {
    rowData: TableRowData,
    headers: TableColumns,
}

export enum TableDetailLinkType {
    SECONDARY = "SECONDARY",
    DELETE = "DELETE",
    PRIMARY = "PRIMARY",
}

/**
 * @component
 * @category Components
 * @subcategory Atoms
 * @param {TableRowData[]} rowData - row table data
 * @param {TableColumnData[]} headers - column data
 */
export const TableDetail = ({rowData, headers} : TableDetailProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();
    const navigation = useNavigate();

    const getActionsColumn = () : TableCellData | null => {

        const actions = rowData.columns.find((column: TableCellData) => {
            return column.actions !== null && typeof column.actions !== "undefined";
        }); return actions ? actions : null;
    }

    const getSelectColumn = () : TableCellData | null => {

        const select = rowData.columns.find((column: TableCellData) => {
            return column.select !== null && typeof column.select !== "undefined";
        }); return select ? select : null;
    }

    const parsedActions = getActionsColumn();
    const parsedSelect = getSelectColumn();

    return (
        <div className={"TableDetail"}>
            {parsedSelect && parsedSelect.select &&
                <div className={"TableDetail-select"}>
                    <div className={'TableDetail-select-item'}>
                        <CustomSelect
                            inputState={null}
                            placeholder={parsedSelect.select.placeholder}
                            inputName={parsedSelect.select.name}
                            defaultValue={parsedSelect.select.default ? parsedSelect.select.default : null}
                            onChange={parsedSelect.select.callback}
                            clearable={parsedSelect.select.clearable}
                            values={parsedSelect.select.options} />
                    </div>
                </div>
            }
            <div className="TableDetail-items">
                {rowData.columns.map((cellData: TableCellData, index: number) => {
                    if (!cellData.text && typeof cellData.boolean === "undefined" && !cellData.colorText && !cellData.role && !cellData.label
                        && (!cellData.sensors || (cellData.sensors && cellData.sensors.length === 0))
                        && !cellData.extreme && !cellData.limit && !cellData.date && !cellData.dateTime) {
                        return null;
                    }
                    return (
                        <div
                            className={`TableDetail-items-item ${cellData.sensors ? 'maxWidth' : ''}`}
                            key={`TableDetail-items-item-${index}`}>

                            {(cellData.extreme || cellData.limit) &&
                                <div className={"TableDetail-items-item-extreme"}>
                                    <div className={"TableDetail-items-item-extreme-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={`TableDetail-items-item-extreme-value value`}>
                                        <div className={`TableDetail-items-item-extreme-value-item min`}>
                                            <Image source={limitDown} description={'Down arrow'}/>
                                            {cellData.extreme ? ValueUtil.getStringFromValue(t, cellData.extreme.min) : ''}
                                            {cellData.limit ? ValueUtil.getStringFromValue(t, cellData.limit.min) : ''}
                                            {cellData.extreme ? cellData.extreme.unit : ''}
                                            {cellData.limit ? cellData.limit.unit : ''}
                                        </div>
                                        <div className={`TableDetail-items-item-extreme-value-item max`}>
                                            <Image source={limitUp} description={'Up arrow'}/>
                                            {cellData.extreme ? ValueUtil.getStringFromValue(t, cellData.extreme.max) : ''}
                                            {cellData.limit ? ValueUtil.getStringFromValue(t, cellData.limit.max) : ''}
                                            {cellData.extreme ? cellData.extreme.unit : ''}
                                            {cellData.limit ? cellData.limit.unit : ''}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(cellData.text || cellData.colorText || cellData.date || cellData.dateTime) &&
                                <div className={"TableDetail-items-item-text"}>
                                    <div className={"TableDetail-items-item-text-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={`TableDetail-items-item-text-value value 
                                        ${cellData.colorText ? cellData.colorText.color : ''}`}>
                                        {cellData.text ? ValueUtil.getStringFromValue(t, cellData.text, undefined, true) : ''}
                                        {cellData.colorText ? ValueUtil.getStringFromValue(t, cellData.colorText.text, undefined, true) : ''}
                                        {cellData.date ? ValueUtil.getStringFromValue(t, cellData.date, undefined, true) : ''}
                                        {cellData.dateTime ? ValueUtil.getStringFromValue(t, cellData.dateTime, undefined) : ''}
                                    </div>
                                </div>
                            }
                            {cellData.label &&
                                <div className={"TableDetail-items-item-label"}>
                                    <div className={"TableDetail-items-item-label-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={`TableDetail-items-item-label-value value`}>
                                        <Label text={ValueUtil.getStringFromValue(t, cellData.label)} />
                                    </div>
                                </div>
                            }
                            {typeof cellData.boolean !== 'undefined' &&
                                <div className={"TableDetail-items-item-boolean"}>
                                    <div className={"TableDetail-items-item-boolean-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={"TableDetail-items-item-boolean-content"}>
                                        <div className={"TableDetail-items-item-boolean-content-image"}>
                                            {cellData.boolean.flag
                                                ? <img src={yesIcon} alt={t('TableColumn_boolean_yes')}/>
                                                : <img src={noIcon} alt={t('TableColumn_boolean_no')}/>}
                                        </div>
                                        {cellData.boolean.text &&
                                            <div className={`TableDetail-items-item-boolean-content-text ${cellData.boolean.flag ? 'yes' : 'no'}`}>
                                                {cellData.boolean.text}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {cellData.role &&
                                <div className={"TableDetail-items-item-role"}>
                                    <div className={"TableDetail-items-item-role-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={`TableDetail-items-item-role-value value`}>
                                        <Role role={cellData.role} />
                                    </div>
                                </div>
                            }
                            {cellData.sensors &&
                                <div className={"TableDetail-items-item-images"}>
                                    <div className={"TableDetail-items-item-images-title title"}>
                                        {headers[index].name}
                                    </div>
                                    <div className={"TableDetail-items-item-images-value value"}>
                                        {cellData.sensors.map((sensor: TableCellDataSensor, index: number) => {
                                            return (
                                                <div
                                                    key={`TableDetail-items-item-images-value-item-${index}`}
                                                    className={'TableDetail-items-item-images-value-item'}>
                                                    <div className={'TableDetail-items-item-images-value-item-image'}>
                                                        <img src={sensor.icon} alt={sensor.name} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>

            {rowData.link &&
                <div onClick={() => {
                    if (rowData.link) {
                        navigation(rowData.link.link);
                    }
                }} className={`TableDetail-link PRIMARY`}>
                    <div className={'TableDetail-link-icon arrow icon'}>
                        <img src={backArrow} alt={t('TableDetail_link')}/>
                    </div>
                    <div className={'TableDetail-link-text text'}>
                        {rowData.link.title}
                    </div>
                </div>
            }

            {parsedActions && parsedActions.actions &&
                <>
                    {parsedActions.actions.map((action: TableCellDataAction, index: number) => {
                        return (
                            <div
                                onClick={(e) => {
                                    void showConfirmDialog(action.trigger, e, action, dispatch);
                                }}
                                key={`TableDetail-link-item-${index}`}
                                className={`TableDetail-link ${action.linkType}`}>
                                <div className={'TableDetail-link-icon icon'}>
                                    <img src={action.image} alt={t('TableDetail_link')}/>
                                </div>
                                <div className={'TableDetail-link-text text'}>
                                    {action.title}
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </div>
    )
}

export default TableDetail;
