import React, {useCallback, useMemo} from "react";
import "./HardwareLookup.scss"
import {EasyList, EasyListItem} from "../../molecules/EasyList/EasyList";
import {CustomDraggableAllowedContent, CustomDraggableDoneCallback} from "../../molecules/CustomDraggable/CustomDraggable";
import {useAppDispatch} from "../../../store/hooks";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";
import {BuildingUtil} from "../../../models/utils/BuildingUtil";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {HardwareUtil} from "../../../models/utils/HardwareUtil";
import {useTranslate} from "@tolgee/react";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";

type HardwareLookupProps = {
    allowedContent: CustomDraggableAllowedContent|null,
    dispatchUpdateActiveHardware: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
    doneCallBack: CustomDraggableDoneCallback,
    hardwareType: HardwareTypeEnum,
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    initialStateOpened?: boolean,
    hardwareList?: HardwareResponseDto[]|null,

}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @todo move load hardware up or load specific hardware into specific slice
 * @param {CustomDraggableAllowedContent|null} allowedContent - allowed bounds
 * @param {CustomDraggableDoneCallback} doneCallBack - callback when drag done
 * @param {HardwareTypeEnum} hardwareType - type of hardware
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActiveHardware - dispatch to update active hardware
 * @param {AbstractBuildingUpdateActivePayload|null} activeHardware - currently active hardware id
 * @param {boolean|undefined} initialStateOpened - opened on init
 * @param {HardwareResponseDto[]|undefined|null} hardwareList - hw list
 */
export const HardwareLookup = ({allowedContent, doneCallBack, hardwareType, dispatchUpdateActiveHardware, hardwareList,
    activeHardware, initialStateOpened} : HardwareLookupProps): React.JSX.Element => {

    const dispatch = useAppDispatch();
    const {t} = useTranslate();

    // Parse hardware to easy list items
    const parseHardwareListToEasyListItems = useCallback(() : Array<EasyListItem>|null|undefined => {

        if (!hardwareList) { return hardwareList; }
        return hardwareList
            .filter((hardware: HardwareResponseDto) => { return hardware.Hardware.active && hardware.HardwareTemplate.type === hardwareType; })
            .map((hardware: HardwareResponseDto) => {
                return {
                    searchTags: [hardware.Hardware.internalId, hardware.Hardware.uid, hardware.HardwareTemplate.name, hardware.HardwareTemplate.model],
                    placed: hardware.HardwareLocation !== null,
                    active: hardware.Hardware.id === activeHardware?.id,
                    activatedOutside: !!(activeHardware && activeHardware.callerType !== AbstractBuildingActiveType.FROM_EASY_LIST),
                    title: hardware.Hardware.internalId ?? hardware.Hardware.uid,
                    id: hardware.Hardware.id,
                    type: BuildingUtil.getInternalBuildingLocationTypeFromHardwareType(hardware.HardwareTemplate.type)
                }
        });
    }, [activeHardware, hardwareList, hardwareType]);

    // Get output item list
    const items = useMemo(() => parseHardwareListToEasyListItems(), [parseHardwareListToEasyListItems]);

    return (
        <div className={"HardwareLookup"}>
            <EasyList
                initialStateOpened={initialStateOpened}
                clickCallBack={ (id: number) => {
                    dispatch(dispatchUpdateActiveHardware({id: id, callerType: AbstractBuildingActiveType.FROM_EASY_LIST}));
                } }
                type={BuildingUtil.getInternalBuildingLocationTypeFromHardwareType(hardwareType)}
                startCallBack={ () => {  } }
                items={items}
                title={HardwareUtil.getHardwareTypeMetaFromHardwareType(hardwareType, t).title}
                dragging={{allowedContent: allowedContent, doneCallBack: doneCallBack }} />
        </div>
    );
}

export default HardwareLookup;
