import { createAsyncThunk } from "@reduxjs/toolkit";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";

/**
 * Update hardware sensors state
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number}} payload - request payload
 * @return {Array<SensorResponseDto>|null}
 */
export const actionRefreshHardwareSensors = createAsyncThunk<Array<SensorResponseDto>|null, { hardwareId: number }>(
    "hardwareStates/refreshHardwareSensors",
    async (payload: { hardwareId: number }) => {
        return await SensorRepository.getSensorsForHardware(payload.hardwareId);
    }
);
