import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {actionUpdateHardware} from "../../actions/data/hardwareAction";
import {HardwareTypeEnum} from "../../../models/entities/Enums/HardwareTypeEnum";
import {OrderOptions} from "../../../components/molecules/FunctionTable/FunctionTable";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {
    createHardwareFormInitialState,
    CreateHardwareFormState,
    getCreateHardwareReducers
} from "../../../models/utils/AbstractCreateHardwareReducers";

/**
 * @enum {number}
 * @interface
 * @category Redux
 * @subcategory Slices
 */
export enum ListHardwareFilterTypes {
    ALL = 1,
    OWNED = 2,
    ACTIVE = 3,
    TEMPLATE = 4,
    SENSOR = 5,
    PATIENT = 6,
    FULLTEXT = 7,
    WEARABLE = 8,
    TYPE = 9,
    ELEVATION = 10,
}

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {CreateHardwareFormState}
 */
interface HardwarePageState extends CreateHardwareFormState {

    /** Edit hardware modal shown **/
    editHardwareModalShown: boolean,
    /** Edit hardware modal hardware ID **/
    editHardwareModalId: number|null,
    /** Filter type **/
    filterType: ListHardwareFilterTypes,
    /** Filter by template **/
    templateId: number|null,
    /** Filter by wearable **/
    wearable: boolean|null,
    /** Filter by hardware type **/
    type: HardwareTypeEnum|null,
    /** Filter by patient **/
    patientId: number|null,
    /** Filter by sensor type **/
    sensorType: number|null,
    /** Filter by elevation **/
    elevation: number|null,
    /** Filter by full text value **/
    fullTextValue: string|null,
    /** Filter title **/
    filterTitle: string|null,
    /** Current pagination page **/
    paginationPage: number,
    /** Current pagination order **/
    paginationOrder: OrderOptions|null,
}

const initialState: HardwarePageState = {...createHardwareFormInitialState, ...{
    editHardwareModalShown: false,
    filterType: ListHardwareFilterTypes.ACTIVE,
    editHardwareModalId: null,
    fullTextValue: null,
    sensorType: null,
    templateId: null,
    patientId: null,
    type: null,
    elevation: null,
    filterTitle: null,
    paginationPage: 0,
    wearable: null,
    paginationOrder: null,
}}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const hardwarePageSlice = createSlice({
    name: 'hardwarePage',
    initialState,
    reducers: {...getCreateHardwareReducers(), ...{
        destroyPage: (state: HardwarePageState) => {
            state.editHardwareModalShown = false;
            state.filterType = ListHardwareFilterTypes.ACTIVE;
            state.editHardwareModalId = null;
            state.fullTextValue = null;
            state.sensorType = null;
            state.templateId = null;
            state.patientId = null;
            state.filterTitle = null;
            state.elevation = null;
            state.type = null;
            state.wearable = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        destroyFilter: (state: HardwarePageState) => {
            state.filterType = ListHardwareFilterTypes.ACTIVE;
            state.fullTextValue = null;
            state.sensorType = null;
            state.templateId = null;
            state.elevation = null;
            state.wearable = null;
            state.type = null;
            state.patientId = null;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterTemplate: (state: HardwarePageState, action: PayloadAction<{templateId: number, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.TEMPLATE;
            state.templateId = action.payload.templateId;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterPatient: (state: HardwarePageState, action: PayloadAction<{patientId: number, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.PATIENT;
            state.patientId = action.payload.patientId;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterSensor: (state: HardwarePageState, action: PayloadAction<{sensorType: number, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.SENSOR;
            state.sensorType = action.payload.sensorType;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterElevation: (state: HardwarePageState, action: PayloadAction<{elevationId: number, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.ELEVATION;
            state.elevation = action.payload.elevationId;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterAll: (state: HardwarePageState) => {
            state.filterType = ListHardwareFilterTypes.ALL;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterOwned: (state: HardwarePageState) => {
            state.filterType = ListHardwareFilterTypes.OWNED;
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterWearable: (state: HardwarePageState, action: PayloadAction<{wearable: boolean, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.WEARABLE;
            state.wearable = action.payload.wearable;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterType: (state: HardwarePageState, action: PayloadAction<{type: HardwareTypeEnum, title: string }>) => {
            state.filterType = ListHardwareFilterTypes.TYPE;
            state.type = action.payload.type;
            state.filterTitle = action.payload.title;
            state.paginationPage = 0;
            state.paginationOrder = null;
        },
        filterFullText: (state: HardwarePageState, action: PayloadAction<AbstractFormUpdatePayload>) => {
            state.filterTitle = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
            if (action.payload.inputValue === null) {
                state.filterType = ListHardwareFilterTypes.ACTIVE;
                state.fullTextValue = null;
            }
            else if (action.payload.inputValue) {
                state.filterType = ListHardwareFilterTypes.FULLTEXT;
                state.fullTextValue = action.payload.inputValue.toString();
            }
        },
        changePaginationPage: (state: HardwarePageState, action: PayloadAction<{ page: number }>) => {
            state.paginationPage = action.payload.page;
        },
        changePaginationOrder: (state: HardwarePageState, action: PayloadAction<OrderOptions>) => {
            state.paginationOrder = action.payload;
        },
        showEditHardwareModal: (state: HardwarePageState, action: PayloadAction<{hardwareId: number }>) => {
            state.editHardwareModalShown = true;
            state.editHardwareModalId = action.payload.hardwareId;
        },
        hideEditHardwareModal: (state: HardwarePageState) => {
            state.editHardwareModalShown = false;
            state.editHardwareModalId = null;
        },
    }},
    extraReducers: (builder) => {
        builder.addCase(actionUpdateHardware.fulfilled, (state) => {
            state.editHardwareModalShown = false;
            state.editHardwareModalId = null;
            state.paginationPage = 0;
            state.paginationOrder = null;
        })
    }
})

export default hardwarePageSlice.reducer