import {createSlice, PayloadAction} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface SensorStateState {
    currentNote: string|null,
    noteShown: boolean,
    noteSensorId: number|null
}

const initialState: SensorStateState = {
    currentNote: null,
    noteShown: false,
    noteSensorId: null
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const sensorStateOrganismSlice = createSlice({
    name: 'sensorStateOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.noteShown = false;
            state.currentNote = null;
        },
        showNote: (state, action: PayloadAction<{ noteSensorId: number }>) => {
            state.noteSensorId = action.payload.noteSensorId;
            state.noteShown = true;
        },
        hideNote: (state) => {
            state.noteShown = false;
            state.noteSensorId = null;
        },
        setNote: (state, action: PayloadAction<{ note: string|null }>) => {
            state.currentNote = action.payload.note;
        }
    }
})

export default sensorStateOrganismSlice.reducer