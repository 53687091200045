import React from "react";
import {Table, TableColumns, TableRows} from "../../atoms/Table/Table";
import "./FunctionTable.scss"
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Pagination} from "../Pagination/Pagination";
import {Empty, EmptyTypeEnum} from "../../extras/Empty/Empty";
import {TableRowData} from "../../atoms/Table/TableRow";
import {DotType} from "../../atoms/Dot/Dot";
import {UserUtil} from "../../../models/utils/UserUtil";
import {useTranslate} from "@tolgee/react";
import {useAppDispatch} from "../../../store/hooks";

/**
 * @interface
 * @category Components
 */
export interface FunctionTableData {

    /** List of columns **/
    columns: TableColumns,
    /** List of rows **/
    rows: TableRows
}

/**
 * @enum {string}
 * @category Components
 */
export enum OrderType {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * @interface
 * @category Components
 */
export interface OrderOptions {

    /** Column no. **/
    columnIndex: number,
    /** Type of ordering **/
    orderType: OrderType
}

/**
 * @alias FunctionTableFEPagination
 * @category Components
 */
type FunctionTableFEPagination = {

    /** Ordering options **/
    order?: OrderOptions|null,
    /** Current page **/
    page: number,
    /** Current size **/
    size: number,
    /** Redux callback to change page **/
    dispatchChangePage: ActionCreatorWithPayload<{ page: number }>,
    /** Redux callback to change ordering **/
    dispatchChangeOrder?: ActionCreatorWithPayload<OrderOptions>
}

/**
 * @alias FunctionTableBEPagination
 * @category Components
 */
type FunctionTableBEPagination = {

    /** Total number of elements **/
    total: number
}

/**
 * @alias FunctionTableEmpty
 * @category Components
 */
type FunctionTableEmpty = {

    /** Empty title **/
    title: string,
    /** Empty subtitle **/
    subTitle: string
}

type FunctionTableProps = {
    data: FunctionTableData,
    fePagination?: FunctionTableFEPagination,
    bePagination?: FunctionTableBEPagination,
    empty?: FunctionTableEmpty
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {FunctionTableData} data - table data
 * @param {FunctionTableFEPagination|undefined} fePagination - pagination on FE functionality
 * @param {FunctionTableBEPagination|undefined}bePagination - pagination on BE functionality
 * @param {FunctionTableEmpty|undefined} empty - table is empty
 */
export const FunctionTable = ({data, bePagination, fePagination, empty} : FunctionTableProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    // Create paginated data
    const getPaginatedData = (rows: TableRows) : TableRows => {

        if (bePagination || !fePagination) { return rows; }
        const startIndex = fePagination.page * fePagination.size;
        const endIndex = startIndex + fePagination.size;
        return rows.slice(startIndex, endIndex);
    };

    const getOrderedValueFromDot = (dot: DotType) : number => {
        if (dot === DotType.SUCCESS) { return  1; }
        if (dot === DotType.PRIMARY) { return  2; }
        return 3;
    }

    const getOrderedData = (rows: TableRows) : TableRows => {

        if (!fePagination || !fePagination.order) { return rows; }
        const columnIndex = fePagination.order.columnIndex;

        return rows.sort((a: TableRowData, b: TableRowData) => {

            // Re-check
            if (!fePagination.order) { return 0; }

            // Check is column exists
            if (!a.columns[columnIndex] || !b.columns[columnIndex]) { return 0; }

            // Get parsable values
            const aColumn = a.columns[columnIndex];
            const bColumn = b.columns[columnIndex];
            let aText = aColumn.text;
            let bText = bColumn.text;
            if (aColumn.colorText) { aText = aColumn.colorText.text; }
            if (bColumn.colorText) { bText = bColumn.colorText.text; }
            if (aColumn.select && aColumn.select.defaultText) { aText = aColumn.select.defaultText; }
            if (bColumn.select && bColumn.select.defaultText) { bText = bColumn.select.defaultText; }
            if (aColumn.image) { aText = aColumn.image.text; }
            if (bColumn.image) { bText = bColumn.image.text; }
            if (aColumn.dateTime) { aText = new Date(aColumn.dateTime).valueOf()}
            if (bColumn.dateTime) { bText = new Date(bColumn.dateTime).valueOf()}
            if (aColumn.date) { aText = new Date(aColumn.date).valueOf()}
            if (bColumn.date) { bText = new Date(bColumn.date).valueOf()}
            if (aColumn.dot) { aText = getOrderedValueFromDot(aColumn.dot.type); }
            if (bColumn.dot) { bText = getOrderedValueFromDot(bColumn.dot.type); }
            if (aColumn.role) { aText = UserUtil.getRoleFEMetaFromRole(aColumn.role, t).text; }
            if (bColumn.role) { bText = UserUtil.getRoleFEMetaFromRole(bColumn.role, t).text; }

            // Fix nullable (move them last)
            if (aText && !bText) { return fePagination.order.orderType === OrderType.DESC ? 1 : -1; }
            if (!aText && bText) { return fePagination.order.orderType === OrderType.DESC ? -1 : 1; }
            if (!aText && !bText) { return 0; }

            // Boolean
            let aTextNotNullable = aText as number | string | boolean;
            let bTextNotNullable = bText as number | string | boolean;
            if (typeof aTextNotNullable === "boolean" && typeof bTextNotNullable === "boolean") {
                if (aTextNotNullable && !bTextNotNullable) { return fePagination.order.orderType === OrderType.DESC ? 1 : -1; }
                if (!aTextNotNullable && bTextNotNullable) { return fePagination.order.orderType === OrderType.DESC ? -1: 1; }
            }

            // Number
            aTextNotNullable = typeof aTextNotNullable === "string" ? aTextNotNullable.replace('#', '') : aTextNotNullable;
            bTextNotNullable = typeof bTextNotNullable === "string" ? bTextNotNullable.replace('#', '') : bTextNotNullable;
            if (!isNaN(Number(aTextNotNullable)) && !isNaN(Number(bTextNotNullable))) {
                return fePagination.order.orderType === OrderType.DESC
                    ? (Number(aTextNotNullable) - Number(bTextNotNullable))
                    : (Number(bTextNotNullable) - Number(aTextNotNullable));
            }

            // String
            if (typeof aTextNotNullable === "string" && typeof bTextNotNullable === "string") {
                return fePagination.order.orderType === OrderType.DESC
                    ? (aTextNotNullable.toString().localeCompare(bTextNotNullable.toString()))
                    : (bTextNotNullable.toString().localeCompare(aTextNotNullable.toString()));
            }

            // One type is not compatible
            return 0;
        });
    }

    // Initialize data
    const paginatedData = getPaginatedData(getOrderedData(data.rows));

    return (
        <div className={`FunctionTable ${bePagination || fePagination ? 'paginated' : ''}`}>
            <div className="table">
                <Table
                    order={fePagination ? fePagination.order : null}
                    dispatchChangeOrder={fePagination ? fePagination.dispatchChangeOrder : undefined}
                    rows={paginatedData ?? []}
                    columns={data.columns}/>
            </div>
            {paginatedData.length === 0 && empty &&
                <Empty
                    type={EmptyTypeEnum.FLAT}
                    title={empty.title}
                    subTitle={empty.subTitle} />
            }
            {((bePagination && fePagination) || fePagination) &&
                <Pagination
                    page={fePagination.page}
                    size={fePagination.size}
                    total={bePagination ? bePagination.total : data.rows.length}
                    callBackChangePage={(page: number) => { dispatch(fePagination.dispatchChangePage({page: page})); }}
                />
            }
        </div>
    );
}

export default FunctionTable;
