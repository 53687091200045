import { createAsyncThunk } from "@reduxjs/toolkit";
import {LogRepository} from "../../../models/repositories/LogRepository";
import {BasePaginationObject} from "../../../models/entities/BasePaginationObject";
import {LogResponseBodyDto} from "../../../models/entities/Responses/LogResponseBodyDto";

/**
 * Get log list
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {BasePaginationObject} pagination - pagination info
 * @return {LogResponseBodyDto | null}
 */
export const actionGetLogList = createAsyncThunk<LogResponseBodyDto | null, BasePaginationObject>(
    "listLog/getLogList",
    async (pagination: BasePaginationObject) => {
        return await LogRepository.getLogList({pageSize: pagination.pageSize, currentPage: pagination.currentPage});
    }
);

/**
 * Get log list for hardware ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{hardwareId: number, pagination: BasePaginationObject}} request - pagination and hardware ID
 * @return {LogResponseBodyDto | null}
 */
export const actionGetHardwareLogList = createAsyncThunk<LogResponseBodyDto | null, {hardwareId: number, pagination: BasePaginationObject}> (
    "listLog/getHardwareLogList",
    async ({hardwareId, pagination}) => {
        return await LogRepository.getLogListForHardware(hardwareId, {pageSize: pagination.pageSize, currentPage: pagination.currentPage});
    }
);

/**
 * Get log list for alert ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{alertId: number, pagination: BasePaginationObject}} request - pagination and alert ID
 * @return {LogResponseBodyDto | null}
 */
export const actionGetAlertLogList = createAsyncThunk<LogResponseBodyDto | null, {alertId: number, pagination: BasePaginationObject}>(
    "listLog/getAlertLogList",
    async ({alertId, pagination}) => {
        return await LogRepository.getLogListForAlert(alertId, {pageSize: pagination.pageSize, currentPage: pagination.currentPage});
    }
);

/**
 * Get log list for patient ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number, pagination: BasePaginationObject}} request - pagination and patient ID
 * @return {LogResponseBodyDto | null}
 */
export const actionGetPatientLogList = createAsyncThunk<LogResponseBodyDto | null, {patientId: number, pagination: BasePaginationObject}>(
    "listLog/getPatientLogList",
    async ({patientId, pagination}) => {
        return await LogRepository.getLogListForPatient(patientId, {pageSize: pagination.pageSize, currentPage: pagination.currentPage});
    }
);