import {Communicator, RequestType} from "../utils/Communicator";
import {LogResponseBodyDto} from "../entities/Responses/LogResponseBodyDto";
import {LogFilterDto} from "../entities/Filters/LogFilterDto";

/**
 * @class
 * @category Repositories
 */
export class LogRepository {

    /**
     * Get log list for hardware ID
     * @param {number} hardwareId - hardware ID
     * @param {LogFilterDto} filter - log filter
     */
    public static async getLogListForHardware(hardwareId: number, filter: LogFilterDto) : Promise<LogResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            timeout: 20000,
            endpoint: `log/hardware/${hardwareId}`,
            query: filter
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get log list for alert ID
     * @param {number} alertId - alert ID
     * @param {LogFilterDto} filter - log filter
     */
    public static async getLogListForAlert(alertId: number, filter: LogFilterDto) : Promise<LogResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            timeout: 20000,
            endpoint: `log/alert/${alertId}`,
            query: filter
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get log list for patient
     * @param {number} patientId - patient ID
     * @param {LogFilterDto} filter - log filter
     */
    public static async getLogListForPatient(patientId: number, filter: LogFilterDto) : Promise<LogResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            timeout: 20000,
            endpoint: `log/patient/${patientId}`,
            query: filter
        });

        if (response.ok) { return response.data; }
        return null;
    }

    /**
     * Get log list
     * @param {LogFilterDto} filter - log filter
     */
    public static async getLogList(filter: LogFilterDto) : Promise<LogResponseBodyDto|null> {

        const response = await Communicator.performRequest({
            type: RequestType.GET,
            endpoint: `log`,
            timeout: 20000,
            body: {},
            query: filter
        });

        if (response.ok) { return response.data; }
        return null;
    }
}
