import React, {FormEvent, useEffect} from "react";
import "./BuildingPlanUpload.scss"
import {InputText, InputTextType} from "../../atoms/InputText/InputText";
import {Image} from "../../atoms/Image/Image";
import close from "../../../images/closeWhite.svg";
import {ActionCreatorWithoutPayload} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {Button, ButtonType} from "../../atoms/Button/Button";
import {buildingPlanUploadOrganismSlice} from "../../../store/slices/organisms/buildingPlanUploadSlice";
import {BuildingPlanUploadCallbackUpdateFileInput} from "../../../models/utils/AbstractBuildingReducers";
import {T, useTranslate} from "@tolgee/react";

type BuildingPlanUploadProps = {
    dispatchHideModal: ActionCreatorWithoutPayload,
    floor: number,
    callbackUpdateFileInput: BuildingPlanUploadCallbackUpdateFileInput
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @param {ActionCreatorWithoutPayload} dispatchHideModal - Redux action to hide modal
 * @param {BuildingPlanUploadCallbackUpdateFileInput} callbackUpdateFileInput - Callback when updated file input
 * @param {number} floor - current floor
 */
export const BuildingPlanUpload = ({dispatchHideModal, callbackUpdateFileInput, floor} : BuildingPlanUploadProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    const formValidState = useAppSelector((state) => selectValidState(state.buildingPlanUploadOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.buildingPlanUploadOrganism));
    const fileState = useAppSelector((state) => selectInputState(state.buildingPlanUploadOrganism, 'file'));
    const sizeXState = useAppSelector((state) => selectInputState(state.buildingPlanUploadOrganism, 'sizeX'));
    const sizeYState = useAppSelector((state) => selectInputState(state.buildingPlanUploadOrganism, 'sizeY'));

    // Clean
    useEffect(() => {
        return () => { dispatch(buildingPlanUploadOrganismSlice.actions.clearForm({t: t})); }
    }, [dispatch, t])

    // Submit upload form
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {

        event.preventDefault();
        event.stopPropagation();

        if (!fileState || !fileState.value || !fileState.uploadFileName || !sizeXState || !sizeXState.value || !sizeYState || !sizeYState.value) {
            dispatch(buildingPlanUploadOrganismSlice.actions.setFormError({error: t("Base_errors_wrong_input")}));
            return;
        }

        callbackUpdateFileInput(
            {fileValue: fileState.value.toString(), fileName: fileState.uploadFileName},
            parseInt(sizeXState.value.toString()),
            parseInt(sizeYState.value.toString())
        );

        dispatch(dispatchHideModal());
    }

    return (
        <div className="BuildingPlanUpload">
            <div className="BuildingPlanUpload-modal">
                <div className="BuildingPlanUpload-modal-content">
                    <div className="BuildingPlanUpload-modal-content-title">
                        <div className="BuildingPlanUpload-modal-content-title-text">
                            <T keyName={'BuildingPlanUpload_title'} params={{floor: floor}} />
                        </div>
                        <div className="BuildingPlanUpload-modal-content-title-close">
                            <Image
                                onClick={() => dispatch(dispatchHideModal()) }
                                source={close}
                                description={t("Base_close_modal")}
                                size={{width: 20, height: 20}} />
                        </div>
                    </div>
                    <div className="BuildingPlanUpload-modal-content-form">
                        <form onSubmit={handleSubmit.bind(this)}>
                            <div className="BuildingPlanUpload-modal-content-form-inputs">
                                <InputText
                                    required={true}
                                    placeholder={t('BuildingPlanUpload_sizeX_placeholder')}
                                    inputName="sizeX"
                                    inputType={InputTextType.NUMBER}
                                    label={t('BuildingPlanUpload_sizeX_label')}
                                    disabled={false}
                                    startValue={null}
                                    value={sizeXState?.value}
                                    inputState={sizeXState}
                                    dispatchOnChange={buildingPlanUploadOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={buildingPlanUploadOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={buildingPlanUploadOrganismSlice.actions.removeFocus} />
                                <InputText
                                    required={true}
                                    placeholder={t('BuildingPlanUpload_sizeY_placeholder')}
                                    inputName="sizeY"
                                    inputType={InputTextType.NUMBER}
                                    label={t('BuildingPlanUpload_sizeY_label')}
                                    disabled={false}
                                    startValue={null}
                                    value={sizeYState?.value}
                                    inputState={sizeYState}
                                    dispatchOnChange={buildingPlanUploadOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={buildingPlanUploadOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={buildingPlanUploadOrganismSlice.actions.removeFocus} />
                                <InputText
                                    startValue={null}
                                    inputState={fileState}
                                    inputName={'file'}
                                    value={fileState?.value}
                                    dispatchOnChange={buildingPlanUploadOrganismSlice.actions.updateInput}
                                    dispatchOnFocus={buildingPlanUploadOrganismSlice.actions.setFocus}
                                    dispatchOnBlur={buildingPlanUploadOrganismSlice.actions.removeFocus}
                                    inputType={InputTextType.FILE}
                                    placeholder={t('BuildingPlanUpload_input_placeholder')}/>
                                {formErrorState && <span className="formError">{formErrorState}</span>}
                            </div>
                            <div className="BuildingPlanUpload-modal-content-form-inputs-hint">
                                <T keyName={'BuildingPlanUpload_hint'} />
                            </div>
                            <div className="BuildingPlanUpload-modal-content-form-inputs-button">
                                <Button
                                    text={t('BuildingPlanUpload_button')}
                                    type={ButtonType.PRIMARY}
                                    disabled={!formValidState}
                                    onClickDisabled={() => { dispatch(buildingPlanUploadOrganismSlice.actions.showInputValidStates()); }}
                                    isSubmit={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingPlanUpload;
