import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {toast} from "react-toastify";
import {Toastify, ToastifyNavigation, ToastifyType} from "../../../components/extras/Toastify/Toastify";
import {actionSetMessagingToken, actionShowNotification} from "../../actions/extra/mainSliceAction";
import {NavigateFunction} from "react-router/dist/lib/hooks";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface MainState {

    /** Laptop navigation hidden **/
    navigationLaptopHidden: boolean,
    /** Mobile navigation shown **/
    navigationMobileShown: boolean,
    /** Profile navigation shown on mobile **/
    profileMobileShown: boolean,
    /** Shown call help dropdown **/
    dialModalShown: boolean,
    /** Shown send sms dropdown **/
    smsModalShown: boolean,
    /** Firebase Messaging API token **/
    firebaseMessagingToken: string|null,
    /** Navigator hook function reference **/
    navigateHook: NavigateFunction|null,
}

const initialState: MainState = {

    navigationLaptopHidden: false,
    navigationMobileShown: false,
    profileMobileShown: false,
    dialModalShown: false,
    smsModalShown: false,
    firebaseMessagingToken: null,
    navigateHook: null,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        navigationLaptopShow: (state) => { state.navigationLaptopHidden = false; },
        navigationLaptopHide: (state) => { state.navigationLaptopHidden = true; },
        navigationMobileHide: (state) => { state.navigationMobileShown = false; },
        profileMobileHide: (state) => { state.profileMobileShown = false; },
        dialModalHide: (state) => { state.dialModalShown = false; },
        smsModalHide: (state) => { state.smsModalShown = false; },
        profileMobileShow: (state) => {
            state.profileMobileShown = true;
            state.dialModalShown = false;
            state.navigationMobileShown = false;
            state.smsModalShown = false;
        },
        dialModalShow: (state) => {
            state.dialModalShown = true;
            state.profileMobileShown = false;
            state.navigationMobileShown = false;
            state.smsModalShown = false;
        },
        smsModalShow: (state) => {
            state.smsModalShown = true;
            state.profileMobileShown = false;
            state.navigationMobileShown = false;
            state.dialModalShown = false;
        },
        navigationMobileShow: (state) => {
            state.navigationMobileShown = true;
            state.profileMobileShown = false;
        },
        setNavigateHook: (state, action: PayloadAction<{ navigateHook: NavigateFunction }>) => {
            state.navigateHook = action.payload.navigateHook;
        },
        setTopLevelErrorMessage: (state, action: PayloadAction<{ message: string }>) => {
                toast.error(Toastify({
                    message : action.payload.message.replaceAll('Error:', ''),
                    type : ToastifyType.ERROR
                }), {
                position: "top-right",
                autoClose: 5000,
                icon: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            })
        },
        setTopLevelSuccessMessage: (state, action: PayloadAction<{ message: string }>) => {
            toast.success(Toastify({message : action.payload.message, type : ToastifyType.SUCCESS}), {
                position: "top-right",
                autoClose: 5000,
                icon: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            })
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionShowNotification.fulfilled, (state, {payload}) => {

            const navigation : ToastifyNavigation|null = state.navigateHook !== null && payload.address
                ? {address: payload.address, navigateHook: state.navigateHook}
                : null;

            toast.info(Toastify({
                message : payload.message,
                type : payload.type ?? ToastifyType.NOTIFY,
                title : payload.title,
                navigation : navigation,
                subtitle: payload.subtitle
            }), {
                position: "top-right",
                autoClose: 5000,
                icon: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            })
        })
        builder.addCase(actionSetMessagingToken.fulfilled, (state, {payload}) => {
            state.firebaseMessagingToken = payload;
        })
    }
})

export default mainSlice.reducer
