import React, {useEffect, useRef} from "react";
import "./Header.scss"
import {DateUtil} from "../../../models/utils/DateUtil";
import {Image} from "../../atoms/Image/Image";
import calendar from "../../../images/calendar.svg"
import clock from "../../../images/clock.svg"
import person from "../../../images/personIcon.svg"
import dial from "../../../images/dial.svg"
import sms from "../../../images/sms.svg"
import {EasySearch} from "../../molecules/EasySearch/EasySearch";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {AbstractFormUpdatePayload} from "../../../models/utils/AbstractFormReducers";
import {T, useTranslate} from "@tolgee/react";
import {RoleEnum} from "../../../models/entities/Enums/RoleEnum";
import {useOutsideClickTrigger} from "../../../models/utils/UIUtil";
import {headerOrganismSlice} from "../../../store/slices/organisms/headerSlice";
import Breadcrumb, {BreadcrumbItem} from "../../molecules/Breadcrumb/Breadcrumb";
import ProfileMenu from "../../molecules/ProfileMenu/ProfileMenu";
import Button, {ButtonType} from "../../atoms/Button/Button";
import DialMenu from "../DialMenu/DialMenu";
import {mainSlice} from "../../../store/slices/extra/mainSlice";
import AddDial from "../AddDial/AddDial";
import SMSMenu from "../SMSMenu/SMSMenu";

/**
 * @alias HeaderEasySearch
 * @category Components
 */
type HeaderEasySearch = {

    /** Fulltext search value **/
    searchValue: string | null,
    /** Redux callback to search **/
    dispatchSearchValueUpdate: ActionCreatorWithPayload<AbstractFormUpdatePayload>
}

/**
 * @alias HeaderProps
 * @category Components
 */
type HeaderProps = {

    /** Welcome text subject **/
    title: string,
    /** Easy search options **/
    easySearch?: HeaderEasySearch,
    /** Logged user role **/
    role: RoleEnum,
    /** Bread crumb items **/
    breadItems: Array<BreadcrumbItem>,
    /** Show current date **/
    showDate?: boolean,
}

/**
 * @component
 * @category Components
 * @subcategory Organisms
 * @todo move to redux
 * @param {string} title - header title text
 * @param {HeaderEasySearch|undefined} easySearch - easy search filter fulltext
 * @param {RoleEnum} role - logged user role
 * @param {Array<BreadcrumbItem>} breadItems - breadcrumb items
 * @param {boolean} showDate - show current date
 */
export const Header = ({title, easySearch, role, breadItems, showDate} : HeaderProps): React.JSX.Element => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();

    const [time, setTime] = React.useState(DateUtil.getCurrentTimeFormatted());
    const dropdownShownProfileState = useAppSelector((state) => state.headerOrganism.dropdownShownProfile);
    const dropdownShownDialState = useAppSelector((state) => state.headerOrganism.dropdownShownDial);
    const dropdownShownSMSState = useAppSelector((state) => state.headerOrganism.dropdownShownSMS);
    const addContactShownState = useAppSelector((state) => state.headerOrganism.addContactFormShown);

    // Timer init
    useEffect(() => {
        let mounted = true
        setInterval(() => {
            if (!mounted) { return; }
            setTime(DateUtil.getCurrentTimeFormatted())
        }, 1000);
        return function cleanup() {
            mounted = false;
            dispatch(headerOrganismSlice.actions.destroyOrganism());
        }
    }, [dispatch]);

    // Get date
    const getTodayDate = () : string => {
        return DateUtil.getCurrentDateFormatted('.');
    }

    // Outside click trigger profile
    const wrapperRefProfile: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    useOutsideClickTrigger([wrapperRefProfile], () => { if (dropdownShownProfileState) {
        dispatch(headerOrganismSlice.actions.hideDropdownProfile());
    } });

    // Outside click trigger dial
    const wrapperRefDial: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    useOutsideClickTrigger([wrapperRefDial], () => { if (dropdownShownDialState) {
        dispatch(headerOrganismSlice.actions.hideDropdownDial());
        dispatch(headerOrganismSlice.actions.hideAddDialContact());
    } });

    // Outside click trigger SMS
    const wrapperRefSMS: React.MutableRefObject<HTMLDivElement|null> = useRef(null);
    useOutsideClickTrigger([wrapperRefSMS], () => { if (dropdownShownSMSState) {
        dispatch(headerOrganismSlice.actions.hideDropdownSMS());
    } });

    return (
        <div className="Header">
            <div className="Header-left">
                <div className="Header-left-welcome">
                    {title}
                </div>
                {showDate &&
                    <div className="Header-left-date">
                        <div className="Header-left-date-image">
                            <Image source={calendar} description="Calendar icon"/>
                        </div>
                        <div className="Header-left-date-today">
                            <T keyName={'Header_today'}/>,
                        </div>
                        <div className="Header-left-date-date">
                            {getTodayDate()}
                        </div>
                        <div className="Header-left-date-icon">
                            <Image source={clock} description={t('Header_time')}/>
                        </div>
                        <div className="Header-left-date-time">
                            {time ? time : ''}
                        </div>
                    </div>
                }
                <div className={"Header-left-breadcrumb"}>
                    <Breadcrumb items={breadItems} />
                </div>
            </div>
            <div className="Header-right">
                {easySearch &&
                    <div className="Header-right-item">
                        <EasySearch
                            searchValue={easySearch.searchValue}
                            dispatchSearchValueUpdate={easySearch.dispatchSearchValueUpdate}/>
                    </div>
                }
                <div className="Header-right-item">
                    <div className="Header-right-item-dial" ref={wrapperRefDial}>
                        <div
                            onClick={() => { dispatch(headerOrganismSlice.actions.toggleDropdownDial()); }}
                            className="Header-right-item-dial-main">
                            <img src={dial} alt={t('Base_dial_picture')}/>
                        </div>
                        {dropdownShownDialState &&
                            <div className="Header-right-item-dial-dropdown">
                                <div className={"Header-right-item-dial-dropdown-triangle"}>
                                    <div className={"Header-right-item-dial-dropdown-triangle-inside"}>
                                        <div className={"Header-right-item-dial-dropdown-triangle-inside-sub"}>
                                        </div>
                                    </div>
                                </div>
                                {!addContactShownState
                                    ?
                                    <div className="Header-right-item-dial-dropdown-content">
                                        <DialMenu
                                            dispatchShowAddForm={headerOrganismSlice.actions.showAddDialContact}/>
                                    </div>
                                    :
                                    <div className="Header-right-item-dial-dropdown-content">
                                        <AddDial
                                            dispatchHide={headerOrganismSlice.actions.hideAddDialContact}/>
                                    </div>
                                }

                            </div>
                        }
                    </div>
                    <div className="Header-right-item-sms" ref={wrapperRefSMS}>
                        <div
                            onClick={() => {
                                dispatch(headerOrganismSlice.actions.toggleDropdownSMS());
                            }}
                            className="Header-right-item-sms-main">
                            <img src={sms} alt={t('Base_sms_picture')}/>
                        </div>
                        {dropdownShownSMSState &&
                            <div className="Header-right-item-sms-dropdown">
                                <div className={"Header-right-item-sms-dropdown-triangle"}>
                                    <div className={"Header-right-item-sms-dropdown-triangle-inside"}>
                                        <div className={"Header-right-item-sms-dropdown-triangle-inside-sub"}>
                                        </div>
                                    </div>
                                </div>
                                <div className="Header-right-item-sms-dropdown-content">
                                    <SMSMenu dispatchHide={headerOrganismSlice.actions.hideDropdownSMS}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="Header-right-item">
                    <div className="Header-right-item-profile" ref={wrapperRefProfile}>
                        <div
                            onClick={() => {
                                dispatch(headerOrganismSlice.actions.toggleDropdownProfile());
                            }}
                            className="Header-right-item-profile-main">
                            <img src={person} alt={t('Base_profile_picture')}/>
                        </div>
                        {dropdownShownProfileState &&
                            <div className="Header-right-item-profile-dropdown">
                                <div className={"Header-right-item-profile-dropdown-triangle"}>
                                    <div className={"Header-right-item-profile-dropdown-triangle-inside"}>
                                        <div className={"Header-right-item-profile-dropdown-triangle-inside-sub"}>
                                        </div>
                                    </div>
                                </div>
                                <div className="Header-right-item-profile-dropdown-content">
                                    <ProfileMenu role={role} dispatchHideProfile={headerOrganismSlice.actions.hideDropdownProfile}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="Header-mobileRight">
                <div className="Header-mobileRight-sos">
                    <Button
                        onClick={() => {
                            dispatch(mainSlice.actions.dialModalShow());
                        }}
                        text={'SOS'}
                        type={ButtonType.TERNARY}/>
                </div>
                <div className="Header-mobileRight-sms">
                    <Button
                        onClick={() => {
                            dispatch(mainSlice.actions.smsModalShow());
                        }}
                        text={'SMS'}
                        type={ButtonType.TERNARY}/>
                </div>
            </div>
        </div>
    );
}

export default Header;
