import "./Dropdown.scss"
import React from "react";

/**
 * @interface
 * @category Components
 */
export interface DropdownOption {

    /** Name of option **/
    title: string,
    /** Shown option icon **/
    icon: any,
    /** Option click callback **/
    callback?: EmptyFunc,
}

/**
 * @alias DropdownProps
 * @category Components
 */
type DropdownProps = {

    /** Options for dropdown **/
    options: Array<DropdownOption>
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {DropdownOption[]} options - dropdown options
 */
export const Dropdown = ({options} : DropdownProps): React.JSX.Element => {

    return (
        <div className={`Dropdown`}>
            <div className={`Dropdown-arrow`}>
                <div className={`Dropdown-arrow-item`}>
                </div>
            </div>
            <div className={`Dropdown-content`}>
                {options.map((option: DropdownOption, index: number) => {
                    return (
                        <div className={'Dropdown-content-item'}
                             key={`Dropdown-content-item-${index}`}
                             onClick={ () => { if (option.callback) { option.callback(); } } }>
                            <div className={'Dropdown-content-item-icon'}>
                                <img src={option.icon} alt={option.title} />
                            </div>
                            <div className={'Dropdown-content-item-title'}>
                                {option.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Dropdown;
