import React from "react";
import "./HardwareCreateDialogManual.scss"
import AddOrEditHardware from "../AddOrEditHardware/AddOrEditHardware";
import {useAppSelector} from "../../../store/hooks";
import {selectFormError, selectInputState, selectValidState} from "../../../models/utils/AbstractFormReducers";
import {hardwareCreateDialogManualOrganismSlice} from "../../../store/slices/organisms/hardwareCreateDialogManualSlice";

/**
 * @component
 * @category Components
 * @subcategory Organisms
 */
export const HardwareCreateDialogManual = (): React.JSX.Element => {

    const hardwareTemplateState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogManualOrganism, 'hardwareTemplate'));
    const modelState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogManualOrganism, 'model'));
    const uidState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogManualOrganism, 'uid'));
    const internalIdState = useAppSelector((state) => selectInputState(state.hardwareCreateDialogManualOrganism, 'internalId'));
    const formValidState = useAppSelector((state) => selectValidState(state.hardwareCreateDialogManualOrganism));
    const formErrorState = useAppSelector((state) => selectFormError(state.hardwareCreateDialogManualOrganism));

    return (
        <div className={"HardwareCreateDialogManual"}>
            <div className="HardwareCreateDialogManual-content">
                <AddOrEditHardware
                    addMoreCallback={ () => {}}
                    formState={{
                        hardwareTemplateState: hardwareTemplateState,
                        modelState: modelState,
                        uidState: uidState,
                        internalIdState: internalIdState,
                        formValidState: formValidState,
                        formErrorState: formErrorState,
                        clearForm: hardwareCreateDialogManualOrganismSlice.actions.clearForm,
                        setFormError: hardwareCreateDialogManualOrganismSlice.actions.setFormError,
                        clearFormErrors: hardwareCreateDialogManualOrganismSlice.actions.clearFormErrors,
                        updateInput: hardwareCreateDialogManualOrganismSlice.actions.updateInput,
                        showInputError: hardwareCreateDialogManualOrganismSlice.actions.setFocus,
                        hideInputError: hardwareCreateDialogManualOrganismSlice.actions.removeFocus,
                        showInputValidStates: hardwareCreateDialogManualOrganismSlice.actions.showInputValidStates,
                    }}
                    isModal={false} />
            </div>
        </div>
    );
}

export default HardwareCreateDialogManual;
