import {PayloadAction} from "@reduxjs/toolkit";
import {CustomDraggableAllowedContent} from "../../components/molecules/CustomDraggable/CustomDraggable";
import * as Sentry from "@sentry/react";
import {BuildingUtil} from "./BuildingUtil";
import {HiveResponseDto} from "../entities/Responses/HiveResponseDto";

/**
 * @callback updateFloorCallback
 * @param {number} floor - floor elevation
 * @return void
 */
export type UpdateFloorCallback = (floor: number) => void;

/**
 * @alias BuildingPlanUploadCallbackUpdateFileInput
 * @category Utils
 */
export type BuildingPlanUploadCallbackUpdateFileInput = (file: {fileValue: string, fileName: string}, sizeX: number, sizeY: number) => void;

/**
 * @enum {number}
 * @category Utils
 */
export enum AbstractBuildingActiveType {
    FROM_EASY_LIST = 1,
    FROM_BUILDING_PLAN,
    DETAIL_PAGE,
    BOX_HIVE
}

/**
 * @interface
 * @category Utils
 */
export interface AbstractBuildingUpdateActivePayload {
    id: number,
    callerType: AbstractBuildingActiveType
}

/**
 * @enum {string}
 * @category Utils
 */
export enum InternalBuildingLocationType {
    BLUE = "blue",
    DEFAULT = "default",
    PINK = "pink",
    ORANGE = "orange"
}

/**
 * @interface
 * @category Utils
 */
export interface InternalBuildingLocation {

    /** Hardware id **/
    hardwareId: number,
    /** Hardware name **/
    hardwareName: string,
    /** Left offset for map location **/
    leftOffsetPct: number,
    /** Top offset for map location **/
    topOffsetPct: number,
    /** Internal type **/
    type: InternalBuildingLocationType,
}

/**
 * @interface
 * @category Utils
 */
export interface InternalBuildingFloor {

    /** Elevation **/
    elevation: number,
    /** Plan in base64 **/
    plan: string,
    /** List of all locations **/
    locations: Array<InternalBuildingLocation>,
}

/**
 * @interface
 * @category Utils
 */
export interface InternalBuildingBuilding {

    /** Building name **/
    name: string,
    /** Shown transparency for map **/
    transparency: number,
    /** Floor list **/
    floors: Array<InternalBuildingFloor>,
}

/**
 * @interface
 * @category Utils
 */
export interface InternalBuilding {

    /** Internal building object **/
    building?: InternalBuildingBuilding|null
}

/**
 * @interface
 * @category Utils
 */
export interface AbstractBuildingReducersState {

    /** Current floor elevation **/
    floorElevation: number,
    /** Current floor index in array **/
    floorIndex: number,
    /** Current zoom level **/
    zoom: number,
    /** Current active hardware **/
    activeHardware: AbstractBuildingUpdateActivePayload|null,
    /** Current active patient **/
    activePatient: AbstractBuildingUpdateActivePayload|null,
    /** Internal building data **/
    internalBuilding?: InternalBuilding|null,
    /** Hive data **/
    hive?: HiveResponseDto|null,
    /** Map allowed bounds **/
    draggableAllowedContent: CustomDraggableAllowedContent|null,
    /** Modal for fullscreen shown **/
    modalFullScreenShown: boolean,
    /** Current pagination page **/
    paginationPage: number|null,
    /** View is initialized **/
    initialized: boolean
}

/**
 * Default building state initialized
 * @category Utils
 * @constant
 */
export const abstractBuildingInitialState: AbstractBuildingReducersState = {
    floorElevation: 1,
    floorIndex: 0,
    zoom: 1,
    activeHardware: null,
    activePatient: null,
    draggableAllowedContent: null,
    modalFullScreenShown: false,
    paginationPage: null,
    initialized: false
}

/**
 * Destroy abstract building state
 * @category Utils
 * @function
 * @param {AbstractBuildingReducersState} state - abstract building state
 */
export const destroyGetAbstractBuilding = (state: AbstractBuildingReducersState) => {

    state.internalBuilding = undefined;
    state.activeHardware = null;
    state.floorElevation = 1;
    state.floorIndex = 0;
    state.zoom = 1;
    state.initialized = false;
    state.draggableAllowedContent = null;
    state.paginationPage = null;
}

/**
 * Get default building reducers
 * @function
 * @category Utils
 */
export const getAbstractBuildingReducers = () => {
    return {
        hideFullScreen: (state: AbstractBuildingReducersState) => {
            state.modalFullScreenShown = false;
        },
        showFullScreen: (state: AbstractBuildingReducersState) => {
            state.modalFullScreenShown = true;
        },
        setInitialized: (state: AbstractBuildingReducersState) => {
            state.initialized = true;
        },
        removeActives: (state: AbstractBuildingReducersState) => {
            state.activeHardware = null;
            state.activePatient = null;
        },
        updateDraggableAllowedContent: (state: AbstractBuildingReducersState, action: PayloadAction<CustomDraggableAllowedContent>) => {
            state.draggableAllowedContent = action.payload;
        },
        updateFloorElevation: (state : AbstractBuildingReducersState, action: PayloadAction<{ floor: number }>) => {

            if (!state.internalBuilding || !state.internalBuilding.building) {
                Sentry.captureMessage("Attempt for removal but building not initialized!",
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            state.floorElevation = action.payload.floor;
            state.activePatient = null;
            state.activeHardware = null;
            state.draggableAllowedContent = null;
            state.zoom = 1;
            state.initialized = false;
            state.floorIndex = state.internalBuilding.building.floors.findIndex((floor: InternalBuildingFloor) => {
                return floor.elevation === action.payload.floor;
            });
        },
        updateZoomValue: (state : AbstractBuildingReducersState, action: PayloadAction<{ zoom: number }>) => {
            state.zoom = action.payload.zoom;
        },
        updateActivePatient: (state : AbstractBuildingReducersState, action: PayloadAction<AbstractBuildingUpdateActivePayload>) => {

            state.activePatient = action.payload;
            state.activeHardware = null;
            state.paginationPage = null;

            if (!state.hive) {
                Sentry.captureMessage("Attempt for patient activation but building not initialized!",
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            if (!state.internalBuilding || !state.internalBuilding.building) {
                Sentry.captureMessage("Attempt for hardware activation but building not initialized!",
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            // Get floor
            const activeFloor = BuildingUtil.getFloorFromPatientId(state.hive, action.payload.id);
            if (!activeFloor) {
                Sentry.captureMessage(`Can't get active floor for patient ${action.payload.id}!`,
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            // Get floor index
            const activeFloorIndex = state.internalBuilding.building.floors.findIndex((floor: InternalBuildingFloor) => {
                return floor.elevation === activeFloor;
            });

            // Update
            if (activeFloorIndex !== -1) {
                state.floorElevation = activeFloor;
                state.floorIndex = activeFloorIndex;
            }
        },
        updateActiveHardware: (state : AbstractBuildingReducersState, action: PayloadAction<AbstractBuildingUpdateActivePayload>) => {

            state.activeHardware = action.payload;
            state.activePatient = null;
            state.paginationPage = null;

            if (!state.internalBuilding || !state.internalBuilding.building) {
                Sentry.captureMessage("Attempt for hardware activation but building not initialized!",
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            // Get floor
            const activeFloor = BuildingUtil.getFloorFromHardwareId(state.internalBuilding.building, action.payload.id);
            if (!activeFloor) {
                Sentry.captureMessage(`Can't get active floor for hardware${action.payload.id}!`,
                    {tags: {'slice' : 'getAbstractBuildingReducers'}});
                return;
            }

            const activeFloorIndex = state.internalBuilding.building.floors.findIndex((floor: InternalBuildingFloor) => {
               return floor.elevation === activeFloor.elevation;
            });

            // Update
            if (activeFloorIndex !== -1) {
                state.floorElevation = activeFloor.elevation;
                state.floorIndex = activeFloorIndex;
            }
        },
    }
}
