import {createSlice} from '@reduxjs/toolkit'

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 */
interface DialModalState {

    /** Add new contact form shown **/
    addFormShown: boolean,
}

const initialState: DialModalState = {
    addFormShown: false,
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const dialModalOrganismSlice = createSlice({
    name: 'dialModalOrganism',
    initialState,
    reducers: {
        destroyOrganism: (state) => {
            state.addFormShown = false;
        },
        showAddForm: (state) => {
            state.addFormShown = true;
        },
        hideAddForm: (state) => {
            state.addFormShown = false;
        },
    },
})

export default dialModalOrganismSlice.reducer