import React, {useEffect} from "react";
import {HardwareDetailTemplate} from "../templates/HardwareDetailTemplate/HardwareDetailTemplate";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {actionGetSensorList} from "../../store/actions/data/hardwareAction";
import {hardwareDetailPageSlice} from "../../store/slices/pages/hardwareDetailPageSlice";
import {Loading, LoadingTypeEnum} from "../extras/Loading/Loading";
import {ErrorComponent, ErrorComponentTypeEnum, ErrorComponentVariant} from "../extras/ErrorComponent/ErrorComponent";
import {actionGetSensorTypes} from "../../store/actions/data/sensorAction";
import {Storage} from "../../models/utils/Storage";
import {useTranslate} from "@tolgee/react";
import {actionGetHardware} from "../../store/actions/pages/hardwareDetailAction";
import {actionGetZoneList} from "../../store/actions/data/zoneAction";

/**
 * @component
 * @category Components
 * @subcategory Pages
 */
export const HardwareDetailPage = (): React.JSX.Element => {

    // Init
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = Storage.getSession()?.user.role;
    const {t, isLoading} = useTranslate();

    // Initialize states
    const hardwareState = useAppSelector((state) => state.hardwareDetailPage.hardware);
    const sensorListState = useAppSelector((state) => state.hardwareDetailPage.sensorList);
    const sensorTypes = useAppSelector((state) => state.sensorData.sensorTypes);
    const zoneList = useAppSelector((state) => state.zoneData.zoneList);

    // Get data
    useEffect(() => { if (params.id) { dispatch(actionGetHardware(parseInt(params.id))); }}, [dispatch, params]);
    useEffect(() => { if (!zoneList) { dispatch(actionGetZoneList()); } }, [dispatch, zoneList]);
    useEffect(() => { if (!sensorTypes) { dispatch(actionGetSensorTypes()); } }, [dispatch, sensorTypes]);
    useEffect(() => { if (params.id) { dispatch(actionGetSensorList(parseInt(params.id))); } }, [params, dispatch]);
    useEffect(() => { return () => { dispatch(hardwareDetailPageSlice.actions.destroyPage()); } }, [dispatch]);

    // Wrong session state
    if (!role) {
        return (
            <ErrorComponent
                variant={ErrorComponentVariant.UNAUTHORIZED}
                type={ErrorComponentTypeEnum.FULL}
                title={t("Base_errors_wrong_session_title")}
                subTitle={t("Base_errors_wrong_session_description")} />
        )
    }

    // Check & Wait for required data
    if (!params.id) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_not_found_title")}
            subTitle={t("Base_errors_page_not_found_description")} />
    }

    if (typeof hardwareState === "undefined" || typeof sensorListState === "undefined"
        || typeof sensorTypes === "undefined" || typeof zoneList === "undefined"  || isLoading) {
        return <Loading type={LoadingTypeEnum.FULL} />
    }

    if (hardwareState === null) {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_hardware')})} />
    }

    if (sensorListState === null)  {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_hardware_sensors')})} />
    }

    if (sensorTypes === null)  {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_sensor_types')})} />
    }

    if (zoneList === null)  {
        return <ErrorComponent
            type={ErrorComponentTypeEnum.FULL}
            title={t("Base_errors_page_load_title")}
            subTitle={t("Base_errors_page_load_description", {dataName: t('Base_name_zone_list')})} />
    }

    return (
        <HardwareDetailTemplate
            role={role}
            zoneList={zoneList}
            sensorTypes={sensorTypes}
            sensorsData={sensorListState}
            hardwareData={hardwareState} />
    )
};

export default HardwareDetailPage;
