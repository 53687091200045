import {createSlice} from '@reduxjs/toolkit'
import {AbstractFormReducersState, getAbstractFormReducers} from "../../../models/utils/AbstractFormReducers";
import {actionCreatePatient, actionUpdatePatient} from "../../actions/data/patientAction";
import {ValidatorItemType} from "../../../models/utils/Validator";

/**
 * @interface
 * @category Redux
 * @subcategory Slices
 * @extends {AbstractFormReducersState}
 */
interface AddOrEditPatientState extends AbstractFormReducersState {}

const initialState: AddOrEditPatientState = {
    form: {
        inputs: [
            {name: 'firstName', validations: {required: true}},
            {name: 'lastName', validations: {required: true}},
            {name: 'dateOfBirth', validations: {required: false, type: ValidatorItemType.DATE}},
            {name: 'location', validations: {required: false}},
            {name: 'placeOfBirth', validations: {required: false}},
            {name: 'residence', validations: {required: false}},
            {name: 'phone', validations: {required: false}},
            {name: 'submit'}
        ]
    },
}

/**
 * @constant
 * @category Redux
 * @subcategory Slices
 */
export const addOrEditPatientOrganismSlice = createSlice({
    name: 'addOrEditPatientOrganism',
    initialState,
    reducers: {...getAbstractFormReducers(), ...{}},
    extraReducers: (builder) => {
        builder.addCase(actionCreatePatient.fulfilled, (state) => {
            state.form.error = null;
        })
        builder.addCase(actionUpdatePatient.fulfilled, (state) => {
            state.form.error = null;
        })
    }
})

export default addOrEditPatientOrganismSlice.reducer
