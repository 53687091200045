import { createAsyncThunk } from "@reduxjs/toolkit";
import {HardwareRepository} from "../../../models/repositories/HardwareRepository";
import {HardwareResponseDto} from "../../../models/entities/Responses/HardwareResponseDto";

/**
 * Get hardware by ID
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {number} hardwareId - hardware ID
 * @return {HardwareResponseDto | null}
 */
export const actionGetHardware = createAsyncThunk<HardwareResponseDto | null, number>(
    "hardwareDetail/getHardware",
    async (hardwareId: number) => {
        return await HardwareRepository.getHardware(hardwareId);
    }
);