import React from "react";
import {CustomDraggableAllowedContent} from "../CustomDraggable/CustomDraggable";
import {useAppDispatch} from "../../../store/hooks";
import {HiveUtil} from "../../../models/utils/HiveUtil";
import {DraggablePlanElement} from "./DraggablePlanElement";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {SensorTypeDto} from "../../../models/entities/SensorTypeDto";
import {HivePatientResponseDto} from "../../../models/entities/Responses/HivePatientResponseDto";
import {useTranslate} from "@tolgee/react";
import {AbstractBuildingActiveType, AbstractBuildingUpdateActivePayload} from "../../../models/utils/AbstractBuildingReducers";
import {ZoneDto} from "../../../models/entities/ZoneDto";

type DraggablePlanElementPatientComponentProps = {
    patient: HivePatientResponseDto,
    allowedContent: CustomDraggableAllowedContent,
    currentZoom: number,
    activePatient: AbstractBuildingUpdateActivePayload|null,
    sensorTypes: Array<SensorTypeDto>,
    zoneList: Array<ZoneDto>,
    dispatchUpdateActivePatient?: ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>,
}

/**
 * @component
 * @category Components
 * @subcategory Molecules
 * @param {HivePatientResponseDto} patient - patient data
 * @param {CustomDraggableAllowedContent} allowedContent - map bounds
 * @param {number} currentZoom - actual zoom
 * @param {AbstractBuildingUpdateActivePayload|null} activePatient - active patient
 * @param {SensorTypeDto[]} sensorTypes - list of sensors
 * @param {ZoneDto[]} zoneList - list of zones
 * @param {ActionCreatorWithPayload<AbstractBuildingUpdateActivePayload>} dispatchUpdateActivePatient - update active patient Redux callback
 */
export const DraggablePlanElementPatientComponent = ({patient, allowedContent, currentZoom, activePatient,
    sensorTypes, zoneList, dispatchUpdateActivePatient} : DraggablePlanElementPatientComponentProps): React.JSX.Element|null => {

    const {t} = useTranslate();
    const dispatch = useAppDispatch();
    if (patient.Patient.calcLocPlanOffsetTop === null || patient.Patient.calcLocPlanOffsetLeft === null) { return null; }
    const hiveMetas = HiveUtil.getHiveMetas({
        sensors : patient.Sensors,
        sensorTypes : sensorTypes,
        t : t,
        zoneList: zoneList,
        tempBeatMode : true,
        patient : patient.Patient});

    return (
        <DraggablePlanElement
            dragged={false}
            small={true}
            type={hiveMetas.type}
            zoom={currentZoom}
            allowedContent={allowedContent}
            active={patient.Patient.id === activePatient?.id}
            onClick={dispatchUpdateActivePatient ? () => {
                dispatch(dispatchUpdateActivePatient({
                    id: patient.Patient.id,
                    callerType: AbstractBuildingActiveType.FROM_BUILDING_PLAN
                }));
            } : undefined }
            name={hiveMetas.title}
            patient={{id: patient.Patient.id, name: hiveMetas.title}}
            bubble={{
                alert: hiveMetas.label,
                locationStatus: patient.Patient.locatingState,
                sensors: hiveMetas.sensors
            }}
            location={{
                leftOffsetPct: patient.Patient.calcLocPlanOffsetLeft,
                topOffsetPct: patient.Patient.calcLocPlanOffsetTop,
            }}
        />
    )
}

export default DraggablePlanElementPatientComponent;
