import { createAsyncThunk } from "@reduxjs/toolkit";
import {SensorRepository} from "../../../models/repositories/SensorRepository";
import {SensorResponseDto} from "../../../models/entities/Responses/SensorResponseDto";

/**
 * Update patient sensors state
 * @category Redux
 * @subcategory Actions
 * @function
 * @param {{patientId: number}} payload - request payload
 * @return {SensorResponseDto|string}
 */
export const actionRefreshPatientSensors = createAsyncThunk<Array<SensorResponseDto>|null|string, { patientId: number }>(
    "patientSensors/refreshPatientSensors",
    async (payload: { patientId: number }, { rejectWithValue }) => {
        try { return await SensorRepository.getSensorsForPatient(payload.patientId) }
        catch (e: any) { return rejectWithValue(e.toString()); }
    }
);
